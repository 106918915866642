/*
 * @Author: Chanaka Wickramasinghe
 * @Description: Material Expansion Panel
 * @Date: 2020-02-14 09:55:14
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-03-02 15:20:34
 */

import React from 'react';
import PropTypes from 'prop-types';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import {templateTypes, defaultTemplateType} from "../../../../config/template.config";

import {Icon} from "../common/BaseElements";


const emptyFun = (...para) => undefined;

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Material Expansion Panel
 * --------------------------------------------
 */

const ExpansionPanelWrapper=({
    uiType = defaultTemplateType,
    elementStyle = "",
    elementName = "",
    isExpandedStatus = false,
    panelTitle = (null),
    panelBody = (null),
    onExpansionFn = emptyFun
}) => {
    return (
        <ExpansionPanel
            className={`fullWidthDiv ${uiType}ExpansionPanelWrapper ${elementStyle}`}
            onChange={() => onExpansionFn(elementName)}
            expanded={isExpandedStatus}
        >
            <ExpansionPanelSummary
                className={`defaultExpansionBar`}
                expandIcon={<Icon iconClass="mdi mdi-chevron-down" />}
                aria-controls={`${elementName}-content`}
                id={`${elementName}-header`}
            >
                {panelTitle}
            </ExpansionPanelSummary>

            <ExpansionPanelDetails
                className={`defaultExpansionContent`}
            >
                {panelBody}
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Material Expansion Panel
 * --------------------------------------------
 */

ExpansionPanelWrapper.propTypes = {
    /** ExpansionPanelWrapper type */
    uiType: PropTypes.oneOf([templateTypes.material, templateTypes.bootstrap]),
    /** Expansion panel element css class */
    elementStyle: PropTypes.string,
    /** Element name */
    elementName: PropTypes.string,
    /** Is expanded or not */
    isExpandedStatus: PropTypes.bool,
    /** Panel title element */
    panelTitle: PropTypes.element,
    /** Panel body element */
    panelBody: PropTypes.element,
    /** Onexpansion function */
    onExpansionFn: PropTypes.func
};

//----------------ExpansionPanelWrapper---------------------

export default ExpansionPanelWrapper;