/*
 * @Author: Anjula Karunarathne 
 * @Date: 2021-01-21 16:06:16 
 * @Last Modified by:   Anjula Karunarathne 
 * @Last Modified time: 2021-01-21 16:06:16 
 */

import React, { Fragment } from "react";
import { Navigate } from "../../ui-components/ui-elements/common/Navigate";
import { permissions } from "../../../config/permission.config";
import AdditionsDeductions from "./pages/AdditionsDeductions";
import authorized from "../../hoc-middlewares/Authorized";

const AdditionsDeductionsRoutes = () => {
  return (
    <Fragment>
      <Navigate
        exact={true}
        path="/additions-deductions"
        routeKey={"additionsDeductions"}
        component={authorized(AdditionsDeductions)}
        routePermissions={permissions.ADDITION_DEDUCTION_MANAGE.permissions}
      />
    </Fragment>
  );
};

export default AdditionsDeductionsRoutes;
