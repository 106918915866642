import React, { useState } from "react";
import {
  TabsWrapper,
  SubTab,
  tabsWrapperScrollButtons,
  tabsWrapperVariants,
} from "../../../../ui-components/ui-elements/tab/Tabs";
import { CompanyInfo } from "./common-incudes/CompanyInfo";
import { IssuingOfficers } from "./common-incudes/IssuingOfficers";
import { vrpCompanyListAPI } from './../../../../../config/apiUrl.config';

const AllInfo = (props) => {
  const [activeStep, setActiveStep] = useState("company_info");
  const fromParent={
    uiType:"EDIT",
    formKey:vrpCompanyListAPI.key + "_edit",
  }
  return (
    <TabsWrapper
      isTabFullWidth={false}
      active={activeStep}
      variant={tabsWrapperVariants.scrollable}
      scrollButtons={tabsWrapperScrollButtons.on}
      onChangeFn={(event) => setActiveStep(event.value)}
    >
      <SubTab tabid="company_info" header="Company Info" >
        <CompanyInfo fromParent={fromParent} row={{...props.row}}/>
      </SubTab>
      <SubTab tabid="issuing_officers" header="Issuing Officers" >
        <IssuingOfficers row={{ ...props.row }} />
      </SubTab>
    </TabsWrapper>
  );
}

export {
  AllInfo,
};
