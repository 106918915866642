/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-07-14 10:18:15
 * @Last Modified by: Pradeepa Sandaruwan
 * @Last Modified time: 2021-01-21 12:33:00
 */

import React from "react";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import {
  DataTable,
  actionBtnTypes,
} from "../../../ui-components/ui-elements/table/DataTable";
import { InsuranceCompanyForm } from "./includes/Insurance-company-form";
import { ViewInsuranceCompany } from "./includes/View-insurance-company";
import { SearchElement } from "./includes/Search-element";
import { insuranceCompany } from "../../../../config/apiUrl.config";
import { CustomButton } from "./includes/Custom-button";
import { permissions } from "../../../../config/permission.config";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const ManageInsuranceCompany = () => {
  const formKey = "testInuts";
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
}, [pathname]);
  return (
    <DashboardTemplate
      pageTitle="Manage Insurance Company"
      breadCrumbList={[
        { urlTo: "/", displayName: "Home" },
        { urlTo: "", displayName: "Support Organisations" },
        { urlTo: "", displayName: "Insurance Company" },
      ]}
    >
      <div className="row">
        <div className="col-md-12 floatLeft ">
          <DataTable
            apiUrl={`${insuranceCompany.url}?sort_by=created_at|desc`}
            dataTableKey={insuranceCompany.key}
            isSetAction={true}
            tableName="Insurance Companies"
            isSetSearchFrom={true}
            defaultSearchFormObject={{
              "name": "",
              "code": "",
              "active": null              
            }}
            searchFromComponent={
              <SearchElement dataTableKey={insuranceCompany.key}  />
            }
            actionTypeList={[
              {
                actionType: actionBtnTypes.create,
                actionBtn: { tooltip: "add", "permissions": permissions.INSURANCE_COMPANY_ADD.permissions },
                modelContentElement: InsuranceCompanyForm,
                dialogPopupProps: {
                  isFullScreen: false,
                  isSaveButton: false,
                  isCustomButton: true,
                  dialogTitle: "Add Insurance Company",
                  customButtonComponent:CustomButton,
                  isConfirmationPopup: true,
                  customButtonComponentProps:{ btnText: "Create", uiType: "Add" },
                  closeButton: {
                    btnText: "Cancel",
                    startIcon: "far fa-times-circle",
                  }
                },
                parentToModelProps: {
                  formKey: insuranceCompany.key + "_create",
                  dataTableKey: insuranceCompany.key,
                  uiType: "Add"
              },
              },
              {
                actionType: actionBtnTypes.view,
                actionBtn: { 
                  
                  tooltip: "View", 
                  "permissions": permissions.INSURANCE_COMPANY_VIEW.permissions 
                },
                modelContentElement: ViewInsuranceCompany,
                dialogPopupProps: {
                  isFullScreen: false,
                  isSaveButton: false,
                  isCloseButton: true,
                  dialogTitle: "View Insurance Company",
                  closeButton: {
                    btnText: "Close",
                    startIcon: "far fa-times-circle",
                  }
                },
              },
              {
                actionType: actionBtnTypes.edit,
                actionBtn: { tooltip: "Edit", "permissions": permissions.INSURANCE_COMPANY_EDIT.permissions },
                modelContentElement: InsuranceCompanyForm,
                dialogPopupProps: {
                  isFullScreen: false,
                  dialogTitle: "Edit Insurance Company",
                  isSaveButton: false,
                  closeButton: {
                    btnText: "Cancel",
                    startIcon: "far fa-times-circle",
                  },
                  isCustomButton: true,
                  customButtonComponentProps:{ btnText: "Update", uiType: "Edit" },
                  dialogTitle: "Edit Insurance Company ",
                  customButtonComponent: CustomButton,
                  isConfirmationPopup: true,
                },
                parentToModelProps: {
                  formKey: insuranceCompany.key + "_edit",
                  dataTableKey: insuranceCompany.key,
                  uiType: "Edit"
              },
              },
            ]}
            tableHeaderList={[
              { displayName: "Insurance Company Code", key: "code", isSort: false },
              { displayName: "Insurance Company Name", key: "service.name", isSort: false },
              { displayName: "Business Registration Number", key: "br_number", isSort: false },
              { displayName: "Status", key: "service.active", isSort: false, onPrintFn: (rawData) => ((rawData.service.active == 1) ? "Active" : "Inactive" )},
            ]}
          />
        </div>
      </div>
    </DashboardTemplate>
  );
};

export default ManageInsuranceCompany;
