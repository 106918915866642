import React, { Fragment, useContext, useEffect } from "react";
import { templateTypes } from "../../../../../config/template.config";
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import {
  FormWrapper,
  SelectBoxWithState,
  InputBoxWithState,
  NumberInputBoxWithState,
} from "../../../../ui-components/ui-elements/form";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { getProvinceFn } from "../../../../../helpers/common-helpers/getProvinceById.helpers";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
const Form = (props) => {
  const formKey = `${props.fromParent.formKey}`;
  const [formState, formAction] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);
  const serviceId=_get(authStatus, "authUser.service_id", null)
  useEffect(() => {    
    getProvinceFn({value:serviceId}, formAction, formKey);
  }, []);
  
  return (
    <Fragment>
      <UICard>
        <FormWrapper
          setGroupName={formKey}
          formGroupLinkWith={formKey}
          setFormObject={{
            id: _get(props, "row.id", undefined),
            province: _get(props, "row.province_id", ""),
            active: _get(props, "row.active", ""),
            charge: parseFloat(_get(props, "row.charge", null)),
            delivery_method: _get(props, "row.delivery_method.id", ""),
            delivery_method_name: _get(props, "row.delivery_method.name", ""),
          }}
          onDestroyUnsetFormObject={true}
        >
          <InputBoxWithState
            formGroupName={formKey}
            isDisabled={true}
            inputName={"province_name"}
            labelText="Province"
            isRequired={false}
          />

          <InputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            isDisabled={true}
            inputName={"delivery_method_name"}
            labelText="Delivery Method"
            isRequired={false}
          />

          <SelectBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            dataList={[
              {
                id: "1",
                value: "Active",
              },
              {
                id: "0",
                value: "Inactive",
              },
            ]}
            inputName={"active"}
            labelText="Status"
            emptySelectOptionTxt={null}
          />

          <NumberInputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            inputName={"charge"}
            labelText="Charge (LKR)"
            isRequired={true}
            inputPlaceholder={(charge) => (charge == 0 ? "Enter" : charge)}
            isAllowNegative={false}
            inputPlaceholder={"Enter"}
            decimalScale={2}
            isAllowedFn={(value) => {
                let checkValue = value.value.toString();

                if (checkValue.includes(".")) {
                    checkValue = checkValue.split(".")[0];
                }

                return checkValue.length <= 5;
            }}
          />
          
        </FormWrapper>
      </UICard>
    </Fragment>
  );
};

export { Form };
