/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-02-28 17:02:47 
 * @Last Modified by:   Nisal Madusanka(EruliaF) 
 * @Last Modified time: 2020-02-28 17:02:47 
 */

import React from 'react';
import ReactDOM from 'react-dom';
import App from './bootstrap/App';
import "./style/app.scss";
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
