import React from "react";

import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { FormWrapper, InputBoxWithState, SelectBoxWithState, NumberInputBoxWithState } from "../../../../ui-components/ui-elements/form";
import { _get, _find } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { contactsArrayToObject } from "../../../../../helpers/common-helpers/contact.helpers";
import { ContactTypeCode } from "../../../../../config/enum.config";

const InsuranceCompanyForm = (props) => {
    const formKey = `${props.fromParent.formKey}`;

    return (
        <UICard>
            <FormWrapper
                setGroupName={formKey}
                formGroupLinkWith={props.fromParent.formKey}
                onDestroyUnsetFormObject={true}
                setFormObject={
                    props.fromParent.uiType !== "Add" ?
                        (
                            {
                                id: _get(props, "row.id", ""),
                                code: _get(props, "row.code", ""),
                                br_number: _get(props, "row.br_number", ""),
                                name: _get(props, "row.service.name", ""),
                                name_si: _get(props, "row.service.name_si", ""),
                                name_ta: _get(props, "row.service.name_ta", ""),
                                email: contactsArrayToObject(_get(props, `row.service.contacts`, []), false, ContactTypeCode.EMAIL),
                                contact_number: contactsArrayToObject(_get(props, `row.service.contacts`, []), false, ContactTypeCode.PHONE),
                                active: _get(props, "row.service.active", 1),
                                configurations: null,
                            }
                        ) : (
                            {
                                id: _get(props, "row.id", ""),
                                code: _get(props, "row.code", ""),
                                br_number: _get(props, "row.br_number", ""),
                                name: _get(props, "row.service.name", ""),
                                name_si: _get(props, "row.service.name_si", ""),
                                name_ta: _get(props, "row.service.name_ta", ""),
                                email: contactsArrayToObject(_get(props.row, `service.contacts`, []), false, ContactTypeCode.EMAIL),
                                contact_number: contactsArrayToObject(_get(props.row, `service.contacts`, []), false, ContactTypeCode.PHONE),
                                active: _get(props, "row.service.active", 1),
                                configurations: null,
                            }
                        )
                }
            >

                {_get(props, "fromParent.uiType", "") === "Edit" ? (
                    <InputBoxWithState
                        formGroupName={formKey}
                        labelText={"Insurance Company Code"}
                        isRequired={true}
                        inputName={"code"}
                        isDisabled={true}
                    />
                ) : (
                    <InputBoxWithState
                        formGroupName={formKey}
                        labelText={"Insurance Company Code"}
                        isRequired={true}
                        inputName={"code"}
                        maxLength={"12"}
                    />
                )}

                <InputBoxWithState
                    formGroupName={formKey}
                    labelText={"Insurance Company Name in Engilsh"}
                    isRequired={true}
                    inputName={"name"}
                    maxLength={"100"}
                />
                <InputBoxWithState
                    formGroupName={formKey}
                    labelText={"Insurance Company Name in Sinhala"}
                    isRequired={true}
                    inputName={"name_si"}
                    maxLength={"100"}
                />
                <InputBoxWithState
                    formGroupName={formKey}
                    labelText={"Insurance Company Name in Tamil"}
                    isRequired={true}
                    inputName={"name_ta"}
                    maxLength={"100"}
                />
                <InputBoxWithState
                    formGroupName={formKey}
                    labelText={"Business Registration Number"}
                    isRequired={true}
                    inputName={"br_number"}
                    maxLength={"30"}
                />
                <SelectBoxWithState
                    formGroupName={formKey}
                    labelText={"Status"}
                    inputName={"active"}
                    valueName={"value"}
                    keyName={"id"}
                    dataList={[{ "id": 1, "value": "Active" }, { "id": 0, "value": "Inactive" }]}
                />

                <InputBoxWithState
                    formGroupName={formKey}
                    labelText={"E-mail"}
                    isRequired={false}
                    inputName={"email"}
                    maxLength={50}
                />
                <NumberInputBoxWithState
                    formGroupName={formKey}
                    inputName={"contact_number"}
                    labelText={"Contact Number"}
                    isRequired={false}
                    isThousandSeparator={false}
                    isAllowNegative={false}
                    isAllowLeadingZeros={true}
                    decimalScale={0}
                    isAllowedFn={(value) => value.value.toString().length <= 10}
                />
                {/* <UICard
                            elementStyle={"defaultMarginTop"}
                            cardHeading = "API Configurations">
                                <FormWrapper setGroupName={formKey}>
                                    <InputBoxWithState
                                        formGroupName={formKey}
                                        inputName={"configurations"}
                                        labelText={"Configurations"}
                                        isRequired={true}
                                        maxLength={"50"}
                                    />
                                </FormWrapper>
                        </UICard> */}
            </FormWrapper>
        </UICard>
    )
}

export { InsuranceCompanyForm };