import React, { useContext } from "react";
import { actionBtnTypes } from "../../../../../config/dataTable.config";
import { FormWrapper, InputButton } from "../../../../ui-components/ui-elements/form";
import { DataTable } from "../../../../ui-components/ui-elements/table/DataTable";
import { CustomButton } from "./Custom-button";
import { AddCertificateNumber, RemoveCertificateNumbersForm } from './AddCerticateNumber'
import { vftListAPI, vftCertificateListAPI } from "../../../../../config/apiUrl.config";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { FormContext } from "../../../core/context-providers/FormContext.provider";

const CertificateNumbersUI = (props) => {
    const formKey = "CertifyingNumbers"
    const [formState, formAction] = useContext(FormContext);
    const company_id = _get(formState[`${vftListAPI.key}_edit`], "id", "");
    return (
        <div className="row">
            <FormWrapper
                setGroupName={formKey}
            >

                <DataTable
                    isSetTableFooter={true}
                    tableName={"Assigned VFT Certificate Numbers"}
                    apiUrl={`${vftListAPI.url}/${company_id}/certificate-numbers?sort_by=created_at|asc&used=false&page_size=3`}
                    dataTableKey={vftCertificateListAPI.key}
                    // elementWrapperStyle={"defaultMarginBottom"}
                    tableHeaderList={[
                        { displayName: "Certificate No. From", key: "certificate_number_from", isSort: false },
                        { displayName: "Certificate No. To", key: "certificate_number_to", isSort: false },
                        { displayName: "Certificate No. Count", key: "certificate_number_from", isSort: false, onPrintFn: ({ certificate_number_from, certificate_number_to }) => certificate_number_from ?(parseInt(certificate_number_to) - parseInt(certificate_number_from)+1): 0 },
                        { displayName: "Certificate No. Balance", key: "remaining_certificates", isSort: false, 
                        // onPrintFn: ({ certificate_number_from, certificate_number_to, not_used }) => certificate_number_from ? (parseInt(certificate_number_to) - parseInt(certificate_number_from)+1) : 0 - parseInt(not_used) 
                    },
                    ]}
                    actionTypeList={[
                        {
                            actionType: actionBtnTypes.delete,
                            actionBtn: { tooltip: 'Remove' },
                            modelContentElement: RemoveCertificateNumbersForm,
                            dialogPopupProps: {
                                dialogTitle: 'Please confirm',
                                isFullScreen: false,
                                isSaveButton: false,
                                isCloseButton: true,
                                isCustomButton: true,
                                customButtonComponent: CustomButton,
                                customButtonComponentProps: { btnText: "Yes", uiType: "REMOVE", dataTableKey: vftCertificateListAPI.key },
                                closeButton: {
                                    btnText: "No",
                                    startIcon: "fas fa-times",
                                },

                            },
                            parentToModelProps: {
                                // uiType:"CREATE",
                                requiredId: company_id,
                                vftCompanyId: (_get(props, "row.service.id", "")),
                                formKey: vftCertificateListAPI.key + "_delete",
                                dataTableKey: vftCertificateListAPI.key,

                            },
                        },
                        {
                            actionType: actionBtnTypes.create,
                            actionBtn: { tooltip: 'Create' },
                            modelContentElement: AddCertificateNumber,
                            dialogPopupProps: {
                                isFullScreen: false,
                                isSaveButton: false,
                                isCloseButton: true,
                                dialogTitle: "Set VFT Certificate Numbers",
                                // isCustomButton: true,
                                // customButtonComponent:()=>CustomButton({btnText:"Save"}),
                                closeButton: {
                                    btnText: "Cancel",
                                    startIcon: "far fa-times-circle",
                                },
                                isCustomButton: true,
                                isConfirmationPopup: true,
                                customButtonComponent: CustomButton,
                                customButtonComponentProps: { btnText: "Save", uiType: "CREATE", dataTableKey: vftCertificateListAPI.key },

                            },
                            parentToModelProps: {
                                // uiType:"CREATE",
                                requiredId: company_id,
                                vftCompanyId: (_get(props, "row.service.id", "")),
                                formKey: vftCertificateListAPI.key + "_create",
                                dataTableKey: vftCertificateListAPI.key,

                            },
                        },
                    ]}
                />


                <DataTable
                    isSetTableFooter={true}
                    dataTableKey={`${vftListAPI.key}CertificateNumberHistory`}
                    apiUrl={vftListAPI.url + "/" + company_id + "/certificate-numbers" + "?sort_by=created_at|desc&used=false&page_size=3"}
                    tableName={"VFT Certificate Number History"}
                    tableHeaderList={[
                        { displayName: "Certificate No. From", key: "certificate_number_from", isSort: false },
                        { displayName: "Certificate No. To", key: "certificate_number_to", isSort: false },
                        {
                            displayName: "Certificate No. Count", key: "used_certificates", isSort: false, 
                            // onPrintFn: (value) => {
                            //     return value.certificate_number_to - value.certificate_number_from;
                            // }
                        },
                        {
                            displayName: "Created By", key: "created_by_name", isSort: false, 
                        //     onPrintFn: ({ created_by }) =>
                        //         _get(created_by, "user_name", "-"),
                        },
                        { displayName: "Created Location", key: "created_location_name", isSort: false },
                        {
                            displayName: "Created At", key: "created_at", isSort: false, onPrintFn: (created_at) => {
                                return dateObjectToString(created_at.created_at, "dd/mm/YY HH:MM");
                            }
                        },
                    ]}
                    isSetAction={false}
                />

            </FormWrapper>

        </div>



    )
}

export { CertificateNumbersUI }