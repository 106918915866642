/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-02-28 17:31:46
 * @Last Modified by: Pradeepa Sandaruwan(EruliaF)
 * @Last Modified time: 2020-09-17 10:12:43
 */

import React, { Fragment } from "react";
import { Navigate } from "../../ui-components/ui-elements/common/Navigate";
import { permissions } from "../../../config/permission.config";
import guest from "../../hoc-middlewares/Guest";
import ManageDeliveryMethods from "./pages/ManageDeliveryMethods";
import authorized from "../../hoc-middlewares/Authorized";

const ManageDeliveryMethodsRoutes = () => {
  return (
    <Fragment>
      <Navigate
        exact={true}
        path="/manage-delivery-methods"
        routeKey={"delivery-methods"}
        component={authorized(ManageDeliveryMethods)}
        routePermissions={permissions.DELIVERY_METHOD_MANAGE.permissions}
      />
    </Fragment>
  );
};

export default ManageDeliveryMethodsRoutes;
