import React, { Fragment } from 'react';

import { permissions } from '../../../config/permission.config';
import { Navigate } from '../../ui-components/ui-elements/common/Navigate';
import WebNews from './pages/WebNews';
import authorized from '../../hoc-middlewares/Authorized';

const WebPortalRoutes = () => {
    return (
        <Fragment>
            <Navigate
                exact={true} path="/web-news"
                routeKey={"webPortalNews"}
                component={authorized(WebNews)}
                routePermissions={permissions.NEWS_MANAGE.permissions}
            />
        </Fragment>
    )
};

export default WebPortalRoutes;