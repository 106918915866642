import React, { Fragment } from 'react';
import { permissions } from '../../../config/permission.config';
import guest from '../../hoc-middlewares/Guest';
import { Navigate } from '../../ui-components/ui-elements/common/Navigate';
import Login from './pages/login/Login';
import { ChangePassword } from './pages/change-password/ChangePassword';
import authorized from "../../hoc-middlewares/Authorized";

const AuthRoutes = () => {
  return (
    <Fragment>
      <Navigate
        exact={true}
        path="/login"
        component={guest(Login)}
        routerPermissions={permissions.NONE.permissions}
      />
      <Navigate
        exact={true}
        path="/login"
        component={guest(Login)}
        routerPermissions={permissions.NONE.permissions}
      />
      <Navigate
        exact={true}
        path="/change-password"
        component={authorized(ChangePassword)}
        routerPermissions={permissions.PASSWARD_CHANGE.permissions}
      />
    </Fragment>
  );
};

export default AuthRoutes;
