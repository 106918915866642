import React from "react";
import { FormWrapper, InputBoxWithState, SelectBoxWithState } from "../../../../ui-components/ui-elements/form";

const SearchElement = ({ dataTableKey = "" }) => {
    const formKey = `${dataTableKey}_search`;
    
    return (
        <FormWrapper setGroupName={formKey}>
            <div className="row">
                <div className={"col-md-2 col-sm-5 floatLeft"}>
                    <InputBoxWithState
                        labelText={"Prefix"}
                        formGroupName={formKey}
                        inputName={"prefix"}
                        inputPlaceholder={"Prefix"}
                        maxLength={"2"}
                    />
                </div>
                <div className={"col-md-2 col-sm-5 floatLeft"}>
                    <InputBoxWithState
                        labelText={"Name"}
                        formGroupName={formKey}
                        inputName={"name"}
                        inputPlaceholder={"Name"}
                        maxLength={"50"}
                    />
                </div>
                <div className={"col-md-2 col-sm-5 floatLeft"}>
                    <SelectBoxWithState
                        isEnableAllOption= {true}
                        labelText={"Status"}
                        formGroupName={formKey}
                        inputName={"active"}
                        emptySelectOptionTxt={"All"}
                        dataList={[{ "id": "true", "value": "Active" }, { "id": "false", "value": "Inactive" }]}
                    />
                </div>
            </div>
        </FormWrapper>
    );
};

export {
    SearchElement,
}