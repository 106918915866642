/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-02-28 16:56:11
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-04-06 13:07:15
 */

import React from 'react';

import { ContextProvider } from './Providers';
import { ThemeProvider } from '../components/ui-components/ui-elements/common/BaseElements';
import Routes from './Routers';
import { SnackBarList } from '../components/ui-components/ui-elements/common/SnackbarWrapper';

const App = () => {
  return (
    <ContextProvider>
      <ThemeProvider>
        <SnackBarList />
        <Routes />
      </ThemeProvider>
    </ContextProvider>
  );
};

export default App;
