/*
 * @Author: Sujith
 * @Date: 2020-09-28 11:11:46 
 * @Last Modified by: Sujith
 * @Last Modified time: 2020-09-28 11:12:43
 */

import React, { Fragment } from 'react';


import { Navigate } from "../../ui-components/ui-elements/common/Navigate";
import { permissions } from "../../../config/permission.config";
import Bank from "./pages/Bank";
import authorized from '../../hoc-middlewares/Authorized';


const BankRoutes = () => {
    return (
        <Fragment>
            <Navigate exact={true} path="/bank" routeKey={"bank"} component={authorized(Bank)} routePermissions={permissions.BANK_MANAGE.permissions} />
        </Fragment>
    )
};

export default BankRoutes;