/*
 * @Author: Chanaka Wickramasinghe
 * @Description: Image/File Upload Base64
 * @Date: 2021-06-17 09:37:56
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2021-06-17 09:37:56
 */

import React, { Fragment, useContext, useRef } from "react";
import PropTypes from "prop-types";

import { Icon } from "../common/BaseElements";
import { _size, _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { FormContext } from "../../../modules/core/context-providers/FormContext.provider";
import { checkValidFileType } from "../../../../helpers/upload-component-helpers/uploadComponent.helpers";
import FileDisplay from "./upload-component-includes/FileDisplay";
import { FormHelperTextWrapper, InputLabelWrapper } from "../form/form-includes/FormCoreIncludes";
import { getValueByFilter } from "../../../../helpers/common-helpers/common.helpers";


/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Upload Component Base64
 * --------------------------------------------
 */

// Upload Types
const uploadTypes = {
    image: "image",
    file: "file"
};

// Valid Image Types
const validImageTypes = {
    png: "png",
    jpg: "jpg",
    jpeg: "jpeg",
};

// Valid File Types
const validFileTypes = {
    txt: "txt",
    pdf: "pdf",
    docx: "docx",
    xlsx: "xlsx",
};
let fileName = "";
const UploadComponentBase64 = ({
    elementWrapperStyle = "",
    uploadSliderStyle = "",
    displayFileStyle = "",
    uploadBtnStyle = "",
    isRequired = false,
    isDisable = false,
    labelText = "",
    helperText = "",
    formGroupName = "",
    formKey = "",
    isMultiple = true,
    isDelete = true,
    uploadType = uploadTypes.image,
    maxUploadCount = 3,
    validTypes = [validImageTypes.jpeg, validImageTypes.jpg, validImageTypes.png],
    imageList = []
}) => {
    const inputRef = useRef(null);
    const [formState, formAction] = useContext(FormContext);

    const checkUploadedCount = (isMultiple) ? maxUploadCount : 1;
    const dataList = imageList.length > 0 ? imageList : _get(formState, `${formGroupName}.${formKey}`, []);

    const onChangeFn = async (event) => {
        if (_size(event.target.files) > 0) {
            const files = event.target.files;
            let count = _get(formState, `${formGroupName}.${formKey}`, []).length;
            const currentFileList = [];
            for (let i = 0; i < files.length; i++) {
                if (count < checkUploadedCount && checkValidFileType(files[i].type, uploadType, validTypes) === true) {
                    const base64Encode = await getBase64(files[i]);
                    currentFileList.push(base64Encode);
                    count++;
                    fileName = files[i].name;
                } else {
                    fileName = "Only can upload (Jpg, Png, Tiff)";
                }
            }

            const base64EncodedStrings = isMultiple ? [..._get(formState, `${formGroupName}.${formKey}`, []), ...currentFileList] : currentFileList;
            formAction.changeInputFn(formGroupName, formKey, "", base64EncodedStrings);
        }
    };

    const getBase64 = (file) => {
        return new Promise((resolve) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            };
        });
    };

    const deleteImage = (value) => {
        if (isMultiple) {
            const temp = _get(formState, `${formGroupName}.${formKey}`, []).filter(element => element !== value);
            formAction.changeInputFn(formGroupName, formKey, "", temp);
        } else {
            formAction.changeInputFn(formGroupName, formKey, "", "");
        }
    };
    return (
        <Fragment>
            <div className={`defaultUploadComponentWrapper ${elementWrapperStyle}`}>
                <InputLabelWrapper
                    isRequired={isRequired}
                    lableText={labelText}
                />

                <div className={`defaultUploadSlider d-inline-flex ${uploadSliderStyle}`}>
                    {
                        (isMultiple) ? (
                            <Fragment>
                                {
                                    dataList.map((value, index) => {
                                        return (index < checkUploadedCount) ? (
                                            <FileDisplay
                                                key={index}
                                                isBase64Image={true}
                                                elementStyle={displayFileStyle}
                                                file={value}
                                                isDownload={false}
                                                isDelete={isDelete}
                                                uploadType={uploadType}
                                                onDeleteFn={deleteImage}
                                            />
                                        ) : null
                                    })
                                }

                                {
                                    (_size(dataList) < checkUploadedCount) ? (
                                        <div className={`upload-btn-wrapper ${uploadBtnStyle}`}>
                                            <div className="btn text-center" onClick={() => inputRef.current.click()}>
                                                <Icon iconClass={`${(uploadType === "image") ? "fas fa-camera" : "far fa-file-alt"}`} />
                                                <h5>Upload</h5>
                                            </div>
                                            <input ref={inputRef} type="file" multiple={isMultiple} disabled={isDisable} onChange={(event) => onChangeFn(event)} />
                                        </div>
                                    ) : null
                                }
                            </Fragment>
                        ) : (
                            <Fragment>

                                <FileDisplay
                                    isBase64Image={true}
                                    elementStyle={displayFileStyle}
                                    file={_get(formState, `${formGroupName}.${formKey}`, "")}
                                    isDownload={false}
                                    isDelete={isDelete}
                                    uploadType={uploadType}
                                    onDeleteFn={deleteImage}
                                    fileName={fileName}
                                />

                                {
                                    (_get(formState, `${formGroupName}.${formKey}`, "") === "") ? (
                                        <div className={`upload-btn-wrapper ${uploadBtnStyle}`}>
                                            <div className="btn text-center" onClick={() => inputRef.current.click()}>
                                                <Icon iconClass={`${(uploadType === "image") ? "fas fa-camera" : "far fa-file-alt"}`} />
                                                <h5>Upload</h5>
                                            </div>
                                            <input ref={inputRef} type="file" multiple={isMultiple} disabled={isDisable} onChange={(event) => onChangeFn(event)} />
                                        </div>
                                    ) : null
                                }
                            </Fragment>
                        )
                    }
                </div>

                <FormHelperTextWrapper
                    inputError={getValueByFilter(_get(formState, `${formGroupName}._errors`, []), ["property", formKey], "message", "", "")}
                    helperText={helperText}
                />
            </div>
        </Fragment>
    )
};

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Upload Component Base64
 * --------------------------------------------
 */

UploadComponentBase64.propTypes = {
    /** Element Wrapper css class */
    elementWrapperStyle: PropTypes.string,
    /** Upload slider element css class */
    uploadSliderStyle: PropTypes.string,
    /** Display uploaded File css class */
    displayFileStyle: PropTypes.string,
    /** Upload button css class */
    uploadBtnStyle: PropTypes.string,
    /** Label text */
    labelText: PropTypes.string,
    /**  input field helper text */
    helperText: PropTypes.string,
    /** set required * mark */
    isRequired: PropTypes.bool,
    /** Is disable or not */
    isDisable: PropTypes.bool,
    /** form group name */
    formGroupName: PropTypes.string,
    /** Form state key */
    formKey: PropTypes.string,
    /** Is multiple files allowed */
    isMultiple: PropTypes.bool,
    /** Is delete files allowed */
    isDelete: PropTypes.bool,
    /** Upload type */
    uploadType: PropTypes.oneOf([uploadTypes.image, uploadTypes.file]),
    /** Max upload count */
    maxUploadCount: PropTypes.number,
    /** Valid file types */
    validTypes: PropTypes.array,
};

//-------------UploadComponentBase64--------------

export {
    UploadComponentBase64,
    uploadTypes,
    validFileTypes,
    validImageTypes
};
