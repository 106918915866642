import React, { useContext } from "react";

import { fineConfigurationsListAPI, licenceTypeListApi } from "../../../../../config/apiUrl.config";
import {
    DatePickerWithState,
    FormWrapper,
    InputBoxWithState,
    SelectBoxWithState, SubmitButton
} from "../../../../ui-components/ui-elements/form";
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { templateTypes } from "../../../../../config/template.config";
import { fineConfigurationsChargeTypes } from "../../../../../config/DBstatus.config";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { getDataByFormObject } from "../../../../../helpers/common-helpers/common.helpers";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { formErrorKey } from "../../../../../config/apiResponseKey";
import MonthRanges from "./MonthRanges";


const AddFineConfigurations = () => {
    const formKey = `${fineConfigurationsListAPI.key}_create`;

    const setMinDateFn = () => {
        let minDate = new Date();
        minDate.setHours(0);
        minDate.setMinutes(0);
        minDate.setSeconds(0);
        minDate = new Date(minDate.setDate(minDate.getDate() + 1));

        return minDate;
    }

    return (
        <FormWrapper
            setGroupName={formKey}
            setFormObject={{
                effective_from: setMinDateFn(),
                charge_type: "PRC",
                monthRanges: {
                    "0": {
                        "value_from": 1
                    }
                }
            }}
        >
            <UICard>
                <div className="col-md-6 col-sm-12 floatLeft">
                    <InputBoxWithState
                        uiType={templateTypes.bootstrap}
                        formGroupName={formKey}
                        inputName={"description"}
                        labelText="Fine Description"
                        isRequired={true}
                        inputPlaceholder={"Enter Fine Description"}
                        maxLength={100}
                    />
                </div>

                <div className="col-md-6 col-sm-12 floatLeft">
                    <SelectBoxWithState
                        uiType={templateTypes.bootstrap}
                        formGroupName={formKey}
                        inputName={"licence_type"}
                        labelText="Licence Type"
                        emptySelectOptionTxt={"Select Licence Type"}
                        isRequired={true}
                        apiUrl={`${licenceTypeListApi.url}/list?codes=NO&codes=NU`}
                        apiStateKey={licenceTypeListApi.key}
                        valueName={"name"}
                    />
                </div>

                <div className="col-md-6 col-sm-12 floatLeft">
                    <DatePickerWithState
                        uiType={templateTypes.bootstrap}
                        formGroupName={formKey}
                        labelText="Effective From"
                        inputName={"effective_from"}
                        isRequired={true}
                        minDate={setMinDateFn()}
                    />
                </div>

                <div className="col-md-6 col-sm-12 floatLeft">
                    <SelectBoxWithState
                        uiType={templateTypes.bootstrap}
                        formGroupName={formKey}
                        dataList={fineConfigurationsChargeTypes}
                        inputName={"charge_type"}
                        labelText="Charge Type"
                        emptySelectOptionTxt={"Select Charge Type"}
                        isRequired={true}
                    />
                </div>
            </UICard>

            <UICard cardHeading="Charges for Month Ranges">
                <MonthRanges formKey={formKey} />
            </UICard>
        </FormWrapper>
    )
}

const FineConfigurationCreateBtn = (props) => {
    const [, formAction] = useContext(FormContext);

    return (
        <SubmitButton
            btnText="Save"
            elementStyle="btnWrapper"
            startIcon={"far fa-save"}
            formGroupName={`${fineConfigurationsListAPI.key}_create`}
            dataTableKey={fineConfigurationsListAPI.key}
            isValidate={true}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Fine Configuration is successfully added.",
                    messageType: "success"
                }
            }}
            validationObject={{
                fileds: {
                    description: "Fine Description",
                    licence_type: "Licence Type",
                    effective_from: "Effective From",
                    charge_type: "Charge Type"
                },
                rules: {
                    description: "required|max:100",
                    licence_type: "required",
                    charge_type: "required",
                    effective_from: "required",
                },
                message: {
                    "description.required": "Fine Description is required",
                    "licence_type.required": "Licence Type is required",
                    "charge_type.required": "Effective From is required",
                    "effective_from.required": "Charge Type is required"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "post",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: "DATA_TABLE_LINKED_FORM",
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: fineConfigurationsListAPI.url,
                    key: fineConfigurationsListAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                let data = getDataByFormObject(formObject);
                const bulk_list = [];

                Object.keys(data.monthRanges).forEach((value) => {
                    bulk_list.push({
                        value_from: parseInt(data.monthRanges[value].value_from),
                        value_to: parseInt(data.monthRanges[value].value_to),
                        charge: parseFloat(data.monthRanges[value].charge)
                    });
                });

                return {
                    description: data.description,
                    licence_type: data.licence_type,
                    charge_type: data.charge_type,
                    effective_from: data.effective_from,
                    bulk_list: bulk_list
                };
            }}
            onResponseCallBackFn={(error) => {
                if (!error) {
                    props.onClosePopupFn(false);
                } else {
                    if (_get(error, 'data.errors.name', null) === formErrorKey) {
                        const errors = [];

                        _get(error, "data.errors.details", []).forEach((value) => {
                            const check = value.property.split(".");

                            if (check[0] === "bulk_list") {
                                errors.push({
                                    property: `monthRanges.${check[1]}.${check[2]}`,
                                    message: value.message
                                })
                            } else {
                                errors.push({
                                    property: value.property,
                                    message: value.message
                                })
                            }
                        });

                        formAction.setFormErrorFn(`${fineConfigurationsListAPI.key}_create`, errors);
                    }
                }
            }}
        />
    )
}

export {
    AddFineConfigurations,
    FineConfigurationCreateBtn
}