import { callApi } from "./callApi.helpers";
import { pdmtListAPI } from "../../config/apiUrl.config";

const getProvinceFn = (event, formAction, formGroupName) => {
    if (event.value == "null") {
        formAction.changeInputFn(formGroupName, "province_name", "", "")
    } else {
        callApi(`${pdmtListAPI.url}/list?service_id=${event.value}`)
            .headers(true)
            .method("get")
            .send((err, result) => {

                if (err) {
                    console.log("???????error???????......", err)
                } else {
                    if (result) {
                        formAction.changeInputFn(formGroupName, "province_name", "", result.data.data[0].service.name)
                    } else {
                        console.log("???????error2???????..", result)
                    }
                }
            })
    }
}

export {
    getProvinceFn
}