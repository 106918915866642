/*
 * @Author: gajani Kangesan 
 * @Date: 2022-12-20 10:30:06 
 * @Last Modified by: gajani Kangesan
 * @Last Modified time: 2022-12-20 13:00:00
 */


import React, { Fragment, useContext } from 'react';
import { SubmitButton } from "../../../../ui-components/ui-elements/form";
import { CreateReasonAPI } from "../../../../../config/apiUrl.config";
import { getDataByFormObject,isEmptyValue } from '../../../../../helpers/common-helpers/common.helpers';
import { apiResponseStoringType } from "../../../../../config/apiResponseKey";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers"
import { UIContext } from "../../../../ui-components/context-providers/UIContext.provider";

const emptyFn = (...para) => undefined;
const CustomButton = (props) => {
    const [, uiAction] = useContext(UIContext);
    const close = () => {
        props.onClosePopupFn();
    }

    return (
        <Fragment>
            <SubmitButton
                btnText={props.uiType === "edit" ? "Update" : props.uiType === "remove" ? "Yes" : "Save"}
                formGroupName={props.formGroupName}
                startIcon={props.uiType === "remove" ? 'fas fa-check' : props.uiType === "create" ? 'far fa-save' : 'far fa-edit'}
                tooltip={props.uiType === "edit" ? "Update" : props.uiType === "remove" ? "" : "Create"}
                dataTableKey={props.dataTableKey}
                isValidate={props.uiType === "remove" ? false : true}
                validationObject={{
                    fileds: {
                        "name": "reason",

                    },
                    rules: {
                        "name": "required|max:100",

                    },
                }}
                mapValidationErrorObj={{
                    "name": "Reason already exists.",

                }}
                flashMessages={{
                    "success": {
                        status: true,
                        message: `Reason is successfully ${props.uiType === "edit" ? "updated." : props.uiType === "remove" ? "removed." : "saved."}`,
                        messageType: "success"
                    }
                }}
                callApiObject={{
                    isSetHeaders: (props.uiType === "create" || props.uiType === "edit") ? true : undefined,
                    method: (props.uiType === "create" ? "post" : "put"),
                }}
                apiDataStoringObject={{
                    setLoader: true,
                    storingType: apiResponseStoringType.dateTableLinkedForm
                }}
                onGetAPIEndPointFn={(formState) => {
                    return {
                        url: (props.uiType === "create" ? (`${CreateReasonAPI.url}`) : (props.uiType === "edit" ? (`${CreateReasonAPI.url}/update-reason/` + formState.id) : (`${CreateReasonAPI.url}/delete-reason/` + formState.id))),
                        key: CreateReasonAPI.key

                    }
                }}
                onChangeRequestBodyFn={(fromObject) => {
                    fromObject.id = props.uiType === "edit" ? fromObject.id : undefined;
                    return getDataByFormObject({
                        ...fromObject,
                        name:fromObject.name,
                        add_dealer:(isEmptyValue(fromObject.add_dealer)?false:fromObject.add_dealer) ,
                        add_licence_receipt_transaction: (isEmptyValue(fromObject.add_licence_receipt_transaction)?false:fromObject.add_licence_receipt_transaction) ,
                        add_licence_transaction: (isEmptyValue(fromObject.add_licence_transaction)?false:fromObject.add_licence_transaction) ,
                        add_vehicle: (isEmptyValue(fromObject.add_vehicle)?false:fromObject.add_vehicle) ,
                        change_serial_number:  (isEmptyValue(fromObject.change_serial_number)?false:fromObject.change_serial_number) ,
                        dealer_change_serial_number:(isEmptyValue(fromObject.dealer_change_serial_number)?false:fromObject.dealer_change_serial_number),
                        dealers_cancel_licence_receipt_transaction:(isEmptyValue(fromObject.dealers_cancel_licence_receipt_transaction)? false :fromObject.dealers_cancel_licence_receipt_transaction) ,
                        dealers_licence_reissuance:  (isEmptyValue(fromObject.dealers_licence_reissuance)?false:fromObject.dealers_licence_reissuance) ,
                        dealers_licence_reprint: (isEmptyValue(fromObject.dealers_licence_reprint)?false:fromObject.dealers_licence_reprint) ,
                        licence_receipt_reprint:  (isEmptyValue(fromObject.licence_receipt_reprint)?false:fromObject.licence_receipt_reprint) ,
                        licence_type_change:  (isEmptyValue(fromObject.licence_type_change)?false:fromObject.licence_type_change) ,
                        licence_waive_off:  (isEmptyValue(fromObject.licence_waive_off)?false:fromObject.licence_waive_off) ,
                        other_additions_deductions: (isEmptyValue(fromObject.other_additions_deductions)?false:fromObject.other_additions_deductions) ,
                        request_licence_invalid_documents: (isEmptyValue(fromObject.request_licence_invalid_documents)?false:fromObject.request_licence_invalid_documents) ,
                        request_licence_licence_arrears:  (isEmptyValue(fromObject.request_licence_licence_arrears)?false:fromObject.request_licence_licence_arrears) ,
                        reverse_last_transaction: (isEmptyValue(fromObject.reverse_last_transaction)?false:fromObject.reverse_last_transaction) ,
                        set_effective_date:   (isEmptyValue(fromObject.set_effective_date)?false:fromObject.set_effective_date) ,
                        update_dealer:  (isEmptyValue(fromObject.update_dealer)?false:fromObject.update_dealer) ,
                        update_vehicle:  (isEmptyValue(fromObject.update_vehicle)?false:fromObject.update_vehicle) ,
                        vehicle_black_list:  (isEmptyValue(fromObject.vehicle_black_list)?false:fromObject.vehicle_black_list) ,
                        vehicle_ineligible:  (isEmptyValue(fromObject.vehicle_ineligible)?false:fromObject.vehicle_ineligible) ,
                        vehicle_transfer: (isEmptyValue(fromObject.vehicle_transfer)?false:fromObject.vehicle_transfer),
                        vehicle_status_change:isEmptyValue(fromObject.vehicle_status_change)?false:fromObject.vehicle_status_change,
                        webpayment_reconciliation:isEmptyValue(fromObject.webpayment_reconciliation)?false:fromObject.webpayment_reconciliation,
                    })

                }}
                onResponseCallBackFn={(response, error) => {

                    if (response.length === undefined && error === undefined && response.data.errors === undefined) {
                        if (props.uiType === "remove") {
                            uiAction.setFlashMessage({
                                status: true,
                                message: "Reason is successfully deleted",
                                messageType: "success"
                            });
                        }
                        props.onClosePopupFn();
                    }
                    

                }}

            />
        </Fragment>
    );
}
export {
    CustomButton
}