import React, { Fragment } from 'react';
import { permissions } from '../../../config/permission.config';
import authorized from '../../hoc-middlewares/Authorized';
import { Navigate } from '../../ui-components/ui-elements/common/Navigate';
import ManageVehicleCondition from './pages/ManageVehicleCondition';

const VehicleConditionRoutes = () => {
    return (
        <Fragment>
            <Navigate
                exact={true}
                path="/vehicleCondition"
                routeKey={"vehicleCondition"}
                component={authorized(ManageVehicleCondition)}
                routePermissions={permissions.VEHICLE_CONDITION_MANAGE.permissions}
            />
        </Fragment>
    );
};

export default VehicleConditionRoutes;