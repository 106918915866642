/*
 * @Author: Binara Medawatta
 * @Date: 2020-12-30 15:10:18
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-30 11:41:57
 */
import React, { Fragment, useContext } from "react";
import {
  AuditDetail,
  UICard,
  ViewDetailBlock,
} from "../../../../ui-components/ui-elements/common/BaseElements";
import {
  CheckBoxWithState,
  FormWrapper,
} from "../../../../ui-components/ui-elements/form";
import { DataTable } from "../../../../ui-components/ui-elements/table/DataTable";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { contactsArrayToObject } from "../../../../../helpers/common-helpers/contact.helpers";
import { ContactTypeCode } from "../../../../../config/enum.config";
import {
  userDefaultDelegationAPI,
  provincesOfficeByServiceIdAPI,
  userDelegationAPI,
  provincesOfficesListAPI,
  AuthorityListApi,
  AuthorityByServiceIdApi,
} from "../../../../../config/apiUrl.config";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";

const View = (props) => {
  const [formState] = useContext(FormContext);
  return (
    <Fragment>
      <UICard cardHeading="User Information">
        <ViewDetailBlock
          label={"Title"}
          value={_get(props, `row.title.name`, "-")}
          labelCol={4}
        />
        <ViewDetailBlock
          label={"Full Name"}
          value={_get(props, `row.full_name`, "-")}
          labelCol={4}
        />
        <ViewDetailBlock
          label={"Designation"}
          value={_get(props, `row.user.designation`, "-")}
          labelCol={4}
        />
        <ViewDetailBlock
          label={"NIC"}
          value={_get(props, `row.nic`, "-")}
          labelCol={4}
        />
        <ViewDetailBlock
          label={"E-mail"}
          value={contactsArrayToObject(
            _get(props, `row.user.contact`, []),
            false,
            ContactTypeCode.EMAIL
          )}
          labelCol={4}
        />
        <ViewDetailBlock
          label={"Mobile Number"}
          value={contactsArrayToObject(
            _get(props, `row.user.contact`, []),
            false,
            ContactTypeCode.MOBILE
          )}
          labelCol={4}
        />
        <ViewDetailBlock
          label={"Status"}
          value={
            parseInt(_get(props, `row.user.active`, 0)) === 1
              ? "Active"
              : "Inactive"
          }
          labelCol={4}
        />
      </UICard>
      <div className="defaultHalfMarginTop" />

      <UICard cardHeading="User Login Information">
        <ViewDetailBlock
          label={"Username"}
          value={_get(props, `row.user.user_name`, "-")}
          labelCol={4}
        />
        <ViewDetailBlock
          label={"Display Name"}
          value={_get(props, `row.display_name`, "-")}
          labelCol={4}
        />
        <div className="col-md-4 textContent floatLeft">Notify via </div>
        <div className="col-md-4 col-sm-4 floatLeft">
          <CheckBoxWithState
            isDisabled={true}
            labelText={"SMS"}
            inputName={"notifyViaSMS"}
            inputValue={_get(props, `row.user.sms_notification`, "-") === 1}
          />
        </div>
        <div className="col-md-2 col-sm-4 floatLeft">
          <CheckBoxWithState
            isDisabled={true}
            labelText={"E-mail"}
            inputName={"notifyViaEmail"}
            inputValue={_get(props, `row.user.email_notification`, "-") === 1}
          />
        </div>
      </UICard>
      <div className="defaultHalfMarginTop" />
      <UICard cardHeading="Base Location">
        <FormWrapper
          setGroupName={provincesOfficeByServiceIdAPI.key}
          apiUrl={
            _get(props, `row.user.base_pdmt_service_id`, null)
              ? `${provincesOfficeByServiceIdAPI.url}/${_get(
                  props,
                  `row.user.base_pdmt_service_id`,
                  "0"
                )}`
              : null
          }
          onDestroyUnsetFormObject={true}
          onRebuildResponseFn={(response) => {
            return {
              province: response,
              _onLoad: false,
            };
          }}
        >
          <ViewDetailBlock
            label={"Province Office"}
            value={
              _get(formState, `${provincesOfficeByServiceIdAPI.key}.province`, null)?.name ?? "-"
            }
            labelCol={4}
          />
        </FormWrapper>

        <FormWrapper
          setGroupName={AuthorityByServiceIdApi.key}
          apiUrl={
            _get(props, `row.user.base_authority_service_id`, null)
              ? `${AuthorityByServiceIdApi.url}/${_get(
                  props,
                  `row.user.base_authority_service_id`,
                  "0"
                )}`
              : null
          }
          onDestroyUnsetFormObject={true}
          onRebuildResponseFn={(response) => {
            return {
              authority: response,
              _onLoad: false,
            };
          }}
        >
          <ViewDetailBlock
            label={"Authority"}
            value={
              _get(formState, `${AuthorityByServiceIdApi.key}.authority`, null)?.name ?? "-"
            }
            labelCol={4}
          />
        </FormWrapper>
      </UICard>
      <div className="defaultHalfMarginTop" />

      <UICard cardHeading="Default Role & Location">
        <FormWrapper
          setGroupName={userDefaultDelegationAPI.key}
          apiUrl={`${userDefaultDelegationAPI.url}/${props.row.user.id}`}
          onDestroyUnsetFormObject={true}
          onRebuildResponseFn={(response) => {
            return {
              internal_role: _get(response, "internal.role.name", ""),
              province: _get(response, "internal.authority.province.name", "-"),
              internal_authority_id: _get(
                response,
                "internal.authority[0].service.name",
                null
              ),
              internal_pdmt_id: _get(
                response,
                "internal.authority[0].pdmt[0].service.name",
                null
              ),
              authority: _get(
                response,
                "internal.authority.service[0].name",
                ""
              ),
              admin_role: _get(response, "admin.role.name", ""),
              admin_pdmt_id: _get(response, "admin.pdmt[0].service.name", null),
              admin_service: _get(
                response,
                "admin.authority.service.name",
                "-"
              ),
              is_admin: Object.keys(_get(response, "admin", {})).length !== 0,
              is_internal:
                Object.keys(_get(response, "internal", {})).length !== 0,
              _onLoad: false,
            };
          }}
        >
          <div className="row">
            <div className="col-md-4 textContent floatLeft">
              Internal Module
            </div>
            <div className="col-md-4 col-sm-8 floatLeft">
              <CheckBoxWithState
                inputName={"is_internal"}
                isDisabled={true}
                formGroupName={userDefaultDelegationAPI.key}
              />
            </div>

            <div className="fullWidthDiv">
              <ViewDetailBlock
                label={"User Role"}
                value={_get(
                  formState,
                  `${userDefaultDelegationAPI.key}.internal_role`,
                  ""
                )}
                labelCol={4}
              />
              <ViewDetailBlock
                label={"Province Office"}
                value={_get(
                  formState,
                  `${userDefaultDelegationAPI.key}.internal_pdmt_id`,
                  ""
                )}
                labelCol={4}
              />
              <ViewDetailBlock
                label={"Authority"}
                value={_get(
                  formState,
                  `${userDefaultDelegationAPI.key}.internal_authority_id`,
                  ""
                )}
                labelCol={4}
              />
            </div>
          </div>
          <div className="row defaultPaddingTop">
            <div className="col-md-4 textContent floatLeft">Admin Module</div>
            <div className="col-md-4 col-sm-8 floatLeft ">
              <CheckBoxWithState
                inputName={"is_admin"}
                isDisabled={true}
                formGroupName={userDefaultDelegationAPI.key}
              />
            </div>
          </div>

          <div className="fullWidthDiv">
            <ViewDetailBlock
              label={"User Role"}
              value={_get(
                formState,
                `${userDefaultDelegationAPI.key}.admin_role`,
                ""
              )}
              labelCol={4}
            />
            <ViewDetailBlock
              label={"Province Office"}
              value={_get(
                formState,
                `${userDefaultDelegationAPI.key}.admin_pdmt_id`,
                ""
              )}
              labelCol={4}
            />
          </div>
        </FormWrapper>
      </UICard>
      <div className="defaultHalfMarginTop" />
      <DataTable
        tableName="Additional Roles & Locations"
        dataTableKey={"chargeRange"}
        apiUrl={`${userDelegationAPI.url}/${props.row.user.id}/list?default_delegation=false&userType=INT`}
        isSetAction={false}
        tableHeaderList={[
          {
            displayName: "Province",
            key: "authority",
            isSort: false,
            onPrintFn: ({ orgData }) =>
              _get(orgData, "pdmt[0].code", null)
                ? _get(orgData, "pdmt[0].service.name", "-")
                : _get(orgData, "authority[0].pdmt[0].service.name", "-"),
          },
          {
            displayName: "Authority",
            key: "orgData.authority[0].service.name",
            isSort: false,
          },

          {
            displayName: "User Role",
            key: "role.name",
            isSort: false,
          },
          {
            displayName: "Status",
            key: "role.status",
            isSort: false,
            onPrintFn: ({ status }) => (status === 1 ? "Active" : "Inactive"),
          },
          {
            displayName: "Expire Date",
            key: "expiry_at",
            isSort: false,
            onPrintFn: (raw) => dateObjectToString(raw.expiry_at, "dd/mm/YY"),
          },
          {
            displayName: "Reason",
            key: "updated_reason",
            isSort: false,
          },
        ]}
      />
      <div className="defaultHalfMarginTop" />

      <div className="fullWidthDiv defaultPaddingTop">
        <AuditDetail
          createdById={_get(props, `row.user.created_by`, "")}
          createdLocationId={_get(props, `row.user.updated_location`, "")}
          createdAt={dateObjectToString(_get(props, `row.user.created_at`, ""),"dd/mm/YY HH:MM")}
          createdRoleId={_get(props, `row.user.created_role_id`, "")}
          updatedById={_get(props, `row.user.updated_by`, "")}
          updatedLocationId={_get(props, `row.user.updated_location`, "")}
          updatedAt={dateObjectToString(_get(props, `row.user.updated_at`, ""),"dd/mm/YY HH:MM")}
          updatedRoleId={_get(props, `row.user.updated_role_id`, "")}
          labelCol="4"
        />
      </div>
    </Fragment>
  );
};

export { View };
