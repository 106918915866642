/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-07-14 10:18:15
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-30 11:38:22
 */

import React from "react";
import { find } from "lodash";
import {
  UICard,
  AuditDetail,
  ViewDetailBlock,
} from "../../../../ui-components/ui-elements/common/BaseElements";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { FormWrapper } from "../../../../ui-components/ui-elements/form";
import { contactsArrayToObject } from "../../../../../helpers/common-helpers/contact.helpers";
import { ContactTypeCode } from "../../../../../config/enum.config";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";

const ViewInsuranceCompany = (props) => {
  const numberOfLabelCol = 6;
  return (
    <div className="col-md-12 col-sm-12 ">
      <UICard>
        <FormWrapper setGroupName={"test2"}>
          <ViewDetailBlock
            label={"Insurance Company Code "}
            value={_get(props, "row.code", "")}
            labelCol={numberOfLabelCol}
          />
          <ViewDetailBlock
            label={"Insurance Company Name in English"}
            value={_get(props, "row.service.name", "")}
            labelCol={numberOfLabelCol}
          />
          <ViewDetailBlock
            label={"Insurance Company Name in Sinhala"}
            value={_get(props, "row.service.name_si", "")}
            labelCol={numberOfLabelCol}
          />
          <ViewDetailBlock
            label={"Insurance Company Name in Tamil"}
            value={_get(props, "row.service.name_ta", "")}
            labelCol={numberOfLabelCol}
          />
          <ViewDetailBlock
            label={"Business Registration Number"}
            value={_get(props, "row.br_number", "")}
            labelCol={numberOfLabelCol}
          />
          <ViewDetailBlock
            label={"Status"}
            value={
              _get(props, "row.service.active", 1) === 1 ? "Active" : "Inactive"
            }
            labelCol={numberOfLabelCol}
          />
          <ViewDetailBlock
            label={"E-mail"}
            value={contactsArrayToObject(
              _get(props, `row.service.contacts`, []),
              false,
              ContactTypeCode.EMAIL
            )}
            labelCol={numberOfLabelCol}
          />
          <ViewDetailBlock
            label={"Contact Number"}
            value={contactsArrayToObject(
              _get(props, `row.service.contacts`, []),
              false,
              ContactTypeCode.PHONE
            )}
            labelCol={numberOfLabelCol}
          />
        </FormWrapper>
      </UICard>

      {/* <UICard cardHeading="API Configurations" elementStyle="defaultMarginTop">
        <ViewDetailBlock
          label={"Configurations"}
          value={_get(props, "row.configurations", "")}
          labelCol={numberOfLabelCol}
        />
      </UICard> */}
      <AuditDetail
        createdById={_get(props, `row.service.created_by`, "")}
        updatedById={_get(props, `row.service.updated_by`, "")}
        createdLocationId={_get(props, `row.service.created_location`, "")}
        updatedLocationId={_get(props, `row.service.updated_location`, "")}
        createdAt={dateObjectToString(
          _get(props, `row.service.created_at`, ""),
          "dd/mm/YY HH:MM"
        )}
        updatedAt={dateObjectToString(
          _get(props, `row.service.updated_at`, ""),
          "dd/mm/YY HH:MM"
        )}
        createdRoleId={_get(props, `row.service.created_role_id`, '')}
        updatedRoleId={_get(props, `row.service.updated_role_id`, '')}
        labelCol={6}
      />
    </div>
  );
};

export { ViewInsuranceCompany };
