import React, { Fragment } from "react";
import { ContactTypeCode } from "../../../../../config/enum.config";
import { contactsArrayToObject } from "../../../../../helpers/common-helpers/contact.helpers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { AuditDetail, UICard, ViewDetailBlock } from "../../../../ui-components/ui-elements/common/BaseElements";

const ViewCertifyingOfficerPopup = (props) => {
  return (
    <Fragment>
      <UICard elementStyle="defaultMarginTop" cardHeading={"Officer Information"}>
        <ViewDetailBlock
          labelCol={3}
          label={"Full Name"}
          value={_get(props, `row.full_name`, "")}
        />
        <ViewDetailBlock
          labelCol={3}
          label={"Contact Number"}
          value={contactsArrayToObject(
            _get(props, `row.user.contact`, []),
            false,
            ContactTypeCode.MOBILE
          )}
        />
        <ViewDetailBlock
          labelCol={3}
          label={"Email"}
          value={contactsArrayToObject(
            _get(props, `row.user.contact`, []),
            false,
            ContactTypeCode.EMAIL
          )}
        />
        <ViewDetailBlock
          labelCol={3}
          label={"Status"}
          value={_get(props, `row.user.active`, "") === 1 ? "Active" : "Inactive"}
        />
        <div className="col-md-6 defaultHalfPaddingTop">
          <strong>Signature: </strong>{" "}
          <img
            src={_get(props, `row.signature`, "")}
            className={"img-thumbnail select-image"}
          />
        </div>

      </UICard>
      <UICard elementStyle="defaultMarginTop" cardHeading={"User Login Information"}>
        <ViewDetailBlock
          labelCol={3}
          label={"Username"}
          value={_get(props, `row.user.user_name`, '')}
        />
        <ViewDetailBlock
          labelCol={3}
          label={"Display Name"}
          value={_get(props, `row.display_name`, '')}
        />
        <ViewDetailBlock
          labelCol={3}
          label={"Device ID"}
          value={_get(props, `row.device_id`, '')}
        />
      </UICard>

      <div className="row defaultPaddingTopBottom">
        <div className="col-12">
          <AuditDetail
            labelCol="4"
            createdAt={dateObjectToString(_get(props, `row.user.created_at`, ""), "dd/mm/YY HH:MM")}
            updatedAt={dateObjectToString(_get(props, `row.user.updated_at`, ""), "dd/mm/YY HH:MM")}
            createdById={_get(props, `row.user.created_by`, "")}
            updatedById={_get(props, `row.user.updated_by`, "")}
            createdLocationId={_get(props, `row.user.created_location`, "")}
            updatedLocationId={_get(props, `row.user.updated_location`, "")}
            createdRoleId={_get(props, `row.user.created_role_id`, '')}
            updatedRoleId={_get(props, `row.user.updated_role_id`, '')}
          />
        </div>
      </div>

    </Fragment>
  );
};

export { ViewCertifyingOfficerPopup };
