/*
 * @Author: Sujith
 * @Date: 2020-10-20 16:11:46
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2021-06-29 19:52:44
 */
import React, { useContext, useEffect, useState } from "react";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import {
  DataTable,
  actionBtnTypes
} from "../../../ui-components/ui-elements/table/DataTable";
import { SubmitButton } from "../../../ui-components/ui-elements/form";
import { ConfigForm } from "./includes/Form";
import { ConfigView } from "./includes/view";
import {
  generalConfigAPI,
} from "../../../../config/apiUrl.config";
import { getDataByFormObject } from "../../../../helpers/common-helpers/common.helpers";
import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { permissions } from "../../../../config/permission.config";
import { CoreContext } from "../../core/context-providers/CoreContext.provider";
import { useLocation } from "react-router-dom";

const GenaralConfiguration = () => {
  const [row, setRow] = useState({});
  const callbackFunction = (childData) => {
    setRow(childData)
  }
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <DashboardTemplate
      pageTitle="Manage General Configuration"
      breadCrumbList={[
        { urlTo: "/", displayName: "Home" },
        { urlTo: "", displayName: "Other" },
        { urlTo: "", displayName: "General Configuration" },
      ]}
    >
      <DataTable
        tableName={"General Configurations"}
        isSetAction={true}
        isSetTableFooter={false}
        apiUrl={generalConfigAPI.url}
        dataTableKey={generalConfigAPI.key}
        tableHeaderList={[
          {
            displayName: "Configuration Name",
            key: "name",
            isSort: false,
          },
        ]}
        actionTypeList={[

          {
            actionType: actionBtnTypes.edit,
            actionBtn: {
              tooltip: "Edit",
              "permissions": (permissions.RENEWABLE_PERIOD_LICENSE_TYPES_EDIT.permissions ||
                permissions.FINE_EXEMPTION_VEHICLE_REGISTRATION_EDIT.permissions ||
                permissions.FINE_EXEMPTION_LICENSE_RENEWAL_EDIT.permissions ||
                permissions.LICENSE_TYPE_CHANGE_TO_NON_USER_EDIT.permissions ||
                permissions.VET_EXEMPTION_MANUFACTURED_YEAR_EDIT.permissions ||
                permissions.WORKFLOW_LICENSE_WITH_ARREARS_EDIT.permissions ||
                permissions.ONLINE_TEMPORARY_LICENSE_RECEIPT_EDIT.permissions ||
                permissions.SERVICE_CHARGE_CONFIG_EDIT.permissions)
            },
            modelContentElement: ConfigForm,
            dialogPopupProps: {
              isFullScreen: false,
              closeButton: {
                btnText: "Cancel",
                startIcon: "far fa-times-circle",
              },
              isCustomButton: true,
              customButtonComponent: CustomButtons,
              isConfirmationPopup: true,
              dialogTitle: `Edit ${row.name}`,
            },
            parentToModelProps: {
              formKey: generalConfigAPI.key + "_edit",
              dataTableKey: generalConfigAPI.key,
              parentCallback: (value) => callbackFunction(value)
            },

            // checkBeforeActionRenderFn: (rawData) => {
            //   const MyDate = new Date();
            //   var MyDateString;
            //   MyDateString = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2);
            //   if (rawData.row.effective_from) {
            //     const slicedFromDate = rawData.row.effective_from.slice(0, 10);
            //     return slicedFromDate > MyDateString;
            //   } else {
            //     return true
            //   }
            // }
          },
          {
            actionType: actionBtnTypes.view,
            actionBtn: {
              
              tooltip: "View",
              "permissions": (permissions.RENEWABLE_PERIOD_LICENSE_TYPES_VIEW.permissions ||
                permissions.FINE_EXEMPTION_VEHICLE_REGISTRATION_VIEW.permissions ||
                permissions.FINE_EXEMPTION_LICENSE_RENEWAL_VIEW.permissions ||
                permissions.LICENSE_TYPE_CHANGE_TO_NON_USER_VIEW.permissions ||
                permissions.VET_EXEMPTION_MANUFACTURED_YEAR_VIEW.permissions ||
                permissions.WORKFLOW_LICENSE_WITH_ARREARS_VIEW.permissions ||
                permissions.ONLINE_TEMPORARY_LICENSE_RECEIPT_VIEW.permissions ||
                permissions.SERVICE_CHARGE_CONFIG_VIEW.permissions)
            },
            modelContentElement: ConfigView,
            dialogPopupProps: {
              isFullScreen: false,
              isCustomButton: false,
              dialogTitle: `View ${row.name}`,
              closeButton: {
                btnText: "Close",
                startIcon: "far fa-times-circle",
              },
            },
            parentToModelProps: {
              parentCallback: (value) => callbackFunction(value)
            },
          },
          // {
          //   actionType: actionBtnTypes.other,
          //   actionBtn: { tooltip: "Download", icon: "fa fa-download" },
          // },
        ]}
      />
    </DashboardTemplate>
  );
};
const emptyFn = (...para) => undefined;
const CustomButtons = (props) => {
  const [coreState, coreAction] = useContext(CoreContext);
  const check = (type) => {
    let dependency = "";
    if (
      type === "FIN_EXMPT" ||
      type === "LCN_NUSR" ||
      type === "VET_FSTR"
    ) {
      dependency = "required|numeric|max:2";
    } else if (type === "WEB_MESG") {
      dependency = "required|max:200";
    } else if (type === "SER_CHRG") {
      dependency = "required";
    } else {
      dependency = "required";
    }

    return dependency;
  };

  const valueCheck = (type) => {
    let value = "";
    if (
      type === "FIN_EXMPT" ||
      type === "LCN_NUSR" ||
      type === "VET_FSTR"
    ) {
      value = "Days Count";
    } else if (type === "WEB_MESG") {
      value = "Message";
    } else if (type === "SER_CHRG") {
      value = "Service Charge";
    } else if (
      type === "VET_EXMPT" ||
      type === "LCN_WARR"
    ) {
      value = "Year";
    } else if (type === "TMP_RENW") {
      value = "Calendar Months";
    }

    return value;
  };

  const valueType = (type) => {
    let valueType = "";
    if (
      type === "FIN_EXMPT" ||
      type === "LCN_NUSR" ||
      type === "VET_FSTR"
    ) {
      valueType = "Days Type";
    }

    return valueType;
  };
  //console.log("++++++++++++++++++++++====",valueType(_get(props, "row.configuration_code", null)))
  //console.log("++++++++++++++++++++++===oo=",valueCheck(_get(props, "row.configuration_code", null)))
  return (
    <SubmitButton
      btnText={"Update"}
      startIcon={"far fa-edit"}
      elementStyle="btnWrapper"

      formGroupName={
        _get(props, `row.configuration_code`, "") ===
          "RNW_LITY"
          ? `dataTableResponses.${generalConfigAPI.key}.results`
          : props.formGroupName
      }
      tooltip={"Edit"}
      dataTableKey={generalConfigAPI.key}
      isValidate={
        _get(props, "row.configuration_code", null) ===
          "RNW_LITY"
          ? false
          : true
      }
      flashMessages={{
        success: {
          status: true,
          message: `${_get(
            props,
            `row.name`,
            ""
          )} ${_get(props, "row.configuration_code", null) === 'RNW_LITY'
            || _get(props, "row.configuration_code", null) === 'VET_FSTR'
            || _get(props, "row.configuration_code", null) === 'FIN_EXMPT' ? ('are successfully updated.') : ('is successfully updated.')}`,
          messageType: "success",
        }
      }}
      validationObject={{
        fileds: {
          value: valueCheck(_get(props, "row.configuration_code", null)),
          value_type: valueType(_get(props, "row.configuration_code", null)),
        },
        rules: {
          value: check(_get(props, "row.name", null)),
          value_type: "required",
        },
      }}
      onGetAPIEndPointFn={(formState) => {
        return {
          url:
            formState.id === undefined
              ? `${generalConfigAPI.url}/RNW_LITY`
              : `${generalConfigAPI.url}/${formState.configuration_code}`,
          key: generalConfigAPI.key,
        };
      }}
      onChangeRequestBodyFn={({ ...formObject }) => {
        return getDataByFormObject({
          ...formObject,
          0: {
            configuration_code: "RNW_LITY",
            id: "renewLicenceType",
            name: "Renewable Period for Licence Types",
            array:_get(coreState,'dataTableResponses.RenePerForLicTyp.results',[])
          }
        });
      }}
      callApiObject={{
        isSetHeaders: true,
        method: "put",
      }}
      onResponseCallBackFn={(error, response) => {
        if (!error) {
          props.onClosePopupFn();
        }
      }}
    />
  );
};
export default GenaralConfiguration;
