import React, { Fragment , useContext} from "react";

import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { SubmitButton } from "../../../ui-components/ui-elements/form";
import { actionBtnTypes, DataTable } from "../../../ui-components/ui-elements/table/DataTable";
import { VETCompanyForm } from "./includes/VETCompanyForm";
import { SearchElement } from "./includes/VETCompanyElements";
import { ViewVETCompany } from './includes/ViewVETCompany';
import { getDataByFormObject } from "../../../../helpers/common-helpers/common.helpers";
import { apiResponseStoringType } from "../../../../config/apiResponseKey";
import { vetCompanyAPI } from "../../../../config/apiUrl.config";
import { permissions } from "../../../../config/permission.config";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const emptyFn = (...para) => undefined;
const CustomButtonEdit = (props) => {

    const close = () => {
        props.onClosePopupFn();
    }

    return (
        <Fragment>
            <SubmitButton
            
                btnText={props.uiType === "Edit" ? "Update" : "Save"}
                startIcon={
                    props.uiType !== "Edit"
                    ? "far fa-save"
                    : "far fa-edit"
                  }
                elementStyle="btnWrapper"
                formGroupName={props.formGroupName}
                isValidate={true}
                flashMessages={{
                    "success": {
                        status: true,
                        message:  `VET Company is successfully ${props.uiType === "Edit" ? "updated." : "saved."}`,
                        messageType: "success"
                    }
                }}
                mapValidationErrorObj={{
                        name: "VET Company Name in English already exists",
                        name_si: "VET Company Name in Sinhala already exists",
                        name_ta: "VET Company Name in Tamil already exists",
                        br_number: "Business Registration Number already exists",
                }}
                validationObject={{
                    fileds: {
                        br_number: "Business Registration Number",
                        code: "VET Code",
                        name: "VET Company Name in English",
                        name_si: "VET Company Name in Sinhala",
                        name_ta: "VET Company Name in Tamil" ,
                        contact_number: "Contact Number" ,
                        api_mapping: "API Mapping",
                        active: "Status"
                    },
                    rules: {
                        code: "required|max:4",
                        br_number: "required|max:30",
                        name:"required|max:50",
                        name_si: "required|max:50",
                        name_ta: "required|max:50",
                        contact_number: "required|max:10|numeric|min:9",
                        api_mapping: "required|max:50",
                        active: "required"
                    },
                    message: {},
                }}
                dataTableKey={vetCompanyAPI.key}
                callApiObject={{
                    isSetHeaders: true,
                    multipart: false,
                    method: (props.uiType === "Add" ? ("post") : ("put")),
                    onUpload: false,
                }}
                apiDataStoringObject={{
                    setLoader: true,
                    storingType: apiResponseStoringType.dateTableLinkedForm
                }}
                onGetAPIEndPointFn={(formState) => {
                    return {
                        url: (props.uiType === "Add" ? (vetCompanyAPI.url) : (`${vetCompanyAPI.url}/` + formState.id)),
                        key: vetCompanyAPI.key
                    }
                }}
                onChangeRequestBodyFn={(fromObject) => {
                    let data =  getDataByFormObject(fromObject);
                    return {
                        name: data.name,
                        code: data.code,
                        name_si: data.name_si,
                        name_ta: data.name_ta,
                        contact_number: data.contact_number,
                        active: data.active,
                        br_number: data.br_number,
                        api_mapping: data.code
                    };
                }}
                onResponseCallBackFn={(error, response) => {
                    if (!error) {
                        props.onClosePopupFn();
                    }
                }}
            />
        </Fragment>
    )
}

const VETCompany = () => {

    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    
    return (
        <DashboardTemplate
            pageTitle="Manage VET Company"
            breadCrumbList={[
                { urlTo: "/", displayName: "Home" },
                { urlTo: "", displayName: "Support Organisations" },
                { urlTo: "", displayName: "Manage VET Company" },
            ]}
        >
            <DataTable
                tableName="VET Companies"
                apiUrl={`${vetCompanyAPI.url}?sort_by=created_at|desc`}
                dataTableKey={vetCompanyAPI.key}
                isSetSearchFrom={true}
                defaultSearchFormObject={{
                    "name": "",
                    "code": "",
                    "active": null
                  }}
                searchFromComponent={<SearchElement dataTableKey={vetCompanyAPI.key} />}
                tableHeaderList={[
                    { displayName: "VET Code", key: "code", isSort: false },
                    { displayName: "VET Company Name", key: "service.name", isSort: false },
                    { displayName: "Status", key: "active", isSort: false, onPrintFn: (rawData) => ((rawData.service.active == 1) ? "Active" : "Inactive" )},
                ]}
                actionTypeList={[
                    {
                        actionType: actionBtnTypes.create,
                        actionBtn: { "permissions": permissions.VET_COMPANY_ADD.permissions },
                        modelContentElement: VETCompanyForm,
                        dialogPopupProps: {
                            isFullScreen: false,
                            isSaveButton: false,
                            isValidate: true,
                            dialogTitle: "Add VET Company",
                            closeButton: {
                                btnText: "Cancel",
                                startIcon: "far fa-times-circle",
                            },
                            customButtonComponentProps:{ btnText: "Save", uiType: "Add" },
                            isCustomButton: true,
                            isConfirmationPopup: true,
                            customButtonComponent: CustomButtonEdit,
                        },
                        parentToModelProps: {
                            formKey: vetCompanyAPI.key + "_create",
                            dataTableKey: vetCompanyAPI.key,
                            uiType: "Add"
                        }
                    },
                    {
                        actionType: actionBtnTypes.view,
                        actionBtn: {  tooltip: 'View', "permissions": permissions.VET_COMPANY_VIEW.permissions },
                        modelContentElement: ViewVETCompany,
                        dialogPopupProps: {
                            isFullScreen: false,
                            dialogTitle: "VET Company",
                            closeButton: {
                                btnText: "Close",
                                startIcon: "far fa-times-circle",
                            }
                        }
                    },
                    {
                        actionType: actionBtnTypes.edit,
                        actionBtn: { tooltip: 'Edit', "permissions": permissions.VET_COMPANY_EDIT.permissions },
                        modelContentElement: VETCompanyForm,
                        dialogPopupProps: {
                            isFullScreen: false,
                            dialogTitle: "Edit VET Company",
                            isSaveButton: false,
                            isValidate: true,
                            closeButton: {
                                btnText: "Cancel",
                                startIcon: "far fa-times-circle",
                            },
                            customButtonComponentProps:{ btnText: "Update", uiType: "Edit" },
                            isCustomButton: true,
                            isConfirmationPopup: true,
                            customButtonComponent: CustomButtonEdit
                        },
                        parentToModelProps: {
                            formKey: vetCompanyAPI.key + "_edit",
                            dataTableKey: vetCompanyAPI.key,
                            uiType: "Edit"
                        },
                    }
                ]}
            />
        </DashboardTemplate>
    );
};
export default VETCompany;