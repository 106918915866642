/*
 * @Author: Chanaka Wickramasinghe
 * @Description: Image/File Upload
 * @Date: 2020-06-24 13:24:56
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-07-29 10:24:03
 */

import React, { Fragment, useContext, useRef } from "react";
import PropTypes from "prop-types";

import { Icon } from "../../../../ui-components/ui-elements/common/BaseElements";
import { _size, _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { CoreContext } from "../../../core/context-providers/CoreContext.provider";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import {DealerFileDisplay }from "./DealerFileDisplay";
import { FormHelperTextWrapper, InputLabelWrapper } from "../../../../ui-components/ui-elements/form/form-includes/FormCoreIncludes";
import { getValueByFilter } from "../../../../../helpers/common-helpers/common.helpers";
import { InputButton } from "../../../../ui-components/ui-elements/form";
import { UIContext } from "../../../../ui-components/context-providers/UIContext.provider";



const DealerFileUploadComponent = ({
    elementWrapperStyle = "",
    uploadSliderStyle = "",
    displayFileStyle = "",
    uploadBtnStyle = "",
    isRequired = false,
    isDisable = false,
    labelText = "",
    helperText = "",
    formGroupName = "",
    formKey = "",
    formObjectAppendKey = "",
    isMultiple = true,
    isDownload = true,
    isDelete = true,
    isButtonOnly = true,
    isCompressImages = false,
    uploadApiUrl = "",
    getApiUrl = "",
    maxUploadCount = 3,
    btnStyle=""
}) => {

    const inputRef = useRef(null);
    const [, coreAction] = useContext(CoreContext);
    const [formState, formAction] = useContext(FormContext);
    const [, uiAction] = useContext(UIContext);
    const checkUploadedCount = (isMultiple) ? maxUploadCount : 1;
    const maxSize = 5
    const sizeUnit = 'MB'
    // _get(formState, `${formGroupName}.${formKey}_fileType`, "") === "" ? 'txt' : _get(formState, `${formGroupName}.${formKey}_fileType`, "").split('/')[1]
    let fileType = ''
    const onChangeFn = async (event) => {
        // console.log('FTYPE===', event.target.files['0'].type);

        if(event.target.files[0].size > (maxSize * 1000000)) {
           
            uiAction.setFlashMessage({
                status: true,
                message: `Maximum file size should be ${maxSize}(${sizeUnit}).`,
                messageType: "error",
            });
            return
        }
        formAction.changeInputFn(formGroupName, `${formKey}_fileType`, "", null);
        if(event.target.files['0'].type.split('/')[1] === 'csv') {

            const formData = new FormData();
    
       
            formData.append(formObjectAppendKey, event.target.files['0']);
   


            coreAction.sendRequestFn(uploadApiUrl)
            .isMultipart(true)
            .method("post")
            .body(formData)
            .setLoader(true)
            .setInitStoring("none", null)
            .send((error, result) => {
                if (result?._statue ===true) {

                        // console.log('ASSSS22222', result.data.data);
                        formAction.changeInputFn(formGroupName, formKey, "", _get(result.data, "data.name", ""));
                        formAction.changeInputFn(formGroupName, `${formKey}_url`, "", _get(result.data, "data.url", ""));
                        formAction.changeInputFn(formGroupName, `${formKey}_doc_references`, "", _get(result.data, "data.documentReference", ""));
                        formAction.changeInputFn(formGroupName, `${formKey}_id`, "", _get(result.data, "data.id", ""));
                } else if(error?._statue ===true) {
                    // console.log('ERRR===', error);
                    const errorObject = [..._get(formState, `${formGroupName}._errors`, [])];
                    errorObject.push({
                        "property": formKey,
                        "message": (_get(error.data, "errors.name", "") === "File too large") ? "File size is too large (max: 10MB)" : _get(error.data, "errors.details[0][message]", "Something went wrong")
                    });
                    uiAction.setFlashMessage({
                        status: true,
                        message: `Document upload failed.`,
                        messageType: "error",
                        });
                    formAction.setFormErrorFn(formGroupName, errorObject);
                }
            });
        } else {
            uiAction.setFlashMessage({
                status: true,
                message: `Invalid format. File format should be in .csv`,
                messageType: "error",
                });
        }
        // if(event.target.files['0'].type.split('/')[1] === 'vnd.openxmlformats-officedocument.wordprocessingml.document' || 
        // event.target.files['0'].type.split('/')[1] === 'png' || 
        // event.target.files['0'].type.split('/')[1] === 'jpg' || 
        // event.target.files['0'].type.split('/')[1] === 'jpeg' ||
        // event.target.files['0'].type.split('/')[1] === 'pdf' || 
        // event.target.files['0'].type.split('/')[1] === 'csv') {
        //     // console.log();

        
        
    };

    const deleteImage = (value) => {
        if (isMultiple) {
            const temp = _get(formState, `${formGroupName}.${formKey}`, []).filter(element => element !== value);
            formAction.changeInputFn(formGroupName, formKey, "", temp);
        } else {
            formAction.changeInputFn(formGroupName, formKey, "", "");
        }
    };

    return (
        <Fragment>
            {
    
                    <Fragment>
                        <div className={`upload-btn-wrapper ${uploadBtnStyle}`}>
                            <input ref={inputRef} type="file" multiple={false} disabled={isDisable} onChange={(event) => onChangeFn(event)} />
                        </div>

                        <div className={`float-left`}>
                            <InputButton
                                startIcon={"fa fa-file"}
                                btnText={"Choose File"}
                                onClickBtnFn={() => inputRef.current.click()}
                            />
                        </div>
                        
                    </Fragment>

            }
        </Fragment>
    )
};

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Upload Component
 * --------------------------------------------
 */

DealerFileUploadComponent.propTypes = {
    /** Element Wrapper css class */
    elementWrapperStyle: PropTypes.string,
    /** Upload slider element css class */
    uploadSliderStyle: PropTypes.string,
    /** Display uploaded File css class */
    displayFileStyle: PropTypes.string,
    /** Upload button css class */
    uploadBtnStyle: PropTypes.string,
    /** Label text */
    labelText: PropTypes.string,
    /**  input field helper text */
    helperText: PropTypes.string,
    /** set required * mark */
    isRequired: PropTypes.bool,
    /** Is disable or not */
    isDisable: PropTypes.bool,
    /** form group name */
    formGroupName: PropTypes.string,
    /** Form state key */
    formKey: PropTypes.string,
    /** Form object append key */
    formObjectAppendKey: PropTypes.string,
    /** Is multiple files allowed */
    isMultiple: PropTypes.bool,
    /** Is download allowed */
    isDownload: PropTypes.bool,
    /** Is delete files allowed */
    isDelete: PropTypes.bool,
    /** Upload api url */
    uploadApiUrl: PropTypes.string,
    /** get api url */
    getApiUrl: PropTypes.string,
    /** Max upload count */
    maxUploadCount: PropTypes.number,
    /** Valid file types */
    validTypes: PropTypes.array,
};

//-------------DealerFileUploadComponent--------------


export {
    DealerFileUploadComponent,
};
