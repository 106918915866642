/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-03-22 18:48:21 
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2021-06-01 15:15:54
 */

import React, {  Fragment,memo } from 'react';
import { UICard,emptyFun } from "../common/BaseElements";
import { defaultTemplateType } from "../../../../config/template.config";
import { apiResponseStoringType } from "../../../../config/apiResponseKey";
import {useTableState} from "../../../hooks/common-hooks/useDataTable.hook";
import {TableUIWrapper} from "./includes/TableElement";
import {CURDPops} from "./includes/ModelPopups";
import { actionBtnTypes, commonActionBtnPops } from "../../../../config/dataTable.config";



const TableWrapper=({
    apiUrl = null,
    dataTableKey = "",
    templateType = defaultTemplateType,
    tableName = "Data Table",
    elementWrapperStyle="",
    isSetAction = true,
    isSelectRow=false,
    isSetSearchFrom = false,
    searchFromComponent = null,
    onSetSearchObjectFn = false,
    specificComponents = {},
    defaultSearchFormObject = {},
    tableHeaderList = [],
    tableBodyList = [],
    isSetTableFooter=true,
    isSetTableHeader=true,
    onRowStyleFn=emptyFun,
    maxItemCount=2,
    actionTypeList = []
})=>{

    const [
        _apiUrl,_dataTableKey,_templateType,_tableName,
        _isSetAction,_isSetSearchFrom,_searchFromComponent,
        _specificComponents,_defaultSearchFormObject,
        _tableHeaderList,_actionTypeList,_screenType ,
        _responseUpdateStatus,_reloadStatus,_responseFetchingStatus,
        _pagingObject,_tableBody,_shortObject,_maxItemCount,_dataTableErros,_isSelectRow,requestAPIDataFn,dataTableState,
        _setDataTableStateFn,onChangeDataTableFieldValueFn,onSubmitCurdFn,onShortFn,_onSetSearchObjectFn
    ]=useTableState(
        apiUrl,dataTableKey,templateType,
        tableName,isSetAction,isSetSearchFrom,
        searchFromComponent,specificComponents,
        defaultSearchFormObject,tableHeaderList,tableBodyList,
        actionTypeList,maxItemCount,isSelectRow,onSetSearchObjectFn
    );

    return (
        <div className={`dataTableWrapper ${_templateType}DataTableBodyWrapper ${elementWrapperStyle}`} >
            <Fragment>
                <TableUIWrapper
                    _apiUrl={_apiUrl}
                    _tableName={_tableName}
                    _dataTableKey={_dataTableKey}
                    _isSetSearchFrom={_isSetSearchFrom}
                    _isSetAction={_isSetAction}
                    dataTableState={dataTableState}
                    _actionTypeList={_actionTypeList}
                    _tableHeaderList={_tableHeaderList}
                    _defaultSearchFormObject={_defaultSearchFormObject}
                    _searchFromComponent={_searchFromComponent}
                    _onSetSearchObjectFn={_onSetSearchObjectFn}
                    _specificComponents={_specificComponents}
                    _screenType={_screenType}
                    _pagingObject={_pagingObject}
                    _shortObject={_shortObject}
                    tableBody={_tableBody}
                    maxItemCount={_maxItemCount}
                    _responseFetchingStatus={_responseFetchingStatus}
                    _setDataTableStateFn={_setDataTableStateFn}
                    _dataTableErros={_dataTableErros}
                    isSetTableFooter={isSetTableFooter}
                    isSetTableHeader={isSetTableHeader}
                    isSelectRow={_isSelectRow}
                    onRowStyleFn={onRowStyleFn}
                    requestAPIDataFn={requestAPIDataFn}
                    onShortFn={onShortFn}
                    onChangeDataTableFieldValueFn={onChangeDataTableFieldValueFn}
                />
                <CURDPops
                    dataTableState={dataTableState}
                    actionTypeList={_actionTypeList}
                    setDataTableStateFn={_setDataTableStateFn}
                    onSubmitCurdFn={onSubmitCurdFn}
                />
            </Fragment>
        </div>
    )
}

const DataTable=({
    apiUrl = null,
    dataTableKey = "",
    templateType = defaultTemplateType,
    tableName = "",
    isSelectRow=false,
    isSetAction = true,
    isSetSearchFrom = false,
    isSetTableFooter=true,
    isSetTableHeader=true,
    searchFromComponent = null,
    onSetSearchObjectFn = false,
    specificComponents = {},
    defaultSearchFormObject = {},
    tableHeaderList = [],
    tableBodyList = [],
    actionTypeList = [],
    maxItemCount=2,
    elementWrapperStyle="",
    onRowStyleFn=emptyFun
})=>{
    return(
        <TableWrapper
            apiUrl = {apiUrl}
            dataTableKey = {dataTableKey}
            templateType = {templateType}
            tableName = {tableName}
            elementWrapperStyle={elementWrapperStyle}
            isSetAction = {isSetAction}
            isSetSearchFrom = {isSetSearchFrom}
            searchFromComponent = {searchFromComponent}
            onSetSearchObjectFn = {onSetSearchObjectFn}
            specificComponents = {specificComponents}
            defaultSearchFormObject = {defaultSearchFormObject}
            tableHeaderList = {tableHeaderList}
            tableBodyList = {tableBodyList}
            maxItemCount={maxItemCount}
            isSelectRow={isSelectRow}
            isSetTableFooter={isSetTableFooter}
            isSetTableHeader={isSetTableHeader}
            onRowStyleFn={onRowStyleFn}
            actionTypeList = {actionTypeList}
        />
    )
}

const RawIndex=({
    index=0,
    current_page=0,
    page_size=0,    
})=>{
    return (
        <Fragment>
            {(`${((current_page-1) * 10) + (index + 1)}`).padStart(2, '0')}
        </Fragment>
    )
}

const RawIndexMemo=memo(RawIndex);

export {
    DataTable,
    actionBtnTypes,
    commonActionBtnPops,
    apiResponseStoringType,
    RawIndex,
    RawIndexMemo
};
