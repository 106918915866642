export const Provinces = {
  WES: {
    code: "WES",
    name: "Western Province",
  },
  UVA: {
    code: "UVA",
    name: "Uva Province",
  },
  CEN: {
    code: "CEN",
    name: "Central Province",
  },
  NOR: {
    code: "NOT",
    name: "Northern Province",
  },
  SAB: {
    code: "SAB",
    name: "Sabaragamuwa Province",
  },
  SOU: {
    code: "SOU",
    name: "Southern Province",
  },
  NWE: {
    code: "NWE",
    name: "North Western Province",
  },
  NCE: {
    code: "NCE",
    name: "North Central Province",
  },
  EAS: {
    code: "EAS",
    name: "North Central Province",
  },
};

export const NotificationTypesList = {
  "Role_Change": "T01",
  "Serial_number_Change": "T02",
  "Approval_Requests": "T03",
  "ReOrder_Level_Reached": "T04",
  "user_transfer": "T05",
}

export const RoleLevelCode = {
  ADN_NTL: "ADN_NTL",
  ADN_PRV: "ADN_PRV",
};

export const ContactTypeCode = {
  PHONE: "PHO",
  EMAIL: "EMA",
  FAX: "FAX",
  MOBILE: "MOB",
};

export const PaymentCategoriesEnum = {
    CASH: "CSH",
    CREDITCARDS: "CRC",
    CHEQUE: "CHQ",
    EZCASH: "ECH",
    MCASH: "MCH"
};

export const ApplicabilityTypeCode = [
  {
    id: "GEN",
    value: "General",
  },
  {
    id: "VHS",
    value: "Vehicle Specific",
  },
];

export const ChargeEffectCode = [
  {
    id: "ADD",
    value: "Addition",
  },
  {
    id: "DED",
    value: "Deduction",
  },
];

export const ChargeTypeCode = [
  {
    id: "PRE",
    value: "Percentage",
  },
  {
    id: "FLR",
    value: "Flat Rate",
  },
];

export const WeightBaseCode = [
  {
    id: "GWT",
    value: "Gross Weight",
  },
  {
    id: "TWT",
    value: "Tare Weight",
  },
];

export const DateBaseCode = [
  {
    id: "VRD",
    value: "Vehicle Registration Date",
  },
  {
    id: "CUD",
    value: "Custom Date",
  },
];

export const DateDurationMode = [
  {
    id: "AFTER",
    value: "AFTER",
  },
  {
    id: "BEFORE",
    value: "BEFORE",
  },
];

export const DateDurationCode = [
  {
    id: "MON",
    value: "Month",
  },
  {
    id: "YER",
    value: "Year",
  },
];

export const OperationTypes = [
  {
    id: "normal_free_non_user_licence",
    value: "Normal/ Free/ Non-User Licence",
  },
  {
    id: "dealers_licence",
    value: "Dealer's Licence",
  },
  {
    id: "carnet_licence",
    value: "Carnet Licence",
  },
  {
    id: "quotation",
    value: "Quotation",
  },
  {
    id: "temporary_licence",
    value: "Temporary Licence",
  },
  {
    id: "arrears_only",
    value: "Arrears Only",
  },
];

export const OperationTypeCode = {
  NO:{
    id: "normal_licence",
    value: "Normal Licence",
  },
  FE:{
    id: "free_licence",
    value: "Free Licence",
  },
  NU:{
    id: "non_user_licence",
    value: "Non-User Licence",
  },
  DE:{
    id: "dealers_licence",
    value: "Dealer's Licence",
  },
  CA:{
    id: "carnet_licence",
    value: "Carnet Licence",
  },
  QT:{
    id: "quotation",
    value: "Quotation",
  },
  TM:{
    id: "temporary_licence",
    value: "Temporary Licence",
  },
  AO:{
    id: "arrears_only",
    value: "Arrears Only",
  },
  WL:{
    id: "web_licence",
    value: "Web Licence",
  },
};

export const LicenceTypeCode = [
  'NO',//NORMAL
  'FE',//FREE
  'NU',//NONUSER
  'DE',//DEALER
  'CA',//CARNET
  'QT',//QUOTATION
  'WL',//WEB
  'AO',//ARREARS
  'TM',//TEMPORARY
]

export const WorkFlowReason =[
  {
    id:"add_dealer",
    name:"Add Dealer"
  },
  {
    id:"add_licence_receipt_transaction",
    name:"Add Licence Receipt Transaction"
  },
  {
    id:"add_licence_transaction",
    name:"Add Licence Transaction"
  },
  {
    id:"add_vehicle",
    name:"Add Vehicle"
  },
  {
    id:"change_serial_number",
    name:"Change Serial Number"
  },
  {
    id:"dealer_change_serial_number",
    name:"Dealer Change Serial Number"
  },
  {
    id:"dealers_cancel_licence_receipt_transaction",
    name:"Dealers Cancel Licence Receipt Transaction"
  },
  {
    id:"dealers_licence_reissuance",
    name:"Dealers Licence Reissuance"
  },
  {
    id:"dealers_licence_reprint",
    name:"Dealers Licence Reprint"
  },
  {
    id:"licence_receipt_reprint",
    name:"Licence Receipt Reprint"
  },
  {
    id:"licence_type_change",
    name:"Licence Type Change"
  },
  {
    id:"licence_waive_off",
    name:"Licence Waive Off"
  },
  {
    id:"other_additions_deductions",
    name:"Other Additions/Deductions"
  },
  {
    id:"request_licence_invalid_documents",
    name:"Request Licence Invalid Documents"
  },
  {
    id:"request_licence_licence_arrears",
    name:"Request Licence Arrears"
  },
  {
    id:"reverse_last_transaction",
    name:"Reverse Last Tansaction"
  },
  {
    id:"set_effective_date",
    name:"Set Effective Date"
  },
  {
    id:"update_dealer",
    name:"Update Dealer"
  },
  {
    id:"update_vehicle",
    name:"Update Vehicle"
  },
  
  {
    id:"vehicle_transfer",
    name:"Vehicle Transfer"
  },
  {
    id:"vehicle_status_change",
    name:"Vehicle Status Change"
  },
  
  
  
]

export const OtherReason =[
  {
    id:"vehicle_black_list",
    name:"Vehicle Blacklist"
  },
  {
    id:"vehicle_ineligible",
    name:"Vehicle Ineligibility"
  },
  {
    id:"webpayment_reconciliation",
    name:"Web Payment Reconciliation"
  },
]

export const ReasonList =[
  {
    id:"add_dealer",
    name:"Add Dealer"
  },
  {
    id:"add_licence_receipt_transaction",
    name:"Add Licence Receipt Transaction"
  },
  {
    id:"add_licence_transaction",
    name:"Add Licence Transaction"
  },
  {
    id:"add_vehicle",
    name:"Add Vehicle"
  },
  {
    id:"change_serial_number",
    name:"Change Serial Number"
  },
  {
    id:"dealer_change_serial_number",
    name:"Dealer Change Serial Number"
  },
  {
    id:"dealers_cancel_licence_receipt_transaction",
    name:"Dealers Cancel Licence Receipt Transaction"
  },
  {
    id:"dealers_licence_reissuance",
    name:"Dealers Licence Reissuance"
  },
  {
    id:"dealers_licence_reprint",
    name:"Dealers Licence Reprint"
  },
  {
    id:"licence_receipt_reprint",
    name:"Licence Receipt Reprint"
  },
  {
    id:"licence_type_change",
    name:"Licence Type Change"
  },
  {
    id:"licence_waive_off",
    name:"Licence Waive Off"
  },
  {
    id:"other_additions_deductions",
    name:"Other Additions/Deductions"
  },
  {
    id:"request_licence_invalid_documents",
    name:"Request Licence Invalid Documents"
  },
  {
    id:"request_licence_licence_arrears",
    name:"Request Licence Arrears"
  },
  {
    id:"reverse_last_transaction",
    name:"Reverse Last Tansaction"
  },
  {
    id:"set_effective_date",
    name:"Set Effective Date"
  },
  {
    id:"update_dealer",
    name:"Update Dealer"
  },
  {
    id:"update_vehicle",
    name:"Update Vehicle"
  },
  
  {
    id:"vehicle_transfer",
    name:"Vehicle Transfer"
  },
  {
    id:"vehicle_status_change",
    name:"Vehicle Status Change"
  },
  {
    id:"vehicle_black_list",
    name:"Vehicle Blacklist"
  },
  {
    id:"vehicle_ineligible",
    name:"Vehicle Ineligibility"
  },
  {
    id:"webpayment_reconciliation",
    name:"Web Payment Reconciliation"
  },
  
  
  
]
