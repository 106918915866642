import React from "react";
import { FormWrapper, InputBoxWithState, SelectBoxWithState } from "../../../../ui-components/ui-elements/form";

const SearchElement = ({ dataTableKey = "" }) => {
    const formKey = `${dataTableKey}_search`;

    return (
        <FormWrapper setGroupName={formKey}> 
            <div className="row">
                <div className="col-md-4">
                    <InputBoxWithState
                        labelText={"Prefix"}
                        inputPlaceholder={"Prefix"}
                        formGroupName={formKey}
                        inputName={"prefix"}
                        maxLength= "50"
                    />
                </div>

                <div className="col-md-4">
                    <InputBoxWithState
                        labelText={"Name"}
                        inputPlaceholder={"Name"}
                        formGroupName={formKey}
                        inputName={"name"}
                        maxLength= "50"
                    />
                </div>

                <div className="col-md-4">
                    <SelectBoxWithState
                        formGroupName={formKey}
                        labelText={"Status"}
                        emptySelectOptionTxt={"All"}
                        dataList={[
                            {
                            id: "true",
                            value: "Active",
                            },
                            {
                            id: "false",
                            value: "Inactive",
                            },
                        ]}
                        inputName={"active"}
                        isEnableAllOption={true}
                    />
                </div>
            </div>
        </FormWrapper>
    )
}

export {
    SearchElement
}