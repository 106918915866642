/* * @Last Modified by: Migara Gunarathne
 * @Last Modified time: 2021-04-30 15.20
*/
import React, { useContext, useEffect } from "react";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { FormWrapper, InputBoxWithState, SelectBoxWithState, DatePickerWithState, } from "../../../../ui-components/ui-elements/form";
import { templateTypes } from "../../../../../config/template.config";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { AuthorityListApi, pdmtOfficesListAPI, holidayTypesListAPI, pdmtListAPI } from "../../../../../config/apiUrl.config";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { callApi } from "../../../../../helpers/common-helpers/callApi.helpers";
import { useState } from "react";
import { isEmpty } from "lodash";

const HolidayForm = (props) => {
  const [formState, formAction] = useContext(FormContext);
  const formKey = _get(props, "fromParent.formKey", "");
  const today = new Date();
  const type = _get(props, "row.holiday_type_code.id");

  useEffect(() => {
    if (_get(formState, `${formKey}._uiFormDescription`, undefined) === 'getHolidayConfigurationListKEY_create') {
      if (_get(formState, `${formKey}.holiday_type_code`, undefined) === 'NAT') {
        formAction.changeInputFn(formKey, "pdmt_id", "");
        formAction.changeInputFn(formKey, "authority_id", "");
      }
      if (_get(formState, `${formKey}.holiday_type_code`, undefined) === 'PRO') {
        formAction.changeInputFn(formKey, "authority_id", "");
        formAction.changeInputFn(formKey, "pdmt_id", "");
      }
      if (_get(formState, `${formKey}.holiday_type_code`, undefined) === 'AUT') {
        formAction.changeInputFn(formKey, "pdmt_id", "");
        formAction.changeInputFn(formKey, "authority_id", "");
      }
      if (_get(formState, `${formKey}.holiday_type_code`, undefined) === "null") {
        formAction.changeInputFn(formKey, "pdmt_id", "");
        formAction.changeInputFn(formKey, "authority_id", "");
      }
    }

    if (_get(formState, `${formKey}._uiFormDescription`, undefined) === 'getHolidayConfigurationListKEY_edit') {
      if (_get(formState, `${formKey}.holiday_type_code`, undefined) === 'NAT') {
        formAction.changeInputFn(formKey, "pdmt_id", "");
        formAction.changeInputFn(formKey, "authority_id", "");
      }
      if (_get(formState, `${formKey}.holiday_type_code`, undefined) === 'PRO') {
        formAction.changeInputFn(formKey, "authority_id", "");
      }
      if (_get(formState, `${formKey}.holiday_type_code`, undefined) === "null") {
        formAction.changeInputFn(formKey, "pdmt_id", "");
        formAction.changeInputFn(formKey, "authority_id", "");
      }
    }



  }, [_get(formState, `${formKey}.holiday_type_code`, undefined)]);
  const [authStatus, authAction] = useContext(AuthContext);
  const userRoleLevelCode = _get(authStatus, "authUser.role_level_code", "");
  const service_Id = _get(authStatus, "authUser.service_id", "");
  const provinceCode = _get(authStatus, "authUser.province_code", "");
  const pdmtId = _get(authStatus, "authUser.pdmt_id", "");
  const [provinceName, setProvinceName] = useState("")
  useEffect(() => {
   if(_get(formState, `${formKey}.holiday_type_code`, "")==='PRO' || _get(formState, `${formKey}.holiday_type_code`, "")==='AUT'){
    callApi(
      (userRoleLevelCode === "ADN_NTL") ?
        `${pdmtListAPI.url}/list?sort_by=name|asc` :
        `${pdmtListAPI.url}/list?sort_by=name|asc&service_id=${service_Id}`)
      .method("get")
      .headers(true)
      .send((error, result) => {
        if (!error) {
          setProvinceName(result.data.data[0].service.name)
          formAction.changeInputFn(formKey, "pdmt_id", "", result.data.data[0].id)
        }
      });
   }
  

  }, [_get(formState, `${formKey}.holiday_type_code`, ""), pdmtId]);

  console.log("pdmt", _get(formState, `${formKey}.pdmt_id`, ""));

  return (

    <FormWrapper
      setGroupName={formKey}
      formGroupLinkWith={formKey}
      setFormObject={{
        id: _get(props, "row.id", ""),
        name: _get(props, "row.name", ""),
        description: _get(props, "row.description", ""),
        active: _get(props, "row.active", "1"),
        holiday_type_code: _get(props, "row.holiday_type_code.code", ""),
        authority_id: _get(props, "row.authority_id.id", ""),
        pdmt_id: _get(props, "row.pdmt_id.id", ""),
        to_date: _get(props, "row.to_date", today),
        from_date: _get(props, "row.from_date", today),
      }}
      onDestroyUnsetFormObject={true}
    >
      <InputBoxWithState
        uiType={templateTypes.bootstrap}
        formGroupName={formKey}
        inputName={"name"}
        labelText="Name"
        isRequired={true}
        maxLength={50}
      />

      <InputBoxWithState
        uiType={templateTypes.bootstrap}
        formGroupName={formKey}
        inputName={"description"}
        labelText="Description"
        isMultiline={true}
        isRequired={false}
        rows={3}
        maxLength={200}
      />

      <SelectBoxWithState
        uiType={templateTypes.bootstrap}
        formGroupName={formKey}
        apiUrl={(userRoleLevelCode === "" || userRoleLevelCode === null || userRoleLevelCode === undefined || userRoleLevelCode === "ADN_AUT") ? null : holidayTypesListAPI.url + "?user_role_level=" + userRoleLevelCode}
        apiStateKey={holidayTypesListAPI.key}
        keyName="code"
        valueName="name"
        inputName={"holiday_type_code"}
        labelText="Type"
        isRequired={true}
        emptySelectOptionTxt="Select the holiday type"
      />

      {_get(formState, `${formKey}.holiday_type_code`, undefined) === 'PRO' || _get(formState, `${formKey}.holiday_type_code`, undefined) === 'AUT' ?

        <SelectBoxWithState
          uiType={templateTypes.bootstrap}
          formGroupName={formKey}
          apiUrl={pdmtListAPI.url + "/list?sort_by=name|asc"}
          apiStateKey={pdmtListAPI.key}
          keyName="id"
          valueName="service.name"
          inputName={"pdmt_id"}
          labelText="Province office"
          isRequired={_get(formState, `${formKey}.holiday_type_code`, undefined) === 'PRO' ? true : _get(formState, `${formKey}.holiday_type_code`, undefined) === 'AUT' ? true : false}
          isDisabled={true}
          emptySelectOptionTxt={provinceName}
        /> : null}

      {_get(formState, `${formKey}.holiday_type_code`, undefined) === 'AUT' ?
        <SelectBoxWithState
          uiType={templateTypes.bootstrap}
          formGroupName={formKey}
          apiUrl={(_get(formState, `${formKey}.pdmt_id`, undefined) === null || _get(formState, `${formKey}.pdmt_id`, undefined) === "" || _get(formState, `${formKey}.pdmt_id`, undefined) === undefined) ? null : AuthorityListApi.url + "?sort_by=name|asc&active=true&pdmt_id=" + _get(formState, `${formKey}.pdmt_id`, undefined)}
          apiStateKey={AuthorityListApi.key}
          keyName="id"
          valueName="service.name"
          inputName={"authority_id"}
          labelText="Authority"
          isRequired={_get(formState, `${formKey}.holiday_type_code`, undefined) === 'AUT' ? true : false}
          isDisabled={_get(formState, `${formKey}.holiday_type_code`, undefined) === 'AUT' ? false : true}
          emptySelectOptionTxt="Select the authority"
        /> : null}

      <SelectBoxWithState
        uiType={templateTypes.bootstrap}
        formGroupName={formKey}
        dataList={[
          {
            id: "1",
            value: "Active",
          },
          {
            id: "0",
            value: "Inactive",
          },
        ]}
        emptySelectOptionTxt="Please select the status"
        inputName={"active"}
        isRequired={true}
        labelText="Status"

      />

      <div className="col-12">
        <UICard cardHeading="Holiday Range" elementStyle="defaultMarginTop">

          <div className="row">
            <div className="col-md-6 col-sm-12 ">
              <DatePickerWithState
                formGroupName={formKey}
                labelText="From"
                inputName={"from_date"}
                minDate={today}
              />
            </div>

            <div className="col-md-6 col-sm-12 ">
              <DatePickerWithState
                formGroupName={formKey}
                labelText="To"
                inputName={"to_date"}
                minDate={_get(formState, `${formKey}.from_date`, undefined)}
                minDateMessage="‘To Date’ should be greater than or equal to the ‘From Date’."
              />
            </div>
          </div>
        </UICard>
      </div>
    </FormWrapper>
  );
};

export {
  HolidayForm
};