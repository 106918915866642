import React, { useContext, useEffect } from "react";
import { authorityTypeListAPI, districtListAPI, provinceListAPI, pdmtListAPI, transactionTypeListAPI, licenseTypeListAPI, operationTypesListAPI } from "../../../../../config/apiUrl.config";
import { ContactTypeCode } from "../../../../../config/enum.config";
import { contactsArrayToObject } from "../../../../../helpers/common-helpers/contact.helpers";
import { _find, _findindex, _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { CheckBoxWithState, FormWrapper, InputBoxWithState, NumberInputBoxWithState, SelectBoxWithState } from "../../../../ui-components/ui-elements/form";
import { templateTypes } from "../../../../../config/template.config";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { isEmptyValue } from "../../../../../helpers/common-helpers/common.helpers";

const AddAuthority = (props) => {
    const formKey = props.fromParent.formKey;
    const [authStatus, authAction] = useContext(AuthContext);
    const [formState, formAction] = useContext(FormContext);
    const userRoleLevelCode = _get(authStatus, "authUser.role_level_code", "");
    const service_Id = _get(authStatus, "authUser.service_id", "");
    const provinceCode = _get(authStatus, "authUser.province_code", "");
    const pdmtId = _get(authStatus, "authUser.pdmt_id", "");
    const disable = isEmptyValue(provinceCode) ? false : true;

    const getTransactionTypesAllowed = (categories = [], allowedTypes = []) => {
        let temp = {};
        categories.forEach((value) => {
            const index = _findindex(allowedTypes, (e) => value.code === e.operation_type);
            temp[value.id] = index !== -1;
            // if (formKey === "authorityListAPIKey_create") {
            //     if (value.code === "LC" || value.code === "AO" || value.code === "TM") {
            //         temp[value.id] = true;
            //     }
            // }
        });
        return temp;
    }

    const getLicenceTypesAllowed = (categories = [], allowedTypes = []) => {
        let temp = {};
        categories.forEach((value) => {
            const index = _findindex(allowedTypes, (e) => value.id === e.licence_type_id);
            temp[value.id] = index !== -1;
            // if (formKey === "authorityListAPIKey_create") {
            //     if (value.code === "CA" || value.code === "FE" || value.code === "NU" || value.code === "NO" || value.code === "DE") {
            //         temp[value.id] = true;
            //     }
            // }
        });
        return temp;
    }

    const getTransactionAllowedError = () => {
        const check = _find(_get(formState, `${formKey}._errors`, []), (e) => e.property === "service_operation_types_allowed");

        if (check !== undefined) {
            return {
                status: true,
                message: check.message
            };
        } else {
            return {
                status: false
            }
        }
    };

    const getLicenceTypeError = () => {
        const check = _find(_get(formState, `${formKey}._errors`, []), (e) => e.property === "service_licence_types_allowed");

        if (check !== undefined) {
            return {
                status: true,
                message: check.message
            };
        } else {
            return {
                status: false
            }
        }
    };

    const checkQuotation = (eventData, code) => {

        if (code === "QT" && eventData === true) {
            const licenceType = _get(formState, `authorityListAPIKey_create1.service_licence_types_allowed`, [])
            const freeLicence = _find(licenceType, (e) => e.code === "FE");
            const nonUser = _find(licenceType, (e) => e.code === "NU");
            const normalLicence = _find(licenceType, (e) => e.code === "NO");

            formAction.changeInputFn("authorityListAPIKey_create1", "", `authorityListAPIKey_create1.check_licence_type[${freeLicence.id}]`, true);
            formAction.changeInputFn("authorityListAPIKey_create1", "", `authorityListAPIKey_create1.check_licence_type[${nonUser.id}]`, true);
            formAction.changeInputFn("authorityListAPIKey_create1", "", `authorityListAPIKey_create1.check_licence_type[${normalLicence.id}]`, true);

        }

    }

    useEffect(() => {
        if (_get(formState, `${formKey}._uiFormDescription`, undefined) === "authorityListAPIKey_create") {
            formAction.changeInputFn(formKey, "district_code", "");
            formAction.changeInputFn(formKey, "prefix", "111");
        }
    }, [_get(formState, `${formKey}.pdmt_id`, undefined)]);
    
    return (
        <UICard>

            <FormWrapper
                setGroupName={formKey}
                apiUrl={operationTypesListAPI.url}
                onRebuildResponseFn={(response) => {
                    return {
                        id: _get(props, "row.id", ""),
                        code: _get(props, "row.code", ""),
                        pdmt_id: _get(props, "row.province.id", isEmptyValue(pdmtId) ? "" : pdmtId),
                        province_code: _get(props, "row.province.code", ""),
                        district_code: _get(props, "row.district.code", ""),
                        prefix: _get(props, "row.prefix", "").replace(_get(props, "row.district.code", ""), ""),
                        name: _get(props, "row.service.name", ""),
                        name_si: _get(props, "row.service.name_si", ""),
                        name_ta: _get(props, "row.service.name_ta", ""),
                        authority_type_code: _get(props, "row.authority_type.code", ""),
                        contact_number_1: contactsArrayToObject(_get(props, `row.service.contacts`, []), false, ContactTypeCode.MOBILE),
                        contact_number_2: contactsArrayToObject(_get(props, `row.service.contacts`, []), false, ContactTypeCode.PHONE),
                        email: contactsArrayToObject(_get(props, `row.service.contacts`, []), false, ContactTypeCode.EMAIL),
                        fax: contactsArrayToObject(_get(props, `row.service.contacts`, []), false, ContactTypeCode.FAX),
                        line1: _get(props, "row.service.addresses[0].line1", ""),
                        line1_si: _get(props, "row.service.addresses[0].line1_si", ""),
                        line1_ta: _get(props, "row.service.addresses[0].line1_ta", ""),
                        line2: _get(props, "row.service.addresses[0].line2", ""),
                        line2_si: _get(props, "row.service.addresses[0].line2_si", ""),
                        line2_ta: _get(props, "row.service.addresses[0].line2_ta", ""),
                        city: _get(props, "row.service.addresses[0].city", ""),
                        city_si: _get(props, "row.service.addresses[0].city_si", ""),
                        city_ta: _get(props, "row.service.addresses[0].city_ta", ""),
                        postal_code: _get(props, "row.service.addresses[0].postal_code", ""),
                        active: _get(props, "row.service.active", "1"),
                        licence_reorder_level: _get(props, "row.licence_reorder_level", ""),
                        service_operation_types_allowed: response,
                        check_transaction_type: { ...getTransactionTypesAllowed(response, _get(props, "row.service.operationTypesAllowed", [])) },
                        "_onLoad": false
                    }
                }}
                onDestroyUnsetFormObject={false}
            >
                <FormWrapper
                    setGroupName={"authorityListAPIKey_create1"}
                    apiUrl={licenseTypeListAPI.url}
                    onRebuildResponseFn={(response) => {
                        return {
                            service_licence_types_allowed: response,
                            check_licence_type: { ...getLicenceTypesAllowed(response, _get(props, "row.service.licenceTypes", [])) },
                            "_onLoad": false
                        }
                    }}
                    onDestroyUnsetFormObject={false}

                >
                    <div className="row">
                        <div className="col-12">
                            <table className={"fullWidthDiv"}>
                                <tr>
                                    <th className="textRight defaultPaddingRight">Authority Code <sup className="text-danger">*</sup> </th>
                                    <td className={"defaultPaddingRight"}>
                                        {props.fromParent.uiType === "EDIT" ? (
                                            _get(formState, `${formKey}.code`, "")
                                        ) : (<InputBoxWithState
                                            formGroupName={formKey}
                                            inputName={"code"}
                                            maxLength={"12"}
                                            inputPlaceholder={"Authority Code"}
                                        />)}

                                    </td>
                                </tr>

                                <tr>
                                    <th className="textRight defaultPaddingRight">Province office <sup className="text-danger">*</sup> </th>
                                    <td className={"defaultPaddingRight"}>
                                        <SelectBoxWithState
                                            formGroupName={formKey}
                                            inputName={"pdmt_id"}
                                            apiUrl={(userRoleLevelCode === "ADN_NTL") ? pdmtListAPI.url + "/list?sort_by=name|asc" : pdmtListAPI.url + "/list?sort_by=name|asc&service_id=" + service_Id}
                                            apiStateKey={pdmtListAPI.key}
                                            keyName="id"
                                            valueName="service.name"
                                            emptySelectOptionTxt={"Select Province office"}
                                            isDisabled={disable}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <th className="textRight defaultPaddingRight">District <sup className="text-danger">*</sup> </th>
                                    <td className={"defaultPaddingRight"}>
                                        <SelectBoxWithState
                                            formGroupName={formKey}
                                            inputName={"district_code"}
                                            apiUrl={_get(formState, `${formKey}.pdmt_id`) === undefined ? null : (districtListAPI.url + '?province_id=' + _get(formState, `${formKey}.pdmt_id`))}
                                            apiStateKey={districtListAPI.key}
                                            isDisabled={_get(formState, `${formKey}.pdmt_id`, undefined) === '' ? true : _get(formState, `${formKey}.pdmt_id`, undefined) === 'null' ? true : false}
                                            emptySelectOptionTxt={"Select District"}
                                            keyName="code"
                                            valueName="name"
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <th className="textRight defaultPaddingRight">Authority Prefix  <sup className="text-danger">*</sup> </th>
                                    <td className={"defaultPaddingRight"}>
                                        <InputBoxWithState
                                            formGroupName={formKey}
                                            inputName={"district_code"}
                                            labelText=""
                                            isDisabled={true}
                                        />
                                    </td>
                                    <td className={"defaultPaddingLeftRight"}>
                                        <InputBoxWithState
                                            formGroupName={formKey}
                                            inputName={"prefix"}
                                            maxLength={"9"}
                                            inputPlaceholder={"Authority Prefix"}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th className={"defaultHalfPadding"} />
                                </tr>

                                <tr>
                                    <th></th>
                                    <td className={"defaultPaddingRight"}>English</td>
                                    <td className={"defaultPaddingLeftRight"}>Sinhala</td>
                                    <td className={"defaultPaddingLeft"}>Tamil</td>
                                </tr>

                                <tr>
                                    <th className="textRight defaultPaddingRight">Authority Name
                                        <sup className="text-danger">*</sup></th>
                                    <td className={"defaultPaddingRight"}>
                                        <InputBoxWithState
                                            formGroupName={formKey}
                                            inputName={"name"}
                                            maxLength={"50"}
                                            inputPlaceholder={"Authority Name in English"}
                                        />
                                    </td>
                                    <td className={"defaultPaddingLeftRight"}>
                                        <InputBoxWithState
                                            formGroupName={formKey}
                                            inputName={"name_si"}
                                            maxLength={"50"}
                                            inputPlaceholder={"Authority Name in Sinhala"}
                                        />
                                    </td>
                                    <td className={"defaultPaddingLeft"}>
                                        <InputBoxWithState
                                            formGroupName={formKey}
                                            inputName={"name_ta"}
                                            maxLength={"50"}
                                            inputPlaceholder={"Authority Name in Tamil"}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <th className="textRight defaultPaddingRight">Authority Type <sup className="text-danger">*</sup> </th>
                                    <td className={"defaultPaddingRight"}>
                                        <SelectBoxWithState
                                            formGroupName={formKey}
                                            inputName={"authority_type_code"}
                                            apiUrl={`${authorityTypeListAPI.url}?is_creation=true`}
                                            apiStateKey={authorityTypeListAPI.key}
                                            emptySelectOptionTxt={"Select Authority Type"}
                                            keyName="code"
                                            valueName="name"
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <th className="textRight defaultPaddingRight">Status <sup className="text-danger">*</sup> </th>
                                    <td className={"defaultPaddingRight"}>
                                        <SelectBoxWithState
                                            formGroupName={formKey}
                                            inputName={"active"}
                                            emptySelectOptionTxt={"Select Status"}
                                            dataList={[
                                                {
                                                    id: "1",
                                                    value: "Active"
                                                },
                                                {
                                                    id: "0",
                                                    value: "Inactive"
                                                }
                                            ]}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <th className="textRight defaultPaddingRight">Contact Number 1 <sup className="text-danger">*</sup> </th>
                                    <td className={"defaultPaddingRight"}>
                                        <NumberInputBoxWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName={formKey}
                                            inputName={"contact_number_1"}
                                            inputStatePath={`${formKey}.contact_number_1`}
                                            isAllowNegative={false}
                                            isThousandSeparator={false}
                                            isAllowLeadingZeros={true}
                                            decimalScale={0}
                                            isAllowedFn={(value) => {
                                                const checkValue = value.value.toString();
                                                return checkValue.length <= 10;
                                            }}
                                            inputPlaceholder={"Contact Number 1"}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <th className="textRight defaultPaddingRight">Contact Number 2  </th>
                                    <td className={"defaultPaddingRight"}>
                                        <NumberInputBoxWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName={formKey}
                                            inputName={"contact_number_2"}
                                            inputStatePath={`${formKey}.contact_number_2`}
                                            isAllowNegative={false}
                                            isThousandSeparator={false}
                                            isAllowLeadingZeros={true}
                                            decimalScale={0}
                                            isAllowedFn={(value) => {
                                                const checkValue = value.value.toString();
                                                return checkValue.length <= 10;
                                            }}
                                            inputPlaceholder={"Contact Number 2"}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <th className="textRight defaultPaddingRight">E-mail  </th>
                                    <td className={"defaultPaddingRight"}>
                                        <InputBoxWithState
                                            formGroupName={formKey}
                                            inputName={"email"}
                                            inputStatePath={`${formKey}.email`}
                                            maxLength={"50"}
                                            inputPlaceholder={"E-mail"}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <th className="textRight defaultPaddingRight">Fax  </th>
                                    <td className={"defaultPaddingRight"}>
                                        <InputBoxWithState
                                            formGroupName={formKey}
                                            inputName={"fax"}
                                            inputStatePath={`${formKey}.fax`}
                                            maxLength={"10"}
                                            inputPlaceholder={"Fax"}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th className={"defaultHalfPadding"} />
                                </tr>

                                <tr>
                                    <th></th>
                                    <td className={"defaultPaddingRight"}>English</td>
                                    <td className={"defaultPaddingLeftRight"}>Sinhala</td>
                                    <td className={"defaultPaddingLeft"}>Tamil</td>
                                </tr>

                                <tr>
                                    <th className="textRight defaultPaddingRight">Address Line 1  <sup className="text-danger">*</sup></th>
                                    <td className={"defaultPaddingRight"}>
                                        <InputBoxWithState
                                            formGroupName={formKey}
                                            inputName={"line1"}
                                            maxLength={"50"}
                                            inputPlaceholder={"Address Line 1 in English"}
                                        />
                                    </td>
                                    <td className={"defaultPaddingLeftRight"}>
                                        <InputBoxWithState
                                            formGroupName={formKey}
                                            inputName={"line1_si"}
                                            maxLength={"50"}
                                            inputPlaceholder={"Address Line 1 in Sinhala"}
                                        />
                                    </td>
                                    <td className={"defaultPaddingLeft"}>
                                        <InputBoxWithState
                                            formGroupName={formKey}
                                            inputName={"line1_ta"}
                                            maxLength={"50"}
                                            inputPlaceholder={"Address Line 1 in Tamil"}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <th className="textRight defaultPaddingRight">Address Line 2 </th>
                                    <td className={"defaultPaddingRight"}>
                                        <InputBoxWithState
                                            formGroupName={formKey}
                                            inputName={"line2"}
                                            maxLength={"50"}
                                            inputPlaceholder={"Address Line 2 in English"}
                                        />
                                    </td>
                                    <td className={"defaultPaddingLeftRight"}>
                                        <InputBoxWithState
                                            formGroupName={formKey}
                                            inputName={"line2_si"}
                                            maxLength={"50"}
                                            inputPlaceholder={"Address Line 2 in Sinhala"}
                                        />
                                    </td>
                                    <td className={"defaultPaddingLeft"}>
                                        <InputBoxWithState
                                            formGroupName={formKey}
                                            inputName={"line2_ta"}
                                            maxLength={"50"}
                                            inputPlaceholder={"Address Line 2 in Tamil"}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <th className="textRight defaultPaddingRight">City <sup className="text-danger">*</sup></th>
                                    <td className={"defaultPaddingRight"}>
                                        <InputBoxWithState
                                            formGroupName={formKey}
                                            inputName={"city"}
                                            maxLength={"50"}
                                            inputPlaceholder={"City in English"}
                                        />
                                    </td>
                                    <td className={"defaultPaddingLeftRight"}>
                                        <InputBoxWithState
                                            formGroupName={formKey}
                                            inputName={"city_si"}
                                            maxLength={"50"}
                                            inputPlaceholder={"City in Sinhala"}
                                        />
                                    </td>
                                    <td className={"defaultPaddingLeft"}>
                                        <InputBoxWithState
                                            formGroupName={formKey}
                                            inputName={"city_ta"}
                                            maxLength={"50"}
                                            inputPlaceholder={"City in Tamil"}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <th className="textRight defaultPaddingRight">Postal Code <sup className="text-danger">*</sup></th>
                                    <td className={"defaultPaddingRight"}>
                                        <InputBoxWithState
                                            formGroupName={formKey}
                                            inputName={"postal_code"}
                                            maxLength={"6"}
                                            inputPlaceholder={"Postal Code"}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <th className="textRight defaultPaddingRight">Re-order Level <sup className="text-danger">*</sup></th>
                                    <td className={"defaultPaddingRight"}>
                                        <NumberInputBoxWithState
                                            uiType={templateTypes.bootstrap}
                                            formGroupName={formKey}
                                            inputName={"licence_reorder_level"}
                                            isAllowNegative={false}
                                            isThousandSeparator={false}
                                            decimalScale={0}
                                            isAllowedFn={(value) => {
                                                const checkValue = value.value.toString();
                                                return checkValue.length <= 6;
                                            }}
                                        />
                                    </td>
                                </tr>

                            </table>

                        </div>

                    </div>
                    <div className={"row col-md-12 "}>
                        <div className="col-md-6 ">
                            {
                                (getLicenceTypeError().status === true) ? (
                                    <div className={"defaultFormHelperText text-danger"}>
                                        {getLicenceTypeError().message}
                                    </div>
                                ) : null
                            }
                            <UICard
                                cardHeading="Licence Types"
                                elementStyle={"defaultMarginTop"}
                                inputName={"service_licence_types_allowed"}
                            >
                                <div className="fullWidthDiv">
                                    {
                                        _get(formState, `authorityListAPIKey_create1.service_licence_types_allowed`, []).map((value, index) => {
                                            return (
                                                <div key={index}>
                                                    {
                                                        value.code === 'FE' || value.code === 'NU' ? 
                                                        null : 
                                                        <CheckBoxWithState
                                                            inputStatePath={`authorityListAPIKey_create1.check_licence_type[${value.id}]`}
                                                            formGroupName={formKey}
                                                            labelText={value.code === 'NO' ? 'Normal/ Free/ Non-User Licence' : value.name}
                                                            onChangeFn={(event) => {
                                                                checkQuotation(event.value, value.code)
                                                            }}
                                                        />
                                                    }
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </UICard>
                        </div>
                        <div className="col-md-6 ">
                            {
                                (getTransactionAllowedError().status === true) ? (
                                    <div className={"defaultFormHelperText text-danger"}>
                                        {getTransactionAllowedError().message}
                                    </div>
                                ) : null
                            }
                            <UICard
                                cardHeading="Operation Allowed"
                                elementStyle={"defaultMarginTop"}
                                inputName={"service_operation_types_allowed"}
                            >
                                <div className="fullWidthDiv">
                                    {
                                        _get(formState, `${formKey}.service_operation_types_allowed`, []).map((value, index) => {
                                            return (
                                                (_get(formState, `${formKey}.authority_type_code`, "") !== "WLA" && value.code === "WL" ? "" :
                                                <div key={index}>
                                                    <CheckBoxWithState
                                                        inputStatePath={`${formKey}.check_transaction_type[${value.id}]`}
                                                        formGroupName={formKey}
                                                        labelText={value.name}
                                                    />
                                                </div>
                                                )
                                            )
                                        })
                                    }
                                </div>
                            </UICard>
                        </div>
                    </div>
                </FormWrapper>

            </FormWrapper>
            {/* </FormWrapper> */}

        </UICard >
    )
}

export default AddAuthority;