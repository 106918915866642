import React, { Fragment, useState } from 'react';
import {useHistory} from "react-router-dom";

import { templateTypes } from '../../../../../config/template.config';
import { SnackBarList } from '../../../../ui-components/templates/common-includes/SnackbarWrapper';
import { Icon, UICard } from '../../../../ui-components/ui-elements/common/BaseElements';
import { FormWrapper, InputBoxWithState, InputButtonWithState } from '../../../../ui-components/ui-elements/form';
import {authUser} from "../../../../../config/dummyData.config";
import {_get} from "../../../../../helpers/common-helpers/lodash.wrappers";

const loginFromKey="loginForm";

const Login = () => {

    const [passwordShown, setPasswordShown] = useState(false);

    const history =useHistory();

    const tooglePasswordVisibility = () => {
        setPasswordShown(passwordShown ? false:true)
    }

    return(
        <div className="LoginMainWrapper">
            <SnackBarList />
            <UICard elementStyle="loginCard">
                <Fragment>
                    <div className="loginHeaderWrapper">
                        <img src="images/_emblem1.png" alt="Sri Lanka emblem" style={{width:65}}/>
                        <h4 className="defaultMarginTop">e-Revenue Licence</h4>
                    </div>
                    <FormWrapper
                        elementStyle="loginFormWrapper"
                        setGroupName={loginFromKey}
                    >

                        <InputBoxWithState
                            uiType={templateTypes.bootstrap}
                            formGroupName={loginFromKey}
                            inputName={"username"}
                            labelText="Username"
                            isRequired={true}
                        />

                        <div className="passwordWrapper">
                            <InputBoxWithState
                                uiType={templateTypes.bootstrap}
                                formGroupName={loginFromKey}
                                inputType={passwordShown ? "text":"password"}
                                inputName={"password"}
                                labelText="Password"
                                isRequired={true}
                            />

                            <Icon
                                onClickFn={tooglePasswordVisibility}
                                iconClass={passwordShown ? "mdi mdi-eye":"mdi mdi-eye-off"}
                                elementStyle={"eye"}
                            />
                        </div>

                        <div className={"mt-2"}>
                            If you are a new user &nbsp;
                            <a 
                                href="https://www.nipo.gov.lk/web/images/pdf_downloads/application_forms/form_m01_en.pdf"
                                target="_blank"
                            >
                                <u>download the form here</u>
                            </a>
                        </div>

                        <div className="fullWidthDiv">
                            <InputButtonWithState
                                elementWrapperStyle={"floatRight"}
                                formGroupName={"loginForm"}
                                onClickBtnFn={(eventObj)=> {
                                    eventObj.formAction.setFormErrorFn(loginFromKey,[{
                                        "property":"username",
                                        "message":""
                                    },{
                                        "property":"username",
                                        "message":""
                                    }]);

                                    if(_get(eventObj,'formObject.username',"")===authUser.userName && _get(eventObj,'formObject.password',"")===authUser.password){
                                        history.push("/dashboard");
                                    }else{
                                        eventObj.formAction.setFormErrorFn(loginFromKey,[{
                                            "property":"username",
                                            "message":"invalid username or password"
                                        },{
                                            "property":"password",
                                            "message":"invalid username or password"
                                        }]);
                                    }
                                }}
                                btnName={"login"}
                                btnText={"Login"}
                            />
                        </div>
                    </FormWrapper>
                </Fragment>
            </UICard>
        </div>
    )
};

export default Login;