import React, { useContext } from 'react';
import { templateTypes } from '../../../../../config/template.config';
import { _get } from '../../../../../helpers/common-helpers/lodash.wrappers';
import { FormWrapper, InputBoxWithState } from '../../../../ui-components/ui-elements/form';
import { FormContext } from '../../../core/context-providers/FormContext.provider';
import { vftListAPI } from "../../../../../config/apiUrl.config";

const AddCertificateNumber=(props)=>{
    const [formState, formAction] = useContext(FormContext);
    const company_id=_get(formState[`${vftListAPI.key}_edit`], "id", "");
    const formKey = `${props.fromParent.formKey}`;
   
    return(
        <FormWrapper
            setGroupName={formKey}
            formGroupLinkWith={formKey}
            onDestroyUnsetFormObject={true}
            setFormObject={{
                reqId:`${props.fromParent.requiredId}`,
                vftCompanyId: `${props.fromParent.vftCompanyId}`
              }}
        >
            {/* <div className="row  defaultMarginBottom">
                <p className="defaultPaddingLeftRight"><b>Set VFT Certificate Numbers</b></p>
            </div> */}

            <div className="row">
                <div className="col-md-12 col-sm-12 floatLeft">
                    <InputBoxWithState
                        uiType={templateTypes.bootstrap}
                        formGroupName={formKey}
                        inputName={"certificate_number_from"}
                        labelText="Certificate No. From"
                        isRequired={true}
                        maxLength={12}
                    />
                </div>

                <div className="col-md-12 col-sm-12 floatLeft">
                    <InputBoxWithState
                        uiType={templateTypes.bootstrap}
                        formGroupName={formKey}
                        inputName={"certificate_number_to"}
                        labelText="Certificate No. To"
                        isRequired={true}
                        maxLength={12}
                    />
                </div>

            </div>
        </FormWrapper>  
    )
}
const RemoveCertificateNumbersForm = (props) => {
    
    const formKey = `${props.fromParent.formKey}`;
    
    return (
      <FormWrapper
        setGroupName={formKey}
        formGroupLinkWith={formKey}
        setFormObject={{
          id: props.row.id,
          reqId:`${props.fromParent.requiredId}`,
          vftCompanyId: `${props.fromParent.vftCompanyId}`
        }}
        onDestroyUnsetFormObject={true}
      >
        <div className="removeContent">
          <p> Are you sure you want to remove?</p>
          <br />
          <br />
        </div>
      </FormWrapper>
    );
  };

export {
    AddCertificateNumber,
    RemoveCertificateNumbersForm 
};
