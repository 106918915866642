import React,{Component} from 'react';


class Test1 extends Component{

    constructor(){
        super();
        this.state={
            count:111,
            prices:0,
        };
        console.log("====constructor======");
    }

    static getDerivedStateFromProps(props, state){
        console.log("====getDerivedStateFromProps======");
        return {
           "prices": props.data*10
        };
    }

    componentDidMount(){
        console.log("====componentDidMount======");
    }

    shouldComponentUpdate(nextProps, nextState){
        console.log("====shouldComponentUpdate======");
        return true;
    }

    getSnapshotBeforeUpdate(prevProps, prevState){
        console.log("====getSnapshotBeforeUpdate======");
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        console.log("====componentDidUpdate======",snapshot);
    }

    componentWillUnmount(){
        console.log("====componentWillUnmount======");
    }

    
    render(){
        console.log("====render======");
       return(
       <div>test {this.props.data} 
       <br/>
       {this.state.count}
       <br/>
       {this.state.prices}
       </div>
       )
    }
}

class Test2 extends Component{

    constructor(){
        super();
        this.state={
            "count":0
        };
    }

    render(){
        return (
            <div className="col-md-12">
                <button onClick={()=>this.setState({
                    count:this.state.count+1
                })}>Click {this.state.count}</button>
                <Test1 data={this.state.count}/>
            </div>
        )
    }
}

export default Test2;