/*
 * @Author: Anjula Karunarathne 
 * @Date: 2021-01-21 16:05:19 
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2021-07-21 16:24:41
 */

import React from "react";
import { ApplicabilityTypeCode, ChargeEffectCode } from "../../../../../config/enum.config";
import {
    FormWrapper,
    SelectBoxWithState,
    InputBoxWithState,
} from "../../../../ui-components/ui-elements/form";

const SearchElement = ({ dataTableKey = "datatablekey" }) => {
    const formKey = `${dataTableKey}_search`;

    return (
        <FormWrapper setGroupName={formKey}>
            <div className="row">
                <div className="col-md-2 col-sm-12">
                    <InputBoxWithState
                        formGroupName={formKey}
                        inputName={"search"}
                        labelText="Name"
                        inputPlaceholder={"Name"}
                        maxLength={50}
                    />
                </div>

                <div className="col-md-3 col-sm-12">
                    <SelectBoxWithState
                      formGroupName={formKey}
                      dataList={ApplicabilityTypeCode}
                      inputName={"applicability_type_code"}
                      labelText="Applicability Type"
                      emptySelectOptionTxt="All"
                      isEnableAllOption={true}
                    />
                </div>

                <div className="col-md-2 col-sm-12">
                    <SelectBoxWithState
                        emptySelectOptionTxt="All"
                        formGroupName={formKey}
                        dataList={ChargeEffectCode}
                        inputName={"charge_effect_code"}
                        labelText="Charge Effect"
                        isEnableAllOption={true}
                    />
                </div>

                <div className="col-md-2 col-sm-12">
                    <SelectBoxWithState
                        emptySelectOptionTxt="All"
                        formGroupName={formKey}
                        dataList={[
                            {
                              id: "true",
                              value: "Active",
                            },
                            {
                              id: "false",
                              value: "Inactive",
                            },
                          ]}
                        inputName={"active"}
                        labelText="Status"
                        isEnableAllOption={true}
                    />
                </div>
            </div>
        </FormWrapper>
    );
};
export { SearchElement };