import React, { Fragment } from "react";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { AuditDetail, UICard, ViewDetailBlock } from "../../../../ui-components/ui-elements/common/BaseElements";

const LicenceTypes = (props) => {
    const extendedApplicability =(para)=>{
        if(para){
            if(para==="MAN"){
                return "Mandatory";
            }
            else if(para==="OPT"){
                return "Optional"
            }else{
                return "Not Required"
            }
        }else{
            return null;
        }
    }

    const numberOfLabelCol = 4
    return (
        <Fragment>
            <UICard>
                <ViewDetailBlock
                    label={"Licence Type Prefix"}
                    value={_get(props, `row.prefix`, '')}
                    labelCol={numberOfLabelCol}
                />
                <ViewDetailBlock
                    label={"Licence Type Name"}
                    value={_get(props, `row.name`, '')}
                    labelCol={numberOfLabelCol}
                />
                <ViewDetailBlock
                    label={"Status"}
                    value={parseInt(_get(props, `row.active`, ''))=== 1 ? ('Active') : ('Inactive')}
                    labelCol={numberOfLabelCol}
                /> 
            </UICard>
            <div className="defaultPaddingTop">
                <UICard className="" cardHeading="Document Validations">
                    <ViewDetailBlock
                        label={"Insurance"}
                        value={extendedApplicability(_get(props, `row.insurance_applicability`, ''))}
                        labelCol={numberOfLabelCol}
                    /> 
                    <ViewDetailBlock
                        label={"VET"}
                        value={extendedApplicability(_get(props, `row.vet_applicability`, ''))}
                        labelCol={numberOfLabelCol}
                    />
                    <ViewDetailBlock
                        label={"VFT"}
                        value={extendedApplicability(_get(props, `row.vft_applicability`, ''))}
                        labelCol={numberOfLabelCol}
                    />
                    <ViewDetailBlock
                        label={"VRP"}
                        value={extendedApplicability(_get(props, `row.vrp_applicability`, ''))}
                        labelCol={numberOfLabelCol}
                    />
                    
                    <div className="row defaultPaddingTop">
                        
                    </div>
                    
                </UICard>
            </div>

            <AuditDetail
                createdById={_get(props, `row.created_by`, "")}
                createdLocationId={_get(props, `row.created_location`, "")}
                createdAt={dateObjectToString(_get(props, `row.created_at`, ''),"dd/mm/YY HH:MM")}
                createdRoleId={_get(props, `row.created_role_id`, '')}
                updatedById={_get(props, `row.updated_by`, "")}
                updatedLocationId={_get(props, `row.updated_location`, "")}
                updatedAt={dateObjectToString(_get(props, `row.updated_at`, ''),"dd/mm/YY HH:MM")}
                updatedRoleId={_get(props, `row.updated_role_id`, '')}
                labelCol={numberOfLabelCol}
            />
        </Fragment>
    );
};

export {
    LicenceTypes
};