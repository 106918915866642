/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-09-08 18:12:24
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-16 12:44:09
 */
import React, { useState, Fragment } from "react";
import {
  FormWrapper,
  InputBoxWithState,
  InputButton,
  CheckBoxWithState,
  RoundButton,
  roundBtnSizes,
  SelectBoxWithState,
  checkBoxLabelPlacement
} from "../../../../ui-components/ui-elements/form/index";
import { PaddingLessUICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { DataTable } from "../../../../ui-components/ui-elements/table/DataTable";
import { RoundButtonWithPopups } from "../../../../ui-components/ui-elements/form/Button";
import {normalLicenseDummy} from "../../../../../config/dummyData.config";
import {_get} from "../../../../../helpers/common-helpers/lodash.wrappers";
import {VariableToComponent} from "../../../../ui-components/ui-elements/common/BaseElements";

const emptyFun = (...para) => undefined;

const OwnerInfoDisplay = ({
  ownerInfo={}
}) => {
  return (
    <div className="row">
      <div className="col-md-6">
        <strong>Name: </strong> {ownerInfo.name}
      </div>
      <div className="col-md-6">
        <strong>Address: </strong>{ownerInfo.address1} {ownerInfo.address2},{ownerInfo.city}
      </div>
      <div className="col-md-6">
        <strong>Mobile Number: </strong>{ownerInfo.mobileNumber}
      </div>
      <div className="col-md-6">
        <strong>Identification Number: </strong>{ownerInfo.identificationNumber}
      </div>
      <div className="col-md-6">
        <strong>Absolute Owner: </strong>{ownerInfo.absoluteOwner}
      </div>
    </div>
  );
};

const VehicleInfoDisplay = ({
  uiType="fullInfo",
  vehicleInfo={}
}) => {
    return (
      <div className="row">
        <div className="col-md-4">
          <strong>Chassis: </strong>{vehicleInfo.chassis}
        </div>
        <div className="col-md-4">
          <strong>Tare: </strong>{vehicleInfo.tare}
        </div>
        <div className="col-md-4">
          <strong>Vehicle Status: </strong>{vehicleInfo.vehicleStatus}
        </div>
        <div className="col-md-4">
          <strong>Engine: </strong>{vehicleInfo.engine}
        </div>
        <div className="col-md-4">
          <strong>Gross: </strong>{vehicleInfo.gross}
        </div>
        <div className="col-md-4">
          <strong>Fuel Type: </strong> {vehicleInfo.fuelType}
        </div>
        <div className="col-md-4">
          <strong>1st Reg: Date: </strong> {vehicleInfo.regDate1}
        </div>
        <div className="col-md-4">
          <strong>Seats (Exp Driver): </strong> {vehicleInfo.seats}
        </div>
        <div className="col-md-4">
          <strong>Class: </strong>{vehicleInfo.class}
        </div>
        <div className="col-md-4">
          <strong>Province: </strong> {vehicleInfo.province}
        </div>
        {
          (uiType==="fullInfo")?(
            <Fragment>
              <div className="col-md-4">
                <strong>Manufacture Year: </strong> {vehicleInfo.manufactureYear}
              </div>
              <div className="col-md-4">
                <strong>Cylinder Capacity: </strong> {vehicleInfo.cylinderCapacity}
              </div>
              <div className="col-md-4">
                <strong>Tare Size: </strong> {vehicleInfo.tareSize}
              </div>
              <div className="col-md-4">
                <strong>Wheel Base: </strong>  {vehicleInfo.wheelBase}
              </div>
              <div className="col-md-4">
                <strong>Type Of Body: </strong> {vehicleInfo.typeOfBody}
              </div>
              <div className="col-md-4">
                <strong>Make: </strong> {vehicleInfo.make}
              </div>
              <div className="col-md-4">
                <strong>Model: </strong> {vehicleInfo.model}
              </div>
              <div className="col-md-4">
                <strong>Colors: </strong> {vehicleInfo.colors}
              </div>
            </Fragment>
          ):(null)
        }
      </div>
    );
  };

const OwnerInfoEdit = () => {
  return (
    <div className="row">
      <div className="col-md-6">
        <InputBoxWithState
          labelText={"Name"}
          formGroupName={"normalLicence"}
          inputName={"name"}
        />
      </div>
      <div className="col-md-6">
        <InputBoxWithState
          labelText={"Address 1"}
          formGroupName={"normalLicence"}
          inputName={"address_1"}
        />
      </div>
      <div className="col-md-6">
        <InputBoxWithState
          labelText={"Address 2"}
          formGroupName={"normalLicence"}
          inputName={"address_2"}
        />
      </div>
      <div className="col-md-6">
        <InputBoxWithState
          labelText={"City"}
          formGroupName={"normalLicence"}
          inputName={"city"}
        />
      </div>
      <div className="col-md-6">
        <InputBoxWithState
          labelText={"Mobile Number"}
          formGroupName={"normalLicence"}
          inputName={"mobileNumber"}
        />
      </div>
      <div className="col-md-6">
        <InputBoxWithState
          labelText={"Identification Number"}
          formGroupName={"normalLicence"}
          inputName={"identificationNumber"}
        />
      </div>
      <div className="col-md-6">
        <InputBoxWithState
          labelText={"Absolute Owner Name"}
          formGroupName={"normalLicence"}
          inputName={"absoluteOwner"}
        />
      </div>
      <div className="col-md-6">
        <InputBoxWithState
          labelText={"Absolute Owner Address 1"}
          formGroupName={"normalLicence"}
          inputName={"absoluteOwneradd1"}
        />
      </div>
      <div className="col-md-6">
        <InputBoxWithState
          labelText={"Absolute Owner Address 2"}
          formGroupName={"normalLicence"}
          inputName={"absoluteOwneradd2"}
        />
      </div>
      <div className="col-md-6">
        <InputBoxWithState
          labelText={"Absolute Owner City"}
          formGroupName={"normalLicence"}
          inputName={"absoluteOwneracity"}
        />
      </div>
      <div className="col-md-6">
        <SelectBoxWithState
          labelText={"SMS Alerts"}
          formGroupName={"normalLicence"}
          dataList={[
            {
              "id":"1",
              "value":"Enable"
            },{
              "id":"2",
              "value":"Disable"
            }
          ]}
          inputName={"smsAlert"}
        />
      </div>
    </div>
  );
};

const VehicleInfoEdit = () => {
  return (
    <div className="row">
      <div className="col-md-4">
        <InputBoxWithState
          labelText={"Chassis"}
          formGroupName={"normalLicence"}
          inputName={"chassis"}
        />
      </div>
      <div className="col-md-4">
        <InputBoxWithState
          labelText={"Tare"}
          formGroupName={"normalLicence"}
          inputName={"tare"}
        />
      </div>
      <div className="col-md-4">
        <InputBoxWithState
          labelText={"Vehicle Status"}
          formGroupName={"normalLicence"}
          inputName={"vehicleStatus"}
        />
      </div>
      <div className="col-md-4">
        <InputBoxWithState
          labelText={"Engine"}
          formGroupName={"normalLicence"}
          inputName={"engine"}
        />
      </div>
      <div className="col-md-4">
        <InputBoxWithState
          labelText={"Gross"}
          formGroupName={"normalLicence"}
          inputName={"gross"}
        />
      </div>
      <div className="col-md-4">
        <InputBoxWithState
          labelText={"Fuel Type"}
          formGroupName={"normalLicence"}
          inputName={"fuelType"}
        />
      </div>
      <div className="col-md-4">
        <InputBoxWithState
          labelText={"1st Reg"}
          formGroupName={"normalLicence"}
          inputName={"reg1"}
        />
      </div>
      <div className="col-md-4">
        <InputBoxWithState
          labelText={"Seats (Exp Driver)"}
          formGroupName={"normalLicence"}
          inputName={"seats"}
        />
      </div>
      <div className="col-md-4">
        <InputBoxWithState
          labelText={"Class"}
          formGroupName={"normalLicence"}
          inputName={"class"}
        />
      </div>
      <div className="col-md-4">
        <InputBoxWithState
          labelText={"Province"}
          formGroupName={"normalLicence"}
          inputName={"province"}
        />
      </div>
    </div>
  );
};

const OwnerDetailsIcons = (props) => {


  const getDialogTitle = (uiType = null) => {
    if (uiType === "owner_infor") {
      return "Owner Information";
    } else {
      return "Vehicle Information";
    }
  };

  const getEditMode = (uiType = null) => {
    if (uiType === "owner_infor") {
      return  OwnerInfoEdit;
    } else {
      return VehicleInfoEdit;
    }
  };

  const getViewMode = (uiType = null) => {
    if (uiType === "owner_infor") {
      return OwnerInfoDisplay;
    } else {
      return  VehicleInfoDisplay;
    }
  };

  return (
    <Fragment>
      <RoundButtonWithPopups
        btnName="edit"
        iconClass={"mdi mdi-pencil"}
        btnSize={roundBtnSizes.small}
        dialogProps={{
          dialogTitle: `Edit ${getDialogTitle()}`,
        }}
        modelComponent={getEditMode(props.uiType)}
        propsToModel={props}
      />
      <RoundButtonWithPopups
        btnName="viewmore"
        iconClass={"mdi mdi-magnify-plus-outline"}
        btnSize={roundBtnSizes.small}
        dialogProps={{
          dialogTitle: `View ${getDialogTitle()}`,
        }}
        modelComponent={getViewMode(props.uiType)}
        propsToModel={props}
      />
    </Fragment>
  );
};

const ViewLicenseArrears=({
  licenseArrearsList=[]
})=>{
    return(
        <DataTable
              tableHeaderList={[
                { displayName: "From", key: "from", isSort: false },
                { displayName: "To", key: "to", isSort: false },
                { displayName: "Total Fee (LKR)", key: "fee", isSort: false },
                { displayName: "Total Fine (LKR)", key: "fine", isSort: false },
              ]}
              tableBodyList={licenseArrearsList}
              isSetTableFooter={true}
              isSetTableHeader={true}
        />
    )
}

const CommentsHistory=()=>{
  return (
    <div className="commentHistoryWrapper">
        <div className="commentElementWrapper">
            <h1 className="defaultHeading"><strong>Roshan Liyanage</strong> 2020-12-03:00:00:23</h1>
            <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec
            </p>
        </div>
        <div className="commentElementWrapper">
            <h1 className="defaultHeading"><strong>Roshan Liyanage</strong> 2020-12-03:00:00:23</h1>
            <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec
            </p>
        </div>
        <div className="commentElementWrapper">
            <h1 className="defaultHeading"><strong>Roshan Liyanage</strong> 2020-12-03:00:00:23</h1>
            <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec
            </p>
        </div>
    </div>
  )
}

const LicenseArrearsIcons=(props)=>{
    return(
        <Fragment>
            <RoundButtonWithPopups
            btnName="viewmore"
            iconClass={"mdi mdi-magnify-plus-outline"}
            btnSize={roundBtnSizes.small}
            dialogProps={{
            dialogTitle: ` ${props.uiType=="comments"?"Comments":"View Licence Arrears"} `,
            }}
            modelComponent={props.uiType=="comments"?CommentsHistory:ViewLicenseArrears}
            propsToModel={props}
        />
        </Fragment>
    )
}

const LicencePeriodIcons=({ uiType = null, formGroupName = null })=>{
  return (
    <CheckBoxWithState
        labelText={ uiType=="paymentHistory"?"View All":"Re issuance"}
        formGroupName={formGroupName}
        labelPlacement={checkBoxLabelPlacement.start}
        inputName={uiType=="paymentHistory"?"viewAll":"reissu"}
    />
  )
}


const NormalLicense = ({
  setShowHideFooterFN=emptyFun
}) => {
  const [getState,setState]=useState({
    "searchKey":"ks-5555",
    "currentObject":null,
  })
  return (
    <div className="normalLicenseWrapper">
      <div className="topWrapper">
        <div className="searchWrapper">
          <FormWrapper elementStyle={""} setGroupName={"searchFromKey"}>
            <div className="searchInputWrapper">
              <label className="control-labe">Vehicle ID:</label>
              <input type="text" className="form-control" value={getState.searchKey} onChange={(event)=>setState({
                ...getState,
                searchKey:event.target.value
              })} />
              <InputButton
                btnName="search"
                onClickBtnFn={()=>{
                  if(normalLicenseDummy.hasOwnProperty(getState.searchKey.toLowerCase())){
                    setState({
                      ...getState,
                      "currentObject":_get(normalLicenseDummy,getState.searchKey,{})
                    });
                    setShowHideFooterFN(true);
                  }else{
                    setState({
                      ...getState,
                      "currentObject":"none"
                    })
                    setShowHideFooterFN(false);
                  }
                }}
                overrideTextToIcon={
                  <i className="mdi mdi-magnify-plus-outline" />
                }
              />
            </div>
          </FormWrapper>
        </div>
        <div className="statusWrapper">
          <ul className="statusUL">
            <li className="setBorder">New</li>
            <li className="setBorder">Active</li>
            <li>
              <InputButton btnName="search" btnText={"Normal License"} />
            </li>
            <li>
              <InputButton btnName="search" btnText={"Request for"} />
            </li>
          </ul>
        </div>
      </div>

        {
          ((getState.currentObject===null)?(null):((getState.currentObject==="none")?(
            <div className="col-md-12 defaultPaddingTop floatLeft">No result found</div>
          ):(

            <FormWrapper
              setGroupName={"normalLicence"}
              elementStyle={"normalLicenceWrapper"}
            >
              <div className="col-md-6 floatLeft">
                <PaddingLessUICard
                  cardHeading="Owner Information"
                  passPropsToCardHeadingTail={{
                    uiType: "owner_infor",
                    formGroupName: "normalLicence",
                    ownerInfo:_get(getState.currentObject,`ownerInformation`,{})
                  }}
                  cardHeadingTail={OwnerDetailsIcons}
                >
                  <OwnerInfoDisplay ownerInfo={_get(getState.currentObject,`ownerInformation`,{})} />
                </PaddingLessUICard>
              </div>
              <div className="col-md-6 documentValidation floatRight">
                <PaddingLessUICard
                  cardHeading="Document Validation"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <CheckBoxWithState
                            inputName={"isVerifyRegister"}
                            formGroupName={"normalLicence"}
                            labelText={"Certificate of Registration"}
                          />
                        </div>
                        <div className="col-md-4">
                          <InputBoxWithState
                            labelText={"Previous Rev No/ Rcpt No"}
                            formGroupName={"normalLicence"}
                            inputName={"previous_no"}
                          />
                        </div>
                      </div>
                    </div>

                    {
                      (_get(getState.currentObject,`documentValidation.isSetVET`,false)===true?(
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-4">
                              <InputBoxWithState
                                labelText={"VET"}
                                formGroupName={"normalLicence"}
                                inputName={"vet"}
                              />
                            </div>
                            <div className="col-md-4">
                              <InputBoxWithState
                                labelText={"VET No"}
                                formGroupName={"normalLicence"}
                                inputName={"previous_no"}
                              />
                            </div>
                            <div className="col-md-4 checkBox_Btn_wrraper">
                              <CheckBoxWithState
                                inputName={"isVet"}
                                formGroupName={"normalLicence"}
                              />
                            </div>
                          </div>
                        </div>
                      ):(null))
                    }

                    {
                      (_get(getState.currentObject,`documentValidation.isSetInsurance`,false)===true?(
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-4">
                              <InputBoxWithState
                                labelText={"Insurance"}
                                formGroupName={"normalLicence"}
                                inputName={"vet"}
                              />
                            </div>
                            <div className="col-md-4">
                              <InputBoxWithState
                                labelText={"Expiry Date"}
                                formGroupName={"normalLicence"}
                                inputName={"previous_no"}
                              />
                            </div>
                            <div className="col-md-4 checkBox_Btn_wrraper">
                              <CheckBoxWithState
                                inputName={"isVet"}
                                formGroupName={"normalLicence"}
                              />
                            </div>
                          </div>
                        </div>
                      ):(null))
                    }


                    {
                      (_get(getState.currentObject,`documentValidation.isSetFitness`,false)===true?(
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-4">
                              <InputBoxWithState
                                labelText={"Fitness"}
                                formGroupName={"normalLicence"}
                                inputName={"fitness"}
                              />
                            </div>
                            <div className="col-md-4">
                              <InputBoxWithState
                                labelText={"Issued Date"}
                                formGroupName={"normalLicence"}
                                inputName={"issuedDate"}
                              />
                            </div>
                            <div className="col-md-4 checkBox_Btn_wrraper">
                              <CheckBoxWithState
                                inputName={"isVet"}
                                formGroupName={"normalLicence"}
                              />
                              <InputButton
                                btnName="view_more"
                                overrideTextToIcon={
                                  <i className="mdi mdi-magnify-plus-outline" />
                                }
                              />
                            </div>
                          </div>
                        </div>
                      ):(null))
                    }


{
                      (_get(getState.currentObject,`documentValidation.isSetRoutePerm`,false)===true?(
                       <Fragment>
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-4">
                                <InputBoxWithState
                                  labelText={"Route Perm"}
                                  formGroupName={"normalLicence"}
                                  inputName={"routePerm"}
                                />
                              </div>
                              <div className="col-md-4">
                                <InputBoxWithState
                                  labelText={"Expiry Date"}
                                  formGroupName={"normalLicence"}
                                  inputName={"expiryDate"}
                                />
                              </div>
                              <div className="col-md-4 checkBox_Btn_wrraper">
                                <CheckBoxWithState
                                  inputName={"isVet"}
                                  formGroupName={"normalLicence"}
                                />
                                <InputButton
                                  btnName="view_more"
                                  overrideTextToIcon={
                                    <i className="mdi mdi-magnify-plus-outline" />
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-4">
                                <InputBoxWithState
                                  labelText={"Route PermNo"}
                                  formGroupName={"normalLicence"}
                                  inputName={"routePermNo"}
                                />
                              </div>
                            </div>
                          </div>
                       </Fragment>
                      ):(null))
                    }

                    

                    
                    
                    
                  </div>
                </PaddingLessUICard>
              </div>
              <div className="col-md-6 floatLeft">
                <PaddingLessUICard
                  cardHeading="Vehicle Information"
                  passPropsToCardHeadingTail={{
                    uiType: "vehicle_infor",
                    formGroupName: "normalLicence",
                    vehicleInfo:_get(getState.currentObject,`vehicleInformation`,{})
                  }}
                  cardHeadingTail={OwnerDetailsIcons}
                >
                <VehicleInfoDisplay uiType={"summary"} vehicleInfo={_get(getState.currentObject,`vehicleInformation`,{})} />
                </PaddingLessUICard>
              </div>
              <div className="col-md-6 floatLeft">
                <PaddingLessUICard
                  cardHeading="License Arrears"
                  passPropsToCardHeadingTail={{
                    uiType: "licenseArrears",
                    formGroupName: "normalLicence",
                    licenseArrearsList:_get(getState.currentObject,`licenseArrears.licenseArrearsList`,{})
                  }}
                  cardHeadingTail={LicenseArrearsIcons}
                >
                  <div className="row">
                      <div className="col-md-4">
                          <strong>From: </strong>{_get(getState.currentObject,`licenseArrears.from`,"")}
                      </div>
                      <div className="col-md-4">
                          <strong>To: </strong>{_get(getState.currentObject,`licenseArrears.to`,"")}
                      </div>
                      <div className="col-md-4">
                          <strong>Total Fee (LKR): </strong>{_get(getState.currentObject,`licenseArrears.totalFee`,"00.00/=")}
                      </div>
                      <div className="col-md-4">
                          <strong>Total Fine (LKR): </strong>{_get(getState.currentObject,`licenseArrears.totalFine`,"00.00/=")}
                      </div>               
                  </div>
                </PaddingLessUICard>
              </div>

              <div className="col-md-6 floatRight">
                <PaddingLessUICard
                  cardHeading="Licence Period"
                  cardHeadingTail={LicencePeriodIcons}
                  passPropsToCardHeadingTail={{
                    uiType: "licence_period",
                    formGroupName: "normalLicence",
                  }}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <InputBoxWithState
                        labelText={"From"}
                        formGroupName={"normalLicence"}
                        inputName={"from"}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputBoxWithState
                        labelText={"To"}
                        formGroupName={"normalLicence"}
                        inputName={"to"}
                      />
                    </div>
                  </div>
                </PaddingLessUICard>
              </div>

              <div className="col-md-6 floatLeft">
                <PaddingLessUICard
                  cardHeading="License Payment History"
                  cardHeadingTail={LicencePeriodIcons}
                  passPropsToCardHeadingTail={{
                    uiType: "paymentHistory",
                    formGroupName: "normalLicence",
                  }}
                >
                  <DataTable
                    tableHeaderList={[
                      { displayName: "Year", key: "year", isSort: false },
                      { displayName: "Tx Num", key: "txNum", isSort: false },
                      { displayName: "Lic/Rec No", key: "regNo", isSort: false },
                      {
                        displayName: "Validity Period",
                        key: "validity",
                        isSort: false,
                      },
                      { displayName: "Fee (LKR)", key: "fee", isSort: false },
                      { displayName: "Fine (LKR)", key: "fine", isSort: false },
                      { displayName: "Total (LKR)", key: "total", isSort: false },
                      { displayName: "Licence Type", key: "licence", isSort: false },
                      { displayName: "Trans. Type", key: "transType", isSort: false },
                      { displayName: "Trans. Date", key: "transDate", isSort: false },
                    ]}
                    tableBodyList={_get(getState.currentObject,`licensePaymentHistoryList`,[])}
                    isSetTableFooter={true}
                    isSetTableHeader={false}
                  />
                </PaddingLessUICard>
              </div>

              <div className="col-md-6 floatRight ">
                <PaddingLessUICard
                  cardHeading="Licence Amount"
                >
                  <table style={{ width: "100%" }}>
                    <tr>
                      <th>Fee (LKR)</th>
                      <td className="text-right">1000,000.00</td>
                    </tr>
                    <tr>
                      <th>Fine (LKR)</th>
                      <td className="text-right">1000,000.00</td>
                    </tr>
                    <tr>
                      <th>Total Arrears (LKR)</th>
                      <td className="text-right">1000,000.00</td>
                    </tr>
                    <tr>
                      <th>Other Charges (LKR)</th>
                      <td className="text-right">1000,000.00</td>
                    </tr>
                    <tr>
                      <th>Total Amount (LKR)</th>
                      <td className="text-right">
                        <b>1000,000.00</b>
                      </td>
                    </tr>
                  </table>
                </PaddingLessUICard>
              </div>
            
              <div className="col-md-6 floatRight ">
                <PaddingLessUICard
                  cardHeading="Comments"
                  passPropsToCardHeadingTail={{
                    uiType: "comments",
                    formGroupName: "normalLicence",
                  }}
                  cardHeadingTail={LicenseArrearsIcons}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <InputBoxWithState
                        isMultiline={true}
                        rows={1}
                        labelText={"Comments"}
                        formGroupName={"normalLicence"}
                        inputName={"comments"}
                      />
                    </div>
                    <div className="col-md-12 text-right">
                      <InputButton 
                        elementWrapperStyle={'defaultHalfMarginLeftRight'}
                        btnText="Save"
                      />
                      <InputButton 
                        elementWrapperStyle={'defaultHalfMarginLeftRight'}
                        btnText="Cancel"
                      />
                    </div>
                    </div>

                </PaddingLessUICard>
              </div>
            
            </FormWrapper>

          )))
          
        }        


      
    </div>
  );
};

export { NormalLicense };
