import React, { Fragment, useContext, useState, useEffect } from "react";

import {
  FormWrapper,
  SelectBoxWithState,
  InputBoxWithState,
  CheckBoxWithState, SubmitButton, NumberInputBoxWithState, InputButton
} from "../../../../ui-components/ui-elements/form";
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { StepsWrapper, CustomStep, stepWrapperOrientations } from "../../../../ui-components/ui-elements/stepper/Stepper";
import { AdditionalRolesAndLocations, CustomButtons } from "./AdditionalRolesAndLocations";
import {
  actionBtnTypes, apiResponseStoringType, DataTable,
} from "../../../../ui-components/ui-elements/table/DataTable";
import { TabsWrapper, SubTab } from "../../../../ui-components/ui-elements/tab/Tabs";
import { UIContext } from "../../../../ui-components/context-providers/UIContext.provider";
import {
  AuthorityListApi,
  internalUserAPI,
  userRolesListAPI,
  tempInternalUserAPI,
  userTitlesListAPI,
  userDefaultDelegationAPI,
  userDelegationAPI,
  userPasswordResetAPI,
  provincesOfficesListAPI,
  pdmtListAPI,
  tempUserDelegationCreateAPI,
  userInternalDefaultDelegationAPI,
  userAdminDefaultDelegationAPI,
  pdmtOfficesListAPI
} from "../../../../../config/apiUrl.config";
import { getDataByFormObject } from "../../../../../helpers/common-helpers/common.helpers";
import { CoreContext } from "../../../core/context-providers/CoreContext.provider";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { contactsArrayToObject } from "../../../../../helpers/common-helpers/contact.helpers";
import { ContactTypeCode } from "../../../../../config/enum.config";
import { roleLevelChangeFn } from "../../../../../helpers/common-helpers/setRoleLevelCode";
import { RemoveFrom } from "./RemoveForm";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { DialogPopup } from "../../../../ui-components/ui-elements/modal/DialogPopup";
import { isEmpty } from "lodash";
import { callApi } from "../../../../../helpers/common-helpers/callApi.helpers";
import { useHistory } from "react-router-dom";

const UserForm = (props) => {
  const [resetPopup, setResetPopup] = useState(false);
  const [formState, formAction] = useContext(FormContext);
  const [coreState, coreAction] = useContext(CoreContext);
  const [responseData, setresponseData] = useState(false);
  const [authStatus] = useContext(AuthContext);
  const serviceId = _get(authStatus, "authUser.service_id", "");
  const userRoleLevelCode = _get(authStatus, "authUser.role_level_code", "");
  const userRoleType = _get(authStatus, "authUser.role_level_code", "");
  const provinceId = _get(authStatus, "authUser.pdmt_id", "");
  const pdmtCode = _get(authStatus, "authUser.pdmt_code", "")
  const [getProvinceId, setProvinceId] = useState()
  let getAuthorityList = []
  const [isopen, setIsopen] = useState(false)
  const getBaseAuthorityValiadtation = () => {
    if (userRoleType == 'ADN_PRV') {
      return 'required'
    }
    else {
      return 'max:200'
    }
  }
  const getAuthoritydataList = () => {
    if (userRoleType === 'ADN_PRV') {
      callApi(`${AuthorityListApi.url}?active=true&pdmt_id=${provinceId}`)
        .method("get")
        .headers(true)
        .send((error, result) => {
          if (!error) {
            _get(result, "data.data", []).map((item) => {
              getAuthorityList.push({
                id: item.service.id,
                name: item.service.name
              })
            })
            getAuthorityList.push({ id: 0, name: "NO AUTHORITY REQUIRED - USER IS A PDMT USER" })
            formAction.changeInputFn(props.formKey, "Authority_data", "", getAuthorityList)

          }
        });

    }
  }
  useEffect(() => {
    if (userRoleType === 'ADN_PRV') {
      callApi(
        `${pdmtOfficesListAPI.url}/${provinceId}`)
        .method("get")
        .headers(true)
        .send((error, result) => {
          if (!error) {
            setProvinceId(result.data.data.service.id)

          }
        });
    }
    if (userRoleType === 'ADN_PRV') {
      getAuthoritydataList()
    }





  }, [provinceId, isopen]);

  const DialogCloseFn = () => {
    setIsopen(false)
    //window.location.reload()

  }

  return (
    <FormWrapper
      setGroupName={props.formKey}
      setFormObject={
        props.uiType !== "create-edit" ?
          (
            {
              title_id: _get(props, 'row.title.id', ""),
              full_name: _get(props, 'row.full_name', ""),
              designation: _get(props, 'row.user.designation', ""),
              nic: _get(props, 'row.nic', ""),
              email: contactsArrayToObject(_get(props, `row.user.contact`, []), false, ContactTypeCode.EMAIL),
              contact_number: contactsArrayToObject(_get(props, `row.user.contact`, []), false, ContactTypeCode.MOBILE),
              active: _get(props, 'row.user.active', 1),
              user_name: _get(props, 'row.user.user_name', ""),
              display_name: _get(props, 'row.display_name', ""),
              email_notification: _get(props, 'row.user.email_notification', true),
              sms_notification: _get(props, 'row.user.sms_notification', true),
              base_pdmt_service_id: userRoleType === 'ADN_PRV' ? serviceId : _get(props, 'row.base_pdmt_service_id', ""),
              base_authority_service_id: _get(props, 'row.base_authority_service_id', "")
            }
          ) : (
            {
              title_id: !isEmpty(_get(props, 'row.title_id', "")) ? _get(props, 'row.title_id', "") : _get(props, 'row.title.id', ""),
              full_name: _get(props, 'row.full_name', ""),
              designation: !isEmpty(_get(props, 'row.designation', "")) ? _get(props, 'row.designation', "") : _get(props, 'row.user.designation', ""),
              nic: _get(props, 'row.nic', ""),
              email: !isEmpty(_get(props, 'row.email', "")) ? _get(props, 'row.email', "") : (_get(props, 'row.user.contact[0].contact_type', "") === "EMA" ? _get(props, 'row.user.contact[0].contact', "") : ""),
              contact_number: !isEmpty(_get(props, 'row.contact_number', "")) ? _get(props, 'row.contact_number', "") : (_get(props, 'row.user.contact[1].contact_type', "") === "MOB" ? _get(props, 'row.user.contact[1].contact', "") : ""),
              active: _get(props, 'row.active', "") === 1 ? 1 : _get(props, 'row.active', "") === 0 ? 0 : _get(props, 'row.user.active', 0),
              user_name: !isEmpty(_get(props, 'row.user_name', "")) ? _get(props, 'row.user_name', "") : _get(props, 'row.user.user_name', ""),
              display_name: _get(props, 'row.display_name', ""),
              sms_notification: (_get(props, 'row.sms_notification', "") === 1) ? true : (_get(props, 'row.sms_notification', "") === 0 ? false : _get(props, 'row.user.sms_notification', false)),
              email_notification: (_get(props, 'row.email_notification', "") === 1) ? true : _get(props, 'row.email_notification', "") === 0 ? false : _get(props, 'row.user.email_notification', false),
              base_pdmt_service_id: userRoleType === 'ADN_PRV' ? serviceId : !isEmpty(_get(props, 'row.base_pdmt_service_id', "")) ? _get(props, 'row.base_pdmt_service_id', "") : _get(props, 'row.user.base_pdmt_service_id', ""),
              base_authority_service_id: !isEmpty(_get(props, 'row.base_authority_service_id', "")) ? _get(props, 'row.base_authority_service_id', "") : _get(props, 'row.user.base_authority_service_id', "")
            }
          )
      }
      apiUrl={props.uiType === "edit" ? `${internalUserAPI.url}/${props.row.user.id}` : null}
      onRebuildResponseFn={(response) => {
        return {
          title_id: _get(response, 'title.id', ""),
          full_name: _get(response, 'full_name', ""),
          designation: _get(response, 'user.designation', ""),
          nic: _get(response, 'nic', ""),
          email: contactsArrayToObject(_get(response, `user.contact`, []), false, ContactTypeCode.EMAIL),
          contact_number: contactsArrayToObject(_get(response, `user.contact`, []), false, ContactTypeCode.MOBILE),
          active: parseInt(_get(response, 'user.active', 0)) === 0 ? (0) : (1),
          user_name: _get(response, 'user.user_name', ""),
          display_name: _get(response, 'display_name', ""),
          sms_notification: _get(response, 'user.sms_notification', false),
          email_notification: _get(response, 'user.email_notification', false),
          base_pdmt_service_id: _get(response, 'user.base_pdmt_service_id', ""),
          base_authority_service_id: _get(response, 'user.base_authority_service_id', ""),
          base_province_Id: _get(response, 'user.base_pdmt_service_id', ""),
        }
      }}
    >
      <UICard cardHeading="User Information">

        <div className="row">
          <div className="col-md-12 col-sm-12 floatLeft">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <SelectBoxWithState
                  formGroupName={props.formKey}
                  isRequired={true}
                  apiUrl={`${userTitlesListAPI.url}?sort_by=int_disp_order|asc&user_title=true`}
                  apiStateKey={userTitlesListAPI.key}
                  keyName={"id"}
                  valueName={"name"}
                  inputName={"title_id"}
                  labelText="Title"
                  emptySelectOptionTxt={'Select Title'}
                />
              </div>
              <div className="col-md-6 col-sm-12 floatLeft">
                <InputBoxWithState
                  formGroupName={props.formKey}
                  inputName={"full_name"}
                  labelText="Full Name"
                  isRequired={true}
                  maxLength={150}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12 floatLeft">
            <div className="row">
              <div className="col-md-6 col-sm-12 floatLeft">
                <InputBoxWithState
                  formGroupName={props.formKey}
                  inputName={"designation"}
                  labelText="Designation"
                  isRequired={true}
                  inputError={"Designation is required"}
                  maxLength={'150'}
                />
              </div>
              <div className="col-md-6 col-sm-12 floatLeft">
                <InputBoxWithState
                  formGroupName={props.formKey}
                  inputName={"nic"}
                  labelText="NIC"
                  isRequired={true}
                  isDisabled={props.uiType === "edit"}
                  maxLength={'12'}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-sm-12 floatLeft">
            <div className="row">
              <div className="col-md-6 col-sm-12 floatLeft">
                <InputBoxWithState
                  formGroupName={props.formKey}
                  inputName={"email"}
                  labelText="E-mail"
                  isRequired={true}
                  maxLength={100}
                />
              </div>
              <div className="col-md-6 col-sm-12 floatLeft">
                <NumberInputBoxWithState
                  labelText="Mobile Number"
                  isRequired={true}
                  formGroupName={props.formKey}
                  inputName={"contact_number"}
                  isAllowNegative={false}
                  isThousandSeparator={false}
                  decimalScale={0}
                  isAllowLeadingZeros={true}
                  isAllowedFn={(value) => {
                    const checkValue = value.value.toString();
                    return checkValue.length <= 10;
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-sm-12 floatLeft">
            <div className="row">
              <div className="col-md-6 col-sm-12 floatLeft">
                <SelectBoxWithState
                  formGroupName={props.formKey}
                  dataList={[
                    {
                      id: 1,
                      value: "Active",
                    },
                    {
                      id: 0,
                      value: "Inactive",
                    }
                  ]}
                  inputName={"active"}
                  labelText="Status"
                  emptySelectOptionTxt={null}
                />
              </div>
            </div>
          </div>
        </div>
      </UICard>



      <UICard cardHeading="User Login Information" elementStyle="defaultMarginTopBottom">

        <div className="row">
          <div className="col-md-12 col-sm-12 floatLeft">
            <div className="row">
              <div className="col-md-6 col-sm-12 floatLeft">
                <InputBoxWithState
                  formGroupName={props.formKey}
                  inputName={"user_name"}
                  labelText="Username"
                  isRequired={true}
                  isDisabled={props.uiType === "edit"}
                  maxLength={50}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12 floatLeft">
                <InputBoxWithState
                  formGroupName={props.formKey}
                  inputName={"display_name"}
                  labelText="Display Name"
                  isRequired={true}
                  maxLength={'20'}
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <CheckBoxWithState
                labelText={"Notify via SMS"}
                formGroupName={props.formKey}
                inputName={"sms_notification"}
                isDisabled={true}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <CheckBoxWithState
                labelText={"Notify via Email"}
                formGroupName={props.formKey}
                inputName={"email_notification"}
              />
            </div>

            <DialogPopup
              isOpen={resetPopup}
              onClosePopupFn={() => setResetPopup(false)}
              dialogTitle={"Reset Password"}
              isCustomButton={true}
              customButtonComponent={resetButton}
              customButtonComponentProps={props}
              closeButton={{
                btnText: "No",
                startIcon: "far fa-times-circle",
              }
              }
            >
              <div className="removeContent">
                <p>Are you sure you want to Reset the Password?</p>
              </div>
            </DialogPopup>

            {
              (props.uiType === "edit") ? (
                <div className="row">
                  <div className="col-md-12 col-sm-12 floatLeft">
                    <InputButton
                      elementWrapperStyle={'defaultHalfMarginLeftRight'}
                      btnName="resetPassword"
                      btnText={"Reset Password"}
                      onClickBtnFn={() => setResetPopup(true)}
                    />
                  </div>
                </div>
              ) : (null)
            }
          </div>
        </div>
      </UICard>

      <UICard cardHeading="Base Location" elementStyle="defaultMarginTopBottom">

        <div className="row">
          {userRoleType === "ADN_NTL" ? (
            <div className="col-md-6 col-sm-12 floatLeft">
              <SelectBoxWithState
                formGroupName={props.formKey}
                emptySelectOptionTxt={"Select Province Office"}
                apiUrl={(userRoleLevelCode === "ADN_NTL") ? `${provincesOfficesListAPI.url}?active=true` : `${provincesOfficesListAPI.url}?service_id=${serviceId}&active=true`}
                apiStateKey={`${provincesOfficesListAPI.key}`}
                keyName={"service.id"}
                isEnableEmptySelectAfter={true}
                valueName={"service.name"}
                inputName={"base_pdmt_service_id"}
                labelText="Province Office"
                isRequired={userRoleLevelCode === "ADN_NTL" ? true : false}
                onChangeFn={(event) => {
                  formAction.changeInputFn(props.formKey, "base_pdmt_service_id", "", event.value);
                  formAction.changeInputFn(props.formKey, "province-name", "", event.value);

                }}
              />
            </div>
          ) : null}

        </div>
        <div className="row">
          {userRoleType === "ADN_PRV" ?
            <div className="col-md-12 col-sm-12">

              {(props.uiType === "edit") ? (
                <div className="col-md-6 col-sm-12">
                  <SelectBoxWithState
                    formGroupName={props.formKey}
                    emptySelectOptionTxt={"Select Province Office"}
                    apiUrl={`${provincesOfficesListAPI.url}?active=true`}
                    apiStateKey={`${provincesOfficesListAPI.key}_ADN`}
                    keyName={"service.id"}
                    isEnableEmptySelectAfter={true}
                    isDisabled={false}
                    valueName={"service.name"}
                    inputName={"base_pdmt_service_id"}
                    labelText="Province Office"
                    isRequired={true}
                  />
                </div>
              ) : (<div className="col-md-6 col-sm-12">
                <SelectBoxWithState
                  formGroupName={props.formKey}
                  emptySelectOptionTxt={"Select Province Office"}
                  apiUrl={`${provincesOfficesListAPI.url}?active=true`}
                  apiStateKey={`${provincesOfficesListAPI.key}_ADN`}
                  keyName={"service.id"}
                  isEnableEmptySelectAfter={true}
                  isDisabled={true}
                  valueName={"service.name"}
                  inputName={"base_pdmt_service_id"}
                  labelText="Province Office"
                  onChangeFn={(event) => {
                    formAction.changeInputFn(props.formKey, "base_pdmt_service_id", "", event.value);
                    formAction.changeInputFn(props.formKey, "province-name", "", event.value);

                  }}
                />
              </div>)}

              {userRoleType === 'ADN_PRV' ? (
                <div className="fullWidthDiv">
                  {(props.uiType !== "edit") ?
                    <div className="col-md-6 col-sm-12 floatLeft">
                      <SelectBoxWithState
                        formGroupName={props.formKey}
                        isEnableEmptySelectAfter={true}
                        emptySelectOptionTxt={"Select Authority"}
                        apiUrl={`${AuthorityListApi.url}?active=true&pdmt_id=${provinceId}`}
                        apiStateKey={AuthorityListApi.key}
                        dataList={_get(formState, `${props.formKey}.Authority_data`, [])}
                        keyName={"id"}
                        isDisabled={(props.uiType === "edit") && (getProvinceId !== _get(formState[props.formKey], "base_pdmt_service_id", null)) ? true : false}
                        valueName={"name"}
                        inputName={"base_authority_service_id"}
                        labelText="Authority"
                        isRequired={userRoleType === 'ADN_PRV' ? true : false}
                      />
                    </div> : ((getProvinceId === _get(formState[props.formKey], "base_pdmt_service_id", null)) ?

                      <div className="col-md-6 col-sm-12 floatLeft">
                        <SelectBoxWithState
                          formGroupName={props.formKey}
                          isEnableEmptySelectAfter={true}
                          emptySelectOptionTxt={"Select Authority"}
                          apiUrl={`${AuthorityListApi.url}?active=true&pdmt_id=${provinceId}`}
                          apiStateKey={AuthorityListApi.key}
                          keyName={"id"}
                          dataList={_get(formState, `${props.formKey}.Authority_data`, [])}
                          isDisabled={(props.uiType === "edit") && (getProvinceId !== _get(formState[props.formKey], "base_pdmt_service_id", null)) ? true : false}
                          valueName={"name"}
                          inputName={"base_authority_service_id"}
                          labelText="Authority"
                          isRequired={userRoleType === 'ADN_PRV' ? true : false}
                        />
                      </div> : null)
                  }

                </div>
              ) : (null)}
            </div>
            : null}
        </div>

      </UICard>
      {
        (props.uiType === "edit") ? (
          (_get(formState, `${props.formKey}.base_province_Id`, null) === _get(formState, `${props.formKey}.base_pdmt_service_id`, null)) ?
            <div className="col-md-12 floatRight">
              <SubmitButton
                btnText="Update"
                elementStyle="btnWrapper floatRight"
                startIcon={"far fa-edit"}
                formGroupName={props.formKey}
                dataTableKey={props.groupKey}
                isValidate={true}
                flashMessages={{
                  "success": {
                    status: true,
                    message: "User Account is successfully updated.",
                    messageType: "success"
                  }
                }}
                mapValidationErrorObj={{
                  user_name: "The Username is required",
                  nic: "The NIC is required",
                  email: "The Email is required",
                  sms_notification: "The Notify via SMS is required",
                  display_name: "Display Name already exists.",
                  contact_number: "Contact Number must be a valid phone number"
                }}
                validationObject={{
                  fileds: {
                    title_id: "Title",
                    full_name: "Full Name",
                    designation: "Designation",
                    nic: "NIC",
                    email: "E-mail",
                    contact_number: "Mobile Number",
                    user_name: "Username",
                    display_name: "Display Name",
                    sms_notification: "Notify via SMS",
                    base_pdmt_service_id: "Province Office",
                    base_authority_service_id: "Authority"
                  },
                  rules: {
                    title_id: "required",
                    full_name: "required|max:150|matchRegex:AlphabetsOnlyWithSpacesAndDotsRegexPattern",
                    designation: "required|max:150",
                    nic: "required|min:10",
                    email: "required|email|max:100",
                    contact_number: "min:9|max:10|required",
                    user_name: "required|max:50",
                    display_name: "required|max:20",
                    sms_notification: "requiredIf:sms_notification,false",
                    base_authority_service_id: userRoleType === 'ADN_PRV' && (getProvinceId === _get(formState[props.formKey], "base_pdmt_service_id", null)) ? "required" : "alphaSpecial",
                    base_pdmt_service_id: userRoleType === 'ADN_PRV' ? "required" : "alphaSpecial"
                  },
                  message: {
                    "sms_notification.requiredIf": "The Notify via SMS is required.",
                    "nic": "Invalid NIC format.",
                  },
                }}
                callApiObject={{
                  isSetHeaders: true,
                  multipart: false,
                  method: "put",
                  onUpload: false
                }}
                apiDataStoringObject={{
                  setLoader: true,
                  storingType: apiResponseStoringType.responseLinkToFrom
                }}
                onGetAPIEndPointFn={(formState) => {
                  return {
                    "url": `${internalUserAPI.url}/${props.row.user.id}`,
                    "key": internalUserAPI.key
                  }
                }}
                onChangeRequestBodyFn={(fromObject) => {

                  if ((getProvinceId === _get(fromObject, "base_pdmt_service_id", null)) || (userRoleLevelCode === "ADN_NTL")) {
                    return getDataByFormObject({
                      title_id: fromObject.title_id,
                      full_name: fromObject.full_name,
                      designation: fromObject.designation,
                      nic: fromObject.nic,
                      email: fromObject.email,
                      contact_number: fromObject.contact_number,
                      active: parseInt(fromObject.active) === 1 ? true : false,
                      user_name: fromObject.user_name,
                      display_name: fromObject.display_name,
                      email_notification: fromObject.email_notification,
                      sms_notification: fromObject.sms_notification,
                      base_pdmt_service_id: fromObject.base_pdmt_service_id,
                      base_authority_service_id: fromObject.base_authority_service_id,
                      account_type: "INT",
                      preferred_language: "EN",
                    })
                  } else {
                    return getDataByFormObject({
                      title_id: fromObject.title_id,
                      full_name: fromObject.full_name,
                      designation: fromObject.designation,
                      nic: fromObject.nic,
                      email: fromObject.email,
                      contact_number: fromObject.contact_number,
                      active: parseInt(fromObject.active) === 1 ? true : false,
                      user_name: fromObject.user_name,
                      display_name: fromObject.display_name,
                      email_notification: fromObject.email_notification,
                      sms_notification: fromObject.sms_notification,
                      base_pdmt_service_id: fromObject.base_pdmt_service_id,
                      base_authority_service_id: null,
                      account_type: "INT",
                      preferred_language: "EN",

                    })


                  }

                }}
                onResponseCallBackFn={(response, error) => {
                  //props.onClosePopupFn();
                  // window.location.reload()
                }}

              />
            </div> :
            <>
              <div className="col-md-12 floatRight">
                <InputButton
                  btnText={'Update'}
                  elementStyle="btnWrapper floatRight"
                  onClickBtnFn={() => setIsopen(true)}
                  startIcon={"far fa-edit"}
                />
              </div>
              <DialogPopup
                isOpen={isopen}
                onClosePopupFn={DialogCloseFn}
                dialogTitle={"User Update"}
                closeButton={{
                  btnText: "No",
                  startIcon: "far fa-times-circle",
                }}
                isCustomButton={true}
                customButtonComponent={UpdateButton}
                customButtonComponentProps={{ propsKey: props, getProvinceId: getProvinceId, userRoleType: userRoleType, userRoleLevelCode: userRoleLevelCode }}

              >
                <div className="row">
                  <div className="col-md-12">
                    <strong>Are you sure you want to continue in this Province? </strong>
                  </div>
                </div>
                <div className="col-md-12 floatRight">

                </div>
              </DialogPopup>
            </>

        ) : (null)
      }
    </FormWrapper>
  )
}

const UpdateButton = (props) => {
  const [formState, formAction] = useContext(FormContext);
  return (
    <SubmitButton
      btnText="yes"
      elementStyle="btnWrapper floatRight"
      startIcon={'fas fa-check'}
      formGroupName={props.propsKey.formKey}
      dataTableKey={props.propsKey.groupKey}
      isValidate={true}
      flashMessages={{
        "success": {
          status: true,
          message: "User Account is successfully updated.",
          messageType: "success"
        }
      }}
      mapValidationErrorObj={{
        user_name: "The Username is required",
        nic: "The NIC is required",
        email: "The Email is required",
        sms_notification: "The Notify via SMS is required",
        display_name: "Display Name already exists.",
        contact_number: "Contact Number must be a valid phone number"
      }}
      validationObject={{
        fileds: {
          title_id: "Title",
          full_name: "Full Name",
          designation: "Designation",
          nic: "NIC",
          email: "E-mail",
          contact_number: "Mobile Number",
          user_name: "Username",
          display_name: "Display Name",
          sms_notification: "Notify via SMS",
          base_pdmt_service_id: "Province Office",
          base_authority_service_id: "Authority"
        },
        rules: {
          title_id: "required",
          full_name: "required|max:150|matchRegex:AlphabetsOnlyWithSpacesAndDotsRegexPattern",
          designation: "required|max:150",
          nic: "required|min:10",
          email: "required|email|max:100",
          contact_number: "min:9|max:10|required",
          user_name: "required|max:50",
          display_name: "required|max:20",
          sms_notification: "requiredIf:sms_notification,false",
          base_authority_service_id: props.userRoleType === 'ADN_PRV' && (props.getProvinceId === _get(formState[props.propsKey.formKey], "base_pdmt_service_id", null)) ? "required" : "alphaSpecial",
          base_pdmt_service_id: props.userRoleType === 'ADN_PRV' ? "required" : "alphaSpecial"
        },
        message: {
          "sms_notification.requiredIf": "The Notify via SMS is required.",
          "nic": "Invalid NIC format.",
        },
      }}
      callApiObject={{
        isSetHeaders: true,
        multipart: false,
        method: "put",
        onUpload: false
      }}
      apiDataStoringObject={{
        setLoader: true,
        storingType: apiResponseStoringType.responseLinkToFrom
      }}
      onGetAPIEndPointFn={(formState) => {
        return {
          "url": `${internalUserAPI.url}/${props.propsKey.row.user.id}`,
          "key": internalUserAPI.key
        }
      }}
      onChangeRequestBodyFn={(fromObject) => {

        if ((props.getProvinceId === _get(fromObject, "base_pdmt_service_id", null)) || (props.userRoleLevelCode === "ADN_NTL")) {
          return getDataByFormObject({

            ...fromObject,
            account_type: "INT",
            preferred_language: "EN",
            active: parseInt(fromObject.active) === 1 ? true : false,
          })
        } else {
          return getDataByFormObject({
            title_id: fromObject.title_id,
            full_name: fromObject.full_name,
            designation: fromObject.designation,
            nic: fromObject.nic,
            email: fromObject.email,
            contact_number: fromObject.contact_number,
            active: parseInt(fromObject.active) === 1 ? true : false,
            user_name: fromObject.user_name,
            display_name: fromObject.display_name,
            email_notification: fromObject.email_notification,
            sms_notification: fromObject.sms_notification,
            base_pdmt_service_id: fromObject.base_pdmt_service_id,
            base_authority_service_id: null,
            account_type: "INT",
            preferred_language: "EN",

          })


        }

      }}
      onResponseCallBackFn={(response, error) => {
        props.onClosePopupFn();
        window.location.reload()



      }}
    />
  )
}
const resetButton = (props) => {
  return (
    <SubmitButton
      elementWrapperStyle={"floatLeft"}
      btnName={"ResetPassword"}
      btnText={"Yes"}
      startIcon={'fas fa-check'}
      isValidate={false}
      flashMessages={{
        "success": {
          status: true,
          message: "User password is reset successfully.",
          messageType: "success"
        }
      }}
      callApiObject={{
        isSetHeaders: true,
        multipart: false,
        method: "put",
        onUpload: false
      }}
      apiDataStoringObject={{
        setLoader: true,
        storingType: apiResponseStoringType.responseLinkToFrom
      }}
      onGetAPIEndPointFn={(formState) => {
        return {
          "url": `${userPasswordResetAPI.url}/${_get(props, 'row.user.id', null)}`,
          "key": userPasswordResetAPI.key
        }
      }}
      onResponseCallBackFn={(response, error) => {
        if (
          response.length === undefined &&
          error === undefined &&
          response.data.errors === undefined
        ) {
          props.onClosePopupFn();
        }
      }}
    />
  )
}

const DefaultRoles = (props) => {

  const [formState, formAction] = useContext(FormContext);
  const [coreState, coreAction] = useContext(CoreContext);
  const [responseData, setresponseData] = useState(false);
  const [authStatus] = useContext(AuthContext);
  const serviceId = _get(authStatus, "authUser.service_id", "");
  const pdmtId = _get(authStatus, "authUser.pdmt_id", "");
  const userRoleLevelCode = _get(authStatus, "authUser.role_level_code", "");
  const userRoleType = _get(authStatus, "authUser.role_level_code", "");
  const [provinceName, setProvince] = useState()

  useEffect(() => {
    if (_get(formState, `${props.formKey}.is_internal`, false) === false) {
      formAction.changeInputFn(props.formKey, "internal_role_id", null);
      formAction.changeInputFn(props.formKey, "internal_pdmt_id", null);
      formAction.changeInputFn(props.formKey, "internal_authority_id", null);
      formAction.changeInputFn(props.formKey, "intRoleLevelCode", null);
    }
    if (_get(formState, `${props.formKey}.is_admin`, false) === false) {
      formAction.changeInputFn(props.formKey, "admin_role_id", null);
      formAction.changeInputFn(props.formKey, "admin_pdmt_id", null);
      formAction.changeInputFn(props.formKey, "admRoleLevelCode", null);
    }
  }, [(_get(formState, `${props.formKey}.is_internal`, "")), (_get(formState, `${props.formKey}.is_admin`, ""))]);

  useEffect(() => {
    if (responseData === false) {
      formAction.changeInputFn(props.formKey, "internal_authority_id", null);
    }
  }, [_get(formState, `${props.formKey}.internal_pdmt_id`, null)]);

  useEffect(() => {
    if (responseData === false) {
      formAction.changeInputFn(props.formKey, "internal_authority_id", null);
      formAction.changeInputFn(props.formKey, "internal_pdmt_id",  _get(formState,`${props.formKey}.province_data.id`,""));
    }
  }, [_get(formState, `${props.formKey}.intRoleLevelCode`, null)]);

  useEffect(() => {
    if (responseData === false) {
      if(_get(formState, `${props.formKey}.admRoleLevelCode`, null)==="ADN_NTL"){
        formAction.changeInputFn(props.formKey, "admin_pdmt_id", null);
      }
      else{
        formAction.changeInputFn(props.formKey, "admin_pdmt_id",  _get(formState,`${props.formKey}.province_data.id`,""));
      }
      
    }
  }, [_get(formState, `${props.formKey}.admRoleLevelCode`, null)]);

   
  const getAdminProvinceFn = (pdmt) => {
    if (pdmt === null) {
      setProvince("")
    } else {
      callApi(`${provincesOfficesListAPI.url}?active=true`)
        .headers(true)
        .method("get")
        .send((err, result) => {
          if (!err) {
            _get(result, "data.data", []).filter((item) => {
              if (item.service.id === pdmt) {
                setProvince(item)
                formAction.changeInputFn(props.formKey, "province_data", "", item)
                if (userRoleLevelCode === "ADN_NTL") {
                  formAction.changeInputFn(props.formKey, "admin_pdmt_id", "", item.id)
                } else {
                  formAction.changeInputFn(props.formKey, "internal_pdmt_id", "", item.id)
                }
              }
            })
          }
        });
    }
  }

  useEffect(() => {
    if (props.uiType === "create") {
      getAdminProvinceFn(_get(props, 'row.base_pdmt_service_id', null))
    }
    else {
      callApi(`${internalUserAPI.url}/${props.uiType === "create-edit" ? _get(props, 'row.id', null) : _get(props, 'row.user.id', null)}`)
        .headers(true)
        .method("get")
        .send((err, result) => {
          if (!err) {
            getAdminProvinceFn(_get(result, "data.data.user.base_pdmt_service_id", null))
          }
        });
    }
  }, [internalUserAPI, userRoleType, userRoleLevelCode, _get(props, 'row.user.id', null)])


  return (
    <FormWrapper
      setGroupName={props.formKey}
      formGroupLinkWith={props.formKey}
      apiUrl={props.uiType !== "create" ? (`${userDefaultDelegationAPI.url}/${props.uiType === "create-edit" ? _get(props, 'row.id', null) : _get(props, 'row.user.id', null)}`) : (null)}
      onDestroyUnsetFormObject={false}
      setFormObject={
        {
          is_admin: _get(props, "row.is_admin", true),
          is_internal: _get(props, "row.is_internal", true),
         
        }
      }
      onRebuildResponseFn={(response) => {
        setresponseData(true);
        const internalRoleId = _get(response, 'internal.role.id', null);
        const isInternal = Object.keys(response.internal).length !== 0;
        const isAdmin = Object.keys(response.admin).length !== 0;
        const adminRoleId = _get(response, 'admin.role.id', null);
        if (internalRoleId) {
          const intRid = { value: internalRoleId }
          roleLevelChangeFn(intRid, formAction, props.formKey, 'intRole');
        }
        if (adminRoleId) {
          const admRid = { value: adminRoleId }
          roleLevelChangeFn(admRid, formAction, props.formKey, 'admRole');
        }
        return {
          "internal_role_id": internalRoleId,
          "province": _get(response, 'internal.authority.province.code', null),
          "internal_authority_id": _get(response, 'internal.authority[0].id', null),
          "internal_pdmt_id":!isEmpty(_get(response, 'internal.authority[0].pdmt[0].id', _get(response, 'internal.pdmt[0].id', null)))?_get(response, 'internal.authority[0].pdmt[0].id', _get(response, 'internal.pdmt[0].id', null)):_get(formState,`${props.formKey}.province_data.id`,""),
          "admin_role_id": adminRoleId,
          "admin_pdmt_id": _get(response, 'admin.pdmt[0].id', _get(formState,`${props.formKey}.province_data.id`,"")),
          // "is_admin": isAdmin,
          // "is_internal": isInternal,
          "is_admin": isAdmin === true ? true : (userRoleLevelCode === "ADN_NTL" ? true : false),
          "is_internal": isInternal === true ? true : (userRoleType === "ADN_PRV" ? true : false),
          "logged_user_role_type": userRoleType,
          "_onLoad": false,
        }
      }}
    >
      <UICard cardHeading="Default Role & Location" elementStyle="defaultMarginBottom">
        <div className="row">
          {userRoleType === "ADN_PRV" ?
            <div className="col-md-12 col-sm-12">
              <div className="col-md-6 col-sm-12 ">
                <CheckBoxWithState
                  labelText={"Internal Module"}
                  formGroupName={props.formKey}
                  inputName={"is_internal"}
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <SelectBoxWithState
                  formGroupName={props.formKey}
                  emptySelectOptionTxt={"Select User Role"}
                  apiUrl={`${userRolesListAPI.url}/list?role_type=INT_APP&active=true`}
                  apiStateKey={`${userRolesListAPI.key}_INT`}
                  keyName={"id"}
                  valueName={"name"}
                  inputName={"internal_role_id"}
                  labelText="User Role"
                  isRequired={true}
                  isDisabled={!_get(formState, `${props.formKey}.is_internal`, false)}
                  onChangeFn={(event) => {
                    roleLevelChangeFn(event, formAction, props.formKey, 'intRole');
                    setresponseData(false);
                  }}
                />
              </div>
              {_get(formState, `${props.formKey}.intRoleLevelCode`, null) === 'INT_PRV' && _get(formState, `${props.formKey}.is_internal`, false) === true ? (
                <div className="col-md-6 col-sm-12">
                  <SelectBoxWithState
                    formGroupName={props.formKey}
                    inputName={"internal_pdmt_id"}
                    apiUrl={(userRoleLevelCode === "ADN_NTL") ? `${pdmtListAPI.url}/list?active=true` : `${pdmtListAPI.url}/list?service_id=${serviceId}&active=true`}
                    apiStateKey={`${pdmtListAPI.key}?active=true`}
                    keyName="id"
                    valueName="service.name"
                    emptySelectOptionTxt={isEmpty(provinceName) ? "Select Province Office" : provinceName.service.name}
                    isDisabled={!_get(formState, `${props.formKey}.is_internal`, false)}
                    labelText="Province Office"
                    isRequired={true}
                    onChangeFn={(event) => {
                      setresponseData(false);
                    }}
                  />
                </div>
              ) : (null)}

              {_get(formState, `${props.formKey}.intRoleLevelCode`, null) === 'INT_AU' && _get(formState, `${props.formKey}.is_internal`, false) === true ? (
                <div className="fullWidthDiv">
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <SelectBoxWithState
                      formGroupName={props.formKey}
                      inputName={"internal_pdmt_id"}
                      apiUrl={(userRoleLevelCode === "ADN_NTL") ? `${pdmtListAPI.url}/list?active=true` : `${pdmtListAPI.url}/list?service_id=${serviceId}&active=true`}
                      apiStateKey={pdmtListAPI.key}
                      keyName="id"
                      valueName="service.name"
                      emptySelectOptionTxt={isEmpty(provinceName) ? "Select Province Office" : provinceName.service.name}
                      isDisabled={!_get(formState, `${props.formKey}.is_internal`, false)}
                      labelText="Province Office"
                      isRequired={true}
                      onChangeFn={(event) => {
                        setresponseData(false);
                      }}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <SelectBoxWithState
                      formGroupName={props.formKey}
                      emptySelectOptionTxt={"Select Authority"}
                      apiUrl={`${AuthorityListApi.url}?active=true&pdmt_id=${_get(formState, `${props.formKey}.internal_pdmt_id`, pdmtId)}`}
                      apiStateKey={AuthorityListApi.key}
                      keyName={"id"}
                      valueName={"service.name"}
                      inputName={"internal_authority_id"}
                      labelText="Authority"
                      isDisabled={false}
                      isRequired={true}
                    />
                  </div>
                </div>
              ) : (null)}
            </div>
            : null}
        </div>
        <div className="row">
          {userRoleType === "ADN_NTL" ? (
            <div className="col-md-12 col-sm-12 floatLeft">
              <div className="col-md-6 col-sm-12 ">
                <CheckBoxWithState
                  labelText={"Admin Module"}
                  formGroupName={props.formKey}
                  inputName={"is_admin"}
                />
              </div>

              <div className="col-md-6 col-sm-12 floatLeft">
                <SelectBoxWithState
                  formGroupName={props.formKey}
                  emptySelectOptionTxt={"Select User Role"}
                  apiUrl={`${userRolesListAPI.url}/list?role_type=ADN_APP&active=true`}
                  apiStateKey={`${userRolesListAPI.key}_ADN`}
                  keyName={"id"}
                  valueName={"name"}
                  inputName={"admin_role_id"}
                  labelText="User Role"
                  isDisabled={!_get(formState, `${props.formKey}.is_admin`, false)}
                  onChangeFn={(event) => {
                    roleLevelChangeFn(event, formAction, props.formKey, 'admRole');
                    setresponseData(false);

                  }}
                  isRequired={true}
                />
              </div>
              {_get(formState, `${props.formKey}.admRoleLevelCode`, null) === 'ADN_PRV' && _get(formState, `${props.formKey}.is_admin`, false) === true ? (
                <div className="col-md-6 col-sm-12 floatLeft">
                  <SelectBoxWithState
                    formGroupName={props.formKey}
                    emptySelectOptionTxt={isEmpty(provinceName) ? "Select Province Office" : provinceName.service.name}
                    apiUrl={(userRoleLevelCode === "ADN_NTL") ? `${provincesOfficesListAPI.url}?active=true` : `${provincesOfficesListAPI.url}?service_id=${serviceId}&active=true`}
                    apiStateKey={`${provincesOfficesListAPI.key}_ADN`}
                    keyName={"id"}
                    valueName={"service.name"}
                    inputName={"admin_pdmt_id"}
                    labelText="Province Office"
                    isDisabled={true}
                    isRequired={true}
                    onChangeFn={(event) => {
                      setresponseData(false);
                    }}
                  />
                </div>
              ) : (null)}
            </div>
          ) : null}

        </div>
      </UICard>
      {
        (props.uiType === "edit") ? (
          <div className="col-md-12 floatLeft">
            <SubmitButton
              btnText="Update"
              elementStyle="btnWrapper floatRight"
              startIcon={"far fa-edit"}
              formGroupName={props.formKey}
              isValidate={true}
              mapValidationErrorObj={{
                internal_role_id: "The User Role is required",
                internal_pdmt_id: "The Province Office is required",
                internal_authority_id: "The Authority is required",
                admin_role_id: "The User Role is required",
                admin_pdmt_id: "The Province Office is required",
                is_internal: "The Internal Module should be selected",
                is_admin: "The Admin Module should be selected"
              }}
              validationObject={{
                fileds: {
                  internal_role_id: "User Role",
                  internal_pdmt_id: "Province Office",
                  internal_authority_id: "Authority",
                  admin_role_id: "User Role",
                  admin_pdmt_id: "Province Office"

                },
                rules: {
                  internal_role_id: _get(formState, `${props.formKey}.is_internal`, false) === true ? "required" : "alphaSpecial",
                  internal_pdmt_id: "alphaSpecial",
                  //internal_pdmt_id: _get(formState, `${props.formKey}.is_internal`, false) === true ? "required" : "alphaSpecial",
                  internal_authority_id: (_get(formState, `${props.formKey}.is_internal`, false) === true && _get(coreState, `apiResponses.${`${userRolesListAPI.key}_INT`}.result`, []).find(item => item.id === _get(formState, `${props.formKey}.internal_role_id`, null))?.prefix === "OX") ? "required" : "alphaSpecial",
                  admin_role_id: _get(formState, `${props.formKey}.is_admin`, false) === true && userRoleLevelCode === "ADN_NTL" ? "required" : "alphaSpecial",
                  admin_pdmt_id: "alphaSpecial"
                  //admin_pdmt_id: _get(formState, `${props.formKey}.is_admin`, false) === true && (_get(coreState, `apiResponses.${`${userRolesListAPI.key}_ADN`}.result`, []).find(item => item.id === _get(formState, 'defaultRole.admin_role_id', ""))?.role_level_code.code) === 'ADN_PRV' ? "required" : "alphaSpecial"

                },
                message: {

                },
              }}
              flashMessages={{
                "success": {
                  status: true,
                  message: "Default roles are successfully updated.",
                  messageType: "success"
                }
              }}
              callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "put",
                onUpload: false
              }}
              apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.responseLinkToFrom
              }}
              onGetAPIEndPointFn={(formState) => {
                if (formState["logged_user_role_type"] === "ADN_PRV") {
                  return {
                    "url": `${userInternalDefaultDelegationAPI.url}/${props.row.user.id}`,
                    "key": userInternalDefaultDelegationAPI.key
                  };
                } else {
                  return {
                    "url": `${userAdminDefaultDelegationAPI.url}/${props.row.user.id}`,
                    "key": userAdminDefaultDelegationAPI.key
                  };
                }
              }}
              onChangeRequestBodyFn={(fromObject) => {
                if (_get(fromObject, "logged_user_role_type", "") === "ADN_PRV") {
                  return {
                    "is_internal": _get(fromObject, "is_internal", ""),
                    "internal_authority_id": _get(fromObject, "internal_authority_id", ""),
                    "internal_pdmt_id": provinceName.id,
                    "internal_role_id": _get(fromObject, "internal_role_id", "")
                  };
                } else {
                  return {
                    "is_admin": _get(fromObject, "is_admin", ""),
                    "admin_pdmt_id": _get(coreState, `apiResponses.${`${userRolesListAPI.key}_ADN`}.result`, []).find(item => item.id === _get(formState, 'defaultRole.admin_role_id', ""))?.role_level_code.code === 'ADN_PRV' ? provinceName.id : _get(fromObject, "admin_pdmt_id", ""),
                    "admin_role_id": _get(fromObject, "admin_role_id", ""),
                  };
                }
              }}

            />
          </div>
        ) : (null)
      }
    </FormWrapper >
  )
}

const AdditionalRoles = (props) => {
  const dataTableKey = "AdditionalRolesKey";
  const userId = props.uiType === "edit" ? props.row.user.id : props.row.id;
  return (
    <Fragment>
      <DataTable
        elementWrapperStyle="defaultMarginBottom"
        tableName="Additional Roles & Locations"
        apiUrl={`${userDelegationAPI.url}/${userId}/list?sort_by=created_at|desc&default_delegation=false&userType=INT`}
        dataTableKey={dataTableKey}
        isSetAction={true}
        actionTypeList={[
          {
            actionType: actionBtnTypes.create,
            modelContentElement: AdditionalRolesAndLocations,
            parentToModelProps: {
              formKey: "additionalRoles",
              uiType: "add",
              userId: userId
            },
            dialogPopupProps: {
              isFullScreen: false,
              dialogTitle: "Add Additional Role & Location",
              closeButton: {
                btnText: "Cancel",
                startIcon: "far fa-times-circle",
              },
              isConfirmationPopup: true,
              isCustomButton: true,
              customButtonComponent: CustomButtons,
              customButtonComponentProps: {
                user_id: props.uiType === "edit" ? (_get(props, 'row.user.id', null)) : (_get(props, 'row.id', null)),
                uiType: "add",
              }
            },

          },
          {
            actionType: actionBtnTypes.edit,
            actionBtn: { tooltip: "Edit" },
            modelContentElement: AdditionalRolesAndLocations,
            dialogPopupProps: {
              isFullScreen: false,
              dialogTitle: "Edit Additional Role & Location",
              closeButton: {
                btnText: "Cancel",
                startIcon: "far fa-times-circle",
              },
              isConfirmationPopup: true,
              isCustomButton: true,
              customButtonComponent: CustomButtons,
              customButtonComponentProps: {
                uiType: "Edit",
                user_id: props.uiType === "edit" ? _get(props, 'row.user.id', null) : _get(props, 'row.id', null),
              }
            },
            parentToModelProps: {
              uiType: "edit",
              formKey: "additionalRoles",
            },
          },
          {
            actionType: actionBtnTypes.delete,
            actionBtn: { tooltip: "Remove" },
            callAPiPara: {
              method: "delete",
              dataStoringType: "DATA_TABLE_LINKED_FORM",
              dataStoringKeys: {
                "dataTable": userDelegationAPI.key,
                "form": userDelegationAPI.key + "_remove",
              },
            },
            isValidate: false,
            modelContentElement: RemoveFrom,
            getAPIUrlFn: (data) => {
              return {
                "url": userDelegationAPI.url + data.row.id,
                "key": userDelegationAPI.key
              }
            },
            isCustomButton: true,
            getRequestBodyFN: (formData) => { return getDataByFormObject(formData.formObject) },
            dialogPopupProps: {
              customButtonComponent: CustomButtons,
              closeButton: {
                btnText: "No",
                startIcon: "fas fa-times",
              },
              isCustomButton: true,
              isFullScreen: false,
              dialogTitle: "Please confirm",
              customButtonComponentProps: {
                uiType: "remove",
                dataTableKey: userDelegationAPI.key,
                user_id: props.row.id
              }
            },
            parentToModelProps: {
              formKey: userDelegationAPI.key + "_remove",
              dataTableKey: userDelegationAPI.key,
              uiType: "remove"
            },
          }
        ]}
        tableHeaderList={[
          {
            displayName: "Province Office ",
            key: "authority",
            isSort: false,
            onPrintFn: ({ orgData }) => (_get(orgData, 'pdmt[0].code', null) ? _get(orgData, 'pdmt[0].service.name', 'N/A') : _get(orgData, 'authority[0].pdmt[0].service.name', 'N/A'))
          },
          {
            displayName: "Authority",
            key: "orgData.authority[0].service.name",
            isSort: false,
            onPrintFn: ({ orgData }) => (_get(orgData, 'authority[0].service.name', null) ? _get(orgData, 'authority[0].service.name', 'N/A') : _get(orgData, 'authority[0].service.name', 'N/A'))
          },

          {
            displayName: "User Role",
            key: "role.name",
            isSort: false,
          },
          {
            displayName: "Status",
            key: "role.status",
            isSort: false,
            onPrintFn: ({ status }) => (parseInt(status) === 1 ? "Active" : "Inactive")
          },
          {
            displayName: "Expire Date",
            key: "expiry_at",
            isSort: false,
            onPrintFn: (raw) =>
              dateObjectToString(
                raw.expiry_at,
                "dd/mm/YY"
              ),
          },
        ]}
      />
    </Fragment>
  )
}

const CreateStepsWrapper = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [activeStepType, setActiveStepType] = useState({
    "0_step": "create",
    "1_step": "create",
    "2_step": "create",
  });

  const [currentRow, setRow] = useState({});
  const [coreState, coreAction] = useContext(CoreContext);
  const [formState, formAction] = useContext(FormContext);
  const [authStatus] = useContext(AuthContext);
  const [intValidation, setIntValidation] = useState({});
  const [admValidation, setAdmValidation] = useState({});
  const [, uiAction] = useContext(UIContext);

  const formKey = 'userCreate';
  const userRoleType = _get(authStatus, "authUser.role_level_code", "");
  console.log("ADM" + JSON.stringify(props))
  const getBaseAuthorityValiadtation = () => {
    if (userRoleType == 'ADN_PRV') {
      return 'required'
    }
    else {
      return 'max:200'
    }
  }

  useEffect(() => {
    if (_get(formState, 'defaultRole.is_internal', false) === true) {
      setIntValidation(prevState => ({
        fileds: {
          ...prevState.fileds,
          internal_role_id: "User Role",
          internal_pdmt_id: "Province Office",
        },
        rules: {
          ...prevState.rules,
          internal_role_id: "required",
          internal_pdmt_id: "alphaSpecial",
        }

      }));
    } else if (_get(formState, 'defaultRole.is_internal', false) === false) {
      setIntValidation(prevState => ({
        fileds: {
          ...prevState.fileds,
          is_internal: "Internal Module",
          internal_role_id: "User Role",

        },
        rules: {
          ...prevState.rules,
          internal_role_id: "required",
          is_internal: "required",

        }

      }));
    }

    if (_get(formState, 'defaultRole.is_admin', false) === true) {
      setAdmValidation(prevState => ({
        fileds: {
          ...prevState.fileds,
          admin_role_id: "User Role",
          admin_pdmt_id: "Province Office",
          is_admin: "Admin Module"

        },
        rules: {
          ...prevState.rules,
          admin_role_id: "required",
          admin_pdmt_id: (_get(coreState, `apiResponses.${`${userRolesListAPI.key}_ADN`}.result`, []).find(item => item.id === _get(formState, 'defaultRole.admin_role_id', ""))?.role_level_code.code) === 'ADN_PRV' ? "required" : "alphaSpecial",
          is_admin: "required"
        }
      }));

    }
    else {
      setAdmValidation(prevState => ({
        fileds: {
          ...prevState.fileds,
          admin_role_id: "User Role",
          is_admin: "Admin Module"

        },
        rules: {
          ...prevState.rules,
          admin_role_id: "required",
          is_admin: "required"
        }
      }));
    }
  }, [_get(formState, 'defaultRole', false)]);

  const onGetActionBtnConf = () => {
    const firstStep = {
      formGroupName: formKey,
      isValidate: true,
      flashMessages: {},
      mapValidationErrorObj: {
        user_name: "Username already exits",
        nic: "NIC already exits",
        email: "Email already exits",
        display_name: "Display Name already exists.",
        contact_number: "Contact number must be a valid phone number"
      },
      validationObject: {
        fileds: {
          title_id: "Title",
          full_name: "Full Name",
          designation: "Designation",
          nic: "NIC",
          email: "E-mail",
          contact_number: "Mobile Number",
          user_name: "Username",
          display_name: "Display Name",
          base_pdmt_service_id: "Province Office",
          base_authority_service_id: "Authority"
        },
        rules: {
          title_id: "required",
          full_name: "required|max:150|matchRegex:AlphabetsOnlyWithSpacesAndDotsRegexPattern",
          designation: "required|max:150",
          nic: "required|min:10|max:12",
          email: "required|email|max:100",
          contact_number: "required|max:10|min:9",
          user_name: "required|max:50",
          display_name: "required|max:20",
          base_pdmt_service_id: userRoleType === "ADN_NTL" ? "required" : "alphaSpecial",
          base_authority_service_id: userRoleType === "ADN_PRV" ? "required" : "alphaSpecial"
        },
        message: {
          "nic.min": "Invalid NIC format.",
          "nic.max": "Invalid NIC format.",
          "full_name.matchRegex": "The Full Name should only contain alphabetical letters.",
        }
      },
      callApiObject: {
        isSetHeaders: true,
        multipart: false,
        method: activeStepType["0_step"] === "create" ? "post" : "put",
        onUpload: false
      },
      onGetAPIEndPointFn: (formState) => {
        return activeStepType["0_step"] === "create" ? internalUserAPI : {
          "url": `${tempInternalUserAPI.url}/${currentRow.id}`,
          "key": tempInternalUserAPI.key
        }
      },
      onChangeRequestBodyFn: (fromObject) => {
        return getDataByFormObject({
          title_id: fromObject.title_id,
          full_name: fromObject.full_name,
          designation: fromObject.designation,
          nic: fromObject.nic,
          contact_number: fromObject.contact_number,
          active: parseInt(fromObject.active) === 1 ? true : false,
          user_name: fromObject.user_name,
          display_name: fromObject.display_name,
          email_notification: fromObject.email_notification,
          sms_notification: fromObject.sms_notification,
          base_pdmt_service_id: fromObject.base_pdmt_service_id,
          base_authority_service_id: fromObject.base_authority_service_id,
          account_type: "INT",
          preferred_language: "EN",
          email: (fromObject.email).trim()
        })
      },
      onResponseCallBackFn: (error, result) => {
        if (!error) {
          setRow(result.data.data);
          setActiveStep(activeStep + 1)
        }
      }
    };

    const secondStep = {
      formGroupName: 'defaultRole',
      isValidate: true,
      validationObject: userRoleType == 'ADN_PRV' ? intValidation : admValidation,
      mapValidationErrorObj: {
        internal_role_id: "The User Role is required",
        internal_pdmt_id: "The Province Office is required",
        internal_authority_id: "The Authority is required",
        admin_role_id: "The User Role is required",
        admin_pdmt_id: "The Province Office is required"
      },
      flashMessages: {},
      callApiObject: {
        isSetHeaders: true,
        multipart: false,
        method: activeStepType["1_step"] === "create" ? "post" : "put",
        onUpload: false
      },
      onGetAPIEndPointFn: (formState) => {
        if (userRoleType === "ADN_PRV") {
          return {
            "url": `${userInternalDefaultDelegationAPI.url}/${currentRow.id}`,
            "key": userInternalDefaultDelegationAPI.key
          };
        } else {
          return {
            "url": `${userAdminDefaultDelegationAPI.url}/${currentRow.id}`,
            "key": userAdminDefaultDelegationAPI.key
          };
        }
      },
      onChangeRequestBodyFn: (fromObject) => {
        console.log("pdmtid===",_get(fromObject, "internal_pdmt_id", ""))
        if (userRoleType === "ADN_PRV") {
          return {
            "is_internal": _get(fromObject, "is_internal", ""),
            "internal_authority_id": _get(fromObject, "internal_authority_id", ""),
            "internal_pdmt_id": _get(fromObject, "internal_pdmt_id", ""),
            "internal_role_id": _get(fromObject, "internal_role_id", "")
          };
        } else {
          return {
            "is_admin": _get(fromObject, "is_admin", ""),
            "admin_pdmt_id": _get(fromObject, "admin_pdmt_id", ""),
            "admin_role_id": _get(fromObject, "admin_role_id", ""),
          };
        }
      },
      onResponseCallBackFn: (error, result) => {
        if (!error) {
          setActiveStep(activeStep + 1)
        }
      }
    };

    const thirdStep = {
      formGroupName: 'additionalRole',
      isValidate: false,
      flashMessages: {
        "success": {
          status: true,
          message: "User Account is successfully created",
          messageType: "success"
        }
      },
      callApiObject: {
        isSetHeaders: true,
        multipart: false,
        method: "patch",
        onUpload: false
      },
      apiDataStoringObject: {
        setLoader: true,
        storingType: apiResponseStoringType.setResponse,
        mergeToSuccessResponse: null,
        mergeToErrorResponse: null,
      },
      onGetAPIEndPointFn: (formState) => {
        return {
          url: `${internalUserAPI.url}/${currentRow.id}/update-status`,
          key: internalUserAPI.key
        };
      },
      onChangeRequestBodyFn: (fromObject) => {
        return {};
      },

      onResponseCallBackFn: (error, response) => {   
        if (!error) {
            props.onClosePopupFn(true);
            coreAction.resetDataTable(internalUserAPI.key);
        }
        else if (error?.data?.errors?.details[0].message){
        let errortop=error?.data?.errors?.details[0].message
          uiAction.setFlashMessage({
            status: true,
            message: errortop,
            messageType: "error"
          });}
       else if (
          response.length === undefined &&
          error === undefined &&
          response.data.errors === undefined
        ) {
          props.onClosePopupFn(true);
        }

      }
    };

    return activeStep === 0 ? firstStep : activeStep === 1 ? secondStep : thirdStep;
  }

  const onNext = () => {
    if (activeStep <= 2) {
      setActiveStep(activeStep + 1);
    }
  }

  const onBackFn = () => {
    setActiveStepType({
      ...activeStepType,
      [`${activeStep - 1}_step`]: "create-edit"
    });
    setActiveStep(activeStep - 1);
  }

  return (
    <Fragment>
      <StepsWrapper
        activeStep={activeStep}
        orientation={stepWrapperOrientations.vertical}
        onNextBtnClickFn={() => onNext()}
        onBackBtnClickFn={() => onBackFn()}
        nextBtnProps={onGetActionBtnConf()}
      >
        <CustomStep stepid="0" label="Add User Information">
          <UserForm formKey={formKey} row={currentRow} activeStep={activeStep} uiType={`${activeStepType[`${activeStep}_step`]}`} />
        </CustomStep>
        <CustomStep stepid="1" label="Default Role & Location">
          <DefaultRoles formKey={'defaultRole'} row={currentRow} activeStep={activeStep} uiType={`${activeStepType[`${activeStep}_step`]}`} />
        </CustomStep>
        <CustomStep stepid="2" label="Additional Roles & Locations">
          <AdditionalRoles row={currentRow} uiType={`${activeStepType[`${activeStep}_step`]}`} />
        </CustomStep>
      </StepsWrapper>
    </Fragment>
  )
}

const EditFormWrapper = (props) => {
  const formKey = props.fromParent.formKey;
  const groupKey = props.fromParent.groupKey;
  const defaultRoleFormKey = "defaultRoleFormKey";
  const [getStepUIState, setStepUIState] = useState({
    'activeTab': "0",
  });
  return (
    <Fragment>
      <TabsWrapper
        active={getStepUIState.activeTab}
        onChangeFn={(eventInfo) => setStepUIState({ ...getStepUIState, activeTab: eventInfo.value })}
      >
        <SubTab tabid="0" header="Add User Information">
          <UserForm groupKey={groupKey} formKey={formKey} uiType={"edit"} row={{ ...props.row }} />
        </SubTab>
        <SubTab tabid="1" header="Default Role & Location">
          <DefaultRoles groupKey={groupKey} formKey={'defaultRole'} uiType={"edit"} row={{ ...props.row }} />
        </SubTab>
        <SubTab tabid="2" header="Additional Roles & Locations">
          <AdditionalRoles uiType={"edit"} row={{ ...props.row }} />
        </SubTab>
      </TabsWrapper>
    </Fragment>
  )
}

export {
  CreateStepsWrapper,
  EditFormWrapper
}