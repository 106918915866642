/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-03-26 15:28:37
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-04-06 13:52:11
 */
import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';

import useInit from './useInit.hook';
//import {InitialPageLoader} from "../ui-components/templates/template-one-includes/InitialPageLoader";

function guest(Component) {
  function WrappedComponent(props) {
    const [initStatus, isAuth] = useInit(props.routeKey);

    return <Component {...props} />;
  }

  return WrappedComponent;
}

export default guest;
