/*
 * @Author: Sujith
 * @Date: 2020-10-20 16:11:46 
 * @Last Modified by: Sujith
 * @Last Modified time: 2020-10-20 16:11:46 
 */

import React, { Fragment } from 'react';

import { Navigate } from "../../ui-components/ui-elements/common/Navigate";
import { permissions } from "../../../config/permission.config";
import GenaralConfiguration from "./pages/GeneralConfigurations";
import authorized from '../../hoc-middlewares/Authorized';


const GeneralConfigurationRoutes = () => {
    return (
        <Fragment>
            <Navigate exact={true} path="/generalConfiguration" routeKey={"generalConfiguration"} component={authorized(GenaralConfiguration)} routePermissions={permissions.GENERAL_CONFIGURATIONS_MANAGE.permissions} />
        </Fragment>
    )
};

export default GeneralConfigurationRoutes;
