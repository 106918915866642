
export const permissions={
    "NONE":{
        "key":"NONE",
        "description":"",
        "permissions":"NONE"
    },
    "LOGIN":{
        "key":"LOGIN",
        "description":"",
        "permissions":[
            "ADMIN.LOGIN"
        ]
    },
    "DASHBOARD_COMMON_DETAILS":{
        "key":"DASHBOARD_COMMON_DETAILS",
        "description":"",
        "permissions":[
            "ADMIN.DASHBOARD_COMMON_DETAILS"
        ]
    },
    "DELEGATION_SWITCH":{
        "key":"DELEGATION_SWITCH",
        "description":"",
        "permissions":[
            "ADMIN.DELEGATION_SWITCH"
        ]
    },
    "NOTIFICATION_VIEW":{
        "key":"NOTIFICATION_VIEW",
        "description":"",
        "permissions":[
            "ADMIN.NOTIFICATION.VIEW"
        ]
    },
    "PASSWARD_CHANGE":{
        "key":"PASSWARD_CHANGE",
        "description":"",
        "permissions":[
            "ADMIN.PASSWARD_CHANGE"
        ]
    },
    "PDMT_MANAGE":{
        "key":"PDMT_MANAGE",
        "description":"",
        "permissions":[
            "ADMIN.PDMT.MANAGE"
        ]
    },
    "PDMT_EDIT":{
        "key":"PDMT_EDIT",
        "description":"",
        "permissions":[
            "ADMIN.PDMT.EDIT"
        ]
    },
    "PDMT_VIEW":{
        "key":"PDMT_VIEW",
        "description":"",
        "permissions":[
            "ADMIN.PDMT.VIEW"
        ]
    },
    "AUTHORITY_MANAGE":{
        "key":"AUTHORITY_MANAGE",
        "description":"",
        "permissions":[
            "ADMIN.AUTHORITY.MANAGE"
        ]
    },
    "AUTHORITY_ADD":{
        "key":"AUTHORITY_ADD",
        "description":"",
        "permissions":[
            "ADMIN.AUTHORITY.ADD"
        ]
    },
    "AUTHORITY_EDIT":{
        "key":"AUTHORITY_EDIT",
        "description":"",
        "permissions":[
            "ADMIN.AUTHORITY.EDIT"
        ]
    },
    "AUTHORITY_VIEW":{
        "key":"AUTHORITY_VIEW",
        "description":"",
        "permissions":[
            "ADMIN.AUTHORITY.VIEW"
        ]
    },
    "AUTHORITY_SET_SERIAL":{
        "key":"AUTHORITY_SET_SERIAL",
        "description":"",
        "permissions":[
            "ADMIN.AUTHORITY.SET_SERIAL"
        ]
    },
    "COUNTER_MANAGE":{
        "key":"COUNTER_MANAGE",
        "description":"",
        "permissions":[
            "ADMIN.COUNTER.MANAGE"
        ]
    },
    "COUNTER_ADD":{
        "key":"COUNTER_ADD",
        "description":"",
        "permissions":[
            "ADMIN.COUNTER.ADD"
        ]
    },
    "COUNTER_EDIT":{
        "key":"COUNTER_EDIT",
        "description":"",
        "permissions":[
            "ADMIN.COUNTER.EDIT"
        ]
    },
    "COUNTER_VIEW":{
        "key":"COUNTER_VIEW",
        "description":"",
        "permissions":[
            "ADMIN.COUNTER.VIEW"
        ]
    },
    "COUNTER_SET_SERIAL":{
        "key":"COUNTER_SET_SERIAL",
        "description":"",
        "permissions":[
            "ADMIN.COUNTER.SET_SERIAL"
        ]
    },
    "BANK_MANAGE":{
        "key":"BANK_MANAGE",
        "description":"",
        "permissions":[
            "ADMIN.BANK.MANAGE"
        ]
    },
    "BANK_ADD":{
        "key":"BANK_ADD",
        "description":"",
        "permissions":[
            "ADMIN.BANK.ADD"
        ]
    },
    "BANK_EDIT":{
        "key":"BANK_EDIT",
        "description":"",
        "permissions":[
            "ADMIN.BANK.EDIT"
        ]
    },
    "BANK_VIEW":{
        "key":"BANK_VIEW",
        "description":"",
        "permissions":[
            "ADMIN.BANK.VIEW"
        ]
    },
    "INSURANCE_COMPANY_MANAGE":{
        "key":"INSURANCE_COMPANY_MANAGE",
        "description":"",
        "permissions":[
            "ADMIN.INSURANCE_COMPANY.MANAGE"
        ]
    },
    "INSURANCE_COMPANY_ADD":{
        "key":"INSURANCE_COMPANY_ADD",
        "description":"",
        "permissions":[
            "ADMIN.INSURANCE_COMPANY.ADD"
        ]
    },
    "INSURANCE_COMPANY_EDIT":{
        "key":"INSURANCE_COMPANY_EDIT",
        "description":"",
        "permissions":[
            "ADMIN.INSURANCE_COMPANY.EDIT"
        ]
    },
    "INSURANCE_COMPANY_VIEW":{
        "key":"INSURANCE_COMPANY_VIEW",
        "description":"",
        "permissions":[
            "ADMIN.INSURANCE_COMPANY.VIEW"
        ]
    },
    "VET_COMPANY_MANAGE":{
        "key":"VET_COMPANY_MANAGE",
        "description":"",
        "permissions":[
            "ADMIN.VET_COMPANY.MANAGE"
        ]
    },
    "VET_COMPANY_ADD":{
        "key":"VET_COMPANY_ADD",
        "description":"",
        "permissions":[
            "ADMIN.VET_COMPANY.ADD"
        ]
    },
    "VET_COMPANY_EDIT":{
        "key":"VET_COMPANY_EDIT",
        "description":"",
        "permissions":[
            "ADMIN.VET_COMPANY.EDIT"
        ]
    },
    "VET_COMPANY_VIEW":{
        "key":"VET_COMPANY_VIEW",
        "description":"",
        "permissions":[
            "ADMIN.VET_COMPANY.VIEW"
        ]
    },
    "VFT_COMPANY_MANAGE":{
        "key":"VFT_COMPANY_MANAGE",
        "description":"",
        "permissions":[
            "ADMIN.VFT_COMPANY.MANAGE"
        ]
    },
    "VFT_COMPANY_ADD":{
        "key":"VFT_COMPANY_ADD",
        "description":"",
        "permissions":[
            "ADMIN.VFT_COMPANY.ADD"
        ]
    },
    "VFT_COMPANY_VIEW":{
        "key":"VFT_COMPANY_VIEW",
        "description":"",
        "permissions":[
            "ADMIN.VFT_COMPANY.VIEW"
        ]
    },
    "VFT_COMPANY_EDIT":{
        "key":"VFT_COMPANY_EDIT",
        "description":"",
        "permissions":[
            "ADMIN.VFT_COMPANY.EDIT"
        ]
    },
    "VFT_COMPANY_CONFIGURE":{
        "key":"VFT_COMPANY_CONFIGURE",
        "description":"",
        "permissions":[
            "ADMIN.VFT_COMPANY.CONFIGURE"
        ]
    },
    "VRP_COMPANY_MANAGE":{
        "key":"VRP_COMPANY_MANAGE",
        "description":"",
        "permissions":[
            "ADMIN.VRP_COMPANY.MANAGE"
        ]
    },
    "VRP_COMPANY_ADD":{
        "key":"VRP_COMPANY_ADD",
        "description":"",
        "permissions":[
            "ADMIN.VRP_COMPANY.ADD"
        ]
    },
    "VRP_COMPANY_EDIT":{
        "key":"VRP_COMPANY_EDIT",
        "description":"",
        "permissions":[
            "ADMIN.VRP_COMPANY.EDIT"
        ]
    },
    "VRP_COMPANY_CONFIGURE":{
        "key":"VRP_COMPANY_CONFIGURE",
        "description":"",
        "permissions":[
            "ADMIN.VRP_COMPANY.CONFIGURE" 
        ]
    },
    "RATES_MANAGE":{
        "key":"RATES_MANAGE",
        "description":"",
        "permissions":[
            "ADMIN.RATES.MANAGE" 
        ]
    },
    "RATES_ADD":{
        "key":"RATES_ADD",
        "description":"",
        "permissions":[
            "ADMIN.RATES.ADD"
        ]
    },
    "RATES_EDIT":{
        "key":"RATES_EDIT",
        "description":"",
        "permissions":[
            "ADMIN.RATES.EDIT" 
        ]
    },
    "RATES_VIEW":{
        "key":"RATES_VIEW",
        "description":"",
        "permissions":[
            "ADMIN.RATES.VIEW"
        ]
    },
    "RATES_DELETE":{
        "key":"RATES_DELETE",
        "description":"",
        "permissions":[
            "ADMIN.RATES.DELETE"
        ]
    },
    "FINE_CONFIG_MANAGE":{
        "key":"FINE_CONFIG_MANAGE",
        "description":"",
        "permissions":[
            "ADMIN.FINE_CONFIG.MANAGE"
        ]
    },
    "FINE_CONFIG_ADD":{
        "key":"FINE_CONFIG_ADD",
        "description":"",
        "permissions":[
            "ADMIN.FINE_CONFIG.ADD"
        ]
    },
    "FINE_CONFIG_EDIT":{
        "key":"FINE_CONFIG_EDIT",
        "description":"",
        "permissions":[
            "ADMIN.FINE_CONFIG.EDIT"
        ]
    },
    "FINE_CONFIG_VIEW":{
        "key":"FINE_CONFIG_VIEW",
        "description":"",
        "permissions":[
            "ADMIN.FINE_CONFIG.VIEW"
        ]
    },
    "FINE_CONFIG_DELETE":{
        "key":"FINE_CONFIG_DELETE",
        "description":"",
        "permissions":[
            "ADMIN.FINE_CONFIG.DELETE"
        ]
    },
    "ADDITION_DEDUCTION_MANAGE":{
        "key":"ADDITION_DEDUCTION_MANAGE",
        "description":"",
        "permissions":[
            "ADMIN.ADDITION_DEDUCTION.MANAGE"
        ]
    },
    "ADDITION_DEDUCTION_ADD":{
        "key":"ADDITION_DEDUCTION_ADD",
        "description":"",
        "permissions":[
            "ADMIN.ADDITION_DEDUCTION.ADD"
        ]
    },
    "ADDITION_DEDUCTION_EDIT":{
        "key":"ADDITION_DEDUCTION_EDIT",
        "description":"",
        "permissions":[
            "ADMIN.ADDITION_DEDUCTION.EDIT"
        ]
    },
    "ADDITION_DEDUCTION_VIEW":{
        "key":"ADDITION_DEDUCTION_VIEW",
        "description":"",
        "permissions":[
            "ADMIN.ADDITION_DEDUCTION.VIEW"
        ]
    },
    "ADDITION_DEDUCTION_DELETE":{
        "key":"ADDITION_DEDUCTION_DELETE",
        "description":"",
        "permissions":[
            "ADMIN.ADDITION_DEDUCTION.DELETE"
        ]
    },
    "DELIVERY_CHARGE_MANAGE":{
        "key":"DELIVERY_CHARGE_MANAGE",
        "description":"",
        "permissions":[
            "ADMIN.DELIVERY_CHARGE.MANAGE"
        ]
    },
    "DELIVERY_CHARGE_ADD":{
        "key":"DELIVERY_CHARGE_ADD",
        "description":"",
        "permissions":[
            "ADMIN.DELIVERY_CHARGE.ADD"
        ]
    },
    "DELIVERY_CHARGE_EDIT":{
        "key":"DELIVERY_CHARGE_EDIT",
        "description":"",
        "permissions":[
            "ADMIN.DELIVERY_CHARGE.EDIT"
        ]
    },
    "DELIVERY_CHARGE_VIEW":{
        "key":"DELIVERY_CHARGE_VIEW",
        "description":"",
        "permissions":[
            "ADMIN.DELIVERY_CHARGE.VIEW"
        ]
    },
    "DELIVERY_METHOD_MANAGE":{
        "key":"DELIVERY_METHOD_MANAGE",
        "description":"",
        "permissions":[
            "ADMIN.DELIVERY_METHOD.MANAGE"
        ]
    },
    "DELIVERY_METHOD_ADD":{
        "key":"DELIVERY_METHOD_ADD",
        "description":"",
        "permissions":[
            "ADMIN.DELIVERY_METHOD.ADD"
        ]
    },
    "DELIVERY_METHOD_EDIT":{
        "key":"DELIVERY_METHOD_EDIT",
        "description":"",
        "permissions":[
            "ADMIN.DELIVERY_METHOD.EDIT"
        ]
    },
    "DELIVERY_METHOD_VIEW":{
        "key":"DELIVERY_METHOD_VIEW",
        "description":"",
        "permissions":[
            "ADMIN.DELIVERY_METHOD.VIEW"
        ]
    },
    "GENERAL_CONFIGURATIONS_MANAGE":{
        "key":"GENERAL_CONFIGURATIONS_MANAGE",
        "description":"",
        "permissions":[
            "ADMIN.GENERAL_CONFIGURATIONS.MANAGE"
        ]
    },
    "RENEWABLE_PERIOD_LICENSE_TYPES_EDIT":{
        "key":"RENEWABLE_PERIOD_LICENSE_TYPES_EDIT",
        "description":"",
        "permissions":[
            "ADMIN.RENEWABLE_PERIOD_LICENSE_TYPES.EDIT"
        ]
    },
    "RENEWABLE_PERIOD_LICENSE_TYPES_VIEW":{
        "key":"RENEWABLE_PERIOD_LICENSE_TYPES_VIEW",
        "description":"",
        "permissions":[
            "ADMIN.RENEWABLE_PERIOD_LICENSE_TYPES.VIEW"
        ]
    },
    "FINE_EXEMPTION_VEHICLE_REGISTRATION_EDIT":{
        "key":"FINE_EXEMPTION_VEHICLE_REGISTRATION_EDIT",
        "description":"",
        "permissions":[
            "ADMIN.FINE_EXEMPTION_VEHICLE_REGISTRATION.EDIT"
        ]
    },
    "FINE_EXEMPTION_VEHICLE_REGISTRATION_VIEW":{
        "key":"FINE_EXEMPTION_VEHICLE_REGISTRATION_VIEW",
        "description":"",
        "permissions":[
            "ADMIN.FINE_EXEMPTION_VEHICLE_REGISTRATION.VIEW"
        ]
    },
    "FINE_EXEMPTION_LICENSE_RENEWAL_EDIT":{
        "key":"FINE_EXEMPTION_LICENSE_RENEWAL_EDIT",
        "description":"",
        "permissions":[
            "ADMIN.FINE_EXEMPTION_LICENSE_RENEWAL.EDIT"
        ]
    },
    "FINE_EXEMPTION_LICENSE_RENEWAL_VIEW":{
        "key":"FINE_EXEMPTION_LICENSE_RENEWAL_VIEW",
        "description":"",
        "permissions":[
            "ADMIN.FINE_EXEMPTION_LICENSE_RENEWAL.VIEW"
        ]
    },
    "LICENSE_TYPE_CHANGE_TO_NON_USER_EDIT":{
        "key":"LICENSE_TYPE_CHANGE_TO_NON_USER_EDIT",
        "description":"",
        "permissions":[
            "ADMIN.LICENSE_TYPE_CHANGE_TO_NON_USER.EDIT"
        ]
    },
    "LICENSE_TYPE_CHANGE_TO_NON_USER_VIEW":{
        "key":"LICENSE_TYPE_CHANGE_TO_NON_USER_VIEW",
        "description":"",
        "permissions":[
            "ADMIN.LICENSE_TYPE_CHANGE_TO_NON_USER.VIEW"
        ]
    },
    "VET_EXEMPTION_MANUFACTURED_YEAR_EDIT":{
        "key":"VET_EXEMPTION_MANUFACTURED_YEAR_EDIT",
        "description":"",
        "permissions":[
            "ADMIN.VET_EXEMPTION_MANUFACTURED_YEAR.EDIT"
        ]
    },
    "VET_EXEMPTION_MANUFACTURED_YEAR_VIEW":{
        "key":"VET_EXEMPTION_MANUFACTURED_YEAR_VIEW",
        "description":"",
        "permissions":[
            "ADMIN.VET_EXEMPTION_MANUFACTURED_YEAR.VIEW"
        ]
    },
    "WORKFLOW_LICENSE_WITH_ARREARS_EDIT":{
        "key":"WORKFLOW_LICENSE_WITH_ARREARS_EDIT",
        "description":"",
        "permissions":[
            "ADMIN.WORKFLOW_LICENSE_WITH_ARREARS.EDIT"
        ]
    },
    "WORKFLOW_LICENSE_WITH_ARREARS_VIEW":{
        "key":"WORKFLOW_LICENSE_WITH_ARREARS_VIEW",
        "description":"",
        "permissions":[
            "ADMIN.WORKFLOW_LICENSE_WITH_ARREARS.VIEW"
        ]
    },
    "ONLINE_TEMPORARY_LICENSE_RECEIPT_EDIT":{
        "key":"ONLINE_TEMPORARY_LICENSE_RECEIPT_EDIT",
        "description":"",
        "permissions":[
            "ADMIN.ONLINE_TEMPORARY_LICENSE_RECEIPT.EDIT"
        ]
    },
    "ONLINE_TEMPORARY_LICENSE_RECEIPT_VIEW":{
        "key":"ONLINE_TEMPORARY_LICENSE_RECEIPT_VIEW",
        "description":"",
        "permissions":[
            "ADMIN.ONLINE_TEMPORARY_LICENSE_RECEIPT.VIEW"
        ]
    },
    "SERVICE_CHARGE_CONFIG_EDIT":{
        "key":"SERVICE_CHARGE_CONFIG_EDIT",
        "description":"",
        "permissions":[
            "ADMIN.SERVICE_CHARGE_CONFIG.EDIT"
        ]
    },
    "SERVICE_CHARGE_CONFIG_VIEW":{
        "key":"SERVICE_CHARGE_CONFIG_VIEW",
        "description":"",
        "permissions":[
            "ADMIN.SERVICE_CHARGE_CONFIG.VIEW"
        ]
    },
    "HOLIDAY_CONFIG_MANAGE":{
        "key":"HOLIDAY_CONFIG_MANAGE",
        "description":"",
        "permissions":[
            "ADMIN.HOLIDAY_CONFIG.MANAGE"
        ]
    },
    "HOLIDAY_CONFIG_ADD":{
        "key":"HOLIDAY_CONFIG_ADD",
        "description":"",
        "permissions":[
            "ADMIN.HOLIDAY_CONFIG.ADD"
        ]
    },
    "HOLIDAY_CONFIG_EDIT":{
        "key":"HOLIDAY_CONFIG_EDIT",
        "description":"",
        "permissions":[
            "ADMIN.HOLIDAY_CONFIG.EDIT"
        ]
    },
    "HOLIDAY_CONFIG_VIEW":{
        "key":"HOLIDAY_CONFIG_VIEW",
        "description":"",
        "permissions":[
            "ADMIN.HOLIDAY_CONFIG.VIEW"
        ]
    },
    "LICENSE_TYPE_MANAGE":{
        "key":"LICENSE_TYPE_MANAGE",
        "description":"",
        "permissions":[
            "ADMIN.LICENSE_TYPE.MANAGE"
        ]
    },
    "LICENSE_TYPE_EDIT":{
        "key":"LICENSE_TYPE_EDIT",
        "description":"",
        "permissions":[
            "ADMIN.LICENSE_TYPE.EDIT"
        ]
    },
    "LICENSE_TYPE_VIEW":{
        "key":"LICENSE_TYPE_VIEW",
        "description":"",
        "permissions":[
            "ADMIN.LICENSE_TYPE.VIEW"
        ]
    },
    "VEHICLE_CLASS_MANAGE":{
        "key":"VEHICLE_CLASS_MANAGE",
        "description":"",
        "permissions":[
            "ADMIN.VEHICLE_CLASS.MANAGE"
        ]
    },
    "VEHICLE_CLASS_ADD":{
        "key":"VEHICLE_CLASS_ADD",
        "description":"",
        "permissions":[
            "ADMIN.VEHICLE_CLASS.ADD"
        ]
    },
    "VEHICLE_CLASS_EDIT":{
        "key":"VEHICLE_CLASS_EDIT",
        "description":"",
        "permissions":[
            "ADMIN.VEHICLE_CLASS.EDIT"
        ]
    },
    "VEHICLE_CLASS_VIEW":{
        "key":"VEHICLE_CLASS_VIEW",
        "description":"",
        "permissions":[
            "ADMIN.VEHICLE_CLASS.VIEW"
        ]
    },
    "FUEL_TYPE_MANAGE":{
        "key":"FUEL_TYPE_MANAGE",
        "description":"",
        "permissions":[
            "ADMIN.FUEL_TYPE.MANAGE"
        ]
    },
    "FUEL_TYPE_ADD":{
        "key":"FUEL_TYPE_ADD",
        "description":"",
        "permissions":[
            "ADMIN.FUEL_TYPE.ADD"
        ]
    },
    "FUEL_TYPE_EDIT":{
        "key":"FUEL_TYPE_EDIT",
        "description":"",
        "permissions":[
            "ADMIN.FUEL_TYPE.EDIT"
        ]
    },
    "FUEL_TYPE_VIEW":{
        "key":"FUEL_TYPE_VIEW",
        "description":"",
        "permissions":[
            "ADMIN.FUEL_TYPE.VIEW"
        ]
    },
    "VEHICLE_CONDITION_MANAGE":{
        "key":"VEHICLE_CONDITION_MANAGE",
        "description":"",
        "permissions":[
            "ADMIN.VEHICLE_CONDITION.MANAGE"
        ]
    },
    "VEHICLE_CONDITION_ADD":{
        "key":"VEHICLE_CONDITION_ADD",
        "description":"",
        "permissions":[
            "ADMIN.VEHICLE_CONDITION.ADD"
        ]
    },
    "VEHICLE_CONDITION_EDIT":{
        "key":"VEHICLE_CONDITION_EDIT",
        "description":"",
        "permissions":[
            "ADMIN.VEHICLE_CONDITION.EDIT"
        ]
    },
    "VEHICLE_CONDITION_VIEW":{
        "key":"VEHICLE_CONDITION_VIEW",
        "description":"",
        "permissions":[
            "ADMIN.VEHICLE_CONDITION.VIEW"
        ]
    },
    "DEALER_FILE_UPLOAD":{
        "key":"DEALER_FILE_UPLOAD",
        "description":"",
        "permissions":[
            "ADMIN.DEALER_FILE_UPLOAD"
        ]
    },
    "USER_ROLE_MANAGE":{
        "key":"USER_ROLE_MANAGE",
        "description":"",
        "permissions":[
            "ADMIN.USER_ROLE.MANAGE"
        ]
    },
    "USER_ROLE_ADD":{
        "key":"USER_ROLE_ADD",
        "description":"",
        "permissions":[
            "ADMIN.USER_ROLE.ADD"
        ]
    },
    "USER_ROLE_EDIT":{
        "key":"USER_ROLE_EDIT",
        "description":"",
        "permissions":[
            "ADMIN.USER_ROLE.EDIT"
        ]
    },
    "USER_ROLE_VIEW":{
        "key":"USER_ROLE_VIEW",
        "description":"",
        "permissions":[
            "ADMIN.USER_ROLE.VIEW"
        ]
    },
    "USER_ACCOUNT_MANAGE":{
        "key":"USER_ACCOUNT_MANAGE",
        "description":"",
        "permissions":[
            "ADMIN.USER_ACCOUNT.MANAGE"
        ]
    },
    "USER_ACCOUNT_ADD":{
        "key":"USER_ACCOUNT_ADD",
        "description":"",
        "permissions":[
            "ADMIN.USER_ACCOUNT.ADD"
        ]
    },
    "USER_ACCOUNT_EDIT":{
        "key":"USER_ACCOUNT_EDIT",
        "description":"",
        "permissions":[
            "ADMIN.USER_ACCOUNT.EDIT"
        ]
    },
    "USER_ACCOUNT_VIEW":{
        "key":"USER_ACCOUNT_VIEW",
        "description":"",
        "permissions":[
            "ADMIN.USER_ACCOUNT.VIEW"
        ]
    },
    "NEWS_MANAGE":{
        "key":"NEWS_MANAGE",
        "description":"",
        "permissions":[
            "ADMIN.NEWS.MANAGE"
        ]
    },
    "NEWS_ADD":{
        "key":"NEWS_ADD",
        "description":"",
        "permissions":[
            "ADMIN.NEWS.ADD"
        ]
    },
    "NEWS_EDIT":{
        "key":"NEWS_EDIT",
        "description":"",
        "permissions":[
            "ADMIN.NEWS.EDIT"
        ]
    },
    "NEWS_VIEW":{
        "key":"NEWS_VIEW",
        "description":"",
        "permissions":[
            "ADMIN.NEWS.VIEW"
        ]
    },
    "NEWS_DELETE":{
        "key":"NEWS_DELETE",
        "description":"",
        "permissions":[
            "ADMIN.NEWS.DELETE"
        ]
    },
    "COUNTER_SESSION_END":{
        "key":"COUNTER_SESSION_END",
        "description":"",
        "permissions":[
            "ADMIN.COUNTER_SESSION.END"
        ]
    },
    "DEALER_FILE_UPLOAD":{
        "key":"DEALER_FILE_UPLOAD",
        "description":"",
        "permissions":[
            "ADMIN.DEALER_FILE_UPLOAD"
        ]
    },
    "REASON_VIEW":{
        "key":"REASON_VIEW",
        "description":"",
        "permissions":[
            "ADMIN.REASON.VIEW"
        ]
    },
    "REASON_EDIT":{
        "key":"REASON_EDIT",
        "description":"",
        "permissions":[
            "ADMIN.REASON.EDIT"
        ]
    },
    "REASON_MANAGE":{
        "key":"REASON_MANAGE",
        "description":"",
        "permissions":[
            "ADMIN.REASON.MANAGE"
        ]
    },
    "REASON_ADD":{
        "key":"REASON_ADD",
        "description":"",
        "permissions":[
            "ADMIN.REASON.ADD"
        ]
    },
    "REASON_DELETE":{
        "key":"REASON_DELETE",
        "description":"",
        "permissions":[
            "ADMIN.REASON.DELETE"
        ]
    },

  
    
};