/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-02-28 17:19:46
 * @Last Modified by: Nisal Madusanka(EruliaF)EruliaF)
 * @Last Modified time: 2021-03-01 14:06:19
 */

import React, { Fragment } from "react";
import { BrowserRouter, Switch } from "react-router-dom";

// Import routers
import DashboardRoutes from "../components/modules/dashboard/Dashboard.routes";
import ErrorBoundary from "../components/ui-components/ui-elements/common/ErrorBoundary";
import DisplayError from "../components/modules/error-boundary/pages/DisplayError";
import ErrorBoundaryRoutes from "../components/modules/error-boundary/ErrorBoundary.routes";
import RateRoutes from "../components/modules/rates/Rate.routes";
import GeneralConfigurationRoutes from "../components/modules/general-configurations/GeneralConfigurations.route";
import BankRoutes from "../components/modules/bank/Bank.routes";
import VFTCompanyRoutes from "../components/modules/vft-company/VFTCompany.routes";
import LicenceTypesRoutes from "../components/modules/licence-types/LicenceTypes.routes";
import VehicleClassRoutes from "../components/modules/vehicle-class/VehicleClass.routes";
import VehicleConditionRoutes from "../components/modules/vehicle-condition/VehicleCondition.routes";
import ManageCountersRoutes from "../components/modules/manage-counters/Manage-counters.routes";
import ManageInsuranceCompanyRoutes from "../components/modules/manage-insurance-company/Manage-insurance-company.routes";
import ManageFuelTypeRoutes from "../components/modules/manage-fuel-type/Manage-fuel-type.routes";
import ManageDeliveryMethodsRoutes from "../components/modules/manage-delivery-method/ManageDeliveryMethods.routes";
import VehicleRoutePermit from "../components/modules/veh-route-permit/VehicleRoutePermit.routes";
import VETCompanyRoutes from "../components/modules/vet-company/VETCompany.routes";
import ProvinceOfficeRoutes from "../components/modules/province-office/ProvinceOffice.routes";
import AuthorityRoutes from "../components/modules/authority/Authority.routes";
import NotificationRoutes from "../components/modules/notification/Notification.routes";
import AuthRoutes from "../components/modules/auth/Auth.routes";
import ManageFineExcemptionDaysRoutes from "../components/modules/manage-fine-excemption-days/Manage-fine-excemption-days.routes";
import FineConfigurationsRoutes from "../components/modules/fine-configurations/FineConfigurations.routes";
import DeliveryChargesRoutes from "../components/modules/delivery-charge/DeliveryCharge.routes";
import UserAccount from "../components/modules/user-account/UserAccount.routes"
import DealerFilesRoutes from "../components/modules/dealer-files/DealerFiles.routes";
import UserRoleRoutes from "../components/modules/user-role/UserRole.routes";
import AdditionsDeductionsRoutes from "../components/modules/additions-deductions/AdditionsDeductions.routes";
import WebPortalRoutes from "../components/modules/web-portal/WebPortal.routes"; 
import ReasonRoutes from "../components/modules/reason/Reasons.routes";


const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Fragment>
          <ErrorBoundary displayComponent={DisplayError}>
            <AuthRoutes />
            <ManageDeliveryMethodsRoutes />
            <DashboardRoutes />
            <ManageFuelTypeRoutes />
            <ManageFineExcemptionDaysRoutes />
            <ErrorBoundaryRoutes />
            <ManageCountersRoutes />
            <RateRoutes />
            <GeneralConfigurationRoutes />
            <BankRoutes />
            <VFTCompanyRoutes />
            <LicenceTypesRoutes />
            <VehicleClassRoutes />
            <VehicleConditionRoutes />
            <ManageInsuranceCompanyRoutes />
            <VehicleRoutePermit />
            <VETCompanyRoutes />
            <ProvinceOfficeRoutes />
            <AuthorityRoutes />
            <NotificationRoutes />
            <FineConfigurationsRoutes />
            <DeliveryChargesRoutes />
            <UserAccount />
            <DealerFilesRoutes />
            <AdditionsDeductionsRoutes />
            <UserRoleRoutes />
            <WebPortalRoutes/>
            <ReasonRoutes />
          </ErrorBoundary>
        </Fragment>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
