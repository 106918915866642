/*
 * @Author: Farah Jabir
 * @Date: 2020-10-19 10:18:15
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-30 11:43:35
 */

import React, { Fragment } from "react";
import { ContactTypeCode } from "../../../../../../../config/enum.config";
import { contactsArrayToObject } from "../../../../../../../helpers/common-helpers/contact.helpers";
import { dateObjectToString } from "../../../../../../../helpers/common-helpers/dateTime.helpers";
import { _get } from "../../../../../../../helpers/common-helpers/lodash.wrappers";
import { AuditDetail, ViewDetailBlock } from "../../../../../../ui-components/ui-elements/common/BaseElements";

const ViewIssuingOfficer = (props) => {
    const labelCol = 4;

    return (
        <Fragment>
            <div className="row defaultPaddingBottom">
                <h1 className="defaultHeading col-12">Officer Information</h1>
                <hr className="fullWidthDiv" />

                <div className="col-12">
                    <ViewDetailBlock
                        label={"Full Name"}
                        value={_get(props, `row.full_name`, '')}
                        labelCol={labelCol}
                    />
                    <ViewDetailBlock
                        label={"Contact Number"}
                        value={contactsArrayToObject(_get(props, `row.user.contact`, []), false, ContactTypeCode.MOBILE)}
                        labelCol={labelCol}
                    />
                    <ViewDetailBlock
                        label={"Email"}
                        value={contactsArrayToObject(_get(props, `row.user.contact`, []), false, ContactTypeCode.EMAIL)}
                        labelCol={labelCol}
                    />
                    <ViewDetailBlock
                        label={"Status"}
                        value={_get(props, `row.user.status`, '')===1?"Active":"Inactive"}
                        labelCol={labelCol}
                    />
                </div>
            </div>

            <div className="row defaultPaddingTopBottom">
                <h1 className="defaultHeading col-12">User Login Information</h1>
                <hr className="fullWidthDiv" />

                <div className="col-12">
                    <ViewDetailBlock
                        label={"Username"}
                        value={_get(props, `row.user.user_name`, '')}
                        labelCol={labelCol}
                    />
                    <ViewDetailBlock
                        label={"Display Name"}
                        value={_get(props, `row.display_name`, '')}
                        labelCol={labelCol}
                    />
                </div>
            </div>

            <AuditDetail
                labelCol={4}
                createdAt={dateObjectToString(_get(props, `row.user.created_at`, ''),"dd/mm/YY HH:MM")}
                createdById={_get(props, `row.user.created_by`, '')}
                createdLocationId={_get(props, `row.user.created_location`, '')}
                createdRoleId={_get(props, `row.user.created_role_id`, '')}
                updatedAt={dateObjectToString(_get(props, `row.user.updated_at`, ''),"dd/mm/YY HH:MM")}
                updatedBy={_get(props, `row.user.updated_by`, '')}
                updatedLocationId={_get(props, `row.user.updated_location`, '')}
                updatedRoleId={_get(props, `row.user.updated_role_id`, '')}
            />
        </Fragment>
    );
}

export { ViewIssuingOfficer };
