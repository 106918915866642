import React, { useContext } from "react";
import { vftCertificateOfficersListAPI } from "../../../../../config/apiUrl.config";
import { ContactTypeCode } from "../../../../../config/enum.config";
import { templateTypes } from "../../../../../config/template.config";
import { contactsArrayToObject } from "../../../../../helpers/common-helpers/contact.helpers";
import { _find, _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { FormWrapper, InputBoxWithState, SelectBoxWithState, CheckBoxWithState, NumberInputBoxWithState } from "../../../../ui-components/ui-elements/form";
import { UploadComponentBase64 } from "../../../../ui-components/ui-elements/upload/UploadComponentBase64";
import { FormContext } from "../../../core/context-providers/FormContext.provider";

const AddEditCertifyingOfficer = (props) => {

    const [formState, formAction] = useContext(FormContext);
    const formKey = `${props.fromParent.formKey}`;

    const getSMSNotifyError = () => {
        const check = _find(_get(formState, `${formKey}._errors`, ""), (e) => e.property === "isSMS");

        if (check !== undefined) {
            return {
                status: true,
                message: check.message
            };
        } else {
            return {
                status: false
            }
        }
    };

    return (
        <FormWrapper
            setGroupName={formKey}
            formGroupLinkWith={formKey}
            onDestroyUnsetFormObject={true}
            setFormObject={{
                id: _get(props, "row.user.id", ""),
                reqId: `${props.fromParent.requiredId}`,
                full_name: _get(props, "row.full_name", ""),
                contact_number: contactsArrayToObject(_get(props, `row.user.contact`, []), false, ContactTypeCode.MOBILE),
                email: contactsArrayToObject(_get(props, `row.user.contact`, []), false, ContactTypeCode.EMAIL),
                status: _get(props, "row.user.active", 1),
                user_name: _get(props, "row.user.user_name", ""),
                display_name: _get(props, "row.display_name", ""),
                isSMS: _get(props, "row.user.sms_notification", true),
                isEmail: _get(props, "row.user.email_notification", true),
                title: _get(props, "row.title.id", ""),
                signature: _get(props, "row.signature", "")

            }}
        >
            <div className="row defaultPaddingLeftRight">
                <div className="row defaultPaddingBottom defaultPaddingLeftRight">
                    <p className="defaultPaddingLeftRight"><b>Officer Information</b></p>
                </div>
                <div className="row defaultPaddingBottom defaultPaddingLeftRight">
                    <div className="col-md-6 col-sm-12 floatLeft">
                        <InputBoxWithState
                            uiType={templateTypes.bootstrap}
                            formGroupName={formKey}
                            inputName={"full_name"}
                            labelText="Full Name"
                            isRequired={true}
                            maxLength={150}
                        // inputPlaceholder={"Full Name"}
                        />
                    </div>

                    <div className="col-md-6 col-sm-12 floatLeft">
                        <NumberInputBoxWithState
                            uiType={templateTypes.bootstrap}
                            formGroupName={formKey}
                            inputName={"contact_number"}
                            isRequired={true}
                            isAllowNegative={false}
                            isThousandSeparator={false}
                            isAllowLeadingZeroes={true}
                            decimalScale={0}
                            isAllowedFn={(value) => {
                                const checkValue = value.value.toString();
                                return checkValue.length <= 10;
                            }}
                            // inputPlaceholder={"Contact Number"}
                            labelText={"Contact Number"}
                        />
                    </div>
                    <div className="col-md-6 col-sm-12 floatLeft">
                        <InputBoxWithState
                            uiType={templateTypes.bootstrap}
                            formGroupName={formKey}
                            inputName={"email"}
                            labelText="Email"
                            isRequired={true}
                            maxLength={100}
                        // inputPlaceholder={"Email"}
                        />
                    </div>
                    <div className="col-md-6 col-sm-12 floatLeft">
                        <SelectBoxWithState
                            formGroupName={formKey}
                            isRequired={true}
                            labelText={"Status"}
                            dataList={[{ "id": 1, "value": "Active" }, { "id": 0, "value": "Inactive" }]}
                            inputName={"status"}
                        />
                    </div>
                </div>
            </div>
            <div className="row defaultPadding">

                <UploadComponentBase64
                    uiType={templateTypes.bootstrap}
                    formGroupName={formKey}
                    formKey={"signature"}
                    labelText="Signature"
                    isRequired={true}
                    isMultiple={false}
                />
            </div>

            <div className="row defaultPaddingBottom defaultPaddingLeftRight">
                <p className="defaultPaddingLeftRight"><b>User Login Information</b></p>
            </div>

            <div className="row defaultPaddingLeftRight">
                <div className="col-md-6 col-sm-12 floatLeft">
                    {formKey === vftCertificateOfficersListAPI.key + "_edit" ?
                        <div className="col-md-3 defaultHalfPaddingRight floatLeft">
                            Username: <strong> {_get(props, "row.user.user_name", "")}</strong>
                        </div>
                        : <InputBoxWithState
                            uiType={templateTypes.bootstrap}
                            formGroupName={formKey}
                            inputName={"user_name"}
                            labelText="Username"
                            isRequired={true}
                            maxLength={50}
                        // inputPlaceholder="Username"
                        />}
                </div>
                <div className="col-md-6 col-sm-12 floatLeft">
                    <InputBoxWithState
                        uiType={templateTypes.bootstrap}
                        formGroupName={formKey}
                        inputName={"display_name"}
                        labelText="Display Name"
                        isRequired={true}
                        maxLength={100}
                    // inputPlaceholder="Display Name"
                    />
                </div>
                <div className="col-md-6 col-sm-12 floatLeft">
                    {
                        formKey === vftCertificateOfficersListAPI.key + "_edit" ?
                            (
                                <div className="col-md-4 defaultHalfPaddingRight floatLeft">
                                    Device Id: <strong> {_get(props, "row.device_id", "")}</strong>
                                </div>
                            ) : null
                    }
                </div>
            </div>
            <div className="row defaultPaddingLeftRight">

                <div className="col-md-6 col-sm-12 ">
                    {
                        (getSMSNotifyError().status === true) ? (
                            <div className={"defaultFormHelperText text-danger"}>
                                {getSMSNotifyError().message}
                            </div>
                        ) : null
                    }
                    <CheckBoxWithState
                        formGroupName={formKey}
                        isRequired={true}
                        inputName={"isSMS"}
                        labelText="Notify via SMS"
                        isDisabled={true}
                    />
                    <br />
                    <CheckBoxWithState
                        formGroupName={formKey}
                        isRequired={true}
                        inputName={"isEmail"}
                        labelText="Notify via E-mail"
                    />
                </div>
            </div>
        </FormWrapper>


    )
}

export { AddEditCertifyingOfficer }