/*
 * @Author: Chanaka Wickramasinghe
 * @Date: 2020-02-03 14:18:10
 * @Last Modified by: Senura Weerasinghe
 * @Last Modified time: 2021-04-07 11:37:54
 */

import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Collapse from '@material-ui/core/Collapse';
import Skeleton from '@material-ui/lab/Skeleton';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import { callApi } from "../../../../helpers/common-helpers/callApi.helpers";
import {
  defaultTemplateType,
  baseTemplate,
  templateTypes,
} from '../../../../config/template.config';
import { usePermission } from '../../../hooks/common-hooks/usePermission.hook';
import { CoreContext } from '../../../modules/core/context-providers/CoreContext.provider';
import { FormContext } from '../../../modules/core/context-providers/FormContext.provider';
import { _get } from '../../../../helpers/common-helpers/lodash.wrappers';
import { getAuditLocationNameAPI, getAuditUsernameAPI,userRolesListAPI } from "../../../../config/apiUrl.config";
import { isEmptyValue } from "../../../../helpers/common-helpers/common.helpers";

const emptyFun = (...para) => undefined;
const EmptyComponent = (...para) => <Fragment></Fragment>;

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Tooltip Wrapper
 * --------------------------------------------
 */

const TooltipWrapper = ({
  elementStyle = '',
  isArrowHead = false,
  children = null,
  placement = 'bottom',
  description = '',
}) => {
  return (
    <Tooltip
      title={description}
      arrow={isArrowHead}
      className={`defaultTooltipStyle ${elementStyle}`}
      placement={placement}
    >
      {children}
    </Tooltip>
  );
};

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Tooltip Wrapper
 * --------------------------------------------
 */

TooltipWrapper.propTypes = {
  /** Tooltip element css class */
  elementStyle: PropTypes.string,
  /** Is arrow head true or false */
  isArrowHead: PropTypes.bool,
  /** Placement of the tooltip */
  placement: PropTypes.oneOf([
    'bottom-end',
    'bottom-start',
    'bottom',
    'left-end',
    'left-start',
    'left',
    'right-end',
    'right-start',
    'right',
    'top-end',
    'top-start',
    'top',
  ]),
  /** Tooltip description */
  description: PropTypes.string,
  /** children element */
  children: PropTypes.element,
};

//----------------TooltipWrapper---------------------

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Breadcrumb Wrapper
 * --------------------------------------------
 */

const BreadCrumbWrapper = ({
  uiType = defaultTemplateType,
  elementStyle = '',
  breadCrumbList = [],
}) => {
  return (
    <Fragment>
      {breadCrumbList.length !== 0 ? (
        <Breadcrumbs
          aria-label="breadcrumb"
          className={`defaulBreadCrumbWrapper ${uiType}BreadCrumbWrapper ${elementStyle}`}
        >
          {breadCrumbList.map((value, index) => {
            return (
              <span key={index}>
                {breadCrumbList.length - 1 !== index ? (
                  <Link
                    key={index}
                    color="inherit"
                    to={value.urlTo}
                    className={`defaultLinkClass`}
                  >
                    {value.icon != null || value.icon !== undefined ? (
                      <Fragment>
                        <Icon iconClass={value.icon} />
                        {value.displayName}
                      </Fragment>
                    ) : (
                      value.displayName
                    )}
                  </Link>
                ) : (
                  <Typography
                    key={index}
                    color="textPrimary"
                    className={`activeLink`}
                  >
                    {value.displayName}
                  </Typography>
                )}
              </span>
            );
          })}
        </Breadcrumbs>
      ) : null}
    </Fragment>
  );
};

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Breadcrumb Wrapper
 * --------------------------------------------
 */

BreadCrumbWrapper.propTypes = {
  /** UI type */
  uiType: PropTypes.oneOf([templateTypes.material, templateTypes.bootstrap]),
  /** Tooltip element css class */
  elementStyle: PropTypes.string,
  /** Breadcrumb list */
  breadCrumbList: PropTypes.array,
};

//----------------BreadCrumbWrapper---------------------

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Card Wrapper
 * --------------------------------------------
 */

const UICard = ({
  uiType = defaultTemplateType,
  elementStyle = '',
  cardHeading = '',
  children = null,
  onClickFn = emptyFun,
}) => {
  return (
    <Card
      className={`defaultCardWrapper ${uiType}CardWrapper ${elementStyle} paddingNone`}
      onClick={(event) => onClickFn(event)}
    >
      {cardHeading ? (
        <div className="cardHeaderWrapper">
          <div className="row">
            <div className="col-md-6">
              <h5 className="headingTxt">{cardHeading}</h5>
            </div>
            <div className="col-md-6"></div>
          </div>
        </div>
      ) : null}
      <div className="cardBodyWrapper">{children}</div>
    </Card>
  );
};

const PaddingLessUICard = ({
  uiType = defaultTemplateType,
  elementStyle = '',
  cardHeading = '',
  cardHeadingTail = null,
  passPropsToCardHeadingTail = {},
  children = null,
}) => {
  return (
    <div
      className={`defaultPaddingLessUICardWrapper ${uiType}PaddingLessUICardWrapper ${elementStyle}`}
    >
      <div className="paddingLessHeaderWrapper">
        <div className="hedingWrapper">
          <h5>{cardHeading}</h5>
        </div>
        <div className={'tailWrapper'}>
          {cardHeadingTail != null ? (
            <div className={'tailComponentWrapper'}>
              <VariableToComponent
                component={cardHeadingTail}
                props={passPropsToCardHeadingTail}
              />
            </div>
          ) : null}
        </div>
      </div>
      <span className="underLine"></span>
      <div className="paddingLessBodyWrapper">{children}</div>
    </div>
  );
};

const UICardWithBackGround = ({
  uiType = defaultTemplateType,
  elementStyle = '',
  children = null,
}) => {
  return (
    <div className={`fullWidthDiv defaultBGWrapper ${uiType}BGWrapper`}>
      <Card
        className={`defaultCardWrapper ${uiType}CardWrapper ${elementStyle}`}
      >
        <Fragment>{children}</Fragment>
      </Card>
    </div>
  );
};

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Card Wrapper
 * --------------------------------------------
 */

UICard.propTypes = {
  /** UI type */
  uiType: PropTypes.oneOf([templateTypes.material, templateTypes.bootstrap]),
  /** Card element css class */
  elementStyle: PropTypes.string,
  /** Card children */
  children: PropTypes.element,
};

//----------------UICard---------------------

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Default Icon Wrapper
 * --------------------------------------------
 */

const Icon = ({
  templateTypes = defaultTemplateType,
  elementStyle = '',
  iconClass = '',
  tooltip = '',
  onClickFn = emptyFun,
}) => {
  return (
    <TooltipWrapper description={tooltip}>
      <i
        className={`defaultIconStyle ${elementStyle} ${iconClass} ${templateTypes}DefaultIcon `}
        onClick={onClickFn}
      ></i>
    </TooltipWrapper>
  );
};

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Default Icon Wrapper
 * --------------------------------------------
 */

Icon.propTypes = {
  /** UI type */
  uiType: PropTypes.oneOf([templateTypes.material, templateTypes.bootstrap]),
  /** Icon element css class */
  elementStyle: PropTypes.string,
  /** Icon class */
  iconClass: PropTypes.string,
  /** Tooltip text */
  tooltip: PropTypes.string,
  /** Onclick event function */
  onClickFn: PropTypes.func,
};

//----------------DefaultIcon---------------------

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Toolbar Wrapper
 * --------------------------------------------
 */

const ToolBarWrapper = ({
  appBarStyle = '',
  appBarcolor = 'primary',
  appBarPosition = 'static',
  toolBarStyle = '',
  toolBarVariant = 'regular',
  children = null,
}) => {
  return (
    <AppBar
      className={`defaultAppBarWrapper fullWidthDiv ${appBarStyle}`}
      position={appBarPosition}
      color={appBarcolor}
    >
      <Toolbar
        className={`defaultToolBar ${toolBarStyle}`}
        variant={toolBarVariant}
      >
        {children}
      </Toolbar>
    </AppBar>
  );
};

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Toolbar Wrapper
 * --------------------------------------------
 */

ToolBarWrapper.propTypes = {
  /** App bar css class */
  appBarStyle: PropTypes.string,
  /** App bar color */
  appBarcolor: PropTypes.oneOf([
    'default',
    'inherit',
    'primary',
    'secondary',
    'transparent',
  ]),
  /** App bar position */
  appBarPosition: PropTypes.oneOf([
    'absolute',
    'fixed',
    'relative',
    'static',
    'sticky',
  ]),
  /** Tool bar css class */
  toolBarStyle: PropTypes.string,
  /** Tool bar variant */
  toolBarVariant: PropTypes.oneOf(['regular', 'dense']),
  /** Childrens */
  children: PropTypes.element,
};

//----------------ToolBarWrapper---------------------

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Collapse Wrapper
 * --------------------------------------------
 */

const CollapseWrapper = ({
  elementStyle = '',
  key = '',
  component = 'div',
  isIn = false,
  timeout = 'auto',
  isMountOnEnter = false,
  isUnMountOnExit = false,
  children = null,
}) => {
  return (
    <Collapse
      className={`defaultCollapseWrapper ${elementStyle}`}
      key={key}
      component={component}
      in={isIn}
      timeout={timeout}
      mountOnEnter={isMountOnEnter}
      unmountOnExit={isUnMountOnExit}
    >
      {children}
    </Collapse>
  );
};

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Collapse Wrapper
 * --------------------------------------------
 */

CollapseWrapper.propTypes = {
  /** Collapse element css class */
  elementStyle: PropTypes.string,
  /** Key */
  key: PropTypes.string,
  /** Component type */
  component: PropTypes.string,
  /** Is in */
  isIn: PropTypes.bool,
  /** Collapse timeout */
  timeout: PropTypes.number,
  /** Is mount on enter */
  isMountOnEnter: PropTypes.bool,
  /** Is unmount on enter */
  isUnMountOnExit: PropTypes.bool,
  /** Childrens */
  children: PropTypes.element,
};

//----------------CollapseWrapper---------------------

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Skeleton Wrapper
 * --------------------------------------------
 */

const skeletonWrapperAnimations = {
  pulse: 'pulse',
  wave: 'wave',
  false: false,
};

const skeletonWrapperVariants = {
  text: 'text',
  rect: 'rect',
  circle: 'circle',
};

const SkeletonWrapper = ({
  elementStyle = '',
  animation = skeletonWrapperAnimations.wave,
  component = 'span',
  height = '',
  width = '',
  variant = skeletonWrapperVariants.text,
}) => {
  return (
    <Skeleton
      className={`defaultSkeletonWrapper ${elementStyle}`}
      animation={animation}
      component={component}
      height={height}
      width={width}
      variant={variant}
    />
  );
};

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Skeleton Wrapper
 * --------------------------------------------
 */

SkeletonWrapper.propTypes = {
  /** Skeleton element css class */
  elementStyle: PropTypes.string,
  /** Animation style */
  animation: PropTypes.oneOf(['pulse', 'wave', false]),
  /** Root node component */
  component: PropTypes.string,
  /** Height */
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Width */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Variant */
  variant: PropTypes.oneOf(['text', 'rect', 'circle']),
};

//----------------SkeletonWrapper---------------------

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: ConvertDateFormat component
 * --------------------------------------------
 */
const ThemeProvider = ({ children = null }) => {
  return (
    <Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={createMuiTheme(baseTemplate)}>
        {children}
      </MuiThemeProvider>
    </Fragment>
  );
};

/**
 * --------------------------------------------
 * @Author: Nisal Madusanka(EruliaF)
 * @Description: Circle Loader
 * --------------------------------------------
 */
const CircleLoaderElement = ({
  loaderStyle = '',
  loaderSize = 40,
  loaderThickness = 2,
}) => {
  return (
    <CircularProgress
      className={loaderStyle}
      size={loaderSize}
      thickness={loaderThickness}
    />
  );
};

const VariableToComponent = ({
  component = <Fragment></Fragment>,
  props = {},
}) => {
  const UIComponent = component;

  return <UIComponent {...props} />;
};

const CheckPermission = ({
  permission = null,
  ifNotAllowed = null,
  children = null,
}) => {
  const [, checkISAllowedFn] = usePermission();
  return (
    <Fragment>
      {checkISAllowedFn(permission) === true ? (
        <Fragment>{children}</Fragment>
      ) : (
        <Fragment>
          {ifNotAllowed === null ? null : (
            <VariableToComponent component={ifNotAllowed} />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

/**
 * --------------------------------------------
 * @Author: Pradeepa Sandaruwan
 * @Description: View Detail Block component for all views
 *
 * --------------------------------------------
 */
const ViewDetailBlock = ({
  label = null,
  value = null,
  wrapperStyle = '',
  labelWrapperStyle = '',
  valueWrapperStyle = '',
  labelCol = '3',
}) => {
  return (
    <div className={`default-ViewDetailBlock  ${wrapperStyle}`}>
      <div className="row">
        <div
          className={`col-${labelCol} font-weight-bold text-right tittle-style ${labelWrapperStyle}`}
        >
          {label}
        </div>
        <div
          className={`col-${12 - labelCol
            } text-left detail-style view-block-word-wrap-style ${valueWrapperStyle}`}
        >
          {value}
        </div>
      </div>
    </div>
  );
};

/**
 * --------------------------------------------
 * @Author: Pradeepa Sandaruwan
 * @Description: View Audit Details Component for all component
 *
 * --------------------------------------------
 */


const AuditDetail = ({
  labelCol = "3",
  createdAt = "",
  createdRoleId = "",
  updatedAt = "",
  createdById = "",
  updatedById = "",
  createdLocationId = "",
  updatedLocationId = "",
  updatedRoleId = "",

}) => {
  const [auditState, setAuditState] = useState({
    created_by: "",
    updated_by: "",
    created_location: "",
    updated_location: ""
  });

  useEffect(() => {
    setDetails();
  }, []);

  const setDetails = async () => {
    let temp = {
      created_by: "",
      updated_by: "",
      created_location: "",
      updated_location: ""
    };

    if (!isEmptyValue(createdById) && createdById !== "no-header") {
      const createdByUsername = await getDetails("user", createdById);

      if (createdByUsername.status === true) {
        temp["created_by"] = createdByUsername.data;
      }
    }

    if (!isEmptyValue(updatedById) && updatedById !== "no-header") {
      const updatedByUsername = await getDetails("user", updatedById);

      if (updatedByUsername.status === true) {
        temp["updated_by"] = updatedByUsername.data;
      }
    }

    if (!isEmptyValue(createdLocationId)) {
      const createdLocationName = await getDetails("location", createdLocationId);
      if (createdLocationName.status === true) {
        temp["created_location"] = createdLocationName.data;
      }
    }

    if (!isEmptyValue(updatedLocationId)) {
      const updatedLocationName = await getDetails("location", updatedLocationId);

      if (updatedLocationName.status === true) {
        temp["updated_location"] = updatedLocationName.data;
      }
    }
    if (!isEmptyValue(updatedLocationId)) {
      const updatedLocationName = await getDetails("location", updatedLocationId);

      if (updatedLocationName.status === true) {
        temp["updated_location"] = updatedLocationName.data;
      }
    }
    if (!isEmptyValue(updatedRoleId)) {
      const updatedRole = await getRoleDetails(updatedRoleId);
      if (updatedRole.status === true) {
        temp["updated_role"] = updatedRole.data;
      }
    }
    if (!isEmptyValue(createdRoleId)) {
      const createdRole = await getRoleDetails(createdRoleId);

      if (createdRole.status === true) {
        temp["created_role"] = createdRole.data;
      }
    }
    setAuditState(temp);
  };
  const getRoleDetails = (id = "") => {
    return new Promise(resolve => {
      const api = userRolesListAPI.url;

      callApi(`${api}/${id}`)
        .isMultipart(false)
        .method("get")
        .headers(true)
        .send((error, result) => {
          if (!error) {

            resolve({
              status: true,
              data: _get(result, `data.data[name]`, "")
            });
          } else {
            resolve({
              status: false
            });
          }
        });
    })
  };
  const getDetails = (apiType = "user", id = "") => {
  
    return new Promise(resolve => {
      const api = apiType === "user" ? getAuditUsernameAPI.url : getAuditLocationNameAPI.url;

      callApi(`${api}${id}`)
        .isMultipart(false)
        .method("get")
        .headers(true)
        .send((error, result) => {
          if (!error) {
            const keyName = apiType === "user" ? "username" : "location_name";

            resolve({
              status: true,
              data: _get(result, `data.data[${keyName}]`, "")
            });
          } else {
            resolve({
              status: false
            });
          }
        });
    })
  };

  return (
    <UICard elementStyle="defaultMarginTop">
      <div className="col-md-12 col-sm-12 floatLeft">
        <ViewDetailBlock
          label={'Created By'}
          value={auditState.created_by+`${(auditState.created_role!=undefined)?`(`+auditState.created_role+`)`:''}`}
          labelCol={labelCol}
        />
      </div>

      <div className="col-md-12 col-sm-12 floatLeft">
        <ViewDetailBlock
          label={"Created At"}
          value={createdAt}
          labelCol={labelCol}
        />
      </div>

      <div className="col-md-12 col-sm-12 floatLeft">
        <ViewDetailBlock
          label={"Created Location"}
          value={auditState.created_location}
          labelCol={labelCol}
        />
      </div>
      <div className="col-md-12 col-sm-12 floatLeft">
        <ViewDetailBlock
          label={"Last Updated At"}
          value={updatedAt}
          labelCol={labelCol}
        />
      </div>

      <div className="col-md-12 col-sm-12 floatLeft">
        <ViewDetailBlock
          label={"Last Updated By"}
          value={auditState.updated_by+`${(auditState.updated_role!=undefined)?`(`+auditState.updated_role+`)`:''}`}
          labelCol={labelCol}
        />
      </div>

      <div className="col-md-12 col-sm-12 floatLeft">
        <ViewDetailBlock
          label={"Last Updated Location"}
          value={auditState.updated_location}
          labelCol={labelCol}
        />
      </div>

    </UICard>
  );
};

const GETFormState = ({
  formKey = '',
  defaultValue = {},
  parentToChild = {},
  component = EmptyComponent,
}) => {
  const [formState] = useContext(FormContext);

  return (
    <Fragment>
      <VariableToComponent
        component={component}
        props={{
          data: _get(formState, formKey, defaultValue),
          formParent: parentToChild,
        }}
      />
    </Fragment>
  );
};

const GETCoreState = ({
  formKey = '',
  defaultValue = {},
  parentToChild = {},
  component = EmptyComponent,
}) => {
  const [coreState] = useContext(CoreContext);

  return (
    <Fragment>
      <VariableToComponent
        component={component}
        props={{
          data: _get(coreState, formKey, defaultValue),
          formParent: parentToChild,
        }}
      />
    </Fragment>
  );
};

const InitialLoader = () => {
  return (
    <div className="initialLoader">
      <CircleLoaderElement
        loaderStyle={'loader'}
        loaderSize={50}
        loaderThickness={2}
      />
    </div>
  );
};

export {
  TooltipWrapper,
  BreadCrumbWrapper,
  UICard,
  UICardWithBackGround,
  Icon,
  ToolBarWrapper,
  CollapseWrapper,
  SkeletonWrapper,
  skeletonWrapperAnimations,
  skeletonWrapperVariants,
  ThemeProvider,
  CircleLoaderElement,
  VariableToComponent,
  emptyFun,
  CheckPermission,
  PaddingLessUICard,
  ViewDetailBlock,
  AuditDetail,
  GETFormState,
  GETCoreState,
  EmptyComponent,
  InitialLoader,
};
