import React, { Fragment, useContext } from "react";

import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import {
    InputBoxWithState,
    NumberInputBoxWithState,
    RoundButton
} from "../../../../ui-components/ui-elements/form";
import { templateTypes } from "../../../../../config/template.config";


const MonthRanges = (props) => {
    const [formState, formAction] = useContext(FormContext);

    const checkPlusBtnVisibility = (index) => {
        return (
            _get(formState, `${props.formKey}.monthRanges.${index}.value_to`, "") !== ""
                &&
            Object.keys(_get(formState, `${props.formKey}.monthRanges`, {})).length === index + 1
                &&
            parseInt(_get(formState, `${props.formKey}.monthRanges.${index}.value_to`, 0)) < 99
        );
    };

    return (
        <Fragment>
            {
                Object.keys(_get(formState, `${props.formKey}.monthRanges`, {})).map((value, index) => {
                    return (
                        <div className={"row"} key={index}>
                            <div className="col-md-3 col-sm-6 floatLeft">
                                <InputBoxWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName={props.formKey}
                                    inputStatePath={`${props.formKey}.monthRanges.${index}.value_from`}
                                    labelText="From"
                                    isDisabled={true}
                                    inputType={"number"}
                                />
                            </div>

                            <div className="col-md-3 col-sm-6 floatLeft">
                                <NumberInputBoxWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName={props.formKey}
                                    inputStatePath={`${props.formKey}.monthRanges.${index}.value_to`}
                                    labelText="To"
                                    isRequired={true}
                                    isThousandSeparator={false}
                                    isAllowNegative={false}
                                    decimalScale={0}
                                    isAllowedFn={(value) => value.value.toString().length <= 2}
                                />
                            </div>

                            <div className="col-md-3 col-sm-6 floatLeft">
                                <NumberInputBoxWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName={props.formKey}
                                    inputStatePath={`${props.formKey}.monthRanges.${index}.charge`}
                                    labelText={_get(formState, `${props.formKey}.charge_type`, "PRC") === "PRC" ? "Charge (%)" : "Charge (LKR)"}
                                    isRequired={true}
                                    isAllowNegative={false}
                                    decimalScale={_get(formState, `${props.formKey}.charge_type`, "PRC") === "PRC" ? 0 : 2}
                                    isAllowedFn={(value) => {
                                        let checkValue = value.value.toString();

                                        if (_get(formState, `${props.formKey}.charge_type`, "PRC") === "PRC") {
                                            return checkValue.length <= 2;
                                        }

                                        if (checkValue.includes(".")) {
                                            checkValue = checkValue.split(".")[0];
                                        }

                                        return checkValue.length <= 6;
                                    }}
                                />
                            </div>

                            <div className="col-md-3 col-sm-6 floatLeft roundBtnContainer">
                                <div className={"roundBtnVerticalCenter"}>
                                    {
                                        (checkPlusBtnVisibility(index)) ? (
                                            <RoundButton
                                                elementStyle={"mr-2"}
                                                iconClass={"fas fa-plus"}
                                                onClickBtnFn={(eventData) => {
                                                    const currentToValue = parseInt(_get(formState, `${props.formKey}.monthRanges.${index}.value_to`));
                                                    formAction.changeInputFn(props.formKey, "", `${props.formKey}.monthRanges.${index + 1}.value_from`, currentToValue + 1);
                                                }}
                                            />
                                        ) : null
                                    }

                                    {
                                        (index > 0 && Object.keys(_get(formState, `${props.formKey}.monthRanges`, {})).length === index + 1) ? (
                                            <RoundButton
                                                iconClass={"fas fa-minus"}
                                                onClickBtnFn={(eventData) => {
                                                    let monthRangeObj = _get(formState, `${props.formKey}.monthRanges`, {});
                                                    delete monthRangeObj[index.toString()];
                                                    formAction.changeInputFn(props.formKey, "", `${props.formKey}.monthRanges`, monthRangeObj);
                                                }}
                                            />
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </Fragment>
    );
};

export default MonthRanges;