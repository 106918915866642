import React,{useContext, useEffect} from "react";

import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { actionBtnTypes, DataTable } from "../../../ui-components/ui-elements/table/DataTable";
import { EditProvinceOffice, ProvinceOfficeEditBtn } from "./includes/EditProvinceOffice";
import ViewProvinceOffice from "./includes/ViewProvinceOffice";
import { pdmtListAPI } from "../../../../config/apiUrl.config";
import { permissions } from "../../../../config/permission.config";
import { AuthContext } from "../../core/context-providers/AuthContext.provider";
import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";
const ProvinceOffice = () => {
    const [authStatus, authAction] = useContext(AuthContext);
    const userRoleType=_get(authStatus, "authUser.role_level_code", "")
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <DashboardTemplate
            pageTitle="Manage Province Office"
            breadCrumbList={[
                { urlTo: "/", displayName: "Home" },
                { urlTo: "", displayName: "eRL Departments" },
                { urlTo: "", displayName: "Province Office" },
            ]}
        >
            <div className="row">
                <div className="col-md-12 col-sm-12">
                    <DataTable
                        apiUrl={`${pdmtListAPI.url}?service_id=${userRoleType === "ADN_NTL"?null:_get(authStatus, "authUser.service_id", "")}&sort_by=code|asc`}
                        dataTableKey={pdmtListAPI.key}
                        tableName={"Province Offices"}
                        tableHeaderList={[
                            { displayName: "Province Code", key: "code", isSort: false },
                            { displayName: "Province Prefix", key: "prefix", isSort: false },
                            { displayName: "DMT Mapping Code", key: "dmt_mapping", isSort: false },
                            { displayName: "Province Office Name", key: "service.name", isSort: false },
                            { displayName: "Province", key: "province.name", isSort: false }
                        ]}
                        actionTypeList={[
                            {
                                actionType: actionBtnTypes.edit,
                                actionBtn: {
                                    tooltip: 'Edit',
                                    "permissions": permissions.PDMT_EDIT.permissions
                                },
                                modelContentElement: EditProvinceOffice,
                                dialogPopupProps: {
                                    isFullScreen: true,
                                    isSaveButton: false,
                                    isCloseButton: true,
                                    dialogTitle: "Edit Province Office",
                                    closeButton: {
                                        btnText: "Cancel",
                                        startIcon: "far fa-times-circle",
                                    },
                                    isCustomButton: true,
                                    customButtonComponent: ProvinceOfficeEditBtn,
                                    isConfirmationPopup: true
                                }
                            },
                            {
                                actionType: actionBtnTypes.view,
                                actionBtn: {
                                    tooltip: "View",
                                    "permissions": permissions.PDMT_VIEW.permissions
                                },
                                modelContentElement: ViewProvinceOffice,
                                dialogPopupProps: {
                                    isFullScreen: true,
                                    isSaveButton: false,
                                    isCloseButton: true,
                                    dialogTitle: "View Province Office",
                                    isFullWidth: true,
                                    maxWidth: "md"
                                }
                            }
                        ]}
                    >

                    </DataTable>
                </div>
            </div>
        </DashboardTemplate>
    )
}

export default ProvinceOffice;
