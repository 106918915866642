import React from "react";
import {
    FormWrapper,
    InputBoxWithState,
    SelectBoxWithState,
    CheckBoxWithState,
    NumberInputBoxWithState
} from "../../../../../../ui-components/ui-elements/form";
import { templateTypes } from "../../../../../../../config/template.config";
import { _get } from "../../../../../../../helpers/common-helpers/lodash.wrappers";
import { contactsArrayToObject } from "../../../../../../../helpers/common-helpers/contact.helpers";
import { ContactTypeCode } from "../../../../../../../config/enum.config";
import { vrpCertificateOfficersListAPI } from "../../../../../../../config/apiUrl.config";

const IssuingOfficerForm = (props) => {

    const formKey = `${props.fromParent.formKey}`
    return (
        <FormWrapper
            setGroupName={formKey}
            formGroupLinkWith={formKey}
            onDestroyUnsetFormObject={true}
            setFormObject={{
                id: _get(props, "row.user.id", ""),
                reqId: `${props.fromParent.requiredId}`,
                full_name: _get(props, "row.full_name", ""),
                contact_number: contactsArrayToObject(_get(props, `row.user.contact`, []), false, ContactTypeCode.MOBILE),
                email: contactsArrayToObject(_get(props, `row.user.contact`, []), false, ContactTypeCode.EMAIL),
                status: _get(props, "row.user.active", 1),
                user_name: _get(props, "row.user.user_name", ""),
                display_name: _get(props, "row.display_name", ""),
                isSMS: _get(props, "row.user.sms_notification", true),
                isEmail: _get(props, "row.user.email_notification", true),
                title: _get(props, "row.title.id", "")

            }}
        >
            <div className="row">
                <div className="col-12 defaultMarginBottom">
                    <h6>Officer Information</h6>
                </div>

                <div className="col-6">
                    <InputBoxWithState
                        labelText={"Full Name"}
                        formGroupName={formKey}
                        isRequired={true}
                        inputName={"full_name"}
                        maxLength={150}
                    />
                </div>

                <div className="col-6">
                    <NumberInputBoxWithState
                        labelText={"Contact Number"}
                        formGroupName={formKey}
                        inputName={"contact_number"}
                        isRequired={true}
                        isThousandSeparator={false}
                        isAllowNegative={false}
                        isAllowLeadingZeros={true}
                        decimalScale={0}
                        isAllowedFn={(value) => value.value.toString().length <= 10}
                    />
                </div>

                <div className="col-6">
                    <InputBoxWithState
                        labelText={"E-mail"}
                        formGroupName={formKey}
                        isRequired={true}
                        inputName={"email"}
                        maxLength={100}
                    />
                </div>

                <div className="col-6">
                    <SelectBoxWithState
                        uiType={templateTypes.bootstrap}
                        formGroupName={formKey}
                        dataList={[
                            {
                                id: "1",
                                value: "Active ",
                            },
                            {
                                id: "0",
                                value: "Inactive",
                            },

                        ]}
                        inputName={"status"}
                        labelText="Status"
                    />
                </div>

                <div className="col-md-6 col-sm-12 floatLeft">

                </div>

                <div className="col-12 defaultMarginTopBottom">
                    <h6>User Login Information</h6>
                </div>
                <div className="col-md-6 col-sm-12 floatLeft">
                    {formKey === vrpCertificateOfficersListAPI.key + "_edit" ?
                        <div className="col-md-12 defaultHalfPaddingRight floatLeft">
                            Username: <div className="col-12 floatLeft">
                                <strong> {_get(props, "row.user.user_name", "")}</strong>
                            </div>
                        </div>
                        :
                        <InputBoxWithState
                            uiType={templateTypes.bootstrap}
                            formGroupName={formKey}
                            inputName={"user_name"}
                            labelText="Username"
                            isRequired={true}
                            maxLength={50}
                        />}
                </div>
                {/* <div className="col-6 floatLeft">
                    <InputBoxWithState
                        formGroupName={formKey}
                        inputName={"username"}
                        labelText="Username"
                        isRequired={uiType === "CREATE"}
                        isDisabled={uiType !== "CREATE"}
                    />
                </div> */}
                <div className="col-6 floatLeft">
                    <InputBoxWithState
                        uiType={templateTypes.bootstrap}
                        formGroupName={formKey}
                        inputName={"display_name"}
                        labelText="Display Name"
                        isRequired={true}
                        maxLength={100}
                    />
                </div>
                <div className="col-12 floatLeft">
                    <CheckBoxWithState
                        formGroupName={formKey}
                        inputName={"isSMS"}
                        labelText="Notify via SMS"
                        isRequired={true}
                        isDisabled={true}
                    />
                    <br />
                    <CheckBoxWithState
                        formGroupName={formKey}
                        inputName={"isEmail"}
                        labelText="Notify via E-mail"
                    />
                </div>
            </div>
        </FormWrapper >
    );
};

export { IssuingOfficerForm };
