import React, { Fragment, useContext } from "react";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { DataTable, actionBtnTypes, apiResponseStoringType } from "../../../ui-components/ui-elements/table/DataTable";
import { SubmitButton } from "../../../ui-components/ui-elements/form";
import { AllInfo } from "../pages/includes/Common";
import { SearchElement } from "../pages/includes/ManageVrpElements"
import { VrpCompanyForm } from "./includes/VrpCompanyForm";
import { vrpCompanyListAPI } from './../../../../config/apiUrl.config';
import { AuthContext } from "../../core/context-providers/AuthContext.provider";
import { getDataByFormObject, isEmptyValue } from "../../../../helpers/common-helpers/common.helpers";
import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { permissions } from "../../../../config/permission.config";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const CustomButtons = (props) => {

    const [authState] = useContext(AuthContext);

    return (
        <Fragment>
            <SubmitButton
                btnText={props.uiType === "EDIT" ? "Update" : "Save"}
                startIcon={props.uiType === "EDIT" ? "far fa-edit" : "far fa-save"}
                formGroupName={props.formGroupName}
                dataTableKey={props.dataTableKey}
                isValidate={true}
                flashMessages={{
                    "success": {
                        status: true,
                        message: (props.uiType) === "EDIT" ? "VRP Company Info is successfully updated." : "VRP Company is successfully saved.",
                        messageType: "success"
                    }
                }}
                mapValidationErrorObj={{
                    code: "VRP Company Code already exists",
                }}
                validationObject={{
                    fileds: {
                        code: "VRP Company Code",
                        line1: "Address Line 1 in English",
                        line1_si: "Address Line 1 in Sinhala",
                        line1_ta: "Address Line 1 in Tamil",
                        line2: "Address Line 2 in English",
                        line2_si: "Address Line 2 in Sinhala",
                        line2_ta: "Address Line 2 in tamil",
                        city: "City in English ",
                        city_si: "City in Sinhala",
                        city_ta: "City in Tamil",
                        postal_code: "Postal code",
                        contact_number_1: "Contact Number 1",
                        contact_number_2: "Contact Number 2",
                        email: "Email Address",
                        fax: "Fax Number",
                        name: "VRP Company Name in English",
                        name_si: "VRP Company Name in Sinhala",
                        name_ta: "VRP Company Name in Tamil",

                    },
                    rules: {
                        code: "required|max:20",
                        line1: "required",
                        line1_si: "required|max:50",
                        city: "required|max:50",
                        line1_ta: "required|max:50",
                        line2: "max:50",
                        line2_si: "max:50",
                        line2_ta: "max:50",
                        city_si: "required|max:50",
                        city_ta: "required|max:50",
                        postal_code: "max:6|required",
                        contact_number_1: "required|max:10|min:9",
                        contact_number_2: "numeric|max:10|min:9",
                        email: "max:50|email",
                        fax: "max:10|numeric|min:9",
                        name: "required|max:200",
                        name_si: "required|max:200",
                        name_ta: "required|max:200",

                    },
                    message: {
                    },
                }}
                callApiObject={{
                    isSetHeaders: true,
                    method: (props.uiType === "EDIT" ? ("put") : ("post")),
                }}
                apiDataStoringObject={{
                    setLoader: true,
                    storingType: props.uiType === "EDIT" ? apiResponseStoringType.responseLinkToFrom : apiResponseStoringType.dateTableLinkedForm
                }}
                onGetAPIEndPointFn={(formState) => {
                    return {
                        url: (props.uiType === "EDIT" ? (`${vrpCompanyListAPI.url}/` + formState.id) : (vrpCompanyListAPI.url)),
                        key: vrpCompanyListAPI.key
                    }
                }}
                onChangeRequestBodyFn={(fromObject) => {
                    let contact_number2_new;
                    if (fromObject.contact_number_2 === "" || fromObject.contact_number_2 === undefined) {
                        contact_number2_new = null
                    } else {
                        contact_number2_new = fromObject.contact_number_2
                    }

                    let fax_new;
                    if (fromObject.fax === "" || fromObject.fax === undefined) {
                        fax_new = null
                    } else {
                        fax_new = fromObject.fax
                    }

                    return getDataByFormObject({
                        ...fromObject,
                        province_code: authState.authUser.province_code,
                        pdmt_id: authState.authUser.pdmt_id,
                        line1: fromObject.line1,
                        line1_si: fromObject.line1_si,
                        line1_ta: fromObject.line1_ta,
                        line2: fromObject.line2,
                        line2_si: fromObject.line2_si,
                        line2_ta: fromObject.line2_ta,
                        city: fromObject.city,
                        city_si: fromObject.city_si,
                        city_ta: fromObject.city_ta,
                        postal_code: fromObject.postal_code,
                        contact_number_1: fromObject.contact_number_1,
                        contact_number_2: contact_number2_new,
                        email: fromObject.email,
                        fax: fax_new

                    })
                }}
                onResponseCallBackFn={(response, error) => {
                    if (response.length === undefined && error === undefined && response.data.errors === undefined && props.uiType !== "EDIT") {
                        props.onClosePopupFn();
                    }
                }}
            />
        </Fragment>
    );
}

const ManageVRPCompany = () => {

    const [authStatus] = useContext(AuthContext);
    const provinceCode = _get(authStatus, "authUser.province_code", "");
    const filter = isEmptyValue(provinceCode) ? "?sort_by=name|asc" : `?sort_by=name|asc&province_code=${provinceCode}`;
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <DashboardTemplate
            pageTitle="Manage VRP Company"
            breadCrumbList={[
                { urlTo: "/", displayName: "Home" },
                { urlTo: "", displayName: "Support Organisations" },
                { urlTo: "", displayName: "VRP Company" },
            ]}
        >
            <DataTable
                tableName={"VRP Companies"}
                apiUrl={`${vrpCompanyListAPI.url}${filter}`}
                dataTableKey={vrpCompanyListAPI.key}
                isSetAction={true}
                isSetSearchFrom={true}
                searchFromComponent={
                    <SearchElement dataTableKey={vrpCompanyListAPI.key} />
                }
                defaultSearchFormObject={{
                    code: "",
                    name: "",
                    active: null
                }}
                tableHeaderList={[
                    { displayName: "VRP Company Code", key: "code", isSort: false },
                    { displayName: "VRP Company Name", key: "service.name", default: "-", isSort: false },
                    { displayName: "Status", key: "service.active", isSort: false, onPrintFn: ({ service }) => service.active === 1 ? "Active" : "Inactive", default: "-", },
                ]}

                actionTypeList={[
                    {
                        actionType: actionBtnTypes.create,
                        actionBtn: {
                            "permissions": permissions.VRP_COMPANY_ADD.permissions
                        },
                        modelContentElement: VrpCompanyForm,
                        dialogPopupProps: {
                            isFullScreen: true,
                            isCloseButton: true,
                            dialogTitle: "Add VRP Company",
                            isCustomButton: true,
                            closeButton: {
                                btnText: "Cancel",
                                startIcon: "far fa-times-circle",
                            },
                            customButtonComponent: CustomButtons,
                            isConfirmationPopup: true,
                            customButtonComponentProps: {
                                btnText: "Save",
                                uiType: "CREATE",
                                dataTableKey: vrpCompanyListAPI.key
                            },
                        },
                        parentToModelProps: {
                            uiType: "CREATE",
                            formKey: vrpCompanyListAPI.key + "_create",
                        },
                    },
                    {
                        actionType: actionBtnTypes.edit,
                        actionBtn: {
                            tooltip: 'Configure',
                            icon: "mdi mdi-settings-outline",
                            "permissions": permissions.VRP_COMPANY_EDIT.permissions
                        },
                        modelContentElement: AllInfo,
                        dialogPopupProps: {
                            isFullScreen: true,
                            dialogTitle: "Configure VRP Company",
                            isSaveButton: false,
                            isCustomButton: false,
                            closeButton: {
                                btnText: "Close",
                                startIcon: "far fa-times-circle",
                            },
                            isConfirmationPopup: false,
                            customButtonComponent: CustomButtons,
                            customButtonComponentProps: { btnText: "Update", uiType: "EDIT", dataTableKey: vrpCompanyListAPI.key },
                        },
                        parentToModelProps: {
                            uiType: "EDIT",
                            formKey: vrpCompanyListAPI.key + "_edit",
                        },
                    },
                ]}
            />
        </DashboardTemplate>
    );
};

export { ManageVRPCompany, CustomButtons };