import React from "react";

import { DatePickerWithState, FormWrapper, SelectBoxWithState } from "../../../../ui-components/ui-elements/form";
import { templateTypes } from "../../../../../config/template.config";
import {
    chargingCategoryListAPI,
    fuelTypeListApi,
    licenceTypeListApi,
    vehicleClassAPI
} from "../../../../../config/apiUrl.config";

const SearchRates = (props) => {
    const formKey = `${props.dataTableKey}_search`;

    return (
        <FormWrapper
            setGroupName={formKey}
        >
            <div className={"row"}>
                <div className={"col-md-4"}>
                    <SelectBoxWithState
                        uiType={templateTypes.bootstrap}
                        formGroupName={formKey}
                        inputName={"licence_type_id"}
                        labelText="Licence Type"
                        isEnableAllOption={true}
                        apiUrl={`${licenceTypeListApi.url}/list?codes=NO&codes=NU&codes=CA&codes=DE`}
                        apiStateKey={licenceTypeListApi.key}
                        valueName={"name"}
                    />
                </div>

                <div className={"col-md-4"}>
                    <SelectBoxWithState
                        uiType={templateTypes.bootstrap}
                        formGroupName={formKey}
                        apiUrl={`${chargingCategoryListAPI.url}/list`}
                        apiStateKey={chargingCategoryListAPI.key}
                        inputName={"charging_category_code"}
                        labelText="Charging Category"
                        isEnableAllOption={true}
                        keyName={"code"}
                        valueName={"name"}
                    />
                </div>

                <div className={"col-md-4"}>
                    <SelectBoxWithState
                        uiType={templateTypes.bootstrap}
                        formGroupName={formKey}
                        apiUrl={`${vehicleClassAPI.url}/list?sort_by=name|asc`}
                        apiStateKey={vehicleClassAPI.key}
                        inputName={"vehicle_class_id"}
                        labelText="Vehicle Class"
                        isEnableAllOption={true}
                        valueName={"name"}
                    />
                </div>

                <div className={"col-md-4"}>
                    <SelectBoxWithState
                        uiType={templateTypes.bootstrap}
                        formGroupName={formKey}
                        inputName={"fuel_type_id"}
                        labelText="Fuel Type"
                        isEnableAllOption={true}
                        apiUrl={`${fuelTypeListApi.url}/list?sort_by=name|asc`}
                        apiStateKey={fuelTypeListApi.key}
                        valueName={"name"}
                    />
                </div>

                <div className={"col-md-4"}>
                    <DatePickerWithState
                        uiType={templateTypes.bootstrap}
                        formGroupName={formKey}
                        labelText="Applicable On"
                        inputName={"effective_from"}
                    />
                </div>
            </div>
        </FormWrapper>
    );
};

export default SearchRates;