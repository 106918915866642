import React, { Fragment } from "react";
import { FormWrapper, SelectBoxWithState, InputBoxWithState } from "../../../../ui-components/ui-elements/form";

const SearchElement = ({ dataTableKey = "" }) => {
    const formkey=`${dataTableKey}_search`
    return (
        <Fragment>
            <FormWrapper setGroupName={formkey}>
                <div className="row" >

                    <div className="col-md-2 col-sm-4 floatLeft">
                        <InputBoxWithState
                            formGroupName={formkey}
                            inputName={"delivery_method"}
                            maxLength={50}
                            labelText="Delivery Method"
                        />
                    </div>
                    <div className="col-md-2 col-sm-4 floatLeft">
                        <SelectBoxWithState
                            formGroupName={formkey}
                            dataList={[
                                {
                                    id: "true",
                                    value: "Active",
                                },
                                {
                                    id: "false",
                                    value: "Inactive",
                                }

                            ]}
                            inputName={"active"}
                            labelText="Status"
                            isEnableAllOption={true}
                        />
                    </div>
                </div>

            </FormWrapper>

        </Fragment>

    );
};


export { SearchElement };