import React, { Fragment } from "react";
import { vftCertificateOfficersListAPI, vftListAPI } from "../../../../../config/apiUrl.config";
import { getDataByFormObject } from "../../../../../helpers/common-helpers/common.helpers";
import { SubmitButton } from "../../../../ui-components/ui-elements/form";
import { apiResponseStoringType } from "../../../core/context-providers/CoreContext.provider";

const CustomButtonCertifying = (props) => {
    const uiType = props.uiType;

    return (

        <Fragment>
            <SubmitButton
                btnText={uiType === "CREATE" ? "Save" : "Update"}
                startIcon={uiType === "CREATE" ? "far fa-save" : "far fa-edit"}
                elementStyle="btnWrapper"
                formGroupName={props.formGroupName}
                isValidate={true}
                flashMessages={{
                    "success": {
                        status: true,
                        message: (uiType === "CREATE") ? "Certifying Officer is successfully saved." : "Certifying Officer is successfully updated.",
                        messageType: "success"
                    }
                }}
                validationObject={{
                    fileds: {
                        full_name: "full name",
                        contact_number: "contact number",
                        email: "email",
                        user_name: "username",
                        display_name: "display name",
                        isSMS: "SMS",
                        signature: "Signature"


                    },
                    rules: {
                        full_name: "required|max:150",
                        contact_number: "required|numeric|max:10|min:9",
                        email: "required|email|max:100",
                        user_name: "required|max:50",
                        display_name: "required|max:100",
                        isSMS: "required",
                        signature: "required"

                    },
                    message: {},
                }}
                mapValidationErrorObj={{
                    "email": "Email already exists.",
                    "contact_number": "The Contact Number must be a valid Contact Number.",
                    "user_name": "The username already exists.",
                    "display_name": "Display Name already exists.",
                }}
                dataTableKey={props.dataTableKey}
                callApiObject={{
                    isSetHeaders: true,
                    multipart: false,
                    method:
                        uiType === "CREATE"
                            ? "post"
                            : "put",
                    onUpload: false,
                }}
                apiDataStoringObject={{
                    setLoader: true,
                    storingType: apiResponseStoringType.responseLinkToFrom
                }}
                onGetAPIEndPointFn={(formState) => {
                    return {
                        url:uiType === "CREATE"
                                ? `${vftListAPI.url}/${formState.reqId}/users`
                                : `${vftListAPI.url}/${formState.reqId}/users/${formState.id}`,
                        key: vftCertificateOfficersListAPI.key
                    }
                }}
                onChangeRequestBodyFn={(fromObject) => {
                    return getDataByFormObject({
                        ...fromObject,
                        isSMS: (fromObject.isSMS === true || parseInt(fromObject.isSMS) === 1 ? 1 : 0),
                        isEmail: (fromObject.isEmail === true || parseInt(fromObject.isEmail) === 1 ? 1 : 0),
                        active:(parseInt(fromObject.status)),
                    });
                }}
                onResponseCallBackFn={(response, error) => {
                    if (
                        response.length === undefined &&
                        error === undefined &&
                        response.data.errors === undefined
                    ) {
                        props.onClosePopupFn();
                    }
                }}
            />
        </Fragment>
    );
}

export {
    CustomButtonCertifying
}
