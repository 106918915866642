import React, { Fragment } from "react";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { actionBtnTypes, apiResponseStoringType, DataTable } from "../../../ui-components/ui-elements/table/DataTable";
import { EditLicenceTypes } from "./includes/EditLicenceTypes";
import { LicenceTypes } from "./includes/LicenceTypes";
import { SearchElement } from "./includes/SearchElement";
import { SubmitButton } from "../../../ui-components/ui-elements/form";
import { licenceTypeListApi } from "../../../../config/apiUrl.config";
import { getDataByFormObject } from "../../../../helpers/common-helpers/common.helpers";
import { permissions } from "../../../../config/permission.config";
import { CheckPermission } from "../../../ui-components/ui-elements/common/BaseElements";
import { useEffect } from "react";

const emptyFn = (...para) => undefined;

const UpdateBtn = ({ onClosePopupFn = emptyFn }) => {
    return (
        <Fragment>
            <CheckPermission
                permission={permissions.LICENSE_TYPE_EDIT.permissions}
            >
                <SubmitButton
                    startIcon={"far fa-edit"}
                    btnText="Update"
                    elementStyle="btnWrapper"
                    formGroupName={`${licenceTypeListApi.key}_edit`}
                    dataTableKey={licenceTypeListApi.key}
                    isValidate={true}
                    flashMessages={{
                        "success": {
                            status: true,
                            message: "Licence Type is successfully updated.",
                            messageType: "success"
                        }
                    }}
                    mapValidationErrorObj={{
                        prefix: "Licence Type Prefix already exists",
                        name: "Licence Type Name already exists"
                    }}
                    validationObject={{
                        fileds: {
                            "prefix": "Licence Type Prefix",
                            "name": "Licence Type Name",
                        },
                        rules: {
                            "prefix": "required|max:2",
                            "name": "required|max:50",
                        },
                        message: {}
                    }}
                    onGetAPIEndPointFn={(formState) => {
                        return {
                            url: `${licenceTypeListApi.url}/${formState.id}`,
                            key: licenceTypeListApi.key,
                        };
                    }}
                    onChangeRequestBodyFn={({ ...formObject }) => {
                        formObject.id = undefined;
                        return getDataByFormObject({
                            ...formObject,
                            prefix: formObject.prefix.trim(),
                            name: formObject.name.trim(),
                            status: parseInt(formObject.status) === 1 ? true : false,
                        });
                    }}
                    callApiObject={{
                        isSetHeaders: true,
                        method: "put",
                        multipart: false,
                        onUpload: false
                    }}
                    apiDataStoringObject={{
                        setLoader: true,
                        storingType: apiResponseStoringType.dateTableLinkedForm,
                        mergeToSuccessResponse: null,
                        mergeToErrorResponse: null
                    }}
                    onResponseCallBackFn={(response, error) => {
                        if (response.length === undefined && error === undefined && response.data.errors === undefined) {
                            onClosePopupFn();
                        }
                    }}
                />
            </CheckPermission>

        </Fragment>
    );
};

const ManageLicenceType = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <DashboardTemplate
            pageTitle="Manage Licence Type"
            breadCrumbList={[
                { urlTo: "/", displayName: "Home" },
                { urlTo: "", displayName: "Configurations" },
                { urlTo: "", displayName: "Manage Licence Type" },
            ]}
        >
            <DataTable
                isSetSearchFrom={true}
                tableName="Licence Types"
                apiUrl={`${licenceTypeListApi.url}?sort_by=name|asc`}
                dataTableKey={licenceTypeListApi.key}
                defaultSearchFormObject={{
                    prefix: "",
                    name: "",
                    active: null
                }}
                searchFromComponent={
                    <SearchElement dataTableKey={licenceTypeListApi.key} />}
                isSetTableFooter={false}
                tableHeaderList={[
                    { displayName: "Licence Type Prefix", key: "prefix", isSort: false },
                    { displayName: "Licence Type Name", key: "name", isSort: false },
                    { displayName: "Status", key: "active", isSort: false, onPrintFn: ({ active }) => active === 1 ? "Active" : "Inactive" },
                ]}
                actionTypeList={[
                    {
                        actionType: actionBtnTypes.edit,
                        actionBtn: { tooltip: "Edit", "permissions": permissions.LICENSE_TYPE_EDIT.permissions },
                        modelContentElement: EditLicenceTypes,
                        dialogPopupProps: {
                            isFullScreen: false,
                            dialogTitle: "Edit Licence Type",
                            closeButton: {
                                btnText: "Cancel",
                                startIcon: "far fa-times-circle",
                            },
                            isCustomButton: true,
                            customButtonComponent: UpdateBtn,
                            isConfirmationPopup: true
                        },
                        parentToModelProps: {
                            formKey: licenceTypeListApi.key + "_edit",
                            dataTableKey: licenceTypeListApi.key,
                        },
                    },
                    {
                        actionType: actionBtnTypes.view,
                        actionBtn: {   tooltip: "View", "permissions": permissions.LICENSE_TYPE_VIEW.permissions },
                        modelContentElement: LicenceTypes,
                        dialogPopupProps: {
                            isFullScreen: false,
                            dialogTitle: "View Licence Type",
                        }
                    }
                ]}
            >
            </DataTable>
        </DashboardTemplate>

    );
};

export default ManageLicenceType;