/*
 * @Author: Anjula Karunarathne
 * @Date: 2020-12-30 13:57:41
 * @Last Modified by: Chamith Jayathilaka
 * @Last Modified time: 2022-08-11 00:58:00
 */

import React, { Fragment, useContext, useEffect, useState } from "react";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { UICard } from "../../../ui-components/ui-elements/common/BaseElements";
import {
  SubmitButton,
  FormWrapper,
  InputBoxWithState,
} from "../../../ui-components/ui-elements/form";
import {
  DataTable,
  actionBtnTypes,
} from "../../../ui-components/ui-elements/table/DataTable";
import { UploadDealerFilesForm } from "./includes/UploadDealerFilesForm";
import { dateObjectToString } from "../../../../helpers/common-helpers/dateTime.helpers";
import { getDataByFormObject } from "../../../../helpers/common-helpers/common.helpers";
import { dealerFileCreateAPi, dealerFileUpdateAPi, dealerFileGetAPi, dealerFileDeleteAPi, DMSMediatorApi } from "../../../../config/apiUrl.config";
import { apiResponseStoringType } from "../../../../config/apiResponseKey";
import { _size, _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { FormContext } from "../../core/context-providers/FormContext.provider";
import { RemoveForm } from "./includes/RemoveForm"
import { UIContext } from "../../../ui-components/context-providers/UIContext.provider";
import { CoreContext } from "../../core/context-providers/CoreContext.provider";
import { isEmpty } from "lodash";
import { Link, useLocation } from "react-router-dom";
import { DialogPopup } from "../../../ui-components/ui-elements/modal/DialogPopup";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";

const emptyFn = (...para) => undefined;

const UploadBtn = (props) => {
  const [formState, formAction] = useContext(FormContext);
  const [uiState, uiAction] = useContext(UIContext);
  const [coreState, coreAction] = useContext(CoreContext);
  const [isOpen, setIsOpen] = useState(false)
  // console.log('looo', coreState, formState);
  const [errorList, setErrorList] = useState()

  const closeFn = () => {
    setIsOpen(false)
    props.onClosePopupFn()
  }
  return (
    <Fragment>
      <SubmitButton
        btnText="Add"
        startIcon={"far fa-save"}
        elementStyle="btnWrapper"
        formGroupName={props.formGroupName}
        dataTableKey={dealerFileGetAPi.key}
        isValidate={false}
        mapValidationErrorObj={{
          description: "Document Description is required."
        }}
        validationObject={{
          fileds: {
            description: "Document Description",
          },
          rules: {
            description: "required",
          },
          message: {
            "description": "Document Description is required.",
          },
        }}
        // flashMessages={{
        //   success: {
        //     status: true,
        //     message: "Document Uploaded Successfully",
        //     messageType: "success",
        //   },
        // }}
        onGetAPIEndPointFn={(formState) => {
          return {
            url: `${dealerFileCreateAPi.url}`,
            key: dealerFileCreateAPi.key,
          };
        }}
        onChangeRequestBodyFn={(formObject) => {
          // console.log('lll', formObject);
          if (formObject.documentDescription === '' || formObject.documentDescription === null || formObject.documentDescription === undefined) {
            uiAction.setFlashMessage({
              status: true,
              message: `Document Description is required`,
              messageType: "error",
            });
          } else {
            return getDataByFormObject({
              name: _get(formState, 'dealerFileGetAPiKEY_create.file_name', undefined),
              url: _get(formState, `dealerFileGetAPiKEY_create.file_name_url`, undefined),
              description: formObject.documentDescription,
            });
          }


        }}
        callApiObject={{
          isSetHeaders: true,
          multipart: false,
          method: "post",
        }}
        apiDataStoringObject={{
          setLoader: true,
          storingType: apiResponseStoringType.dateTableLinkedForm,
        }}
        onResponseCallBackFn={(response, error) => {


          if (!isEmpty(_get(error, "data.errors", []))) {

            if (_get(error, "data.errors[0].error", "") === "Dealer File Empty") {
              uiAction.setFlashMessage({
                status: true,
                message: "Dealer File is Empty",
                messageType: "error",
              });
              props.onClosePopupFn();
            } else {
              setIsOpen(!isOpen)
              let errorToPop = "Faild To Upload The Dealer File "
              uiAction.setFlashMessage({
                status: true,
                message: errorToPop,
                messageType: "error",
              });
              setErrorList(_get(error, "data.errors", []))
            }
            console.log("file-error")

          } else if (!isEmpty(_get(error, "data.data", []))) {
            if(!isEmpty(_get(error, "data.data.duplicateVehicles", []))){

              uiAction.setFlashMessage({
                status: true,
                message: `Document Uploaded Successfully. ${_get(error, "data.data.duplicateVehicles", []).length} Duplicated Plates Found And Ignored`,
                messageType: "success",
              });
            }
            else{
              uiAction.setFlashMessage({
                status: true,
                message: " Document Uploaded Successfully.",
                messageType: "success",
              });
             
            }
            props.onClosePopupFn();
          }
          else if (response.length === undefined &&
            error === undefined &&
            response.data.errors === undefined) {
            props.onClosePopupFn();
          }

          // if (
          //   response.length === undefined &&
          //   error === undefined &&
          //   response.data.errors === undefined
          // ) {
          //   // console.log('RES-->', response, error);
          //  // props.onClosePopupFn();
          // }


        }}
      />
      <DialogPopup
        isOpen={isOpen}
        onClosePopupFn={closeFn}
        dialogTitle={"Dealer File Upload Error  "}
        isCustomButton={false}
        maxWidth={1200}
        isFullScreen={true}
      //customButtonComponent={ViewButton}
      >
        <ErrorTable dataList={errorList} />

      </DialogPopup>
    </Fragment>
  );
};

const ErrorTable = ({ dataList = [] }) => {

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
    <TableContainer sx={{ maxHeight: 100, border: '1px dashed grey' }}>
      <h5 className="my-3"><strong className="mx-3">Errors :</strong></h5>
      <Table stickyHeader aria-label="sticky table" >
        <TableHead >
          <TableRow >
            <TableCell >Error Row</TableCell>
            <TableCell sx={{ ml: 3, fontWeight: "bold" }} >Error Messages</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataList.map((data) => (
            <TableRow style={{backgroundColor:"#ffa4a4"}}

              sx={{ '&:last-child td, &:last-child th': { border: 2 } }}
            >
              <TableCell style={{width:"120px", verticalAlign: 'top'}}>{data.row - 1}</TableCell>
              <TableCell style={{fontSize:"12px"}} >
                <ul >{data.errors.map((item) => (
                  <li>{item.error}</li>
                ))}
                </ul></TableCell>
            </TableRow>
          ))}

        </TableBody>
      </Table>
    </TableContainer>
  </Paper>
  );
}

const deleteBtn = (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [, uiAction] = useContext(UIContext);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [formState, formAction] = useContext(FormContext);
  return (
    <Fragment>
      <SubmitButton
        btnText="Yes"
        startIcon={"fas fa-check"}
        elementStyle="btnWrapper"
        formGroupName={props.formGroupName}
        dataTableKey={props.dataTableKey}
        isValidate={false}
        flashMessages={{
          success: {
            status: true,
            message: "Document Deleted Successfully",
            messageType: "success",
          },
        }}
        onGetAPIEndPointFn={(formState) => {
          return {
            url: `${dealerFileDeleteAPi.url}/${_get(formState, `id`, undefined)}`,
            key: dealerFileDeleteAPi.key,
          };
        }}
        callApiObject={{
          isSetHeaders: true,
          multipart: false,
          method: "delete",
        }}
        apiDataStoringObject={{
          setLoader: true,
          storingType: apiResponseStoringType.dateTableLinkedForm,
        }}
        onResponseCallBackFn={(response, error) => {
          if (
            response.length === undefined &&
            error === undefined &&
            response.data.errors === undefined
          ) {
            uiAction.setFlashMessage({
              status: true,
              message: `Document Deleted successfully.`,
              messageType: "success",
            });
            props.onClosePopupFn();
          }
        }}
      />
    </Fragment>
  );
};

const downloadBtn = async (row) => {
  console.log(row);
  var a = document.createElement("a");
  // a.href = await `${DMSMediatorApi.url}/ref-no/${props.row.document_reference}`;
  a.href = row.url;
  a.setAttribute("download", row.name);
  a.click();
  // let downloadLink;
  // downloadLink = document.createElement("a");
  // downloadLink.href = window.URL.createObjectURL(url);
  // downloadLink.style.display = "none";
  // document.body.appendChild(downloadLink);
  // downloadLink.click();
};

const UploadDealerFiles = (props) => {
  const formKey = "uploadDealerFile";
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
}, [pathname]);


  return (
    <DashboardTemplate
      pageTitle="Manage Dealer Files"
      breadCrumbList={[
        { urlTo: "/", displayName: "Home" },
        { urlTo: "/dealerFiles", displayName: "Dealer Files" },
      ]}
    >
      <DataTable
        tableName={"Uploaded Documents"}
        apiUrl={`${dealerFileGetAPi.url}?sort_by=created_at|desc`}
        dataTableKey={dealerFileGetAPi.key}
        tableHeaderList={[
          {
            displayName: "Uploaded Date & Time",
            key: "created_date",
            default: "-",
            isSort: false,
            onPrintFn: ({ created_date }) =>
              dateObjectToString(created_date, "dd/mm/YY HH:MM"),
          },
          {
            displayName: "Uploaded by",
            key: "created_by",
            isSort: false,
            default: "-",
            onPrintFn: ({ created_by, province, authority, created_role }) =>
              created_by + (created_role ? '(' + created_role + ')' : '') + ((province !== "-" || authority !== "-") ? (' - ' + province + (authority !== "-" ? (' (' + authority + ')') : "")) : "")
          },

          {
            displayName: "Document Description",
            key: "description",
            isSort: false,
            default: "-",
          },
        ]}
        isSetAction={true}
        actionTypeList={[
          {
            actionType: actionBtnTypes.other,
            actionBtn: {
              icon: "fas fa-download",
              tooltip: "Download",
              onClickBtnFn: (row) => downloadBtn(_get(row, "row", "-")),
            },
          },
          {
            actionType: actionBtnTypes.delete,
            actionBtn: { tooltip: 'Remove' },
            modelContentElement: RemoveForm,
            dialogPopupProps: {
              dialogTitle: 'Please confirm',
              isFullScreen: false,
              isSaveButton: false,
              isCloseButton: true,
              isCustomButton: true,
              customButtonComponent: deleteBtn,
              customButtonComponentProps: { btnText: "Yes", uiType: "REMOVE", dataTableKey: dealerFileGetAPi.key },
              closeButton: {
                btnText: "No",
                startIcon: "fas fa-times",
              },

            },
            parentToModelProps: {
              documentId: (_get(props, "row.id", "")),
              formKey: dealerFileGetAPi.key + "_remove",
              dataTableKey: dealerFileGetAPi.key,
              uiType: "remove"
            },
          },
          {
            actionType: actionBtnTypes.create,
            modelContentElement: UploadDealerFilesForm,
            dialogPopupProps: {
              dialogTitle: "Upload Dealer File",
              isValidate: true,
              closeButton: {
                btnText: "Cancel",
              },
              isCustomButton: true,
              isFullScreen: false,
              isFullWidth: true,
              customButtonComponent: UploadBtn,
            },
            parentToModelProps: {
              formKey: dealerFileGetAPi.key + "_create",
              dataTableKey: dealerFileGetAPi.key,
              uiType: "Add"
            }
          },
        ]}
      />
    </DashboardTemplate>
  );
};

export default UploadDealerFiles;
