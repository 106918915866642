/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-09-07 13:47:09
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-04-06 13:32:39
 */

import React, { useContext, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Badge from '@material-ui/core/Badge';

import { MenuItemWrapper, MenuWrapper, } from '../../ui-elements/lists-and-menus/ListsAndMenus';
import { CheckPermission, Icon, UICard } from '../../ui-elements/common/BaseElements';
import { DialogPopup } from '../../ui-elements/modal/DialogPopup';
import { FormWrapper, SubmitButton, } from '../../ui-elements/form/index';
import { userDelegationChangeAPI, userDelegationCreateAPI, userInfoAPI, userLogoutAPI } from "../../../../config/apiUrl.config";
import { _find, _findindex, _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { FormContext } from "../../../modules/core/context-providers/FormContext.provider";
import { apiResponseStoringType } from "../../../../config/apiResponseKey";
import { AuthContext } from "../../../modules/core/context-providers/AuthContext.provider";
import { CoreContext } from "../../../modules/core/context-providers/CoreContext.provider";
import { authTokenStorageKey, ReactAppVerNO } from "../../../../config/core.config";
import { callApi } from '../../../../helpers/common-helpers/callApi.helpers';
import { setToLocalStorage } from '../../../../helpers/common-helpers/manageStorage.helpers';
import { getDataByFormObject } from '../../../../helpers/common-helpers/common.helpers';
import { permissions } from '../../../../config/permission.config';
import { UIContext } from "../../context-providers/UIContext.provider";

const emptyFn = (...para) => undefined;

const ChangeDelegation = (
  {
    delegation_id = "",
    user_id = "",
    onClosePopupFn = emptyFn,
  }
) => {
  const history = useHistory();
  const [authStatus, authAction] = useContext(AuthContext);
  const [uiState, uiAction] = useContext(UIContext);

  return (
    <SubmitButton
      btnText={'Change'}
      startIcon={'fas fa-exchange-alt'}
      isValidate={true}
      formGroupName={"switchDelegation"}
      flashMessages={{
        "success": {
          status: true,
          message: "Delegation Switched successfully.",
          messageType: "success"
        }
      }}
      validationObject={{
        fileds: {
          "switchDelegation": "Switch Delegation",
        },
        rules: {
          "switchDelegation": "switchDeligationError",
        }
      }}
      onChangeRequestBodyFn={(fromObject) => {
        return getDataByFormObject(fromObject);

      }}
      callApiObject={{
        isSetHeaders: true,
        multipart: false,
        method: "put",
        onUpload: false
      }}
      apiDataStoringObject={{
        setLoader: true,
        storingType: apiResponseStoringType.responseLinkToFrom
      }}
      onGetAPIEndPointFn={(formState) => {
        return {
          "url": `${userDelegationChangeAPI.url}/${user_id}/user/${delegation_id}`,
          "key": userDelegationChangeAPI.key
        }
      }}
      onResponseCallBackFn={(error, result) => {
        if (!error) {
          onClosePopupFn();
          callApi(userInfoAPI.url)
            .headers(true)
            .method('get')
            .send((err, result) => {
              console.log('ppp-auth', result);
              if (!err) {
                const tokens = {
                  accessToken: _get(authStatus, "accessToken", ""),
                  refreshToken: _get(authStatus, "refreshToken", ""),
                  keycloakObject: _get(authStatus, "keycloakObject", {}),
                };
                tokens['id'] = _get(result, 'data.sub', null);
                tokens['name'] = _get(result, 'data.display_name', null);
                tokens['role'] = _get(result, 'data.role_name', []);
                tokens['permission'] = _get(result, 'data.permissions', []);
                tokens['current_delegation'] = _get(result, 'data.current_delegation_id', null);
                tokens['service_id'] = _get(result, 'data.service_id', null);
                tokens['pdmt_code'] = _get(result, 'data.pdmt.code', null);
                tokens['pdmt_id'] = _get(result, 'data.pdmt.id', null);
                tokens['province_code'] = _get(result, 'data.pdmt.province.code', null);
                tokens['role_name'] = _get(result, 'data.role_name', null);
                tokens['role_level_code'] = _get(result, 'data.role_level_code', null);
                tokens['role_type_code'] = _get(result, 'data.role_type_code', null);
                tokens['keycloak'] = _get(authStatus, "keycloak", "");
                setToLocalStorage(authTokenStorageKey, tokens);
                authAction.setTokensFn(tokens);

                uiAction.getNotificationCount({
                  read: false,
                  pdmt_service_id: _get(result, 'data.service_id', null),
                   user_id: _get(result, 'data.sub', null),
                  role_category_code: _get(result, 'data.role_level_code', undefined)  ===  "ADN_NTL" ? undefined : "SUP|PDM_ADN",
                  current_role_category: "PDM_ADN"
                });
              }
            })
          history.push("/");
        }
      }}
    />
  );
};

const NotificationWrapper = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [getState, setState] = useState({
    delegationModelStatus: false,
  });
  const history = useHistory();
  const [formState, formAction] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);
  const [getDelegation, setDelegation] = useState(null);
  const [coreState, coreAction] = useContext(CoreContext);
  const [uiState] = useContext(UIContext);

  const setDeligationValue = (id) => {
    setDelegation(id)
    formAction.changeInputFn("switchDelegation", "switchDelegation", "", id)
  }

  const logoutApiCall = () => {
    const requestBody = {
      //client_id:_get(authStatus, 'keycloak.clientId', null),
      refresh_token: _get(authStatus, 'keycloak.refreshToken', null)
    }

    coreAction.sendRequestFn(userLogoutAPI.url)
      .isMultipart(false)
      .method("post")
      .body(requestBody)
      .setLoader(true)
      .setInitStoring("none", null)
      .headers(true)
      .send((error, result) => {
        if (!error) {
          authAction.unauthorisedUserFn();
        }
      });

  }

  const profileMenuFn = (key) => {

    if (key === 'delegation') {
      setState({
        ...getState,
        delegationModelStatus: true,
      });
    } else if (key === 'logout') {
      // authAction.unauthorisedUserFn();
      //authStatus.keycloak.logout();
      logoutApiCall();
    } else if (key === 'notification') {
      history.push('/notification');
    } else if (key === 'changePassword') {
      history.push('/change-password');
    }

    setAnchorEl(undefined);
  };

  const switchDeligationError = () => {
    const check = _find(_get(formState, `switchDelegation._errors`, []), (e) => e.property === "switchDelegation");
    if (check !== undefined) {
      return {
        status: true,
        message: check.message
      };
    } else {
      return {
        status: false
      }
    }
  };
  useEffect(()=>{
    // console.log('FFF--', uiState.notificationCount);
    // formAction.changeInputFn("Notification", "count", "",uiState.notificationCount )
},[])

  return (
    <div className={`notificationNaviBar`}>
      <ul className={'notificationUL'}>
        <li>
          <strong>{_get(authStatus, 'authUser.name', "")} ({_get(authStatus, 'authUser.roles', "")})
            {(_get(authStatus, 'authUser.pdmt_code', "")) ?
              (` - ${_get(authStatus, 'authUser.pdmt_code', '')}`) :
              (` - ${_get(authStatus, 'authUser.authority_code', '')}`)
            }
          </strong>
        </li>
        <CheckPermission
          permission={permissions.NOTIFICATION_VIEW.permissions}
        >
          <li>
            <span onClick={() => profileMenuFn('notification')}>
              {
                (uiState?.notificationCount === null || uiState?.notificationCount === 0 || uiState?.notificationCount < 0) ? (
                    <i className="mdi mdi-bell mainIcon"></i>
                ) : (
                    <Badge badgeContent={uiState?.notificationCount} color="error">
                      <i className="mdi mdi-bell mainIcon"></i>
                      {/*<i className="mdi mdi-chevron-down arraow"></i>*/}
                    </Badge>
                )
              }
            </span>
          </li>
        </CheckPermission>

        <li aria-controls="profileMenu" aria-haspopup="true">
          <span onClick={(event) => setAnchorEl(event.currentTarget)}>
            <i className="mdi mdi-account mainIcon"></i>
            <i className="mdi mdi-chevron-down arraow"></i>
          </span>

          <MenuWrapper
            id="profileMenu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onCloseFn={() => profileMenuFn(null)}
            elementStyle={'hedaerMenus profileMenu'}
          >
            <CheckPermission
              permission={permissions.DELEGATION_SWITCH.permissions}
            >
              <MenuItemWrapper
                elementStyle={'profileMenuItem'}
                menuKey="delegation"
                onClickFn={profileMenuFn}
                onCloseFn={() => profileMenuFn(null)}
              >
                <p>
                  <Icon iconClass="fas fa-user-clock" />
                  Switch Delegation
                </p>
              </MenuItemWrapper>
            </CheckPermission>

            <CheckPermission
              permission={permissions.PASSWARD_CHANGE.permissions}
            >
              <MenuItemWrapper
                elementStyle={'profileMenuItem'}
                menuKey="changePassword"
                onClickFn={profileMenuFn}
                onCloseFn={() => profileMenuFn(null)}
              >
                <p>
                  <Icon iconClass="fas fa-user-edit" />
                  Change Password
                </p>
              </MenuItemWrapper>
            </CheckPermission>


            <MenuItemWrapper
              elementStyle={'profileMenuItem'}
              menuKey="logout"
              onClickFn={profileMenuFn}
              onCloseFn={() => profileMenuFn(null)}
            >
              <p>
                <Icon iconClass="mdi mdi-logout" />
                Logout
              </p>
            </MenuItemWrapper>
            <MenuItemWrapper
              elementStyle={'profileMenuItem'}
              menuKey="logout"
              onCloseFn={() => profileMenuFn(null)}
            >
              <p>
                {`Version ${ReactAppVerNO}`}
              </p>

            </MenuItemWrapper>
          </MenuWrapper>
        </li>
      </ul>

      <DialogPopup
        isOpen={getState.delegationModelStatus}
        dialogTitle={'Switch Delegation'}
        onClosePopupFn={() =>
          {
            setState({
              ...getState,
              delegationModelStatus: !getState.delegationModelStatus,
            })
            setDelegation(null)
          }
        }
        isCloseButton={true}
        closeButton={{
          btnText: "Cancel",
          startIcon: 'far fa-times-circle'
        }}
        isCustomButton={true}
        customButtonComponent={ChangeDelegation}
        customButtonComponentProps={
          {
            delegation_id: getDelegation,
            user_id: _get(authStatus, 'authUser.id', "")
          }
        }
      >
        <UICard>
          <FormWrapper
            // elementStyle="col-md-12"
            setGroupName={'switchDelegation'}
            apiUrl={`${userDelegationCreateAPI.url}/${_get(authStatus, 'authUser.id', "")}/list`}
            onRebuildResponseFn={(response) => {
              const index = _findindex(response, (e) => {
                return e.id === _get(authStatus, 'authUser.current_delegation', "")
              })
              const current_delegation = response.splice(index, 1);
              return {
                "current_delegation": current_delegation[0],
                "delegations": response,
                "_onLoad": false,
              }
            }}
          >
            <div className="row">
              <div className="col-md-12 defaultMarginBottom">
                <dl className="row">
                  <dt className="col-sm-6 text-right">Current Delegation</dt>
                  <dd className="col-sm-6">{_get(formState, 'switchDelegation.current_delegation.role.name', "")}
                    {_get(formState, 'switchDelegation.current_delegation.orgData.pdmt[0].code', null) ?
                      _get(formState, 'switchDelegation.current_delegation.orgData.pdmt[0].code', null) === null ? ("") : ((` - ${_get(formState, 'switchDelegation.current_delegation.orgData.pdmt[0].code', '')}`)) :
                      _get(formState, 'switchDelegation.current_delegation.orgData.authority[0].pdmt[0].code', null) === null ? ("") : (` - ${_get(formState, 'switchDelegation.current_delegation.orgData.authority[0].pdmt[0].code', '')}`)

                    }</dd>
                </dl>

              </div>


              <div className="col-md-12 defaultHalfMarginTop">
                {
                  (switchDeligationError().status === true) ? (
                    <div className={"defaultFormHelperText text-danger"}>
                      {switchDeligationError().message}
                    </div>
                  ) : null
                }

                <UICard
                  cardHeading="Switch To"
                  inputName={"switchDelegation"}
                >
                  <React.Fragment>
                    {
                      _get(formState, 'switchDelegation.delegations', []).map(delegation => (
                        <div className="col-md-12" key={delegation.id}>
                          <p onClick={() => { setDeligationValue(_get(delegation, 'id', "")) }} style={{ cursor: "pointer" }}>
                            {
                              (getDelegation === _get(delegation, 'id', "") ? (
                                <i className="mdi mdi-check mainIcon" />
                              ) : null)
                            }
                            {_get(delegation, 'role.name', "")} {(_get(delegation, 'orgData.pdmt[0].code', null) ?
                              _get(delegation, 'orgData.pdmt[0].service.name', null) === null ? ("") : (`- ${_get(delegation, 'orgData.pdmt[0].service.name', "")}`) :
                              _get(delegation, 'orgData.authority[0].pdmt[0].service.name', null) === null ? ("") : (` - ${_get(delegation, 'orgData.authority[0].pdmt[0].service.name', '')}`))}
                          </p>
                        </div>
                      ))
                    }
                  </React.Fragment>
                </UICard>

              </div>
            </div>
          </FormWrapper>
        </UICard>
      </DialogPopup>
    </div>
  );
};

const HeaderWrapper = ({
  fixedMenuStatus = true,
  pageTitle = '',
  breadCrumbList = [],
}) => {
  return (
    <div
      className={`headerWrapper ${fixedMenuStatus === true ? 'halfHeader' : 'fullHeader'
        }`}
    >
      <div className={`breadcrumbWrapper`}>
        <div className="headerTxtWrapper">
          <h1 className="pageHeadingTxt">{pageTitle}</h1>
        </div>
        <div className="pageLinks">
          {breadCrumbList.length !== 0 ? (
            <span className="otherLinks">
              <i className="mdi mdi-home"></i>
              <span className="separator">/</span>
            </span>
          ) : null}
          {breadCrumbList.map((item, index) => {
            if (index === breadCrumbList.length - 1) {
              return (
                <span key={index} className="lastLink">
                  {item.displayName}
                </span>
              );
            } else if(index === breadCrumbList.length - 2) {
              return (
                <span className="otherLinks">
                  {item.displayName}
                  <span className="separator">/</span>
                </span>
              );
            } else {
              return (
                <Link
                  key={index}
                  to={item.urlTo}
                  className={`defaultLinkClass`}
                >
                  <span key={index} className="otherLinks">
                    {item.displayName}
                    <span className="separator">/</span>
                  </span>
                </Link>
              );
            }
          })}
        </div>
      </div>

      <NotificationWrapper />
    </div>
  );
};

export { HeaderWrapper };
