import React, { Fragment, useContext, useEffect } from "react";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { SubmitButton } from "../../../ui-components/ui-elements/form";
import { actionBtnTypes, apiResponseStoringType, DataTable } from "../../../ui-components/ui-elements/table/DataTable";
import AddAuthority from "./includes/AddAuthority";
import SerialNumbers from "./includes/SerialNumbers";
import { SearchElement } from "./includes/AuthorityElement";
import { ViewAuthority } from "./includes/ViewAuthority";
import { authorityListAPI, authoritySerialNumbersCreateApi } from "../../../../config/apiUrl.config";
import { getDataByFormObject, isEmptyValue } from "../../../../helpers/common-helpers/common.helpers";
import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { FormContext } from "../../core/context-providers/FormContext.provider";
import { AuthContext } from "../../core/context-providers/AuthContext.provider";
import { CheckPermission } from "../../../ui-components/ui-elements/common/BaseElements";
import { permissions } from "../../../../config/permission.config";

const CustomButtons = (props) => {
    const [formState] = useContext(FormContext);

    return (
        <CheckPermission
        permission={permissions.AUTHORITY_ADD.permissions}
        >
            <SubmitButton
                btnText={props.uiType === "EDIT" ? "Update" : "Save"}
                formGroupName={props.formGroupName}
                tooltip={props.uiType === "EDIT" ? "Edit" : "Create New"}
                startIcon="far fa-save"
                dataTableKey={props.dataTableKey}
                isValidate={true}
                flashMessages={{
                    "success": {
                        status: true,
                        message: `Authority is successfully ${props.uiType === "EDIT" ? "updated" : "saved"}.`,
                        messageType: "success"
                    }
                }}
                mapValidationErrorObj={{
                    "email": "Email already exists",
                    "name": "Authority Name in English already exists",
                    "name_si": "Authority Name in Sinhala already exists",
                    "name_ta": "Authority Name in Tamil already exists"
                }}
                validationObject={{
                    fileds: {
                        "contact_number_1": "Contact Number 1",
                        "code": "Authority Code",
                        "pdmt_id": "Province Office",
                        "district_code": "District",
                        "prefix": "Authority Prefix",
                        "name": "Authority Name - English",
                        "name_si": "Authority Name - Sinhala",
                        "name_ta": "Authority Name - Tamil",
                        "authority_type_code": "Authority Type",
                        // "status": "Status",
                        // "contacts": "Contacts",
                        "contact_number_2": "Contact Number 2",
                        "email": "E-mail",
                        "fax": "Fax",
                        "line1": "Address Line 1 - English",
                        "line2": "Address Line 2 - English",
                        "city": "City - English",
                        "line1_si": "Address Line 1 - Sinhala",
                        "line1_ta": "Address Line 1 - Tamil",
                        "line2_si": "Address Line 2 - Sinhala",
                        "line2_ta": "Address Line 2 - Tamil",
                        "city_si": "City - Sinhala",
                        "city_ta": "City - Tamil",
                        // "email": "Email",
                        "postal_code": "Postal Code",
                        "service_operation_types_allowed": "Service Transaction Types",
                        "service_licence_types_allowed": "Service Licence Types",
                        "licence_reorder_level": "Reorder Level",
                    },
                    rules: {
                        "contact_number_1": "required|max:10|numeric|min:9",
                        "code": "required|max:12",
                        "pdmt_id": "required",
                        "district_code": "required",
                        "prefix": "required|max:12",
                        "name": "required|max:50",
                        // "email": "email",
                        "name_si": "required|max:50",
                        "name_ta": "required|max:50",
                        "authority_type_code": "required",
                        // "status": "required",
                        // "contacts": "required",
                        "contact_number_2": "max:10|numeric|min:9",
                        "email": "max:50|email",
                        "fax": "max:10|alphaSpecial",
                        "line1": "required|max:50",
                        "line2": "max:50",
                        "city": "required|max:50",
                        "line1_si": "required|max:50",
                        "line1_ta": "required|max:50",
                        "line2_si": "max:50",
                        "line2_ta": "max:50",
                        "city_si": "required|max:50",
                        "city_ta": "required|max:50",
                        "postal_code": "required|max:6|alphaSpecial",
                        "service_operation_types_allowed": "getTransactionAllowedError",
                        "service_licence_types_allowed": "getLicenceTypeError",
                        "licence_reorder_level": "required|max:6|numeric",
                    }
                }}
                onGetAPIEndPointFn={(formState) => {
                    return {
                        url: props.uiType === "EDIT" ? `${authorityListAPI.url}/${formState.id}` : authorityListAPI.url,
                        key: authorityListAPI.key
                    }
                }}
                onChangeRequestBodyFn={(fromObject) => {
                    let data = getDataByFormObject(fromObject);
                    data.id = undefined;
                    const service_operation_types_allowed = [];

                    _get(data, "service_operation_types_allowed", []).forEach((value) => {
                        if (data.check_transaction_type[value.id] === true) {
                            service_operation_types_allowed.push(value.id);
                        }
                    });

                    const licence_types_allowed = [];
                    _get(formState, "authorityListAPIKey_create1.service_licence_types_allowed", []).forEach((value) => {
                        if (_get(formState, `authorityListAPIKey_create1.check_licence_type[${value.id}]`, "") === true) {

                            licence_types_allowed.push(value.id);
                        }
                    });
                    const pre = !isEmptyValue(data.prefix) ? _get(data, 'district_code', "") + data.prefix : null;
                    /**
                     * Normal/ Free/ Non-User Licence checking
                     * if Normal/ Free/ Non-User Licence is checked, 
                     * NO, NU, FE all 3 will added as allowed licence types
                     */
                    const lcnTypes = _get(formState, `authorityListAPIKey_create1.service_licence_types_allowed`, []);
                    const index = lcnTypes.map(object => object.code).indexOf('NO');
                    const normalLcnTypId = lcnTypes[index]['id'];
                    const freeLcnTypIdx = lcnTypes.map(object => object.code).indexOf('FE');
                    const nonUsrLcnTypIdx = lcnTypes.map(object => object.code).indexOf('NU');
                    const freeLcnTypId = lcnTypes[freeLcnTypIdx]['id'];
                    const nonUsrLcnTypId = lcnTypes[nonUsrLcnTypIdx]['id'];
                    if(licence_types_allowed.includes(normalLcnTypId)){
                        licence_types_allowed.push(freeLcnTypId, nonUsrLcnTypId);
                    }else{
                        if(licence_types_allowed.includes(freeLcnTypId) || licence_types_allowed.includes(nonUsrLcnTypId)){
                            licence_types_allowed.splice(licence_types_allowed.indexOf(freeLcnTypId), 1);
                            licence_types_allowed.splice(licence_types_allowed.indexOf(nonUsrLcnTypId), 1);
                        }
                    }
                    return {
                        ...data,
                        contacts: {
                            "contact_number_1": data.contact_number_1,
                            "contact_number_2": data.contact_number_2,
                            "email": (data.email) ? data.email : null,
                            "fax": data.fax
                        },
                        service_operation_types_allowed: service_operation_types_allowed,
                        prefix: pre,
                        service_licence_types_allowed: licence_types_allowed,
                        active: parseInt(data.active) === 1 ? true : false,
                    }
                }}
                callApiObject={{
                    isSetHeaders: true,
                    method: props.uiType === "EDIT" ? "put" : "post",
                }}
                apiDataStoringObject={{
                    setLoader: true,
                    storingType: apiResponseStoringType.dateTableLinkedForm
                }}
                onResponseCallBackFn={(response, error) => {
                    if (response.length === undefined && error === undefined && response.data.errors === undefined) {
                        props.onClosePopupFn();
                    }
                }}
            />
        </CheckPermission>

    )
}

const EditButton = ({ }) => {

    return (
        <Fragment>
            <SubmitButton
                btnText="Update"
                elementStyle="btnWrapper"
            />
        </Fragment>
    )
}

const Authority = () => {

    const [, formAction] = useContext(FormContext);
    const [authStatus, authAction] = useContext(AuthContext);
    const provinceCode = _get(authStatus, "authUser.province_code", "");
    const provinceId = _get(authStatus, "authUser.pdmt_id", "");
    const filter = isEmptyValue(provinceCode) ? "?sort_by=name|asc" : `?sort_by=name|asc&province_code=${provinceCode}`;

    useEffect(() => {
        return () => {
            formAction.removeFromGroupFn(authorityListAPI.key + "_create")
            formAction.removeFromGroupFn(authorityListAPI.key + "_edit")
            formAction.removeFromGroupFn("authorityListAPIKey_create1")
        }
    }, [])
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <DashboardTemplate
            pageTitle="Manage Authority"
            breadCrumbList={[
                { urlTo: "/", displayName: "Home" },
                { urlTo: "", displayName: "eRL Departments" },
                { urlTo: "", displayName: "Authorities" }
            ]}
        >
            <div className="row">
                <div className="col-md-12 col-sm-12">
                    <div className="row">
                        <div className="col-md-12">
                            <DataTable
                                apiUrl={`${authorityListAPI.url}${filter}`}
                                dataTableKey={authorityListAPI.key}
                                isSetSearchFrom={true}
                                isSetAction={true}
                                tableName="Authorities"
                                searchFromComponent={
                                    <SearchElement dataTableKey={authorityListAPI.key} />
                                }
                                defaultSearchFormObject={{
                                    name: "",
                                    code: "",
                                    district_code: null,
                                    active: null,
                                    authority_type_code: null,
                                    province_id: provinceId
                                }}
                                tableHeaderList={[
                                    { displayName: "Authority Code", key: "code", isSort: false },
                                    { displayName: "Authority Prefix", key: "prefix", isSort: false },
                                    { displayName: "Authority Name", key: "service.name", isSort: false },
                                    { displayName: "Authority Type", key: "authority_type.name", isSort: false },
                                    { displayName: "Province Office", key: "pdmt_id.service.name", isSort: false },
                                    { displayName: "District", key: "district.name", isSort: false },
                                    {
                                        displayName: "Status", key: "service.active", isSort: false, onPrintFn: (rawData) => {
                                            return rawData.service.active === 1 ? "Active" : "Inactive";
                                        }
                                    },
                                ]}
                                actionTypeList={[
                                    {
                                        actionType: actionBtnTypes.create,
                                        actionBtn: {
                                            "permissions": permissions.AUTHORITY_ADD.permissions
                                        },
                                        modelContentElement: AddAuthority,
                                        dialogPopupProps: {
                                            isFullScreen: true,
                                            dialogTitle: "Add Authority",
                                            closeButton: {
                                                btnText: "Cancel",
                                                startIcon: "far fa-times-circle",
                                            },
                                            isSaveButton: false,
                                            isCustomButton: true,
                                            customButtonComponent: CustomButtons,
                                            isConfirmationPopup: true,
                                            customButtonComponentProps: { 
                                                btnText: "Save", 
                                                uiType: "CREATE", 
                                                dataTableKey: authorityListAPI.key 
                                            },
                                        },
                                        parentToModelProps: {
                                            uiType: "CREATE",
                                            formKey: authorityListAPI.key + "_create",
                                        },
                                    },
                                    {
                                        actionType: actionBtnTypes.edit,
                                        actionBtn: {
                                            tooltip: "Edit",
                                            "permissions": permissions.AUTHORITY_EDIT.permissions
                                        },
                                        modelContentElement: AddAuthority,
                                        dialogPopupProps: {
                                            isFullScreen: true,
                                            isSaveButton: false,
                                            isCustomButton: true,
                                            dialogTitle: "Edit Authority",
                                            closeButton: {
                                                btnText: "Cancel",
                                                startIcon: "far fa-times-circle",
                                            },
                                            customButtonComponent: CustomButtons,
                                            isConfirmationPopup: true,
                                            customButtonComponentProps: { btnText: "Update", uiType: "EDIT", dataTableKey: authorityListAPI.key },
                                        },
                                        parentToModelProps: {
                                            uiType: "EDIT",
                                            formKey: authorityListAPI.key + "_edit",
                                        },
                                        checkBeforeActionRenderFn: (rawData) => {
                                          const status= _get(rawData, "row.authority_type.code", null);
                                          return status != 'WLA' ? true : false;
                                        }
                                    },
                                    {
                                        actionType: actionBtnTypes.view,
                                        actionBtn: {
                                            
                                            tooltip: "View",
                                            "permissions": permissions.AUTHORITY_VIEW.permissions
                                        },
                                        modelContentElement: ViewAuthority,
                                        dialogPopupProps: {
                                            isFullScreen: true,
                                            isSaveButton: false,
                                            isCloseButton: true,
                                            dialogTitle: "View Authority",
                                            closeButton: {
                                                btnText: "Close",
                                                startIcon: "far fa-times-circle",
                                            },
                                        }
                                    },
                                    {
                                        actionType: actionBtnTypes.view,
                                        actionBtn: {
                                            icon: "fa fa-barcode",
                                            tooltip: "Serial Numbers",
                                            "permissions": permissions.AUTHORITY_SET_SERIAL.permissions
                                        },
                                        modelContentElement: SerialNumbers,
                                        dialogPopupProps: {
                                            isFullScreen: true,
                                            isSaveButton: false,
                                            isCloseButton: true,
                                            isCustomButton: false,
                                            customButtonComponentProps: { 
                                                btnText: "Create",
                                                uiType: "add",
                                            },
                                            dialogTitle: "Serial Numbers",
                                        },
                                        parentToModelProps: {
                                            formKey: authoritySerialNumbersCreateApi.key + "_create",
                                            dataTableKey: authoritySerialNumbersCreateApi.key,
                                            _onLoad:false,
                                        },
                                        checkBeforeActionRenderFn: (rawData) => {
                                          const status= _get(rawData, "row.service.active", 0);
                                          return status == 1 ? true : false;
                                        }
                                    },
                                ]}
                            >
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardTemplate>
    );
};

export default Authority;