/*
 * @Author: Farah Jabir
 * @Date: 2020-10-19 10:18:15
 * @Last Modified by: Senura Weerasinghe
 * @Last Modified time: 2021-06-14 16:09:01
 */

import React from "react";
import { VrpCompanyForm } from "../VrpCompanyForm";
import { CustomButtons } from "../../ManageVrpCompany";
import { vrpCompanyListAPI } from "../../../../../../config/apiUrl.config";

const CompanyInfo = (props) => {
  return (
    <div className="row">
      <div className="col-12 defaultMarginTopBottom">
        <VrpCompanyForm fromParent={props.fromParent} row={props.row} />
        <div className="floatRight defaultPaddingTopBottom">
          <CustomButtons
            btnText={"Update"}
            uiType={"EDIT"}
            dataTableKey={vrpCompanyListAPI.key}
            formGroupName={`${vrpCompanyListAPI.key}_edit`}
          />
        </div>
      </div>

    </div>
  );
}

export { CompanyInfo };
