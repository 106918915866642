import React, { Fragment } from 'react';
import { permissions } from '../../../config/permission.config';
import authorized from '../../hoc-middlewares/Authorized';
import { Navigate } from '../../ui-components/ui-elements/common/Navigate';
import Authority from './pages/Authority';

const AuthorityRoutes = () => {
    return (
        <Fragment>
            <Navigate
                exact={true}
                path="/authority"
                routeKey="authority"
                component={authorized(Authority)}
                routePermissions={permissions.AUTHORITY_MANAGE.permissions} />
        </Fragment>
    );
};

export default AuthorityRoutes;