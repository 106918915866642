/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-03-19 10:43:26 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-07 10:38:04
 */
import React from "react";

import {TemplateOne} from "./TemplateOne";
import {TemplateTwo} from "./TemplateTwo";


const DashboardTemplate=({...props})=>{
    return (
        <TemplateTwo {...props} />
    )
};


export {
    DashboardTemplate
}
