import React, { Fragment } from "react";

import { _get, _orderBy } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { AuditDetail, UICard, ViewDetailBlock } from "../../../../ui-components/ui-elements/common/BaseElements";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { isEmptyValue, thousandSeparator } from "../../../../../helpers/common-helpers/common.helpers";
import { DataTable } from "../../../../ui-components/ui-elements/table/DataTable";

const ViewFineConfigurations = (props) => {
    const chargeTypes = {
        "PRC": "Percentage",
        "FLT": "Flat"
    };

    const getTableBodyListData = () => {
        const data = _orderBy(_get(props, `row.configuraton_charge`, []), ["value_from"], ["asc"]);
        data[data.length - 1] = {
            ...data[data.length - 1],
            "value_to": "Above"
        }

        return data;
    };

    return (
        <Fragment>
            <UICard>
                <ViewDetailBlock
                    label={"Fine Description"}
                    value={_get(props, `row.description`, "")}
                    labelCol={6}
                />

                <ViewDetailBlock
                    label={"Charge Type"}
                    value={chargeTypes[_get(props, `row.charge_type`, "")]}
                    labelCol={6}
                />

                <ViewDetailBlock
                    label={"Licence Type"}
                    value={_get(props, `row.licence_type.name`, "")}
                    labelCol={6}
                />

                <ViewDetailBlock
                    label={"Effective From"}
                    value={dateObjectToString(_get(props, `row.effective_from`, ""), "dd/mm/YY")}
                    labelCol={6}
                />
            </UICard>

            <div className={"defaultMarginTop"}>
                <DataTable
                    tableName={"Charges for Month Ranges"}
                    dataTableKey={"chargeRange"}
                    isSetAction={false}
                    isSetTableFooter={false}
                    tableHeaderList={[
                        { displayName: "From", key: "value_from", isSort: false },
                        { displayName: "To", key: "value_to", isSort: false, onPrintFn: (row) => isEmptyValue(row.value_to) ? "-" : row.value_to },
                        { displayName: "Charge (LKR / %)", key: "charge", isSort: false, onPrintFn: (row) => {
                            return _get(props, `row.charge_type`, "FLT") === "FLT" ? thousandSeparator(row.charge, true) : row.charge;
                        }},
                    ]}
                    tableBodyList={getTableBodyListData()}
                />
            </div>

            <div className={"fullWidthDiv"}>
                <AuditDetail
                    createdById={_get(props, `row.created_by`, "")}
                    updatedById={_get(props, `row.updated_by`, "")}
                    createdLocationId={_get(props, `row.created_location`, "")}
                    updatedLocationId={_get(props, `row.updated_location`, "")}
                    createdAt={dateObjectToString(_get(props, `row.created_at`, ""), "dd/mm/YY HH:MM")}
                    updatedAt={dateObjectToString(_get(props, `row.updated_at`, ""), "dd/mm/YY HH:MM")}
                    createdRoleId={_get(props, `row.created_role_id`, '')}
                    updatedRoleId={_get(props, `row.updated_role_id`, '')}
                    labelCol={6}
                />
            </div>
        </Fragment>
    )
}

export default ViewFineConfigurations;