import React, { Fragment, useContext, useState } from "react";

import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { templateTypes } from "../../../../../config/template.config";
import {
    FormWrapper,
    SelectBoxWithState,
    InputBoxWithState,
    DatePickerWithState,
    SubmitButton,
    NumberInputBoxWithState
} from "../../../../ui-components/ui-elements/form";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { _get, _find } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { MonthRange, WeightRange } from "./RangesForm";
import {
    rateListAPI,
    licenceTypeListApi,
    chargingCategoryListAPI,
    vehicleClassAPI,
    fuelTypeListApi
} from "../../../../../config/apiUrl.config";
import { CoreContext } from "../../../core/context-providers/CoreContext.provider";
import { getDataByFormObject, isEmptyValue } from "../../../../../helpers/common-helpers/common.helpers";
import { formErrorKey } from "../../../../../config/apiResponseKey";

const AddRate = () => {
    const formKey = `${rateListAPI.key}_create`;

    const [formState, formAction] = useContext(FormContext);
    const [coreState] = useContext(CoreContext);

    const [chargingCategoryList, setChargingCategoryList] = useState([]);
    const [licenceType, setLicenceType] = useState("none");

    const setRelevantCategories = (lcnId) => {
        const lcnTypesArray = _get(coreState, `apiResponses[${licenceTypeListApi.key}].result`, []);
        const lcnType = _find(lcnTypesArray, (e) => e.id === lcnId);
        const chargingCategoryList = _get(formState, `${formKey}.chargingCategoryList`, []);

        if (lcnType.code === "NO") {
            const GRW = _find(chargingCategoryList, (e) => e.code === "GRW");
            const TRW = _find(chargingCategoryList, (e) => e.code === "TRW");
            const ANN = _find(chargingCategoryList, (e) => e.code === "ANN");
            const PES = _find(chargingCategoryList, (e) => e.code === "PES");

            setChargingCategoryList([GRW, TRW, ANN, PES]);
            setLicenceType("Normal Licence");
        } else if (lcnType.code === "NU") {
            setChargingCategoryList([_find(chargingCategoryList, (e) => e.code === "MTR")]);
            setLicenceType("Non-User Licence");
        } else if (lcnType.code === "CA") {
            setChargingCategoryList([_find(chargingCategoryList, (e) => e.code === "MTH")]);
            setLicenceType("Carnet Licence");
        } else if (lcnType.code === "DE") {
            setChargingCategoryList([_find(chargingCategoryList, (e) => e.code === "ANN")]);
            setLicenceType("Dealer's Licence");
        } else {
            setChargingCategoryList(chargingCategoryList);
        }
    }

    const checkChargeFieldVisibility = () => {
        if (licenceType === "Normal Licence" && _get(formState, `${formKey}.charging_category_code`, "") === "ANN")
            return true;
        else if (licenceType === "Normal Licence" && _get(formState, `${formKey}.charging_category_code`, "") === "PES")
            return true;
        else if (licenceType === "Dealer's Licence" && _get(formState, `${formKey}.charging_category_code`, "") === "ANN")
            return true;
        else if (licenceType === "Carnet Licence" && _get(formState, `${formKey}.charging_category_code`, "") === "MTH")
            return true;
        else
            return false;
    }

    const setScenarioFn = (code) => {
        if (licenceType === "Normal Licence" && code === "ANN")
            formAction.changeInputFn(formKey, "scenario", "", "1");
        else if (licenceType === "Normal Licence" && (code === "GRW" || code === "TRW")) {
            formAction.changeInputFn(formKey, "", `${formKey}.weightRanges.0.from_value`, 1);
            formAction.changeInputFn(formKey, "scenario", "", "2");
        } else if (licenceType === "Normal Licence" && code === "PES")
            formAction.changeInputFn(formKey, "scenario", "", "3");
        else if (licenceType === "Non-User Licence" && code === "MTR") {
            formAction.changeInputFn(formKey, "", `${formKey}.monthRanges.0.from_value`, 1);
            formAction.changeInputFn(formKey, "scenario", "", "4");
        } else if (licenceType === "Dealer's Licence" && code === "ANN")
            formAction.changeInputFn(formKey, "scenario", "", "5");
        else if (licenceType === "Carnet Licence" && code === "MTH")
            formAction.changeInputFn(formKey, "scenario", "", "6");
    }

    const setMinDateFn = () => {
        let minDate = new Date();
        minDate.setHours(0);
        minDate.setMinutes(0);
        minDate.setSeconds(0);
        minDate = new Date(minDate.setDate(minDate.getDate() + 1));

        return minDate;
    }

    return (
        <Fragment>
            <FormWrapper
                setGroupName={formKey}
                apiUrl={`${chargingCategoryListAPI.url}/list`}
                onRebuildResponseFn={(response) => {
                    setChargingCategoryList(response);

                    return {
                        chargingCategoryList: response,
                        effective_from: setMinDateFn(),
                        scenario: "",
                        "_onLoad": false
                    }
                }}
            >
                <UICard>
                    <div className="col-md-6 col-sm-12 floatLeft">
                        <InputBoxWithState
                            uiType={templateTypes.bootstrap}
                            formGroupName={formKey}
                            inputName={"description"}
                            labelText="Rate Description"
                            isRequired={true}
                            inputPlaceholder={"Enter Rate Description"}
                            maxLength={100}
                        />
                    </div>

                    <div className="col-md-6 col-sm-12 floatLeft">
                        <SelectBoxWithState
                            uiType={templateTypes.bootstrap}
                            formGroupName={formKey}
                            inputName={"licence_type_id"}
                            labelText="Licence Type"
                            emptySelectOptionTxt={"Select Licence Type"}
                            isRequired={true}
                            apiUrl={`${licenceTypeListApi.url}/list?codes=NO&codes=NU&codes=CA&codes=DE`}
                            apiStateKey={licenceTypeListApi.key}
                            valueName={"name"}
                            onChangeFn={(eventData) => {
                                formAction.changeInputFn(formKey, "charging_category_code", "", "");
                                formAction.changeInputFn(formKey, "monthRanges", "", {});
                                formAction.changeInputFn(formKey, "weightRanges", "", {});
                                setLicenceType("none");
                                formAction.changeInputFn(formKey, "scenario", "", "");
                                setRelevantCategories(eventData.value);
                                formAction.changeInputFn(formKey, "charge", "", "");
                            }}
                        />
                    </div>

                    <div className="col-md-6 col-sm-12 floatLeft">
                        <SelectBoxWithState
                            uiType={templateTypes.bootstrap}
                            formGroupName={formKey}
                            dataList={chargingCategoryList}
                            inputName={"charging_category_code"}
                            labelText="Charging Category"
                            emptySelectOptionTxt={"Select Charging Category"}
                            isRequired={true}
                            keyName={"code"}
                            valueName={"name"}
                            onChangeFn={(eventData) => setScenarioFn(eventData.value)}
                        />
                    </div>

                    {
                        (_get(formState, `${formKey}.scenario`, "") === "1" || _get(formState, `${formKey}.scenario`, "") === "2" || _get(formState, `${formKey}.scenario`, "") === "3" || _get(formState, `${formKey}.scenario`, "") === "4") ? (
                            <div className="col-md-6 col-sm-12 floatLeft">
                                <SelectBoxWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName={formKey}
                                    apiUrl={`${vehicleClassAPI.url}/list?sort_by=name|asc&active=true`}
                                    apiStateKey={vehicleClassAPI.key}
                                    inputName={"vehicle_class_id"}
                                    labelText="Vehicle Class"
                                    emptySelectOptionTxt={"Select Vehicle Class"}
                                    isRequired={true}
                                    valueName={"name"}
                                />
                            </div>
                        ) : null
                    }

                    {
                        (_get(formState, `${formKey}.scenario`, "") === "1" || _get(formState, `${formKey}.scenario`, "") === "2") ? (
                            <div className="col-md-6 col-sm-12 floatLeft">
                                <SelectBoxWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName={formKey}
                                    inputName={"fuel_type_id"}
                                    labelText="Fuel Type"
                                    emptySelectOptionTxt={"Select Fuel Type"}
                                    isRequired={true}
                                    apiUrl={`${fuelTypeListApi.url}/list?sort_by=name|asc&active=true`}
                                    apiStateKey={fuelTypeListApi.key}
                                    valueName={"name"}
                                />
                            </div>
                        ) : null
                    }

                    {
                        (_get(formState, `${formKey}.scenario`, "") !== "") ? (
                            <div className="col-md-6 col-sm-12 floatLeft">
                                <DatePickerWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName={formKey}
                                    labelText="Effective From"
                                    inputName={"effective_from"}
                                    isRequired={true}
                                    minDate={setMinDateFn()}
                                />
                            </div>
                        ) : null
                    }
                </UICard>

                {
                    (checkChargeFieldVisibility() === true) ? (
                        <UICard>
                            <div className="col-md-6 col-sm-12 floatLeft">
                                <NumberInputBoxWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName={formKey}
                                    inputName={"charge"}
                                    labelText="Charge (LKR)"
                                    isRequired={true}
                                    inputPlaceholder={'Charge(LKR)'}
                                    isAllowNegative={false}
                                    decimalScale={0}
                                    isAllowedFn={(value) => {
                                        const checkValue = value.value.toString();
                                        return checkValue.length <= 6;
                                    }}
                                />
                            </div>
                        </UICard>
                    ) : null
                }

                {
                    (_get(formState, `${formKey}.scenario`, "") === "4") ? (
                        <UICard cardHeading="Charges for Month Ranges">
                            <MonthRange formKey={formKey} />
                        </UICard>
                    ) : null
                }

                {
                    (_get(formState, `${formKey}.scenario`, "") === "2") ? (
                        <UICard cardHeading="Charges for Weight Ranges">
                            <WeightRange formKey={formKey} />
                        </UICard>
                    ) : null
                }
            </FormWrapper>
        </Fragment>
    );
};

const RateCreateBtn = (props) => {
    const [formState, formAction] = useContext(FormContext);

    const licenceType = {
        "1": "NO",
        "2": "NO",
        "3": "NO",
        "4": "NU",
        "5": "DE",
        "6": "CA"
    };

    return (
        <SubmitButton
            btnText="Save"
            elementStyle="btnWrapper"
            startIcon={"far fa-save"}
            formGroupName={`${rateListAPI.key}_create`}
            dataTableKey={rateListAPI.key}
            isValidate={true}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Rate is successfully saved.",
                    messageType: "success"
                }
            }}
            validationObject={{
                fileds: {
                    description: "Rate Description",
                    licence_type_id: "Licence Type",
                    charging_category_code: "Charging Category"
                },
                rules: {
                    description: "required|max:100",
                    licence_type_id: "required",
                    charging_category_code: "required"
                },
                message: {
                    "description.required": "Rate Description is required",
                    "licence_type_id.required": "Licence Type is required",
                    "charging_category_code.required": "Charging Category is required",
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "post",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: "DATA_TABLE_LINKED_FORM",
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: rateListAPI.url,
                    key: rateListAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                let data = getDataByFormObject(formObject);
                const lcn_rate_ranges = [];

                if (!isEmptyValue(data.scenario) && data.scenario === "4") {
                    Object.keys(data.monthRanges).forEach((value) => {
                        lcn_rate_ranges.push({
                            from_value: parseInt(data.monthRanges[value].from_value),
                            to_value: parseInt(data.monthRanges[value].to_value),
                            charge: parseFloat(data.monthRanges[value].charge)
                        });
                    });
                } else if (!isEmptyValue(data.scenario) && data.scenario === "2") {
                    Object.keys(data.weightRanges).forEach((value) => {
                        lcn_rate_ranges.push({
                            from_value: parseInt(data.weightRanges[value].from_value),
                            to_value: parseInt(data.weightRanges[value].to_value),
                            charge: parseFloat(data.weightRanges[value].charge)
                        });
                    });
                } else {
                    lcn_rate_ranges.push({
                        from_value: null,
                        to_value: null,
                        charge: parseFloat(_get(data, "charge", undefined))
                    });
                }

                return {
                    description: data.description,
                    licence_type_id: data.licence_type_id,
                    licence_type: {
                        id: data.licence_type_id,
                        code: licenceType[data.scenario]
                    },
                    charging_category_code: data.charging_category_code,
                    vehicle_class_id: data.vehicle_class_id,
                    fuel_type_id: data.fuel_type_id,
                    effective_from: data.effective_from,
                    lcn_rate_ranges: lcn_rate_ranges
                };
            }}
            onResponseCallBackFn={(error) => {
                if (!error) {
                    props.onClosePopupFn(false);
                } else {
                    if (_get(error, 'data.errors.name', null) === formErrorKey) {
                        let rangeType = "monthRanges";

                        if (_get(formState, `${rateListAPI.key}_create.scenario`, "") === "2") {
                            rangeType = "weightRanges";
                        }

                        const errors = [];

                        _get(error, "data.errors.details", []).forEach((value) => {
                            const check = value.property.split(".");
                            let tempErrorMsj = value.message;

                            if (check[0] === "lcn_rate_ranges") {
                                if (tempErrorMsj === "charge must be a number conforming to the specified constraints") {
                                    tempErrorMsj = "Charge is required";
                                }

                                if (tempErrorMsj === "to_value must be an integer number") {
                                    tempErrorMsj = "To value is required";
                                }

                                if (["2", "4"].includes(_get(formState, `${rateListAPI.key}_create.scenario`, ""))) {
                                    errors.push({
                                        property: `${rangeType}.${check[1]}.${check[2]}`,
                                        message: tempErrorMsj
                                    });
                                } else {
                                    errors.push({
                                        property: "charge",
                                        message: tempErrorMsj
                                    });
                                }
                            } else {
                                if (value.property === "vehicle_class_id" && tempErrorMsj === "vehicle_class_id should not be empty") {
                                    tempErrorMsj = "Vehicle Class is required";
                                }

                                if (value.property === "fuel_type_id" && tempErrorMsj === "fuel_type_id should not be empty") {
                                    tempErrorMsj = "Fuel Type is required";
                                }

                                errors.push({
                                    property: value.property,
                                    message: tempErrorMsj
                                })
                            }
                        });

                        formAction.setFormErrorFn(`${rateListAPI.key}_create`, errors);
                    }
                }
            }}
        />
    )
}

export {
    AddRate,
    RateCreateBtn
};