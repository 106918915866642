/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2021-02-16 13:38:51 
 * @Last Modified by: Senura Weerasinghe
 * @Last Modified time: 2021-04-04 22:28:36
 * @Last Modified time: 2021-04-15 14:21:03
 */

import React, { Fragment } from "react";
import {DashboardTemplate} from "../../../../ui-components/templates/DashboardTemplate";
import {DataTable,actionBtnTypes} from "../../../../ui-components/ui-elements/table/DataTable";
import {FormWrapper,InputBoxWithState,SelectBoxWithState,SubmitButton} from "../../../../ui-components/ui-elements/form/index";
import {fuelTypeListApi} from "../../../../../config/apiUrl.config";
import {apiResponseStoringType} from "../../../../../config/apiResponseKey";

import {_get} from "../../../../../helpers/common-helpers/lodash.wrappers";
import {getDataByFormObject} from "../../../../../helpers/common-helpers/common.helpers";
import { ViewFuelType } from "../includes/View-fuel-type";
import { FuelTypeForm } from "../includes/Fuel-type-form";


const SubmitBtn=(props)=>{
    return <SubmitButton
                btnText={props.uiType==="EDIT"?"Edit":"Create"}
                formGroupName={props.formGroupName}
                tooltip={props.uiType==="EDIT"?"Edit":"Create New"}
                dataTableKey={props.dataTableKey}
                isValidate={true}
                validationObject={{
                    fileds:{
                      "prefix":"Prefix",  
                      "name":"Name",
                      "vet_validation":"VET Validation",
                      "dmt_mapping_parameter":"DMT Mapping"
                    },
                    rules:{
                        "prefix":"required|max:5",   
                        "name":"required",   
                        "vet_validation":"required",
                        "dmt_mapping_parameter":"required",
                    },                   
                }}
                onGetAPIEndPointFn={(formState) => {
                    return {
                        url: props.uiType==="EDIT"?`${fuelTypeListApi.url}/${formState.id}`:fuelTypeListApi.url,
                        key: fuelTypeListApi.key
                    }
                }}
                onChangeRequestBodyFn={(fromObject) => {
                    fromObject.id=undefined;
                    return getDataByFormObject({
                        ...fromObject,
                        status: (parseInt(fromObject.status) === 1 ? (true) : (false)),
                    })
                }}
                callApiObject={{
                    isSetHeaders: true,
                    method:props.uiType==="EDIT"?"put":"post",
                }}
                apiDataStoringObject={{
                    setLoader: true,
                    storingType: apiResponseStoringType.dateTableLinkedForm
                }}
                onResponseCallBackFn={(response, error) => {
                    if (response.length === undefined && error === undefined) {
                        props.onClosePopupFn();
                    }
                }}
        />
}


const ManageFuelType=(props)=>{

    const formKey=props.fromParent.formKey;

    return (
        <FormWrapper
            setGroupName={`${formKey}`}
            formGroupLinkWith={formKey}    
            setFormObject={{
                id: _get(props, "row.id", undefined),
                prefix: _get(props, "row.prefix", ""),
                name: _get(props, "row.name", ""),
                active: _get(props, "row.active", ""),
                vet_validation: _get(props, "row.vet_validation", ""),
                dmt_mapping_parameter: _get(props, "row.dmt_mapping_parameter", ""),
            }}
            onDestroyUnsetFormObject={true}
        >

            <InputBoxWithState
                formGroupName={formKey}
                inputName={"prefix"}
                labelText="Fuel Type Prefix"
                isRequired={true}
            />

            <InputBoxWithState
                formGroupName={formKey}
                inputName={"name"}
                labelText="name"
                isRequired={true}
            />      

            <InputBoxWithState
                formGroupName={formKey}
                inputName={"vet_validation"}
                labelText="vet_validation"
                isRequired={true}
            />     

            <InputBoxWithState
                formGroupName={formKey}
                inputName={"dmt_mapping_parameter"}
                labelText="dmt_mapping_parameter"
                isRequired={true}
            />   

            <SelectBoxWithState
                formGroupName={formKey}
                dataList={[
                {
                    id: "1",
                    value: "Active",
                },
                {
                    id: "2",
                    value: "Inactive",
                },
                ]}
                inputValue='1'
                inputName={"active"}
                labelText="Status"
            />  

        </FormWrapper>
    )
}


 const ManageFuelTypeTestPage=()=>{
    return (
        <DashboardTemplate
            pageTitle={"Manage Fuel Type Test Page"}
            breadCrumbList={[
                { urlTo: "/", displayName: "Home" },
                { urlTo: "", displayName: "Manage Fuel Type Test" },
            ]}
        >
            <DataTable
                tableName="Manage Fuel Type (Test)"
                apiUrl={fuelTypeListApi.url}
                dataTableKey={fuelTypeListApi.key}
                tableHeaderList={[
                    { 
                        displayName: "Fuel Type Prefix", 
                        key: "prefix", 
                        isSort: false 
                    },
                    { 
                        displayName: "Fuel Type Name", 
                        key: "name", 
                        isSort: false 
                    },
                    { 
                        displayName: "Status", 
                        key: "active", 
                        isSort: false ,
                        onPrintFn:({active}) => active === 1 ? "Active":"Inactive"
                    }
                ]}
                actionTypeList={[
                    {
                        actionType: actionBtnTypes.view,
                        actionBtn: { tooltip: "View" },
                        modelContentElement: ViewFuelType,
                        dialogPopupProps: {
                            isFullScreen: false,
                            dialogTitle: "View Licence Types",
                        }
                    },
                    {
                        actionType:actionBtnTypes.create,
                        modelContentElement:FuelTypeForm,
                        parentToModelProps:{
                            "uiType":"CREATE",
                            formKey: fuelTypeListApi.key + "_create",
                        },
                        dialogPopupProps:{
                            isFullScreen:false,
                            dialogTitle:"Add Fuel Type",
                            isCustomButton:true,
                            customButtonComponent:SubmitBtn,
                            customButtonComponentProps:{
                                "uiType":"CREATE",
                                dataTableKey: fuelTypeListApi.key,
                            }
                        }
                    },
                    {
                        actionType:actionBtnTypes.edit,
                        modelContentElement:FuelTypeForm,
                        parentToModelProps:{
                            "uiType":"EDIT",
                            formKey: fuelTypeListApi.key + "_edit",
                        },
                        dialogPopupProps:{
                            isFullScreen:false,
                            dialogTitle:"Edit Fuel Type",
                            isCustomButton:true,
                            customButtonComponent:SubmitBtn,
                            customButtonComponentProps:{
                                "uiType":"EDIT",
                                dataTableKey: fuelTypeListApi.key,
                            }
                        }
                    }
                ]}
                
            />
        </DashboardTemplate>
    )
 }

 export {
    ManageFuelTypeTestPage
 }