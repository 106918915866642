import React, { Fragment } from "react";
import { permissions } from "../../../config/permission.config";
import authorized from "../../hoc-middlewares/Authorized";
import { Navigate } from "../../ui-components/ui-elements/common/Navigate";
import ManageLicenceType from "./pages/ManageLicenceTypes";

const LicenceTypesRoutes = () => {
    return (
        <Fragment>
         <Navigate exact={true} path="/licence-types" routeKey={"licenceTypes"} component={authorized(ManageLicenceType)} routePermissions={permissions.LICENSE_TYPE_MANAGE.permissions}/>
        </Fragment>
    );
};

export default LicenceTypesRoutes;
