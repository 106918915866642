/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-04 11:15:40
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2021-11-22 08:30:05
 */
import React, { Fragment } from "react";
import {
  FormWrapper,
  SelectBoxWithState,
  InputBoxWithState
} from "../../../../ui-components/ui-elements/form";
import { templateTypes } from "../../../../../config/template.config";
import { userRolesListAPI } from "../../../../../config/apiUrl.config";

const SearchElement = ({ dataTableKey = "" }) => {
  const formKey = `${dataTableKey}_search`;
  return (
    <FormWrapper setGroupName={formKey}>
      <div className="row">
        <div className="col-md-3 col-sm-12 floatLeft">
          <InputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            inputName={"full_name"}
            labelText="Full Name"
            inputPlaceholder={"Full Name"}
            maxLength={50}
          />
        </div>
        <div className="col-md-3 col-sm-12 floatLeft">
          <InputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            inputName={"display_name"}
            labelText="Display Name"
            inputPlaceholder={"Display Name"}
            maxLength={50}
          />
        </div>
        <div className="col-md-3 col-sm-12 floatLeft">
          <InputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            inputName={"user_name"}
            labelText="Username"
            inputPlaceholder={"Username"}
            maxLength={50}
          />
        </div>
        <div className="col-md-3 col-sm-12 floatLeft">
          <SelectBoxWithState
            formGroupName={formKey}
            emptySelectOptionTxt={"All"}
            isEnableAllOption={true}
            apiUrl={`${userRolesListAPI.url}/list`}
            apiStateKey={`${userRolesListAPI.key}_INT`}
            keyName={"id"}
            valueName={"name"}
            inputName={"role_id"}
            labelText="User Role"
          />
        </div>
        <div className="col-md-3 col-sm-12 floatLeft">
          <SelectBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            isEnableAllOption={false}
            dataList={[
              {
                id: null,
                value: "All",
              },
              {
                id: true,
                value: "Active",
              },
              {
                id: false,
                value: "Inactive",
              },
            ]}
            inputName={"active"}
            labelText="Status"
          />
        </div>
      </div>
    </FormWrapper>
  );
};
export { SearchElement }
