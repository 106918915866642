/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-03-21 09:57:06 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-16 13:26:52
 */

import React,{createContext,useState} from "react";
import {_findindex} from "../../../helpers/common-helpers/lodash.wrappers";


const UIMenuContext=createContext({});


const UIMenuContextProvider=({children})=>{
   
    const [sidenavConfig, setsidenavConfig] = useState([
        { id: 1, open: false },
        { id: 2, open: false },
        { id: 3, open: false },
        { id: 4, open: false },
        { id: 5, open: false },
        { id: 6, open: false },
        { id: 7, open: false },
        { id: 8, open: false },
        { id: 9, open: false },
        
        
      ]);
      const setSidenavConfigurations = (sidenavConfig) => {
        setsidenavConfig(sidenavConfig);
      };
    const type=""
    return(
        <UIMenuContext.Provider value={[sidenavConfig,setSidenavConfigurations,type]}>
            {children}
        </UIMenuContext.Provider>
    )
}

export {
    UIMenuContext,
    UIMenuContextProvider
}