/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-03-21 16:17:02
 * @Last Modified by: Senura Weerasinghe
 * @Last Modified time: 2021-06-22 16:43:34
 */

import axios from 'axios';
import { checkUserINLocalStorage, logoutUser, setToLocalStorage } from './manageStorage.helpers';
import { _get } from './lodash.wrappers';
import { refreshTokenAPI } from "../../config/apiUrl.config";
import { authTokenStorageKey } from '../../config/core.config';


/**
 * @description: request data from api
 * @param {String} apiParth api parth
 * @param {String} methodType method type
 * @param {Object} headerObject header request
 * @param {Object|Array} requestBody request body
 * @param {Function} call-back function
 */



const axiosWrapper = (
  apiParth,
  methodType = 'get',
  headerObject,
  requestBody = {},
  onUpload,
  cb
) => {
  let apiCallObject = {
    ...headerObject,
    method: methodType,
    url: apiParth,
    data: requestBody,
  };

  if (onUpload !== false) {
    apiCallObject['onUploadProgress'] = (progressEvent) =>
      onUpload(progressEvent);
  }
  axios(apiCallObject)
    .then((response) => {
      cb(null, {
        _statue: true,
        data: response.data,
      });
    })
    .catch((error) => {
      if (_get(error, 'response.status', "") === 401) {
        logoutUser();
        window.location.reload();
      } else {
        cb(
          {
            _statue: false,
            data: _get(error, 'response.data', {}),
          },
          null
        );
      }
    });
};

const updateTokens = async () => {
  let currentTime = new Date().getTime() / 1000;
  let userData = checkUserINLocalStorage();
  let tokenExpiresAt = _get(userData, "result.expiresAt", "");

  if (!tokenExpiresAt || (currentTime > tokenExpiresAt)) {
    await axios({
      method: 'post',
      url: refreshTokenAPI.url,
      data: {
        refresh_token: _get(userData, "result.refreshToken", "")
      }
    }).then((result) => {
      if (result) {
        let expiresAt = (currentTime + result.data.expires_in) - 30; // set expiresAt with 30 seconds buffer
        userData.result.accessToken = result.data.access_token;
        userData.result.refreshToken = result.data.refresh_token;
        userData.result.expiresAt = expiresAt;
        setToLocalStorage(authTokenStorageKey, userData.result);
      } else {
        logoutUser();
        window.location.reload();
      }
    }).catch((error) => {
      logoutUser();
      window.location.reload();
    });
  }
}

const createHeader = async (isSetHeaders = true, multipart = false) => {
  await updateTokens();
  let headers = {
    accept: 'application/json',
    'Content-Type': multipart ? 'multipart/form-data' : 'application/json',
  };
  if (isSetHeaders === true) {
    let userData = checkUserINLocalStorage();
    headers['Authorization'] =
      'Bearer ' + _get(userData, 'result.accessToken', null);
    headers['current-delegation'] = _get(userData, 'result.current_delegation', null);
  }
  return {
    headers: headers,
  };
};

/**
 * @Author: Nisal Madusanka(EruliaF)
 * @description: request data from api
 * @param {String} apiUrl api url
 */
const callApi = (apiUrl = null) => {
  var attributes = {
    apiUrl: apiUrl,
    isSetHeaders: true,
    multipart: false,
    method: 'get',
    body: {},
    onUpload: false,
  };
  return {
    /**
     * @description: set headers
     * @param {Boolean} status set headers
     */

    headers: function (status = false) {
      attributes = {
        ...attributes,
        isSetHeaders: status,
      };
      return this;
    },
    /**
     * @description: set multipart
     * @param {*} status
     */
    isMultipart: function (status = true) {
      attributes = {
        ...attributes,
        multipart: status,
      };
      return this;
    },
    /**
     * @description: set api method type
     * @param {String} method set api method type
     */

    method: function (method = 'post') {
      attributes = {
        ...attributes,
        method: method,
      };
      return this;
    },
    /**
     * @description: set api body
     * @param {Object|Array} status set api body
     */

    body: function (body = {}) {
      attributes = {
        ...attributes,
        body: body,
      };
      return this;
    },
    /**
     * @description: tracking uploading process
     * @param {Funtion} onUploadFn
     */
    onUpload: function (onUploadFn) {
      attributes = {
        ...attributes,
        onUpload: onUploadFn,
      };
      return this;
    },

    /**
     * @description: full  object
     * @param {Funtion} onUploadFn
     */
    setFullObject: function (object) {
      attributes = {
        ...attributes,
        ...object,
      };
      return this;
    },

    /**
     * @description: send request to end-point
     * @param {Function} cb callback function
     */
    send: async function (cb) {
      const headerRequest = await createHeader(
        attributes.isSetHeaders,
        attributes.multipart
      );

      return axiosWrapper(
        attributes.apiUrl,
        attributes.method,
        headerRequest,
        attributes.body,
        attributes.onUpload,
        cb
      );
    },
  };
};

export { callApi, axiosWrapper };
