/*
 * @Author: Chanaka Wickramasinghe
 * @Date: 2020-02-10 14:01:54
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-31 22:18:54
 */

const baseUrl = process.env.REACT_APP_BASE_URL;
const mockApiBaseURL = "http://localhost:8086/";
const adminBffBaseURL = process.env.REACT_APP_ERL_ADMIN_BFF_BASE_URL;
// const adminBffBaseURL = "http://localhost:3004/";
const adminSockertBaseURL = process.env.REACT_APP_ERL_ADMIN_SOCKET_BASE_URL;

const authServiceClient = process.env.REACT_APP_AUTH_SERVICE_CLIENT;
const authServiceClientSecret = process.env.REACT_APP_AUTH_SERVICE_CLIENT_SECRET;

const authTokenStorageKey = "@webAuthKey";

const ReactAppVerNO = "V0.10.60";

const uploadComponentConfig = {
  txt: "file-txt.png",
  pdf: "file-pdf.png",
  docx: "file-word.png",
  xlsx: "file-excel.png",
};

const validImageTypes = {
  png: "image/png",
  jpg: "image/jpg",
  jpeg: "image/jpeg",
};

const validFileTypes = {
  txt: "text/plain",
  pdf: "application/pdf",
  docx:
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

export {
  mockApiBaseURL,
  authTokenStorageKey,
  adminSockertBaseURL,
  baseUrl,
  adminBffBaseURL,
  uploadComponentConfig,
  validImageTypes,
  validFileTypes,
  authServiceClient,
  authServiceClientSecret,
  ReactAppVerNO
};
