import React, { Fragment, useContext } from "react";
import { UICard, AuditDetail, ViewDetailBlock } from "../../../../ui-components/ui-elements/common/BaseElements";
import { templateTypes } from "../../../../../config/template.config";
import {
  FormWrapper,
  SelectBoxWithState,
  InputBoxWithState,
  DatePickerWithState

} from "../../../../ui-components/ui-elements/form";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { DataTable } from "../../../../ui-components/ui-elements/table/DataTable";
import { CheckBox } from "../../../../ui-components/ui-elements/form";

const SearchElement = ({ dataTableKey = "", props }) => {
  const [formState, formAction] = useContext(FormContext);
  const formKey = `${dataTableKey}_search`;
  const today = new Date();
  return (
    <FormWrapper setGroupName={formKey}>

      <div className="row">
        <div className={"col-md-4"}>
          <InputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            isRequired={false}
            inputPlaceholder="News Code"
            inputName={"code_search"}
            labelText="Code"
            maxLength="50"

          />
        </div>
        <div className={"col-md-4"}>
          <InputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            isRequired={false}
            inputPlaceholder="Heading"
            inputName={"heading_search"}
            labelText="Heading"
            maxLength="50"
          />
        </div>
        <div className={"col-md-4"}>
          <SelectBoxWithState

            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            isRequired={false}
            isEnableAllOption={true}
            dataList={[
              {
                id: "1",
                value: "Active",
              },
              {
                id: "0",
                value: "Inactive",
              },
            ]}
            inputName={"status_search"}
            labelText="Status"
          />
        </div>
        <div className={"col-md-4"}>
          <DatePickerWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            labelText="Start Date"
            inputName={"start_date_search"}
            minDateMessage={"‘Start Date’ should be less than or equal to ‘End Date’."}
          />
        </div>
        <div className={"col-md-4"}>
          <DatePickerWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            isRequired={false}
            //value={dateObjectToString(_get(props, `row.end_date`, ''), "YY-mm-dd")}
            inputName={"end_date_search"}
            labelText="End date"
            minDate={_get(formState, `${formKey}.start_date_search`, undefined)}
            minDateMessage={"‘End Date’ should be greater than or equal to the ‘Start Date’."}
          />
        </div>
      </div>
    </FormWrapper>
  );
};

const ViewWebNews = (props) => {
  const labelCol = 3;
  const cellCol = (12 - labelCol) / 3;

  ; return (

    <Fragment>
      <UICard elementStyle="defaultPaddingLeft"  >
        <ViewDetailBlock
          labelCol={labelCol}
          label={"News Code"}
          value={_get(props, `row.code`, '')}
        />

        <br />

        <div className="row defaultPaddingTopBottom">
          <div className={`col-${labelCol}`}>{/* Empty cell */}</div>
          <div className={`col-${cellCol}`}>
            <strong>English</strong>
          </div>
          <div className={`col-${cellCol}`}>
            <strong>Sinhala</strong>
          </div>
          <div className={`col-${cellCol}`}>
            <strong>Tamil</strong>
          </div>
          <div className="col-12">
            <ViewDetailBlock
              labelCol={labelCol}
              label={"News Heading"}
              value={
                <div className="row">
                  <div className="col-4">{_get(props, `row.heading_english`, '')}

                  </div>
                  <div className="col-4">{_get(props, `row.heading_sinhala`, '')}</div>
                  <div className="col-4">{_get(props, `row.heading_tamil`, '')}</div>
                </div>
              }
            />
            <ViewDetailBlock
              labelCol={labelCol}
              label={"News Content"}
              value={
                <div className="row">
                  <div className="col-4">
                    {_get(props, `row.content_english`, '')}
                  </div>
                  <div className="col-4">{_get(props, `row.content_sinhala`, '')}</div>
                  <div className="col-4"> {_get(props, `row.content_tamil`, '')}</div>
                </div>
              }
            />
          </div>
        </div>
        <br />
        <div className="row defaultPaddingTopBottom">
          <div className="col-12">
            <ViewDetailBlock
              labelCol={labelCol}
              label={"Status"}
              value={_get(props, `row.status`, '') == 1 ? "Active" : "Inactive"}
            />

            <ViewDetailBlock
              labelCol={labelCol}
              label={"Start Date"}
              value={dateObjectToString(_get(props, `row.start_date`, ''), "dd/mm/YY")}
            />
            <ViewDetailBlock
              labelCol={labelCol}
              label={"End Date"}
              value={dateObjectToString(_get(props, `row.end_date`, ''), "dd/mm/YY")}
            />
          </div>
        </div>
      </UICard>
      <AuditDetail

        createdById={_get(props, `row.created_by`, '')}
        updatedById={_get(props, `row.updated_by`, '')}
        createdLocationId={_get(props, `row.created_location`, '')}
        updatedLocationId={_get(props, `row.updated_location`, '')}
        createdAt={dateObjectToString(_get(props, `row.created_at`, ''), "dd/mm/YY HH:MM")}
        updatedAt={dateObjectToString(_get(props, `row.updated_at`, ''), "dd/mm/YY HH:MM")}
        createdRoleId={_get(props, `row.created_role_id`, '')}
        updatedRoleId={_get(props, `row.updated_role_id`, '')}
        labelCol={labelCol}
      />
    </Fragment >
  );
}
export { SearchElement, ViewWebNews };