import React from "react";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { FormWrapper, InputBoxWithState, SelectBoxWithState } from "../../../../ui-components/ui-elements/form";

const EditLicenceTypes = (props) => {
    
    const formKey = `${props.fromParent.formKey}`;
    
    return (
        <FormWrapper
            setGroupName={`${props.fromParent.formKey}`}
            formGroupLinkWith={props.fromParent.formKey}
            setFormObject={{
                id: _get(props, "row.id", ""),
                prefix: _get(props, "row.prefix", ""),
                name: _get(props, "row.name", ""),
                active: _get(props, "row.active", ""),
                insurance_applicability: _get(props, "row.insurance_applicability", ""),
                vet_applicability: _get(props, "row.vet_applicability", ""),
                vft_applicability: _get(props, "row.vft_applicability", ""),
                vrp_applicability: _get(props, "row.vrp_applicability", "")
              }}
              onDestroyUnsetFormObject={true}
        >
            <InputBoxWithState
                formGroupName={formKey}
                labelText={"Licence Type Prefix"}
                isRequired={true}
                inputName={"prefix"}
                maxLength = "2"
            />

            <InputBoxWithState
                formGroupName={formKey}
                labelText={"Licence Type Name"}
                isRequired={true}
                inputName={"name"}
                maxLength = "50"
            />


            <SelectBoxWithState
                formGroupName={formKey}
                labelText={"Status"}
                inputName={"active"}
                keyName={"id"}
                valueName={"value"}
                dataList={[
                    {
                      id: "1",
                      value: "Active",
                    },
                    {
                      id: "0",
                      value: "Inactive",
                    },
                  ]}
            />

            <UICard
                cardHeading={"Document Validation"}
                elementStyle="defaultMarginTop"
            >
                <SelectBoxWithState
                    formGroupName={formKey}
                    emptySelectOptionTxt = ""
                    dataList={[
                        {
                          id: "MAN",
                          value: "Mandatory",
                        },
                        {
                          id: "OPT",
                          value: "Optional",
                        },
                        {
                          id: "NOT",
                          value: "Not Required",
                        },
                      ]}
                      inputName={"insurance_applicability"}
                      labelText="Insurance"
                />
                <SelectBoxWithState
                    formGroupName={formKey}
                    emptySelectOptionTxt = ""
                    dataList={[
                        {
                          id: "MAN",
                          value: "Mandatory",
                        },
                        {
                          id: "OPT",
                          value: "Optional",
                        },
                        {
                          id: "NOT",
                          value: "Not Required",
                        },
                      ]}
                      labelText={"VET"}
                      inputName={"vet_applicability"}
                />
                <SelectBoxWithState
                    formGroupName={formKey}
                    emptySelectOptionTxt = ""
                    dataList={[
                        {
                          id: "MAN",
                          value: "Mandatory",
                        },
                        {
                          id: "OPT",
                          value: "Optional",
                        },
                        {
                          id: "NOT",
                          value: "Not Required",
                        },
                      ]}
                      labelText={"VFT"}
                      inputName={"vft_applicability"}
                />
                <SelectBoxWithState
                    formGroupName={formKey}
                    emptySelectOptionTxt = ""
                    dataList={[
                        {
                          id: "MAN",
                          value: "Mandatory",
                        },
                        {
                          id: "OPT",
                          value: "Optional",
                        },
                        {
                          id: "NOT",
                          value: "Not Required",
                        },
                      ]}
                      labelText={"VRP"}
                      inputName={"vrp_applicability"}
                />
            </UICard>
        </FormWrapper>
    );
};

export {
    EditLicenceTypes
};