/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Description: Core Material Form Components Wrappers
 * @Date: 2020-01-29 10:22:54
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-08 15:32:14
 */

import React from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormGroup from '@material-ui/core/FormGroup';
import Tooltip from '@material-ui/core/Tooltip';
import FormHelperText from "@material-ui/core/FormHelperText";
import {  makeStyles } from '@material-ui/core/styles';

import {templateTypes, defaultTemplateType} from "../../../../../config/template.config";


const useStylesBootstrap = makeStyles({
  arrow: {
    color:  props => props?"#fdd9d7":"",
  },
  tooltip: {    
    color:  props => props?"#994542":"",
    backgroundColor: props => props?"#fdd9d7":"",
    borderColor:props => props?"#fccac7":"",
  },
});

function HtmlTooltip(props) {
  const classes = useStylesBootstrap(props.isError);

  return <Tooltip arrow classes={classes} {...props} />;
}

/**
 * --------------------------------------------
 * @Author: Nisal Madusanka(EruliaF)
 * @Description: Form Control Wrapper
 * --------------------------------------------
 */
const FormControlWrapper = ({
  elementWrapperStyle = "",
  isFullWidth = true,
  inputError = "",
  helperText="",
  isMultiline = false,
  children = null
}) => {
  return (
    <HtmlTooltip title={inputError?inputError:helperText} arrow={true} isError={inputError ? true : false} >
      <FormControl
        fullWidth={isFullWidth}
        error={inputError ? true : false}
        className={`
        defaultInputWrapper 
        ${elementWrapperStyle} ${isMultiline ? " textAreaWrapper" : ""}
        ${inputError !== "" ? " defaultErrorInputWrapper" : ""}      
        `}
      >
        {children}
      </FormControl>
    </HtmlTooltip>
  );
};

/**
 * --------------------------------------------
 * @Author: Nisal Madusanka(EruliaF)
 * @Description: Form Control Wrapper
 * --------------------------------------------
 */
FormControlWrapper.propTypes = {
  /** element Wrapper css class */
  elementWrapperStyle: PropTypes.string,
  /** is set full width or not */
  isFullWidth: PropTypes.bool,
  /**  input field error message */
  inputError: PropTypes.string,
  /** is text area or not */
  isMultiline: PropTypes.bool,
  /** children element */
  children: PropTypes.node
};

//----------------FormControlWrapper-------------------

/**
 * --------------------------------------------
 * @Author: Nisal Madusanka(EruliaF)
 * @Description: Input Label Wrapper
 * ---------------------------------------------
 */

const InputLabelWrapper = ({
  uiType = defaultTemplateType,
  inputName = "",
  isRequired = false,
  lableText = ""
}) => {
  return (
    <InputLabel
      htmlFor={inputName}
      className={"defaultInputLabel"}
      required={isRequired}
      shrink={uiType === templateTypes.bootstrap ? true : undefined}
    >
      {lableText}
    </InputLabel>
  );
};

/**
 * --------------------------------------------
 * @Author: Nisal Madusanka(EruliaF)
 * @Description: Input Label Wrapper
 * --------------------------------------------
 */
InputLabelWrapper.propTypes = {
  /** UI type */
  uiType: PropTypes.oneOf([templateTypes.material, templateTypes.bootstrap]),
  /**  input field name */
  inputName: PropTypes.string,
  /** set required * mark */
  isRequired: PropTypes.bool,
  /** lable text */
  lableText: PropTypes.string
};

//----------------InputLabelWrapper-------------------

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Form Group Wrapper
 * --------------------------------------------
 */

const FormGroupWrapper = ({
  elementWrapperStyle = "",
  isMultiline = false,
  children = null
}) => {
    return (
      <FormGroup
        className={`defaultFormGroupWrapper ${elementWrapperStyle}`}
        row={isMultiline}
      >
        {children}
      </FormGroup>
    );

};

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Form Group Wrapper
 * --------------------------------------------
 */

FormGroupWrapper.propTypes = {
  /** element Wrapper css class */
  elementWrapperStyle: PropTypes.string,
  /** is text area or not */
  isMultiline: PropTypes.bool,
  /** children element */
  children: PropTypes.element
};

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Form Helper Text Wrapper
 * --------------------------------------------
 */

const FormHelperTextWrapper = ({
  elementStyle = "",
  inputError = "",
  helperText = ""
}) => {
    return (
      <FormHelperText 
        className={`defaultFormHelperText ${elementStyle} ${(inputError !== "") ? " defaultInputErrorText" : " defaultHelperText"}`}
      >
        { (inputError !== "") ? inputError : helperText}
      </FormHelperText>
    );
};

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Form Helper Text Wrapper
 * --------------------------------------------
 */

FormHelperTextWrapper.propTypes = {
  /** element css class */
  elementStyle: PropTypes.string,
  /** input error text */
  inputError: PropTypes.string,
  /** helper text */
  helperText: PropTypes.string
};

export { FormControlWrapper, InputLabelWrapper, FormGroupWrapper, FormHelperTextWrapper};
