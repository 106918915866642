/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-05-23 09:17:11 
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2021-06-01 15:15:54
 */

import React, { Fragment,useState } from 'react';


import { _get,  _findindex,_getInt } from "../../../../../helpers/common-helpers/lodash.wrappers";
import {CircleLoaderElement} from "../../common/BaseElements";
import { actionBtnTypes, commonActionBtnPops } from "../../../../../config/dataTable.config";
import {TableHeaderBar} from "./TableHeaderBar";
import {TableHeader} from "./TableHeader";
import {TableBody} from "./TableBody";
import {SearchBoxWrapper} from "./TableSearchForm";
import { FooterWrapper } from "./TableFooter";


const emptyFun = (...para) => undefined;


const TableUI=({  
    _dataTableKey="",
    _isSetAction=false,
    dataTableState={},
    _actionTypeList=[],
    _tableHeaderList=[],
    _specificComponents=null,
    _screenType="",
    _shortObject={},
    tableBody=[],
    maxItemCount=3,
    _dataTableErros=[],
    pagingObject={},
    isSelectRow=false,
    onRowStyleFn=emptyFun,
    _setDataTableStateFn=emptyFun,
    onShortFn=emptyFun,
    onChangeDataTableFieldValueFn=emptyFun
})=>{


    const [getState,setState]=useState({
        "selectAll":false,        
    });

    return(
        <div className="dataTableBodyWrapper">
            <table>
                <TableHeader
                    tableHeaderList={_tableHeaderList}
                    dataTableKey={_dataTableKey}
                    isSetAction={_isSetAction}
                    screenType={_screenType}
                    maxItemCount={maxItemCount}
                    shortObject={_shortObject}
                    isSelectRow={isSelectRow}
                    onShortFn={onShortFn}
                    selectAllRowStatus={getState.selectAll}
                    onCheckSelectALLFn={(value)=>setState({
                        ...getState,
                        "selectAll":value
                    })}
                />

                <TableBody
                    tableHeaderList={_tableHeaderList}
                    dataTableKey={_dataTableKey}
                    isSetAction={_isSetAction}
                    maxItemCount={maxItemCount}
                    isSelectRow={isSelectRow}
                    actionTypeList={_actionTypeList}
                    specificComponents={_specificComponents}
                    dataTableErros={_dataTableErros}
                    onRowStyleFn={onRowStyleFn}
                    onChangeValueFn={(stateKey, inputKey, value) => onChangeDataTableFieldValueFn(stateKey, inputKey, value)}
                    onActionClickFn={(row = {}, index = null,rowIndex=null) => _setDataTableStateFn({
                        ...dataTableState,
                        modelStatus: {
                            ...dataTableState.modelStatus,
                                status: true,
                                type: "actionList",
                                index: index,
                                props: row,
                                rowIndex:rowIndex
                            }
                        })
                    }
                    screenType={_screenType}
                    tableBody={tableBody}
                    pagingObject={pagingObject}
                    rowCheckObject={getState}
                    onCheckRowFn={(key,status)=>setState({
                        ...getState,
                        [key]:status
                    })}
                />
            </table>
        </div>     
    );
}


const TableUIWrapper=({
    _apiUrl="",
    _tableName="",
    _dataTableKey="",
    _isSetSearchFrom=false,
    _isSetAction=false,
    dataTableState={},
    _actionTypeList=[],
    _tableHeaderList=[],
    _defaultSearchFormObject={},
    _searchFromComponent=null,
    _onSetSearchObjectFn=false,
    _specificComponents=null,
    _screenType="",
    _pagingObject={},
    _shortObject={},
    tableBody=[],
    maxItemCount=3,
    _dataTableErros=[],
    isSetTableFooter=true,
    isSetTableHeader=true,
    isSelectRow=false,
    _responseFetchingStatus="init",
    onRowStyleFn=emptyFun,
    _setDataTableStateFn=emptyFun,
    requestAPIDataFn=emptyFun,
    onShortFn=emptyFun,
    onChangeDataTableFieldValueFn=emptyFun
})=>{
    return (
        <Fragment>

                {
                    (isSetTableHeader==true)?(
                        <Fragment>
                            <TableHeaderBar
                                tableName={_tableName}
                                isSetSearchFrom={_isSetSearchFrom}
                                dataTableState={dataTableState}
                                setDataTableStateFn={_setDataTableStateFn}
                                actionIndex={_findindex(_actionTypeList, ["actionType", actionBtnTypes.create])}
                                actionBtnPara={{
                                    ...commonActionBtnPops[actionBtnTypes.create],
                                    ..._get(_actionTypeList, `${_findindex(_actionTypeList, ["actionType", actionBtnTypes.create])}.actionBtn`, {})
                                }}
                                isSetModel={_get(_actionTypeList, `${_findindex(_actionTypeList, ["actionType", actionBtnTypes.create])}.modelContentElement`, null) === null ? false : true}
                            />
                            <SearchBoxWrapper
                                isSetSearchFrom={_isSetSearchFrom}
                                dataTableState={dataTableState}
                                dataTableKey={_dataTableKey}
                                defaultSearchFormObject={_defaultSearchFormObject}
                                searchFromComponent={_searchFromComponent}
                                setDataTableStateFn={_setDataTableStateFn}
                                onGetData={requestAPIDataFn}
                                onSetSearchObjectFn={_onSetSearchObjectFn}
                            />
                        </Fragment>
                    ):(null)
                }
                
                {
                    (_apiUrl && (_responseFetchingStatus==='init'|| _responseFetchingStatus===undefined))?(
                        <div className="fullWidthDiv defaultMarginTopBottom">
                            <center>
                            <CircleLoaderElement/>
                            </center>
                        </div>
                    ):(
                    <Fragment>
                        {
                         (_responseFetchingStatus==='error'||tableBody.length===0)?(
                            <div className="fullWidthDiv defaultMarginTopBottom defaultPaddingLeftRight">
                                No record(s) found.
                            </div>
                         ):(
                            <Fragment>
                                <TableUI
                                    _dataTableKey={_dataTableKey}
                                    _isSetAction={_isSetAction}
                                    dataTableState={dataTableState}
                                    _actionTypeList={_actionTypeList}
                                    _tableHeaderList={_tableHeaderList}
                                    _specificComponents={_specificComponents}
                                    _screenType={_screenType}
                                    _shortObject={_shortObject}
                                    tableBody={tableBody}
                                    maxItemCount={maxItemCount}
                                    _dataTableErros={_dataTableErros}
                                    pagingObject={_pagingObject}
                                    isSelectRow={isSelectRow}
                                    _setDataTableStateFn={_setDataTableStateFn}
                                    onShortFn={onShortFn}
                                    onRowStyleFn={onRowStyleFn}
                                    onChangeDataTableFieldValueFn={onChangeDataTableFieldValueFn}
                                />

                                {
                                    (isSetTableFooter===true)?(
                                        <FooterWrapper
                                            totalRecordsCount={_getInt(_pagingObject,'total',null)}
                                            currentPage={_getInt(_pagingObject,'current_page',1)}
                                            pageCount={_getInt(_pagingObject,'page_count',1)}
                                            perPage={_getInt(_pagingObject,'per_page',10)}
                                            onNextFn={requestAPIDataFn}
                                        />
                                    ):(null)
                                }
                               
                            </Fragment>
                         )
                        }
                    </Fragment>                        
                    )
                    
                }
                
        </Fragment>
    )
}




export {
    TableUIWrapper
}