import React, { useContext } from "react";

import { SubmitButton } from "../../../../ui-components/ui-elements/form";
import { rateListAPI } from "../../../../../config/apiUrl.config";
import { getDataByFormObject } from "../../../../../helpers/common-helpers/common.helpers";
import { UIContext } from "../../../../ui-components/context-providers/UIContext.provider";


const DeleteRates = () => {
    return (
        <div className="fullWidthDiv">
            <p>Are you sure you want to continue?</p>
        </div>
    )
}

const RateDeleteBtn = (props) => {
    const [, uiAction] = useContext(UIContext);

    return (
        <SubmitButton
            btnText="Yes"
            elementStyle="btnWrapper"
            startIcon={"far fa-save"}
            //formGroupName={`${fineConfigurationsListAPI.key}_edit`}
            dataTableKey={rateListAPI.key}
            isValidate={false}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Rate is successfully removed.",
                    messageType: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "delete",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: "DATA_TABLE_LINKED_FORM",
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${rateListAPI.url}/${props.row.id}`,
                    key: rateListAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                return getDataByFormObject(formObject);
            }}
            onResponseCallBackFn={(error) => {
                if (!error) {
                    uiAction.setFlashMessage({
                        status: true,
                        message: "Rate is successfully deleted",
                        messageType: "success"
                    });

                    props.onClosePopupFn(false);
                }
            }}
        />
    )
}

export {
    DeleteRates,
    RateDeleteBtn
}