/**
 * Created By Pradeepa Sandaruwan
 * At 2020/12/28
 */
import React, { Fragment } from "react";
import { SubmitButton } from "../../../../ui-components/ui-elements/form";
import { bankListApi } from "../../../../../config/apiUrl.config";
import { getDataByFormObject } from "../../../../../helpers/common-helpers/common.helpers";
import { apiResponseStoringType } from "../../../core/context-providers/CoreContext.provider";

const CustomButton = (props) => {
  return (
    <Fragment>
      <SubmitButton
        startIcon={props.uiType === "Edit"? "far fa-edit" : "far fa-save"}
        btnText={props.uiType === "Edit" ? "Update" : "Save"}
        formGroupName={props.formGroupName}
        tooltip={props.uiType === "Edit" ? "Edit" : "Create New"}
        dataTableKey={props.dataTableKey}
        isValidate={true}
        validationObject={{
          fileds: {
            code: "Bank Code",
            name: "Bank Name",
            active: "Status",
          },
          rules: {
            code: "required|max:4",
            name: "required|max:100",
            active: "required",
          },
        }}
        flashMessages={{
          success: {
            status: true,
            message:
              props.uiType === "Add"
                ? "Bank is successfully saved."
                : "Bank is successfully updated.",
            messageType: "success",
          },
        }}
        onGetAPIEndPointFn={(formState) => {
          return {
            url:
              props.uiType === "Add"
                ? bankListApi.url
                : `${bankListApi.url}/` + formState.id,
            key: bankListApi.key,
          };
        }}
        onChangeRequestBodyFn={(formObject) => {
          return getDataByFormObject({
            ...formObject,
            active: parseInt(formObject.active) === 1 ? true : false,
            type: 4
          });
        }}
        callApiObject={{
          isSetHeaders: true,
          method: props.uiType === "Add" ? "post" : "put",
        }}
        apiDataStoringObject={{
          setLoader: true,
          storingType: apiResponseStoringType.dateTableLinkedForm,
        }}
        onResponseCallBackFn={(response, error) => {
          if (
            response.length === undefined &&
            error === undefined &&
            response.data.errors === undefined
          ) {
            props.onClosePopupFn();
          }
        }}
      />
    </Fragment>
  );
};
export { CustomButton };
