import React from "react";
import { FormWrapper, InputBoxWithState, SelectBoxWithState } from "../../../../ui-components/ui-elements/form";

const SearchElement = ({dataTableKey=""}) => {
    const formKey = `${dataTableKey}_search`
    return (
        
        <FormWrapper setGroupName={"vrpSearch"}>
            <div className="row">
                <div className={"col-md-2 floatLeft"}>
                    <InputBoxWithState
                        labelText={"Code"}
                        inputName={"code"}
                        maxLength={20}
                        formGroupName={formKey}
                    />
                </div>
                <div className="col-md-2">
                    <InputBoxWithState
                        labelText={"Name"}
                        formGroupName={formKey}
                        maxLength={200}
                        inputName={"name"}
                    />
                </div>
                <div className={"col-md-2 floatLeft"}>
                <SelectBoxWithState
                        formGroupName={formKey}
                        labelText={"Status"}
                        inputName={"active"}
                        dataList={[
                        { id: null, value: "All"},
                        { id: true, value: "Active" },
                        { id: false, value: "Inactive" },
                        ]}
                />
                </div>
            </div>
        </FormWrapper>
    );
};

export {
    SearchElement,
};