/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-02-28 17:31:46
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-04-08 10:49:11
 */

import React, { Fragment,Suspense } from 'react';


import TestUI from './pages/TestUI';
import { Navigate } from '../../ui-components/ui-elements/common/Navigate';
import { permissions } from '../../../config/permission.config';
import authorized from '../../hoc-middlewares/Authorized';
import Test2 from './pages/Test2';
import Test3 from './pages/Dashboard';

const Dashboard = React.lazy(() => import('./pages/Dashboard'));

const UI = () => {
    return (
      <Suspense fallback={<div></div>}>
        <Dashboard />
      </Suspense>
    )
  }

const DashboardRoutes = () => {
  return (
    <Fragment>
      <Navigate
        path="/"
        exact
        component={authorized(UI)}
        routeKey={'dashboard'}
        routePermissions={permissions.NONE.permissions}
      />

      <Navigate
        exact={true}
        path="/test"
        routeKey={'test'}
        component={authorized(TestUI)}
        routePermissions={permissions.NONE.permissions}
      />
      <Navigate
        exact={true}
        path="/test2"
        routeKey={'test'}
        component={authorized(Test2)}
        routePermissions={permissions.NONE.permissions}
      />
    </Fragment>
  );
};

export default DashboardRoutes;
