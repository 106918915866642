import React from "react";
import { FormWrapper, InputBoxWithState, SelectBoxWithState } from "../../../../ui-components/ui-elements/form";

const SearchElement = ({dataTableKey=""}) => {
    const formKey = `${dataTableKey}_search`
    return(
        <FormWrapper
            setGroupName={"VFTSearch"}
        >
            <div className="row">
                <div className="col-md-2 col-sm-4 floatLeft">
                    <InputBoxWithState
                        labelText={"Code"}
                        inputName={"code"}
                        formGroupName={formKey}
                        maxLength={50}
                        inputPlaceholder={"Code"}
                        
                    />
                </div>

                <div className="col-md-2 col-sm-4 floatLeft">
                    <InputBoxWithState
                        labelText={"Name"}
                        inputName={"name"}
                        formGroupName={formKey}
                        maxLength={50}
                        inputPlaceholder={"Name"}
                    />
                </div>
                
                <div className="col-md-2 col-sm-4 floatLeft">
                    <SelectBoxWithState
                        formGroupName={formKey}
                        labelText={"Status"}
                        inputName={"active"}
                        dataList={[
                        { id: null, value: "All"},
                        { id: true, value: "Active" },
                        { id: false, value: "Inactive" },
                        ]}
                        emptySelectOptionTxt={"All"}
                    />
                </div>
            </div>
        </FormWrapper>
    )
}

export {
    SearchElement
}