export const NumbersOnlyRegexPattern = {
    pattern: /^\d+$/,
    message: "The :attribute should only contain numbers"
};

export const URLRegexPattern = {
    pattern: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
    message: "The :attribute should be a valid URL"
};

export const AlphabetsOnlyRegexPattern = {
    pattern: /^[a-zA-Z]+$/,
    message: "The :attribute should only contain alphabetical letters"
};

export const AlphabetsOnlyWithSpacesRegexPattern = {
    pattern: /^[a-zA-Z ]+$/,
    message: "The :attribute should only contain alphabetical letters"
};

export const AlphabetsOnlyWithSpacesAndDotsRegexPattern = {
    pattern: /^[a-zA-Z. ]+$/,
    message: "The :attribute should only contain alphabetical letters"
};