import React, { useContext } from "react";
import { Fragment } from "react";
import { authorityListAPI, licenseTypeListAPI, operationTypesListAPI, transactionTypeListAPI } from "../../../../../config/apiUrl.config";
import { ContactTypeCode } from "../../../../../config/enum.config";
import { templateTypes } from "../../../../../config/template.config";
import { contactsArrayToObject } from "../../../../../helpers/common-helpers/contact.helpers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { _findindex, _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { UICard, AuditDetail, ViewDetailBlock } from "../../../../ui-components/ui-elements/common/BaseElements";
import { CheckBox, FormWrapper, InputBoxWithState, SubmitButton } from "../../../../ui-components/ui-elements/form";
import { actionBtnTypes, DataTable } from "../../../../ui-components/ui-elements/table/DataTable";
import { FormContext } from "../../../core/context-providers/FormContext.provider";

const ViewAuthority = (props) => {

  const labelCol = 3;
  const cellCol = (12 - labelCol) / 3;

  const [formState, formAction] = useContext(FormContext);

  const getTransactionTypesAllowed = (categories = [], allowedTypes = []) => {
    let temp = {};

    categories.forEach((value) => {
      const index = _findindex(allowedTypes, (e) => value.code === e.operation_type);
      temp[value.id] = index !== -1;
    });
    return temp;
  }
  const getLicenceTypesAllowed = (categories = [], allowedTypes = []) => {
    let temp = {};

    categories.forEach((value) => {
      const index = _findindex(allowedTypes, (e) => value.id === e.licence_type_id);
      temp[value.id] = index !== -1;
    });

    return temp;
  }
  return (
    <FormWrapper
      setGroupName={`${authorityListAPI.key}_view`}
      apiUrl={operationTypesListAPI.url}
      onRebuildResponseFn={(response) => {
        return {
          id: _get(props, "row.id", ""),
          code: _get(props, "row.code", ""),
          province_code: _get(props, "row.province.code", ""),
          district_code: _get(props, "row.district.code", ""),
          prefix: _get(props, "row.prefix", ""),
          name: _get(props, "row.service.name", ""),
          name_si: _get(props, "row.service.name_si", ""),
          name_ta: _get(props, "row.service.name_ta", ""),
          authority_type_code: _get(props, "row.authority_type.code", ""),

          contacts: {
            contact_number_1: contactsArrayToObject(_get(props, `row.service.contacts`, []), false, ContactTypeCode.MOBILE),
            contact_number_2: contactsArrayToObject(_get(props, `row.service.contacts`, []), false, ContactTypeCode.PHONE),
            email: contactsArrayToObject(_get(props, `row.service.contacts`, []), false, ContactTypeCode.EMAIL),
            fax: contactsArrayToObject(_get(props, `row.service.contacts`, []), false, ContactTypeCode.FAX),
          },
          line1: _get(props, "row.service.addresses[0].line1", ""),
          line1_si: _get(props, "row.service.addresses[0].line1_si", ""),
          line1_ta: _get(props, "row.service.addresses[0].line1_ta", ""),
          line2: _get(props, "row.service.addresses[0].line2", ""),
          line2_si: _get(props, "row.service.addresses[0].line2_si", ""),
          line2_ta: _get(props, "row.service.addresses[0].line2_ta", ""),
          city: _get(props, "row.service.addresses[0].city", ""),
          city_si: _get(props, "row.service.addresses[0].city_si", ""),
          city_ta: _get(props, "row.service.addresses[0].city_ta", ""),
          postal_code: _get(props, "row.service.addresses[0].postal_code", ""),
          active: _get(props, "row.service.active", ""),
          licence_reorder_level: _get(props, "row.licence_reorder_level", ""),
          service_operation_types_allowed: response,
          check_transaction_type: { ...getTransactionTypesAllowed(response, _get(props, "row.service.operationTypesAllowed", [])) },
          "_onLoad": false
        }
      }}
      onDestroyUnsetFormObject={false}
    >
      <FormWrapper
        setGroupName={"authorityListAPIKey_view1"}
        apiUrl={licenseTypeListAPI.url}
        onRebuildResponseFn={(response) => {
          return {
            service_licence_types_allowed: response,
            check_licence_type: { ...getLicenceTypesAllowed(response, _get(props, "row.service.licenceTypes", [])) },
            "_onLoad": false
          }
        }}
        onDestroyUnsetFormObject={false}

      >

        <Fragment>
          <UICard elementStyle="defaultPaddingLeft"  >
            <ViewDetailBlock
              labelCol={labelCol}
              label={"Authority Code"}
              value={_get(props, `row.code`, '')}
            />
            <ViewDetailBlock
              labelCol={labelCol}
              label={"District"}
              value={_get(props, `row.district.name`, '')}
            />
            <ViewDetailBlock
              labelCol={labelCol}
              label={"Status"}
              value={(_get(props, "row.service.active", "") === 1) ? "Active" : "Inactive"}
            />
            <ViewDetailBlock
              labelCol={labelCol}
              label={"Authority Prefix"}
              value={_get(props, `row.prefix`, '')}
            />

            <br />

            <div className="row defaultPaddingTopBottom">
              <div className={`col-${labelCol}`}>{/* Empty cell */}</div>
              <div className={`col-${cellCol}`}>
                <strong>English</strong>
              </div>
              <div className={`col-${cellCol}`}>
                <strong>Sinhala</strong>
              </div>
              <div className={`col-${cellCol}`}>
                <strong>Tamil</strong>
              </div>
              <div className="col-12">
                <ViewDetailBlock
                  labelCol={labelCol}
                  label={"Authority Name"}
                  value={
                    <div className="row">
                      <div className="col-4">{_get(props, "row.service.name", "")}</div>
                      <div className="col-4">{_get(props, "row.service.name_si", "")}</div>
                      <div className="col-4">{_get(props, "row.service.name_ta", "")}</div>
                    </div>
                  }
                />
              </div>
            </div>

            <br />

            <div className="row defaultPaddingTopBottom">
              <div className="col-12">
                <ViewDetailBlock
                  labelCol={labelCol}
                  label={"Authority Type"}
                  value={_get(props, `row.authority_type.name`, '')}
                />

                <ViewDetailBlock
                  labelCol={labelCol}
                  label={"Province"}
                  value={_get(props, `row.province.name`, '')}
                />
                <ViewDetailBlock
                  labelCol={labelCol}
                  label={"Contact Number 1"}
                  value={contactsArrayToObject(_get(props, `row.service.contacts`, []), false, ContactTypeCode.MOBILE)}
                />
                <ViewDetailBlock
                  labelCol={labelCol}
                  label={"Contact Number 2"}
                  value={contactsArrayToObject(_get(props, `row.service.contacts`, []), false, ContactTypeCode.PHONE)}
                />
                <ViewDetailBlock
                  labelCol={labelCol}
                  label={"E-mail"}
                  value={contactsArrayToObject(_get(props, `row.service.contacts`, []), false, ContactTypeCode.EMAIL)}
                />
                <ViewDetailBlock
                  labelCol={labelCol}
                  label={"Fax"}
                  value={contactsArrayToObject(_get(props, `row.service.contacts`, []), false, ContactTypeCode.FAX)}
                />
              </div>
            </div>

            <br />

            <div className="row defaultPaddingTopBottom">
              <div className={`col-${labelCol}`}>{/* Empty cell */}</div>
              <div className={`col-${cellCol}`}>
                <strong>English</strong>
              </div>
              <div className={`col-${cellCol}`}>
                <strong>Sinhala</strong>
              </div>
              <div className={`col-${cellCol}`}>
                <strong>Tamil</strong>
              </div>
              <div className="col-12">
                <ViewDetailBlock
                  labelCol={labelCol}
                  label={"Address Line 1"}
                  value={
                    <div className="row">
                      <div className="col-4">{_get(props, "row.service.addresses[0].line1", "")}</div>
                      <div className="col-4">{_get(props, "row.service.addresses[0].line1_si", "")}</div>
                      <div className="col-4">{_get(props, "row.service.addresses[0].line1_ta", "")}</div>
                    </div>
                  }
                />
                <ViewDetailBlock
                  labelCol={labelCol}
                  label={"Address Line 2"}
                  value={
                    <div className="row">
                      <div className="col-4">{_get(props, "row.service.addresses[0].line2", "")}</div>
                      <div className="col-4">{_get(props, "row.service.addresses[0].line2_si", "")}</div>
                      <div className="col-4">{_get(props, "row.service.addresses[0].line2_ta", "")}</div>
                    </div>
                  }
                />
                <ViewDetailBlock
                  labelCol={labelCol}
                  label={"City"}
                  value={
                    <div className="row">
                      <div className="col-4">{_get(props, "row.service.addresses[0].city", "")}</div>
                      <div className="col-4">{_get(props, "row.service.addresses[0].city_si", "")}</div>
                      <div className="col-4">{_get(props, "row.service.addresses[0].city_ta", "")}</div>
                    </div>
                  }
                />
              </div>
            </div>

            <br />

            <div className="row defaultPaddingTopBottom">
              <div className="col-12">
                <ViewDetailBlock
                  labelCol={labelCol}
                  label={"Postal Code"}
                  value={_get(props, "row.service.addresses[0].postal_code", "")}
                />
              </div>
              <div className="col-12">
                <ViewDetailBlock
                  labelCol={labelCol}
                  label={"Re-order Level"}
                  value={_get(props, "row.licence_reorder_level", "")}
                />
              </div>
            </div>

            <div className="row defaultPaddingTopBottom">
              <div className="col-6">
                <UICard cardHeading={"Licence Types"}>
                  {
                    _get(formState, `authorityListAPIKey_view1.service_licence_types_allowed`, []).map((value, index) => {
                      return (
                        <div key={index}>
                          <CheckBox
                            inputValue={_get(formState, `authorityListAPIKey_view1.check_licence_type[${value.id}]`, "")}
                            isDisabled={true}
                            labelText={value.name}
                          />
                        </div>
                      )
                    })
                  }
                </UICard>
              </div>
              <div className="col-6">
                <UICard 
                cardHeading={"Operation Allowed"}                
                inputName={"service_operation_types_allowed"}>
                  {
                    _get(formState, `${authorityListAPI.key}_view.service_operation_types_allowed`, []).map((value, index) => {
                      return (
                        <div className="col-sm-6" key={index}>
                          <CheckBox
                            labelText={value.name}
                            isDisabled={true}
                            inputValue={_get(formState, `${authorityListAPI.key}_view.check_transaction_type[${value.id}]`, "")}
                          />
                        </div>
                      )
                    })
                  }
                </UICard>

              </div>

            </div>

            <div className="row defaultPaddingTopBottom">
              <div className="col-12">
                <AuditDetail
                  labelCol="4"
                  createdAt={dateObjectToString(_get(props, `row.service.created_at`, ""), "dd/mm/YY HH:MM")}
                  updatedAt={dateObjectToString(_get(props, `row.service.updated_at`, ""), "dd/mm/YY HH:MM")}
                  createdById={_get(props, `row.service.created_by`, "")}
                  updatedById={_get(props, `row.service.updated_by`, "")}
                  createdLocationId={_get(props, `row.service.created_location`, "")}
                  updatedLocationId={_get(props, `row.service.updated_location`, "")}
                  createdRoleId={_get(props, `row.service.created_role_id`, '')}
                  updatedRoleId={_get(props, `row.service.updated_role_id`, '')}
                />
              </div>
            </div>

          </UICard>

        </Fragment >

      </FormWrapper>
    </FormWrapper>

  )
}

const SetSerial = () => {
  const formKey = "testInuts1s";
  const dataTableKey = "datatablekey"
  const dataTableKey1 = "datatablekey1"
  return (
    <div className="row">
      <div className="col-md-12 col-sm-12 ">
        <UICard>
          <FormWrapper setGroupName={formKey}>
            <div className="col-md-4 col-sm-12 ">
              <InputBoxWithState
                uiType={templateTypes.bootstrap}
                formGroupName={formKey}
                inputName={"serialNo"}
                labelText="Serial No From"
                isRequired={true}
              />
            </div>
            <div className="col-md-4 col-sm-12 ">
              <InputBoxWithState
                uiType={templateTypes.bootstrap}
                formGroupName={formKey}
                inputName={"serialTo"}
                labelText="Serial No To"
                isRequired={true}
              />
            </div>
            <div className="col-md-4 col-sm-12 ">
              <SubmitButton
                elementWrapperStyle={"floatLeft"}
                formGroupName={formKey}
                btnName={"Add"}

                btnText={"add"}
              />
            </div>
          </FormWrapper>
        </UICard>
        <div className="row">
          <div className="col-md-12 floatLeft defaultMarginTopBottom">
            <DataTable
              tableName={"Assigned Serial Numbers"}
              dataTableKey={dataTableKey1}
              isSetAction={true}
              actionTypeList={[
                {
                  actionType: actionBtnTypes.delete,
                  actionBtn: { tooltip: 'Remove' },
                }
              ]
              }
              tableHeaderList={[
                {
                  displayName: "Serial No. From",
                  key: "Serial_from",
                  isSort: false,
                },
                {
                  displayName: "Serial No. To",
                  key: "Serial_to",
                  isSort: false,
                },

                {
                  displayName: "Serial No. Count ",
                  key: "serial_count",
                  isSort: false,
                },
                {
                  displayName: "Discarded Count",
                  key: "discarded_count",
                  isSort: false,
                },
                {
                  displayName: "Serial No. Balance",
                  key: "serial_balance",
                  isSort: false,
                },
              ]}
              tableBodyList={[
                {
                  Serial_from: "1",
                  Serial_to: "5000",
                  serial_count: "5001",
                  discarded_count: "8",
                  serial_balance: "545465",
                  status: "Active",
                },
              ]}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 floatLeft defaultMarginTopBottom">
            <DataTable
              tableName={"Serial Number History"}
              dataTableKey={dataTableKey}
              isSetAction={false}
              tableHeaderList={[
                {
                  displayName: "Serial No. From",
                  key: "Serial_from",
                  isSort: false,
                },
                {
                  displayName: "Serial No. To",
                  key: "Serial_to",
                  isSort: false,
                },

                {
                  displayName: "Serial No. Count ",
                  key: "serial_count",
                  isSort: false,
                },
                {
                  displayName: "Discarded Count",
                  key: "discarded_count",
                  isSort: false,
                },
                {
                  displayName: "Serial No. Balance",
                  key: "serial_balance",
                  isSort: false,
                },
                {
                  displayName: "Created By",
                  key: "created_by",
                  isSort: false,
                },
                {
                  displayName: "Create Location",
                  key: "created_loc",
                  isSort: false,
                },
                {
                  displayName: "Create At",
                  key: "created_at",
                  isSort: false,
                },
              ]}
              tableBodyList={[
                {
                  Serial_from: "1",
                  Serial_to: "5000",
                  serial_count: "5001",
                  discarded_count: "8",
                  created_by: "Admin",
                  created_loc: "Uva",
                  created_at: "2019/01/21 8:50 AM",
                },
              ]}
            />
          </div>
        </div>

      </div>
    </div>
  );
};

export { SetSerial, ViewAuthority };