/*
 * @Author: Chanaka Wickramasinghe
 * @Description: Material Stepper
 * @Date: 2020-02-19 15:24:12
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-04-29 10:28:03
 */

import React, { Fragment }  from 'react';
import PropTypes from 'prop-types';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';

import {InputButton, inputBtnVariants,SubmitButton} from "../form/Button";

const emptyFun = (...para) => undefined;

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Material Stepper
 * --------------------------------------------
 */

// StepsWrapper orientations
const stepWrapperOrientations = {
    horizontal: "horizontal",
    vertical: "vertical"
};

const StepsWrapper = ({
    elementWrapperStyle = "",
    elementStyle = "",
    orientation = stepWrapperOrientations.horizontal,
    activeStep = 0,
    children = (null),
    nextBtnProps={
        onGetAPIEndPointFn:(formState)=>{
            return false
        }
    },
    backBtnProps={
        onGetAPIEndPointFn:(formState)=>{
            return false
        }
    },
    onBackBtnClickFn = emptyFun,
    onNextBtnClickFn = emptyFun
}) => {
    let stepList = {};

    return (
        <Fragment>
            <Stepper
                className={`defaultStepperWrapper ${elementWrapperStyle}`}
                orientation={orientation}
                activeStep={activeStep}
            >
                {
                    React.Children.map(children, (child, i) => {
                        if (child) {
                            stepList[child.props.stepid] = child;

                            return (
                                <Step key={i} className={elementStyle}>
                                    <StepLabel><h6>{child.props.label}</h6></StepLabel>
                                    {
                                        (orientation === stepWrapperOrientations.vertical) ? (
                                            <StepContent>
                                                <Typography component="div" className={"defaultStepperBodyWrapper"}>
                                                    {child}
                                                </Typography>

                                                <div className={"defaultStepperBtnWrapper"}>
                                                    <SubmitButton
                                                        btnText={"Back"}
                                                        variant={inputBtnVariants.text}
                                                        isBtnDisabled={activeStep === 0}
                                                        onClickBtnFn={onBackBtnClickFn}
                                                        {...backBtnProps}
                                                        startIcon={'fas fa-backward'}
                                                    />

                                                    <SubmitButton
                                                        btnText={(activeStep === children.length - 1) ? "Finish" : "Next"}
                                                        startIcon={(activeStep === children.length - 1) ?"far fa-stop-circle" : "fas fa-forward"}
                                                        variant={inputBtnVariants.contained}
                                                        onClickBtnFn={onNextBtnClickFn}
                                                        {...nextBtnProps}
                                                    />
                                                </div>
                                            </StepContent>
                                        ) : null
                                    }
                                </Step>
                            );
                        }
                    })
                }
            </Stepper>

            {
                (orientation === stepWrapperOrientations.horizontal) ? (
                    <div>
                        <Typography component="div" className={"defaultStepperBodyWrapper"}>
                            {stepList[activeStep]}
                        </Typography>

                        {
                            (activeStep === children.length) ? null : (
                                <div className={"defaultStepperBtnWrapper"}>
                                    <InputButton
                                        btnText={"Back"}
                                        variant={inputBtnVariants.text}
                                        isBtnDisabled={activeStep === 0}
                                        onClickBtnFn={onBackBtnClickFn}
                                    />

                                    <InputButton
                                        btnText={(activeStep === children.length - 1) ? "Finish" : "Next"}
                                        variant={inputBtnVariants.contained}
                                        onClickBtnFn={onNextBtnClickFn}
                                    />
                                </div>
                            )
                        }
                    </div>
                ) : null
            }

            {
                (activeStep === children.length) ? (
                    <Typography>All steps are completed</Typography>
                ) : null
            }
        </Fragment>
    );
};

const CustomStep = (props) => {
    return (
        <div stepid={props.stepid} label={props.label}>
            {props.children}
        </div>
    );
};

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Material Stepper
 * --------------------------------------------
 */

StepsWrapper.propTypes = {
    /** element Wrapper css class */
    elementWrapperStyle: PropTypes.string,
    /** Step element css class */
    elementStyle: PropTypes.string,
    /** Orientation */
    orientation: PropTypes.string,
    /** Active step */
    activeStep: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Children */
    children: PropTypes.node,
    /** Onbackbtn click function */
    onBackBtnClickFn: PropTypes.func,
    /** Onnextbtn click function */
    onNextBtnClickFn: PropTypes.func
};

//----------------StepsWrapper---------------------

export {
    StepsWrapper, 
    CustomStep,
    stepWrapperOrientations
};