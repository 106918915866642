/*
 * @Author: Binara Medawatta
 * @Date: 2021-01-04 10:56:20
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-01-26 12:54:07
 */
import React from "react";
import {
  FormWrapper,
  InputBoxWithState,
  SelectBoxWithState,
} from "../../../../ui-components/ui-elements/form";
import { templateTypes } from "../../../../../config/template.config";
import { authoritySerialNumbersListAPI } from "../../../../../config/apiUrl.config";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";

const AddSerial = (props) => {
  const formKey = props.fromParent.formKey;
  const authority_id = props.fromParent.authorityId;
  
  return (
    <div className="row">
      <div className="col-md-12 col-sm-12 ">
        <FormWrapper
          setGroupName={formKey}
          formGroupLinkWith={formKey}
          onDestroyUnsetFormObject={true}
          setFormObject={{
            counter_id: props.fromParent.counterId,
            active: true
          }}
        >
          <div className="col-md-12 col-sm-12 ">
            <SelectBoxWithState
              apiUrl={`${authoritySerialNumbersListAPI.url}/${authority_id}/serial-numbers/list?assigned=false`}
              apiStateKey={authoritySerialNumbersListAPI.key}
              keyName={"id"}
              valueName={"range"}
              formGroupName={formKey}
              inputName={"authority_serial_number_range"}
              labelText="Serial No. Range"
              emptySelectOptionTxt={"Select Serial Range"}
            />
          </div>
        </FormWrapper>
      </div>
    </div>
  );
};

const RemoveSerialNumbersForm = (props) => {
  const formKey = `${props.fromParent.formKey}`;
  const serialFrom = _get(props, "row.auth_serial_serial_number_from", 0);
  const serialTo = _get(props, "row.auth_serial_serial_number_to", 0);
  
  return (
    <FormWrapper
      setGroupName={formKey}
      formGroupLinkWith={formKey}
      setFormObject={{
        id: props.row.id,
        counter_id: props.fromParent.counterId
      }}
      onDestroyUnsetFormObject={true}
    >
      <div className="removeContent">
        <p>Are you sure you want to remove? (From :- {serialFrom} To :- {serialTo} )</p>
        <br />
        <br />
      </div>
    </FormWrapper>
  );
};

export { AddSerial, RemoveSerialNumbersForm };
