/*
 * @Author: Chanaka Wickramasinghe
 * @Description: Confirmation Popup
 * @Date: 2021-07-20 12:10:12
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2021-07-20 12:10:12
 */
import React, { Fragment } from "react";

import { DialogPopup } from "../../../../ui-components/ui-elements/modal/DialogPopup";
import { InputButton } from "../../../../ui-components/ui-elements/form";

const emptyFun = (...para) => undefined;

const CustomButtons = (props) => {
    return (
        <div className="row">
            <div className='defaultPaddingLeft'>
                <InputButton
                    btnText={'Yes'}
                    onClickBtnFn={() => props.onConfirmationYesFn()}
                    startIcon={'fas fa-check'}
                />
            </div>

            <div className='defaultHalfPaddingLeftRight'>
                <InputButton
                    btnText={'No'}
                    onClickBtnFn={() => props.onConfirmationNoFn()}
                    startIcon={'fas fa-times'}
                />
            </div>
        </div>
    );
};

const ConfirmationPopup = ({
    isOpen = false,
    onConfirmationYesFn = emptyFun,
    onConfirmationNoFn = emptyFun,
}) => {
    return (
        <Fragment>
            <DialogPopup
                isOpen={isOpen}
                onClosePopupFn={() => onConfirmationNoFn()}
                dialogTitle={"Please Confirm"}
                isConfirmation={false}
                isCustomButton={true}
                isCloseButton={false}
                customButtonComponent={CustomButtons}
                customButtonComponentProps={{
                    onConfirmationYesFn,
                    onConfirmationNoFn
                }}
            >
                <div className="fullWidthDiv">
                    <p>Are you sure want to change this field?</p>
                </div>
            </DialogPopup>
        </Fragment>
    )
}

export default ConfirmationPopup;
