import React from "react";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import {
  UICard,
  AuditDetail,
  ViewDetailBlock,
} from "../../../../ui-components/ui-elements/common/BaseElements";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";

const ViewVETCompany = (props) => {
  const formKey = "dataTableKey";
  const numberOfLabelCol = 6;
  return (
    <div>
      <UICard cardHeading="View VET Company">
        <ViewDetailBlock
          label={"VET Code"}
          value={_get(props, "row.code", "")}
          labelCol={numberOfLabelCol}
        />
        <ViewDetailBlock
          label={"VET Company Name in English"}
          value={_get(props, "row.service.name", "")}
          labelCol={numberOfLabelCol}
        />
        <ViewDetailBlock
          label={"VET Company Name in Sinhala"}
          value={_get(props, "row.service.name_si", "")}
          labelCol={numberOfLabelCol}
        />
        <ViewDetailBlock
          label={"VET Company Name in Tamil"}
          value={_get(props, "row.service.name_ta", "")}
          labelCol={numberOfLabelCol}
        />
        <ViewDetailBlock
          label={"Business Registration Number"}
          value={_get(props, "row.br_number", "")}
          labelCol={numberOfLabelCol}
        />
        <ViewDetailBlock
          label={"Contact Number"}
          value={_get(props, "row.service.contacts[0].contact", "")}
          labelCol={numberOfLabelCol}
        />
        <ViewDetailBlock
          label={"Status"}
          value={
            _get(props, "row.service.active", "") === 1 ? "Active" : "Inactive"
          }
          labelCol={numberOfLabelCol}
        />
        {/* <ViewDetailBlock
          label={"API Mapping"}
          value={_get(props, "row.api_mapping", "")}
          labelCol={numberOfLabelCol}
        /> */}
      </UICard>
      <AuditDetail
        createdById={_get(props, `row.service.created_by`, "")}
        createdAt={dateObjectToString(_get(props, `row.service.created_at`, ""),"dd/mm/YY HH:MM")}
        createdRoleId={_get(props, `row.service.created_role_id`, '')}
        createdLocationId={_get(props, `row.service.created_location`, "")}
        updatedById={_get(props, `row.service.updated_by`, "")}
        updatedLocationId={_get(props, `row.service.updated_location`, "")}
        updatedAt={dateObjectToString(_get(props, `row.service.updated_at`, ""),"dd/mm/YY HH:MM")}
        updatedRoleId={_get(props, `row.service.updated_role_id`, '')}
        labelCol={6}
      />{" "}
    </div>
  );
};
export { ViewVETCompany };
