/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-02-28 17:31:46
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-04-06 13:50:38
 */

import React, { Fragment } from 'react';
import { Navigate } from '../../ui-components/ui-elements/common/Navigate';
import { permissions } from '../../../config/permission.config';
import ManageFuelType from './pages/Manage-fuel-type';
import { ManageFuelTypeTestPage } from './pages/test/ManageFuelTest';
import authorized from '../../hoc-middlewares/Authorized';

const ManageFuelTypeRoutes = () => {
  return (
    <Fragment>
      <Navigate
        exact={true}
        path="/manage-fuel-type"
        routeKey={'manage-fuel-type'}
        component={authorized(ManageFuelType)}
        routePermissions={permissions.FUEL_TYPE_MANAGE.permissions}
      />
      <Navigate
        exact={true}
        path="/manage-fuel-type-test"
        routeKey={'manageFuelTypePage'}
        component={authorized(ManageFuelTypeTestPage)}
        routePermissions={permissions.FUEL_TYPE_MANAGE.permissions}
      />
    </Fragment>
  );
};

export default ManageFuelTypeRoutes;
