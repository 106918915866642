/*
 * @Author: Unknown
 * @Date: 2021-01-04 10:55:22
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-04-08 16:10:55
 */
import React, { Fragment, useContext } from "react";
import {
  counterSerialNumbersCreateApi,
  counterSerialNumbersDeleteApi,
  unusedCounterSerialNumbersListApi,
  usedCounterSerialNumbersListApi,
} from "../../../../../config/apiUrl.config";
import { getDataByFormObject, isEmptyValue } from "../../../../../helpers/common-helpers/common.helpers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import {
  _get,
  _getInt,
} from "../../../../../helpers/common-helpers/lodash.wrappers";
import { SubmitButton } from "../../../../ui-components/ui-elements/form";
import {
  DataTable,
  actionBtnTypes,
  apiResponseStoringType,
} from "../../../../ui-components/ui-elements/table/DataTable";
import { CoreContext } from "../../../core/context-providers/CoreContext.provider";
import { AddSerial, RemoveSerialNumbersForm } from "./AddSerial";

const CustomButtonsSave = (props) => {
  const [coreState, coreAction] = useContext(CoreContext);
  const uiType = props.uiType;
  return (
    <Fragment>
      <SubmitButton
        btnText={uiType === "create" ? "Save" : "Yes"}
        startIcon={uiType === "create" ? "far fa-save" : "fas fa-check"}
        formGroupName={props.formGroupName}
        dataTableKey={unusedCounterSerialNumbersListApi.key}
        isValidate={uiType === "create" ? true : false}
        validationObject={{
          fileds: {
            authority_serial_number_range: "Serial No. Range",
          },
          rules: {
            authority_serial_number_range: "required",
          },
        }}
        flashMessages={{
          success: {
            status: true,
            message: " Serial Numbers are successfully saved.",
            messageType: "success",
          },
        }}
        onGetAPIEndPointFn={(formState) => {
          return {
            url:
              uiType === "create"
                ? `${counterSerialNumbersCreateApi.url}/${formState.counter_id}/serial-numbers`
                : `${counterSerialNumbersDeleteApi.url}/${formState.counter_id}/serial-numbers/${formState.id}`,
            key: uiType === "create" ? counterSerialNumbersCreateApi.key : counterSerialNumbersDeleteApi.key,
          };
        }}
        onChangeRequestBodyFn={(formObject) => {
          return getDataByFormObject({
            ...formObject,
          });
        }}
        callApiObject={{
          isSetHeaders: true,
          method: uiType === "create" ? "post" : "delete",
        }}
        apiDataStoringObject={{
          setLoader: true,
          storingType: apiResponseStoringType.dateTableLinkedForm,
        }}
        onResponseCallBackFn={(response, error) => {
          if (response.length === undefined && error === undefined) {
            coreAction.resetDataTable(unusedCounterSerialNumbersListApi.key);
            coreAction.resetDataTable(usedCounterSerialNumbersListApi.key);
            props.onClosePopupFn();
          }
        }}
      />
    </Fragment>
  );
};
const SetSerial = (props) => {
  const authorityName = _get(props, "row.authority_name", "");
  return (
    <div className="row">
      <div className="col-md-12 col-sm-12 ">
        <div className="row">
          <div className="col-md-12 col-sm-12 floatLeft">
            <DataTable
              tableName={"Assigned Serial Numbers"}
              apiUrl={`${unusedCounterSerialNumbersListApi.url}/${props.row.id}/serial-numbers?used=false&sort_by=created_at|asc`}
              dataTableKey={unusedCounterSerialNumbersListApi.key}
              isSetAction={true}
              actionTypeList={[
                {
                  actionType: actionBtnTypes.create,
                  modelContentElement: AddSerial,
                  dialogPopupProps: {
                    isFullScreen: false,
                    isSaveButton: true,
                    isCloseButton: true,
                    isCustomButton: true,
                    customButtonComponent: CustomButtonsSave,
                    customButtonComponentProps: {
                      dataTableKey: unusedCounterSerialNumbersListApi.key,
                      uiType: "create",
                    },
                    dialogTitle: "Set Serial Number",
                    closeButton: {
                      btnText: "Cancel",
                      startIcon: "far fa-times-circle",
                    },
                    isConfirmationPopup: true,
                  },
                  parentToModelProps: {
                    counterId: `${props.row.id}`,
                    authorityId: `${props.row.authority_id}`,
                    formKey: counterSerialNumbersCreateApi.key,
                    dataTableKey: unusedCounterSerialNumbersListApi.key,
                  },
                },
                {
                  actionType: actionBtnTypes.delete,
                  actionBtn: { tooltip: "Remove" },
                  modelContentElement: RemoveSerialNumbersForm,
                  dialogPopupProps: {
                    isFullScreen: false,
                    dialogTitle: "Please Confirm",
                    isCustomButton: true,
                    closeButton: {
                      btnText: "No",
                      startIcon: "fa fa-times",
                    },
                    customButtonComponent: CustomButtonsSave,
                    customButtonComponentProps: {
                      dataTableKey: unusedCounterSerialNumbersListApi.key,
                      uiType: "delete",
                    },
                  },
                  parentToModelProps: {
                    counterId: props.row.id,
                    formKey: counterSerialNumbersDeleteApi.key,
                    dataTableKey: unusedCounterSerialNumbersListApi.key,
                  },
                  checkBeforeActionRenderFn: (rawData) => {
                    const used = parseInt(_get(rawData, "row.used_count", 0));
                    const discarded = parseInt(
                      _get(rawData, "row.discarded_count", 0)
                    );
                    if (used > 0 || discarded > 0) {
                      return false;
                    } else {
                      return true;
                    }
                  },
                },
                ,
              ]}
              tableHeaderList={[
                {
                  displayName: "Serial No. From",
                  key: "auth_serial_serial_number_from",
                  isSort: false,
                },
                {
                  displayName: "Serial No. To",
                  key: "auth_serial_serial_number_to",
                  isSort: false,
                },
                {
                  displayName: "Serial No. Count ",
                  key: "serial_count",
                  isSort: false,
                  onPrintFn: ({
                    auth_serial_serial_number_from,
                    auth_serial_serial_number_to,
                  }) =>
                    parseInt(auth_serial_serial_number_to) -
                    parseInt(auth_serial_serial_number_from) +
                    +1,
                },
                {
                  displayName: "Discarded Count",
                  key: "discarded_count",
                  isSort: false,
                },
                {
                  displayName: "Serial No. Balance",
                  key: "serial_balance",
                  isSort: false,
                  onPrintFn: ({
                    auth_serial_serial_number_from,
                    auth_serial_serial_number_to,
                    discarded_count,
                    used_count
                  }) =>
                    parseInt(auth_serial_serial_number_to) -
                    parseInt(auth_serial_serial_number_from) -
                    (parseInt(discarded_count) + parseInt(used_count))
                    +1,
                },
              ]}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12 floatLeft defaultMarginTop">
            <DataTable
              tableName={"Serial Number History"}
              apiUrl={`${usedCounterSerialNumbersListApi.url}/${props.row.id}/serial-numbers?used=true&sort_by=created_at|desc`}
              dataTableKey={usedCounterSerialNumbersListApi.key}
              isSetAction={false}
              tableHeaderList={[
                {
                  displayName: "Serial No. From",
                  key: "auth_serial_serial_number_from",
                  isSort: false,
                },
                {
                  displayName: "Serial No. To",
                  key: "auth_serial_serial_number_to",
                  isSort: false,
                },
                {
                  displayName: "Serial No. Count ",
                  key: "serial_count",
                  isSort: false,
                  onPrintFn: ({
                    auth_serial_serial_number_from,
                    auth_serial_serial_number_to,
                  }) =>
                    parseInt(auth_serial_serial_number_to) -
                    parseInt(auth_serial_serial_number_from) +
                    +1,
                },
                {
                  displayName: "Discarded Count",
                  key: "discarded_count",
                  isSort: false,
                },
                {
                  displayName: "Created By",
                  key: "created_by",
                  isSort: false,
                  onPrintFn: ({ created_by }) =>
                  !isEmptyValue(_get(created_by, "user_name", null)) ? `${_get(created_by, "user_name", null)} (${_get(created_by, "user_role", null)})`: '-',
                },
                {
                  displayName: "Created Location",
                  key: "created_location",
                  isSort: false,
                  onPrintFn: ({ created_location }) =>
                    _get(created_location, "location", "-"),
                },
                {
                  displayName: "Created At",
                  key: "created_at",
                  isSort: false,
                  onPrintFn: ({ created_at }) =>
                    dateObjectToString(created_at, "dd/mm/YY HH:MM"),
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { SetSerial };
