/*
 * @Author: Sujith
 * @Date: 2020-12-22 10:11:46 
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2021-03-03 15:00:14
 */

import React, { Fragment } from 'react';


import { Navigate } from "../../ui-components/ui-elements/common/Navigate";
import { permissions } from "../../../config/permission.config";
import DeliveryCharge from "./pages/DeliveryCharge";
import authorized from '../../hoc-middlewares/Authorized';


const DeliveryChargesRoutes = () => {
    return (
        <Fragment>
            <Navigate exact={true} path="/delivery-charge" routeKey={"deliveryCharges"} component={authorized(DeliveryCharge)} routePermissions={permissions.DELIVERY_CHARGE_MANAGE.permissions} />
        </Fragment>
    )
};

export default DeliveryChargesRoutes;