/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-03-26 15:28:37
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-04-06 14:36:32
 */
import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import useKeycloak from './useKeycloak.hook';
import { usePermission } from '../hooks/common-hooks/usePermission.hook';
import { PermissionDenial } from '../modules/error-boundary/pages/PermissionDenial';
import { InitialLoader } from '../ui-components/ui-elements/common/BaseElements';

function authorized(Component) {
  function WrappedComponent(props) {
    const [initStatus, isAuth] = useKeycloak(props.routeKey, 'auth');
    const [, checkPermissionFn] = usePermission();

    const getRedirectUrl = () => {
      if (props.routeKey === "dashboard") {
        window.location.reload();
      }

      return "/";
    };

    return isAuth === null || initStatus === false ? (
      <InitialLoader />
    ) : (
      <Fragment>
        {' '}
        {isAuth === true ? (
          <Fragment>
            {checkPermissionFn(props.routePermissions) === true ? (
              <Component {...props} />
            ) : (
              <PermissionDenial />
            )}
          </Fragment>
        ) : (
          <Redirect to={getRedirectUrl()} />
        )}{' '}
      </Fragment>
    );
  }

  return WrappedComponent;
}

export default authorized;
