import React, { Fragment, useContext, useState,useEffect } from "react";
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { templateTypes } from "../../../../../config/template.config";
import {
  FormWrapper,
  SelectBoxWithState,
  InputBoxWithState,
  SelectBox,
  NumberInputBoxWithState,
} from "../../../../ui-components/ui-elements/form";
import { DataTable } from "../../../../ui-components/ui-elements/table/DataTable";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { CoreContext } from "../../../core/context-providers/CoreContext.provider";
import { generalConfigAPI } from "../../../../../config/apiUrl.config";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { callApi } from "../../../../../helpers/common-helpers/callApi.helpers";
import { CircularProgress } from "@material-ui/core";


const ConfigForm = (props) => {
  const formKey = `${props.fromParent.formKey}`;
  switch (_get(props, "row.configuration_code", null)) {
    case "RNW_LITY":
      return (
        <RenePerForLicTyp
          props={props}
          names={_get(props, `row.array`, "")}
          formKey={formKey}
          id={_get(props, `row.id`, "")}
          value_type={_get(props, `row.value_type`, "")}
          value={_get(props, `row.value`, "")}
          configuration_code={_get(props, `row.configuration_code`, "")}
          effective_from={_get(props, `row.effective_from`, "")}
        />
      );
    case "VET_FSTR":
      return (
        <PopupForm
          name="Edit Fine Exemption Days for Vehicle 1st Registration"
          fieldName="Days from the 1 st Registration Date"
          fineCalculationType="Exclude Fine Calculation for *"
          formKey={formKey}
          id={_get(props, `row.id`, "")}
          value_type={_get(props, `row.value_type`, "")}
          value={_get(props, `row.value`, "")}
          configuration_code={_get(props, `row.configuration_code`, "")}
          effective_from={_get(props, `row.effective_from`, "")}
        />
      );
    case "FIN_EXMPT":
      return (
        <PopupForm
          name="Edit Fine Exemption Days for Licence Renewal"
          fieldName="Days from the Current Revenue Licence Expiry Date"
          fineCalculationType="Exclude Fine Calculation for *"
          formKey={formKey}
          id={_get(props, `row.id`, "")}
          value_type={_get(props, `row.value_type`, "")}
          value={_get(props, `row.value`, "")}
          configuration_code={_get(props, `row.configuration_code`, "")}
          effective_from={_get(props, `row.effective_from`, "")}
        />
      );
    case "LCN_NUSR":
      return (
        <PopupForm
          name="Edit Licence Type Change to Non-User"
          fieldName="Days from the Normal/Temp Licence Expiry Date"
          fineCalculationType="Issue Non-User Only till *"
          formKey={formKey}
          id={_get(props, `row.id`, "")}
          value_type={_get(props, `row.value_type`, "")}
          value={_get(props, `row.value`, "")}
          configuration_code={_get(props, `row.configuration_code`, "")}
          effective_from={_get(props, `row.effective_from`, "")}
        />
      );

    case "VET_EXMPT":
      return (
        <EditVetCertExem
          name="Edit VET Certificate Exemption - Manufactured Year"
          fineCalculationType="Exclude Validating VET Certificate for Vehicles in which the Manufactured Year is On or Before"
          formKey={formKey}
          id={_get(props, `row.id`, "")}
          value_type={_get(props, `row.value_type`, "")}
          value={_get(props, `row.value`, "")}
          configuration_code={_get(props, `row.configuration_code`, "")}
          effective_from={_get(props, `row.effective_from`, "")}
        />
      );
    case "LCN_WARR":
      return (
        <EditWorkConfig
          name="Edit Workflow Configuration – Licence with Arrears"
          fieldName="Years of Arrears"
          fineCalculationType="Restrict Issuing RL Process for Normal/ Non-user Licence with Arrears, which have more than "
          formKey={formKey}
          id={_get(props, `row.id`, "")}
          value_type={_get(props, `row.value_type`, "")}
          value={_get(props, `row.value`, "")}
          configuration_code={_get(props, `row.configuration_code`, "")}
          effective_from={_get(props, `row.effective_from`, "")}
        />
      );
    case "WEB_MESG":
      return (
        <ServiceChargeAndTemporaryLicence
          name="Edit Online Temporary Licence Receipt – Message Content"
          fineCalculationType="Message*"
          formKey={formKey}
          id={_get(props, `row.id`, "")}
          value_type={_get(props, `row.value_type`, "")}
          value={_get(props, `row.value`, "")}
          configuration_code={_get(props, `row.configuration_code`, "")}
          effective_from={_get(props, `row.effective_from`, "")}
          multiline={true}
        />
      );
    case "SER_CHRG":
      return (
        <ServiceChargeAndTemporaryLicence
          name="Edit Service Charge Configuration"
          fineCalculationType="Service Charge(LKR)*"
          formKey={formKey}
          id={_get(props, `row.id`, "")}
          value_type={_get(props, `row.value_type`, "")}
          value={_get(props, `row.value`, "")}
          configuration_code={_get(props, `row.configuration_code`, "")}
          effective_from={_get(props, `row.effective_from`, "")}
        />
      );
    case "TMP_RENW":
      return (
        <RenewablePeriodTemporaryLicence
          props={props}
          names={_get(props, `row.array`, "")}
          formKey={formKey}
          id={_get(props, `row.id`, "")}
          value_type={_get(props, `row.value_type`, "")}
          value={_get(props, `row.value`, "")}
          configuration_code={_get(props, `row.configuration_code`, "")}
          effective_from={_get(props, `row.effective_from`, "")}
        />
      );
    default:
      return null;
  }
};

const SetDay = (props) => {
  const [coreState, coreAction] = useContext(CoreContext);
  const [oldValue,setOldValue]=useState()
  const [value, setValue] = useState(props.row.value)
  useEffect(()=>{
    props.onChangeValueFn(`${props.rowIndex}.value`,oldValue?oldValue:props.row.value);
 
  },[])
  const onChangeFn = (eventData) => {
    setValue(eventData)  
    setOldValue(value)
    props.onChangeValueFn(`${props.rowIndex}.value`, eventData.value);
   
  };
  return (
    <Fragment>
      <FormWrapper
        setGroupName={`${props.rowIndex}_day`}
        formGroupLinkWith={`${props.rowIndex}_day`}
        setFormObject={{
          id: props.row.id,
          value: value,

        }}
        onDestroyUnsetFormObject={false}
      >
        <div className="col-md-6 col-sm-8 floatLeft">
          <SelectBoxWithState
            dataList={[
              { id: "0", value: "0" },
              { id: "1", value: "1" },
              { id: "2", value: "2" },
              { id: "3", value: "3" },
              { id: "4", value: "4" },
              { id: "5", value: "5" },
              { id: "6", value: "6" },
              { id: "7", value: "7" },
              { id: "8", value: "8" },
              { id: "9", value: "9" },
              { id: "10", value: "10" },
              { id: "11", value: "11" },
              { id: "12", value: "12" },
            ]}
            keyName="id"
            valueName="value"
            emptySelectOptionTxt="0"
            uiType={templateTypes.bootstrap}
            formGroupName={`${props.rowIndex}_day`}
            lableText="Day"
            inputName={"value"}
            onChangeFn={(eventData) => onChangeFn(eventData)}
            inputValue={value}
          />
          {/* <SelectBox
            lableText="Day"
            inputName={"value"}
            // inputValue={_get(
            //   coreState,
            //   `dataTableResponses.${props.dataTableKey}.results[${props.rowIndex}].value`,
            //   ""
            // )}
            onChangeFn={(eventData) => onChangeFn(eventData)}
            dataList={[
              { id: "0", value: "0" },
              { id: "1", value: "1" },
              { id: "2", value: "2" },
              { id: "3", value: "3" },
              { id: "4", value: "4" },
              { id: "5", value: "5" },
              { id: "6", value: "6" },
              { id: "7", value: "7" },
              { id: "8", value: "8" },
              { id: "9", value: "9" },
              { id: "10", value: "10" },
              { id: "11", value: "11" },
              { id: "12", value: "12" },
            ]}
            keyName="id"
            valueName="value"
            emptySelectOptionTxt="0"
          /> */}
        </div>
      </FormWrapper>
    </Fragment>
  );
};

const RenePerForLicTyp = ({ names = "", formKey = "", }) => {
  const [formState, formAction] = useContext(FormContext)
  const[dataset,setDataSet]=useState([])
  const[onLoad,setonLoad]=useState(true)
  useEffect(() => {
    configFn()
  }, [dataset])
 
  const configFn = async () => {
    const AddDedData = sendAPIRequest(`${generalConfigAPI.url}`);
    Promise.all([AddDedData])
      .then(result => {   
        const newDataSet =  result[0].data.filter(item=>item.configuration_code==="RNW_LITY")
        setDataSet(newDataSet[0].array)
        setonLoad(false)
        // formAction.changeInputFn("generalConfigAPIKey", "data", newDataSet[0].array )  
        // formAction.changeInputFn("generalConfigAPIKey", "_onLoad", false )  
     
      })
  };
  const sendAPIRequest = (apiUrl) => {
    return new Promise((resolve, reject) => {
      callApi(apiUrl)
        .headers(true)
        .method("get")
        .isMultipart(false)
        .send((error, result) => {
          if (error) {
            reject(error);
          }

          resolve(result?.data);
        })
    });
  };


  return (
    <Fragment>
      {onLoad===false  ?
      <DataTable
        isSetTableHeader={false}
        dataTableKey={"RenePerForLicTyp"}
        isSetSearchFrom={false}
        isSetAction={false}
        isSetTableFooter={false}
        maxItemCount={2}
        tableHeaderList={[
          {
            displayName: "Licence Type",
            key: "licence_type.name",
            isSort: false,
          },
          {
            displayName: "Calendar Months",
            key: "day",
            default: 0,
            fixWidth: 50,
            isSort: false,
          },
        ]}
        tableBodyList={dataset}
        specificComponents={{
          day: SetDay,
        }}
      />: <section style={{display:'flex', justifyContent:'space-around', flex:1, margin: 50}}>
         <CircularProgress />
    </section>}
    </Fragment>
  );
};
const EditVetCertExem = ({
  name = "",
  fineCalculationType = "",
  inputName = "calenderMonth",
  starting_year = 1975,
  ending_year = 2015,
  value = "",
  formKey = "",
  id = "",
  value_type = "",
  configuration_code = "",
  effective_from = "",
}) => {
  var dataList = [];
  for (var i = starting_year; i <= ending_year; i++) {
    dataList.push({ id: i, value: i });
  }

  return (
    <Fragment>
      <UICard >
        <FormWrapper
          setGroupName={formKey}
          formGroupLinkWith={formKey}
          setFormObject={{
            id: id,
            value_type: value_type,
            value: value,
            configuration_code: configuration_code,
            effective_from: effective_from,
          }}
          onDestroyUnsetFormObject={true}
        >
          <div className="row">
            <div className="col-md-7 col-sm-14 defaultPaddingTop">
              <p> {fineCalculationType} </p>
            </div>
            <div className="col-md-3 col-sm-6 floatRight">
              <SelectBoxWithState
                uiType={templateTypes.bootstrap}
                emptySelectOptionTxt="Select"
                formGroupName={formKey}
                dataList={dataList}
                inputName={"value"}
              />
            </div>
          </div>
        </FormWrapper>
      </UICard>
    </Fragment>
  );
};

const EditWorkConfig = ({
  name = "",
  value = "",
  fieldName = "",
  fineCalculationType = "",
  ending_value = 20,
  starting_value = 1,
  formKey = "",
  id = "",
  value_type = "",
  configuration_code = "",
  effective_from = "",
}) => {
  var dataList = [];
  for (var i = starting_value; i <= ending_value; i++) {
    dataList.push({ id: i, value: i });
  }

  return (
    <Fragment>
      <UICard>
        <FormWrapper
          setGroupName={formKey}
          formGroupLinkWith={formKey}
          setFormObject={{
            id: id,
            value_type: value_type,
            value: value,
            configuration_code: configuration_code,
            effective_from: effective_from,
          }}
          onDestroyUnsetFormObject={true}
        >
          <div className="row">
            <div className="col-md-7 col-sm-14 floatLeft defaultPaddingTop">
              <p> {fineCalculationType} </p>
            </div>
            <div className="col-md-2 col-sm-4 floatLeft">
              <SelectBoxWithState
                uiType={templateTypes.bootstrap}
                formGroupName={formKey}
                dataList={dataList}
                emptySelectOptionTxt="select"
                inputName={"value"}
              />
            </div>
            <div className="col-md-3 col-sm-6 floatLeft defaultPaddingTop">
              <p>{fieldName}</p>
            </div>
          </div>
        </FormWrapper>
      </UICard>
    </Fragment>
  );
};

const ServiceChargeAndTemporaryLicence = ({
  name = "",
  multiline = false,
  fineCalculationType = "",
  formKey = "",
  value = "",
  id = "",
  value_type = "",
  configuration_code = "",
  effective_from = "",
}) => {
  return (
    <Fragment>
      <UICard>
        <FormWrapper
          setGroupName={formKey}
          formGroupLinkWith={formKey}
          setFormObject={{
            id: id,
            value_type: value_type,
            value: value,
            configuration_code: configuration_code,
            effective_from: effective_from,
          }}
          onDestroyUnsetFormObject={true}
        >
          <div className="row fullWidth">
            <div
              className={
                multiline
                  ? "col-md-2 col-sm-2 floatLeft defaultPaddingTop"
                  : "col-md-4 col-sm-8 floatLeft defaultPaddingTop"
              }
            >
              <p> {fineCalculationType} </p>
            </div>
            <div
              className={
                multiline
                  ? "col-md-10 col-sm-20 floatLeft"
                  : "col-md-3 col-sm-6 floatLeft"
              }
            >
              {multiline ? (
                <InputBoxWithState
                  isMultiline={multiline}
                  rows={multiline ? 5 : 1}
                  isRequired={false}
                  uiType={templateTypes.bootstrap}
                  formGroupName={formKey}
                  inputName={"value"}
                  maxLength={multiline ? 200 : 50}
                />
              ) : (
                <NumberInputBoxWithState
                  uiType={templateTypes.bootstrap}
                  formGroupName={formKey}
                  inputName={"value"}
                  isRequired={false}
                  isAllowNegative={false}
                  decimalScale={2}
                  isAllowedFn={(value) => {
                    let checkValue = value.value.toString();

                    if (checkValue.includes(".")) {
                      checkValue = checkValue.split(".")[0];
                    }
                    return checkValue.length <= 6;
                  }}
                />
              )}
            </div>
          </div>
        </FormWrapper>
      </UICard>
    </Fragment>
  );
};

const PopupForm = ({
  name = "",
  fieldName = "",
  fineCalculationType = "",
  formKey = "",
  value_type = "",
  value = "",
  configuration_code = "",
  effective_from = "",
  id = "",
}) => {
  return (
    <Fragment>
      <UICard >
        <FormWrapper
          setGroupName={formKey}
          formGroupLinkWith={formKey}
          setFormObject={{
            id: id,
            value_type: value_type,
            value: value,
            configuration_code: configuration_code,
            effective_from: effective_from,
          }}
          onDestroyUnsetFormObject={true}
        >
          <div className="row">
            <div className="col-md-3 col-sm-6 floatLeft defaultPaddingTop">
              <p> {fineCalculationType} </p>
            </div>
            <div className="col-md-2 col-sm-2 floatLeft">
              <NumberInputBoxWithState
                uiType={templateTypes.bootstrap}
                formGroupName={formKey}
                inputName={"value"}
                isRequired={false}
                isAllowNegative={false}
                isThousandSeparator={false}
                isAllowedFn={(value) => {
                  let checkValue = value.value.toString();

                  if (checkValue.includes(".")) {
                    checkValue = checkValue.split(".")[0];
                  }
                  return checkValue.length <= 2;
                }}
              />
            </div>
            <div className="col-md-3 col-sm-6 floatLeft">
              <SelectBoxWithState
                uiType={templateTypes.bootstrap}
                formGroupName={formKey}
                emptySelectOptionTxt={null}
                dataList={[
                  { id: "CAL", value: "Calendar" },
                  { id: "WRK", value: "Working" },
                ]}
                inputName={"value_type"}
              />
            </div>
            <div className="col-md-4 col-sm-4 floatLeft defaultPaddingTop">
              <p>{fieldName}</p>
            </div>
          </div>
        </FormWrapper>
      </UICard>
    </Fragment>
  );
};

const RenewablePeriodTemporaryLicence = (props) => {
  return (
    <Fragment>
      <FormWrapper
        setGroupName={props.formKey}
        setFormObject={{
          id: props.id,
          value_type: props.value_type,
          value: props.value,
          configuration_code: props.configuration_code,
          effective_from: props.effective_from,
        }}
      >
        <SelectBoxWithState
          isRequired={true}
          formGroupName={props.formKey}
          labelText="Calendar Months"
          inputName={"value"}
          dataList={[
            { id: "0", value: "0" },
            { id: "1", value: "1" },
            { id: "2", value: "2" },
            { id: "3", value: "3" },
            { id: "4", value: "4" },
            { id: "5", value: "5" },
            { id: "6", value: "6" },
            { id: "7", value: "7" },
            { id: "8", value: "8" },
            { id: "9", value: "9" },
            { id: "10", value: "10" },
            { id: "11", value: "11" },
            { id: "12", value: "12" }
          ]}
          keyName="id"
          valueName="value"
          emptySelectOptionTxt="0"
        />
      </FormWrapper>
    </Fragment>
  )
};

export { ConfigForm };
