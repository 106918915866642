import React, { Fragment } from 'react';
import { Navigate } from "../../ui-components/ui-elements/common/Navigate";
import { permissions } from "../../../config/permission.config";
import authorized from "../../hoc-middlewares/Authorized";
import UserAccount from "./pages/UserAccount";


const UserAccountRoutes = () => {
    return (
        <Fragment>
            <Navigate exact={true} path="/user" routeKey={"user"} component={authorized(UserAccount)} routePermissions={permissions.NONE.permissions} />
        </Fragment>
    )
};

export default UserAccountRoutes;