import { callApi } from "./callApi.helpers";
import {
  authorityGetByIdAPI,
  licenceTypeListApi,
  operationTypesListAPI,
  counterAllowedOperationTypesListApi,
  counterAllowedLicenceTypesListApi,
} from "../../config/apiUrl.config";

const authorityChangeFn = (event, formAction, formGroupName) => {
  if (event.value == "null") {
    formAction.changeInputFn(formGroupName, "prefix", "", "");
    formAction.changeInputFn(formGroupName, "authority_type", "", "");
  } else {
    callApi(`${authorityGetByIdAPI.url}/${event.value}`)
      .headers(true)
      .method("get")
      .send((err, result) => {
        if (err) {
          console.log("???????error???????..", err);
        } else {
          if (result) {
            formAction.changeInputFn(formGroupName, "prefix", "", result.data.data.prefix);
            formAction.changeInputFn(formGroupName, "authority_type", "", result.data.data.authority_type.code);
            formAction.changeInputFn(formGroupName, "check_licence_type", null);
            formAction.changeInputFn(formGroupName, "check_operation_type", null);
          } else {
            console.log("???????error2???????..");
          }
        }
      });
  }
};

const operationTypeAllowedFn = (event, formAction, formGroupName) => {
  if (event.value != "null") {
    callApi(`${authorityGetByIdAPI.url}/${event.value}`)
      .headers(true)
      .method("get")
      .send((err, result) => {
        if (err) {
          console.log("???????error1111111111111111???????..", err);
        } else {
          if (result) {
            const operationTypes = [];
            const licenceids = [];
            const operationTypesData = result.data.data.service.operationTypesAllowed;
            const licenceTypesData = result.data.data.service.licenceTypes;
            operationTypesData.forEach((value, index) => {
              const exists = operationTypes.includes(value.operation_type);
              if (!exists) {
                operationTypes.push(value.operation_type);
              }
            });
            licenceTypesData.forEach((value, index) => {
              const exists = licenceids.includes(value.licence_type_id);
              if (!exists) {
                licenceids.push(value.licence_type_id);
              }
            });
            const idString = licenceids.length > 1 ? licenceids.join("&ids=") : licenceids[0];
              callApi(`${licenceTypeListApi.url}/list?ids=${idString}`)
                .headers(true)
                .method("get")
                .send((err, result) => {
                  const operationTypesObj = [];
                  const licenceTypesObj = [];
                  if (err) {
                          console.log("???????error33333333333333333333???????..", err);
                  } else {
                    if (result) {
                      for (var key in result.data.data) {
                        licenceTypesObj.push({
                          id: result.data.data[key].id,
                          name: result.data.data[key].name,
                          code: result.data.data[key].code,
                        });
                      }
                      const codeString = operationTypes.length > 1 ? operationTypes.join("&codes=") : operationTypes[0];
                      
                      callApi(`${operationTypesListAPI.url}?codes=${codeString}`)
                        .headers(true)
                        .method("get")
                        .send((err, result) => {
                          if (err) {
                            console.log("???????error33333333333333333333???????..", err);
                          } else {
                            for (var key in result.data.data) {
                              operationTypesObj.push({
                                code: result.data.data[key].code,
                                name: result.data.data[key].name
                              });
                            }
                            formAction.changeInputFn(formGroupName, "operation_type", "", operationTypesObj);
                            formAction.changeInputFn(formGroupName, "licence_type", "", licenceTypesObj);
                      
                          }
                        });
                    } else {
                      console.log("???????error444444444444444???????..");
                    }
                  }
              });
          } else {
            console.log("???????error666666666666??????..");
          }
        }
      });
  }
};

const operationTypeAllowedEditFn = (event, formAction, formGroupName) => {
  if (event.counter_id != "null") {
    
    callApi(`${counterAllowedOperationTypesListApi.url}/${event.counter_id}/allowed-counter-operation-types`)
      .headers(true)
      .method("get")
      .send((err, result) => {
        if (err) {
          console.log("???????error1111111111111111???????..", err);
        } else {
          if (result) {
            const typeCodes = [];
            const licenceids = [];
            const operationTypesData = result.data.data;
            operationTypesData.forEach((value, index) => {
              const exists = typeCodes.includes(value.operation_type_code);
              if (!exists) {
                typeCodes[value.operation_type_code] = true;
              }
            });
            callApi(`${counterAllowedLicenceTypesListApi.url}/${event.counter_id}/allowed-counter-licence-types/licence-ids`)
              .headers(true)
              .method("get")
              .send((err, result) => {
                if (err) {
                  console.log("???????error2222222222???????..", err);
                } else {
                  if (result) {
                    const licenceTypesData = result.data.data;
                    licenceTypesData.forEach((value, index) => {
                      const exists = licenceids.includes(value.licence_type_id);
                      if (!exists) {
                        licenceids[value.licence_type_id] = true;
                      }
                    });
                    formAction.changeInputFn(formGroupName, "check_licence_type", "", licenceids);
                    formAction.changeInputFn(formGroupName, "check_operation_type", "", typeCodes);
                  } else {
                    console.log("???????error555555555555555??????..");
                  }
                }
              });
          } else {
            console.log("???????error666666666666??????..");
          }
        }
      });
  }
};

const getArraysIntersection = (a1, a2) => {
  return a1.filter(function (n) {
    return a2.indexOf(n) !== -1;
  });
};



export { authorityChangeFn, operationTypeAllowedFn, operationTypeAllowedEditFn };
