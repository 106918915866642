import React, { useEffect } from "react";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { actionBtnTypes, DataTable } from "../../../ui-components/ui-elements/table/DataTable";
import { CreateReasonAPI} from "../../../../config/apiUrl.config";
import { dateObjectToString } from "../../../../helpers/common-helpers/dateTime.helpers";
import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import {ReasonForm,RemoveFrom} from "./includes/ManageReasons"
import { permissions } from "../../../../config/permission.config";
import { CustomButton } from "./includes/CustomButton";
import { SearchElement } from "./includes/SearchElement";
const emptyFn = (...para) => undefined;

const ReasonDetails = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <DashboardTemplate
            pageTitle="Manage Reasons"
            breadCrumbList={[
                { urlTo: "/", displayName: "Home" },
                { urlTo: "", displayName: "Reasons" },
                
            ]}
        >
                <DataTable
                    tableName={"Reasons for Workflow Requests and Other Criteria"}
                    isSetSearchFrom={true}
                    apiUrl={`${CreateReasonAPI.url}/list`}
                    dataTableKey={CreateReasonAPI.key}
                    isSetAction={true}
                    
                    defaultSearchFormObject={{
                        "discription": "",
                        "criteria": "",
                        
                      }}
                      searchFromComponent={
                        <SearchElement dataTableKey={CreateReasonAPI.key} />
                      }
                    tableHeaderList={[ 
                        {displayName: "Reason", key: "name", isSort: false},
                        {displayName: "Created At", key: "created_at", isSort: false, onPrintFn: ({ created_at }) =>
                        dateObjectToString(created_at, "dd/mm/YY"),
                    },
                       
                    ]}
                    actionTypeList={[
                        {
                            actionType: actionBtnTypes.create, 
                            actionBtn: {
                                "permissions": permissions.REASON_ADD.permissions
                            },
                            modelContentElement: ReasonForm,
                            dialogPopupProps: {
                                isFullScreen: true,
                                dialogTitle: "Add Reason",
                                isValidate: true,
                                isConfirmationPopup: true,
                                closeButton: {
                                    btnText: "Cancel",
                                    startIcon: 'far fa-times-circle'
                                },
                                isCustomButton: true,
                                customButtonComponent: CustomButton,
                                customButtonComponentProps:{
                                    "uiType":"create",
                                    dataTableKey:CreateReasonAPI.key,
                                },                                
                            },
                            parentToModelProps: {
                                formKey: CreateReasonAPI.key + "_create",
                                dataTableKey: CreateReasonAPI.key,
                                uiType:"create"
                            }
                            
                        },
                        {
                            actionType: actionBtnTypes.view,
                            actionBtn: {   tooltip: "View", "permissions": permissions.REASON_VIEW.permissions },
                            modelContentElement: ReasonForm,
                            dialogPopupProps: {
                                isFullScreen: true,
                                dialogTitle: "View Reason",
                            },
                            parentToModelProps: {
                                formKey:"_view",
                                type: "view",
                                uiType:"view"
                            },
                        },
                        
                        {
                            actionType: actionBtnTypes.edit,
                            actionBtn: { tooltip: 'Edit', "permissions": permissions.REASON_EDIT.permissions },
                            modelContentElement:  ReasonForm,
                            dialogPopupProps: {
                                isFullScreen: true,
                                dialogTitle: "Edit Reason",
                                isCustomButton: true,
                                isValidate: true,
                                isConfirmationPopup: true,
                                closeButton: {
                                    btnText: "Cancel",
                                    startIcon: 'far fa-times-circle'
                                },
                                customButtonComponent: CustomButton,
                                customButtonComponentProps:{
                                    "uiType":"edit",
                                    dataTableKey: CreateReasonAPI.key,
                                }                                
                            },                   
                            parentToModelProps: {
                                formKey: CreateReasonAPI.key + "_edit",
                                dataTableKey: CreateReasonAPI.key,
                                uiType:"edit"
                            },
                        },
                       
                        {
                            actionType: actionBtnTypes.delete,
                            actionBtn: { tooltip: 'Remove', "permissions": permissions.REASON_DELETE.permissions },
                           
                            isValidate: false,
                            modelContentElement: RemoveFrom,
                            dialogPopupProps: {
                                customButtonComponent: CustomButton,
                                closeButton: {
                                    btnText: "No",
                                    startIcon: "fas fa-times",
                                },
                                isCustomButton: true,
                                isFullScreen: false,
                                dialogTitle: "Please confirm",
                                customButtonComponentProps:{
                                    "uiType":"remove",
                                    dataTableKey: CreateReasonAPI.key,
                                }    
                            },
                            parentToModelProps: {
                                formKey:CreateReasonAPI.key + "_remove",
                                dataTableKey: CreateReasonAPI.key,
                                uiType:"remove"
                            },
                        }
                        
                    ]}
                    
                />
            
        </DashboardTemplate>
    );
};
 


export default ReasonDetails;