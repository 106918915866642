/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Description: Material Text Field Input Wrappers
 * @Date: 2020-01-29 09:46:56
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-08 16:56:44
 */

import React, { memo, Fragment } from "react";
import PropTypes from "prop-types";
import Input from "@material-ui/core/Input";
import NumberFormat from 'react-number-format';

import { FormControlWrapper } from "./form-includes/FormCoreIncludes";
import { InputLabelWrapper, FormHelperTextWrapper } from "./form-includes/FormCoreIncludes";
import { defaultTemplateType, templateTypes, formCacheLevel } from "../../../../config/template.config";
import { useBasicInput } from "../../../hooks/common-hooks/useFormInputState.hook";
import { isEmptyValue } from "../../../../helpers/common-helpers/common.helpers";

const emptyFun = (...para) => undefined;


/**
 * --------------------------------------------
 * @Author: Nisal Madusanka(EruliaF)
 * @Description: Input Box Wrapper
 * @usedIn : <InputBoxWithState/>
 * --------------------------------------------
 */
const InputBox = ({
  uiType = defaultTemplateType,
  elementWrapperStyle = "",
  elementStyle = "",
  isFullWidth = true,
  labelText = "",
  inputType = "text",
  inputValue = "",
  inputName = "",
  inputError = "",
  helperText = "",
  isMultiline = false,
  rows = 1,
  isAutoFocus = false,
  inputPlaceholder = "",
  isDisabled = false,
  isRequired = false,
  maxLength = "",
  onChangeTxtFn = emptyFun,
  onClickElementFn = emptyFun
}) => {
  const getInputProps = () => {
    let obj = {};

    if (!isEmptyValue(maxLength)) {
      obj["maxlength"] = maxLength;
    }

    return obj;
  };

  return (
    <FormControlWrapper
      elementWrapperStyle={`${uiType}InputWrapper ${elementWrapperStyle}`}
      isFullWidth={isFullWidth}
      inputError={inputError}
      helperText={helperText}
      isMultiline={isMultiline}
    >
      <InputLabelWrapper
        uiType={uiType}
        inputName={inputName}
        isRequired={isRequired}
        lableText={labelText}
      />

      <Input
        className={`${uiType}InputText ${elementStyle}`}
        type={inputType}
        value={`${inputValue}`}
        name={inputName}
        autoFocus={isAutoFocus}
        placeholder={inputPlaceholder}
        disabled={isDisabled}
        multiline={isMultiline}
        rows={rows}
        inputProps={getInputProps()}
        onChange={event =>
          onChangeTxtFn({
            name: inputName,
            value: event.target.value,
            eventInfo: event
          })
        }
        onClick={event =>
          onClickElementFn({ name: inputName, eventInfo: event })
        }
      />

      {/* <FormHelperTextWrapper
        inputError={inputError}
        helperText={helperText}
      /> */}

    </FormControlWrapper>
  );
};

/**
 * --------------------------------------------
 * @Author: Nisal Madusanka(EruliaF)
 * @Description: Input Box Wrapper
 * --------------------------------------------
 */
InputBox.propTypes = {
  /** InputBox type */
  uiType: PropTypes.oneOf([templateTypes.material, templateTypes.bootstrap]),
  /** element Wrapper css class */
  elementWrapperStyle: PropTypes.string,
  /** Button element css class */
  elementStyle: PropTypes.string,
  /** is set full width or not */
  isFullWidth: PropTypes.bool,
  /** lable text */
  labelText: PropTypes.string,
  /**  input field type */
  inputType: PropTypes.oneOf(['text', 'password', 'number', 'email']),
  /**  input field type */
  inputValue: PropTypes.string,
  /**  input field name */
  inputName: PropTypes.string,
  /**  input field error message */
  inputError: PropTypes.string,
  /**  input field helper text */
  helperText: PropTypes.string,
  /** is text area or not */
  isMultiline: PropTypes.bool,
  /** is text area or not */
  rows: PropTypes.number,
  /** is auto Focus or not */
  isAutoFocus: PropTypes.bool,
  /** input field placeholder text */
  inputPlaceholder: PropTypes.string,
  /** enable/disabled field */
  isDisabled: PropTypes.bool,
  /** set required * mark */
  isRequired: PropTypes.bool,
  /** onchange text event Function */
  onChangeTxtFn: PropTypes.func,
  /** onclick element Function */
  onClickElementFn: PropTypes.func
};

//----------------InputBox---------------------

/**
 * memo render 
 * @param {Object} prevProps 
 * @param {Object} nextProps 
 */
const areEqual = (prevProps, nextProps) => {
  if (nextProps.cacheLevel === formCacheLevel.none) {
    return false;
  } else if (nextProps.cacheLevel === formCacheLevel.updateOnFormGroupChange) {
    return (
      (prevProps.uiType === nextProps.uiType) && (prevProps.elementWrapperStyle === nextProps.elementWrapperStyle) &&
      (prevProps.elementStyle === nextProps.elementStyle) && (prevProps.isFullWidth === nextProps.isFullWidth) &&
      (prevProps.labelText === nextProps.labelText) && (prevProps.inputType === nextProps.inputType) &&
      (prevProps.inputError === nextProps.inputError) && (prevProps.helperText === nextProps.helperText) &&
      (prevProps.isMultiline === nextProps.isMultiline) && (prevProps.rows === nextProps.rows) &&
      (prevProps.isDisabled === nextProps.isDisabled) && (prevProps.isRequired === nextProps.isRequired) &&
      (prevProps.inputValue === nextProps.inputValue) && (prevProps.updateStatus === nextProps.updateStatus) &&
      (prevProps.maxLength === nextProps.maxLength)
    )
  } else if (nextProps.cacheLevel === formCacheLevel.updateOnIndividual) {
    return (
      (prevProps.uiType === nextProps.uiType) && (prevProps.elementWrapperStyle === nextProps.elementWrapperStyle) &&
      (prevProps.elementStyle === nextProps.elementStyle) && (prevProps.isFullWidth === nextProps.isFullWidth) &&
      (prevProps.labelText === nextProps.labelText) && (prevProps.inputType === nextProps.inputType) &&
      (prevProps.inputError === nextProps.inputError) && (prevProps.helperText === nextProps.helperText) &&
      (prevProps.isMultiline === nextProps.isMultiline) && (prevProps.rows === nextProps.rows) &&
      (prevProps.isDisabled === nextProps.isDisabled) && (prevProps.isRequired === nextProps.isRequired) &&
      (prevProps.inputValue === nextProps.inputValue) && (prevProps.maxLength === nextProps.maxLength)
    )
  }

}

const InputBoxMemo = memo(InputBox, areEqual);

/**
 * -------------InputBoxWithState--------------
 * --------------------------------------------
 * @Author: Nisal Madusanka(EruliaF)
 * @Description: Input Box Wrapper
 * @usedIn : 
 * --------------------------------------------
 */

const InputBoxWithState = ({
  uiType = defaultTemplateType,
  elementWrapperStyle = "",
  elementStyle = "",
  isFullWidth = true,
  labelText = "",
  inputType = "text",
  inputValue = "",
  inputName = "",
  inputError = "",
  helperText = "",
  isMultiline = false,
  rows = 1,
  isAutoFocus = false,
  inputPlaceholder = "",
  isDisabled = false,
  isRequired = false,
  formGroupName = "",
  inputStatePath = "",
  cacheLevel = formCacheLevel.updateOnFormGroupChange,
  isInputValueCheck = false,
  regexPattern = null,
  customRegexErrorMessage = "",
  maxLength = "",
  onChangeTxtFn = emptyFun,
  onClickElementFn = emptyFun
}) => {
  const [currentValue, currentError, updateStatus, handleOnChangeFn, handleOnClickFn] = useBasicInput(inputStatePath, formGroupName, inputName, inputValue, onChangeTxtFn, onClickElementFn, isInputValueCheck, regexPattern, customRegexErrorMessage);

  return (
    <InputBoxMemo
      uiType={uiType}
      elementWrapperStyle={elementWrapperStyle}
      elementStyle={elementStyle}
      isFullWidth={isFullWidth}
      labelText={labelText}
      inputType={inputType}
      inputValue={currentValue}
      inputName={inputName}
      inputError={currentError}
      helperText={helperText}
      isMultiline={isMultiline}
      rows={rows}
      isAutoFocus={isAutoFocus}
      inputPlaceholder={inputPlaceholder}
      isDisabled={isDisabled}
      isRequired={isRequired}
      onChangeTxtFn={(eventData) => handleOnChangeFn(eventData)}
      onClickElementFn={handleOnClickFn}
      updateStatus={updateStatus}
      cacheLevel={cacheLevel}
      maxLength={maxLength}
    />
  )
};

InputBoxWithState.propTypes = {
  /** InputBox type */
  uiType: PropTypes.oneOf([templateTypes.material, templateTypes.bootstrap]),
  /** element Wrapper css class */
  elementWrapperStyle: PropTypes.string,
  /** Button element css class */
  elementStyle: PropTypes.string,
  /** is set full width or not */
  isFullWidth: PropTypes.bool,
  /** lable text */
  labelText: PropTypes.string,
  /**  input field type */
  inputType: PropTypes.oneOf(['text', 'password', 'number', 'email']),
  /**  input field type */
  inputValue: PropTypes.string,
  /**  input field name */
  inputName: PropTypes.string,
  /**  input field error message */
  inputError: PropTypes.string,
  /**  input field helper text */
  helperText: PropTypes.string,
  /** is text area or not */
  isMultiline: PropTypes.bool,
  /** is text area or not */
  rows: PropTypes.number,
  /** is auto Focus or not */
  isAutoFocus: PropTypes.bool,
  /** input field placeholder text */
  inputPlaceholder: PropTypes.string,
  /** enable/disabled field */
  isDisabled: PropTypes.bool,
  /** set required * mark */
  isRequired: PropTypes.bool,
  /** form group name */
  formGroupName: PropTypes.string,
  /** input element state avilable path(use for manage complex objects) */
  inputStatePath: PropTypes.string,
  /** onchange text event Function */
  onChangeTxtFn: PropTypes.func,
  /** onclick element Function */
  onClickElementFn: PropTypes.func
};

//-------------InputBoxWithState--------------

const HorizontalInput = ({
  uiType = defaultTemplateType,
  elementWrapperStyle = "",
  elementStyle = "",
  horiLableStyle = "",
  horiInputStyle = "",
  isFullWidth = true,
  labelText = "",
  inputType = "text",
  inputValue = "",
  inputName = "",
  inputError = "",
  helperText = "",
  isMultiline = false,
  rows = 1,
  isAutoFocus = false,
  inputPlaceholder = "",
  isDisabled = false,
  isRequired = false,
  formGroupName = "",
  inputStatePath = "",
  cacheLevel = formCacheLevel.updateOnFormGroupChange,
  onChangeTxtFn = emptyFun,
  onClickElementFn = emptyFun
}) => {
  //const [currentValue,currentError,updateStatus,handleOnChangeFn,handleOnClickFn]=useBasicInput(inputStatePath,formGroupName,inputName,inputValue,onChangeTxtFn,onClickElementFn);
  const [currentError] = useBasicInput(inputStatePath, formGroupName, inputName, inputValue, onChangeTxtFn, onClickElementFn);

  return (
    <div className="horizontalInput ">
      <label className={`${horiLableStyle} floatLeft paddingNone`} htmlFor={inputName}>{labelText}</label>
      <div className={`${horiInputStyle} floatLeft inptutElementWrapper paddingNone`}>
        <input type={inputType} className="form-control paddingNone" id={inputName} placeholder={inputPlaceholder} />
        <p className={`helperText ${currentError ? "errorTxt" : ""}`}>{currentError ? currentError : helperText}</p>
      </div>
    </div>
  );
}

const InputTest = ({
  uiType = defaultTemplateType,
  elementWrapperStyle = "",
  elementStyle = "",
  horiLableStyle = "",
  horiInputStyle = "",
  isFullWidth = true,
  labelText = "",
  inputType = "text",
  inputValue = "",
  inputName = "",
  inputError = "",
  helperText = "",
  isMultiline = false,
  rows = 1,
  isAutoFocus = false,
  inputPlaceholder = "",
  isDisabled = false,
  isRequired = false,
  formGroupName = "",
  inputStatePath = "",
  isHorizontal = false,
  cacheLevel = formCacheLevel.updateOnFormGroupChange,
  onChangeTxtFn = emptyFun,
  onClickElementFn = emptyFun
}) => {
  return (
    <Fragment>
      {
        (isHorizontal === false) ? (
          <InputBoxWithState
            uiType={uiType}
            elementWrapperStyle={elementWrapperStyle}
            elementStyle={elementStyle}
            isFullWidth={isFullWidth}
            labelText={labelText}
            inputType={inputType}
            inputValue={inputValue}
            inputName={inputName}
            inputError={inputError}
            helperText={helperText}
            isMultiline={isMultiline}
            rows={rows}
            isAutoFocus={isAutoFocus}
            inputPlaceholder={inputPlaceholder}
            isDisabled={isDisabled}
            isRequired={isRequired}
            formGroupName={formGroupName}
            inputStatePath={inputStatePath}
            cacheLevel={cacheLevel}
            onChangeTxtFn={onChangeTxtFn}
            onClickElementFn={onClickElementFn}
          />
        ) : (
          <HorizontalInput
            uiType={uiType}
            elementWrapperStyle={elementWrapperStyle}
            elementStyle={elementStyle}
            horiLableStyle={horiLableStyle}
            horiInputStyle={horiInputStyle}
            isFullWidth={isFullWidth}
            labelText={labelText}
            inputType={inputType}
            inputValue={inputValue}
            inputName={inputName}
            inputError={inputError}
            helperText={helperText}
            isMultiline={isMultiline}
            rows={rows}
            isAutoFocus={isAutoFocus}
            inputPlaceholder={inputPlaceholder}
            isDisabled={isDisabled}
            isRequired={isRequired}
            formGroupName={formGroupName}
            inputStatePath={inputStatePath}
            cacheLevel={cacheLevel}
            onChangeTxtFn={onChangeTxtFn}
            onClickElementFn={onClickElementFn}
          />
        )
      }
    </Fragment>
  )
};

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Number Input Box Wrapper
 * @usedIn : <NumberInputBoxWithState/>
 * --------------------------------------------
 */
const NumberInputBox = ({
  uiType = defaultTemplateType,
  elementWrapperStyle = "",
  elementStyle = "",
  isFullWidth = true,
  labelText = "",
  inputValue = "",
  inputName = "",
  inputError = "",
  helperText = "",
  isAutoFocus = false,
  inputPlaceholder = "",
  isDisabled = false,
  isRequired = false,
  isThousandSeparator = true,
  isNumericString = true,
  isAllowNegative = true,
  isAllowLeadingZeros = false,
  prefix = undefined,
  suffix = undefined,
  format = undefined,
  decimalScale = undefined,
  isFixedDecimalScale = false,
  decimalSeparator = ".",
  isAllowedFn = emptyFun,
  onChangeTxtFn = emptyFun
}) => {
  return (
      <FormControlWrapper
          elementWrapperStyle={`${uiType}InputWrapper ${elementWrapperStyle}`}
          isFullWidth={isFullWidth}
          inputError={inputError}
          helperText={helperText}
      >
        <InputLabelWrapper
            uiType={uiType}
            inputName={inputName}
            isRequired={isRequired}
            lableText={labelText}
        />

        <Input
            className={`${uiType}InputText ${elementStyle}`}
            value={`${inputValue}`}
            name={inputName}
            placeholder={inputPlaceholder}
            disabled={isDisabled}
            onChange={onChangeTxtFn}
            autoFocus={isAutoFocus}
            inputComponent={NumberFormatCustom}
            inputProps={{
              thousandSeparator: isThousandSeparator,
              prefix: prefix,
              suffix: suffix,
              decimalSeparator: decimalSeparator,
              isNumericString: isNumericString,
              format: format,
              allowNegative: isAllowNegative,
              allowLeadingZeros: isAllowLeadingZeros,
              decimalScale: decimalScale,
              fixedDecimalScale: isFixedDecimalScale,
              isAllowed: isAllowedFn
            }}
        />
      </FormControlWrapper>
  );
};

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Number Format Component
 * @usedIn : <NumberInputBox/>
 * --------------------------------------------
 */
const NumberFormatCustom = (props) => {
  const { inputRef, name, onChange, ...other } = props;

  return (
      <NumberFormat
          {...other}
          getInputRef={inputRef}
          onValueChange={(event) => {
            onChange({
              name: name,
              value: event.value,
              eventInfo: event
            })
          }}
      />
  );
};

/**
 * memo render
 * @param {Object} prevProps
 * @param {Object} nextProps
 */
const areEqualForNumberInput = (prevProps, nextProps) => {
  if (nextProps.cacheLevel === formCacheLevel.none) {
    return false;
  } else if (nextProps.cacheLevel === formCacheLevel.updateOnFormGroupChange) {
    return (
        (prevProps.uiType === nextProps.uiType) && (prevProps.elementWrapperStyle === nextProps.elementWrapperStyle) &&
        (prevProps.elementStyle === nextProps.elementStyle) && (prevProps.isFullWidth === nextProps.isFullWidth) &&
        (prevProps.labelText === nextProps.labelText) && (prevProps.inputError === nextProps.inputError) &&
        (prevProps.helperText === nextProps.helperText) && (prevProps.isDisabled === nextProps.isDisabled) &&
        (prevProps.isRequired === nextProps.isRequired) && (prevProps.inputValue === nextProps.inputValue) &&
        (prevProps.updateStatus === nextProps.updateStatus) && (prevProps.isThousandSeparator === nextProps.isThousandSeparator) &&
        (prevProps.isNumericString === nextProps.isNumericString) && (prevProps.isAllowNegative === nextProps.isAllowNegative) &&
        (prevProps.isAllowLeadingZeros === nextProps.isAllowLeadingZeros) && (prevProps.prefix === nextProps.prefix) &&
        (prevProps.suffix === nextProps.suffix) && (prevProps.format === nextProps.format) &&
        (prevProps.decimalScale === nextProps.decimalScale) && (prevProps.isFixedDecimalScale === nextProps.isFixedDecimalScale) &&
        (prevProps.decimalSeparator === nextProps.decimalSeparator) && (prevProps.isAllowedFn === nextProps.isAllowedFn)
    )
  } else if (nextProps.cacheLevel === formCacheLevel.updateOnIndividual) {
    return (
        (prevProps.uiType === nextProps.uiType) && (prevProps.elementWrapperStyle === nextProps.elementWrapperStyle) &&
        (prevProps.elementStyle === nextProps.elementStyle) && (prevProps.isFullWidth === nextProps.isFullWidth) &&
        (prevProps.labelText === nextProps.labelText) && (prevProps.inputError === nextProps.inputError) &&
        (prevProps.helperText === nextProps.helperText) && (prevProps.isDisabled === nextProps.isDisabled) &&
        (prevProps.isRequired === nextProps.isRequired) && (prevProps.inputValue === nextProps.inputValue) &&
        (prevProps.updateStatus === nextProps.updateStatus) && (prevProps.isThousandSeparator === nextProps.isThousandSeparator) &&
        (prevProps.isNumericString === nextProps.isNumericString) && (prevProps.isAllowNegative === nextProps.isAllowNegative) &&
        (prevProps.isAllowLeadingZeros === nextProps.isAllowLeadingZeros) && (prevProps.prefix === nextProps.prefix) &&
        (prevProps.suffix === nextProps.suffix) && (prevProps.format === nextProps.format) &&
        (prevProps.decimalScale === nextProps.decimalScale) && (prevProps.isFixedDecimalScale === nextProps.isFixedDecimalScale) &&
        (prevProps.decimalSeparator === nextProps.decimalSeparator) && (prevProps.isAllowedFn === nextProps.isAllowedFn)
    )
  }
};

const NumberInputBoxMemo = memo(NumberInputBox, areEqualForNumberInput);

/**
 * -------------NumberInputBoxWithState--------------
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Input Box Wrapper
 * @usedIn :
 * --------------------------------------------
 */
const isAllowedEmptyFn = (value) => true;

const NumberInputBoxWithState = ({
  uiType = defaultTemplateType,
  elementWrapperStyle = "",
  elementStyle = "",
  isFullWidth = true,
  labelText = "",
  inputValue = "",
  inputName = "",
  inputError = "",
  helperText = "",
  isAutoFocus = false,
  inputPlaceholder = "",
  isDisabled = false,
  isRequired = false,
  formGroupName = "",
  inputStatePath = "",
  cacheLevel = formCacheLevel.updateOnFormGroupChange,
  isThousandSeparator = true,
  isNumericString = true,
  isAllowNegative = true,
  isAllowLeadingZeros = false,
  prefix = undefined,
  suffix = undefined,
  format = undefined,
  decimalScale = undefined,
  isFixedDecimalScale = false,
  decimalSeparator = ".",
  isAllowedFn = isAllowedEmptyFn,
  onChangeTxtFn = emptyFun
}) => {
  const [currentValue, currentError, updateStatus, handleOnChangeFn] = useBasicInput(inputStatePath, formGroupName, inputName, inputValue, onChangeTxtFn);

  return (
      <NumberInputBoxMemo
          uiType={uiType}
          elementWrapperStyle={elementWrapperStyle}
          elementStyle={elementStyle}
          isFullWidth={isFullWidth}
          labelText={labelText}
          inputValue={currentValue}
          inputName={inputName}
          inputError={currentError}
          helperText={helperText}
          isAutoFocus={isAutoFocus}
          inputPlaceholder={inputPlaceholder}
          isDisabled={isDisabled}
          isRequired={isRequired}
          onChangeTxtFn={(eventData) => handleOnChangeFn(eventData)}
          updateStatus={updateStatus}
          cacheLevel={cacheLevel}
          isThousandSeparator={isThousandSeparator}
          isNumericString={isNumericString}
          isAllowNegative={isAllowNegative}
          isAllowLeadingZeros={isAllowLeadingZeros}
          prefix={prefix}
          suffix={suffix}
          format={format}
          decimalScale={decimalScale}
          isFixedDecimalScale={isFixedDecimalScale}
          decimalSeparator={decimalSeparator}
          isAllowedFn={isAllowedFn}
      />
  )
};

export {
  InputBox,
  InputBoxWithState,
  InputBoxMemo,
  formCacheLevel,
  InputTest,
  NumberInputBox,
  NumberInputBoxMemo,
  NumberInputBoxWithState
};
