import React from "react";
import {
    FormWrapper,
    SelectBoxWithState,
    InputBoxWithState,
} from "../../../../ui-components/ui-elements/form";

const SearchElement = ({ dataTableKey = "" }) => {
    const formKey = `${dataTableKey}_search`;
    return (
        <FormWrapper setGroupName={formKey}>
            <div className="row">
                <div className="col-md-2 col-sm-12 floatLeft">
                    <InputBoxWithState
                        formGroupName={formKey}
                        inputName={"name"}
                        maxLength={50}
                        labelText="Search"
                    />
                </div>

                <div className="col-md-2 col-sm-12 floatLeft">
                    <SelectBoxWithState
                        emptySelectOptionTxt="All"
                        formGroupName={formKey}
                        dataList={[
                            {
                                id: "true",
                                value: "Active",
                            },
                            {
                                id: "false",
                                value: "Inactive",
                            },
                        ]}
                        inputName={"active"}
                        labelText="Status"
                        isEnableAllOption={true}
                    />
                </div>
            </div>
        </FormWrapper>
    );
};
export {
    SearchElement
}