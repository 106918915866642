/*
 * @Author: Chanaka Wickramasinghe
 * @Description: Material Date Picker
 * @Date: 2020-03-02 16:52:42
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-05-29 10:56:25
 */

import React, { memo } from 'react';
import PropTypes from "prop-types";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker, KeyboardTimePicker } from '@material-ui/pickers';

import { defaultTemplateType, templateTypes, formCacheLevel } from "../../../../config/template.config";
import { FormControlWrapper, InputLabelWrapper } from "./form-includes/FormCoreIncludes";
import { FormHelperTextWrapper } from "./form-includes/FormCoreIncludes";
import { useBasicInput } from "../../../hooks/common-hooks/useFormInputState.hook";
import { TextField } from '@material-ui/core';


const emptyFun = (...para) => undefined;

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: DatePicker
 * --------------------------------------------
 */

// DatePicker variants
const datePickerVariants = {
    dialog: "dialog",
    inline: "inline",
    static: "static"
};

// DatePicker formats
const datePickerFormats = {
    "yyyy-MM-dd": "yyyy-MM-dd",
    "dd-MM-yyyy": "dd-MM-yyyy",
    "yyyy/MM/dd": "yyyy/MM/dd",
    "dd/MM/yyyy": "dd/MM/yyyy",
    "MM/dd/yyyy": "MM/dd/yyyy",
    "MM-dd-yyyy": "MM-dd-yyyy"
};

// DateTimePicker formats
const dateTimePickerFormats = {
    "yyyy-MM-dd": "yyyy-MM-dd hh:mm a",
    "dd-MM-yyyy": "dd-MM-yyyy hh:mm a",
    "yyyy/MM/dd": "yyyy/MM/dd hh:mm a",
    "dd/MM/yyyy": "dd/MM/yyyy hh:mm a",
    "MM/dd/yyyy": "MM/dd/yyyy hh:mm a",
    "MM-dd-yyyy": "MM-dd-yyyy hh:mm a"
};

const DatePicker = ({
    uiType = defaultTemplateType,
    elementWrapperStyle = "",
    elementStyle = "",
    isDisabled = false,
    isRequired = false,
    isFullWidth = true,
    isAllowedKeyboardInputs = false,
    isLabelShrink = true,
    inputName = "",
    inputValue = new Date(),
    labelText = "",
    inputError = "",
    helperText = "",
    variant = datePickerVariants.dialog,
    format = datePickerFormats['dd/MM/yyyy'],
    onChangeFn = emptyFun,
    minDate = "",
    minDateMessage="",
}) => {
    return (
        <FormControlWrapper
            elementWrapperStyle={`${uiType}InputWrapper ${elementWrapperStyle}`}
            isFullWidth={isFullWidth}
            inputError={inputError}
        >

            <MuiPickersUtilsProvider utils={DateFnsUtils}>

                {/*<TextField*/}
                {/*    id="date"*/}
                {/*    label="Birthday"*/}
                {/*    type="date"*/}
                {/*    defaultValue="2017-05-24"*/}
                {/*    //className={classes.textField}*/}
                {/*    InputLabelProps={{*/}
                {/*        shrink: true,*/}
                {/*    }}*/}
                {/*/>*/}

                <KeyboardDatePicker
                    className={`${uiType}DatePickerStyle ${elementStyle} ${(inputError !== "") ? "datePickerError" : ""}`}
                    disableToolbar={isDisabled}
                    id={inputName}
                    fullWidth={isFullWidth}
                    variant={variant}
                    format={format}
                    margin="normal"
                    label={isRequired ? (labelText + ' *') : (labelText)}
                    value={inputValue}
                    onChange={(date) => onChangeFn({ name: inputName, value: date })}
                    minDate={minDate}
                    InputProps={{
                        readOnly: !isAllowedKeyboardInputs,
                    }}
                    InputLabelProps={{
                        shrink: isLabelShrink,
                    }}
                    minDateMessage={minDateMessage}
                />

                <FormHelperTextWrapper
                    inputError={inputError}
                    helperText={helperText}
                />

            </MuiPickersUtilsProvider>

        </FormControlWrapper>
    );
};

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: DatePicker
 * --------------------------------------------
 */

DatePicker.propTypes = {
    /** DatePicker type */
    uiType: PropTypes.oneOf([templateTypes.material, templateTypes.bootstrap]),
    /** DatePicker element css class */
    elementStyle: PropTypes.string,
    /** Is disable toolbar */
    isDisableToolbar: PropTypes.bool,
    /** Is full width true or false */
    isFullWidth: PropTypes.bool,
    /** Input name */
    inputName: PropTypes.string,
    /** Input value */
    inputValue: PropTypes.object,
    /** Label text */
    labelText: PropTypes.string,
    /** Input error */
    inputError: PropTypes.string,
    /** Helper text */
    helperText: PropTypes.string,
    /** Variant */
    variant: PropTypes.oneOf([datePickerVariants.dialog, datePickerVariants.inline, datePickerVariants.static]),
    /** Formats */
    format: PropTypes.oneOf([
        datePickerFormats["yyyy-MM-dd"],
        datePickerFormats["dd-MM-yyyy"],
        datePickerFormats["dd/MM/yyyy"],
        datePickerFormats["yyyy/MM/dd"],
        datePickerFormats["MM/dd/yyyy"],
        datePickerFormats["MM-dd-yyyy"]
    ]),
    /** Onchange function */
    onChangeFn: PropTypes.func
};

//----------------DatePicker---------------------

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: DateTimePicker
 * --------------------------------------------
 */

const DateTimePicker = ({
    uiType = defaultTemplateType,
    elementWrapperStyle = "",
    elementStyle = "",
    isDisabled = false,
    isRequired = false,
    isFullWidth = true,
    inputName = "",
    inputValue = new Date(),
    labelText = "",
    inputError = "",
    helperText = "",
    variant = datePickerVariants.dialog,
    format = dateTimePickerFormats['dd/MM/yyyy'],
    onChangeFn = emptyFun,
    minDate = "",
    minDateMessage = ""
}) => {
    return (
        <FormControlWrapper
            elementWrapperStyle={`${uiType}InputWrapper ${elementWrapperStyle}`}
            isFullWidth={isFullWidth}
            inputError={inputError}
        >
            <InputLabelWrapper
                isRequired={isRequired}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>

                <KeyboardDateTimePicker
                    className={`${uiType}DatePickerStyle ${elementStyle} ${(inputError !== "") ? "datePickerError" : ""}`}
                    disableToolbar={isDisabled}
                    id={inputName}
                    fullWidth={isFullWidth}
                    variant={variant}
                    format={format}
                    margin="normal"
                    label={labelText}
                    value={inputValue}
                    onChange={(date) => onChangeFn({ name: inputName, value: date })}
                    minDate={minDate}
                    minDateMessage={minDateMessage}
                />

                <FormHelperTextWrapper
                    inputError={inputError}
                    helperText={helperText}
                />

            </MuiPickersUtilsProvider>

        </FormControlWrapper>
    );
};

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: DateTimePicker
 * --------------------------------------------
 */

DateTimePicker.propTypes = {
    /** DatePicker type */
    uiType: PropTypes.oneOf([templateTypes.material, templateTypes.bootstrap]),
    /** DatePicker element css class */
    elementStyle: PropTypes.string,
    /** Is disable toolbar */
    isDisableToolbar: PropTypes.bool,
    /** Is full width true or false */
    isFullWidth: PropTypes.bool,
    /** Input name */
    inputName: PropTypes.string,
    /** Input value */
    inputValue: PropTypes.object,
    /** Label text */
    labelText: PropTypes.string,
    /** Input error */
    inputError: PropTypes.string,
    /** Helper text */
    helperText: PropTypes.string,
    /** Variant */
    variant: PropTypes.oneOf([datePickerVariants.dialog, datePickerVariants.inline, datePickerVariants.static]),
    /** Formats */
    format: PropTypes.oneOf([
        dateTimePickerFormats["yyyy-MM-dd"],
        dateTimePickerFormats["dd-MM-yyyy"],
        dateTimePickerFormats["dd/MM/yyyy"],
        dateTimePickerFormats["yyyy/MM/dd"],
        dateTimePickerFormats["MM/dd/yyyy"],
        dateTimePickerFormats["MM-dd-yyyy"]
    ]),
    /** Onchange function */
    onChangeFn: PropTypes.func
};

//----------------DateTimePicker---------------------

//----------------TimePicker---------------------
const TimePicker = ({
    uiType = defaultTemplateType,
    elementWrapperStyle = "",
    elementStyle = "",
    isDisabled = false,
    isRequired = false,
    isFullWidth = true,
    inputName = "",
    inputValue = new Date(),
    labelText = "",
    inputError = "",
    helperText = "",
    variant = datePickerVariants.dialog,
    format = datePickerFormats['dd/MM/yyyy'],
    onChangeFn = emptyFun
}) => {
    console.log("TimePicker", inputValue)
    return (
        <FormControlWrapper
            elementWrapperStyle={`${uiType}InputWrapper ${elementWrapperStyle}`}
            isFullWidth={isFullWidth}
            inputError={inputError}
        >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>

                <KeyboardTimePicker
                    className={`${uiType}DatePickerStyle ${elementStyle} ${(inputError !== "") ? "datePickerError" : ""}`}
                    disableToolbar={isDisabled}
                    id={inputName}
                    fullWidth={isFullWidth}
                    variant={variant}
                    margin="normal"
                    label={labelText}
                    value={inputValue}
                    onChange={(date) => onChangeFn({ name: inputName, value: date })}

                />

                <FormHelperTextWrapper
                    inputError={inputError}
                    helperText={helperText}
                />

            </MuiPickersUtilsProvider>

        </FormControlWrapper>
    );
};
//----------------TimePicker---------------------

/**
 * memo render 
 * @param {Object} prevProps 
 * @param {Object} nextProps 
 */
const areEqual = (prevProps, nextProps) => {
    if (nextProps.cacheLevel === formCacheLevel.none) {
        return false;
    } else if (nextProps.cacheLevel === formCacheLevel.updateOnFormGroupChange) {
        return (
            (prevProps.uiType === nextProps.uiType) &&
            (prevProps.elementStyle === nextProps.elementStyle) && (prevProps.isFullWidth === nextProps.isFullWidth) &&
            (prevProps.labelText === nextProps.labelText) && (prevProps.isAllowedKeyboardInputs === nextProps.isAllowedKeyboardInputs) &&
            (prevProps.inputError === nextProps.inputError) && (prevProps.helperText === nextProps.helperText) &&
            (prevProps.isDisabled === nextProps.isDisabled) && (prevProps.isRequired === nextProps.isRequired) &&
            (prevProps.inputValue === nextProps.inputValue) && (prevProps.isLabelShrink === nextProps.isLabelShrink) &&
            (prevProps.updateStatus === nextProps.updateStatus)
        )
    } else if (nextProps.cacheLevel === formCacheLevel.updateOnIndividual) {
        return (
            (prevProps.uiType === nextProps.uiType) &&
            (prevProps.elementStyle === nextProps.elementStyle) && (prevProps.isFullWidth === nextProps.isFullWidth) &&
            (prevProps.labelText === nextProps.labelText) && (prevProps.isAllowedKeyboardInputs === nextProps.isAllowedKeyboardInputs) &&
            (prevProps.inputError === nextProps.inputError) && (prevProps.helperText === nextProps.helperText) &&
            (prevProps.isDisabled === nextProps.isDisabled) && (prevProps.isRequired === nextProps.isRequired) &&
            (prevProps.inputValue === nextProps.inputValue) && (prevProps.isLabelShrink === nextProps.isLabelShrink)
        )
    }

};

const DatePickerMemo = memo(DatePicker, areEqual);
const DateTimePickerMemo = memo(DateTimePicker, areEqual);
const TimePickerMemo = memo(TimePicker, areEqual);

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: DatePicker With State
 * --------------------------------------------
 */

const DatePickerWithState = ({
    uiType = defaultTemplateType,
    elementStyle = "",
    isDisabled = false,
    isRequired = false,
    isFullWidth = true,
    isAllowedKeyboardInputs = false,
    isLabelShrink = true,
    inputName = "",
    inputValue = new Date(),
    labelText = "",
    inputError = "",
    helperText = "",
    variant = datePickerVariants.dialog,
    format = datePickerFormats['dd/MM/yyyy'],
    formGroupName = "",
    inputStatePath = "",
    cacheLevel = formCacheLevel.updateOnFormGroupChange,
    onChangeFn = emptyFun,
    minDate = "1970-01-01",
    minDateMessage = "Date should not be before minimal date"

}) => {

    const [currentValue, currentError, updateStatus, handleOnChangeFn] = useBasicInput(inputStatePath, formGroupName, inputName, inputValue, onChangeFn);

    return (
        <DatePickerMemo
            uiType={uiType}
            elementStyle={elementStyle}
            isDisabled={isDisabled}
            isRequired={isRequired}
            isFullWidth={isFullWidth}
            isAllowedKeyboardInputs={isAllowedKeyboardInputs}
            isLabelShrink={isLabelShrink}
            inputName={inputName}
            inputValue={currentValue}
            labelText={labelText}
            inputError={currentError}
            helperText={helperText}
            variant={variant}
            format={format}
            onChangeFn={(eventData => {
                handleOnChangeFn(eventData);
            })}
            updateStatus={updateStatus}
            cacheLevel={cacheLevel}
            minDate={minDate}
            minDateMessage={minDateMessage}
        />
    );
};

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: DatePicker With State
 * --------------------------------------------
 */

DatePickerWithState.propTypes = {
    /** DatePicker type */
    uiType: PropTypes.oneOf([templateTypes.material, templateTypes.bootstrap]),
    /** DatePicker element css class */
    elementStyle: PropTypes.string,
    /** Is disable toolbar */
    isDisableToolbar: PropTypes.bool,
    /** Is full width true or false */
    isFullWidth: PropTypes.bool,
    /** Input name */
    inputName: PropTypes.string,
    /** Input value */
    inputValue: PropTypes.object,
    /** Label text */
    labelText: PropTypes.string,
    /** Input error */
    inputError: PropTypes.string,
    /** Helper text */
    helperText: PropTypes.string,
    /** Variant */
    variant: PropTypes.oneOf([datePickerVariants.dialog, datePickerVariants.inline, datePickerVariants.static]),
    /** Formats */
    format: PropTypes.oneOf([
        datePickerFormats["yyyy-MM-dd"],
        datePickerFormats["dd-MM-yyyy"],
        datePickerFormats["dd/MM/yyyy"],
        datePickerFormats["yyyy/MM/dd"],
        datePickerFormats["MM/dd/yyyy"],
        datePickerFormats["MM-dd-yyyy"]
    ]),
    /** Form group name */
    formGroupName: PropTypes.string,
    /** Input element state avilable path(use for manage complex objects) */
    inputStatePath: PropTypes.string,
    /** Onchange function */
    onChangeFn: PropTypes.func
};

//----------------DatePickerWithState---------------------

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: DateTimePicker With State
 * --------------------------------------------
 */

const DateTimePickerWithState = ({
    uiType = defaultTemplateType,
    elementStyle = "",
    isDisabled = false,
    isRequired = false,
    isFullWidth = true,
    inputName = "",
    inputValue = new Date(),
    labelText = "",
    inputError = "",
    helperText = "",
    variant = datePickerVariants.dialog,
    format = dateTimePickerFormats['dd/MM/yyyy'],
    formGroupName = "",
    inputStatePath = "",
    cacheLevel = formCacheLevel.updateOnFormGroupChange,
    onChangeFn = emptyFun,
    minDate = "",
    minDateMessage = "Date should not be before minimal date"
}) => {
    const [currentValue, currentError, updateStatus, handleOnChangeFn] = useBasicInput(inputStatePath, formGroupName, inputName, inputValue, onChangeFn);

    return (
        <DateTimePickerMemo
            uiType={uiType}
            elementStyle={elementStyle}
            isDisabled={isDisabled}
            isRequired={isRequired}
            isFullWidth={isFullWidth}
            inputName={inputName}
            inputValue={currentValue}
            labelText={labelText}
            inputError={currentError}
            helperText={'helperText'}
            variant={variant}
            format={format}
            onChangeFn={(eventData => {
                handleOnChangeFn(eventData);
            })}
            updateStatus={updateStatus}
            cacheLevel={cacheLevel}
            minDate={minDate}
            minDateMessage={minDateMessage}
        />
    );
};

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: DateTimePicker With State
 * --------------------------------------------
 */

DateTimePickerWithState.propTypes = {
    /** DatePicker type */
    uiType: PropTypes.oneOf([templateTypes.material, templateTypes.bootstrap]),
    /** DatePicker element css class */
    elementStyle: PropTypes.string,
    /** Is disable toolbar */
    isDisableToolbar: PropTypes.bool,
    /** Is full width true or false */
    isFullWidth: PropTypes.bool,
    /** Input name */
    inputName: PropTypes.string,
    /** Input value */
    inputValue: PropTypes.object,
    /** Label text */
    labelText: PropTypes.string,
    /** Input error */
    inputError: PropTypes.string,
    /** Helper text */
    helperText: PropTypes.string,
    /** Variant */
    variant: PropTypes.oneOf([datePickerVariants.dialog, datePickerVariants.inline, datePickerVariants.static]),
    /** Formats */
    format: PropTypes.oneOf([
        dateTimePickerFormats["yyyy-MM-dd"],
        dateTimePickerFormats["dd-MM-yyyy"],
        dateTimePickerFormats["dd/MM/yyyy"],
        dateTimePickerFormats["yyyy/MM/dd"],
        dateTimePickerFormats["MM/dd/yyyy"],
        dateTimePickerFormats["MM-dd-yyyy"]
    ]),
    /** Form group name */
    formGroupName: PropTypes.string,
    /** Input element state avilable path(use for manage complex objects) */
    inputStatePath: PropTypes.string,
    /** Onchange function */
    onChangeFn: PropTypes.func
};

//----------------DateTimePickerWithState---------------------

//----------------TimePickerWithState---------------------

const TimePickerWithState = ({
    uiType = defaultTemplateType,
    elementStyle = "",
    isDisabled = false,
    isRequired = false,
    isFullWidth = true,
    inputName = "",
    inputValue = new Date(),
    labelText = "",
    inputError = "",
    helperText = "",
    variant = datePickerVariants.dialog,
    format = datePickerFormats['dd/MM/yyyy'],
    formGroupName = "",
    inputStatePath = "",
    cacheLevel = formCacheLevel.updateOnFormGroupChange,
    onChangeFn = emptyFun
}) => {

    const [currentValue, currentError, updateStatus, handleOnChangeFn] = useBasicInput(inputStatePath, formGroupName, inputName, inputValue, onChangeFn);

    return (
        <TimePickerMemo
            uiType={uiType}
            elementStyle={elementStyle}
            isDisabled={isDisabled}
            isFullWidth={isFullWidth}
            inputName={inputName}
            inputValue={currentValue}
            labelText={labelText}
            inputError={currentError}
            helperText={helperText}
            variant={variant}
            format={format}
            onChangeFn={(eventData => {
                handleOnChangeFn(eventData);
            })}
            updateStatus={updateStatus}
            cacheLevel={cacheLevel}
        />
    );
};


export {
    DatePicker,
    datePickerVariants,
    datePickerFormats,
    DatePickerWithState,
    DatePickerMemo,
    formCacheLevel,
    dateTimePickerFormats,
    DateTimePicker,
    DateTimePickerWithState,
    DateTimePickerMemo,
    TimePicker,
    TimePickerMemo,
    TimePickerWithState
};
