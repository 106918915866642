import React, { Fragment } from "react";
import { SubmitButton } from "../../../../ui-components/ui-elements/form";
import {
  deliveryMethodCreateApi,
  deliveryMethodEditApi,
  deliveryMethodListApi,
} from "../../../../../config/apiUrl.config";
import { getDataByFormObject } from "../../../../../helpers/common-helpers/common.helpers";
import { apiResponseStoringType } from "../../../../../config/apiResponseKey";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";

const CustomButton = (props) => {
  const uiType = props.uiType;
  const btnText = props.btnText;
  
  return (
    <Fragment>
      <SubmitButton
        btnText={btnText}
        startIcon={
          props.uiType === "add"
          ? "far fa-save"
          : "far fa-edit"
        }
        formGroupName={
          props.uiType === "add"
            ? `${deliveryMethodCreateApi.key}_create`
            : `${deliveryMethodEditApi.key}_edit`
        }
        dataTableKey={deliveryMethodListApi.key}
        isValidate={true}
        validationObject={{
          fileds: {
            name: "Delivery Method Name in English",
            name_sin: "Delivery Method Name in Sinhala",
            name_ta: "Delivery Method Name in Tamil",
            active: "Status",
          },
          rules: {
            name_sin: "required|max:50",
            name: "required|max:50",
            name_ta: "required|max:50",
            active: "required",
          },
        }}
        flashMessages={{
          success: {
            status: true,
            message:
            props.uiType === "add"
                ? "Delivery method is successfully saved."
                : "Delivery method is successfully updated.",
            messageType: "success",
          },
        }}
        onGetAPIEndPointFn={(formState) => {
          return {
            url:
            props.uiType === "add"
                ? deliveryMethodCreateApi.url
                : `${deliveryMethodEditApi.url}/${formState.id}`,
            key: deliveryMethodListApi.key,
          };
        }}
        onChangeRequestBodyFn={({...formObject}) => {
          return getDataByFormObject({
            ...formObject,
            name: formObject.name.trim(),
            name_sin: formObject.name_sin.trim(),
            name_ta: formObject.name_ta.trim(),
            active: parseInt(formObject.active) === 1 ? true : false,
          });
        }}
        callApiObject={{
          isSetHeaders: true,
          method: props.uiType === "add" ? "post" : "put",
        }}
        apiDataStoringObject={{
          setLoader: true,
          storingType: apiResponseStoringType.dateTableLinkedForm,
        }}
        onResponseCallBackFn={(response, error) => {
          if (
            response.length === undefined &&
            error === undefined &&
            response.data.errors === undefined
          ) {
            props.onClosePopupFn();
          }
        }}
      />
    </Fragment>
  );
};

export { CustomButton };
