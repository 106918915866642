import React from "react";
import { FormWrapper, InputBoxWithState, NumberInputBoxWithState, SelectBoxWithState } from "../../../../ui-components/ui-elements/form";
import { _get } from '../../../../../helpers/common-helpers/lodash.wrappers';
import { templateTypes } from "../../../../../config/template.config";

const VehicleConditionForm = (props) => {
    const formKey = props.fromParent.formKey;

    return (
        <FormWrapper
            setGroupName={formKey}
            formGroupLinkWith={formKey}
            setFormObject={{
                id: _get(props, "row.id", ""),
                prefix: _get(props, "row.prefix", ""),
                name: _get(props, "row.name", ""),
                active: _get(props, "row.active", "1"),
                vet_exemption_duration_code: _get(props, "row.vet_exemption_duration_code", "M"),
                vet_exemption_value: _get(props, "row.vet_exemption_value", null),
                vft_exemption_duration_code: _get(props, "row.vft_exemption_duration_code", "M"),
                vft_exemption_value: _get(props, "row.vft_exemption_value", null),
                dmt_mapping_parameter: _get(props, "row.dmt_mapping_parameter", ""),
            }}
            onDestroyUnsetFormObject={true}
        >
            <table className={"fullWidthDiv"}>
                <tr>
                    <td>Vehicle Condition Prefix<sup className="text-danger">*</sup></td>
                    <td colSpan="2" className={"defaultPaddingLeft"}>
                        <InputBoxWithState
                            formGroupName={formKey}
                            inputName={"prefix"}
                            maxLength={"2"}
                        />
                    </td>
                </tr>
                <tr>
                    <td className={"defaultHalfPaddingTop"}>Vehicle Condition Name<sup className="text-danger">*</sup></td>
                    <td colSpan="2" className={"defaultPaddingLeft"}>
                        <InputBoxWithState
                            formGroupName={formKey}
                            inputName={"name"}
                            maxLength={"50"}
                        />
                    </td>
                </tr>
                <tr>
                    <td className={"defaultHalfPaddingTop"}>Status<sup className="text-danger">*</sup></td>
                    <td className={"defaultPaddingLeft"}>
                        <SelectBoxWithState
                            formGroupName={formKey}
                            inputName={"active"}
                            emptySelectOptionTxt={"- Select an option -"}
                            dataList={[
                                {
                                    id: 1,
                                    value: "Active",
                                },
                                {
                                    id: 0,
                                    value: "Inactive",
                                },
                            ]}
                        />
                    </td>
                </tr>

                <tr>
                    <th className={"defaultPaddingTop"}><strong>Document Exemptions</strong></th>
                    <th className={"defaultPaddingTop defaultPaddingLeft"}><strong>Duration</strong></th>
                    <th className={"defaultPaddingTop defaultPaddingLeft"}><strong>Value</strong></th>
                </tr>
                <tr>
                    <td className={"defaultHalfPaddingTop"}>VET Exemption Period<sup className="text-danger">*</sup></td>
                    <td className={"defaultPaddingLeft"}>
                        <SelectBoxWithState
                            formGroupName={formKey}
                            inputName={"vet_exemption_duration_code"}
                            emptySelectOptionTxt={"- Select an option -"}
                            dataList={[{ "id": "Y", "value": "Year" }, { "id": "M", "value": "Month" }, { "id": "D", "value": "Day" }]}
                        />
                    </td>
                    <td className={"defaultPaddingLeft"}>
                        <NumberInputBoxWithState
                            uiType={templateTypes.bootstrap}
                            formGroupName={formKey}
                            inputName={"vet_exemption_value"}
                            isAllowNegative={false}
                            decimalScale={0}
                            isAllowedFn={(value) => {
                                const checkValue = value.value.toString();
                                return checkValue.length <= 3;
                            }}
                        />
                    </td>
                </tr>
                <tr>
                    <td className={"defaultHalfPaddingTop"}>VFT Exemption Period<sup className="text-danger">*</sup></td>
                    <td className={"defaultPaddingLeft"}>
                        <SelectBoxWithState
                            formGroupName={formKey}
                            inputName={"vft_exemption_duration_code"}
                            emptySelectOptionTxt={"- Select an option -"}
                            dataList={[{ "id": "Y", "value": "Year" }, { "id": "M", "value": "Month" }, { "id": "D", "value": "Day" }]}
                        />
                    </td>
                    <td className={"defaultPaddingLeft"}>
                        <NumberInputBoxWithState
                            uiType={templateTypes.bootstrap}
                            formGroupName={formKey}
                            inputName={"vft_exemption_value"}
                            isAllowNegative={false}
                            decimalScale={0}
                            isAllowedFn={(value) => {
                                const checkValue = value.value.toString();
                                return checkValue.length <= 3;
                            }}
                        />
                    </td>
                </tr>

                <tr>
                    <th colSpan="3" className={"defaultPaddingTop"}><strong>DMT Mapping Parameters</strong></th>
                </tr>
                <tr>
                    <td className={"defaultHalfPaddingTop"}>DMT Mapping Parameter<sup className="text-danger">*</sup></td>
                    <td colSpan="2" className={"defaultPaddingLeft"}>
                        <InputBoxWithState
                            formGroupName={formKey}
                            inputName={"dmt_mapping_parameter"}
                            maxLength={"50"}
                        />
                    </td>
                </tr>
            </table>
        </FormWrapper >
    );
};

export { VehicleConditionForm };