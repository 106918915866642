/*
 * @Author: Binara Medawatta
 * @Date: 2020-12-30 15:10:05
 * @Last Modified by: Binara Medawatta
 * @Last Modified time: 2020-12-31 11:15:20
 */

import React from "react";

import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import {
  DataTable,
  actionBtnTypes,
} from "../../../ui-components/ui-elements/table/DataTable";
import { SearchElement } from "./includes/Form";
import { View } from "./includes/ViewUserAccount";
import { CreateStepsWrapper, EditFormWrapper } from "./includes/UserManageForm";
import { internalUserAPI } from "../../../../config/apiUrl.config"
import { useEffect } from "react";

const UserAccount = () => {
  const formKey = internalUserAPI.key;
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  return (
    <DashboardTemplate
      pageTitle="Manage User Account"
      breadCrumbList={[
        { urlTo: "/", displayName: "Home" },
        { urlTo: "/user", displayName: "User Management" },
        { urlTo: "/user", displayName: "User Account" },
      ]}
    >
      <DataTable
        tableName={"User Accounts"}
        apiUrl={`${internalUserAPI.url}?status=true&sort_by=full_name|asc`}
        dataTableKey={internalUserAPI.key}
        isSetAction={true}
        defaultSearchFormObject={{
          full_name:"",
          display_name:"",
          user_name:"",
          role_id:"",
          active:""
        }}
        isSetSearchFrom={true}
        searchFromComponent={
          <SearchElement dataTableKey={internalUserAPI.key} />
        }
        tableHeaderList={[
          {
            displayName: "Full Name",
            key: "full_name",
            default: "-",
            isSort: false,
          },
          {
            displayName: "Display Name",
            key: "display_name",
            isSort: false,
            default: "-",
          },
          {
            displayName: "Username",
            key: "user.user_name",
            isSort: false,
            default: "-",
          },
          {
            displayName: "Designation",
            key: "user.designation",
            isSort: false,
            default: "-",
          },
          {
            displayName: "Status",
            key: "user.active",
            isSort: false,
            default: "-",
            onPrintFn: ({ user }) => (parseInt(user.active) === 1 ? "Active" : "Inactive")
          },
          {
            displayName: "Base Location",
            key: "base_service_name",
            isSort: false,
            default: "-",
            onPrintFn: (rawData) => (rawData.user.base_service_name),
            
          },
          
        ]}
        actionTypeList={[
          {
            actionType: actionBtnTypes.create,
            modelContentElement: CreateStepsWrapper,
            dialogPopupProps: {
              isFullScreen: true,
              dialogTitle: "Add User Account",
              isCustomButton: false,
              isConfirmationPopup: true,
              closeButton: {
                btnText: "Cancel",
                startIcon: "far fa-times-circle",
              },
            },
            parentToModelProps: {
              formKey: `${formKey}_create`,
              type: "CRETAE",
              groupKey :formKey
            },
          },
          {
            actionType: actionBtnTypes.view,
            actionBtn: { icon: "fas fa-binoculars", tooltip: "View"},
            modelContentElement: View,
            dialogPopupProps: {
              isFullScreen: false,
              dialogTitle: "View User Account",
            },
          },
          {
            actionType: actionBtnTypes.edit,
            actionBtn: { tooltip: "Edit" },
            modelContentElement: EditFormWrapper,
            dialogPopupProps: {
              isFullScreen: true,
              dialogTitle: "Edit User Account",
              isConfirmationPopup:false,
              closeButton: {
                btnText: "Close",
                startIcon: "far fa-times-circle",
              },
           
              isCustomButton: false,
            },
            parentToModelProps: {
              formKey: `${formKey}_edit`,
              type: "EDIT",
              groupKey :formKey,
             
            },
          },
        ]}
      />
    </DashboardTemplate>
  );
};

export default UserAccount;
