/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-03-30 11:45:47 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-24 09:38:36
 */

import React from 'react'; 

import {InputButton} from "../../form/Button";
import {CheckPermission} from "../../../ui-elements/common/BaseElements";

const emptyFun = (...para) => undefined;

const TableHeaderBar=({
    tableName="",
    isSetSearchFrom=false,
    dataTableState={},
    actionIndex=(-1),
    actionBtnPara={},
    isSetModel=false,
    setDataTableStateFn={emptyFun}
})=>{
    
    return(
        <div className="dataTableHeader">
                <div className="row">
                <div className="col-md-6 col-sm-12 floatLeft dataTableHeaderTxtWrapper">
                    <h5>{tableName}</h5>
                </div>
                <div className="col-md-6 col-sm-12 floatLeft btnWrapper text-right">

                    
                    {
                        (actionIndex!==-1)?(
                            <CheckPermission permission={actionBtnPara.permissions||"NONE"}>
                                <InputButton
                                    btnText={actionBtnPara.btnText||"Create"}
                                    startIcon={actionBtnPara.icon||"fas fa-plus-circle"}
                                    elementWrapperStyle={`dataTableHeaderBtn ${actionBtnPara.elementStyle||""}`}
                                    onClickBtnFn={(event)=>{
                                        if(isSetModel===true){
                                            setDataTableStateFn({
                                                ...dataTableState,
                                                modelStatus:{
                                                    ...dataTableState.modelStatus,
                                                    status:true,
                                                    type:"actionList",
                                                    index:actionIndex,
                                                    props:{}
                                                }
                                            });
                                        }else{
                                            actionBtnPara.onClickBtnFn(event);
                                        }
                                    }}
                                />
                            </CheckPermission>
                        ):(null)
                    }
                    
                    
                    {
                        (isSetSearchFrom)?(
                            <InputButton
                                btnText="Filter"
                                startIcon="fas fa-search"
                                elementWrapperStyle={"dataTableHeaderBtn"}
                                onClickBtnFn={()=>setDataTableStateFn({
                                    ...dataTableState,
                                    isShowSearch:!dataTableState.isShowSearch
                                })}
                            />
                        ):(null)
                    }

                    
                    
                </div>    
                </div>            
        </div>
    )
}


export {
    TableHeaderBar
}