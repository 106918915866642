/*
 * @Author: Chanaka Wickramasinghe
 * @Description: Confirmation Popup
 * @Date: 2021-06-01 16:10:12
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2021-06-01 16:10:12
 */
import React, { Fragment } from "react";

import { closeBtn, DialogPopup } from "../DialogPopup";
import { InputButton } from "../../form";

const emptyFun = (...para) => undefined;

const CustomButtons = (props) => {
    return (
        <div className="row">
            <div className='defaultPaddingLeft'>
                <InputButton
                    btnText={'Yes'}
                    onClickBtnFn={() => {
                        props.onConfirmationPopupFn();
                        props.onClosePopupFn();
                    }}
                    startIcon={'fas fa-check'}
                />
            </div>

            <div className='defaultHalfPaddingLeftRight'>
                <InputButton
                    btnText={'No'}
                    onClickBtnFn={() => props.onConfirmationPopupFn()}
                    startIcon={'fas fa-times'}
                />
            </div>
        </div>
    );
};

const ConfirmationPopup = ({
    isOpen = false,
    confirmationMessage = "",
    closeButton = closeBtn,
    onConfirmationPopupFn = emptyFun,
    onClosePopupFn = emptyFun,
}) => {
    return (
        <Fragment>
            <InputButton
                btnText={closeButton.btnText}
                elementStyle={closeButton.elementStyle}
                btnName={closeButton.btnName}
                startIcon={closeButton.startIcon}
                isBtnDisabled={closeButton.isBtnDisabled}
                onClickBtnFn={() => onConfirmationPopupFn(true)}
            />

            <DialogPopup
                isOpen={isOpen}
                onClosePopupFn={() => onConfirmationPopupFn(false)}
                dialogTitle={"Please Confirm"}
                isConfirmation={false}
                isCustomButton={true}
                isCloseButton={false}
                customButtonComponent={CustomButtons}
                customButtonComponentProps={{
                    onClosePopupFn,
                    onConfirmationPopupFn
                }}
            >
                <div className="fullWidthDiv">
                    <p>{confirmationMessage}</p>
                </div>
            </DialogPopup>
        </Fragment>
    )
}

export default ConfirmationPopup;
