import React, { Fragment } from "react";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import {
  AuditDetail,
  UICard,
  ViewDetailBlock,
} from "../../../../ui-components/ui-elements/common/BaseElements";

const ViewVehicleClassNew = (props) => {
  const extendedApplicability = (para) => {
    if (para) {
      if (para === "MAN") {
        return "Mandatory";
      } else if (para === "OPT") {
        return "Optional";
      } else {
        return "Not Required";
      }
    } else {
      return null;
    }
  };

  const numberOfLabelCol = 6;
  return (
    <Fragment>
      <UICard>
        <ViewDetailBlock
          label={"Vehicle Class Prefix"}
          value={_get(props, `row.prefix`, "")}
          labelCol={numberOfLabelCol}
        />
        <ViewDetailBlock
          label={"Vehicle Class Name"}
          value={_get(props, `row.name`, "")}
          labelCol={numberOfLabelCol}
        />
        <ViewDetailBlock
          label={"Status"}
          value={
            parseInt(_get(props, `row.active`, "")) === 1
              ? "Active"
              : "Inactive"
          }
          labelCol={numberOfLabelCol}
        />
      </UICard>
      <div className="defaultPaddingTop">
        <UICard className="" cardHeading="Document Validation">
          <ViewDetailBlock
            label={"Insuarance"}
            value={extendedApplicability(
              _get(props, `row.insurance_applicability_code`, "")
            )}
            labelCol={numberOfLabelCol}
          />
          <ViewDetailBlock
            label={"VET"}
            value={extendedApplicability(
              _get(props, `row.vet_applicability_code`, "")
            )}
            labelCol={numberOfLabelCol}
          />
          <ViewDetailBlock
            label={"VFT"}
            value={extendedApplicability(
              _get(props, `row.fitness_applicability_code`, "")
            )}
            labelCol={numberOfLabelCol}
          />
          <ViewDetailBlock
            label={"VRP"}
            value={extendedApplicability(
              _get(props, `row.route_permit_applicability_code`, "")
            )}
            labelCol={numberOfLabelCol}
          />

          <div className="row defaultPaddingTop"></div>
        </UICard>

        <UICard
          cardHeading="DMT Mapping Parameters"
          elementStyle="defaultMarginTop"
        >
          <ViewDetailBlock
            label={"DMT Mapping Parameters"}
            value={_get(props, `row.dmt_mapping_parameter`, "")}
            labelCol={numberOfLabelCol}
          />
        </UICard>
      </div>
      <AuditDetail
        createdById={_get(props, `row.created_by`, "")}
        createdLocationId={_get(props, `row.created_location`, "")}       
        createdAt={dateObjectToString(_get(props, `row.created_at`, ""),"dd/mm/YY HH:MM")}
        createdRoleId={_get(props, `row.created_role_id`, '')}
        updatedById={_get(props, `row.updated_by`, "")}
        updatedLocationId={_get(props, `row.updated_location`, "")}        
        updatedAt={dateObjectToString(_get(props, `row.updated_at`, ""), "dd/mm/YY HH:MM")}
        updatedRoleId={_get(props, `row.updated_role_id`, '')}
        labelCol={numberOfLabelCol}
      />
    </Fragment>
  );
};

export { ViewVehicleClassNew };
