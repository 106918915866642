/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-02-28 17:31:46
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-17 10:12:43
 */

import React, { Fragment } from "react";
import { Navigate } from "../../ui-components/ui-elements/common/Navigate";
import { permissions } from "../../../config/permission.config";
import ManageInsuranceCompany from "./pages/Manage-insurance-company";
import authorized from "../../hoc-middlewares/Authorized";

const ManageInsuranceCompanyRoutes = () => {
  return (
    <Fragment>
      <Navigate
        exact={true}
        path="/manage-insurance-company"
        routeKey={"insurance-company"}
        component={authorized(ManageInsuranceCompany)}
        routePermissions={permissions.INSURANCE_COMPANY_MANAGE.permissions}
      />
    </Fragment>
  );
};

export default ManageInsuranceCompanyRoutes;
