import React, { Fragment, useState, useContext } from 'react';

import { templateTypes } from '../../../../../config/template.config';
import { useHistory } from "react-router-dom";
import { CheckPermission, Icon, UICard } from '../../../../ui-components/ui-elements/common/BaseElements';
import {
    FormWrapper,
    InputBoxWithState,
    InputButton,
    SubmitButton
} from '../../../../ui-components/ui-elements/form';
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { getDataByFormObject } from "../../../../../helpers/common-helpers/common.helpers";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { userPasswordChangeAPI } from "../../../../../config/apiUrl.config";
import { permissions } from '../../../../../config/permission.config';

const ChangePassword = (props) => {
    const formKey = "changePassword";
    const [isPasswordShow, setPasswordShow] = useState(false);
    const [authState] = useContext(AuthContext);

    const togglePasswordVisibility = () => setPasswordShow(!isPasswordShow);
    let history = useHistory();
    return (
        <div className="LoginMainWrapper">
            <UICard elementStyle="loginCard">
                <Fragment>
                    <div className="loginHeaderWrapper">
                        <img src="images/_emblem1.png" alt="Sri Lanka emblem" className="govermentLogo" />
                        <h4 className="defaultMarginTop">Change Password</h4>
                    </div>

                    <FormWrapper
                        elementStyle="loginFormWrapper"
                        setGroupName={formKey}
                    >
                        <InputBoxWithState
                            uiType={templateTypes.bootstrap}
                            formGroupName={formKey}
                            inputName={"current_password"}
                            labelText={"Current Password"}
                            isRequired={true}
                            inputType={isPasswordShow ? "text" : "password"}
                        />

                        <InputBoxWithState
                            uiType={templateTypes.bootstrap}
                            formGroupName={formKey}
                            inputName={"new_password"}
                            labelText={"New Password"}
                            isRequired={true}
                            inputType={isPasswordShow ? "text" : "password"}
                        />

                        <div className="passwordWrapper">
                            <InputBoxWithState
                                uiType={templateTypes.bootstrap}
                                formGroupName={formKey}
                                inputType={isPasswordShow ? "text" : "password"}
                                inputName={"confirmation_password"}
                                labelText="Confirm Password"
                                isRequired={true}
                            />

                            <Icon
                                onClickFn={togglePasswordVisibility}
                                iconClass={isPasswordShow ? "mdi mdi-eye" : "mdi mdi-eye-off"}
                                elementStyle={"eye"}
                            />
                        </div>

                        <div className="fullWidthDiv">

                            <InputButton
                             btnText={"Back"}
                             startIcon={"fa fa-backward"}
                             elementStyle="btnWrapper"
                             onClickBtnFn={() => history.push("/")}
                            />
                            <CheckPermission
                                permission={permissions.PASSWARD_CHANGE.permissions}
                            >
                                <SubmitButton
                                    elementWrapperStyle={"floatRight"}
                                    btnText={"Confirm"}
                                    elementStyle="btnWrapper"
                                    startIcon={"far fa-save"}
                                    formGroupName={formKey}
                                    isValidate={true}
                                    flashMessages={{
                                        "success": {
                                            status: true,
                                            message: "Password changed successfully",
                                            messageType: "success"
                                        }
                                    }}
                                    validationObject={{
                                        fileds: {
                                            current_password: "Current Password",
                                            new_password: "New Password",
                                            confirmation_password: "Confirm Password"
                                        },
                                        rules: {
                                            current_password: "required",
                                            new_password: "required",
                                            confirmation_password: "required|same:new_password"
                                        },
                                        message: {}
                                    }}
                                    callApiObject={{
                                        isSetHeaders: true,
                                        multipart: false,
                                        method: "put",
                                        onUpload: false
                                    }}
                                    apiDataStoringObject={{
                                        setLoader: true,
                                        storingType: "API_RESPONSE_LINKED_FORM",
                                        mergeToSuccessResponse: null,
                                        mergeToErrorResponse: null,
                                    }}
                                    onGetAPIEndPointFn={(formObject) => {
                                        return {
                                            url: `${userPasswordChangeAPI.url}/${_get(authState, "authUser.id")}`,
                                            key: userPasswordChangeAPI.key
                                        }
                                    }}
                                    onChangeRequestBodyFn={(formObject) => {
                                        return getDataByFormObject(formObject);
                                    }}
                                    onResponseCallBackFn={(error) => {
                                        if (!error) {
                                            props.history.push("/");
                                        }
                                    }}
                                />
                            </CheckPermission>

                        </div>
                    </FormWrapper>
                </Fragment>
            </UICard>
        </div>
    )
}

export {
    ChangePassword
}