/*
 * @Author: Sujith
 * @Date: 2020-09-28 11:11:46 
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2021-05-14 12:10:07
 */
import React from "react";

import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { DataTable, actionBtnTypes } from "../../../ui-components/ui-elements/table/DataTable";
import ViewRate from "./includes/ViewRate";
import { AddRate, RateCreateBtn } from "./includes/AddRate";
import { rateListAPI } from "../../../../config/apiUrl.config";
import { dateObjectToString } from "../../../../helpers/common-helpers/dateTime.helpers";
import { EditRate, RateEditBtn } from "./includes/EditRate";
import SearchRates from "./includes/SearchRates";
import { isEmptyValue } from "../../../../helpers/common-helpers/common.helpers";
import { DeleteRates, RateDeleteBtn } from "./includes/DeleteRates";
import { permissions } from "../../../../config/permission.config";
import { useEffect } from "react";


const ManageRate = () => {
    const checkEditDeleteBtnCondition = (effectiveFromDate) => {
        let today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);

        let effectiveDate = new Date(effectiveFromDate);
        effectiveDate.setHours(0);
        effectiveDate.setMinutes(0);
        effectiveDate.setSeconds(0);
        effectiveDate.setMilliseconds(0);

        return today < effectiveDate;
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <DashboardTemplate
            pageTitle="Manage Rate"
            breadCrumbList={[
                { urlTo: "/", displayName: "Home" },
                { urlTo: "", displayName: "Charges" },
                { urlTo: "", displayName: "Manage Rates" },
            ]}
        >
            <DataTable
                tableName={"Rates"}
                apiUrl={rateListAPI.url}
                dataTableKey={rateListAPI.key}
                isSetSearchFrom={true}
                defaultSearchFormObject={{
                    "licence_type_id": "null",
                    "charging_category_code": "null",
                    "vehicle_class_id": "null",
                    "fuel_type_id": "null",
                    "effective_from": null,
                }}
                searchFromComponent={
                    <SearchRates dataTableKey={rateListAPI.key} />
                }
                onSetSearchObjectFn={(searchObject) => {
                    let tempSearchObject = {...searchObject};

                    if (!isEmptyValue(searchObject.effective_from)) {
                        tempSearchObject["effective_from"] = searchObject.effective_from.toISOString();
                    }

                    return tempSearchObject;
                }}
                tableHeaderList={[
                    { displayName: "Rate Code", key: "code", default: "-", isSort: false, onPrintFn: (row) => isEmptyValue(row.code) ? "" : row.code},
                    { displayName: "Rate Description", key: "description", isSort: false },
                    { displayName: "Effective From", key: "effective_from", isSort: false, onPrintFn: (row) => dateObjectToString(row.effective_from,  "dd/mm/YY")},
                    { displayName: "Created At", key: "created_at", isSort: false, onPrintFn: (row) => dateObjectToString(row.created_at, "dd/mm/YY HH:MM") },
                ]}
                isSetAction={true}
                actionTypeList={[
                    {
                        actionType: actionBtnTypes.create,
                        actionBtn: {
                            "permissions": permissions.RATES_ADD.permissions
                        },
                        modelContentElement: AddRate,
                        dialogPopupProps: {
                            isFullScreen: true,
                            dialogTitle: "Add Rate",
                            closeButton: {
                                btnText: "Cancel",
                                startIcon: "far fa-times-circle",
                            },
                            isCustomButton: true,
                            customButtonComponent: RateCreateBtn,
                            isConfirmationPopup: true
                        }
                    },
                    {
                        actionType: actionBtnTypes.delete,
                        actionBtn: { tooltip: 'Delete', "permissions": permissions.RATES_DELETE.permissions },
                        modelContentElement: DeleteRates,
                        dialogPopupProps: {
                            isFullScreen: false,
                            dialogTitle: "Delete Rate",
                            closeButton: {
                                btnText: "No",
                                startIcon: "far fa-times-circle",
                            },
                            actionBtn: {
                                // "permissions": permissions.RATES_DELETE.permissions
                            },
                            isCustomButton: true,
                            customButtonComponent: RateDeleteBtn
                        },
                        checkBeforeActionRenderFn: ({ row }) => {
                            return checkEditDeleteBtnCondition(row.effective_from);
                        }
                    },
                    {
                        actionType: actionBtnTypes.edit,
                        actionBtn: { tooltip: 'Edit', "permissions": permissions.RATES_EDIT.permissions },
                        modelContentElement: EditRate,
                        dialogPopupProps: {
                            isFullScreen: true,
                            dialogTitle: "Edit Rate",
                            closeButton: {
                                btnText: "Cancel",
                                startIcon: "far fa-times-circle",
                            },
                            isCustomButton: true,
                            customButtonComponent: RateEditBtn,
                            isConfirmationPopup: true
                        },
                        checkBeforeActionRenderFn: ({ row }) => {
                            return checkEditDeleteBtnCondition(row.effective_from);
                        }
                    },
                    {
                        actionType: actionBtnTypes.view,
                        actionBtn: { tooltip: "View",  "permissions": permissions.RATES_VIEW.permissions },
                        modelContentElement: ViewRate,
                        dialogPopupProps: {
                            isFullScreen: false,
                            dialogTitle: "View Rates",
                            closeButton: {
                                btnText: "Close",
                                startIcon: "far fa-times-circle",
                            },
                        }
                    },
                ]}

            />
        </DashboardTemplate>
    );
};

export default ManageRate;