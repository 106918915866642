import React, { useContext } from "react";
import axios from "axios";

import { actionBtnTypes, DataTable } from "../../../ui-components/ui-elements/table/DataTable";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { fineConfigurationsListAPI, generatePDFAPI } from "../../../../config/apiUrl.config";
import { dateObjectToString } from "../../../../helpers/common-helpers/dateTime.helpers";
import { AddFineConfigurations, FineConfigurationCreateBtn } from "./includes/AddFineConfigurations";
import { EditFineConfigurations, FineConfigurationEditBtn } from "./includes/EditFineConfigurations";
import ViewFineConfigurations from "./includes/ViewFineConfigurations";
import { DeleteFineConfigurations, FineConfigurationDeleteBtn } from "./includes/DeleteFineConfigurations";
import SearchFineConfigurations from "./includes/SearchFineConfigurations";
import { isEmptyValue } from "../../../../helpers/common-helpers/common.helpers";
import { AuthContext } from "../../core/context-providers/AuthContext.provider";
import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { UIContext } from "../../../ui-components/context-providers/UIContext.provider";
import { permissions } from "../../../../config/permission.config";
import { useEffect } from "react";


const ManageFineConfigurations = () => {
    const [authState] = useContext(AuthContext);
    const [, uiAction] = useContext(UIContext);

    const downloadPDF = (rowData) => {
        uiAction.setPageLoader(true);

        axios.get(`${generatePDFAPI.url}/fine-configurations/${rowData.id}`, {
            responseType: "blob",
            headers: {
                "Authorization": `Bearer ${_get(authState, "accessToken", null)}`
            }
        })
        .then(response => {
            uiAction.setPageLoader(false);

            const newBlob = new Blob([response.data], { type: "application/pdf" });
            const data = window.URL.createObjectURL(newBlob);
            const link = document.createElement('a');

            link.href = data;
            link.download = `fine-configuration-${rowData.id}.pdf`;
            link.click();

            link.remove();
            setTimeout(() => window.URL.revokeObjectURL(data), 100);
        })
        .catch((error) => {
            uiAction.setPageLoader(false);
        });
    };

    const checkEditDeleteBtnCondition = (effectiveFromDate) => {
        let today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);

        let effectiveDate = new Date(effectiveFromDate);
        effectiveDate.setHours(0);
        effectiveDate.setMinutes(0);
        effectiveDate.setSeconds(0);
        effectiveDate.setMilliseconds(0);

        return today < effectiveDate;
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <DashboardTemplate
            pageTitle="Manage Fine Configuration"
            breadCrumbList={[
                { urlTo: "/", displayName: "Home" },
                { urlTo: "", displayName: "Charges" },
                { urlTo: "", displayName: "Fine Configurations" },
            ]}
        >
            <DataTable
                tableName={"Fine Configurations"}
                apiUrl={`${fineConfigurationsListAPI.url}`}
                dataTableKey={fineConfigurationsListAPI.key}
                isSetAction={true}
                isSetSearchFrom={true}
                defaultSearchFormObject={{
                    "licence_type": "null",
                    "effective_from": null,
                }}
                searchFromComponent={
                    <SearchFineConfigurations dataTableKey={fineConfigurationsListAPI.key} />
                }
                onSetSearchObjectFn={(searchObject) => {
                    let tempSearchObject = { ...searchObject };

                    if (!isEmptyValue(searchObject.effective_from)) {
                        tempSearchObject["effective_from"] = searchObject.effective_from.toISOString();
                    }

                    return tempSearchObject;
                }}
                tableHeaderList={[
                    { displayName: "Configuration Name", key: "description", isSort: false },
                    { displayName: "Licence Type", key: "licence_type.name", isSort: false },
                    { displayName: "Effective From", key: "effective_from", isSort: false, onPrintFn: (row) => dateObjectToString(row.effective_from, "dd/mm/YY") },
                    { displayName: "Created Date", key: "created_at", isSort: false, onPrintFn: (row) => dateObjectToString(row.created_at, "dd/mm/YY") },
                ]}
                actionTypeList={[
                    {
                        actionType: actionBtnTypes.create,
                        actionBtn: {
                            "permissions": permissions.FINE_CONFIG_ADD.permissions
                        },
                        modelContentElement: AddFineConfigurations,
                        dialogPopupProps: {
                            isFullScreen: true,
                            dialogTitle: "Add Fine Configuration",
                            closeButton: {
                                btnText: "Cancel",
                                startIcon: "far fa-times-circle",
                            },
                            isCustomButton: true,
                            customButtonComponent: FineConfigurationCreateBtn,
                            isConfirmationPopup: true
                        }
                    },
                    // {
                    //     actionType: actionBtnTypes.other,
                    //     actionBtn: {
                    //         tooltip: "Download",
                    //         icon: "fas fa-file-download",
                    //         onClickBtnFn: ({ row }) => {
                    //             downloadPDF(row);
                    //         }
                    //     },
                    // },
                    {
                        actionType: actionBtnTypes.delete,
                        actionBtn: { tooltip: 'Delete', "permissions": permissions.FINE_CONFIG_DELETE.permissions },
                        modelContentElement: DeleteFineConfigurations,
                        dialogPopupProps: {
                            isFullScreen: false,
                            dialogTitle: "Delete Fine Configuration",
                            closeButton: {
                                btnText: "No",
                                startIcon: "far fa-times-circle",
                            },
                            isCustomButton: true,
                            customButtonComponent: FineConfigurationDeleteBtn
                        },
                        checkBeforeActionRenderFn: ({ row }) => {
                            return checkEditDeleteBtnCondition(row.effective_from);
                        }
                    },
                    {
                        actionType: actionBtnTypes.edit,
                        actionBtn: { tooltip: 'Edit', "permissions": permissions.FINE_CONFIG_EDIT.permissions },
                        modelContentElement: EditFineConfigurations,
                        dialogPopupProps: {
                            isFullScreen: true,
                            dialogTitle: "Edit Fine Configuration",
                            closeButton: {
                                btnText: "Cancel",
                                startIcon: "far fa-times-circle",
                            },
                            isCustomButton: true,
                            customButtonComponent: FineConfigurationEditBtn,
                            isConfirmationPopup: true
                        },
                        checkBeforeActionRenderFn: ({ row }) => {
                            return checkEditDeleteBtnCondition(row.effective_from);
                        }
                    },
                    {
                        actionType: actionBtnTypes.view,
                        actionBtn: { tooltip: "View",  "permissions": permissions.FINE_CONFIG_VIEW.permissions },
                        modelContentElement: ViewFineConfigurations,
                        dialogPopupProps: {
                            isFullScreen: false,
                            dialogTitle: "View Fine Configuration",
                            closeButton: {
                                btnText: "Close",
                                startIcon: "far fa-times-circle",
                            }
                        }
                    },
                ]}
            />
        </DashboardTemplate>
    )
}

export default ManageFineConfigurations;