/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2019-08-11 11:25:28
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2021-11-17 16:50:08
 */

import { getInputsForValidate } from "../../common-helpers/common.helpers";

/**
 * @author Chanaka Wickramasinghe
 * @description validate pdmt payment modes
 * @param {string} key input value key
 * @param {object} values form values
 * @param {array} param additional validation parameters
 * @param {string} message Error message
 * @param {object} filedList display name for form elements
 * @param {Function} cb callback function
 */
const pdmtPaymentModes = (key, values, param, message, filedList, cb) => {
    try {
        const formValue = getInputsForValidate(values, key);

        if (formValue.length === 0) {
            cb("At least 1 value should be selected");
        } else {
            cb(null, true);
        }
    } catch (ex) {
        console.log(
            `----------------Validation Exception At (minAmount)-------------------`,
            `Input Key - ${key}`,
            `Exception - ${ex}`
        );

        cb(true);
    }
};

const getTransactionAllowedError = (key, values, param, message, filedList, cb) => {
    try {
        const formValue = getInputsForValidate(values, key);

        if (formValue.length === 0) {
            cb("At least one Transaction value should be selected");
        } else {
            cb(null, true);
        }
    } catch (ex) {
        console.log(
            `----------------Validation Exception At (minAmount)-------------------`,
            `Input Key - ${key}`,
            `Exception - ${ex}`
        );

        cb(true);
    }
};

const getLicenceTypeError = (key, values, param, message, filedList, cb) => {
    try {
        const formValue = getInputsForValidate(values, key);

        if (formValue.length === 0) {
            cb("At least one Licence Type value should be selected");
        } else {
            cb(null, true);
        }
    } catch (ex) {
        console.log(
            `----------------Validation Exception At (minAmount)-------------------`,
            `Input Key - ${key}`,
            `Exception - ${ex}`
        );

        cb(true);
    }
};

const getSMSNotifyError = (key, values, param, message, filedList, cb) => {
    try {
        const formValue = getInputsForValidate(values, key);

        if (formValue.length === 0) {
            cb("Notify via SMS should be selected");
        } else {
            cb(null, true);
        }
    } catch (ex) {
        console.log(
            `----------------Validation Exception At (minAmount)-------------------`,
            `Input Key - ${key}`,
            `Exception - ${ex}`
        );

        cb(true);
    }
};


const alphabet = (key, values, param, message, filedList, cb) => {
    try {

        var regexp = /^[A-Za-z]+$/;
        const formValue = getInputsForValidate(values, key);
        if (formValue && formValue.search(regexp) === -1)
            cb("User Role Prefix must be letters only");
        else
            cb(null, true)


    } catch (ex) {
        console.log(
            `----------------Validation Exception At (alpha)-------------------`,
            `Input Key - ${key}`,
            `Exception - ${ex}`
        );

        cb(true);
    }
}

const switchDeligationError = (key, values, param, message, filedList, cb) => {
    try {
        const formValue = getInputsForValidate(values, key);

        if (formValue.length === 0) {
            cb("Please select the delegation.");
        } else {
            cb(null, true);
        }
    } catch (ex) {
        console.log(
            `----------------Validation Exception At (minAmount)-------------------`,
            `Input Key - ${key}`,
            `Exception - ${ex}`
        );

        cb(true);
    }
};

export {
    pdmtPaymentModes,
    getTransactionAllowedError,
    getLicenceTypeError,
    getSMSNotifyError,
    alphabet,
    switchDeligationError
};
