/*
 * @Author: Binara Medawatta 
 * @Date: 2021-01-04 11:15:27 
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2021-11-19 07:50:41
 */
import React, { Fragment, useContext, useEffect } from "react";
import { templateTypes } from "../../../../../config/template.config";
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { DatePickerWithState, FormWrapper, InputBoxWithState, SelectBoxWithState, SubmitButton } from "../../../../ui-components/ui-elements/form";
import { AuthorityListApi, provincesOfficesListAPI, userDelegationAPI, userRolesListAPI,userRoleWithoutDeligationAPI } from "../../../../../config/apiUrl.config";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { getDataByFormObject } from "../../../../../helpers/common-helpers/common.helpers";
import { apiResponseStoringType } from "../../../../../config/apiResponseKey";
import { roleLevelChangeFn } from "../../../../../helpers/common-helpers/setRoleLevelCode";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { UIContext } from "../../../../ui-components/context-providers/UIContext.provider";

const emptyFn = (...para) => undefined;

const AdditionalRolesAndLocations = (props) => {
  
  const formKey = "additionalRoles";
  const [formState, formAction] = useContext(FormContext);
  const date = new Date();
  let expDate = date.setDate(date.getDate());
  const [authStatus] = useContext(AuthContext);
  const serviceId = _get(authStatus, "authUser.service_id", "");
  const userRoleLevelCode = _get(authStatus, "authUser.role_level_code", "");
  const userId = _get(props, 'fromParent.uiType', null) !== "edit"? (_get(props, "fromParent.userId", null)):(_get(props, "row.user.id", null));
 
  useEffect(() => {
    if (_get(props, "row.role.id", null) !== null) {
      const intRid = { value: _get(props, "row.role.id", "") }
      roleLevelChangeFn(intRid, formAction, formKey, 'arlRole');
    }
  }, []);

  useEffect(() => {
    if (_get(props, 'fromParent.uiType', null) !== "edit") {
      formAction.changeInputFn(formKey, "province", null);
      formAction.changeInputFn(formKey, "service_id", null);
    }
  }, [_get(formState, `${formKey}.role_id`, false)]);

  useEffect(() => {
    if (_get(props, 'fromParent.uiType', null) !== "edit") {
      formAction.changeInputFn(formKey, "service_id", null);
    }
  }, [_get(formState, `${formKey}.province`, false)]);

  console.log("props",props)
  return (
    <div className="row">
      <div className="col-md-12 col-sm-12 ">
        <UICard cardHeading="Additional Roles & Locations">
          <FormWrapper
            setGroupName={formKey}
            formGroupLinkWith={formKey}
            setFormObject={
              {
                id: _get(props, "row.id", null),
                status: _get(props, "row.status", 1),
                expiry_at: _get(props, "row.expiry_at", date),
                role_id: _get(props, "row.role.id", null),
                province: _get(props, 'row.orgData.pdmt[0].code', null) ? _get(props, 'row.orgData.pdmt[0].id', '-') : _get(props, 'row.orgData.authority[0].pdmt[0].id', '-'),
                service_id: _get(props, 'row.orgData.authority[0].service.id', ""),
                updated_reason: _get(props, "row.updated_reason", null) == null ? "" : _get(props, "row.updated_reason", "")
              }
            }
          >
            <div className="row">
              <div className="col-md-12 col-sm-12 floatLeft">
                <div className="row">
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <SelectBoxWithState
                      formGroupName={formKey}
                      emptySelectOptionTxt={"User Role"}
                      apiUrl={`${userRoleWithoutDeligationAPI.url}/${userId}?role_type=${(userRoleLevelCode==='ADN_PRV')?'INT_APP':'ADN_APP'}`}
                      apiStateKey={userRoleWithoutDeligationAPI.key}
                      keyName={"id"}
                      valueName={"name"}
                      inputName={"role_id"}
                      labelText="User Role"
                      onChangeFn={(event) => {
                        roleLevelChangeFn(event, formAction, formKey, 'arlRole');
                      }}
                      isDisabled={_get(props, 'fromParent.uiType', null) === "edit" ? true : false}
                      isRequired={true}
                    />

                    {_get(formState, `${formKey}.arlRoleLevelCode`, null) === 'INT_PRV' || _get(formState, `${formKey}.arlRoleLevelCode`, null) === 'ADN_PRV' ? (

                      <SelectBoxWithState
                        formGroupName={formKey}
                        inputName={"province"}
                        apiUrl={(userRoleLevelCode === "ADN_NTL") ? `${provincesOfficesListAPI.url}?active=true` : `${provincesOfficesListAPI.url}?service_id=${serviceId}&active=true`}
                        apiStateKey={provincesOfficesListAPI.key}
                        keyName="service.id"
                        valueName="service.name"
                        emptySelectOptionTxt={"Province office"}
                        labelText="Province Office"
                        isDisabled={_get(props, 'fromParent.uiType', null) === "edit" ? true : false}
                        isRequired={true}
                      />

                    ) : (null)}

                    {_get(formState, `${formKey}.arlRoleLevelCode`, null) === 'INT_AU' ? (
                      <div className="fullWidthDiv">
                        <SelectBoxWithState
                          formGroupName={formKey}
                          inputName={"province"}
                          apiUrl={(userRoleLevelCode === "ADN_NTL") ? `${provincesOfficesListAPI.url}?active=true` : `${provincesOfficesListAPI.url}?service_id=${serviceId}&active=true`}
                          apiStateKey={provincesOfficesListAPI.key}
                          keyName="id"
                          valueName="service.name"
                          emptySelectOptionTxt={"Province office"}
                          labelText="Province Office"
                          isDisabled={_get(props, 'fromParent.uiType', null) === "edit" ? true : false}
                          isRequired={true}
                        />

                        <SelectBoxWithState
                          formGroupName={formKey}
                          emptySelectOptionTxt={"Authority"}
                          apiUrl={_get(formState, `${formKey}.province`, null) !== null ?`${AuthorityListApi.url}?active=true&pdmt_id=${_get(formState, `${formKey}.province`, null)}`:""}
                          apiStateKey={AuthorityListApi.key}
                          keyName={"service.id"}
                          valueName={"service.name"}
                          inputName={"service_id"}
                          labelText="Authority"
                          isDisabled={_get(formState, `${formKey}.province`, null) === null || _get(props, 'fromParent.uiType', null) === "edit" ? true : false}
                          isRequired={true}
                        />

                      </div>
                    ) : (null)}
                  </div>
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <DatePickerWithState
                      uiType={templateTypes.bootstrap}
                      formGroupName={formKey}
                      labelText="Expiry Date"
                      inputName={"expiry_at"}
                      isRequired={true}
                      minDate={date}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 floatLeft">
                    <SelectBoxWithState
                      uiType={templateTypes.bootstrap}
                      formGroupName={formKey}
                      emptySelectOptionTxt={"Status"}
                      isRequired={true}
                      dataList={[
                        {
                          id: 1,
                          value: "Active",
                        },
                        {
                          id: 0,
                          value: "Inactive",
                        }
                      ]}
                      inputName={"status"}
                      labelText="Status"
                    />
                  </div>
                  {_get(props, 'fromParent.uiType', null) === "edit" ?
                    (
                      <div className="col-md-6 col-sm-12 floatLeft">
                        <InputBoxWithState
                          formGroupName={formKey}
                          inputName={"updated_reason"}
                          labelText="Reason"
                          isRequired={true}
                          maxLength={200}
                        />
                      </div>
                    ) : (null)
                  }
                </div>
              </div>
            </div>
          </FormWrapper>
        </UICard>
      </div>
    </div >
  );
};

const CustomButtons = (
  {
    onClosePopupFn = emptyFn,
    formGroupName = "",
    user_id = "",
    uiType = "",
  }) => {
  const close = () => {
    onClosePopupFn();
  };

  const [, uiAction] = useContext(UIContext);

  return (
    <Fragment>
      <SubmitButton
        btnText={uiType === "remove" ? ("Yes") : (uiType === "Edit" ? "Update" : "Save")}
        startIcon={uiType === "remove" ? ("fas fa-check") : (uiType === "Edit" ? "far fa-edit" : "far fa-save")}
        elementStyle="btnWrapper"
        formGroupName={formGroupName}
        dataTableKey={"AdditionalRolesKey"}
        isValidate={uiType === "remove" ? (false) : (true)}
        mapValidationErrorObj={{
          role_id: "The User Role is required",
          province: "The Province Office is required",
          service_id: "The Authority is required",
          updated_reason: "The Reason is required"
        }}
        validationObject={{
          fileds: {
            role_id: "User Role",
            expiry_at: "Expiry Date",
            status: "Status",
            province:"Province Office",
            service_id:"Authority"
          },
          rules: {
            role_id: "required",
            expiry_at: "required",
            status: "required",
            province: "requiredIf:arlRoleLevelCode,INT_AU|requiredIf:arlRoleLevelCode,INT_PRV",
            service_id: "requiredIf:arlRoleLevelCode,INT_AU",
          },
          message: {
            "province.requiredIf": "Province Office is required.",
            "service_id.requiredIf": "Authority is required.",
          },
        }}
        flashMessages={{
          success: {
            status: true,
            message: `Additional Role & Location is successfully ${uiType === "remove" ? ("removed.") : (uiType === "Edit" ? "updated." : "saved.")} `,
            messageType: "success",
          }
        }}
        onGetAPIEndPointFn={(formState) => {
          return {
            url: `${userDelegationAPI.url}/${uiType === "add" ? (user_id) : (formState.id)}`,
            key: userDelegationAPI.key
          }
        }}
        onChangeRequestBodyFn={(formObject) => {
          return getDataByFormObject({
            ...formObject,
            status: parseInt(formObject.status) === 1 ? true : false,
            default_delegation: false,
            expiry_at: dateObjectToString(formObject.expiry_at, "YY-mm-dd")
          });
        }}
        callApiObject={{
          isSetHeaders: true,
          method: (uiType === "remove" ? ("delete") : (uiType === "Edit" ? "put" : "post")),
        }}
        apiDataStoringObject={{
          setLoader: true,
          storingType: apiResponseStoringType.responseLinkToFrom,
        }}
        onResponseCallBackFn={(response, error) => {
          if(response?.data?.errors?.details[0].message)
          uiAction.setFlashMessage({
            status: true,
            message: response?.data?.errors?.details[0].message,
            messageType: "error"
        });
          if (
            response.length === undefined &&
            error === undefined &&
            response.data.errors === undefined
          ) {
            onClosePopupFn();
          }
        }}
      />
    </Fragment>
  );
};

export {
  AdditionalRolesAndLocations,
  CustomButtons
};
