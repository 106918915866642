
const authUser = {
  "userName": "inova",
  "password": "inova@123"
}


const normalLicenseDummy = {
  "ks-5555": {
    ownerInformation: {
      name: "R.M.L JAYASUNDARA",
      mobileNumber: "072-4062503",
      identificationNumber: "567402408V",
      address1: "NO:5",
      address2: "SULEIMAN TERRACE",
      city: "COLOMBO 05",
      absoluteOwner: "NATIONAL DEVELOPMENT BANK PLC",
      absoluteOwnerAddress1: "NO:40",
      absoluteOwnerAddress2: "NAWAM MAWATHA",
      absoluteOwnerCity: "COLOMBO 02",
      isSMS: "NO",
      additionalInformation: "",
      previousOwner: [
        {
          "name": "L.M.S Gunawardana",
          "idNo": "567401409V",
          "mobile": "077-1067509",
          "address": "No. 03 Highlevel Road, Pannipitiya",
          "province": "Western",
          "trDate": "07-01-2016"
        }
      ]
    },
    vehicleInformation: {
      chassis: "KSP902053525",
      tare: "990.00 KG",
      vehicleStatus: "ELIGIBLE",
      engine: "1KR-0311587",
      gross: "-",
      fuelType: "PETROL",
      regDate1: "27-04-2011",
      seats: "4",
      class: "MOTOR CAR",
      province: "WESTERN",
      manufactureYear: "2008",
      cylinderCapacity: "990.00 CC",
      tareSize: "165 x 14",
      wheelBase: "0 CM",
      typeOfBody: "CLOSED",
      make: "TOYOTA",
      model: "VITZ",
      colors: "BLACK",
    },
    licenseArrears: {
      from: "27-04-2019",
      to: "26-04-2020",
      totalFee: "2,860.00",
      totalFine: "660.00",
      additions: "00.00",
      deduction: "00.00",
      totalAmount: "3,520.00",
      licenseArrearsList: [
        {
          from: "27-04-2019",
          to: "26-04-2020",
          fee: "2,200.00",
          fine: "660.00",
        },
        // {
        //   from: "2020-12-01",
        //   to: "2021-12-01",
        //   fee: "2,000.00/=",
        //   fine: "500.00/=",
        // },
        // {
        //   from: "2020-12-01",
        //   to: "2021-12-01",
        //   fee: "2,000.00/=",
        //   fine: "500.00/=",
        // },
      ],
    },
    licensePaymentHistoryList: [
      {
        year: "2018",
        txNum: "0000000000009",
        regNo: "48622628",
        validity: "27-04-2018 - 26-04-2019",
        fee: "2,200.00",
        total: "2,860.00",
        fine: "660.00",
        additions: "00.00",
        deductions: "00.00",
        user: "K.L Jayasundara",
        licence: "NL",
        transType: "AR",
        transDate: "10-05-2019",
        location: "WESTERN"
      },
      {
        year: "2017",
        txNum: "0000000000001",
        regNo: "58622614",
        validity: "27-04-2017 - 26-04-2018",
        fee: "2,200.00",
        total: "2,200.00",
        fine: "0.00",
        additions: "00.00",
        deductions: "00.00",
        user: "T.L Warnasekara",
        licence: "NL",
        transType: "LIC",
        transDate: "26-04-2018",
        location: "WESTERN"
      },
      {
        year: "2016",
        txNum: "0000000000002",
        regNo: "48622628",
        validity: "27-04-2016 - 26-04-2017",
        fee: "2,200.00",
        additions: "00.00",
        deductions: "00.00",
        user: "M.L Senevirathna",
        total: "2,420.00",
        fine: "220.00",
        licence: "NL",
        transType: "LIC",
        transDate: "10-05-2016",
        location: "WESTERN"
      }
    ],
    documentValidation: {
      "isSetVET": true,
      "isSetInsurance": true,
      "isSetFitness": true,
      "isSetRoutePerm": true,
    }
  },
  "ks-6666": {
    ownerInformation: {
      name: "R.M.L JAYASUNDARA",
      mobileNumber: "072-4062503",
      identificationNumber: "567402408V",
      address1: "NO:5",
      address2: "SULEIMAN TERRACE",
      city: "COLOMBO 05",
      absoluteOwner: "NATIONAL DEVELOPMENT BANK PLC",
      absoluteOwnerAddress1: "NO:40",
      absoluteOwnerAddress2: "NAWAM MAWATHA",
      absoluteOwnerCity: "COLOMBO 02",
      isSMS: "NO",
      additionalInformation: "",
      previousOwner: [
        {
          "name": "L.M.S Gunawardana",
          "idNo": "567401409V",
          "mobile": "077-1067509",
          "address": "No. 03 Highlevel Road, Pannipitiya",
          "province": "Western",
          "trDate": "07-01-2016"
        }
      ]
    },
    vehicleInformation: {
      chassis: "KSP902053525",
      tare: "990.00 KG",
      vehicleStatus: "ELIGIBLE",
      engine: "1KR-0311587",
      gross: "-",
      fuelType: "PETROL",
      regDate1: "27-04-2011",
      seats: "4",
      class: "MOTOR CAR",
      province: "WESTERN",
      manufactureYear: "2008",
      cylinderCapacity: "990.00 CC",
      tareSize: "165 x 14",
      wheelBase: "0 CM",
      typeOfBody: "CLOSED",
      make: "TOYOTA",
      model: "VITZ",
      colors: "BLACK",
    },
    licenseArrears: {
      from: "27-04-2019",
      to: "26-04-2020",
      totalFee: "2,860.00",
      totalFine: "660.00",
      additions: "00.00",
      deduction: "00.00",
      totalAmount: "3,520.00",
      licenseArrearsList: [
        {
          from: "27-04-2019",
          to: "26-04-2020",
          fee: "2,200.00",
          fine: "660.00",
        },
        // {
        //   from: "2020-12-01",
        //   to: "2021-12-01",
        //   fee: "2,000.00/=",
        //   fine: "500.00/=",
        // },
        // {
        //   from: "2020-12-01",
        //   to: "2021-12-01",
        //   fee: "2,000.00/=",
        //   fine: "500.00/=",
        // },
      ],
    },
    licensePaymentHistoryList: [
      {
        year: "2018",
        txNum: "0000000000009",
        regNo: "48622628",
        validity: "27-04-2018 - 26-04-2019",
        fee: "2,200.00",
        total: "2,860.00",
        fine: "660.00",
        additions: "00.00",
        deductions: "00.00",
        user: "K.L Jayasundara",
        licence: "NL",
        transType: "AR",
        transDate: "10-05-2019",
        location: "WESTERN"
      },
      {
        year: "2017",
        txNum: "0000000000001",
        regNo: "58622614",
        validity: "27-04-2017 - 26-04-2018",
        fee: "2,200.00",
        total: "2,200.00",
        fine: "0.00",
        additions: "00.00",
        deductions: "00.00",
        user: "T.L Warnasekara",
        licence: "NL",
        transType: "LIC",
        transDate: "26-04-2018",
        location: "WESTERN"
      },
      {
        year: "2016",
        txNum: "0000000000002",
        regNo: "48622628",
        validity: "27-04-2016 - 26-04-2017",
        fee: "2,200.00",
        additions: "00.00",
        deductions: "00.00",
        user: "M.L Senevirathna",
        total: "2,420.00",
        fine: "220.00",
        licence: "NL",
        transType: "LIC",
        transDate: "10-05-2016",
        location: "WESTERN"
      }
    ],
    documentValidation: {
      "isSetVET": true,
      "isSetInsurance": true,
      "isSetFitness": true,
      "isSetRoutePerm": true,
    }
  },
};


const dealerLicenseDummy = {
  "00001": {
    ownerInformation: {
      name: "Rohana Traders",
      officeAddress1: "5 Sulaiman Terrace",
      officeAddress2: "Thimbirigasyaya",
      officeCity: "Colombo 5",
      officeDistrict: "Colombo",
      garageAddress1: "5 Sulaiman Terrace",
      garageAddress2: "Thimbirigasyaya",
      garageCity: "Colombo 5",
      garageDistrict: "Colombo",
      province: "Western"
    },
    currentLicences: [
      {
        vahicleNo: "SG K20-1761",
        licNo: "SG9851309768",
        serial: "20083185",
        formTo: "01-01-2020 - 01-01-2021",
        insComapny: "Sri Lanka Insurance Corporation",
        insDate: "02-03-2021",
      },
      {
        vahicleNo: "SG K20-1762",
        licNo: "SG9851309779",
        serial: "20083186",
        formTo: "01-01-2020 - 01-01-2021",
        insComapny: "LOLC Insurance Company Limited",
        insDate: "02-02-2021",
      }
    ],
    pendingLicense: [
      {
        vahicleNo: "SP G20-1553",
        formTo: "01-01-2020 - 01-01-2021",
        insComapny: "Allianz Insurance Lanka Ltd",
        insDate: "02-01-2021",
        reqID: "2345",
        reqBY: "Sadun (Operator)",
        reqLoc: "Habaraduwa",
      },
      {
        vahicleNo: "SP G20-1553",
        formTo: "01-01-2020 - 01-01-2021",
        insComapny: "AIA General Insurance Lanka Limited",
        insDate: "22-01-2021",
        reqID: "2345",
        reqBY: "Sadun (Operator)",
        reqLoc: "Habaraduwa",
      },
    ],
    paymentHistory: [
      {
        txNo: "00000000000000000001",
        txDate: "30-12-2019  15:35",
        totalAmount: "500.00",
        province: "Western",
        authority: "Kaduwela",
      },
      {
        txNo: "00000000000000000002",
        txDate: "30-12-2019  13:26",
        totalAmount: "500.00",
        province: "Western",
        authority: "Kaduwela",
      }
    ],
    licenceAmount: {
      "fee": "1000.00",
      "otherCharges": "00.00",
      "totalAmount": "1000.00",
    }
  }
}

const notification = [
  {
    type: "new",
    requestedAt: "10/10/2020 12:50",
    requestedBy: "K.L Jayasundara",
    requestType: "New License Request",
    requestDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
  }, {
    type: "new",
    requestedAt: "09/10/2020 15:50",
    requestedBy: "S.L Peiris",
    requestType: "New License Request",
    requestDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
  }, {
    type: "new",
    requestedAt: "08/10/2020 14:50",
    requestedBy: "L.S Perera",
    requestType: "New License Request",
    requestDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
  }, {
    type: "new",
    requestedAt: "07/10/2020 08:00",
    requestedBy: "K.L Jayasundara",
    requestType: "New License Request",
    requestDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
  }, {
    type: "new",
    requestedAt: "06/10/2020 16:30",
    requestedBy: "L.S Perera",
    requestType: "New License Request",
    requestDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
  }, {
    type: "read",
    requestedAt: "01/10/2020 18:50",
    requestedBy: "M.G Gunawardana",
    requestType: "New License Request",
    requestDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
  }, {
    type: "read",
    requestedAt: "28/09/2020 11:30",
    requestedBy: "J.K Perera",
    requestType: "New License Request",
    requestDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
  }, {
    type: "read",
    requestedAt: "25/09/2020 13:20",
    requestedBy: "K.L Jayasundara",
    requestType: "New License Request",
    requestDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
  }, {
    type: "read",
    requestedAt: "20/09/2020 12:50",
    requestedBy: "S.S Peiris",
    requestType: "New License Request",
    requestDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
  }, {
    type: "read",
    requestedAt: "01/10/2020 14:40",
    requestedBy: "M.G Gunawardana",
    requestType: "New License Request",
    requestDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
  }
];

export {
  normalLicenseDummy,
  dealerLicenseDummy,
  authUser,
  notification
}