/*
 * @Author: Chanaka Wickramasinghe
 * @Date: 2020-02-10 14:02:32
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-08 18:58:35
 */

const templateTypes = {
    material: "material",
    bootstrap: "bootstrap"
};

const defaultTemplateType = templateTypes.bootstrap;

const defaultPrecisionPoint = 2;

const roundUpTypes = {
    ceil: "ceil",
    floor: "floor",
    round: "round",
    none: "none"
};

const defaultRoundUpType = roundUpTypes.floor;

const defaultPrecisionValue = 0.00;

/**
 * --------------------------------------------
 * @Description: Base template colors
 * --------------------------------------------
 */
const baseTemplate={
    palette: {
        primary: {
            light: '#2196f3',
            main: '#2196f3',
            dark: '#2196f3',
        },
        secondary: {
            light: '#2196f3',
            main: '#2196f3',
            dark: '#2196f3',
        },
    }
};

const formCacheLevel={
    "none":"NONE",
    "updateOnFormGroupChange":"FORMGROUP",
    "updateOnIndividual":"INDIVIDUAL"
}

const screenSizeTypes={
    "largeDevice":{
        "width" :1200,
        "key":"DESKTOP"
    },"mediumDevice":{
        "width" :992,
        "key":"TAB"
    },"smallDevice":{
        "width" :768,
        "key":"MOBILE"
    }
}

export {
    templateTypes, 
    defaultTemplateType,
    defaultPrecisionPoint,
    roundUpTypes,
    defaultRoundUpType,
    defaultPrecisionValue,
    baseTemplate,
    formCacheLevel,
    screenSizeTypes
};