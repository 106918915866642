import React, { Fragment } from "react";
import { Navigate } from '../../ui-components/ui-elements/common/Navigate';
import { permissions } from "../../../config/permission.config";
import { ManageVRPCompany } from "./pages/ManageVrpCompany";
import authorized from "../../hoc-middlewares/Authorized";

const VehicleRoutePermit = () => {
    return (
        <Fragment>
            <Navigate
                exact={true}
                path="/manage-vrp"
                routeKey={"manage-vrp"}
                component={authorized(ManageVRPCompany)}
                routePermissions={permissions.VRP_COMPANY_MANAGE.permissions}
            />
        </Fragment>
    );
};

export default VehicleRoutePermit;
