/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-03-21 15:02:30
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-03-08 12:44:48
 */


import { mockApiBaseURL, adminBffBaseURL, } from "./core.config";

export const paginAPI = {
  url: `${mockApiBaseURL}api/master/dummies/paging`,
  key: 'paginResultKEY',
};

//Fuel type List
export const fuelTypeListApi = {
  url: `${adminBffBaseURL}api/bff/licence-service/fuel-types`,
  key: "getFuelTypeListKEY",
};

//Licence type List
export const licenceTypeListApi = {
  url: `${adminBffBaseURL}api/bff/licence-service/licence-types`,
  key: "getLicenceTypeListKEY",
};
export const vetCompanyAPI = {
  url: `${adminBffBaseURL}api/bff/organization-service/vet-companies`,
  key: "vetCompanyAPIAPIKey",
};

export const vehicleClassAPI = {
  url: `${adminBffBaseURL}api/bff/licence-service/vehicle-classes`,
  key: "vehicleClassAPIKey",
};

export const vehicleConditionListApi = {
  url: `${adminBffBaseURL}api/bff/licence-service/vehicle-conditions`,
  key: "vehicleConditionKEY",
};

//counter API
export const countersListApi = {
  url: `${adminBffBaseURL}api/bff/organization-service`,
  key: "counterKEY"
};

export const countersListSearchApi = {
  url: `${adminBffBaseURL}api/bff/organization-service/authorities`,
  key: "counterKEY"
};

export const counterCreateApi = {
  url: `${adminBffBaseURL}api/bff/organization-service/authorities`,
  key: "counterKEY"
}

export const counterUpdateApi = {
  url: `${adminBffBaseURL}api/bff/organization-service/authorities`,
  key: "counterKEY"
}

export const usedCounterSerialNumbersListApi = {
  url: `${adminBffBaseURL}api/bff/organization-service/counters`,
  key: "usedCounterSerialKEY"
}

export const unusedCounterSerialNumbersListApi = {
  url: `${adminBffBaseURL}api/bff/organization-service/counters`,
  key: "unusedCounterSerialKEY"
}

export const counterSerialNumbersCreateApi = {
  url: `${adminBffBaseURL}api/bff/organization-service/counters`,
  key: "counterSerialKEY"
}

export const counterSerialNumbersDeleteApi = {
  url: `${adminBffBaseURL}api/bff/organization-service/counters`,
  key: "counterSerialDeleteKEY"
}
// Delivery methods
export const deliveryMethodListApi = {
  url: `${adminBffBaseURL}api/bff/licence-service/delivery-methods`,
  key: "deliveryMethodKEY",
};

export const deliveryMethodCreateApi = {
  url: `${adminBffBaseURL}api/bff/licence-service/delivery-methods`,
  key: "deliveryMethodKEY",
};

export const deliveryMethodEditApi = {
  url: `${adminBffBaseURL}api/bff/licence-service/delivery-methods`,
  key: "deliveryMethodKEY",
};

//Delivery charges 
export const deliveryChargeListApi = {
  url: `${adminBffBaseURL}api/bff/licence-service`,
  key: "deliveryChargeKEY"
}

export const deliveryChargeEditApi = {
  url: `${adminBffBaseURL}api/bff/licence-service`,
  key: "deliveryChargeKEY"
}

export const provinceListApi = {
  url: `${adminBffBaseURL}api/bff/organization-service/provinces/list`,
  key: "provinceListKEY"
}

export const AuthorityByServiceIdApi = {
  url: `${adminBffBaseURL}api/bff/organization-service/services/get-authority`,
  key: "authorityByServiceIdKEY"
}

export const AuthorityListApi = {
  url: `${adminBffBaseURL}api/bff/organization-service/authorities/list`,
  key: "authorityListKEY"
}

// dashboard data api
export const DashboardDataApi = {
  url: `${adminBffBaseURL}api/bff/dashboard/get-data`,
  key: "dashboardDataKEY"
}

//Holiday configuration
export const holidayConfigurationListAPI = {
  url: `${adminBffBaseURL}api/bff/organization-service/holiday-configurations`,
  key: "getHolidayConfigurationListKEY",
};

export const generalConfigAPI = {
  url: `${adminBffBaseURL}api/bff/licence-service/general-configurations`,
  key: "generalConfigAPIKey",
};

export const renewPeriodLicenceTypeAPI = {
  url: `${adminBffBaseURL}api/renewable-period-for-licence-types`,
  key: "renewPeriodLicenceTypeAPIKey",
}


//Banks List
export const bankListApi = {
  url: `${adminBffBaseURL}api/bff/organization-service/banks`,
  key: "getBankListKEY",
};

//Web News
export const getWebPortalNewsAPI = {
  url: `${adminBffBaseURL}api/bff/web-service/web-portal-news`,
  key: "getWebPortalNewsApiKEY"
}

export const insuranceCompany = {
  url: `${adminBffBaseURL}api/bff/organization-service/insurance-companies`,
  key: "insuranceCompanyKey",
};

export const pdmtListAPI = {
  url: `${adminBffBaseURL}api/bff/organization-service/province-offices`,
  key: "pdmtListAPIKey",
};

export const districtListAPI = {
  url: `${adminBffBaseURL}api/bff/organization-service/districts/list`,
  key: "districtListAPIKey",
};

export const paymentCategoriesListAPI = {
  url: `${adminBffBaseURL}api/bff/licence-service/payment-types/list`,
  key: "paymentCategoriesListAPIKey",
};

//Authority 

export const authorityListAPI = {
  url: `${adminBffBaseURL}api/bff/organization-service/authorities`,
  key: "authorityListAPIKey",
};

//Transaction Types 

export const transactionTypeListAPI = {
  url: `${adminBffBaseURL}api/bff/licence-service/transaction-types/list`,
  key: "transactionTypeListAPIKey",
};

//License Types 

export const licenseTypeListAPI = {
  url: `${adminBffBaseURL}api/bff/licence-service/licence-types/list`,
  key: "licenseTypeListAPIKey",
};

//Authority Type

export const authorityTypeListAPI = {
  url: `${adminBffBaseURL}api/bff/organization-service/authority-types/list`,
  key: "authorityTypeListAPIKey",
};

//Province

export const provincesOfficeByServiceIdAPI = {
  url: `${adminBffBaseURL}api/bff/organization-service/services/get-pdmt`,
  key: "provincesOfficeByServiceIdAPIKey",
}

export const provincesOfficesListAPI = {
  url: `${adminBffBaseURL}api/bff/organization-service/province-offices/list`,
  key: "provincesOfficesListAPIKey",
}

export const provinceListAPI = {
  url: `${adminBffBaseURL}api/bff/organization-service/provinces/list`,
  key: "provinceListAPIKey",
};

export const usedAuthoritySerialNumbersListApi = {
  url: `${adminBffBaseURL}api/bff/organization-service/authorities`,
  key: "usedAuthoritySerialKEY"
}

export const unusedAuthoritySerialNumbersListApi = {
  url: `${adminBffBaseURL}api/bff/organization-service/authorities`,
  key: "unusedAuthoritySerialKEY"
}

export const authoritySerialNumbersCreateApi = {
  url: `${adminBffBaseURL}api/bff/organization-service/authorities`,
  key: "authoritySerialKEY"
}

export const authoritySerialNumbersDeleteApi = {
  url: `${adminBffBaseURL}api/bff/organization-service/authorities`,
  key: "authoritySerialDeleteKEY"
}
export const authoritiesListAPI = {
  url: `${adminBffBaseURL}api/bff/organization-service/authorities/list`,
  key: "authoritiesListAPIKey",
};

export const serviceOperationTypeAllowedAPI = {
  url: `${adminBffBaseURL}api/bff/organization-service/operation-types-allowed`,
  key: "serviceOperationTypeAllowedAPIKey",
};

export const authorityGetByIdAPI = {
  url: `${adminBffBaseURL}api/bff/organization-service/authorities`,
  key: "authorityAPIKey",
};

//Holiday Types

export const holidayTypesListAPI = {
  url: `${adminBffBaseURL}api/bff/organization-service/holiday-types/list`,
  key: "holidayTypesListAPI",
};

export const rateListAPI = {
  url: `${adminBffBaseURL}api/bff/licence-service/rates`,
  key: "rateListAPIKey"
};

export const chargingCategoryListAPI = {
  url: `${adminBffBaseURL}api/bff/licence-service/charging-categories`,
  key: "chargingCategoryListAPIKey"
};

//Addition deduction 
export const additionDeductionListAPI = {
  url: `${adminBffBaseURL}api/bff/licence-service/additions-deductions`,
  key: "additionDeductionListAPIKey",
};

export const additionDeductionCreateAPI = {
  url: `${adminBffBaseURL}api/bff/licence-service/additions-deductions`,
  key: "additionDeductionCreateAPIKey",
};

export const additionDeductionEditAPI = {
  url: `${adminBffBaseURL}api/bff/licence-service/additions-deductions`,
  key: "additionDeductionEditAPIKey",
};

export const additionDeductionDeleteAPI = {
  url: `${adminBffBaseURL}api/bff/licence-service/additions-deductions`,
  key: "additionDeductionDeleteAPIKey",
};

//Licence type List for dropdown
export const licenceTypesListApi = {
  url: `${adminBffBaseURL}api/bff/licence-service/licence-types/list`,
  key: "LicenceTypesListKEY",
};

//Vehicle class List for dropdown
export const vehicleClassListAPI = {
  url: `${adminBffBaseURL}api/bff/licence-service/vehicle-classes/list?active=true`,
  key: "vehicleClassListAPIKey",
};

//Fuel type List for dropdown 
export const fuelTypesListAPI = {
  url: `${adminBffBaseURL}api/bff/licence-service/fuel-types/list?active=true`,
  key: "fuelTypesListAPIKey",
};

//Charge Component List for dropdown 
export const chargeComponentsListAPI = {
  url: `${adminBffBaseURL}api/bff/licence-service/charge-components/list`,
  key: "chargeComponentsListAPIKey",
};

export const fineConfigurationsListAPI = {
  url: `${adminBffBaseURL}api/bff/licence-service/fine-configurations`,
  key: "fineConfigurationsListAPIKey",
};
//User Service

export const userAuthServiceAPI = {
  url: `${adminBffBaseURL}auth`,
  key: "userAuthServiceAPIKey",
};

export const userTitlesListAPI = {
  url: `${adminBffBaseURL}api/bff/user-service/user-tittles/list`,
  key: "userTitlesListAPIKey",
};

export const userRolesAPI = {
  url: `${adminBffBaseURL}api/bff/user-service/roles`,
  key: "userRolesAPIKey",
};

export const userRolesListAPI = {
  url: `${adminBffBaseURL}api/bff/user-service/roles`,
  key: "userRolesListAPIKey",
};

export const userRolesPermissionsListAPI = {
  url: `${adminBffBaseURL}api/bff/user-service/roles/permissions`,
  key: "userRolesPermissionsListAPIKey",
};

export const userRolesLevelListAPI = {
  url: `${adminBffBaseURL}api/bff/user-service/user-role-level/list`,
  key: "userRolesLevelListAPIKey",
};

export const userRoleTypeListAPI = {
  url: `${adminBffBaseURL}api/bff/user-service/user-role-type/list`,
  key: "userRoleTypeListAPIKey",
};

export const userRoleCategoryListAPI = {
  url: `${adminBffBaseURL}api/bff/user-service/user-role-category/list`,
  key: "userRoleCategoryListAPIKey",
};

export const internalUserAPI = {
  url: `${adminBffBaseURL}api/bff/user-service/users/internal-user`,
  key: "internalUserCreateAPIKey",
};

export const tempInternalUserAPI = {
  url: `${adminBffBaseURL}api/bff/user-service/users/internal-user-temp`,
  key: "tempInternalUserCreateAPIKey",
};

export const userDefaultDelegationAPI = {
  url: `${adminBffBaseURL}api/bff/user-service/users/user-default-delegation`,
  key: "userDefaultDelegationKEY"
}

export const userDelegationAPI = {
  url: `${adminBffBaseURL}api/bff/user-service/users/user-delegation`,
  key: "userDelegationKEY"
}

export const userDelegationCreateAPI = {
  url: `${adminBffBaseURL}api/bff/user-service/users/user-delegation`,
  key: "userDelegationCreateKEY"
}

export const tempUserDelegationCreateAPI = {
  url: `${adminBffBaseURL}api/bff/user-service/users/temp-user-default-delegation`,
  key: "tempUserDelegationCreateKEY"
}

export const userDelegationChangeAPI = {
  url: `${adminBffBaseURL}api/bff/user-service/users`,
  key: "userDelegationChangeKEY"
}

export const userPasswordResetAPI = {
  url: `${adminBffBaseURL}api/bff/user-service/users/reset-password`,
  key: "userPasswordResetKEY"
}

export const userPasswordChangeAPI = {
  url: `${adminBffBaseURL}api/bff/user-service/users/password-update`,
  key: "userPasswordChangeAPIKey"
}

export const userInfoAPI = {
  url: `${adminBffBaseURL}api/bff/user-service/users/user-login`,
  key: "userInfoAPIKEY"
}

export const refreshTokenAPI = {
  url: `${adminBffBaseURL}api/bff/user-service/users/token-refresh`,
  key: "refreshTokenAPIKey",
};

export const userLogoutAPI = {
  // url:`${adminBffBaseURL}auth/realms/erl-internal-user/protocol/openid-connect/logout`,
  url: `${adminBffBaseURL}api/bff/user-service/users/logout`,
  key: "userLogoutAPIKEY"
}

export const userRoleWithoutDeligationAPI = {
  url: `${adminBffBaseURL}api/bff/user-service/roles/user-role-list`,
  key: "userRoleWithoutDeligationAPIKEY"
}

export const vrpCompanyListAPI = {
  url: `${adminBffBaseURL}api/bff/organization-service/vrp-companies`,
  key: "vrpCompanyListAPIKey",
};

export const vrpCertificateOfficersListAPI = {
  url: `${adminBffBaseURL}api/bff/organization-service/vrp-companies`,
  key: "vrpCertificateOfficersListAPIKey",
};
export const vftListAPI = {
  url: `${adminBffBaseURL}api/bff/organization-service/vft-companies`,
  key: "vftListAPIKey",
};

export const pdmtOfficesListAPI = {
  url: `${adminBffBaseURL}api/bff/organization-service/province-offices`,
  key: "pdmtListAPIKey",
};

const vft_company_id = "0ea9aaaf-9995-43eb-baa8-7c7073bff561";
export const vftCertificateOfficersListAPI = {
  url: `${adminBffBaseURL}api/bff/organization-service/vft-companies`,
  key: "vftCertificateOfficersListAPIKey",
};

export const vftCertificateListAPI = {
  url: `${adminBffBaseURL}api/bff/organization-service/vft-companies`,
  key: "vftCertificateListKey",
};

export const authoritySerialNumbersListAPI = {
  url: `${adminBffBaseURL}api/bff/organization-service/authorities`,
  key: "authoritySerialListKEY"
}

export const counterSessionEditAPI = {
  url: `${adminBffBaseURL}api/bff/organization-service/counters`,
  key: "counterSessionEditKEY"
}

export const getAuditUsernameAPI = {
  url: `${adminBffBaseURL}api/bff/user-service/users/get-username-by-user-id/`,
  key: "getAuditUsernameAPIKey"
}

export const getAuditLocationNameAPI = {
  url: `${adminBffBaseURL}api/bff/organization-service/services/get-location-name/`,
  key: "getAuditLocationNameAPIKey"
}

export const resetDeviceAPI = {
  url: `${adminBffBaseURL}api/bff/user-service/users/device-reset`,
  key: "getAuditUsernameAPIKey"
}

export const generatePDFAPI = {
  url: `${adminBffBaseURL}api/bff/generate-pdf`,
  key: "generatePDFAPIKey"
}

export const notificationsAPI = {
  url: `${adminBffBaseURL}api/bff/notification-service/notify-messages`,
  key: "notificationsAPIKey",
};

export const transactionTypeAPI = {
  url: `${adminBffBaseURL}api/bff/licence-service/transaction-types`,
  key: "transactionTypeAPIKey",
}

// operation types
export const operationTypesListAPI = {
  url: `${adminBffBaseURL}api/bff/organization-service/counter-operation-types/list`,
  key: "operationTypesListAPIKey",
};

export const counterAllowedOperationTypesListApi = {
  url: `${adminBffBaseURL}api/bff/organization-service/counters`,
  key: "counterAllowedOperationTypesKEY"
}

export const counterAllowedLicenceTypesListApi = {
  url: `${adminBffBaseURL}api/bff/organization-service/counters`,
  key: "counterAllowedLicenceTypesKEY"
}

export const userInternalDefaultDelegationAPI = {
  url: `${adminBffBaseURL}api/bff/user-service/users/user-internal-default-delegation`,
  key: "userInternalDefaultDelegationKEY"
};

export const dealerFileUploadAPi = {
  url: `${adminBffBaseURL}api/bff/organization-service/dealer-file`,
  key: "dealerFileCreateAPiKEY"
};

export const dealerFileCreateAPi = {
  url: `${adminBffBaseURL}api/bff/organization-service/create-dealer`,
  key: "dealerFileCreateAPiKEY"
};

export const dealerFileUpdateAPi = {
  url: `${adminBffBaseURL}api/bff/organization-service/update-dealer-file-info`,
  key: "dealerFileUpdateApiKEY"
};

export const dealerFileGetAPi = {
  url: `${adminBffBaseURL}api/bff/organization-service/dealer-files`,
  key: "dealerFileGetAPiKEY"
};

export const dealerFileDeleteAPi = {
  url: `${adminBffBaseURL}api/bff/organization-service/dealer-file`,
  key: "dealerFileGetAPiKEY"
};


export const userAdminDefaultDelegationAPI = {
  url: `${adminBffBaseURL}api/bff/user-service/users/user-admin-default-delegation`,
  key: "userAdminDefaultDelegationKEY"
};

export const DMSMediatorApi = {
  url: `${adminBffBaseURL}dms/v1/dms-mediator`,
  key: "DMSmediatorAPIKey"
}


export const CreateReasonAPI = {
  url: `${adminBffBaseURL}api/bff/licence-service/reason`,
  key: "CreateReasonAPIKEY"
};



