import React from 'react';
import {
    FormWrapper,
    SelectBoxWithState,
    InputBoxWithState,
} from "../../../../ui-components/ui-elements/form";
import { _get } from '../../../../../helpers/common-helpers/lodash.wrappers';

const DeliveryMethodForm = (props) => {
    const formKey = `${props.fromParent.formKey}`;

    return (
        <FormWrapper
            setGroupName={formKey}
            formGroupLinkWith={formKey}
            setFormObject={{
                id: _get(props, "row.id", undefined),
                name: _get(props, "row.name", ""),
                name_sin: _get(props, "row.name_sin", ""),
                name_ta: _get(props, "row.name_ta", ""),
                active: _get(props, "row.active", 1),
            }}
            onDestroyUnsetFormObject={true}
        >
            <InputBoxWithState
                formGroupName={formKey}
                inputName={"name"}
                labelText="Delivery Method Name in English"
                //inputPlaceholder={"Delivery Method Name in English"}
                isRequired={true}
                maxLength = "50"
            />
            <InputBoxWithState
                formGroupName={formKey}
                inputName={"name_sin"}
                labelText="Delivery Method Name in Sinhala"
                //inputPlaceholder={"Delivery Method Name in Sinhala"}
                isRequired={true}
                maxLength = "50"
            />

            <InputBoxWithState
                formGroupName={formKey}
                inputName={"name_ta"}
                labelText="Delivery Method Name in Tamil"
                //inputPlaceholder={"Delivery Method Name in Tamil"}
                isRequired={true}
                maxLength = "50"
            />
            <SelectBoxWithState
                formGroupName={formKey}
                dataList={[
                    {
                        id: "1",
                        value: "Active",
                    },
                    {
                        id: "0",
                        value: "Inactive",
                    },
                ]}
                inputName={"active"}
                labelText="Status"
                isRequired={false}
                emptySelectOptionTxt={null}
            />
        </FormWrapper>
    );
};

export { DeliveryMethodForm };