import React, { Fragment } from 'react';
import { Navigate } from "../../ui-components/ui-elements/common/Navigate";
import { permissions } from "../../../config/permission.config";
import guest from "../../hoc-middlewares/Guest";
import authorized from "../../hoc-middlewares/Authorized";
import { UserRole } from './pages/UserRole';

const UserRoleRoutes = () => {
    return (
        <Fragment>
            <Navigate exact={true} path="/userRole" routeKey={"role"} component={authorized(UserRole)} routePermissions={permissions.USER_ROLE_MANAGE.permissions} />
        </Fragment>
    )
};

export default UserRoleRoutes;