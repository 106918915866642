import React, { Fragment } from "react";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import {
  AuditDetail,
  UICard,
  ViewDetailBlock,
} from "../../../../ui-components/ui-elements/common/BaseElements";

const View = (props) => {
  const numberOfLabelCol = 4

  return (
    <Fragment>
      <UICard>
        <ViewDetailBlock
          label={"Bank Code"}
          value={_get(props, `row.code`, '')}
          labelCol={numberOfLabelCol}
        />
        <ViewDetailBlock
          label={"Bank Name"}
          value={_get(props, `row.service.name`, '')}
          labelCol={numberOfLabelCol}
        />
        <ViewDetailBlock
          label={"Bank Status"}
          value={_get(props, `row.service.active`, '')=='1'?"Active":"Inactive"}
          labelCol={numberOfLabelCol}
        />
      </UICard>

      <div className="defaultHalfMarginTop">
        <AuditDetail
          createdById={_get(props, `row.service.created_by`, '')}
          createdLocationId={_get(props, `row.service.created_location`, '')}
          createdRoleId={_get(props, `row.service.created_role_id`, '')}
          createdAt={dateObjectToString(_get(props, `row.service.created_at`, ''), "dd/mm/YY HH:MM")}
          updatedById={_get(props, `row.service.updated_by`, '')}
          updatedLocationId={_get(props, `row.service.updated_location`, '')}
          updatedAt={dateObjectToString(_get(props, `row.service.updated_at`, ''), "dd/mm/YY HH:MM")}
          updatedRoleId={_get(props, `row.service.updated_role_id`, '')}
          labelCol={numberOfLabelCol}
        />
      </div>
    </Fragment>
  );
};

export { View };
