/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2021-03-02 14:36:57 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-03-02 22:13:45
 */
import React, { useState, useContext, useMemo, useEffect } from "react";

import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import ExpansionPanelWrapper from "./ExpansionCard";
import { CheckBox } from "../form/CheckBox";
import { FormContext } from "../../../modules/core/context-providers/FormContext.provider";

const emptyFun = (...para) => undefined;

const PanelTitle = ({
    labelText = "",
    inputValue = false,
    allSubPermition = [],
    selectedList=[],
    uiType="",
    onChangeFn = emptyFun
}) => {

    if(inputValue===false){
        inputValue=allSubPermition[labelText].every(val => selectedList.includes(val));
    }
    if(inputValue===true && allSubPermition[labelText].every(val => selectedList.includes(val))=== false ){
        inputValue=false;
    }
    return (
        <span>
            <CheckBox
                labelText={labelText}
                inputValue={inputValue}
                onChangeFn={onChangeFn}
                isDisabled={uiType==="view"?true:false}
            />
        </span>
    )
}

const PanelBody = ({
    subPermissions = [],
    checkBoxWrapperStyle = "col-md-6",
    subPermissionUniqueKey = "id",
    subPermissionDisplayNameKey = "value",
    selectedList = [],
    uiType="",
    onChangeFn = emptyFun
}) => {
    return (
        <div className="col-md-12">
            <div className="row">
                {
                    subPermissions.map((object, index) => {
                        return (
                            <div className={checkBoxWrapperStyle}>
                                <CheckBox
                                    labelText={_get(object, subPermissionDisplayNameKey, "")}
                                    inputValue={selectedList.indexOf(_get(object, subPermissionUniqueKey, null)) === -1 ? false : true}
                                    onChangeFn={(_) => onChangeFn(_get(object, 'id', null))}
                                    isDisabled={uiType==="view"?true:false}
                                />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

const PermissionsPanel = ({
    dataList = [],
    groupKey = "id",
    groupDisplayNameKey = "value",
    subPermissionKey = "sub_permission",
    subPermissionUniqueKey = "id",
    subPermissionDisplayNameKey = "value",
    formGroupName = "",
    inputStatePath = "",
    inputName = "",
    uiType= ""
}) => {
    const [getState, setState] = useState(dataList);
    const [formState, formAction] = useContext(FormContext);
    const allSubPer = [];

    useEffect(() => {
        setState(dataList);
    }, [dataList]);

    const currentValue = useMemo(() => {
        return _get(formState, inputStatePath ? inputStatePath : `${formGroupName}.${inputName}`, [])
    }, [inputStatePath, formGroupName, inputName, formState]);

    const mySet = new Set(currentValue);
    const onChangeValue = (id) => {
        if (mySet.has(id)) {
            mySet.delete(id)
        } else {
            mySet.add(id);
        }
        formAction.changeInputFn(formGroupName, inputName, "", Array.from(mySet));
    }

    const updateDataList = (index, key) => {

        let dataObject = [...getState];
        dataObject[index] = {
            ...dataObject[index],
            [key]: !_get(dataObject, `${index}.${key}`, false)
        }
        if (_get(dataObject, `${index}.${key}`, false) && key === "isCheck") {
            _get(dataObject, `${index}.${subPermissionKey}`, []).map((object) => {
                mySet.add(object.id);
            });
        }
        else if (!_get(dataObject, `${index}.${key}`, false) && key === "isCheck") {
            for (let data of _get(dataObject[index], 'permissions', [])) {
                mySet.delete(data.id)
            }
        }
        formAction.changeInputFn(formGroupName, inputName, inputStatePath, Array.from(mySet));
        setState(dataObject);
    }
   
    return (
        <div className="fullWidthDiv">
            {
                getState.map((object, index) => {
                    console.log("ddsdfsdfsfsdsdf",object);
                    const subPermition = object.permissions.map(data => data.id);
                    allSubPer[object.description] = subPermition;
                    return <ExpansionPanelWrapper
                        elementName={`group_${object[groupKey]}`}
                        isExpandedStatus={_get(object, 'isOpen', false)}
                        panelTitle={
                            <PanelTitle
                                labelText={_get(object, groupDisplayNameKey, "")}
                                onChangeFn={(eventObj) => updateDataList(index, "isCheck")}
                                inputValue={_get(object, 'isCheck', false)}
                                allSubPermition = {allSubPer}
                                selectedList={currentValue}
                                uiType={uiType}
                            />
                        }
                        panelBody={
                            <PanelBody
                                subPermissions={_get(object, subPermissionKey, [])}
                                subPermissionUniqueKey={subPermissionUniqueKey}
                                subPermissionDisplayNameKey={subPermissionDisplayNameKey}
                                onChangeFn={(id) => onChangeValue(id)}
                                selectedList={currentValue}
                                uiType={uiType}
                            />
                        }
                     onExpansionFn={(key) => updateDataList(index, "isOpen")}
                    />
                })
            }
        </div>
    )
}

export {
    PermissionsPanel
};