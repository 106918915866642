import React from "react";
import { Fragment } from "react";
import { templateTypes } from "../../../../../config/template.config";
import {
  FormWrapper,
  InputBoxWithState,
  SelectBoxWithState,
} from "../../../../ui-components/ui-elements/form";

const SearchElement = ({ dataTableKey = "" }) => {
  const formkey = `${dataTableKey}_search`;
  return (
    <Fragment>
      <FormWrapper setGroupName={formkey}>
        <div className="row">
          <div className="col-md-3 col-sm-4">
            <InputBoxWithState
              labelText={"Bank Code"}
              uiType={templateTypes.bootstrap}
              formGroupName={formkey}
              inputName={"code"}
              maxLength={"50"}
              inputPlaceholder={"Bank Code"}
            />
          </div>
          <div className="col-md-3 col-sm-4">
            <InputBoxWithState
              labelText={"Bank Name"}
              uiType={templateTypes.bootstrap}
              formGroupName={formkey}
              inputName={"name"}
              maxLength={"50"}
              inputPlaceholder={"Bank Name"}
            />
          </div>
          <div className="col-md-3 col-sm-4 floatLeft">
            <SelectBoxWithState
              uiType={templateTypes.bootstrap}
              formGroupName={formkey}
              dataList={[
                {
                  id: "true",
                  value: "Active",
                },
                {
                  id: "false",
                  value: "Inactive",
                },
              ]}
              inputName={"status"}
              labelText="Status"
              emptySelectOptionTxt={"All"}
              isEnableAllOption={true}
            />
          </div>
        </div>
      </FormWrapper>
    </Fragment>
  );
};

export { SearchElement };
