import React, { Fragment } from "react";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { AuditDetail } from "../../../../ui-components/ui-elements/common/BaseElements";
import {
  FormWrapper,
  InputBoxWithState,
  SelectBoxWithState,
  InputButton,
  NumberInputBoxWithState,
} from "../../../../ui-components/ui-elements/form";
import { contactsArrayToObject } from "../../../../../helpers/common-helpers/contact.helpers";
import { ContactTypeCode } from "../../../../../config/enum.config";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";

const VrpCompanyForm = (props) => {
  const formKey = props.fromParent.formKey;
  console.log("-----------------------dd---------", props);

  return (
    <FormWrapper
      setGroupName={formKey}
      formGroupLinkWith={formKey}
      onDestroyUnsetFormObject={false}
      setFormObject={{
        id: _get(props, "row.id", ""),
        code: _get(props, "row.code", ""),
        //br_number: _get(props, "row.br_number", ""),
        name: _get(props, "row.service.name", ""),
        name_si: _get(props, "row.service.name_si", ""),
        name_ta: _get(props, "row.service.name_ta", ""),
        active: _get(props, "row.service.active", 1),
        line1: _get(props, "row.service.addresses[0].line1", ""),
        line1_si: _get(props, "row.service.addresses[0].line1_si", ""),
        line1_ta: _get(props, "row.service.addresses[0].line1_ta", ""),
        line2: _get(props, "row.service.addresses[0].line2", ""),
        line2_si: _get(props, "row.service.addresses[0].line2_si", ""),
        line2_ta: _get(props, "row.service.addresses[0].line2_ta", ""),
        city: _get(props, "row.service.addresses[0].city", ""),
        city_si: _get(props, "row.service.addresses[0].city_si", ""),
        city_ta: _get(props, "row.service.addresses[0].city_ta", ""),
        postal_code: _get(props, "row.service.addresses[0].postal_code", ""),
        // contact_number_1:_get(props, "row.service.contacts[0].contact", ""),
        // contact_number_2:_get(props, "row.service.contacts[1].contact", ""),
        contact_number_1: contactsArrayToObject(
          _get(props.row, `service.contacts`, []),
          false,
          ContactTypeCode.PHONE
        ),
        contact_number_2: contactsArrayToObject(
          _get(props.row, `service.contacts`, []),
          false,
          ContactTypeCode.MOBILE
        ),
        email: contactsArrayToObject(
          _get(props.row, `service.contacts`, []),
          false,
          ContactTypeCode.EMAIL
        ),
        fax: contactsArrayToObject(
          _get(props.row, `service.contacts`, []),
          false,
          ContactTypeCode.FAX
        ),
      }}
    >
      <div className="row">
        <div className="col-12">
          <table className={"fullWidthDiv"}>
            <tr>
              <th className="textRight defaultPaddingRight">
                VRP Company Code <sup className="text-danger">*</sup>
              </th>
              <td className={"defaultPaddingRight"}>
                {props.fromParent.uiType === "CREATE" ? (
                  <InputBoxWithState
                    formGroupName={formKey}
                    inputName={"code"}
                    maxLength={20}
                  />
                ) : (
                  <div>
                    <span>
                      <strong>{_get(props, "row.code", "")} </strong>
                    </span>
                  </div>
                )}
              </td>
            </tr>

            {/* Spacer row */}
            <tr>
              <th className={"defaultHalfPadding"} />
            </tr>

            <tr>
              <th></th>
              <td className={"defaultPaddingRight"}>English</td>
              <td className={"defaultPaddingLeftRight"}>Sinhala</td>
              <td className={"defaultPaddingLeft"}>Tamil</td>
            </tr>
            <tr>
              <th className="textRight defaultPaddingRight">
                VRP Company Name<sup className="text-danger">*</sup>
              </th>
              <td className={"defaultPaddingRight"}>
                <InputBoxWithState
                  formGroupName={formKey}
                  inputName={"name"}
                  maxLength={200}
                />
              </td>
              <td className={"defaultPaddingLeftRight"}>
                <InputBoxWithState
                  formGroupName={formKey}
                  inputName={"name_si"}
                  maxLength={200}
                />
              </td>
              <td className={"defaultPaddingLeft"}>
                <InputBoxWithState
                  formGroupName={formKey}
                  inputName={"name_ta"}
                  maxLength={200}
                />
              </td>
            </tr>

            <tr>
              <th className="textRight defaultPaddingRight">
                Address Line 1<sup className="text-danger">*</sup>
              </th>
              <td className={"defaultPaddingRight"}>
                <InputBoxWithState
                  formGroupName={formKey}
                  inputName={"line1"}
                  maxLength={50}
                />
              </td>
              <td className={"defaultPaddingLeftRight"}>
                <InputBoxWithState
                  formGroupName={formKey}
                  //isRequired={true}
                  inputName={"line1_si"}
                  maxLength={50}
                />
              </td>
              <td className={"defaultPaddingLeft"}>
                <InputBoxWithState
                  formGroupName={formKey}
                  //isRequired={true}
                  inputName={"line1_ta"}
                  maxLength={50}
                />
              </td>
            </tr>

            <tr>
              <th className="textRight defaultPaddingRight">Address Line 2</th>
              <td className={"defaultPaddingRight"}>
                <InputBoxWithState
                  formGroupName={formKey}
                  inputName={"line2"}
                  maxLength={50}
                />
              </td>
              <td className={"defaultPaddingLeftRight"}>
                <InputBoxWithState
                  formGroupName={formKey}
                  inputName={"line2_si"}
                  maxLength={50}
                />
              </td>
              <td className={"defaultPaddingLeft"}>
                <InputBoxWithState
                  formGroupName={formKey}
                  inputName={"line2_ta"}
                  maxLength={50}
                />
              </td>
            </tr>

            <tr>
              <th className="textRight defaultPaddingRight">
                City<sup className="text-danger">*</sup>
              </th>
              <td className={"defaultPaddingRight"}>
                <InputBoxWithState
                  formGroupName={formKey}
                  inputName={"city"}
                  maxLength={50}
                />
              </td>
              <td className={"defaultPaddingLeftRight"}>
                <InputBoxWithState
                  formGroupName={formKey}
                  inputName={"city_si"}
                  maxLength={50}
                />
              </td>
              <td className={"defaultPaddingLeft"}>
                <InputBoxWithState
                  formGroupName={formKey}
                  inputName={"city_ta"}
                  maxLength={50}
                />
              </td>
            </tr>

            <tr>
              <th className="textRight defaultPaddingRight">
                Postal Code<sup className="text-danger">*</sup>
              </th>
              <td className={"defaultPaddingRight"}>
                <NumberInputBoxWithState
                  formGroupName={formKey}
                  inputName={"postal_code"}
                  isAllowLeadingZeros={true}
                  isThousandSeparator={false}
                  isAllowNegative={false}
                  decimalScale={0}
                  isAllowedFn={(value) => value.value.toString().length <= 6}
                />
              </td>
            </tr>

            <tr>
              <th className="textRight defaultPaddingRight">
                Contact Number 1 <sup className="text-danger">*</sup>
              </th>
              <td className={"defaultPaddingRight"}>
                <NumberInputBoxWithState
                  formGroupName={formKey}
                  inputName={"contact_number_1"}
                  isAllowLeadingZeros={true}
                  isThousandSeparator={false}
                  isAllowNegative={false}
                  decimalScale={0}
                  isAllowedFn={(value) => value.value.toString().length <= 10}
                />
              </td>
            </tr>

            <tr>
              <th className="textRight defaultPaddingRight">
                Contact Number 2
              </th>
              <td className={"defaultPaddingRight"}>
                <NumberInputBoxWithState
                  formGroupName={formKey}
                  inputName={"contact_number_2"}
                  isRequired={false}
                  isThousandSeparator={false}
                  isAllowNegative={false}
                  isAllowLeadingZeros={true}
                  decimalScale={0}
                  isAllowedFn={(value) => value.value.toString().length <= 10}
                />
              </td>
            </tr>

            <tr>
              <th className="textRight defaultPaddingRight">E-mail</th>
              <td className={"defaultPaddingRight"}>
                <InputBoxWithState
                  formGroupName={formKey}
                  inputName={"email"}
                  maxLength={50}
                />
              </td>
            </tr>

            <tr>
              <th className="textRight defaultPaddingRight">Fax</th>
              <td className={"defaultPaddingRight"}>
                <NumberInputBoxWithState
                  formGroupName={formKey}
                  inputName={"fax"}
                  isRequired={false}
                  isThousandSeparator={false}
                  isAllowNegative={false}
                  isAllowLeadingZeros={true}
                  decimalScale={0}
                  isAllowedFn={(value) => value.value.toString().length <= 10}
                />
              </td>
            </tr>

            <tr>
              <th className="textRight defaultPaddingRight">Status</th>
              <td className={"defaultPaddingRight"}>
                <SelectBoxWithState
                  emptySelectOptionTxt={null}
                  inputPlaceholder={"Please select a status"}
                  formGroupName={formKey}
                  dataList={[
                    { id: 1, value: "Active" },
                    { id: 0, value: "Inactive" },
                  ]}
                  inputName={"active"}
                />
              </td>
            </tr>
          </table>
        </div>

        {props.fromParent.uiType !== "CREATE" && (
          <Fragment>
            <div className="col-12">
              <AuditDetail
                createdAt={dateObjectToString(_get(props, `row.service.created_at`, ""),"dd/mm/YY HH:MM")}
                createdById={_get(props, `row.service.created_by`, "")}
                createdLocationId={_get(props, `row.service.created_location`, "")}
                createdRoleId={_get(props, `row.service.created_role_id`, '')}
                updatedAt={dateObjectToString(_get(props, `row.service.updated_at`, ""),"dd/mm/YY HH:MM")}
                updatedById={_get(props, `row.service.updated_by`, "")}
                updatedLocationId={_get(props, `row.service.updated_location`, "")}
                updatedRoleId={_get(props, `row.service.updated_role_id`, '')}
                labelCol={5}
              />
            </div>
          </Fragment>
        )}
      </div>
    </FormWrapper>
  );
};

export { VrpCompanyForm };
