/*
 * Created By Pradeepa Sandaruwan
 * At 2020/12/28
*/

import React from "react";
import { templateTypes } from "../../../../../config/template.config";
import {
  FormWrapper,
  SelectBoxWithState,
  InputBoxWithState,
} from "../../../../ui-components/ui-elements/form";

//dfsjifsi

const SearchElement = ({
    dataTableKey = "" 
}) => {
    const formKey = `${dataTableKey}_search`;
    return (
        <FormWrapper setGroupName={formKey}>
            <div className="row">
            <div className="col-md-2 col-sm-4 floatLeft">
                <InputBoxWithState
                uiType={templateTypes.bootstrap}
                formGroupName={formKey}
                inputName={"prefix"}
                inputPlaceholder= "Prefix"
                labelText="Prefix"
                maxLength= "2"  
                />
            </div>

            <div className="col-md-2 col-sm-4 floatLeft">
                <InputBoxWithState
                uiType={templateTypes.bootstrap}
                formGroupName={formKey}
                inputPlaceholder= "Name"
                inputName={"name"}
                labelText="Name"
                maxLength= "50"  
                />
            </div>


            <div className="col-md-2 col-sm-12 floatLeft ">
                <SelectBoxWithState
                uiType={templateTypes.bootstrap}
                formGroupName={formKey}
                isEnableAllOption= {true}
                dataList={[
                    {
                    id: "true",
                    value: "Active",
                    },
                    {
                    id: "false",
                    value: "Inactive",
                    },
                ]}
                inputName={"active"}
                labelText="Status"
                />
            </div>
            </div>
        </FormWrapper>
    );
};

export{
    SearchElement
}