/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2021-03-01 14:22:32 
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-01-24 23:06:45
 */

import React, { useContext } from 'react';

import {
  FormWrapper,
  InputBoxWithState,
  SelectBoxWithState,
  DatePickerWithState
} from "../../../../ui-components/ui-elements/form";
import { _get } from '../../../../../helpers/common-helpers/lodash.wrappers';
import { FormContext } from "../../../core/context-providers/FormContext.provider";

const RemoveFrom = (props) => {
  const formKey = `${props.fromParent.formKey}`;


  return (
    <FormWrapper
      setGroupName={formKey}
      setFormObject={{
        id: _get(props, "row.id", ""),
      }}
      onDestroyUnsetFormObject={true}
    >
      <div className="removeContent">
        <p>Are you sure you want to continue?</p>
        <br />
        <br />
      </div>
    </FormWrapper>
  );
};
const NewsForm = (props) => {
  const [formState, formAction] = useContext(FormContext);
  const formKey = `${props.fromParent.formKey}`;
  const today = new Date();
  const ui = _get(props, "fromParent.uiType");
  return (

    <FormWrapper
      setGroupName={formKey}
      setFormObject={{
        id: _get(props, "row.id", ""),
        code: _get(props, "row.code", ""),
        heading_sinhala: _get(props, "row.heading_sinhala", ""),
        heading_tamil: _get(props, "row.heading_tamil", ""),
        heading_english: _get(props, "row.heading_english", ""),
        content_sinhala: _get(props, "row.content_sinhala", ""),
        content_tamil: _get(props, "row.content_tamil", ""),
        content_english: _get(props, "row.content_english", ""),
        start_date: _get(props, "row.start_date", today),
        end_date: _get(props, "row.end_date", today),
        status: _get(props, "row.status", "1"),
      }}
      onDestroyUnsetFormObject={true}
    >
      <div className="row">
        <div className="col-12">
          <table className={"fullWidthDiv"}>
            <tr>
              <th>News Code<sup className="text-danger">*</sup></th>
              <td className={"defaultPaddingRight"}>
                <InputBoxWithState
                  formGroupName={formKey}
                  inputName={"code"}
                  maxLength="12"
                  isDisabled={ui == "create" ? false : true}
                />
              </td>
            </tr>

            {/* Spacer row */}
            <tr>
              <th className={"defaultHalfPadding"} />
            </tr>

            <tr>
              <th></th>
              <td className={"defaultPaddingRight"}>English</td>
              <td className={"defaultPaddingLeftRight"}>Sinhala</td>
              <td className={"defaultPaddingLeft"}>Tamil</td>
            </tr>
            <tr>
              <th>News Heading<sup className="text-danger">*</sup></th>
              <td className={"defaultPaddingRight"}>
                <InputBoxWithState
                  formGroupName={formKey}
                  inputName={"heading_english"}
                  inputPlaceholder="News Heading in English"
                  maxLength="25"
                />
              </td>
              <td className={"defaultPaddingLeftRight"}>
                <InputBoxWithState
                  formGroupName={formKey}
                  inputName={"heading_sinhala"}
                  maxLength="25"
                  inputPlaceholder="News Heading in Sinhala"
                />
              </td>
              <td className={"defaultPaddingLeft"}>
                <InputBoxWithState
                  formGroupName={formKey}
                  inputName={"heading_tamil"}
                  maxLength="25"
                  inputPlaceholder="News Heading in Tamil"
                />
              </td>
            </tr>

            <tr>
              <th>News Content<sup className="text-danger">*</sup></th>
              <td className={"defaultPaddingRight"}>
                <InputBoxWithState
                  formGroupName={formKey}
                  inputName={"content_english"}
                  inputPlaceholder="News Content in English"
                  maxLength="50"
                  isMultiline={true}
                  rows={3}
                />
              </td>
              <td className={"defaultPaddingLeftRight"}>
                <InputBoxWithState
                  formGroupName={formKey}
                  inputName={"content_sinhala"}
                  inputPlaceholder="News Content in Sinhala"
                  maxLength="50"
                  isMultiline={true}
                  rows={3}
                />
              </td>
              <td className={"defaultPaddingLeft"}>
                <InputBoxWithState
                  formGroupName={formKey}
                  inputPlaceholder="News Content in Tamil"
                  inputName={"content_tamil"}
                  maxLength="50"
                  isMultiline={true}
                  rows={3}
                />
              </td>
            </tr>

            <tr>
              <th>Status<sup className="text-danger">*</sup></th>
              <td className={"defaultPaddingRight"}>
                <SelectBoxWithState
                  formGroupName={formKey}
                  dataList={[
                    {
                      id: "1",
                      value: "Active",
                    },
                    {
                      id: "0",
                      value: "Inactive",
                    },
                  ]}
                  emptySelectOptionTxt=""
                  inputName={"status"}
                />
              </td>
            </tr>
            <tr>
              <th>Start Date<sup className="text-danger">*</sup></th>
              <td className={"paddingTopBottomNone"}>
                <DatePickerWithState
                  formGroupName={formKey}
                  inputName={"start_date"}
                  labelText=" "
                  minDate={today}
                  minDateMessage="Start Date’ should be less than or equal to ‘End Date’."
                />
              </td>
            </tr>
            <tr>
              <th>End Date<sup className="text-danger">*</sup></th>
              <td className={"paddingTopBottomNone"}>
                <DatePickerWithState
                  formGroupName={formKey}
                  inputName={"end_date"}
                  labelText=" "
                  minDate={_get(formState, `${formKey}.start_date`, undefined)}
                  minDateMessage="End Date’ should be greater than or equal to the ‘Start Date’."
                />
              </td>
            </tr>
          </table>
        </div>


      </div>
      {/* <InputBoxWithState
        formGroupName={formKey}
        inputName={"code"}
        labelText="News Code"
        isRequired={true}
        maxLength="12"
        isDisabled={ui == "create" ? false : true}
      />

      <table className="fullWidthDiv">
        <tr>
          <th></th>
          <th>English</th>
          <th>Sinhala</th>
          <th>Tamil</th>
        </tr>
        <tr>
          <th>News Heading</th>
          <td>
            <InputBoxWithState
            formGroupName={formKey}
            inputName={"heading_english"}
            labelText=""
            inputPlaceholder="News Heading in English"
            maxLength="25"
            isRequired={true}
          />
          </td>
          <td>
            <InputBoxWithState
                formGroupName={formKey}
                inputName={"heading_sinhala"}
                labelText=""
                isRequired={true}
                maxLength="25"
                inputPlaceholder="News Heading in Sinhala"
              />
          </td>
          <td>
            <InputBoxWithState
              formGroupName={formKey}
              inputName={"heading_tamil"}
              isRequired={true}
              maxLength="25"
              inputPlaceholder="News Heading in Tamil"
              labelText=""
            />
          </td>
        </tr>
        <tr>
          <th>News Content</th>
          <td>
            <InputBoxWithState
              formGroupName={formKey}
              isRequired={true}
              inputName={"content_sinhala"}
              inputPlaceholder="News Content in Sinhala"
              labelText=""
              maxLength="50"
            />
          </td>
          <td>
            <InputBoxWithState
              formGroupName={formKey}
              isRequired={true}
              inputName={"content_english"}
              inputPlaceholder="News Content in English"
              labelText=""
              maxLength="50"
            />
          </td>
          <td>
            <InputBoxWithState
              formGroupName={formKey}
              isRequired={true}
              inputPlaceholder="News Content in Tamil"
              inputName={"content_tamil"}
              labelText=""
              maxLength="50"
            />
          </td>
        </tr>
      </table>

      <DatePickerWithState
        formGroupName={formKey}
        inputName={"start_date"}
        labelText="Start Date"
        isRequired={true}
        minDate={today}

      />

      <DatePickerWithState
        formGroupName={formKey}
        inputName={"end_date"}
        labelText="End Date"
        isRequired={true}
        minDate={_get(formState, `${formKey}.start_date`, undefined)}
      />


      <SelectBoxWithState
        formGroupName={formKey}
        dataList={[
          {
            id: "1",
            value: "Active",
          },
          {
            id: "0",
            value: "Inactive",
          },
        ]}
        emptySelectOptionTxt=""
        inputName={"status"}
        isRequired={true}
        labelText="Status"
      /> */}

    </FormWrapper>
  );
}

export {
  NewsForm, RemoveFrom
}