/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-03-25 15:10:48 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-08 11:39:17
 */
import React from 'react';

import {RoundButton} from "../../form/Button";
 
const emptyFun = (...para) => undefined;
 
const FooterWrapper=({
    totalRecordsCount="",
    currentPage=1,
    pageCount=1,
    perPage=10,
    onNextFn={emptyFun}
})=>{
  

    return(
        <div className="dataTableFooterWrapper">
            <div className="row">
            <div className="col-md-6 floatLeft">
                <p>Showing {currentPage>=2?((currentPage-1)*10)+1:currentPage} to {((currentPage-1)*10)+perPage} of {totalRecordsCount} entries</p>
            </div>
            <div className="col-md-6 floatLeft btnWrapper">
                <RoundButton
                    iconClass="fas fa-angle-double-left"
                    isBtnDisabled={currentPage===1?true:false}
                    onClickBtnFn={()=>onNextFn("next",1)}
                />
                <RoundButton
                    iconClass="fas fa-angle-left"
                    isBtnDisabled={(currentPage-1)===0?true:false}
                    onClickBtnFn={()=>onNextFn("next",currentPage-1)}
            
                />
                <RoundButton
                    iconClass="fas fa-angle-right"
                    isBtnDisabled={((currentPage+1)>pageCount)?true:false}
                    onClickBtnFn={()=>onNextFn("next",currentPage+1)}
                />
                <RoundButton
                    iconClass="fas fa-angle-double-right"
                    isBtnDisabled={pageCount===currentPage?true:false}
                    onClickBtnFn={()=>onNextFn("next",pageCount)}
                />
            </div>
            </div>
        </div>
    )
} 


export {
    FooterWrapper
}