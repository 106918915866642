import React, { Fragment } from "react";
import { AuditDetail, UICard, ViewDetailBlock } from "../../../../ui-components/ui-elements/common/BaseElements";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";

const ViewVehicleCondition = (props) => {

    const labelCol = 4;
    const cellCol = (12 - labelCol) / 2;

    return (
        <Fragment>
            <UICard elementStyle="defaultPaddingLeft"  >
                <ViewDetailBlock
                    labelCol={labelCol}
                    label={"Vehicle Condition Prefix"}
                    value={_get(props, `row.prefix`, '')}
                />
                <ViewDetailBlock
                    labelCol={labelCol}
                    label={"Vehicle Condition Name"}
                    value={_get(props, `row.name`, '')}
                />
                <ViewDetailBlock
                    labelCol={labelCol}
                    label={"Status"}
                    value={parseInt(_get(props, `row.active`, '')) === 1 ? ('Active') : ('Inactive')}
                />

                <br />

                <div className="row defaultPaddingTopBottom">
                    <div className={`col-${labelCol}`}>{/* Empty cell */}</div>
                    <div className={`col-${cellCol}`}>
                        <strong>Duration</strong>
                    </div>
                    <div className={`col-${cellCol}`}>
                        <strong>Value</strong>
                    </div>
                    <div className="col-12">
                        <ViewDetailBlock
                            labelCol={labelCol}
                            label={"VET Exemption Period"}
                            value={
                                <div className="row">
                                    <div className="col-6">
                                        {
                                            _get(props, `row.vet_exemption_duration_code`, '') === 'Y'
                                                ? 'Year'
                                                : _get(props, `row.vet_exemption_duration_code`, '') === 'M'
                                                    ? 'Month'
                                                    : 'Day'
                                        }
                                    </div>
                                    <div className="col-6">{_get(props, `row.vet_exemption_value`, '')}</div>
                                </div>
                            }
                        />
                        <ViewDetailBlock
                            labelCol={labelCol}
                            label={"VFT Exemption Period"}
                            value={
                                <div className="row">
                                    <div className="col-6">
                                        {
                                            _get(props, `row.vft_exemption_duration_code`, '') === 'Y'
                                                ? 'Year'
                                                : _get(props, `row.vft_exemption_duration_code`, '') === 'M'
                                                    ? 'Month'
                                                    : 'Day'
                                        }
                                    </div>
                                    <div className="col-6">{_get(props, `row.vft_exemption_value`, '')}</div>
                                </div>
                            }
                        />
                    </div>
                </div>
                <br />
                <div className="row defaultPaddingTopBottom">
                    <div className="col-12">
                        <ViewDetailBlock
                            labelCol={labelCol}
                            label={"DMT Mapping Parameter"}
                            value={_get(props, `row.dmt_mapping_parameter`, '')}
                        />
                    </div>
                </div>
            </UICard>
            <div className="col-md-12 col-sm-12 defaultMarginBottom">
                <AuditDetail
                    labelCol="4"
                    createdAt={dateObjectToString(_get(props, `row.created_at`, ""), "dd/mm/YY HH:MM")}
                    updatedAt={dateObjectToString(_get(props, `row.updated_at`, ""), "dd/mm/YY HH:MM")}
                    createdById={_get(props, `row.created_by`, "")}
                    updatedById={_get(props, `row.updated_by`, "")}
                    createdLocationId={_get(props, `row.created_location`, "")}
                    updatedLocationId={_get(props, `row.updated_location`, "")}
                    createdRoleId={_get(props, `row.created_role_id`, '')}
                    updatedRoleId={_get(props, `row.updated_role_id`, '')}
                />
            </div>
        </Fragment >
    );
};
export { ViewVehicleCondition };