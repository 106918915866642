/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-07-14 10:18:15
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-30 11:37:56
 */

import React from "react";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { UICard, ViewDetailBlock, AuditDetail } from "../../../../ui-components/ui-elements/common/BaseElements";
import { FormWrapper } from "../../../../ui-components/ui-elements/form";


//jjbjb
const ViewFuelType = (props) => {
  const formKey = "testInuts";
  const numberOfLabelCol = 6
  return (
    <FormWrapper setGroupName={"test2"}>
    <div className="row"> 
      <div className="col-md-12 col-sm-12 ">
        <UICard>
            <ViewDetailBlock
              label={"Fuel Type Prefix"}
              value={_get(props, `row.prefix`, '')}
              labelCol={numberOfLabelCol}
            />
            <ViewDetailBlock
              label={"Fuel Type Name"}
              value={_get(props, `row.name`, '')}
              labelCol={numberOfLabelCol}
            />
            <ViewDetailBlock
              label={"Status"}
              value={parseInt(_get(props, `row.active`, '')) === 1 ? ('Active') : ('Inactive')}
              labelCol={numberOfLabelCol}
            />
        </UICard>

        <UICard cardHeading="Document Validations Applicability"
          elementStyle="defaultMarginTop"
        >
            <ViewDetailBlock
              label={"VET"}
              value={_get(props, `row.vet_applicability_code`, '') === "MAN" ? "MANDATORY" : _get(props, `row.vet_applicability_code`, '') === "OPT" ? "OPTIONAL" : "NOT REQUIRED" }
              labelCol={numberOfLabelCol}
            />
        </UICard>

        <UICard
          cardHeading="DMT Mapping Parameters"
          elementStyle="defaultMarginTop"
        >
            <ViewDetailBlock
              label={"DMT Mapping Parameters"}
              value={_get(props, `row.dmt_mapping_parameter`, '')}
              labelCol={numberOfLabelCol}
            />
        </UICard>

        <AuditDetail
                
                createdById={_get(props, `row.created_by`, '')}
                updatedById={_get(props, `row.updated_by`, '')}
                createdLocationId={_get(props, `row.created_location`, '')}
                updatedLocationId={_get(props, `row.updated_location`, '')}
                createdAt={dateObjectToString(_get(props, `row.created_at`, ''), "dd/mm/YY HH:MM")}
                updatedAt={dateObjectToString(_get(props, `row.updated_at`, ''), "dd/mm/YY HH:MM")}
                createdRoleId={_get(props, `row.created_role_id`, '')}
                updatedRoleId={_get(props, `row.updated_role_id`, '')}
                labelCol={numberOfLabelCol}
      />
      </div>
    </div>
    </FormWrapper>
  );
};




export {
  ViewFuelType
};
