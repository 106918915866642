/*
 * @Author: Anjula Karunarathne
 * @Date: 2021-01-21 16:06:11
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-04-08 15:47:52
 */

import React from "react";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import {
  DataTable,
  actionBtnTypes,
} from "../../../ui-components/ui-elements/table/DataTable";
import { AdditionDeductionForm } from "./includes/AdditionDeductionForm";
import { getCodeName, ViewAdditionDeduction } from "./includes/ViewAdditionDeduction";
import { SearchElement } from "./includes/SearchElement";
import { CustomButton } from "./includes/CustomButton";
import {
  additionDeductionCreateAPI,
  additionDeductionDeleteAPI,
  additionDeductionEditAPI,
  additionDeductionListAPI,
} from "../../../../config/apiUrl.config";
import { dateObjectToString } from "../../../../helpers/common-helpers/dateTime.helpers";
import { _findindex, _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { ApplicabilityTypeCode, ChargeEffectCode, ChargeTypeCode } from "../../../../config/enum.config";
import { CustomButtonDelete, RemoveAdditionDeductionForm } from "./includes/RemoveAdditionDeduction";
import { permissions } from "../../../../config/permission.config";
import { useEffect } from "react";

const dataTableKey = "add_ded_list";

const AdditionsDeductions = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  return (
    <DashboardTemplate
      pageTitle="Manage Addition / Deduction"
      breadCrumbList={[
        { urlTo: "/", displayName: "Home" },
        { urlTo: "", displayName: "Charges" },
        { urlTo: "", displayName: "Manage Additions / Deduction" },
      ]}
    >
      <DataTable
        tableName="Additions / Deductions"
        apiUrl={`${additionDeductionListAPI.url}?sort_by=name|asc`}
        dataTableKey={additionDeductionListAPI.key}
        isSetSearchFrom={true}
        defaultSearchFormObject={{
          search: "",
          applicability_type_code: null,
          charge_effect_code: null,
          active: null,
        }}
        searchFromComponent={
          <SearchElement dataTableKey={additionDeductionListAPI.key} />
        }
        actionTypeList={[
          {
            actionType: actionBtnTypes.create,
            actionBtn: { "permissions": permissions.ADDITION_DEDUCTION_ADD.permissions },
            modelContentElement: AdditionDeductionForm,
            dialogPopupProps: {
              isFullScreen: false,
              isSaveButton: false,
              isCloseButton: true,
              isCustomButton: true,
              isFullWidth: true,
              customButtonComponent: CustomButton,
              customButtonComponentProps: { btnText: "Save", uiType: "add" },
              dialogTitle: "Add Addition / Deduction",
              closeButton: {
                btnText: "Cancel",
                startIcon: "far fa-times-circle",
              },
              isConfirmationPopup: true,
            },
            parentToModelProps: {
              formKey: additionDeductionCreateAPI.key + "_create",
              dataTableKey: additionDeductionListAPI.key,
              uiType: "add"
            },
          },
          {
            actionType: actionBtnTypes.view,
            actionBtn: { tooltip: "View", "permissions": permissions.ADDITION_DEDUCTION_VIEW.permissions },
            modelContentElement: ViewAdditionDeduction,
            dialogPopupProps: {
              isFullScreen: false,
              isSaveButton: false,
              isCloseButton: true,
              isFullWidth: true,
              dialogTitle: "View Addition / Deduction",
            },
            parentToModelProps: {
              formKey: additionDeductionListAPI.key + "_view",
              dataTableKey: additionDeductionListAPI.key,
            },
          },
          {
            actionType: actionBtnTypes.edit,
            actionBtn: { icon: "far fa-edit", tooltip: "edit", "permissions": permissions.ADDITION_DEDUCTION_EDIT.permissions },
            modelContentElement: AdditionDeductionForm,
            dialogPopupProps: {
              isFullScreen: false,
              isSaveButton: false,
              isCloseButton: true,
              isCustomButton: true,
              isFullWidth: true,
              customButtonComponent: CustomButton,
              customButtonComponentProps: { btnText: "Update", uiType: "edit" },
              dialogTitle: "Edit Addition / Deduction",
              closeButton: {
                btnText: "Cancel",
                startIcon: "far fa-times-circle",
              },
              isConfirmationPopup: true,
            },
            parentToModelProps: {
              formKey: additionDeductionEditAPI.key + "_edit",
              dataTableKey: additionDeductionListAPI.key,
              uiType: "edit"
            },
          },
          { 
            actionType: actionBtnTypes.delete,
            actionBtn: { tooltip: "Remove", "permissions": permissions.ADDITION_DEDUCTION_DELETE.permissions },
            modelContentElement: RemoveAdditionDeductionForm,
            dialogPopupProps: {
              isFullScreen: false,
              dialogTitle: "Remove Addition / Deduction ",
              isCustomButton: true,
              customButtonComponent: CustomButtonDelete,
              customButtonComponentProps: {
                dataTableKey: additionDeductionListAPI.key,
                uiType: "delete",
              },
              closeButton: {
                btnText: "No",
                startIcon: "far fa-times-circle",
              },
            },
            parentToModelProps: {
              formKey: additionDeductionDeleteAPI.key,
              dataTableKey: additionDeductionListAPI.key,
            },
            checkBeforeActionRenderFn: (rawData) => {
              const MyDate = new Date();
              var MyDateString;
              MyDateString = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2);
              const slicedFromDate= rawData.row.effective_date.slice(0,10);
              return slicedFromDate > MyDateString;
            }
          }
        ]}
        tableHeaderList={[
          {
            displayName: "Name",
            key: "name",
            isSort: false,
          },
          {
            displayName: "Applicability Type",
            key: "applicability_type_code",
            isSort: false,
            onPrintFn: ({ applicability_type_code }) =>
            applicability_type_code
                ? getCodeName(ApplicabilityTypeCode, applicability_type_code)
                : "-",
          },
          {
            displayName: "Charge Effect",
            key: "charge_effect_code",
            isSort: false,
            onPrintFn: ({ charge_effect_code }) =>
            charge_effect_code
                ? getCodeName(ChargeEffectCode, charge_effect_code)
                : "-",
          },
          {
            displayName: "Charge Type",
            key: "charge_type_code",
            isSort: false,
            onPrintFn: ({ charge_type_code }) =>
            charge_type_code
                ? getCodeName(ChargeTypeCode, charge_type_code)
                : "-",
          },
          {
            displayName: "Value",
            key: "charge_type_value",
            isSort: false,
          },
          {
            displayName: "Status",
            key: "active",
            isSort: false,
            onPrintFn: (row) => _get(row, "active", "") == 1 ? "Active" : "Inactive"
          },
        ]}
      />
    </DashboardTemplate>
  );
};

export default AdditionsDeductions;
