/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-03-27 12:52:11
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-04-06 14:26:03
 */
import { useContext, useEffect, useState } from 'react';

import { checkUserINLocalStorage } from '../../helpers/common-helpers/manageStorage.helpers';
//import {isMobile} from "../../helpers/common-helpers/common.helpers";
import { AuthContext } from '../modules/core/context-providers/AuthContext.provider';
import { UIContext } from '../ui-components/context-providers/UIContext.provider';
import { isAuthorizedINGlobalState } from './helper/init.helpers';
import { _get } from "../../helpers/common-helpers/lodash.wrappers";
/**
 *
 * @param {String} routeKey
 * @param {String} type auth|guess
 * @returns
 */
const useKeycloak = (routeKey, type = 'auth') => {
  const [initState, setState] = useState(false);
  const [authStatus, authAction] = useContext(AuthContext);
  const [uiState, uiAction] = useContext(UIContext);

  useEffect(() => {
    uiAction.setCurrentRouteFn(routeKey);

    if (
      // authStatus.isAuthenticated === false ||
      authStatus.isAuthenticated === true
    ) {
      setState(true);
    } else {
      const localStoreData = checkUserINLocalStorage();
      if (localStoreData.status === true) {
        authAction.setTokensFn(localStoreData.result);
        setState(true);
        uiAction.initializeSocketConnection();
      } else {
        isAuthorizedINGlobalState(authStatus, authAction, (error, status) => {
          setState(true);
          uiAction.initializeSocketConnection();
        });
      }
    }
  }, []);

  useEffect(() => {
    if (uiState?.socketConnection && !uiState.isJoinedRoom) {
      uiState.socketConnection.emit("joinRoom", _get(authStatus, "authUser.id", ""));

      // This below joinedRoom block is only intended for developing purpose. Please remove it on production
      uiState.socketConnection.on("joinedRoom", (room) => {
        console.log("Joined room................", room);
      });

      uiState.socketConnection.on("chatToClient", (msj) => {
        const pdmtIdGet = _get(authStatus, "authUser.pdmt_id", "")

        // const isHighRole = _get(authStatus, "authUser.role_category_code", "") !== "" && 
        // (_get(authStatus, "authUser.role_category_code", "") === "SUP" ||
        // _get(authStatus, "authUser.role_category_code", "") === "COM") ? true : false

        const isInPdmtIds = msj.pdmt_service_ids.includes(pdmtIdGet)
        // const isInRoleCatogoryIds = msj.role_categories.includes(_get(authStatus, "authUser.role_category_code", ""))
        // const isInAuthorityServicesIds = msj.authority_service_ids.includes(_get(authStatus, "authUser.authorityId", ""))
        const isInUserIds = msj.user_ids.includes(_get(authStatus, "authUser.id", ""))

        // console.log('TTTTTTTTTTTTTTvvvvvGGGGGGGGGG==', isInPdmtIds,  isInUserIds, pdmtIdGet, _get(authStatus, "authUser", ""));
        // console.log('TTTTTTTTTTTTTTvvvvv==', msj);
        // console.log('TTTTTTTTTTTTTTvvvvvauthh==', authStatus);

        uiAction.getNotificationCount({
          read: false,
          pdmt_service_id: _get(authStatus, "authUser.service_id", undefined),
           user_id: _get(authStatus, "authUser.id", undefined),
          role_category_code: _get(authStatus, "authUser.role_level_code", undefined)  ===  "ADN_NTL" ? undefined : "SUP|PDM_ADN",
          current_role_category: "PDM_ADN"
        });

        if(msj.application === 'internal') {
          if(msj.pdmt_service_ids.length > 0 && 
            msj.user_ids.length > 0) {

              if(isInPdmtIds && isInUserIds) {
                uiAction.setFlashMessage({
                  status: true,
                  messageType: "info",
                  isNotificationMsj: true,
                  message: "You have 1 new notification"
                });
                
               // console.log('TTTTTTTTTTTTTTvvvvv==11', msj);
                uiAction.incrementNotificationCountFn();

              }
          } else if(msj.pdmt_service_ids.length === 0 &&
            msj.user_ids.length > 0) {

              if(!isInPdmtIds && isInUserIds) {
                uiAction.setFlashMessage({
                  status: true,
                  messageType: "info",
                  isNotificationMsj: true,
                  message: "You have 1 new notification"
                });
               
               // console.log('TTTTTTTTTTTTTTvvvvv==33', msj);
                uiAction.incrementNotificationCountFn();

              }
            } else if(msj.pdmt_service_ids.length > 0 && 
              msj.user_ids.length === 0) {
                if(isInPdmtIds && !isInUserIds) {
                  uiAction.setFlashMessage({
                    status: true,
                    messageType: "info",
                    isNotificationMsj: true,
                    message: "You have 1 new notification"
                  });
                
                 // console.log('TTTTTTTTTTTTTTvvvvv==44', msj);
                  uiAction.incrementNotificationCountFn();

                }

            }
        } else {
          uiAction.setFlashMessage({
            status: true,
            messageType: "info",
            isNotificationMsj: true,
            message: "You have 1 new notification"
          });
          uiAction.incrementNotificationCountFn();
        }

        
      });

      uiAction.setJoinRoomStatus(true);
    }
  }, [uiState?.socketConnection]);

  useEffect(() => {
    if (uiState?.notificationCount === null && _get(authStatus, "authUser.id", undefined)) {
      //console.log('TTTTTTTTTTTTTTvvvvvauthh==1111', authStatus);
      uiAction.getNotificationCount({
        read: false,
        pdmt_service_id: _get(authStatus, "authUser.service_id", undefined),
        user_id: _get(authStatus, "authUser.id", undefined),
        role_category_code: _get(authStatus, "authUser.role_level_code", undefined)  ===  "ADN_NTL" ? undefined : "SUP|PDM_ADN",
        current_role_category: "PDM_ADN"
      });
    }
  }, [authStatus.authUser]);

  return [initState, authStatus.isAuthenticated];
};

export default useKeycloak;
