/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-03-26 20:17:06
 * @Last Modified by: Senura Weerasinghe
 */
import { permissions } from "./permission.config";

const siteMenu = [
  {
    id:1,
    labelText: "Dashboard",
    routeName: "dashboard",
    icon: "mdi mdi-home",
    permissions: permissions.DASHBOARD_COMMON_DETAILS.permissions,
    child: false,
    url: "/",
  },
  {
    id:2,
    labelText: "eRL Departments",
    routeName: "deparment",
    icon: "fas fa-warehouse",
    permissions: permissions.NONE.permissions,
    child: true,
    nested: [
      {
        labelText: "Province Office",
        routeName: "province-office",
        icon: "mdi mdi-office",
        permissions: permissions.PDMT_MANAGE.permissions,
        child: false,
        url: "/province-office",
      },
      {
        labelText: "Authority",
        routeName: "authority",
        icon: "mdi mdi-server-security",
        permissions: permissions.AUTHORITY_MANAGE.permissions,
        child: false,
        url: "/authority",
      },
      {
        labelText: "Counter",
        routeName: "manage-counters",
        icon: "mdi mdi-cash-usd",
        permissions: permissions.COUNTER_MANAGE.permissions,
        child: false,
        url: "/manage-counters",
      },
    ]
  },
  {
    id:3,
    labelText: "Support Organisations",
    routeName: "org",
    icon: "far fa-building",
    permissions: permissions.NONE.permissions,
    child: true,
    nested: [
      {
        labelText: "VFT",
        routeName: "vft-company",
        icon: "far fa-building",
        permissions: permissions.VFT_COMPANY_MANAGE.permissions,
        child: false,
        url: "/vft-company"
      },
      {
        labelText: "VRP",
        routeName: "manage-vrp",
        icon: "fa fa-road",
        permissions: permissions.VRP_COMPANY_MANAGE.permissions,
        child: false,
        url: "/manage-vrp"
      },
      {
        labelText: "VET Company",
        routeName: "vetCompany",
        icon: "mdi mdi-store",
        permissions: permissions.VET_COMPANY_MANAGE.permissions,
        child: false,
        url: "/vetCompany",
      },
      {
        labelText: "Insurance Company",
        routeName: "insurance-company",
        icon: "mdi mdi-store",
        permissions: permissions.INSURANCE_COMPANY_MANAGE.permissions,
        child: false,
        url: "/manage-insurance-company",
      },
      {
        labelText: "Dealer Files",
        routeName: "dealerFIle",
        icon: "mdi mdi-store",
        permissions: permissions.DEALER_FILE_UPLOAD.permissions,
        child: false,
        url: "/dealerFiles",
      },
    ]
  },
  {
    id:4,
    labelText: "Charges",
    routeName: "charge",
    icon: "fas fa-file-invoice-dollar",
    permissions: permissions.NONE.permissions,
    child: true,
    nested: [
      {
        labelText: "Rates",
        routeName: "rates",
        icon: "fas fa-chart-line",
        permissions: permissions.RATES_MANAGE.permissions,
        child: false,
        url: "/rates",
      },
      {
        labelText: "Fine Configurations",
        routeName: "fine-configurations",
        icon: "mdi mdi-coin",
        permissions: permissions.FINE_CONFIG_MANAGE.permissions,
        child: false,
        url: "/fine-configurations",
      },
      {
        labelText: "Additions / Deductions",
        routeName: "additionsDeductions",
        icon: "mdi mdi-contrast",
        permissions: permissions.ADDITION_DEDUCTION_MANAGE.permissions,
        child: false,
        url: "/additions-deductions",
      },
    ]
  },
  {
    id:5,
    labelText: "Configurations",
    routeName: "config",
    icon: "fas fa-cogs",
    permissions: permissions.NONE.permissions,
    child: true,
    nested: [
      {
        labelText: "Licence Type",
        routeName: "licenceTypes",
        icon: "mdi mdi-tag-text-outline",
        permissions: permissions.LICENSE_TYPE_MANAGE.permissions,
        child: false,
        url: "/licence-types",
      },
      {
        labelText: "Vehicle Class",
        routeName: "vehicleClass",
        icon: "mdi mdi-scale",
        permissions: permissions.VEHICLE_CLASS_MANAGE.permissions,
        child: false,
        url: "/vehicleClass",
      },
      {
        labelText: "Fuel Type",
        routeName: "manage-fuel-type",
        icon: "mdi mdi-gas-station",
        permissions: permissions.FUEL_TYPE_MANAGE.permissions,
        child: false,
        url: "/manage-fuel-type",
      },
      {
        labelText: "Vehicle Conditions",
        routeName: "vehicleCondition",
        icon: "mdi mdi-calendar-text",
        permissions: permissions.VEHICLE_CONDITION_MANAGE.permissions,
        child: false,
        url: "/vehicleCondition",
      },
      {
        labelText: "Bank",
        routeName: "bank",
        icon: "far fa-building",
        permissions: permissions.BANK_MANAGE.permissions,
        child: false,
        url: "/bank"
      },
    ]
  },
  {
    id:6,
    labelText: "Web RL Deliver",
    routeName: "webDeliver",
    icon: "fas fa-truck",
    permissions: permissions.NONE.permissions,
    child: true,
    nested: [
      {
        labelText: "Delivery Method",
        routeName: "delivery-methods",
        icon: "mdi mdi-truck",
        permissions: permissions.DELIVERY_METHOD_MANAGE.permissions,
        child: false,
        url: "/manage-delivery-methods",
      },
      {
        labelText: "Delivery Charge - Web RL",
        routeName: "deliveryCharges",
        icon: "mdi mdi-currency-usd",
        permissions: permissions.DELIVERY_CHARGE_MANAGE.permissions,
        child: false,
        url: "/delivery-charge"
      },
    ]
  },
  {
    id:7,
    labelText: "Other",
    routeName: "other",
    icon: "fas fa-wallet",
    permissions: permissions.NONE.permissions,
    child: true,
    nested: [
      {
        labelText: "Holiday Configuration",
        routeName: "holidayConfigurations",
        icon: "far fa-building",
        permissions: permissions.HOLIDAY_CONFIG_MANAGE.permissions,
        child: false,
        url: "/holiday-configurations"
      },
      {
        labelText: "General Configurations",
        routeName: "generalConfiguration",
        icon: "mdi mdi-settings",
        permissions: permissions.GENERAL_CONFIGURATIONS_MANAGE.permissions,
        child: false,
        url: "/generalConfiguration",
      },
      {
        labelText: "Web Portal News",
        routeName: "webPortalNews",
        icon: "fa fa-users fa-xs",
        permissions: permissions.NEWS_MANAGE.permissions,
        child: false,
        url: "/web-news",
      },
    ]
  },
  {
    id:8,
    labelText: "Reasons",
    routeName: "reasons",
    icon: "mdi mdi-head-question",
    permissions: permissions.REASON_MANAGE.permissions,
    child: false,
    url: "/reasons",
  },
  {
    id:9,
    labelText: "User Management",
    routeName: "userMange",
    icon: "far fa-building",
    permissions: permissions.NONE.permissions,
    child: true,
    nested: [
      {
        labelText: "User Account",
        routeName: "user",
        icon: "fas fa-user",
        url: "/user",
        child: false,
        permissions: permissions.USER_ACCOUNT_MANAGE.permissions,
      },
      {
        labelText: "User Roles",
        routeName: "role",
        icon: "fas fa-user-shield",
        permissions: permissions.USER_ROLE_MANAGE.permissions,
        child: false,
        url: "/userRole"
      },
    ]
  },
];

export { siteMenu };