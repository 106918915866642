/*
 * @Author: Anjula Karunarathne
 * @Date: 2021-01-21 16:05:43
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-02-02 01:02:09
 */
import React, { useContext, useEffect } from "react";
import {
  FormWrapper,
  SelectBoxWithState,
  InputBoxWithState,
  CheckBoxWithState,
  DatePickerWithState,
  NumberInputBoxWithState,
} from "../../../../ui-components/ui-elements/form";
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import {
  licenceTypesListApi,
  vehicleClassListAPI,
  fuelTypesListAPI,
  chargeComponentsListAPI,
} from "../../../../../config/apiUrl.config";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import {
  ApplicabilityTypeCode,
  ChargeEffectCode,
  ChargeTypeCode,
  WeightBaseCode,
  DateBaseCode,
  DateDurationCode,
  DateDurationMode
} from "../../../../../config/enum.config";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { getCodeName } from "./ViewAdditionDeduction";
import { InputLabelWrapper } from "../../../../ui-components/ui-elements/form/form-includes/FormCoreIncludes";
const ViewLabel = ({ label = null, value = null }) => {
  return (
    <div className="defaultMarginTop row">
      <div className={`col-md-4 text-left tittle-style defaultInputLabel`}>
        <InputLabelWrapper lableText={label} />
      </div>
      <div className={`col-md-8 text-left detail-style view-block-word-wrap-style`}>{value}</div>
    </div>
  );
};

const AdditionDeductionForm = (props) => {
  const formKey = `${props.fromParent.formKey}`;
  const uiType = `${props.fromParent.uiType}`;
  const [formState, formAction] = useContext(FormContext);

  const setMinDateFn = () => {
    let minDate = new Date();
    minDate = new Date(minDate.setDate(minDate.getDate() + 1));
    return minDate;
  };

  useEffect(() => {
    if (
      _get(formState, `${formKey}._uiFormDescription`, undefined) === "additionDeductionCreateAPIKey_create" 
      || _get(formState, `${formKey}._uiFormDescription`, undefined) === "additionDeductionEditAPIKey_edit"
    ) {  
      if(!_get(formState, `${formKey}.engine_capacity_base_applied`, undefined)){
        formAction.changeInputFn(formKey, "engine_capacity_from", "");
        formAction.changeInputFn(formKey, "engine_capacity_to", "");
      }
    }
  }, [_get(formState, `${formKey}.engine_capacity_base_applied`, undefined)]);

  useEffect(() => {
    if (
      _get(formState, `${formKey}._uiFormDescription`, undefined) === "additionDeductionCreateAPIKey_create" 
      || _get(formState, `${formKey}._uiFormDescription`, undefined) === "additionDeductionEditAPIKey_edit"
    ) {  
      if(!_get(formState, `${formKey}.seat_base_applied`, undefined)){
        formAction.changeInputFn(formKey, "seat_base_from", "");
        formAction.changeInputFn(formKey, "seat_base_to", "");
      }
    }
  }, [_get(formState, `${formKey}.seat_base_applied`, undefined)]);

  useEffect(() => {
    if (
      _get(formState, `${formKey}._uiFormDescription`, undefined) === "additionDeductionCreateAPIKey_create" 
      || _get(formState, `${formKey}._uiFormDescription`, undefined) === "additionDeductionEditAPIKey_edit"
    ) {      
      if(!_get(formState, `${formKey}.weight_base_applied`, undefined)){
        formAction.changeInputFn(formKey, "weight_base_code", "");
        formAction.changeInputFn(formKey, "weight_base_value_from", "");
        formAction.changeInputFn(formKey, "weight_base_value_to", "");
      }
    }
  }, [_get(formState, `${formKey}.weight_base_applied`, undefined)]);

  useEffect(() => {
    if (
      _get(formState, `${formKey}._uiFormDescription`, undefined) === "additionDeductionCreateAPIKey_create" 
      || _get(formState, `${formKey}._uiFormDescription`, undefined) === "additionDeductionEditAPIKey_edit"
    ) {
      if(!_get(formState, `${formKey}.date_base_applied`, undefined)){
        formAction.changeInputFn(formKey, "date_base_code", "");
        
        formAction.changeInputFn(formKey, "date_duration_code", "");
        formAction.changeInputFn(formKey, "date_duration_mode", "");
        formAction.changeInputFn(formKey, "date_value", "");
      }
    }
  }, [_get(formState, `${formKey}.date_base_applied`, undefined)]);

  useEffect(() => {
    if (
      _get(formState, `${formKey}._uiFormDescription`, undefined) === "additionDeductionCreateAPIKey_create" 
      || _get(formState, `${formKey}._uiFormDescription`, undefined) === "additionDeductionEditAPIKey_edit"
    ) {
      if(_get(formState, `${formKey}.date_base_code`, undefined) === 'CUD'){
        formAction.changeInputFn(formKey, "date_base", new Date());
      }
    }
  }, [_get(formState, `${formKey}.date_base_code`, undefined)]);

  useEffect(() => {
    if (
      _get(formState, `${formKey}._uiFormDescription`, undefined) ===
      "additionDeductionCreateAPIKey_create"
    ) {
      formAction.changeInputFn(formKey, "charge_type_value", "");
    }
  }, [_get(formState, `${formKey}.charge_type_code`, undefined)]);

  return (
    <FormWrapper className="col 2"
      setGroupName={formKey}
      formGroupLinkWith={formKey}
      setFormObject={{
        id: _get(props, "row.id", undefined),
        name: _get(props, "row.name", ""),
        applicability_type_code: _get(props, "row.applicability_type_code", ""),
        charge_effect_code: _get(props, "row.charge_effect_code", ""),
        charge_type_code: _get(props, "row.charge_type_code", ""),
        charge_type_value: _get(props, "row.charge_type_value", ""),
        charge_component: _get(props, "row.charge_component.code", null),
        effective_date: _get(props, "row.effective_date", setMinDateFn()),
        active: _get(props, "row.active", "1"),
        licence_type_id: _get(props, "row.licence_type_id.id", ""),
        vehicle_class_id: _get(props, "row.vehicle_class_id.id", ""),
        fuel_type_id: _get(props, "row.fuel_type_id.id", ""),
        weight_base_applied: _get(props, "row.weight_base_applied", 0) == 1 ? true : false,
        weight_base_code: _get(props, "row.weight_base_code", ""),
        weight_base_value_from: _get(props, "row.weight_base_value_from", "") == null ? "" : props.row.weight_base_value_from,
        weight_base_value_to: _get(props, "row.weight_base_value_to", "") == null ? "" : props.row.weight_base_value_to,
        seat_base_applied: _get(props, "row.seat_base_applied", 0) == 1 ? true : false,
        seat_base_from: _get(props, "row.seat_base_from", "") == null ? "" : props.row.seat_base_from,
        seat_base_to: _get(props, "row.seat_base_to", "") == null ? "" : props.row.seat_base_to,
        date_base_applied: _get(props, "row.date_base_applied", 0) == 1 ? true : false,
        date_base_code: _get(props, "row.date_base_code", ""),
        date_duration_code: _get(props, "row.date_duration_code", ""),
        date_duration_mode:_get(props,"row.date_duration_mode",""),
        date_base: _get(props, "row.date_base", new Date()),
        date_value: _get(props, "row.date_value", "") == null ? "" : props.row.date_value,
        engine_capacity_base_applied: _get(props, "row.engine_capacity_base_applied", false) == 1 ? true : false,
        engine_capacity_from: _get(props, "row.engine_capacity_from", "") == null ? "" : props.row.engine_capacity_from,
        engine_capacity_to: _get(props, "row.engine_capacity_to", "") == null ? "" : props.row.engine_capacity_to,
      }}
      onDestroyUnsetFormObject={true}
    >
      <UICard cardHeading="Addition/Deduction Information">
        {uiType === "edit" ? (
          <ViewLabel label={"Name"} value={_get(props, "row.name", "")} />
        ) : (
          <InputBoxWithState
            formGroupName={formKey}
            inputName={"name"}
            labelText="Name"
            maxLength="50"
            isRequired={uiType != "edit" ? true : false}
            isDisabled={uiType === "edit" ? true : false}
            rows={2}
          />
        )}
        {uiType === "edit" ? (
          <div>
            <ViewLabel
              label={"Applicability Type"}
              value={getCodeName(
                ApplicabilityTypeCode,
                _get(props, "row.applicability_type_code", "")
              )}
            />
            <ViewLabel
              label={"Charge Effect"}
              value={getCodeName(
                ChargeEffectCode,
                _get(props, "row.charge_effect_code", "")
              )}
            />
          </div>
        ) : (
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <SelectBoxWithState
                formGroupName={formKey}
                dataList={ApplicabilityTypeCode}
                inputName={"applicability_type_code"}
                labelText="Applicability Type"
                isRequired={uiType != "edit" ? true : false}
                isDisabled={uiType === "edit" ? true : false}
                emptySelectOptionTxt={"Select Applicability Type"}
                onChangeFn={(event) => {
                  if (event.value === "VHS") {
                    formAction.changeInputFn(formKey, "weight_base_applied", "", false);
                    formAction.changeInputFn(formKey, "seat_base_applied", "", false);
                    formAction.changeInputFn(formKey, "date_base_applied", "", false);
                    formAction.changeInputFn(formKey, "engine_capacity_base_applied", "", false);
                    formAction.changeInputFn(formKey, "date_base_code", "", "");

                  } else if (event.value === "GEN") {
                    formAction.changeInputFn(formKey, "seat_base_from", "", "");
                    formAction.changeInputFn(formKey, "seat_base_to", "", "");
                    formAction.changeInputFn(formKey, "weight_base_code", "", "");
                    formAction.changeInputFn(formKey, "weight_base_value_from", "", "");
                    formAction.changeInputFn(formKey, "weight_base_value_to", "", "");
                    formAction.changeInputFn(formKey, "date_value", "", "");
                    formAction.changeInputFn(formKey, "engine_capacity_from", "", "");
                    formAction.changeInputFn(formKey, "engine_capacity_to", "", "");
                  }
                }}
              />
            </div>
            <div className="col-md-6 col-xs-12">
              <SelectBoxWithState
                formGroupName={formKey}
                dataList={ChargeEffectCode}
                inputName={"charge_effect_code"}
                labelText="Charge Effect"
                isRequired={uiType != "edit" ? true : false}
                isDisabled={uiType === "edit" ? true : false}
                emptySelectOptionTxt={"Select Charge Effect"}
              />
            </div>
          </div>
        )}

        {_get(formState, `${formKey}.applicability_type_code`, []) === "GEN" ? (
          <div className="defaultMarginTop">
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <SelectBoxWithState
                  formGroupName={formKey}
                  dataList={ChargeTypeCode}
                  inputName={"charge_type_code"}
                  labelText="Charge Type"
                  isRequired={true}
                  emptySelectOptionTxt={"Select Charge Type"}
                  onChangeFn={(event) => {
                    formAction.changeInputFn(formKey, "charge_type_value", "", "");

                  }}
                />
              </div>
              {_get(formState, `${formKey}.charge_type_code`, []) === "PRE" ? (
                <div className="col-md-6 col-xs-12">
                  <NumberInputBoxWithState
                    formGroupName={formKey}
                    inputName={"charge_type_value"}
                    labelText="Value (%)"
                    isRequired={true}
                    isThousandSeparator={false}
                    decimalScale={2}
                    isAllowedFn={(value) => {
                      let checkValue = value.value.toString();

                      if (checkValue.includes(".")) {
                        checkValue = checkValue.split(".")[0];
                      }

                      return checkValue.length <= 3;
                    }}
                  />
                </div>
              ) : (
                <div className="col-md-6 col-xs-12">
                  <NumberInputBoxWithState
                    formGroupName={formKey}
                    inputName={"charge_type_value"}
                    labelText="Value"
                    isRequired={true}
                    decimalScale={2}
                    isAllowedFn={(value) => {
                      let checkValue = value.value.toString();

                      if (checkValue.includes(".")) {
                        checkValue = checkValue.split(".")[0];
                      }

                      return checkValue.length <= 5;
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        ) : null}

        {_get(formState, `${formKey}.applicability_type_code`, []) === "GEN" ? (
          <div className="defaultMarginTop">
            <SelectBoxWithState
              formGroupName={formKey}
              apiUrl={chargeComponentsListAPI.url + "?sort_by=display_order|asc"}
              apiStateKey={chargeComponentsListAPI.key}
              keyName={"code"}
              valueName={"name"}
              inputName={"charge_component"}
              emptySelectOptionTxt={"Select Charge Component"}
              labelText={"Charge Component"}
            />
          </div>
        ) : null}

        <div className="defaultMarginTop">
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <DatePickerWithState
                formGroupName={formKey}
                inputName={"effective_date"}
                labelText="Effective Date"
                isRequired={true}
                minDate={setMinDateFn()}
              />
            </div>
            <div className="col-md-6 col-xs-12">
              <SelectBoxWithState
                formGroupName={formKey}
                dataList={[
                  {
                    id: "1",
                    value: "Active",
                  },
                  {
                    id: "0",
                    value: "Inactive",
                  },
                ]}
                inputName={"active"}
                emptySelectOptionTxt={null}
                labelText="Status"
              />
            </div>
          </div>
        </div>
      </UICard>
      {_get(formState, `${formKey}.applicability_type_code`, []) === "GEN" ? (
        <UICard
          cardHeading="Applicable Criteria"
          elementStyle="defaultMarginTopBottom"
        >
          <SelectBoxWithState
            apiUrl={`${licenceTypesListApi.url}?sort_by=display_order|asc&prefixes=no&prefixes=nu&prefixes=ca&prefixes=de`}
            apiStateKey={licenceTypesListApi.key}
            formGroupName={formKey}
            keyName={"id"}
            valueName={"name"}
            inputName={"licence_type_id"}
            labelText="Licence Type"
            isEnableAllOption = {true}
            emptySelectOptionTxt={null}
          />

          <SelectBoxWithState
            apiUrl={vehicleClassListAPI.url}
            apiStateKey={vehicleClassListAPI.key}
            keyName={"id"}
            valueName={"name"}
            formGroupName={formKey}
            inputName={"vehicle_class_id"}
            labelText="Vehicle Class"
            isEnableAllOption = {true}
            emptySelectOptionTxt={null}
          />

          <SelectBoxWithState
            apiUrl={fuelTypesListAPI.url}
            apiStateKey={fuelTypesListAPI.key}
            keyName={"id"}
            valueName={"name"}
            formGroupName={formKey}
            inputName={"fuel_type_id"}
            labelText="Fuel Type"
            isEnableAllOption = {true}
            emptySelectOptionTxt={null}
          />

          <div className="defaultMarginTopBottom">
            <CheckBoxWithState
              inputName={"weight_base_applied"}
              formGroupName={formKey}
              labelText={"Weight Basis applicable"}
              elementWrapperStyle="defaultHalfPaddingBottom"
            />

            {_get(formState, `${formKey}.weight_base_applied`, []) === true ? (
              <SelectBoxWithState
                formGroupName={formKey}
                dataList={WeightBaseCode}
                inputName={"weight_base_code"}
                labelText="Weight Base (kg)"
                emptySelectOptionTxt={"Select Weight Basis"}
                isRequired={true}
              />
            ) : null}

            {_get(formState, `${formKey}.weight_base_applied`, []) === true ? (
              <div className="row">
                <div className="col-md-6 col-xs-12">
                  <NumberInputBoxWithState
                    formGroupName={formKey}
                    inputName={"weight_base_value_from"}
                    labelText="From"
                    isThousandSeparator={false}
                    decimalScale={2}
                    isRequired={true}
                    isAllowedFn={(value) => {
                      let checkValue = value.value.toString();

                      if (checkValue.includes(".")) {
                        checkValue = checkValue.split(".")[0];
                      }

                      return checkValue.length <= 6;
                    }}
                  />
                </div>
                <div className="col-md-6 col-xs-12">
                  <NumberInputBoxWithState
                    formGroupName={formKey}
                    inputName={"weight_base_value_to"}
                    labelText="To"
                    isThousandSeparator={false}
                    decimalScale={2}
                    isRequired={true}
                    isAllowedFn={(value) => {
                      let checkValue = value.value.toString();

                      if (checkValue.includes(".")) {
                        checkValue = checkValue.split(".")[0];
                      }

                      return checkValue.length <= 6;
                    }}
                  />
                </div>
              </div>
            ) : null}
          </div>

          <div className="defaultMarginTopBottom">
            <CheckBoxWithState
              inputName={"seat_base_applied"}
              formGroupName={formKey}
              labelText={"Seat Basis applicable"}
              elementWrapperStyle="defaultHalfPaddingBottom"
            />

            {_get(formState, `${formKey}.seat_base_applied`, []) === true ? (
              <div className="row">
                <div className="col-md-6 col-xs-12">
                  <NumberInputBoxWithState
                    formGroupName={formKey}
                    inputName={"seat_base_from"}
                    labelText="From"
                    isRequired={true}
                    decimalScale={0}
                    isAllowedFn={(value) => {
                      let checkValue = value.value.toString();

                      if (checkValue.includes(".")) {
                        checkValue = checkValue.split(".")[0];
                      }

                      return checkValue.length <= 3;
                    }}
                  />
                </div>
                <div className="col-md-6 col-xs-12">
                  <NumberInputBoxWithState
                    formGroupName={formKey}
                    inputName={"seat_base_to"}
                    labelText="To"
                    decimalScale={0}
                    isRequired={true}
                    isAllowedFn={(value) => {
                      let checkValue = value.value.toString();

                      if (checkValue.includes(".")) {
                        checkValue = checkValue.split(".")[0];
                      }

                      return checkValue.length <= 3;
                    }}
                  />
                </div>
              </div>
            ) : null}
          </div>

          <div className="defaultMarginTopBottom">
            <CheckBoxWithState
              inputName={"date_base_applied"}
              formGroupName={formKey}
              labelText={"Specific Date Basis applicable"}
              elementWrapperStyle="defaultHalfPaddingBottom"
            />

            {_get(formState, `${formKey}.date_base_applied`, []) === true ? (
              <div>
                <div className="row">
                  <div className="col-md-6 col-xs-12">
                    <SelectBoxWithState
                      formGroupName={formKey}
                      dataList={DateBaseCode}
                      inputName={"date_base_code"}
                      labelText="Date Base"
                      isRequired={true}
                      emptySelectOptionTxt={"Select Date Basis"}
                    />
                    {_get(formState, `${formKey}.date_base_code`, []) === "VRD" ||
                    _get(formState, `${formKey}.date_base_code`, []) ==="CUD" ? (
                    
                        <SelectBoxWithState
                      formGroupName={formKey}
                      dataList={DateDurationMode}
                      inputName={"date_duration_mode"}
                      labelText="Effected"
                      isRequired={true}
                      emptySelectOptionTxt={"AFTER/BEFORE"}
                    />
					        ) : null}
                  </div>
                  {_get(formState, `${formKey}.date_base_code`, []) ===
                    "CUD" ? (
                    <div className="col-md-6 col-xs-12">
                      <DatePickerWithState
                        formGroupName={formKey}
                        inputName={"date_base"}
                        labelText="Date"
                        isRequired={true}
                      />
                    </div>
                  ) : null}
                </div>

                {_get(formState, `${formKey}.date_base_code`, []) === "VRD" ||
                  _get(formState, `${formKey}.date_base_code`, []) === "CUD" ? (
                  <div className="row">
                    <div className="col-md-6 col-xs-12">
                      <SelectBoxWithState
                        formGroupName={formKey}
                        dataList={DateDurationCode}
                        inputName={"date_duration_code"}
                        labelText="Duration"
                        isRequired={true}
                        emptySelectOptionTxt={"Select Duration"}
                      />
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <NumberInputBoxWithState
                        formGroupName={formKey}
                        inputName={"date_value"}
                        labelText="Value"
                        isRequired={true}
                        decimalScale={0}
                        isAllowedFn={(value) => {
                          let checkValue = value.value.toString();

                          if (checkValue.includes(".")) {
                            checkValue = checkValue.split(".")[0];
                          }

                          return checkValue.length <= 3;
                        }}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>

          <div className="defaultMarginTopBottom">
            <CheckBoxWithState
              inputName={"engine_capacity_base_applied"}
              formGroupName={formKey}
              labelText={"Engine Capacity Basis Applicable"}
              elementWrapperStyle="defaultHalfPaddingBottom"
            />

            {_get(formState, `${formKey}.engine_capacity_base_applied`, []) ===
              true ? (
              <div className="row">
                <div className="col-md-6 col-xs-12">
                  <NumberInputBoxWithState
                    formGroupName={formKey}
                    inputName={"engine_capacity_from"}
                    labelText="From"
                    decimalScale={0}
                    isRequired={true}
                    isThousandSeparator={false}
                    isAllowedFn={(value) => {
                      let checkValue = value.value.toString();

                      if (checkValue.includes(".")) {
                        checkValue = checkValue.split(".")[0];
                      }

                      return checkValue.length <= 4;
                    }}
                  />
                </div>
                <div className="col-md-6 col-xs-12">
                  <NumberInputBoxWithState
                    formGroupName={formKey}
                    inputName={"engine_capacity_to"}
                    labelText="To"
                    decimalScale={0}
                    isRequired={true}
                    isThousandSeparator={false}
                    isAllowedFn={(value) => {
                      let checkValue = value.value.toString();

                      if (checkValue.includes(".")) {
                        checkValue = checkValue.split(".")[0];
                      }

                      return checkValue.length <= 4;
                    }}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </UICard>
      ) : null}
    </FormWrapper>
  );
};

export { AdditionDeductionForm };
