import React, { Fragment, useContext } from "react";

import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { CoreContext } from "../../../modules/core/context-providers/CoreContext.provider";
import { FormContext } from "../../../modules/core/context-providers/FormContext.provider";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { actionBtnTypes, DataTable } from "../../../ui-components/ui-elements/table/DataTable";
import { SearchElement } from "./includes/VehicleClassElements";
import { ViewVehicleClassNew } from "./includes/ViewVehicle";
import { CheckPermission, UICard } from "../../../ui-components/ui-elements/common/BaseElements";
//import { EditVehicleClass } from "./includes/EditVehicleClass";
import { SubmitButton, InputBoxWithState, FormWrapper, formCacheLevel, SelectBoxWithState } from "../../../ui-components/ui-elements/form";
import { vehicleClassAPI } from "../../../../config/apiUrl.config";
import { getDataByFormObject } from "../../../../helpers/common-helpers/common.helpers";
import { apiResponseStoringType } from "../../../../config/apiResponseKey";
import { UIContext } from "../../../ui-components/context-providers/UIContext.provider";
import { permissions } from "../../../../config/permission.config";
import { useEffect } from "react";

const fromKey = "vehicleClassForm"
const emptyFn = (...para) => undefined;
const CustomButtons = ({
    onClosePopupFn = emptyFn,
    formGroupName = ""
}) => {

    const close = () => {
        onClosePopupFn();
    }

    return (
        <Fragment>
            <SubmitButton
                btnText="Save"
                elementStyle="btnWrapper"
            />
        </Fragment>
    )
}

const EditVehicleClass = (props) => {

    return (
        <FormWrapper
            setGroupName={fromKey}
            //apiUrl={vehicleClassAPI.url}
            setFormObject={{
                prefix: _get(props, "row.prefix", ""),
                name: _get(props, "row.name", ""),
                active: _get(props, "row.active", 1),
                insurance_applicability_code: _get(props, "row.insurance_applicability_code", "MAN"),
                vet_applicability_code: _get(props, "row.vet_applicability_code", "MAN"),
                fitness_applicability_code: _get(props, "row.fitness_applicability_code", "MAN"),
                route_permit_applicability_code: _get(props, "row.route_permit_applicability_code", "MAN"),
                dmt_mapping_parameter: _get(props, "row.dmt_mapping_parameter", ""),
            }}
            onDestroyUnsetFormObject={true}>
            <UICard>
                <InputBoxWithState
                    formGroupName={fromKey}
                    labelText={"Vehicle Class Prefix"}
                    isRequired={true}
                    inputName={"prefix"}
                    maxLength={"2"}
                    cacheLevel={formCacheLevel.updateOnIndividual}
                />
                <InputBoxWithState
                    formGroupName={fromKey}
                    labelText={"Vehicle Class Name"}
                    isRequired={true}
                    inputName={"name"}
                    maxLength={"50"}
                    cacheLevel={formCacheLevel.updateOnIndividual}
                />
                <SelectBoxWithState
                    formGroupName={fromKey}
                    labelText={"Status"}
                    inputName={"active"}
                    valueName={"value"}
                    isRequired={false}
                    keyName={"id"}
                    dataList={[{ "id": 1, "value": "Active" }, { "id": 0, "value": "Inactive" }]}
                />
            </UICard>
            <UICard cardHeading={"Document Validations"}>
                <SelectBoxWithState
                    formGroupName={fromKey}
                    labelText={"Insurance"}
                    emptySelectOptionTxt={"Please Select type"}
                    inputName={"insurance_applicability_code"}
                    valueName={"value"}
                    keyName={"id"}
                    dataList={[
                        { id: "MAN", value: "Mandatory" },
                        { id: "OPT", value: "Optional" },
                        { id: "NOT", value: "Not Required" },
                    ]}
                    isEnableEmptySelectAfter={true}
                    cacheLevel={formCacheLevel.updateOnIndividual}
                />
                <SelectBoxWithState
                    formGroupName={fromKey}
                    labelText={"VET"}
                    emptySelectOptionTxt={"Please Select type"}
                    inputName={"vet_applicability_code"}
                    valueName={"value"}
                    keyName={"id"}
                    dataList={[
                        { id: "MAN", value: "Mandatory" },
                        { id: "OPT", value: "Optional" },
                        { id: "NOT", value: "Not Required" },
                    ]}
                    isEnableEmptySelectAfter={true}
                    cacheLevel={formCacheLevel.updateOnIndividual}
                />
                <SelectBoxWithState
                    formGroupName={fromKey}
                    labelText={"VFT"}
                    emptySelectOptionTxt={"Please Select type"}
                    inputName={"fitness_applicability_code"}
                    valueName={"value"}
                    keyName={"id"}
                    dataList={[
                        { id: "MAN", value: "Mandatory" },
                        { id: "OPT", value: "Optional" },
                        { id: "NOT", value: "Not Required" },
                    ]}
                    isEnableEmptySelectAfter={true}
                    cacheLevel={formCacheLevel.updateOnIndividual}
                />
                <SelectBoxWithState
                    formGroupName={fromKey}
                    labelText={"VRP"}
                    emptySelectOptionTxt={"Please Select type"}
                    inputName={"route_permit_applicability_code"}
                    valueName={"value"}
                    keyName={"id"}
                    dataList={[
                        { id: "MAN", value: "Mandatory" },
                        { id: "OPT", value: "Optional" },
                        { id: "NOT", value: "Not Required" },
                    ]}
                    isEnableEmptySelectAfter={true}
                    cacheLevel={formCacheLevel.updateOnIndividual}
                />
            </UICard>
            <UICard cardHeading="DMT Mapping Parameters">
                <InputBoxWithState
                    formGroupName={fromKey}
                    labelText={"DMT Mapping Parameter"}
                    isRequired={true}
                    inputName={"dmt_mapping_parameter"}
                    maxLength={"50"}
                    cacheLevel={formCacheLevel.updateOnIndividual}
                />
            </UICard>
        </FormWrapper>
    );
};

const CustomButtonAdd = ({
    row = {},
    onClosePopupFn = emptyFn,
    formGroupName = "vehicleClass"
}) => {

    const [coreState, coreAction] = useContext(CoreContext);
    const [formState, formAction] = useContext(FormContext);
    const [, uiAction] = useContext(UIContext);
    const close = () => {
        onClosePopupFn();

    }

    return (
        <Fragment>
            <CheckPermission
                permission={permissions.VEHICLE_CLASS_ADD.permissions}
            >
                <SubmitButton
                    btnText="Save"
                    startIcon={"far fa-save"}
                    elementStyle="btnWrapper"
                    formGroupName={fromKey}
                    isValidate={true}
                    flashMessages={{
                        "success": {
                            status: true,
                            message: "Vehicle Class is successfully saved.",
                            messageType: "success"
                        }

                    }}
                    mapValidationErrorObj={{
                        name: "Vehicle Class Name already exists",
                        prefix: "Vehicle Class Prefix already exists",

                    }}
                    dataTableKey={vehicleClassAPI.key}
                    validationObject={{
                        fileds: {
                            name: "Vehicle Class Name",
                            prefix: "Vehicle Class Prefix",
                            dmt_mapping_parameter: "DMT Mapping Parameter"


                        },
                        rules: {
                            name: "required|max:50",
                            prefix: "required|max:2",
                            dmt_mapping_parameter: "required|max:50"

                        },
                        message: {},
                    }}
                    callApiObject={{
                        isSetHeaders: true,
                        multipart: false,
                        method: "post",
                        onUpload: false,
                    }}
                    apiDataStoringObject={{
                        setLoader: true,
                        storingType: apiResponseStoringType.dateTableLinkedForm
                    }}
                    onGetAPIEndPointFn={(formState) => {

                        return {
                            url: vehicleClassAPI.url,
                            key: vehicleClassAPI.key
                        }
                    }}
                    onChangeRequestBodyFn={({ ...formObject }) => {
                        return getDataByFormObject({
                            ...formObject,
                            prefix: formObject.prefix.trim(),
                            name: formObject.name.trim()
                        });
                    }}
                    onResponseCallBackFn={(response, error) => {
                        if (response.length === undefined && error === undefined && response.data.errors === undefined) {
                            close();
                        }

                    }}
                />
            </CheckPermission>

        </Fragment>
    )
}

const CustomButtonEdit = ({
    row = {},
    onClosePopupFn = emptyFn,
    formGroupName = "vehicleClass"
}) => {

    const [coreState, coreAction] = useContext(CoreContext);
    const [formState, formAction] = useContext(FormContext);
    const [uiAction] = useContext(UIContext);
    const close = () => {
        onClosePopupFn();

    }

    return (
        <Fragment>
            <CheckPermission
                permission={permissions.VEHICLE_CLASS_EDIT.permissions}
            >
                <SubmitButton
                    btnText="Update"
                    startIcon={"far fa-edit"}
                    elementStyle="btnWrapper"
                    formGroupName={fromKey}
                    isValidate={true}
                    flashMessages={{
                        "success": {
                            status: true,
                            message: "Vehicle Class is successfully updated.",
                            messageType: "success"
                        }
                    }}
                    mapValidationErrorObj={{
                        name: "Vehicle Class Name already exists",
                        prefix: "Vehicle Class Prefix already exists",

                    }}
                    dataTableKey={vehicleClassAPI.key}
                    validationObject={{
                        fileds: {
                            name: "Vehicle Class Name",
                            prefix: "Vehicle Class Prefix",
                            dmt_mapping_parameter: "DMT Mapping Parameter"

                        },
                        rules: {
                            name: "required|max:50",
                            prefix: "required|max:2",
                            dmt_mapping_parameter: "required|max:50"

                        },
                        message: {},
                    }}
                    callApiObject={{
                        isSetHeaders: true,
                        multipart: false,
                        method: "put",
                        onUpload: false,
                    }}
                    apiDataStoringObject={{
                        setLoader: true,
                        storingType: apiResponseStoringType.dateTableLinkedForm
                    }}
                    onGetAPIEndPointFn={(formState) => {

                        return {
                            url: (`${vehicleClassAPI.url}/` + row.id),
                            key: vehicleClassAPI.key
                        }
                    }}
                    onChangeRequestBodyFn={(fromObject) => {
                        return getDataByFormObject({
                            ...fromObject,
                        })
                    }}
                    onResponseCallBackFn={(response, errors) => {

                        if (response.length === undefined && errors === undefined && response.data.errors === undefined) {
                            close();
                        }
                    }}
                />

            </CheckPermission>

        </Fragment>
    )
}

const ManageVehicleClass = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <DashboardTemplate
            pageTitle="Manage Vehicle Class"
            breadCrumbList={[
                { urlTo: "/", displayName: "Home" },
                { urlTo: "", displayName: "Configurations" },
                { urlTo: "", displayName: "Vehicle Class" },
            ]}
        >
            <DataTable
                tableName="Vehicle Classes"
                apiUrl={`${vehicleClassAPI.url}?sort_by=name|asc`}
                dataTableKey={vehicleClassAPI.key}
                isSetSearchFrom={true}
                defaultSearchFormObject={{
                    "name": "",
                    "prefix": "",
                    "active": null
                }}
                searchFromComponent={<SearchElement dataTableKey={vehicleClassAPI.key} />}
                tableHeaderList={[
                    { displayName: "Vehicle Class Prefix", key: "prefix", isSort: false },
                    { displayName: "Vehicle Class Name", key: "name", isSort: false },
                    { displayName: "Status", key: "active", isSort: false, onPrintFn: (rawData) => ((rawData.active == 1) ? "Active" : "Inactive") },

                ]}
                actionTypeList={[
                    {
                        actionType: actionBtnTypes.create,
                        actionBtn: {
                            "permissions": permissions.VEHICLE_CLASS_ADD.permissions
                        },
                        modelContentElement: EditVehicleClass,
                        dialogPopupProps: {
                            isFullScreen: false,
                            dialogTitle: "Add Vehicle Class",
                            closeButton: {
                                btnText: "Cancel",
                                startIcon: "far fa-times-circle",
                            },
                            isCustomButton: true,
                            customButtonComponent: CustomButtonAdd,
                            isConfirmationPopup: true,
                        }
                    },
                    {
                        actionType: actionBtnTypes.view,
                        actionBtn: {  tooltip: 'View', "permissions": permissions.VEHICLE_CLASS_VIEW.permissions },
                        modelContentElement: ViewVehicleClassNew,
                        dialogPopupProps: {
                            isFullScreen: false,
                            isSaveButton: false,
                            isCloseButton: true,
                            dialogTitle: "View Vehicle Class",
                            closeButton: {
                                btnText: "Close",
                                startIcon: "far fa-times-circle",
                            }
                        }
                    },

                    {
                        actionType: actionBtnTypes.edit,
                        actionBtn: { tooltip: 'Edit', "permissions": permissions.VEHICLE_CLASS_EDIT.permissions },
                        modelContentElement: EditVehicleClass,
                        dialogPopupProps: {
                            isFullScreen: false,
                            dialogTitle: "Edit Vehicle Class",
                            closeButton: {
                                btnText: "Cancel",
                                startIcon: "far fa-times-circle",
                            },
                            isCustomButton: true,
                            customButtonComponent: CustomButtonEdit,
                            isConfirmationPopup: true,
                        },
                    }
                ]}
            />
        </DashboardTemplate>
    );
};

export default ManageVehicleClass;
