
import React, { useContext } from "react";
import { templateTypes } from "../../../../../config/template.config";
import {
    FormWrapper,
    SelectBoxWithState,
    InputBoxWithState,
    NumberInputBoxWithState
} from "../../../../ui-components/ui-elements/form";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { vftListAPI } from "../../../../../config/apiUrl.config";
import { contactsArrayToObject } from "../../../../../helpers/common-helpers/contact.helpers";
import { ContactTypeCode } from "../../../../../config/enum.config";
import { AuthContext } from '../../../../modules/core/context-providers/AuthContext.provider';
import { isEmptyValue } from "../../../../../helpers/common-helpers/common.helpers"; import { CustomButtonAdd } from "../VFTCompany";

const AddVFTCompany = (props) => {
    const [authStatus, authAction] = useContext(AuthContext);
    const formKey = props.fromParent.uiType === "CREATE" ? (vftListAPI.key + "_create") : (vftListAPI.key + "_edit");
  
    return (
        <FormWrapper
            setGroupName={formKey}
            formGroupLinkWith={formKey}
            onDestroyUnsetFormObject={false}
            setFormObject={{
                id: _get(props, "row.id", ""),
                code: _get(props, "row.code", ""),
                br_number: _get(props, "row.br_number", ""),
                name: _get(props, "row.service.name", ""),
                name_si: _get(props, "row.service.name_si", ""),
                name_ta: _get(props, "row.service.name_ta", ""),
                active: _get(props, "row.service.active", 1),
                line1: _get(props, "row.service.addresses[0].line1", ""),
                line1_si: _get(props, "row.service.addresses[0].line1_si", ""),
                line1_ta: _get(props, "row.service.addresses[0].line1_ta", ""),
                line2: _get(props, "row.service.addresses[0].line2", ""),
                line2_si: _get(props, "row.service.addresses[0].line2_si", ""),
                line2_ta: _get(props, "row.service.addresses[0].line2_ta", ""),
                city: _get(props, "row.service.addresses[0].city", ""),
                city_si: _get(props, "row.service.addresses[0].city_si", ""),
                city_ta: _get(props, "row.service.addresses[0].city_ta", ""),
                postal_code: _get(props, "row.service.addresses[0].postal_code", ""),
                contact_number_1: (props.fromParent.uiType !== "CREATE" && !isEmptyValue(contactsArrayToObject(_get(props, `row.service.contacts`, []), false, ContactTypeCode.PHONE))) ? `0${contactsArrayToObject(_get(props, `row.service.contacts`, []), false, ContactTypeCode.PHONE)}` : contactsArrayToObject(_get(props, `row.service.contacts`, []), false, ContactTypeCode.PHONE),
                contact_number_2: (props.fromParent.uiType !== "CREATE" && !isEmptyValue(contactsArrayToObject(_get(props, `row.service.contacts`, []), false, ContactTypeCode.MOBILE))) ? `0${contactsArrayToObject(_get(props, `row.service.contacts`, []), false, ContactTypeCode.MOBILE)}` : contactsArrayToObject(_get(props, `row.service.contacts`, []), false, ContactTypeCode.MOBILE),
                email: contactsArrayToObject(_get(props, `row.service.contacts`, []), false, ContactTypeCode.EMAIL),
                fax: (props.fromParent.uiType !== "CREATE" && !isEmptyValue(contactsArrayToObject(_get(props, `row.service.contacts`, []), false, ContactTypeCode.FAX))) ? `0${contactsArrayToObject(_get(props, `row.service.contacts`, []), false, ContactTypeCode.FAX)}` : contactsArrayToObject(_get(props, `row.service.contacts`, []), false, ContactTypeCode.FAX),
                province_code: _get(authStatus, "authUser.province_code", null),
                pdmt_id: _get(authStatus, "authUser.pdmt_id", null),
            }}
        >
            <div className="row">
                <div className="col-md-12">
                    <table className={"fullWidthDiv"}>
                        <tr>
                            <th className="textRight defaultPaddingRight">VFT Company Code <sup className="text-danger">*</sup></th>
                            <td className={"defaultPaddingRight"}>
                                {props.fromParent.uiType === "CREATE" ? (
                                    <InputBoxWithState
                                        formGroupName={formKey}
                                        inputName={"code"}
                                        maxLength={20}
                                    />
                                ) : (
                                    <div>
                                        <span>
                                            <strong>{_get(props, "row.code", "")} </strong>
                                        </span>
                                    </div>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <th className="textRight defaultPaddingRight">Business Registration Number <sup className="text-danger">*</sup></th>
                            <td className={"defaultPaddingRight defaultMarginBottom"}>
                                <InputBoxWithState
                                    formGroupName={formKey}
                                    inputName={"br_number"}
                                    maxLength={30}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td>English</td>
                            <td>Sinhala</td>
                            <td>Tamil</td>
                        </tr>
                        <tr>
                            <th className="textRight defaultPaddingRight">VFT Company Name<sup className="text-danger">*</sup></th>
                            <td>
                                <InputBoxWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName={formKey}
                                    inputName={"name"}
                                    maxLength={200}
                                //labelText="VFT Company Name"
                                />
                            </td>
                            <td>
                                <InputBoxWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName={formKey}
                                    inputName={"name_si"}
                                    maxLength={200}
                                //labelText="VFT Company Name"
                                />
                            </td>
                            <td>
                                <InputBoxWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName={formKey}
                                    inputName={"name_ta"}
                                    maxLength={200}
                                //labelText="VFT Company Name"
                                />
                            </td>
                        </tr>

                        <tr>
                            <th className="textRight defaultPaddingRight">Address Line 1<sup className="text-danger">*</sup></th>
                            <td>
                                <InputBoxWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName={formKey}
                                    inputName={"line1"}
                                    maxLength={50}
                                //labelText="VFT Company Name"
                                />
                            </td>
                            <td>
                                <InputBoxWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName={formKey}
                                    inputName={"line1_si"}
                                    maxLength={50}
                                //labelText="VFT Company Name"
                                />
                            </td>
                            <td>
                                <InputBoxWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName={formKey}
                                    inputName={"line1_ta"}
                                    maxLength={50}
                                //labelText="VFT Company Name"
                                />
                            </td>
                        </tr>

                        <tr>
                            <th className="textRight defaultPaddingRight">Address Line 2</th>
                            <td>
                                <InputBoxWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName={formKey}
                                    inputName={"line2"}
                                    maxLength={50}
                                //labelText="VFT Company Name"
                                />
                            </td>
                            <td>
                                <InputBoxWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName={formKey}
                                    inputName={"line2_si"}
                                    maxLength={50}
                                //labelText="VFT Company Name"
                                />
                            </td>
                            <td>
                                <InputBoxWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName={formKey}
                                    inputName={"line2_ta"}
                                    maxLength={50}
                                //labelText="VFT Company Name"
                                />
                            </td>
                        </tr>

                        <tr>
                            <th className="textRight defaultPaddingRight">City<sup className="text-danger">*</sup></th>
                            <td>
                                <InputBoxWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName={formKey}
                                    inputName={"city"}
                                    maxLength={50}
                                //labelText="VFT Company Name"
                                />
                            </td>
                            <td>
                                <InputBoxWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName={formKey}
                                    inputName={"city_si"}
                                    maxLength={50}
                                //labelText="VFT Company Name"
                                />
                            </td>
                            <td>
                                <InputBoxWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName={formKey}
                                    inputName={"city_ta"}
                                    maxLength={50}
                                //labelText="VFT Company Name"
                                />
                            </td>
                        </tr>

                        <tr>
                            <th className="textRight defaultPaddingRight">Postal Code<sup className="text-danger">*</sup></th>
                            <td>
                                <NumberInputBoxWithState
                                    formGroupName={formKey}
                                    isAllowLeadingZeros={true}
                                    inputName={"postal_code"}
                                    isAllowLeadingZeros={true}
                                    isThousandSeparator={false}
                                    isAllowNegative={false}
                                    decimalScale={0}
                                    isAllowedFn={(value) => value.value.toString().length <= 6}
                                />
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                        </tr>

                        <tr>
                            <th className="textRight defaultPaddingRight">Contact Number 1<sup className="text-danger">*</sup></th>
                            <td>
                                <NumberInputBoxWithState
                                    formGroupName={formKey}
                                    isAllowLeadingZeros={true}
                                    inputName={"contact_number_1"}
                                    //isRequired={true}
                                    isThousandSeparator={false}
                                    isAllowLeadingZeros={true}
                                    isAllowNegative={false}
                                    decimalScale={0}
                                    isAllowedFn={(value) => value.value.toString().length <= 10}
                                />

                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                        </tr>

                        <tr>
                            <th className="textRight defaultPaddingRight">Contact Number 2</th>
                            <td>
                                <NumberInputBoxWithState
                                    formGroupName={formKey}
                                    isAllowLeadingZeros={true}
                                    inputName={"contact_number_2"}
                                    isAllowLeadingZeros={true}
                                    isThousandSeparator={false}
                                    isAllowNegative={false}
                                    decimalScale={0}
                                    isAllowedFn={(value) => value.value.toString().length <= 10}
                                />

                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                        </tr>

                        <tr>
                            <th className="textRight defaultPaddingRight">E-mail</th>
                            <td>
                                <InputBoxWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName={formKey}
                                    inputName={"email"}
                                    maxLength={50}
                                //labelText="VFT Company Name"
                                />
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                        </tr>

                        <tr>
                            <th className="textRight defaultPaddingRight">Fax</th>
                            <td>
                                <NumberInputBoxWithState
                                    formGroupName={formKey}
                                    isAllowLeadingZeros={true}
                                    inputName={"fax"}
                                    isAllowLeadingZeros={true}
                                    isThousandSeparator={false}
                                    isAllowNegative={false}
                                    decimalScale={0}
                                    isAllowedFn={(value) => value.value.toString().length <= 10}
                                />
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                        </tr>

                        <tr>
                            <th className="textRight defaultPaddingRight">Status</th>
                            <td>
                                <SelectBoxWithState
                                    formGroupName={formKey}
                                    inputName={"active"}
                                    keyName={"id"}
                                    valueName={"value"}
                                    emptySelectOptionTxt={'Please select Status'}
                                    dataList={[
                                        { id: "1", value: "Active" },
                                        { id: "0", value: "Inactive" }]}

                                />
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                        </tr>

                    </table>
                </div>
            </div>
           


        </FormWrapper>
    );
};


export { AddVFTCompany };