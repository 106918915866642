/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2021-03-01 16:42:06 
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2021-04-30 22:35:26
 */


import React, { Fragment, useContext } from 'react';
import { SubmitButton } from "../../../../ui-components/ui-elements/form";
import { getWebPortalNewsAPI } from "../../../../../config/apiUrl.config";
import { getDataByFormObject } from '../../../../../helpers/common-helpers/common.helpers';
import { apiResponseStoringType } from "../../../../../config/apiResponseKey";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { dateObjectToString } from '../../../../../helpers/common-helpers/dateTime.helpers';
import { UIContext } from "../../../../ui-components/context-providers/UIContext.provider";

const emptyFn = (...para) => undefined;
const CustomButton = (props) => {
    const [, uiAction] = useContext(UIContext);
    const close = () => {
        props.onClosePopupFn();
    }

    return (
        <Fragment>
            <SubmitButton
                btnText={props.uiType === "edit" ? "Update" : props.uiType === "remove" ? "Yes" : "Save"}
                formGroupName={props.formGroupName}
                startIcon={ props.uiType === "remove" ? 'fas fa-check': props.uiType === "create" ? 'far fa-save' : 'far fa-edit'}
                tooltip={props.uiType === "edit" ? "Update" : props.uiType === "remove" ? "" : "Create"}
                dataTableKey={props.dataTableKey}
                isValidate={props.uiType === "remove" ? false : true}
                validationObject={{
                    fileds: {
                        "code": "News Code",
                        "heading_sinhala": "News Heading in Sinhala",
                        "heading_tamil": "News Heading in Tamil",
                        "heading_english": "News Heading in English",
                        "content_sinhala": "News Content in Sinhala",
                        "content_tamil": "News Content in Tamil",
                        "content_english": "News Content in English",
                        "start_date": "Start Date",
                        "end_date": "End Date",
                        "status": "Status",
                    },
                    rules: {
                        "code": "required|max:12",
                        "heading_sinhala": "required|max:25",
                        "heading_tamil": "required|max:25",
                        "heading_english": "required|max:25",
                        "content_sinhala": "required|max:50",
                        "content_tamil": "required|max:50",
                        "content_english": "required|max:50",
                        "start_date": "required",
                        "end_date": "required",
                        "status": "required",
                    },
                }}
                mapValidationErrorObj={{
                    "code": "News Code already exists.",
                    "heading_english": "News Heading in English already exists.",
                    "heading_sinhala": "News Heading in Sinhala already exists.",
                    "heading_tamil": "News Heading in Tamil already exists."
                }}
                flashMessages={{
                    "success": {
                        status: true,
                        message: `News is successfully ${props.uiType === "edit" ? "updated." : props.uiType === "remove" ? "removed." : "saved."}`,
                        messageType: "success"
                    }
                }}
                callApiObject={{
                    isSetHeaders: (props.uiType === "create"  || props.uiType === "edit" ) ? true : undefined,
                    method: (props.uiType === "create" ? ("post") : props.uiType === "edit" ? ("put") : ("delete")),
                }}
                apiDataStoringObject={{
                    setLoader: true,
                    storingType: apiResponseStoringType.dateTableLinkedForm
                }}
                onGetAPIEndPointFn={(formState) => {
                    return {
                        url: (props.uiType === "create" ? (getWebPortalNewsAPI.url) : (`${getWebPortalNewsAPI.url}/` + formState.id)),
                        key: getWebPortalNewsAPI.key
                    }
                }}
                onChangeRequestBodyFn={({ ...fromObject }) => {
                    fromObject.id= props.uiType === "edit" ?  fromObject.id : undefined;

                    return getDataByFormObject({

                        ...fromObject,
                        //status: (parseInt(fromObject.status) === 1 ? (true) : (false)),
                        //dateObjectToString(fromObject.start_date, "HH:MM:II")
                        start_date: dateObjectToString(fromObject.start_date, "YY-mm-dd"),
                        end_date: dateObjectToString(fromObject.end_date, "YY-mm-dd"),
                        code: (props.uiType === "create"  || props.uiType === "edit" ) ? fromObject.code.trim() : undefined,
                        heading_sinhala: (props.uiType === "create"  || props.uiType === "edit" ) ? fromObject.heading_sinhala.trim() : undefined,
                        heading_tamil: (props.uiType === "create"  || props.uiType === "edit" ) ? fromObject.heading_tamil.trim() : undefined,
                        heading_english: (props.uiType === "create"  || props.uiType === "edit" ) ? fromObject.heading_english.trim() : undefined,
                    })
                }}
                onResponseCallBackFn={(response, error) => {

                    if (response.length === undefined && error === undefined && response.data.errors === undefined) {
                        if(props.uiType==="remove"){
                        uiAction.setFlashMessage({
                            status: true,
                            message: "News is successfully deleted",
                            messageType: "success"
                        });
                        }
                        props.onClosePopupFn();
                    }

                }}

            />
        </Fragment>
    );
}
export {
    CustomButton
}