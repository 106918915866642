/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-03-21 09:57:06 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-16 13:26:52
 */

import React,{createContext,useReducer,useState} from "react";

import {uiAction} from "../ui-components-helpers/uiContext.helpers";
import {_findindex} from "../../../helpers/common-helpers/lodash.wrappers";
import {useScreenType,screenSizeTypes} from "../../hooks/common-hooks/useScreenStatus.hook";
import {
    setToggleSideMenuKey, setPageLoaderKey, setRouteKey,
    setFlashMessageKey, removeFlashMessageKey, setSideMenuStatusKey,
    setFixedMenuKey, setToggleSideMenuStatusValueKey,
    setFullScreenKey, setSocketConnection, setJoinRoom,
    setNotificationCount, incrementNotificationCount, decrementNotificationCount
} from "../../../config/actionKeys.config";
import {setScreenSize} from "../../../helpers/common-helpers/common.helpers";


const initialState={
    toggleSideMenu:true,
    fixedMenu:true,
    fullScreen:false,
    setPageLoader:false,
    currentRouteKey:"",
    flashMessageList:[],
    socketConnection:null,
    isJoinedRoom:false,
    notificationCount:null
}


// const getInitState=()=>{
//     const [type]=useScreenType();
//     return {
//         ...initialState,
//         toggleSideMenu:type!==screenSizeTypes.largeDevice.key?false:true
//     }
// }



const UIContext=createContext({});

const uiReducer=(state, action)=>{  
    switch (action.type) {
        case setToggleSideMenuKey:
            return { 
                ...state, 
                toggleSideMenu: !state.toggleSideMenu 
            };
        case setToggleSideMenuStatusValueKey:
            return { 
                ...state, 
                toggleSideMenu: action.playload
            };    
        case setFixedMenuKey:
            return { 
                ...state, 
                fixedMenu: !state?.fixedMenu 
            };
        case setFullScreenKey:

            setScreenSize(!state.fullScreen);
            return { 
                ...state, 
                fullScreen: !state.fullScreen 
            };
        case setSideMenuStatusKey:
            return { 
                ...state, 
                toggleSideMenu: action.playload
            };    
        case setPageLoaderKey:
            return { 
                ...state,
                setPageLoader: action.playload 
            };
        case setRouteKey:
            return{
                ...state,
                currentRouteKey:action.playload
            };      
        case setFlashMessageKey:

            let currentList=state?.flashMessageList||[];
            
            if((currentList).length >= 3){
                currentList=currentList.slice(1,currentList.length);
            }

            return {
                ...state,
                flashMessageList:[...currentList,action.playload]
            };
             
        case removeFlashMessageKey:

            const key=_findindex(state?.flashMessageList,["key",action.key]);

            if(key!==-1){
               return {
                ...state,
                flashMessageList:(state?.flashMessageList||[]).slice(1,(state?.flashMessageList||[]).length)
               };
            }             
            break;
        case setSocketConnection:
            return {
                ...state,
                socketConnection: action.playload
            };
        case setJoinRoom:
            return {
                ...state,
                isJoinedRoom: action.playload
            };
        case setNotificationCount:
            return {
                ...state,
                notificationCount: action.playload
            };
        case incrementNotificationCount:
            return {
                ...state,
                notificationCount: state?.notificationCount + 1
            };
        case decrementNotificationCount:
            return {
                ...state,
                notificationCount: state?.notificationCount - 1
            };

        default:
            return state;
    }
}

const UIContextProvider=({children})=>{
    const [type]=useScreenType();
    const [state,dispatch]=useReducer(uiReducer,{
        ...initialState,
        toggleSideMenu:type!==screenSizeTypes.largeDevice.key?false:true
    });
    const dispatchFuntion=uiAction(dispatch,state);
    
    
    return(
        <UIContext.Provider value={[state,dispatchFuntion]}>
            {children}
        </UIContext.Provider>
    )
}

export {
    UIContext,
    UIContextProvider
}