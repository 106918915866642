import React, { useContext } from "react";
import { FormWrapper, InputButton } from "../../../../ui-components/ui-elements/form";
import { actionBtnTypes, DataTable } from "../../../../ui-components/ui-elements/table/DataTable";
import { AddEditCertifyingOfficer } from "./AddEditCertifyingOfficer";
import { ViewCertifyingOfficerPopup } from "./ViewCertifyingOfficer";
import { CustomButtonCertifying } from './Custom-button-certifying-officers'
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { vftCertificateOfficersListAPI, vftListAPI, userPasswordResetAPI, resetDeviceAPI } from "../../../../../config/apiUrl.config";
import { UIContext } from "../../../../ui-components/context-providers/UIContext.provider";
import { SubmitButton } from "../../../../ui-components/ui-elements/form";
import { getDataByFormObject } from "../../../../../helpers/common-helpers/common.helpers";



const DeleteFineConfigurations = () => {
    return (
        <div className="fullWidthDiv">
            <p>Are you sure you want to Reset the Password?</p>
        </div>
    )
}

const ResetPassword = (props) => {
    const [, uiAction] = useContext(UIContext);

    return (
        <SubmitButton
            btnText="Yes"
            elementStyle="btnWrapper"
            startIcon={"far fa-save"}
            //formGroupName={`${fineConfigurationsListAPI.key}_edit`}
            dataTableKey={vftCertificateOfficersListAPI.key}
            isValidate={false}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Reset password is successful.",
                    messageType: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "put",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: "DATA_TABLE_LINKED_FORM",
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    "url": `${userPasswordResetAPI.url}/${_get(props, "row.user.id", null)}`,
                    "key": userPasswordResetAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                return getDataByFormObject(formObject);
            }}
            onResponseCallBackFn={(error) => {
                if (!error) {
                    uiAction.setFlashMessage({
                        status: true,
                        message: "Reset password is successful",
                        messageType: "success"
                    });

                    props.onClosePopupFn(false);
                }
            }}
        />
    )
}


const DeleteFineConfigurations2 = () => {
    return (
        <div className="fullWidthDiv">
            <p>Are you sure you want to Reset the Device?</p>
        </div>
    )
}

const ResetDevice = (props) => {
    const [, uiAction] = useContext(UIContext);

    return (
        <SubmitButton
            btnText="Yes"
            elementStyle="btnWrapper"
            startIcon={"far fa-save"}
            //formGroupName={`${fineConfigurationsListAPI.key}_edit`}
            dataTableKey={vftCertificateOfficersListAPI.key}
            isValidate={false}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Reset device is successful.",
                    messageType: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "put",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: "DATA_TABLE_LINKED_FORM",
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    "url": `${resetDeviceAPI.url}/${_get(props, "row.user.id", null)}`,
                    "key": resetDeviceAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                return getDataByFormObject(formObject);
            }}
            onResponseCallBackFn={(error) => {
                if (!error) {
                    uiAction.setFlashMessage({
                        status: true,
                        message: "Reset device is successful",
                        messageType: "success"
                    });

                    props.onClosePopupFn(false);
                }
            }}
        />
    )
}
const CertifyingOfficersUI = ({
    formKey = "CertifyingOfficersKey"
}) => {

    const [formState, formAction] = useContext(FormContext);
    const company_id = _get(formState[`${vftListAPI.key}_edit`], "id", "");

    return (
        <div className="row">

            <FormWrapper setGroupName={formKey} >

                {/* <AddEditCertifyingOfficer actionType="add" formKey={formKey} /> */}

                <DataTable
                    apiUrl={`${vftListAPI.url}/${company_id}/users?sort_by=full_name|asc`}
                    dataTableKey={vftCertificateOfficersListAPI.key}
                    tableHeaderList={[
                        { displayName: "Full Name", key: "full_name", isSort: false },
                        { displayName: "Contact No.", key: "user.contact", isSort: false, onPrintFn: ({ user }) => user.contact.find((type) => type.contact_type === "MOB") !== undefined ? (user.contact.find((type) => type.contact_type === "MOB").contact) : ("-"), default: "-" },
                        { displayName: "Email", key: "user.contact", isSort: false, onPrintFn: ({ user }) => user.contact.find((type) => type.contact_type === "EMA") !== undefined ? (user.contact.find((type) => type.contact_type === "EMA").contact) : ("-"), default: "-" },
                        {
                            displayName: "Device Allocated", key: "device_id", isSort: false, onPrintFn: (rawData) => {
                                return (rawData.device_id) ? "Yes" : "No";
                            }
                        },
                        { displayName: "Status", key: "user.active", isSort: false, onPrintFn: ({ user }) => user.active === 1 ? "Active" : "Inactive" }
                    ]}

                    isSetTableFooter={true}
                    isSetTableHeader={true}
                    tableName={"Certifying Officers"}
                    actionTypeList={[
                        {
                            actionType: actionBtnTypes.create,
                            actionBtn: { tooltip: 'Create' },
                            modelContentElement: AddEditCertifyingOfficer,
                            dialogPopupProps: {
                                dialogTitle: "Add Certifying Officer",
                                isFullScreen: false,
                                isSaveButton: false,
                                isCloseButton: true,
                                isCustomButton: true,
                                isConfirmationPopup: true,
                                customButtonComponent: CustomButtonCertifying,
                                customButtonComponentProps: { btnText: "Save", uiType: "CREATE", dataTableKey: vftCertificateOfficersListAPI.key, formKey: vftCertificateOfficersListAPI.key + "_create" },
                                closeButton: {
                                    btnText: "Cancel",
                                    startIcon: "far fa-times-circle",
                                },
                            },
                            parentToModelProps: {
                                requiredId: company_id,
                                formKey: vftCertificateOfficersListAPI.key + "_create",
                                dataTableKey: vftCertificateOfficersListAPI.key,

                            },
                        },
                        {
                            actionType: actionBtnTypes.edit,
                            actionBtn: {
                                tooltip: 'Reset Device',
                                icon: "mdi mdi-phone-ring"
                            },
                            modelContentElement: DeleteFineConfigurations2,

                            dialogPopupProps: {
                                isFullScreen: false,
                                dialogTitle: "Reset Device",
                                closeButton: {
                                    btnText: "No",
                                    startIcon: "far fa-times-circle",
                                },
                                isCustomButton: true,
                                customButtonComponent: ResetDevice
                            }
                        },
                        {
                            actionType: actionBtnTypes.edit,
                            actionBtn: {
                                tooltip: 'Reset Password',
                                icon: "mdi mdi-account-key"
                            },
                            modelContentElement: DeleteFineConfigurations,

                            dialogPopupProps: {
                                isFullScreen: false,
                                dialogTitle: "Reset password",
                                closeButton: {
                                    btnText: "No",
                                    startIcon: "far fa-times-circle",
                                },
                                isCustomButton: true,
                                customButtonComponent: ResetPassword
                            }
                        },
                        {
                            actionType: actionBtnTypes.edit,
                            actionBtn: { tooltip: 'Edit' },
                            modelContentElement: AddEditCertifyingOfficer,
                            dialogPopupProps: {
                                dialogTitle: "Edit Certifying Officer",
                                isFullScreen: false,
                                isSaveButton: false,
                                isCloseButton: true,
                                isCustomButton: true,
                                isConfirmationPopup: true,
                                customButtonComponent: CustomButtonCertifying,
                                customButtonComponentProps: {
                                    btnText: "Update",
                                    uiType: "EDIT",
                                    dataTableKey: vftCertificateOfficersListAPI.key,
                                    formKey: vftCertificateOfficersListAPI.key + "_edit"
                                },
                                closeButton: {
                                    btnText: "Cancel",
                                    startIcon: "far fa-times-circle",
                                },
                            },
                            parentToModelProps: {
                                requiredId: company_id,
                                formKey: vftCertificateOfficersListAPI.key + "_edit",
                                dataTableKey: vftCertificateOfficersListAPI.key,

                            },
                        },
                        {
                            actionType: actionBtnTypes.view,
                            actionBtn: {  tooltip: "View" },
                            modelContentElement: ViewCertifyingOfficerPopup,
                            dialogPopupProps: {
                                isFullScreen: false,
                                dialogTitle: "View Certifying Officer",
                                closeButton: {
                                    btnText: "Close",
                                    startIcon: "far fa-times-circle",
                                },
                            }

                        },
                    ]}
                />
            </FormWrapper>
        </div>


    )
}

export { CertifyingOfficersUI }