import React, { Fragment } from 'react';
import { permissions } from '../../../config/permission.config';
import authorized from '../../hoc-middlewares/Authorized';
import { Navigate } from '../../ui-components/ui-elements/common/Navigate';
import ManageVehicleClass from './pages/ManageVehicleClass';

const VehicleClassRoutes = () => {
    return (
        <Fragment>
            <Navigate exact={true} path="/vehicleClass" routeKey={"vehicleClass"} component={authorized(ManageVehicleClass)} routePermissions={permissions.VEHICLE_CLASS_MANAGE.permissions}/>
        </Fragment>
    );
};

export default VehicleClassRoutes;