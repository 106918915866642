import React, { Fragment, useContext, useEffect } from "react";

import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { SubmitButton } from "../../../ui-components/ui-elements/form";
import { actionBtnTypes, DataTable } from "../../../ui-components/ui-elements/table/DataTable";
import { SearchElement } from "./includes/VFTCompanyElements";
import { ViewVFTCompany } from './includes/ViewVFTCompany';
import { AddVFTCompany } from './includes/AddVFTCompany';
import { vftListAPI } from "../../../../config/apiUrl.config";
import { getDataByFormObject, isEmptyValue } from "../../../../helpers/common-helpers/common.helpers";
import { apiResponseStoringType } from "../../../../config/apiResponseKey";
import { AuthContext } from "../../core/context-providers/AuthContext.provider";
import { permissions } from "../../../../config/permission.config";
import { useLocation } from "react-router-dom";

const CustomButtonAdd = (props) => {
    const [authState] = useContext(AuthContext);

    return (
        <Fragment>
            <SubmitButton
                btnText={props.uiType === "EDIT" ? "Update" : "Save"}
                startIcon={props.uiType === "EDIT" ? "far fa-edit" : "far fa-save"}
                formGroupName={props.formGroupName}
                dataTableKey={props.dataTableKey}
                isValidate={true}
                flashMessages={{
                    "success": {
                        status: true,
                        message: (props.uiType) === "EDIT" ? "VFT Company Info is successfully updated." : "VFT Company is successfully saved.",
                        messageType: "success"
                    }
                }}
                mapValidationErrorObj={{
                    name: "VFT Company Name in English already exists",
                    name_si: "VFT Company Name in Sinhala already exists",
                    name_ta: "VFT Company Name in Tamil already exists",
                    code: "VFT Company Code already exists",
                    br_number: "Business registration number already exists",
                    email: "Email already exists",
                }}
                validationObject={{
                    fileds: {
                        br_number: "Business registration number",
                        code: "VFT Company Code",
                        line1: "Address line 1 in english",
                        line1_si: "Address Line 1 in sinhala",
                        line1_ta: "Address Line 1 in tamil",
                        line2: "Address Line 2",
                        line2_si: "Address Line 2 in sinhala",
                        line2_ta: "Address Line 2 in tamil",
                        city: "City in english",
                        city_si: "City in sinhala",
                        city_ta: "City in tamil",
                        postal_code: "Postal code",
                        contact_number_1: "Contact number",
                        contact_number_2: "Contact number",
                        email: "Email Address",
                        fax: "Fax Number",
                        name: "VFT Company Name in English",
                        name_si: "VFT Company Name in Sinhala",
                        name_ta: "VFT Company Name in Tamil",

                    },
                    rules: {
                        br_number: "required|max:30",
                        code: "required|max:20",
                        line1: "required",
                        line1_si: "required|max:50",
                        line1_ta: "required|max:50",
                        line2: "max:50",
                        line2_si: "max:50",
                        line2_ta: "max:50",
                        city: "required|max:50",
                        city_si: "required|max:50",
                        city_ta: "required|max:50",
                        postal_code: "max:6|required",
                        contact_number_1: "required|numeric|max:10|min:9",
                        contact_number_2: "numeric|max:10|min:9",
                        email: "max:50|email",
                        fax: "max:10|numeric|min:9",
                        name: "required|max:200",
                        name_si: "required|max:200",
                        name_ta: "required|max:200",

                    },
                    message: {},
                }}

                callApiObject={{
                    isSetHeaders: true,
                    method: (props.uiType === "EDIT" ? ("put") : ("post")),
                }}
                apiDataStoringObject={{
                    setLoader: true,
                    storingType: props.uiType === "EDIT" ? apiResponseStoringType.responseLinkToFrom : apiResponseStoringType.dateTableLinkedForm
                }}
                onGetAPIEndPointFn={(formState) => {
                    return {
                        url: (props.uiType === "EDIT" ? (`${vftListAPI.url}/` + formState.id) : (vftListAPI.url)),
                        key: vftListAPI.key
                    }
                }}
                onChangeRequestBodyFn={(fromObject) => {
                    let contact_number_new;
                    if (fromObject.contact_number_2 === "") {
                        contact_number_new = null
                    } else {
                        contact_number_new = fromObject.contact_number_2
                    }

                    let fax_new;
                    if (fromObject.fax === "") {
                        fax_new = null
                    } else {
                        fax_new = fromObject.fax
                    }

                    return getDataByFormObject({
                        ...fromObject,
                        // province_code: "WES",
                        // pdmt_id: "1eae2e82-92ea-11eb-a8b3-0242ac130003",
                        province_code: authState.authUser.province_code,
                        pdmt_id: authState.authUser.pdmt_id,
                        line1: fromObject.line1,
                        line1_si: fromObject.line1_si,
                        line1_ta: fromObject.line1_ta,
                        line2: fromObject.line2,
                        line2_si: fromObject.line2_si,
                        line2_ta: fromObject.line2_ta,
                        city: fromObject.city,
                        city_si: fromObject.city_si,
                        city_ta: fromObject.city_ta,
                        postal_code: fromObject.postal_code,
                        contact_number_1: fromObject.contact_number_1,
                        contact_number_2: contact_number_new,
                        email: fromObject.email,
                        fax: fax_new
                    })
                }}
                onResponseCallBackFn={(response, error) => {
                    if (response.length === undefined && error === undefined && response.data.errors === undefined && props.uiType !== "EDIT") {
                        props.onClosePopupFn();
                    }
                }}
            />
        </Fragment>
    )
}
const VFTCompany = () => {
    const [authStatus] = useContext(AuthContext);
    const provinceCode = _get(authStatus, "authUser.province_code", "");
    const filter = isEmptyValue(provinceCode) ? "?sort_by=name|asc" : `?sort_by=name|asc&province_code=${provinceCode}`;

    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]); 
    return (
        <DashboardTemplate
            pageTitle="Manage VFT Company"
            breadCrumbList={[
                { urlTo: "/", displayName: "Home" },
                { urlTo: "", displayName: "Support Organisations" },
                { urlTo: "", displayName: "Manage VFT Company" },
            ]}
        >
            <DataTable
                tableName={"VFT Companies"}
                isSetSearchFrom={true}
                apiUrl={`${vftListAPI.url}${filter}`}
                dataTableKey={vftListAPI.key}
                searchFromComponent={
                    <SearchElement dataTableKey={vftListAPI.key} />
                }
                defaultSearchFormObject={{
                    code: "",
                    name: "",
                    active: null
                }}
                isSetTableFooter={true}
                tableHeaderList={[
                    { displayName: "VFT Company Code", key: "code", isSort: false },
                    { displayName: "VFT Company Name", key: "service.name", isSort: false },
                    { displayName: "Status", key: "service.active", isSort: false, onPrintFn: ({ service }) => service.active === 1 ? "Active" : "Inactive" },
                ]}

                actionTypeList={[
                    {
                        actionType: actionBtnTypes.create,
                        actionBtn: {
                            "permissions": permissions.VFT_COMPANY_ADD.permissions
                        },
                        modelContentElement: AddVFTCompany,
                        dialogPopupProps: {
                            isFullScreen: true,
                            dialogTitle: "Add VFT Company",
                            closeButton: {
                                btnText: "Cancel",
                                startIcon: "far fa-times-circle",
                            },
                            isCustomButton: true,
                            isConfirmationPopup: true,
                            customButtonComponent: CustomButtonAdd,
                            customButtonComponentProps: {
                                uiType: "CREATE",
                                dataTableKey: vftListAPI.key,
                            }
                        },
                        parentToModelProps: {
                            uiType: "CREATE",
                            formKey: vftListAPI.key + "_create",
                        },
                    },
                    {
                        actionType: actionBtnTypes.edit,
                        actionBtn: {
                            tooltip: 'Configure',
                            icon: "mdi mdi-settings-outline",
                            "permissions": permissions.VFT_COMPANY_EDIT.permissions
                        },
                        //actionBtn: { icon: "far fa-edit", tooltip: "edit" },
                        modelContentElement: ViewVFTCompany,
                        dialogPopupProps: {
                            isFullScreen: true,
                            isCustomButton: false,
                            isConfirmationPopup: false,
                            dialogTitle: 'Configure VFT Company',
                            closeButton: {
                                btnText: "Close",
                                startIcon: "far fa-times-circle",
                            },
                            customButtonComponent: CustomButtonAdd,
                            customButtonComponentProps: {
                                btnText: "Update",
                                uiType: "EDIT",
                                dataTableKey: vftListAPI.key,
                            }
                        },
                        parentToModelProps: {
                            uiType: "EDIT",
                            formKey: vftListAPI.key + "_edit",
                        },
                    },
                ]}
            />
        </DashboardTemplate>
    );
};

export { VFTCompany, CustomButtonAdd };