import { ContactTypeCode } from "../../config/enum.config";
import { _findindex } from "./lodash.wrappers";
/**
 * @description Convert contacts array into object & get contact
 * @param (Array) array 
 * @param (string) contact_type_code 
 */

const contactsArrayToObject = (array, isFullObject=true, contact_type_code="") => {
    const phoneContact = _findindex(array, (e)=> e.contact_type === ContactTypeCode.PHONE);
    const mobileContact = _findindex(array, (e)=> e.contact_type === ContactTypeCode.MOBILE);
    const faxContact = _findindex(array, (e)=> e.contact_type === ContactTypeCode.FAX);
    const emailContact = _findindex(array, (e)=> e.contact_type === ContactTypeCode.EMAIL);

    if(contact_type_code === ""){
        return {
            phone: phoneContact !== -1 ? isFullObject ? array[phoneContact]: array[phoneContact]['contact'] : isFullObject ? {} : "",
            mobile: mobileContact !== -1 ? isFullObject ? array[mobileContact]: array[mobileContact]['contact'] : isFullObject ? {} : "",
            fax: faxContact !== -1 ? isFullObject ? array[faxContact]: array[faxContact]['contact'] : isFullObject ? {} : "",
            email: emailContact !== -1 ? isFullObject ? array[emailContact]: array[emailContact]['contact'] : isFullObject ? {} : "",
        }
    }else{
        const index =  _findindex(array, (e)=> e.contact_type === contact_type_code);
        return index !== -1 ? isFullObject ? array[index]: array[index]['contact'] : isFullObject ? {} : "";
    }

};

export { contactsArrayToObject }