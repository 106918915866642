/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-07-14 10:18:15
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-30 11:37:03
 */

import React, { useContext } from "react";

import {
  AuditDetail,
  UICard,
} from "../../../../ui-components/ui-elements/common/BaseElements";

import { templateTypes } from "../../../../../config/template.config";
import {
  FormWrapper,
  SelectBoxWithState,
  InputBoxWithState,
} from "../../../../ui-components/ui-elements/form";
import { DataTable } from "../../../../ui-components/ui-elements/table/DataTable";
import {
  _get,
  _getInt,
} from "../../../../../helpers/common-helpers/lodash.wrappers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import {
  authoritiesListAPI,
  unusedCounterSerialNumbersListApi,
  usedCounterSerialNumbersListApi,
} from "../../../../../config/apiUrl.config";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { isEmptyValue } from "../../../../../helpers/common-helpers/common.helpers";

const SearchElement = ({ dataTableKey = "" }) => {
  const formKey = `${dataTableKey}_search`;
  const [authStatus, authAction] = useContext(AuthContext);
  const service_id = _get(authStatus, "authUser.service_id", "");
  const pdmt_id = _get(authStatus, "authUser.pdmt_id", "");

  return (
    <FormWrapper setGroupName={formKey}>
      <div className="row">
        <div className="col-md-3 col-sm-12 floatLeft">
          <SelectBoxWithState
            formGroupName={formKey}
            emptySelectOptionTxt={"All"}
            apiUrl={`${authoritiesListAPI.url}?pdmt_id=${pdmt_id}`}
            apiStateKey={authoritiesListAPI.key}
            keyName={"id"}
            valueName={"service.name"}
            inputName={"authority"}
            labelText="Authority"
          />
        </div>
        <div className="col-md-3 col-sm-12 floatLeft">
          <SelectBoxWithState
            formGroupName={formKey}
            emptySelectOptionTxt={"All"}
            isEnableAllOption={true}
            dataList={[
              {
                id: "true",
                value: "Active",
              },
              {
                id: "false",
                value: "Inactive",
              },
            ]}
            inputName={"active"}
            labelText="Status"
          />
        </div>
        <div className="col-md-3 col-sm-12 floatLeft">
          <InputBoxWithState
            formGroupName={formKey}
            inputName={"name"}
            labelText="Counter Name"
            maxLength={50}
            inputPlaceholder={"Counter Name"}
          />
        </div>
      </div>
    </FormWrapper>
  );
};

const ViewCounter = (props) => {
  return (
    <div className="row">
      <div className="col-md-12 col-sm-12 defaultHalfMarginBottom">
        <UICard>
          <div className="row">
            <div className="col-md-6 col-sm-6 floatLeft">
              <p>
                <b>Counter Name:</b> {_get(props, "row.name", "")}
              </p>
            </div>

            <div className="col-md-6 col-sm-6 floatLeft">
              <p>
                <b>Counter Code:</b> {_get(props, "row.code", "")}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 floatLeft">
              <p>
                <b>Authority:</b> {_get(props, "row.authority_name", "")}
              </p>
            </div>

            <div className="col-md-6 col-sm-6 floatLeft">
              <p>
                <b>Status:</b>{" "}
                {parseInt(_get(props, `row.active`, "")) === 1
                  ? "Active"
                  : "Inactive"}
              </p>
            </div>
          </div>
        </UICard>
      </div>
      <div className="col-md-12 floatLeft defaultHalfMarginBottom">
        <DataTable
          tableName={"Assigned Serial Numbers"}
          apiUrl={`${unusedCounterSerialNumbersListApi.url}/${props.row.id}/serial-numbers?used=false&sort_by=created_at|asc`}
          dataTableKey={unusedCounterSerialNumbersListApi.key}
          isSetAction={false}
          tableHeaderList={[
            {
              displayName: "Serial No. From",
              key: "auth_serial_serial_number_from",
              isSort: false,
            },
            {
              displayName: "Serial No. To",
              key: "auth_serial_serial_number_to",
              isSort: false,
            },
            {
              displayName: "Serial No. Count ",
              key: "serial_count",
              isSort: false,
              onPrintFn: ({
                auth_serial_serial_number_from,
                auth_serial_serial_number_to,
              }) =>
                parseInt(auth_serial_serial_number_to) -
                parseInt(auth_serial_serial_number_from) +
                +1,
            },
            {
              displayName: "Discarded Count",
              key: "discarded_count",
              isSort: false,
            },
            {
              displayName: "Serial No. Balance",
              key: "serial_balance",
              isSort: false,
              onPrintFn: ({
                auth_serial_serial_number_from,
                auth_serial_serial_number_to,
                discarded_count,
                used_count,
              }) =>
              isEmptyValue(auth_serial_serial_number_from) ? '-':
              parseInt(auth_serial_serial_number_to) -
              parseInt(auth_serial_serial_number_from) -
              (parseInt(discarded_count) + parseInt(used_count)) +
              +1,
            },
          ]}
        />
      </div>
      <div className="col-md-12 floatLeft defaultHalfMarginBottom">
        <DataTable
          apiUrl={`${usedCounterSerialNumbersListApi.url}/${props.row.id}/serial-numbers?used=true&sort_by=created_at|desc`}
          dataTableKey={usedCounterSerialNumbersListApi.key}
          isSetAction={false}
          tableName={"Serial Number History"}
          tableHeaderList={[
            {
              displayName: "Serial No. From",
              key: "auth_serial_serial_number_from",
              isSort: false,
            },
            {
              displayName: "Serial No. To",
              key: "auth_serial_serial_number_to",
              isSort: false,
            },
            {
              displayName: "Serial No. Count ",
              key: "serial_count",
              isSort: false,
              onPrintFn: ({
                auth_serial_serial_number_from,
                auth_serial_serial_number_to,
              }) =>
                parseInt(auth_serial_serial_number_to) -
                parseInt(auth_serial_serial_number_from) +
                +1,
            },
            {
              displayName: "Discarded Count",
              key: "discarded_count",
              isSort: false,
            },
            {
              displayName: "Created By",
              key: "created_by",
              isSort: false,
              onPrintFn: ({ created_by }) =>
              !isEmptyValue(_get(created_by, "user_name", null)) ? `${_get(created_by, "user_name", null)} (${_get(created_by, "user_role", null)})`: '-',
            },
            {
              displayName: "Created Location",
              key: "created_location",
              isSort: false,
              onPrintFn: ({ created_location }) =>
                _get(created_location, "location", "-"),
            },
            {
              displayName: "Created At",
              key: "created_at",
              isSort: false,
              onPrintFn: ({ created_at }) =>
                dateObjectToString(created_at, "dd/mm/YY HH:MM"),
            },
          ]}
        />
      </div>
      <div className="col-md-12 floatLeft">
        <UICard>
          <AuditDetail
            createdById={_get(props, `row.created_by`, "")}
            createdLocationId={_get(props, `row.created_location`, "")}
            createdAt={dateObjectToString(
              _get(props, `row.created_at`, ""),
              "dd/mm/YY HH:MM"
            )}
            createdRoleId={_get(props, `row.created_role_id`, '')}
            updatedById={_get(props, `row.updated_by`, "")}
            updatedLocationId={_get(props, `row.updated_location`, "")}
            updatedAt={dateObjectToString(
              _get(props, `row.updated_at`, ""),
              "dd/mm/YY HH:MM"
            )}
            updatedRoleId={_get(props, `row.updated_role_id`, '')}
            labelCol={4}
          />
        </UICard>
      </div>
    </div>
  );
};

export { ViewCounter, SearchElement };
