import React, { Fragment } from 'react';
import { permissions } from '../../../config/permission.config';
import authorized from '../../hoc-middlewares/Authorized';
import { Navigate } from '../../ui-components/ui-elements/common/Navigate';
import ProvinceOffice from './pages/ProvinceOffice';

const ProvinceOfficeRoutes = () => {
    return (
        <Fragment>
            <Navigate
                exact
                path="/province-office"
                routeKey="province-office"
                component={authorized(ProvinceOffice)}
                routePermissions={permissions.PDMT_MANAGE.permissions}
            />
        </Fragment>
    );
}

export default ProvinceOfficeRoutes;