import React, { Fragment } from "react";

import { templateTypes } from "../../../../../config/template.config";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import {
  FormWrapper,
  SelectBoxWithState,
  InputBoxWithState,
} from "../../../../ui-components/ui-elements/form";



const Form = (props) => {
  const formKey = `${props.fromParent.formKey}`;

  return (
    <Fragment>
      <FormWrapper
        setGroupName={formKey}
        formGroupLinkWith={formKey}
        setFormObject={
          // _get(props,'fromParent.uiType',"")==='edit'?
          {
            id: _get(props, "row.id", ""),
            code: _get(props, "row.code", ""),
            name: _get(props, "row.service.name", ""),
            active: _get(props, "row.service.active", 1),
          }
        }
        onDestroyUnsetFormObject={true}
      >
        <InputBoxWithState
          formGroupName={formKey}
          inputName={"code"}
          labelText="Bank Code"
          isRequired={true}
          inputError={"Bank Code is required"}
          maxLength={4}
        />
        <InputBoxWithState
          formGroupName={formKey}
          inputName={"name"}
          labelText="Bank Name"
          isRequired={true}
          inputError={"Bank Name is required"}
          maxLength={100}
        />
        <SelectBoxWithState
          uiType={templateTypes.bootstrap}
          formGroupName={formKey}
          dataList={[
            {
              id: 1,
              value: "Active",
            },
            {
              id: 0,
              value: "Inactive",
            },
          ]}
          inputName={"active"}
          labelText="Status"
          emptySelectOptionTxt={null}
        />
      </FormWrapper>
    </Fragment>
  );
};

export { Form };
