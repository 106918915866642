import React, { Fragment, useContext } from "react";
import {
    SubmitButton,
} from "../../../../ui-components/ui-elements/form";
import { insuranceCompany } from "../../../../../config/apiUrl.config"
import { UIContext } from "../../../../ui-components/context-providers/UIContext.provider";
import { getDataByFormObject } from "../../../../../helpers/common-helpers/common.helpers";
import { apiResponseStoringType } from "../../../../../config/apiResponseKey";

const emptyFn = (...para) => undefined;

const CustomButton = ({
    row = {},
    onClosePopupFn = emptyFn,
    formGroupName = "",
    btnText = "",
    uiType = ""
}) => {
    const [uiAction] = useContext(UIContext);
    const close = () => {
        onClosePopupFn();
    }

    return (
        <Fragment>
            <SubmitButton
                btnText={uiType === "Add" ? "Save" : "Update"}
                startIcon={
                    uiType === "Add"
                        ? "far fa-save"
                        : "far fa-edit"
                }
                elementStyle="btnWrapper"
                formGroupName={(uiType === "Add" ? (`${insuranceCompany.key}_create`) : ((`${insuranceCompany.key}_edit`)))}
                isValidate={true}
                flashMessages={{
                    "success": {
                        status: true,
                        message: (uiType === "Add") ? "Insurance Company is successfully saved." : "Insurance Company is successfully updated.",
                        messageType: "success"
                    }
                }}
                mapValidationErrorObj={{
                  code: "The Insurance Company Code already exists",
                  email: "The Email must be a valid Email address.",
                }}
                validationObject={{
                    fileds: {
                        br_number: "Business Registration Number",
                        code: "Insurance Company Code",
                        name: "Insurance Company Name in English",
                        name_si: "Insurance Company Name in Sinhala",
                        name_ta: "Insurance Company Name in Tamil",
                        contact_number: "Contact Number",
                        email: "E-mail"
                    },
                    rules: {
                        br_number: "required|max:30",
                        code: "required|max:12",
                        name: "required|max:100",
                        name_si: "required|max:100",
                        name_ta: "required|max:100",
                        contact_number: "min:9|max:10",
                        email: "max:50",
                    }
                }}
                dataTableKey={insuranceCompany.key}
                callApiObject={{
                    isSetHeaders: true,
                    multipart: false,
                    method: (uiType === "Add" ? ("post") : ("put")),
                    onUpload: false,
                }}
                apiDataStoringObject={{
                    setLoader: true,
                    storingType: apiResponseStoringType.responseLinkToFrom
                }}
                onGetAPIEndPointFn={(formState) => {
                    return {
                        url: (uiType === "Add" ? (insuranceCompany.url) : (`${insuranceCompany.url}/` + formState.id)),
                        key: insuranceCompany.key
                    }
                }}

                onChangeRequestBodyFn={(fromObject) => {
                    return getDataByFormObject({
                        ...fromObject,
                        contacts: {
                            contact_number: fromObject.contact_number,
                            email: fromObject.email,
                        }
                    })
                }}
                onResponseCallBackFn={(response, error) => {
                    if (response.length === undefined && error === undefined && response.data.errors === undefined) {
                        close();
                    }
                }}
            />
        </Fragment>
    )
}
export {
    CustomButton
}
