import React from "react";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { FormWrapper } from "../../../../ui-components/ui-elements/form";

const RemoveFrom = (props) => {
    const formKey = `${props.fromParent.formKey}`;


    return (
        <FormWrapper
            setGroupName={formKey}
            setFormObject={{
                id: _get(props, "row.id", ""),
            }}
            onDestroyUnsetFormObject={true}
        >
            <div className="removeContent">
                <p>Are you sure you want to remove? </p>
                <br />
                <br />
            </div>
        </FormWrapper>
    );
};

export { RemoveFrom }