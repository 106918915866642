/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-07-14 10:18:15
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-30 11:37:17
 */

import React from "react";
import {
  AuditDetail,
  UICard,
  ViewDetailBlock,
} from "../../../../ui-components/ui-elements/common/BaseElements";
import { FormWrapper } from "../../../../ui-components/ui-elements/form";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";

const ViewDeliveryMethod = (props) => {
  const formKey = "testInputs";
  const numberOfLabelCol = 6;
  return (
    <div className="row">
      <div className="col-md-12 col-sm-12 ">

        <FormWrapper setGroupName={formKey}>
          <UICard>
            <ViewDetailBlock
              value={_get(props, `row.name`, "")}
              label={"Delivery Method Name in English"}
              labelCol={numberOfLabelCol}
            />

            <ViewDetailBlock
              value={_get(props, `row.name_sin`, "")}
              label={"Delivery Method Name in Sinhala"}
              labelCol={numberOfLabelCol}
            />

            <ViewDetailBlock
              value={_get(props, `row.name_ta`, "")}
              label={"Delivery Method Name in Tamil"}
              labelCol={numberOfLabelCol}
            />

            <ViewDetailBlock
              value={
                parseInt(_get(props, `row.active`, "")) === 1
                  ? "Active"
                  : "Inactive"
              }
              label={"Status"}
              labelCol={numberOfLabelCol}
            />

          </UICard>
          
          <AuditDetail

            labelCol={numberOfLabelCol}
            createdAt={dateObjectToString(_get(props, `row.created_at`, ''), "dd/mm/YY HH:MM")}
            updatedAt={dateObjectToString(_get(props, `row.updated_at`, ''), "dd/mm/YY HH:MM")}
            createdById={_get(props, `row.created_by`, '')}
            updatedById={_get(props, `row.updated_by`, '')}
            createdLocationId={_get(props, `row.created_location`, '')}
            updatedLocationId={_get(props, `row.updated_location`, '')}
            createdRoleId={_get(props, `row.created_role_id`, '')}
            updatedRoleId={_get(props, `row.updated_role_id`, '')}
          />
        </FormWrapper>
      </div>
    </div>
  );
};

export { ViewDeliveryMethod };
