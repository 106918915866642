import { callApi } from "./callApi.helpers";
import { userRolesListAPI } from "../../config/apiUrl.config";
import { _get } from "./lodash.wrappers";

const roleLevelChangeFn = (event, formAction, formGroupName, type) => {

    if (event.value == "null") {
        formAction.changeInputFn(formGroupName, "role level code", "", "")
    } else {
        callApi(`${userRolesListAPI.url}/${event.value}`)
            .headers(true)
            .method("get")
            .send((err, result) => {
                if (err) {
                } else {
                    if (result) {
                        if(type === 'intRole'){
                            formAction.changeInputFn(formGroupName, "intRoleLevelCode", "", _get(result, 'data.data.role_level_code.code', null))
                        }
                        if(type === 'admRole'){
                            formAction.changeInputFn(formGroupName, "admRoleLevelCode", "", _get(result, 'data.data.role_level_code.code', null))
                        }
                        if(type === 'arlRole'){
                            formAction.changeInputFn(formGroupName, "arlRoleLevelCode", "", _get(result, 'data.data.role_level_code.code', null))
                        }
                    } 
                }
            })
    }

}

export {
    roleLevelChangeFn
}