import React, { useContext } from "react";
import { Fragment } from "react";
import {
  authoritySerialNumbersCreateApi,
  authoritySerialNumbersDeleteApi,
  unusedAuthoritySerialNumbersListApi,
  usedAuthoritySerialNumbersListApi,
} from "../../../../../config/apiUrl.config";
import { permissions } from "../../../../../config/permission.config";
import { getDataByFormObject, isEmptyValue } from "../../../../../helpers/common-helpers/common.helpers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { _get, _getInt } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { CheckPermission } from "../../../../ui-components/ui-elements/common/BaseElements";
import { SubmitButton } from "../../../../ui-components/ui-elements/form";
import {
  actionBtnTypes,
  apiResponseStoringType,
  DataTable,
} from "../../../../ui-components/ui-elements/table/DataTable";
import { CoreContext } from "../../../core/context-providers/CoreContext.provider";
import {
  AddSerialNumbersForm,
  RemoveSerialNumbersForm,
} from "./AddSerialNumbersForm";

const CustomButtonsSave = (props) => {
  const [coreState, coreAction] = useContext(CoreContext);
  const uiType = props.uiType;

  return (
    <Fragment>
      <CheckPermission
        permission={permissions.AUTHORITY_SET_SERIAL.permissions}
      >
        <SubmitButton
          btnText={uiType === "add" ? "Save" : "Yes"}
          startIcon={uiType === "add" ? "far fa-save" : "fas fa-check"}
          formGroupName={props.formGroupName}
          dataTableKey={unusedAuthoritySerialNumbersListApi.key}
          isValidate={uiType === "add" ? true : false}
          validationObject={{
            fileds: {
              serial_number_from: "Serial No. From",
              serial_number_to: "Serial No. To",
            },
            rules: {
              serial_number_from: "required|max:12|minAmount:1|numeric",
              serial_number_to: "required|max:12|minAmount:1|numeric",
            },
            message: {
              "serial_number_from.minAmount": "Serial No. From can not be 0. ",
              "serial_number_to.minAmount": "Serial No. To can not be 0. ",
            }
          }}
          flashMessages={{
            success: {
              status: true,
              message:
                uiType === "add"
                  ? "Serial Number is successfully saved."
                  : "Serial Number is successfully removed.",
              messageType: "success",
            },
          }}
          onGetAPIEndPointFn={(formState) => {
            return {
              url:
                uiType === "add"
                  ? `${authoritySerialNumbersCreateApi.url}/${formState.authority_id}/serial-numbers`
                  : `${authoritySerialNumbersDeleteApi.url}/${formState.authority_id}/serial-numbers/${formState.id}`,
              key: authoritySerialNumbersCreateApi.key,
            };
          }}
          onChangeRequestBodyFn={(formObject) => {
            return getDataByFormObject({
              ...formObject,
            });
          }}
          callApiObject={{
            isSetHeaders: true,
            method: uiType === "add" ? "post" : "delete",
          }}
          apiDataStoringObject={{
            setLoader: true,
            storingType: apiResponseStoringType.dateTableLinkedForm,
          }}
          onResponseCallBackFn={(response, error) => {
            if (
              response.length === undefined &&
              error === undefined &&
              response.data.errors === undefined
            ) {
              coreAction.resetDataTable(unusedAuthoritySerialNumbersListApi.key);
              coreAction.resetDataTable(usedAuthoritySerialNumbersListApi.key);
              props.onClosePopupFn();
            }
          }}
        />
      </CheckPermission>

    </Fragment>
  );
};

const SerialNumbers = (props) => {
  const authorityName = _get(props, "row.service.name", "-");
  const _onload=props.fromParent._onload
  return (
    <div className="row">
      <div className="col-md-12 col-sm-12">
        <div className="row">
          {!_onload &&
          <div className="col-md-12">
            <DataTable
              tableName={"Assigned Serial Numbers"}
              apiUrl={`${unusedAuthoritySerialNumbersListApi.url}/${props.row.id}/serial-numbers?assigned=false&sort_by=created_at|desc`}
              dataTableKey={unusedAuthoritySerialNumbersListApi.key}
              isSetAction={true}
              actionTypeList={[
                {
                  actionType: actionBtnTypes.delete,
                  actionBtn: { tooltip: "Remove" },
                  modelContentElement: RemoveSerialNumbersForm,
                  dialogPopupProps: {
                    isFullScreen: false,
                    dialogTitle: "Please Confirm",
                    isCustomButton: true,
                    closeButton: {
                      btnText: "No",
                      startIcon: "fa fa-times",
                    },
                    customButtonComponent: CustomButtonsSave,
                    customButtonComponentProps: {
                      dataTableKey: unusedAuthoritySerialNumbersListApi.key,
                      uiType: "delete",
                    },
                  },
                  parentToModelProps: {
                    authorityId: props.row.id,
                    formKey: authoritySerialNumbersDeleteApi.key,
                    dataTableKey: unusedAuthoritySerialNumbersListApi.key,
                  },
                },
                {
                  actionType: actionBtnTypes.create,
                  modelContentElement: AddSerialNumbersForm,
                  dialogPopupProps: {
                    isFullScreen: false,
                    dialogTitle: "Set Serial Numbers",
                    isCustomButton: true,
                    customButtonComponent: CustomButtonsSave,
                    customButtonComponentProps: {
                      dataTableKey: unusedAuthoritySerialNumbersListApi.key,
                      uiType: "add",
                    },
                    closeButton: {
                      btnText: "Cancel",
                      startIcon: "far fa-times-circle",
                    },
                    isConfirmationPopup: true,
                  },
                  parentToModelProps: {
                    authorityId: props.row.id,
                    formKey: authoritySerialNumbersCreateApi.key,
                    dataTableKey: unusedAuthoritySerialNumbersListApi.key,
                  },
                },
              ]}
              tableHeaderList={[
                {
                  displayName: "Serial No. From",
                  key: "serial_number_from",
                  isSort: false,
                },
                {
                  displayName: "Serial No. To",
                  key: "serial_number_to",
                  isSort: false,
                },

                {
                  displayName: "Serial No. Count ",
                  key: "serial_count",
                  isSort: false,
                  onPrintFn: ({ serial_number_from, serial_number_to }) =>
                    parseInt(serial_number_to) - parseInt(serial_number_from) + +1,
                },
                {
                  displayName: "Discarded Count",
                  key: "discarded_count",
                  isSort: false,
                  onPrintFn: ({ discarded_count }) =>
                    _getInt(discarded_count, "discarded_count", 0),
                },
                {
                  displayName: "Serial No. Balance",
                  key: "serial_balance",
                  isSort: false,
                  onPrintFn: ({
                    serial_number_to,
                    serial_number_from,
                    discarded_count,
                  }) =>
                    parseInt(serial_number_to) -
                    parseInt(serial_number_from) -
                    parseInt(_getInt(discarded_count, "discarded_count", 0)) +
                    1,
                },
              ]}
            />
          </div>}
        </div>

        <div className="row">
          <div className="col-md-12 floatLeft defaultMarginTopBottom">
            <DataTable
              tableName={"Serial Number History"}
              apiUrl={`${usedAuthoritySerialNumbersListApi.url}/${props.row.id}/serial-numbers?assigned=true&sort_by=created_at|desc`}
              dataTableKey={usedAuthoritySerialNumbersListApi.key}
              isSetAction={false}
              tableHeaderList={[
                {
                  displayName: "Serial No. From",
                  key: "serial_number_from",
                  isSort: false,
                },
                {
                  displayName: "Serial No. To",
                  key: "serial_number_to",
                  isSort: false,
                },

                {
                  displayName: "Serial No. Count ",
                  key: "serial_count",
                  isSort: false,
                  onPrintFn: ({ serial_number_from, serial_number_to }) =>
                    parseInt(serial_number_to) - parseInt(serial_number_from) + +1,
                },
                {
                  displayName: "Discarded Count",
                  key: "discarded_count",
                  isSort: false,
                },
                {
                  displayName: "Created By",
                  key: "created_by",
                  isSort: false,
                  onPrintFn: ({ created_by }) =>
                  !isEmptyValue(_get(created_by, "user_name", null)) ? `${_get(created_by, "user_name", null)} (${_get(created_by, "user_role", null)})`: '-',
                },
                {
                  displayName: "Created Location",
                  key: "created_location",
                  isSort: false,
                  onPrintFn: ({ created_location }) =>
                    `${_get(created_location, "location", "-")} (${authorityName})`,
                },
                {
                  displayName: "Created At",
                  key: "created_at",
                  isSort: false,
                  onPrintFn: ({ created_at }) =>
                    dateObjectToString(created_at, "dd/mm/YY HH:MM"),
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SerialNumbers;
