/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-02-28 17:31:46
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-28 13:44:16
 */

import React, { Fragment } from "react";

import { Notification } from "./pages/Notification";
import { Navigate } from "../../ui-components/ui-elements/common/Navigate";
import { permissions } from "../../../config/permission.config";
import authorized from "../../hoc-middlewares/Authorized";

const NotificationRoutes = () => {
  return (
    <Fragment>
      <Navigate
        exact={true}
        path="/notification"
        routeKey={"notification"}
        component={authorized(Notification)}
        routePermissions={permissions.NOTIFICATION_VIEW.permissions}
      />
    </Fragment>
  );
};

export default NotificationRoutes;
