import React, { Fragment } from "react";
import { additionDeductionDeleteAPI, additionDeductionListAPI } from "../../../../../config/apiUrl.config";
import { templateTypes } from "../../../../../config/template.config";
import { getDataByFormObject } from "../../../../../helpers/common-helpers/common.helpers";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import {
  FormWrapper,
  InputBoxWithState,
  SubmitButton,
} from "../../../../ui-components/ui-elements/form";
import { apiResponseStoringType } from "../../../core/context-providers/CoreContext.provider";

const CustomButtonDelete = (props) => {
  const uiType = props.uiType;
  
  return (
    <Fragment>
      <SubmitButton
        startIcon={"fas fa-check"}
        btnText={"Yes"}
        formGroupName={props.formGroupName}
        dataTableKey={props.dataTableKey}
        isValidate={false}
        flashMessages={{
          success: {
            status: true,
            message: "Addition / Deduction is successfully removed.",
            messageType: "success",
          },
        }}
        onGetAPIEndPointFn={(formState) => {          
          return {
            url:`${additionDeductionDeleteAPI.url}/${formState.id}`,
            key: additionDeductionListAPI.key,
          };
        }}
        onChangeRequestBodyFn={(formObject) => {
          return getDataByFormObject({
            ...formObject,
            active: parseInt(formObject.active) === 1 ? true : false,
          });
        }}
        callApiObject={{
          isSetHeaders: true,
          method: "delete",
        }}
        apiDataStoringObject={{
          setLoader: true,
          storingType: apiResponseStoringType.dateTableLinkedForm,
        }}
        onResponseCallBackFn={(response, error) => {
          if (
            response.length === undefined &&
            error === undefined &&
            response.data.errors === undefined
          ) {
            props.onClosePopupFn();
          }
        }}
      />
    </Fragment>
  );
};

const RemoveAdditionDeductionForm = (props) => {
  const formKey = `${props.fromParent.formKey}`;
  return (
    <FormWrapper
      setGroupName={formKey}
      formGroupLinkWith={formKey}
      setFormObject={{
        id: props.row.id,
      }}
      onDestroyUnsetFormObject={true}
    >
      <div className="removeContent">
        <p>Are you sure you want to remove?</p>
        <br />
        <br />
      </div>
    </FormWrapper>
  );
};

export { RemoveAdditionDeductionForm, CustomButtonDelete };
