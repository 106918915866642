/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-03-30 13:07:24 
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2021-06-01 15:15:54
 */

import React, { Fragment } from 'react';

import { CollapseWrapper } from "../../common/BaseElements";
import { FormWrapper } from "../../form";
import { InputButtonWithState } from "../../form";
import { getDataByFormObject } from "../../../../../helpers/common-helpers/common.helpers";


const emptyFun = (...para) => undefined;

const SearchBoxWrapper = ({
    isSetSearchFrom = false,
    dataTableState = {},
    defaultSearchFormObject = {},
    dataTableKey = "",
    searchFromComponent = null,
    onSetSearchObjectFn = false,
    setDataTableStateFn = emptyFun,
    onGetData = emptyFun
}) => {
    return (
        <Fragment>
            {
                (isSetSearchFrom) ? (
                    <CollapseWrapper
                        component={"div"}
                        elementStyle={"dataTableSearchBoxWrapper"}
                        isIn={dataTableState.isShowSearch}
                        isUnMountOnExit={true}
                    >
                        <FormWrapper
                            elementStyle="fullWidthDiv"
                            setGroupName={`${dataTableKey}_search`}
                            formGroupLinkWith={dataTableKey}
                            setFormObject={defaultSearchFormObject}
                        >
                            <div className="">                                
                                {searchFromComponent}
                            </div>

                            <div className="fullWidthDiv buttonWrapper">
                                <InputButtonWithState
                                    btnText="Search"
                                    startIcon="fas fa-search"
                                    elementWrapperStyle={"searchBtn"}
                                    formGroupName={`${dataTableKey}_search`}
                                    onClickBtnFn={(event) => {
                                        let searchObject = event.formObject || {};

                                        if (onSetSearchObjectFn !== false) {
                                            searchObject = onSetSearchObjectFn(event.formObject || {});
                                        }

                                        setDataTableStateFn({
                                            ...dataTableState,
                                            formObject: getDataByFormObject(searchObject)
                                        });
                                        onGetData("searchFrom", searchObject);
                                    }}
                                />
                                <InputButtonWithState
                                    btnText="Reset"
                                    startIcon="fas fa-sync-alt"
                                    elementWrapperStyle={"refreshBtn"}
                                    formGroupName={`${dataTableKey}_search`}
                                    mergeToForm={defaultSearchFormObject}
                                    onClickBtnFn={(event) => {
                                        setDataTableStateFn({
                                            ...dataTableState,
                                            formObject: defaultSearchFormObject
                                        });
                                        onGetData("searchFrom", defaultSearchFormObject);
                                    }}
                                />
                            </div>

                        </FormWrapper>
                    </CollapseWrapper>
                ) : (null)
            }
        </Fragment>
    )
}

export {
    SearchBoxWrapper
}