import React from 'react';
import { templateTypes } from "../../../../../config/template.config";
import { _get } from '../../../../../helpers/common-helpers/lodash.wrappers';
import { FormWrapper, NumberInputBoxWithState } from "../../../../ui-components/ui-elements/form";

const AddSerialNumbersForm = (props) => {
  const formKey = `${props.fromParent.formKey}`;
  return (
    <div className="row">
      <FormWrapper 
      setGroupName={formKey}
      formGroupLinkWith={formKey}
      onDestroyUnsetFormObject={true}
      setFormObject={{
        authority_id: `${props.fromParent.authorityId}`,
        active: true
      }}
      >
        <div className="col-md-12 col-sm-12 ">
          <NumberInputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            inputName={"serial_number_from"}
            labelText="Serial No From"
            isRequired={true}
            isThousandSeparator={false}
            isAllowNegative={false}
            decimalScale={0}
          />
        </div>
        <div className="col-md-12 col-sm-12 ">
          <NumberInputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            inputName={"serial_number_to"}
            labelText="Serial No To"
            isRequired={true}
            isThousandSeparator={false}
            isAllowNegative={false}
            decimalScale={0}
          />
        </div>
      </FormWrapper>
    </div>
  );
};

const RemoveSerialNumbersForm = (props) => {
  const formKey = `${props.fromParent.formKey}`;
  const serialFrom = props.row.serial_number_from;
  const serialTo = props.row.serial_number_to;
  
  return (
    <FormWrapper
      setGroupName={formKey}
      formGroupLinkWith={formKey}
      setFormObject={{
        id: props.row.id,
        authority_id: `${props.fromParent.authorityId}`
      }}
      onDestroyUnsetFormObject={true}
    >
      <div className="removeContent">
        <p>Are you want to delete this range? (From :- {serialFrom} To :- {serialTo} )</p>
        <br />
        <br />
      </div>
    </FormWrapper>
  );
};

export {
  AddSerialNumbersForm,
  RemoveSerialNumbersForm
}