import React, { useContext } from "react";

import { CheckPermission, PaddingLessUICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import {
    CheckBoxWithState,
    FormWrapper,
    SelectBoxWithState,
    InputBoxWithState,
    SubmitButton,
    NumberInputBoxWithState
} from "../../../../ui-components/ui-elements/form";
import { pdmtListAPI, districtListAPI, paymentCategoriesListAPI } from "../../../../../config/apiUrl.config";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { _get, _findindex, _find } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { contactsArrayToObject } from "../../../../../helpers/common-helpers/contact.helpers";
import { ContactTypeCode } from "../../../../../config/enum.config";
import { pdmtTempLicenseValidityPeriod, pdmtChargingMethods, pdmtPaymentCategories } from "../../../../../config/DBstatus.config";
import { getDataByFormObject, isEmptyValue, toBoolean } from "../../../../../helpers/common-helpers/common.helpers";
import { permissions } from "../../../../../config/permission.config";

const EditProvinceOffice = ({ row }) => {
    const [formState, formAction] = useContext(FormContext);

    const checkNull = (value) => {
        if (isEmptyValue(value))
            return "";

        return value;
    }

    const getAddress = (type) => {
        const index = _findindex(_get(row, `service.addresses`, []), (e) => Number(e[type]));

        if (index !== -1) {
            if (type === "office_address") {
                return {
                    line1: _get(row, `service.addresses[${index}].line1`, ""),
                    line1_si: _get(row, `service.addresses[${index}].line1_si`, ""),
                    line1_ta: _get(row, `service.addresses[${index}].line1_ta`, ""),
                    line2: checkNull(_get(row, `service.addresses[${index}].line2`, "")),
                    line2_si: checkNull(_get(row, `service.addresses[${index}].line2_si`, "")),
                    line2_ta: checkNull(_get(row, `service.addresses[${index}].line2_ta`, "")),
                    city: _get(row, `service.addresses[${index}].city`, ""),
                    city_si: _get(row, `service.addresses[${index}].city_si`, ""),
                    city_ta: _get(row, `service.addresses[${index}].city_ta`, ""),
                    postal_code: _get(row, `service.addresses[${index}].postal_code`, ""),
                    district: _get(row, `service.addresses[${index}].district.code`, ""),
                    province: _get(row, `service.addresses[${index}].province`, "")
                }
            } else {
                return {
                    line1: _get(row, `service.addresses[${index}].line1`, ""),
                    line2: checkNull(_get(row, `service.addresses[${index}].line2`, "")),
                    city: _get(row, `service.addresses[${index}].city`, ""),
                }
            }
        } else {
            return {};
        }
    }

    const getPaymentTypesAllowed = (categories = [], allowedTypes = []) => {
        let temp = {};

        categories.forEach((value) => {
            const index = _findindex(allowedTypes, (e) => value.code === e.payment_type_code);
            temp[value.code] = index !== -1;
        });

        return temp;
    }

    const sameAsAboveFn = (eventData) => {
        if (toBoolean(eventData.value) === true) {
            formAction.changeInputFn(
                `${pdmtListAPI.key}_edit`,
                "",
                `${pdmtListAPI.key}_edit.return_address.line1`,
                _get(formState, `${pdmtListAPI.key}_edit.address.line1`, "")
            );

            formAction.changeInputFn(
                `${pdmtListAPI.key}_edit`,
                "",
                `${pdmtListAPI.key}_edit.return_address.line2`,
                _get(formState, `${pdmtListAPI.key}_edit.address.line2`, "")
            );

            formAction.changeInputFn(
                `${pdmtListAPI.key}_edit`,
                "",
                `${pdmtListAPI.key}_edit.return_address.city`,
                _get(formState, `${pdmtListAPI.key}_edit.address.city`, "")
            );
        } else {
            formAction.changeInputFn(
                `${pdmtListAPI.key}_edit`,
                "",
                `${pdmtListAPI.key}_edit.return_address.line1`,
                ""
            );

            formAction.changeInputFn(
                `${pdmtListAPI.key}_edit`,
                "",
                `${pdmtListAPI.key}_edit.return_address.line2`,
                ""
            );

            formAction.changeInputFn(
                `${pdmtListAPI.key}_edit`,
                "",
                `${pdmtListAPI.key}_edit.return_address.city`,
                ""
            );
        }
    };

    const getPaymentModeError = () => {
        const check = _find(_get(formState, `${pdmtListAPI.key}_edit._errors`, []), (e) => e.property === "payment_types_allowed");

        if (check !== undefined) {
            return {
                status: true,
                message: check.message
            };
        } else {
            return {
                status: false
            }
        }
    };

    return (
        <FormWrapper
            elementStyle="provinceOfficeWrapper"
            setGroupName={`${pdmtListAPI.key}_edit`}
            apiUrl={`${paymentCategoriesListAPI.url}?status=true&internal_payment=true&sort_by=internal_order|ASC`}
            onRebuildResponseFn={(response) => {
                return {
                    prefix: _get(row, `prefix`, ""),
                    code: _get(row, `code`, ""),
                    name: _get(row, `service.name`, ""),
                    name_si: _get(row, `service.name_si`, ""),
                    name_ta: _get(row, `service.name_ta`, ""),
                    address: getAddress("office_address"),
                    return_address: getAddress("postal_address"),
                    contacts: {
                        contact_number_1: contactsArrayToObject(_get(row, `service.contacts`, []), false, ContactTypeCode.MOBILE),
                        contact_number_2: contactsArrayToObject(_get(row, `service.contacts`, []), false, ContactTypeCode.PHONE),
                        email: contactsArrayToObject(_get(row, `service.contacts`, []), false, ContactTypeCode.EMAIL),
                        fax: contactsArrayToObject(_get(row, `service.contacts`, []), false, ContactTypeCode.FAX),
                    },
                    
                    website_url: _get(row, `website_url`, "")===null ? "":_get(row, `website_url`, ""),
                    web_message: _get(row, `web_message`, "")===null ?"":_get(row, `web_message`, ""),
                    temp_licence_validity_period: _get(row, `temp_licence_validity_period`, ""),
                    temp_licence_charging_method: _get(row, `temp_licence_charging_method`, ""),
                    vet_validity_period: _get(row, `vet_validity_period`, ""),
                    vft_validity_period: _get(row, `vft_validity_period`, ""),
                    max_vft_certificates: _get(row, `max_vft_certificates`, ""),
                    web_temp_valid_days: _get(row, `web_temp_valid_days`, ""),
                    dmt_mapping: _get(row, `dmt_mapping`, ""),
                    province: _get(row, `province.name`, ""),
                    payment_categories: response,
                    ...getPaymentTypesAllowed(response, _get(row, `service.payment_types_allowed`, [])),
                    "_onLoad": false
                }
            }}
        >
            <div className="row">
                <div className="col-md-12 defaultPaddingTop">
                    <table className={"fullWidthDiv"}>
                        <tr>
                            <th>Province Code</th>
                            <td>{_get(formState, `${pdmtListAPI.key}_edit.code`, "")}</td>
                        </tr>

                        <tr>
                            <th>Province Prefix<sup className="text-danger">*</sup></th>
                            <td>
                                <InputBoxWithState
                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                    inputName={"prefix"}
                                    maxLength={2}
                                />
                            </td>
                        </tr>

                        <tr>
                            <th>Province</th>
                            <td>{_get(formState, `${pdmtListAPI.key}_edit.province`, "")}</td>
                        </tr>

                        <tr>
                            <td colSpan="4" className="defaultHalfPadding"></td>
                        </tr>

                        <tr>
                            <th></th>
                            <td><b>English</b></td>
                            <td><b>Sinhala</b></td>
                            <td><b>Tamil</b></td>
                        </tr>

                        <tr>
                            <th>Province Office Name <sup className="text-danger">*</sup></th>
                            <td>
                                <InputBoxWithState
                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                    inputName={"name"}
                                    inputPlaceholder={"Province Office Name in English"}
                                    maxLength={200}
                                />
                            </td>
                            <td>
                                <InputBoxWithState
                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                    inputName={"name_si"}
                                    inputPlaceholder={"Province Office Name in Sinhala"}
                                    maxLength={200}
                                />
                            </td>
                            <td>
                                <InputBoxWithState
                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                    inputName={"name_ta"}
                                    inputPlaceholder={"Province Office Name in Tamil"}
                                    maxLength={200}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>Address Line 1 <sup className="text-danger">*</sup></th>
                            <td>
                                <InputBoxWithState
                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                    inputStatePath={`${pdmtListAPI.key}_edit.address.line1`}
                                    inputPlaceholder={"Address Line 1 in English"}
                                    maxLength={50}
                                />
                            </td>
                            <td>
                                <InputBoxWithState
                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                    inputStatePath={`${pdmtListAPI.key}_edit.address.line1_si`}
                                    inputPlaceholder={"Address Line 1 in Sinhala"}
                                    maxLength={50}
                                />
                            </td>
                            <td>
                                <InputBoxWithState
                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                    inputStatePath={`${pdmtListAPI.key}_edit.address.line1_ta`}
                                    inputPlaceholder={"Address Line 1 in Tamil"}
                                    maxLength={50}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>Address Line 2 </th>
                            <td>
                                <InputBoxWithState
                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                    inputStatePath={`${pdmtListAPI.key}_edit.address.line2`}
                                    inputPlaceholder={"Address Line 2 in English"}
                                    maxLength={50}
                                />
                            </td>
                            <td>
                                <InputBoxWithState
                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                    inputStatePath={`${pdmtListAPI.key}_edit.address.line2_si`}
                                    inputPlaceholder={"Address Line 2 in Sinhala"}
                                    maxLength={50}
                                />
                            </td>
                            <td>
                                <InputBoxWithState
                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                    inputStatePath={`${pdmtListAPI.key}_edit.address.line2_ta`}
                                    inputPlaceholder={"Address Line 2 in Sinhala"}
                                    maxLength={50}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>City <sup className="text-danger">*</sup></th>
                            <td>
                                <InputBoxWithState
                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                    inputStatePath={`${pdmtListAPI.key}_edit.address.city`}
                                    inputPlaceholder={"City in English"}
                                    maxLength={50}
                                />
                            </td>
                            <td>
                                <InputBoxWithState
                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                    inputStatePath={`${pdmtListAPI.key}_edit.address.city_si`}
                                    inputPlaceholder={"City in Sinhala"}
                                    maxLength={50}
                                />
                            </td>
                            <td>
                                <InputBoxWithState
                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                    inputStatePath={`${pdmtListAPI.key}_edit.address.city_ta`}
                                    inputPlaceholder={"City in Tamil"}
                                    maxLength={50}
                                />
                            </td>
                        </tr>

                        <tr>
                            <th>Postal Code <sup className="text-danger">*</sup></th>
                            <td>
                                <NumberInputBoxWithState
                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                    isAllowLeadingZeros={true}
                                    inputStatePath={`${pdmtListAPI.key}_edit.address.postal_code`}
                                    isThousandSeparator={false}
                                    isAllowNegative={false}
                                    decimalScale={0}
                                    isAllowedFn={(value) => value.value.toString().length <= 6}
                                    inputPlaceholder={"Postal Code"}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>District <sup className="text-danger">*</sup> </th>
                            <td>
                                <SelectBoxWithState
                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                    inputStatePath={`${pdmtListAPI.key}_edit.address.district`}
                                    apiUrl={`${districtListAPI.url}?province_code=${_get(row, `code`, "")}&sort_by=name|asc`}
                                    apiStateKey={districtListAPI.key}
                                    keyName={"code"}
                                    valueName={"name"}
                                    emptySelectOptionTxt={"Please Select a District"}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>Contact Number 1 <sup className="text-danger">*</sup> </th>
                            <td>
                                <NumberInputBoxWithState
                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                    inputStatePath={`${pdmtListAPI.key}_edit.contacts.contact_number_1`}
                                    inputPlaceholder={"Contact Number 1"}
                                    isAllowNegative={false}
                                    isThousandSeparator={false}
                                    isAllowLeadingZeros={true}
                                    decimalScale={0}
                                    isAllowedFn={(value) => {
                                        const checkValue = value.value.toString();
                                        return checkValue.length <= 10;
                                    }}
                                />
                            </td>
                        </tr>

                        <tr>
                            <th>Contact Number 2</th>
                            <td>
                            <NumberInputBoxWithState
                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                    inputStatePath={`${pdmtListAPI.key}_edit.contacts.contact_number_2`}
                                    inputPlaceholder={"Contact Number 2"}
                                    isAllowNegative={false}
                                    isThousandSeparator={false}
                                    isAllowLeadingZeros={true}
                                    decimalScale={0}
                                    isAllowedFn={(value) => {
                                        const checkValue = value.value.toString();
                                        return checkValue.length <= 10;
                                    }}
                                />
                            </td>
                        </tr>

                        <tr>
                            <th>E-mail</th>
                            <td>
                                <InputBoxWithState
                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                    inputStatePath={`${pdmtListAPI.key}_edit.contacts.email`}
                                    inputPlaceholder={"E-mail"}
                                    maxLength={50}
                                />
                            </td>
                        </tr>

                        <tr>
                            <th>Fax</th>
                            <td>
                                <NumberInputBoxWithState
                                    inputPlaceholder={"Fax"}
                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                    isAllowLeadingZeros={true}
                                    inputStatePath={`${pdmtListAPI.key}_edit.contacts.fax`}
                                    isThousandSeparator={false}
                                    isAllowNegative={false}
                                    decimalScale={0}
                                    isAllowedFn={(value) => value.value.toString().length <= 10}
                                />
                            </td>
                        </tr>

                        <tr>
                            <th>Province Website URL </th>
                            <td>
                                <InputBoxWithState
                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                    inputName={"website_url"}
                                    inputPlaceholder={"Province Website URL"}
                                    maxLength={200}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>Web Message <sup className="text-danger"></sup></th>
                            <td>
                                <InputBoxWithState
                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                    inputName={"web_message"}
                                    inputPlaceholder={"Web Message"}
                                    maxLength={200}
                                />
                            </td>
                        </tr>
                    </table>
                </div>

                <div className="col-md-12">
                    <PaddingLessUICard cardHeading="Payment Modes" elementStyle="defaultHalfMarginTop">
                        {
                            (getPaymentModeError().status === true) ? (
                                <div className={"defaultFormHelperText text-danger"}>
                                    {getPaymentModeError().message}
                                </div>
                            ) : null
                        }

                        <div className="row">
                            <div className="col-md-3">
                                {
                                    _get(formState, `${pdmtListAPI.key}_edit.payment_categories`, []).map((value, index) => {
                                        return (
                                            <div key={index}>
                                                <CheckBoxWithState
                                                    inputName={value.code}
                                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                                    labelText={value.name}
                                                    inputValue={_get(formState, `${pdmtListAPI.key}_edit[${value.code}]`, "")}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </PaddingLessUICard>
                    <PaddingLessUICard cardHeading="Temporary Licence" elementStyle="defaultHalfMarginTop">
                        <div className="row">
                            <div className="col-md-4">
                                <SelectBoxWithState
                                    labelText={"Validity Period"}
                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                    inputName={"temp_licence_validity_period"}
                                    dataList={pdmtTempLicenseValidityPeriod}
                                    isRequired={true}
                                />

                                <SelectBoxWithState
                                    labelText={"Charging Method"}
                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                    inputName={"temp_licence_charging_method"}
                                    dataList={pdmtChargingMethods}
                                    isRequired={true}
                                />
                            </div>
                        </div>
                    </PaddingLessUICard>

                    <PaddingLessUICard cardHeading="Document Validation" elementStyle="defaultHalfMarginTop">
                        <div className="row">
                            <div className="searchWrapper col-md-4">
                                <NumberInputBoxWithState
                                    labelText={"VET valid up to"}
                                    inputPlaceholder={"VET valid up to"}
                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                    inputName={"vet_validity_period"}
                                    isRequired={true}
                                    isAllowNegative={false}
                                    decimalScale={0}
                                    isAllowedFn={(value) => {
                                        const checkValue = value.value.toString();
                                        return checkValue.length <= 3;
                                    }}
                                />

                                <p>days from issuing days</p>
                            </div>

                            <div className="searchWrapper col-md-4">
                                <NumberInputBoxWithState
                                    labelText={"VFT valid up to"}
                                    inputPlaceholder={"VFT valid up to"}
                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                    inputName={"vft_validity_period"}
                                    isRequired={true}
                                    isAllowNegative={false}
                                    isThousandSeparator={false}
                                    decimalScale={0}
                                    isAllowedFn={(value) => {
                                        const checkValue = value.value.toString();
                                        return checkValue.length <= 4;
                                    }}
                                />

                                <p>days from issuing days</p>
                            </div>
                        </div>
                    </PaddingLessUICard>

                    <PaddingLessUICard cardHeading="VFT Pass Count Per Day" elementStyle="defaultHalfMarginTop">
                        <div className="row">
                            <div className="searchWrapper col-md-4">
                                <NumberInputBoxWithState
                                    labelText={"Pass Count"}
                                    inputPlaceholder={"Pass Count"}
                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                    inputName={"max_vft_certificates"}
                                    isRequired={true}
                                    isAllowNegative={false}
                                    isThousandSeparator={false}
                                    decimalScale={0}
                                    isAllowedFn={(value) => {
                                        const checkValue = value.value.toString();
                                        return checkValue.length <= 4;
                                    }}
                                />
                            </div>
                        </div>
                    </PaddingLessUICard>

                    <PaddingLessUICard cardHeading="Web Revenue Licence - Return Address" elementStyle="defaultHalfMarginTop">
                        <div className="row">
                            <div className="col-md-12 defaultPaddingBottom">
                                <CheckBoxWithState
                                    inputName={"sameAsAbove"}
                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                    labelText={"Same As Above"}
                                    onChangeFn={sameAsAboveFn}
                                />
                            </div>

                            <div className="col-md-4">
                                <InputBoxWithState
                                    labelText={"Address Line 1"}
                                    inputPlaceholder={"Address Line 1"}
                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                    inputStatePath={`${pdmtListAPI.key}_edit.return_address.line1`}
                                    isRequired={true}
                                    isDisabled={toBoolean(_get(formState, `${pdmtListAPI.key}_edit.sameAsAbove`, false))}
                                    maxLength={50}
                                />

                                <InputBoxWithState
                                    labelText={"Address Line 2"}
                                    inputPlaceholder={"Address Line 2"}
                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                    inputStatePath={`${pdmtListAPI.key}_edit.return_address.line2`}
                                    isDisabled={toBoolean(_get(formState, `${pdmtListAPI.key}_edit.sameAsAbove`, false))}
                                    maxLength={50}
                                />

                                <InputBoxWithState
                                    labelText={"City"}
                                    inputPlaceholder={"City"}
                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                    inputStatePath={`${pdmtListAPI.key}_edit.return_address.city`}
                                    isRequired={true}
                                    isDisabled={toBoolean(_get(formState, `${pdmtListAPI.key}_edit.sameAsAbove`, false))}
                                    maxLength={50}
                                />
                            </div>
                        </div>
                    </PaddingLessUICard>

                    <PaddingLessUICard cardHeading="Web Temporary Revenue Licence - Valid Period" elementStyle="defaultHalfMarginTop">
                        <div className="row">
                            <div className="searchWrapper col-md-4">
                                <NumberInputBoxWithState
                                    labelText={"Valid up to"}
                                    inputPlaceholder={"valid up to"}
                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                    inputName={"web_temp_valid_days"}
                                    isRequired={true}
                                    isAllowNegative={false}
                                    decimalScale={0}
                                    isAllowedFn={(value) => {
                                        const checkValue = value.value.toString();
                                        return checkValue.length <= 8;
                                    }}
                                />

                                <p>days</p>
                            </div>
                        </div>
                    </PaddingLessUICard>

                    <PaddingLessUICard cardHeading="DMT Mapping Parameters" elementStyle="defaultHalfMarginTop">
                        <div className="row">
                            <div className="searchWrapper col-md-4">
                                <InputBoxWithState
                                    labelText={"DMT Mapping Parameter 1"}
                                    inputPlaceholder={"DMT Mapping Parameter 1"}
                                    formGroupName={`${pdmtListAPI.key}_edit`}
                                    inputName={"dmt_mapping"}
                                    isRequired={true}
                                    maxLength={50}
                                />
                            </div>
                        </div>
                    </PaddingLessUICard>
                </div>
            </div>
        </FormWrapper>
    );
}

const ProvinceOfficeEditBtn = (props) => {
    return (
        <CheckPermission
            permission={permissions.PDMT_EDIT.permissions}
        >
            <SubmitButton
                btnText="Update"
                elementStyle="btnWrapper"
                startIcon={"far fa-edit"}
                formGroupName={`${pdmtListAPI.key}_edit`}
                dataTableKey={pdmtListAPI.key}
                isValidate={true}
                flashMessages={{
                    "success": {
                        status: true,
                        message: "Province Office is successfully updated.",
                        messageType: "success"
                    }
                }}
                mapValidationErrorObj={{
                    "contacts.contact_number_1": "Contact Number 1 must be a valid phone number",
                    "contacts.contact_number_2": "Contact Number 2 must be a valid phone number"
                }}
                validationObject={{
                    fileds: {
                        prefix: "Province Prefix",
                        name: "Province Office Name(English)",
                        name_si: "Province Office Name(Sinhala)",
                        name_ta: "Province Office Name(Tamil)",
                        "address.line1": "Address Line 1(English)",
                        "address.line1_si": "Address Line 1(Sinhala)",
                        "address.line1_ta": "Address Line 1(Tamil)",
                        "address.line2": "Address Line 2(English)",
                        "address.line2_si": "Address Line 2(Sinhala)",
                        "address.line2_ta": "Address Line 2(Tamil)",
                        "address.city": "City(English)",
                        "address.city_si": "City(Sinhala)",
                        "address.city_ta": "City(Tamil)",
                        "address.postal_code": "Postal Code",
                        "address.district": "District",
                        "contacts.contact_number_1": "Contact Number 1",
                        "contacts.contact_number_2": "Contact Number 2",
                        "contacts.email": "E-mail",
                        "contacts.fax": "Fax",
                        "return_address.line1": "Address Line 1",
                        "return_address.line2": "Address Line 2",
                        "return_address.city": "City",
                        web_message:"Web Message",
                        website_url: "Province Website URL",
                        temp_licence_validity_period: "Validity period",
                        temp_licence_charging_method: "Charging Method",
                        vet_validity_period: "VET valid up to",
                        vft_validity_period: "VFT valid up to",
                        max_vft_certificates: "Pass Count",
                        dmt_mapping: "DMT Mapping Parameter 1",
                        payment_types_allowed: "Payment Modes",
                        web_temp_valid_days: "Web Temporary Revenue Licence - Valid Period",
                    },
                    rules: {
                        prefix: "required|max:2|matchRegex:AlphabetsOnlyRegexPattern",
                        name: "required|max:200",
                        name_si: "required|max:200",
                        name_ta: "required|max:200",
                        "address.line1": "required|max:50",
                        "address.line1_si": "required|max:50",
                        "address.line1_ta": "required|max:50",
                        "address.line2": "max:50",
                        "address.line2_si": "max:50",
                        "address.line2_ta": "max:50",
                        "address.city": "required|max:50",
                        "address.city_si": "required|max:50",
                        "address.city_ta": "required|max:50",
                        "address.postal_code": "required|max:6",
                        "address.district": "required",
                        "contacts.contact_number_1": "required|numeric|max:10|min:9",
                        "contacts.contact_number_2": "numeric|max:10|min:9",
                        "contacts.email": "email|max:50",
                        "contacts.fax": "max:10",
                        "return_address.line1": "required|max:50",
                        "return_address.line2": "max:50",
                        web_message: "max:250",
                        "return_address.city": "required|max:50",
                        website_url: "max:200|matchRegex:URLRegexPattern",
                        temp_licence_validity_period: "required",
                        temp_licence_charging_method: "required",
                        vet_validity_period: "required|max:3",
                        vft_validity_period: "required|max:4",
                        max_vft_certificates: "required|max:4",
                        dmt_mapping: "required|max:50",
                        payment_types_allowed: "pdmtPaymentModes",
                        web_temp_valid_days: "required|max:8"
                    },
                    message: {
                        "website_url.matchRegex": "Invalid URL",
                        "prefix.matchRegex": "The Province Prefix should only contain alphabetical letters",
                        //"contacts.email.email": "Invalid Email"
                    }
                }}
                callApiObject={{
                    isSetHeaders: true,
                    multipart: false,
                    method: "put",
                    onUpload: false
                }}
                apiDataStoringObject={{
                    setLoader: true,
                    storingType: "DATA_TABLE_LINKED_FORM",
                    mergeToSuccessResponse: null,
                    mergeToErrorResponse: null,
                }}
                onGetAPIEndPointFn={(formObject) => {
                    return {
                        url: `${pdmtListAPI.url}/${props.row.id}`,
                        key: `${pdmtListAPI.key}`
                    }
                }}
                onChangeRequestBodyFn={(formObject) => {
                    let data = getDataByFormObject(formObject);

                    // if (isEmptyValue(_get(data, "return_address.line2", ""))) {
                    //     delete data["return_address"]["line2"];
                    // }

                    if (isEmptyValue(_get(data, "contacts.contact_number_2", ""))) {
                        delete data["contacts"]["contact_number_2"];
                    }

                    if (isEmptyValue(_get(data, "contacts.email", ""))) {
                        delete data["contacts"]["email"];
                    }

                    if (isEmptyValue(_get(data, "contacts.fax", ""))) {
                        delete data["contacts"]["fax"];
                    }

                    const allowedPaymentTypes = [];

                    _get(data, "payment_categories", []).forEach((value) => {
                        if (data[value.code] === true) {
                            allowedPaymentTypes.push(value.code);
                        }
                    });

                    return {
                        ...data,
                        payment_types_allowed: allowedPaymentTypes,
                        address: {
                            ...data.address,
                            province: _get(props, "row.province.code", "")
                        }
                    };
                }}
                onResponseCallBackFn={(error) => {
                    if (!error) {
                        props.onClosePopupFn(false);
                    }
                }}
            />
        </CheckPermission>

    )
}

export {
    EditProvinceOffice,
    ProvinceOfficeEditBtn
};
