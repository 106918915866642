/*
 * @Author: Anjula Karunarathne
 * @Date: 2020-12-30 13:57:41
 * @Last Modified by: Chamith Jayathilaka
 * @Last Modified time: 2022-08-11 00:58:00
 */

import React, { Fragment , useContext, useRef} from "react";
import { DealerFileUploadComponent } from "./DealerFileUploadComponent";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import {
  SubmitButton,
  FormWrapper,
  InputBoxWithState,
} from "../../../../ui-components/ui-elements/form";
import { dealerFileUploadAPi ,dealerFileGetAPi } from "../../../../../config/apiUrl.config";

const UploadDealerFilesForm = () => {
  const formKey = `${dealerFileGetAPi.key}_create`;
  const [formState, formAction] = useContext(FormContext);

  return (
    <FormWrapper setGroupName={formKey}>
      <div className="defaultPaddingBottom">
      <div className={"col-md-12 defaultMarginBottom floatLeft"}>
          <div className={"row"}>
            <strong style={{marginTop:'4px',marginRight:'12px'}}>Upload Document : </strong>
            <DealerFileUploadComponent
              className={"defaultPaddingTop"}
              formGroupName={formKey}
              formKey={"file_name"}
              isMultiple={true}
              labelText={"Test"}
              // getApiUrl={`${uploadsApiUrl}/`}
              uploadApiUrl={dealerFileUploadAPi.url}
              formObjectAppendKey={"file"}
              isButtonOnly={true}
            />
          </div>
        </div>
        <div className={"defaultHalfMargin"}>
          <span>{_get(formState, `${formKey}.file_name`, undefined)}</span>
        </div>
      </div>

      <InputBoxWithState
        formGroupName={formKey}
        inputName={"documentDescription"}
        isRequired
        maxLength="150"
        labelText="Document Description"
      />
    </FormWrapper>
  );
};

export { UploadDealerFilesForm } ;
