/*
 * @Author: Gajani Kangesan
 * @Date: 2023-02-20 10:10:15
 * @Last Modified by: Gajani Kangesan
 * @Last Modified time: 2023-02-20 10.30
 */

import React, { Fragment, useContext } from "react";
import { templateTypes } from "../../../../../config/template.config";
import {
    FormWrapper,
    InputBoxWithState,
    SelectBoxWithState,

} from "../../../../ui-components/ui-elements/form";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { ReasonList } from "../../../../../config/enum.config";
import { useEffect } from "react";
import { useState } from "react";

const SearchElement = ({ dataTableKey = "" }) => {
    const formKey = `${dataTableKey}_search`;
    const currentYear = new Date().getFullYear();
    const [authStatus, authAction] = useContext(AuthContext);
    const userRoleLevelCode = _get(authStatus, "authUser.role_level_code", "");
 
    return (
        <FormWrapper setGroupName={formKey}>
            <div className="row">
                <div className="col-md-3 col-sm-12 floatLeft">
                    <InputBoxWithState
                        labelText={"Description"}
                        inputName={"discription"}
                        maxLength={20}
                        formGroupName={formKey}
                    />

                </div>
                <div className="col-md-3 col-sm-12 floatLeft">
                    <SelectBoxWithState
                        isEnableAllOption={false}
                        uiType={templateTypes.bootstrap}
                        formGroupName={formKey}
                        dataList={ReasonList}
                        inputName={"criteria"}
                        labelText="Criteria"
                        keyName={"id"}
                        valueName={"name"}
                    />
                </div>

            </div>
        </FormWrapper>
    );
};

export { SearchElement };
