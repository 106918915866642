import React, { Fragment, useContext, useEffect, useState } from "react";
import { CircleLoaderElement, UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { FormWrapper, InputBoxWithState, SelectBoxWithState } from "../../../../ui-components/ui-elements/form";
import { PermissionsPanel } from "../../../../ui-components/ui-elements/expansion-panel/PermissionPanel";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { callApi } from "../../../../../helpers/common-helpers/callApi.helpers";
import { userRolesPermissionsListAPI, userRolesLevelListAPI, userRoleTypeListAPI, userRoleCategoryListAPI } from "../../../../../config/apiUrl.config";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { isEmptyValue } from "../../../../../helpers/common-helpers/common.helpers";


const FormElement = (props) => {
    const [permissions, setPermissions] = useState("loading");
    const [formState, formAction] = useContext(FormContext);
    const formKey = props.fromParent.formKey;

    useEffect(() => {
        getInitialData();
    }, [_get(formState, `${formKey}.role_type`, null)]);

    const getInitialData = () => {
        callApi(`${userRolesPermissionsListAPI.url}?sort_by=name|asc&status=true&application=${_get(formState, `${formKey}.role_type`, null)}`)
            .headers(true)
            .method('get')
            .send((err, result) => {
                if (!err) {
                    setPermissions(result.data.data);
                }
            })
        if (_get(props, "fromParent.type", null) === "edit") {

            const permitionSet = [];

            _get(props, "row.permissions", []).map((permition, index) => {
                permitionSet.push(permition.id);
            })
            formAction.changeInputFn(props.fromParent.formKey, 'permission_ids', "", Array.from(permitionSet));
        }
    }
    console.log("user-role", _get(formState, `${formKey}.role_type`, undefined), _get(formState, `${formKey}.role_level_code`, undefined))
    return (
        <Fragment>
            <FormWrapper
                setGroupName={`${props.fromParent.formKey}`}
                formGroupLinkWith={props.fromParent.formKey}
                setFormObject={{
                    id: _get(props, "row.id", null),
                    name: _get(props, "row.name", ""),
                    prefix: _get(props, "row.prefix", ""),
                    role_level_code: _get(props, "row.role_level_code.code", ""),
                    status: _get(props, "row.active", 1),
                    role_type: _get(props, "row.role_level_code.role_type.code", ""),
                    role_category_code: _get(props, "row.role_category_code.code", "")
                }}
            >
                <UICard>
                    <div className="fullWidthDiv">
                        <div>
                            <InputBoxWithState
                                inputName="prefix"
                                labelText="User Role Prefix"
                                isRequired={true}
                                formGroupName={formKey}
                                maxLength={"2"}
                            />
                            <InputBoxWithState
                                inputName="name"
                                labelText="User Role Name"
                                isRequired={true}
                                formGroupName={formKey}
                                maxLength={"100"}
                                isDisabled={props.fromParent.type === "edit"}
                            />
                            <SelectBoxWithState
                                inputName="role_type"
                                labelText="Role Type"
                                isRequired={true}
                                formGroupName={formKey}
                                emptySelectOptionTxt={"Select Role Type"}
                                apiUrl={`${userRoleTypeListAPI.url}?hidden=false`}
                                apiStateKey={`${userRoleTypeListAPI.key}`}
                                keyName={"code"}
                                valueName={"name"}
                                isDisabled={props.fromParent.type === "edit"}
                                onChangeFn={(event) => {
                                    if (event.value === "ADN_APP") {
                                        formAction.changeInputFn(formKey, "role_level_code", "", "")
                                    }else{
                                        formAction.changeInputFn(formKey, "role_level_code", "", "")
                                    }

                                    
                                }}
                            />
                            <SelectBoxWithState
                                inputName="role_level_code"
                                labelText="Role Level"
                                isRequired={true}
                                formGroupName={formKey}
                                emptySelectOptionTxt={"Select Role Level"}
                                apiUrl={`${userRolesLevelListAPI.url}?role_type_code=${_get(formState, `${formKey}.role_type`, null)}`}
                                apiStateKey={userRolesLevelListAPI.key}
                                keyName={"code"}
                                valueName={"name"}
                                isDisabled={(!isEmptyValue(_get(formState, `${formKey}.role_type`, undefined)) === true ? false : true) || props.fromParent.type === "edit"}
                            />
                            <SelectBoxWithState
                                inputName="role_category_code"
                                labelText="Role Category"
                                isRequired={true}
                                formGroupName={formKey}
                                emptySelectOptionTxt={"Select Role Category"}
                                apiUrl={props.fromParent.type === "edit" ? `${userRoleCategoryListAPI.url}?role_type_code=${_get(props, "row.role_level_code.role_type.code", "")}&role_level_code=${_get(props, "row.role_level_code.code", "")}` : `${userRoleCategoryListAPI.url}?role_type_code=${_get(formState, `${formKey}.role_type`, null)}&role_level_code=${_get(formState, `${formKey}.role_level_code`, null)}`}
                                apiStateKey={userRoleCategoryListAPI.key}
                                keyName={"code"}
                                valueName={"name"}
                                isDisabled={!isEmptyValue(_get(formState, `${formKey}.role_level_code`, undefined)) === true ? false : true}
                            />
                            <SelectBoxWithState
                                labelText={"Status"}
                                formGroupName={formKey}
                                inputName={"status"}
                                isRequired={true}
                                emptySelectOptionTxt={"Select Status"}
                                dataList={[
                                    { "id": 1, "value": "Active" },
                                    { "id": 0, "value": "Inactive" }
                                ]}
                            />
                        </div>

                    </div>
                    <div className="fullWidthDiv floatLeft defaultMarginTopBottom">
                        <strong>Permissions</strong>
                        {(_get(formState, `${formKey}._errors[0].property`, undefined) === "permission_ids" ||
                            _get(formState, `${formKey}._errors[1].property`, undefined) === "permission_ids" ||
                            _get(formState, `${formKey}._errors[2].property`, undefined) === "permission_ids" ||
                            _get(formState, `${formKey}._errors[3].property`, undefined) === "permission_ids") ? <p> <sup className="text-danger"> At least one permission should be selected.</sup> </p> : null}
                        {
                            (permissions !== "loading") ? (
                                <div className="fullWidthDiv defaultPaddingTopBottom">
                                    <PermissionsPanel
                                        formGroupName={formKey}
                                        inputName="permission_ids"
                                        dataList={permissions}
                                        groupKey="id"
                                        groupDisplayNameKey="description"
                                        subPermissionKey="permissions"
                                        subPermissionUniqueKey="id"
                                        subPermissionDisplayNameKey="name"
                                    />
                                </div>
                            ) : null
                            // (
                            //     <div className={"text-center"}>
                            //         <CircleLoaderElement
                            //             loaderSize={70}
                            //             loaderStyle={3}
                            //         />
                            //     </div>
                            // )
                        }
                    </div>
                </UICard>
            </FormWrapper>
        </Fragment>
    );
};
export { FormElement }