import React from "react";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { CustomButton } from "./includes/Custom-button";
import { actionBtnTypes, DataTable } from "../../../ui-components/ui-elements/table/DataTable";
import { getWebPortalNewsAPI } from "../../../../config/apiUrl.config";
import { NewsForm, RemoveFrom } from "./includes/ManageNews";
import { dateObjectToString } from "../../../../helpers/common-helpers/dateTime.helpers";
import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { SearchElement, ViewWebNews } from "../pages/includes/ViewWebNews";
import { getDataByFormObject, isEmptyValue } from "../../../../helpers/common-helpers/common.helpers";
import { permissions } from "../../../../config/permission.config";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const emptyFn = (...para) => undefined;

const StatusCel = (props) => {
    return <p>{_get(props, 'row.status', 0) === 1 ? "Active" : "Inactive"}</p>
}

const DateCel = (props) => {
    return <p>{dateObjectToString(props.row[_get(props, 'header.key', 'date')], "dd/mm/YY")}</p>
}

const WebNews = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <DashboardTemplate
            pageTitle="Manage Web News"
            breadCrumbList={[
                { urlTo: "/", displayName: "Home" },
                { urlTo: "", displayName: "Other" },
                { urlTo: "", displayName: "Manage Web News" },
            ]}
        >
            <DataTable
                tableName={"Web News"}
                isSetSearchFrom={true}
                apiUrl={getWebPortalNewsAPI.url}
                dataTableKey={getWebPortalNewsAPI.key}
                isSetAction={true}
                defaultSearchFormObject={{
                    "heading_search": "",
                    "start_date_search": null,
                    "end_date_search": null,
                    "status_search": null,
                    "code_search": ""
                }}
                searchFromComponent={
                    <SearchElement dataTableKey={getWebPortalNewsAPI.key} />
                }
                onSetSearchObjectFn={(searchObject) => {
                    let tempSearchObject = { ...searchObject };

                    if (!isEmptyValue(searchObject.start_date_search)) {
                        tempSearchObject["start_date_search"] = dateObjectToString(searchObject.start_date_search, "YY-mm-dd");
                    }

                    if (!isEmptyValue(searchObject.end_date_search)) {
                        tempSearchObject["end_date_search"] = dateObjectToString(searchObject.end_date_search, "YY-mm-dd");
                    }
                    return tempSearchObject;
                }}


                tableHeaderList={[
                    { displayName: "News Code", key: "code", isSort: false },
                    { displayName: "News Heading", key: "heading_english", isSort: false },
                    { displayName: "Start Date", key: "start_date", isSort: false },
                    { displayName: "End Date", key: "end_date", isSort: false },
                    { displayName: "Status", key: "status", isSort: false },
                ]}
                actionTypeList={[
                    {
                        actionType: actionBtnTypes.create,
                        actionBtn: {
                            "permissions": permissions.NEWS_ADD.permissions
                        },
                        modelContentElement: NewsForm,
                        dialogPopupProps: {
                            isFullScreen: true,
                            dialogTitle: "Add News",
                            isValidate: true,
                            isConfirmationPopup: true,
                            closeButton: {
                                btnText: "Cancel",
                                startIcon: 'far fa-times-circle'
                            },
                            isCustomButton: true,
                            customButtonComponent: CustomButton,
                            customButtonComponentProps: {
                                "uiType": "create",
                                dataTableKey: getWebPortalNewsAPI.key,
                            },
                        },
                        parentToModelProps: {
                            formKey: getWebPortalNewsAPI.key + "_create",
                            dataTableKey: getWebPortalNewsAPI.key,
                            uiType: "create"
                        }

                    },
                    {
                        actionType: actionBtnTypes.view,
                        actionBtn: { tooltip: "View", "permissions": permissions.NEWS_VIEW.permissions },
                        modelContentElement: ViewWebNews,
                        dialogPopupProps: {
                            isFullScreen: true,
                            isSaveButton: false,
                            isCloseButton: true,
                            dialogTitle: "View News",
                        },
                    },
                    {
                        actionType: actionBtnTypes.edit,
                        actionBtn: { tooltip: 'Edit', "permissions": permissions.NEWS_EDIT.permissions },
                        modelContentElement: NewsForm,
                        dialogPopupProps: {
                            isFullScreen: true,
                            dialogTitle: "Edit News",
                            isCustomButton: true,
                            isValidate: true,
                            isConfirmationPopup: true,
                            closeButton: {
                                btnText: "Cancel",
                                startIcon: 'far fa-times-circle'
                            },
                            customButtonComponent: CustomButton,
                            customButtonComponentProps: {
                                "uiType": "edit",
                                dataTableKey: getWebPortalNewsAPI.key,
                            }
                        },
                        parentToModelProps: {
                            formKey: getWebPortalNewsAPI.key + "_edit",
                            dataTableKey: getWebPortalNewsAPI.key,
                            uiType: "edit"
                        },
                    },
                    {
                        actionType: actionBtnTypes.delete,
                        actionBtn: { tooltip: 'Remove', "permissions": permissions.NEWS_DELETE.permissions },
                        callAPiPara: {
                            method: "delete",
                            dataStoringType: "DATA_TABLE_LINKED_FORM",
                            dataStoringKeys: {
                                "dataTable": getWebPortalNewsAPI.key,
                                "form": getWebPortalNewsAPI.key + "_remove",
                            },
                        },
                        isValidate: false,
                        modelContentElement: RemoveFrom,
                        getAPIUrlFn: (data) => {
                            return {
                                "url": getWebPortalNewsAPI.url + data.row.id,
                                "key": getWebPortalNewsAPI.key
                            }
                        },
                        getRequestBodyFN: (formData) => { return getDataByFormObject(formData.formObject) },
                        dialogPopupProps: {
                            customButtonComponent: CustomButton,
                            closeButton: {
                                btnText: "No",
                                startIcon: "fas fa-times",
                            },
                            isCustomButton: true,
                            isFullScreen: false,
                            dialogTitle: "Please confirm",
                            customButtonComponentProps: {
                                "uiType": "remove",
                                dataTableKey: getWebPortalNewsAPI.key,
                            }
                        },
                        parentToModelProps: {
                            formKey: getWebPortalNewsAPI.key + "_remove",
                            dataTableKey: getWebPortalNewsAPI.key,
                            uiType: "remove"
                        },
                    }

                ]}
                specificComponents={{
                    status: StatusCel,
                    start_date: DateCel,
                    end_date: DateCel
                }}
            />

        </DashboardTemplate>
    );
};



export default WebNews;