import React, { Fragment } from 'react';

import { permissions } from '../../../config/permission.config';
import { Navigate } from '../../ui-components/ui-elements/common/Navigate';
import ReasonDetails from './pages/ReasonDetails';
import authorized from '../../hoc-middlewares/Authorized';

const ReasonRoutes = () => {
    return (
        <Fragment>
            <Navigate
                exact={true} path="/reasons"
                routeKey={"reasons"}
                component={authorized(ReasonDetails)}
                routePermissions={permissions.REASON_MANAGE.permissions}
            />
        </Fragment>
    )
};

export default ReasonRoutes;