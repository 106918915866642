import React, { Fragment } from "react";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { FormWrapper, InputBoxWithState, SelectBoxWithState, SubmitButton } from "../../../ui-components/ui-elements/form";
import { actionBtnTypes, apiResponseStoringType, DataTable } from "../../../ui-components/ui-elements/table/DataTable";
import { View } from "./includes/View";
import { FormElement } from "./includes/FormElement";
import { userRolesAPI } from "../../../../config/apiUrl.config";
import { getDataByFormObject } from "../../../../helpers/common-helpers/common.helpers";
import { permissions } from "../../../../config/permission.config";
import { useEffect } from "react";




const UserRole = () => {
    const formKey = userRolesAPI.key;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <DashboardTemplate
            pageTitle="Manage User Role"
            breadCrumbList={[
                { urlTo: "/", displayName: "Home" },
                { urlTo: "", displayName: "User Management" },
                { urlTo: "", displayName: "Manage User Roles" },
            ]}
        >
            <DataTable
                tableName={"User Roles"}
                dataTableKey={userRolesAPI.key}
                isSetAction={true}
                isSetSearchFrom={true}
                searchFromComponent={
                    <SearchElement formKey={userRolesAPI.key} />
                }
                defaultSearchFormObject={{
                    "prefix": "",
                    "active": null,
                    "name": "",
                }}
                apiUrl={userRolesAPI.url + "?sort_by=prefix|asc"}
                tableHeaderList={[
                    { displayName: "User Role Prefix", key: "prefix", isSort: false },
                    { displayName: "User Role Name", key: "name", default: "-", isSort: false },
                    {
                        displayName: "Status",
                        key: "status",
                        isSort: false,
                        default: "-",
                        onPrintFn: ({ active }) => (parseInt(active) === 1 ? "Active" : "Inactive")
                    },
                ]}
                actionTypeList={[
                    {
                        actionType: actionBtnTypes.create,
                        actionBtn: { "permissions": permissions.USER_ROLE_ADD.permissions },
                        modelContentElement: FormElement,
                        dialogPopupProps: {
                            isFullScreen: false,
                            dialogTitle: "Add User Role",
                            closeButton: {
                                btnText: "Cancel",
                                startIcon: "far fa-times-circle",
                            },
                            isCustomButton: true,
                            isConfirmationPopup: true,
                            customButtonComponent: CustomSaveButton,
                            customButtonComponentProps: {
                                uiType: "add",
                                btnText: "Save",
                            },
                        },
                        parentToModelProps: {
                            formKey: formKey + "_add",
                            type: "add"
                        },
                    }, {
                        actionType: actionBtnTypes.view,
                        actionBtn: {   tooltip: "View", "permissions": permissions.USER_ROLE_VIEW.permissions },
                        modelContentElement: View,
                        dialogPopupProps: {
                            isFullScreen: false,
                            dialogTitle: "View User Role",
                        },
                        parentToModelProps: {
                            formKey: formKey + "_view",
                            type: "view"
                        },
                    },
                    {
                        actionType: actionBtnTypes.edit,
                        modelContentElement: FormElement,
                        actionBtn: { tooltip: "Edit User Role", "permissions": permissions.USER_ROLE_EDIT.permissions },
                        dialogPopupProps: {
                            isFullScreen: false,
                            dialogTitle: "Edit User Role",
                            closeButton: {
                                btnText: "Cancel",
                                startIcon: "far fa-times-circle",
                            },
                            isCustomButton: true,
                            isConfirmationPopup: true,
                            customButtonComponent: CustomSaveButton,
                            customButtonComponentProps: {
                                uiType: "edit",
                                btnText: "Update",
                            },
                        },
                        parentToModelProps: {
                            formKey: formKey + "_edit",
                            type: "edit"
                        },
                    },
                ]}
            />
        </DashboardTemplate>
    );
};
const SearchElement = (dataTableKey = "") => {

    const formKey = `${dataTableKey.formKey}_search`
    return (
        <FormWrapper setGroupName={formKey}>
            <div className="row">
                <div className={"col-md-2 floatLeft"}>
                    <InputBoxWithState
                        labelText={"Prefix"}
                        formGroupName={formKey}
                        inputName={"prefix"}
                        inputPlaceholder={"Prefix"}
                        maxLength = "50"
                    />
                </div>
                <div className={"col-md-2 floatLeft"}>
                    <InputBoxWithState
                        labelText={"Name"}
                        formGroupName={formKey}
                        inputName={"name"}
                        inputPlaceholder={"Name"}
                        maxLength= "50"
                    />
                </div>
                <div className={"col-md-2 floatLeft"}>
                    <SelectBoxWithState
                        labelText={"Status"}
                        formGroupName={formKey}
                        inputName={"active"}
                        isEnableAllOption={true}
                        dataList={[{ "id": true, "value": "Active" }, { "id": false, "value": "Inactive" }]}
                    />
                </div>
            </div>
        </FormWrapper>
    );
};

const emptyFn = (...para) => undefined;

const CustomSaveButton = ({
    onClosePopupFn = emptyFn,
    btnText = "",
    uiType = "",
}) => {
    return (
        <Fragment>
            <SubmitButton
                btnText={btnText}
                startIcon={uiType === "add" ? "far fa-save" : "far fa-edit"}
                elementStyle="btnWrapper"
                formGroupName={
                    uiType === "add"
                        ? `${userRolesAPI.key}_add`
                        : `${userRolesAPI.key}_edit`
                }
                dataTableKey={userRolesAPI.key}
                isValidate={true}
                mapValidationErrorObj={{
                    prefix: "User Role Prefix already exist",
                    role_category_code:"Role Category is required"
                  }}
                validationObject={{
                    fileds: {
                        name: "User Role Name",
                        role_type: "Role Type",
                        role_level_code: "Role Level",
                        status: "Status",
                        prefix: "User Role Prefix",
                        role_category_code:"Role Category"
                    },
                    rules: {
                        name: "required|max:100",
                        role_type: "required",
                        role_level_code: "required",
                        status: "required",
                        prefix: "required|alphabet",
                        role_category_code: "required",
                    },
                }}
                flashMessages={{
                    success: {
                        status: true,
                        message: `User Role is successfully ${uiType === "add" ? 'saved' : 'updated'}.`,
                        messageType: "success",
                    },
                }}
                onGetAPIEndPointFn={(formState) => {
                    return {
                        url:
                            uiType === "add"
                                ? `${userRolesAPI.url}`
                                : `${userRolesAPI.url}/${formState.id}`,
                        key: userRolesAPI.key
                    }
                }}
                onChangeRequestBodyFn={(formObject) => {
                    return getDataByFormObject({
                        ...formObject,
                        active: parseInt(formObject.status) === 1,
                    });
                }}
                callApiObject={{
                    isSetHeaders: true,
                    method: uiType === "add" ? "post" : "put",
                }}
                apiDataStoringObject={{
                    setLoader: true,
                    storingType: apiResponseStoringType.dateTableLinkedForm,
                }}
                onResponseCallBackFn={(error, response) => {
                    if (!error) {
                        onClosePopupFn();
                    }

                }}
            />
        </Fragment>
    )
}
const CustomUpdateButton = ({ }) => {
    return (
        <Fragment>
            <SubmitButton
                btnText="Update"
                elementStyle="btnWrapper"
            />
        </Fragment>
    )
}
export { UserRole };