/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-09-08 16:06:43 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-16 11:16:25
 */
import React,{useState} from 'react';
import {CounterTemplate} from "./../../../ui-components/templates/CounterTemplate.js";

import {TabsWrapper,SubTab,tabsWrapperVariants,tabsWrapperScrollButtons} from "../../../ui-components/ui-elements/tab/Tabs";
import {NormalLicense} from "./includes/NormalLicense";
import {DealerLicense} from "./includes/DealerLicense";
import {InputButton} from "../../../ui-components/ui-elements/form/Button";

const Footer=()=>{
    return(
        <div className="row">
            <div className="col-md-6">
                <InputButton
                    elementWrapperStyle={'defaultHalfMarginLeftRight'}
                    btnName="submit"
                    btnText={"Activity Log"}
                />
                <InputButton
                    elementWrapperStyle={'defaultHalfMarginLeftRight'}
                    btnName="submit"
                    btnText={"View Documents"}
                />
            </div>
            <div className="col-md-6 text-right">
                <InputButton
                    elementWrapperStyle={'defaultHalfMarginLeftRight'}
                    btnName="submit"
                    btnText={"Pay Arrears Only"}
                />
                <InputButton
                    elementWrapperStyle={'defaultHalfMarginLeftRight'}
                    btnName="submit"
                    btnText={"Issue RL"}
                />
                <InputButton
                    elementWrapperStyle={'defaultHalfMarginLeftRight'}
                    btnName="submit"
                    btnText={"Cancel"}
                />
            </div>
        </div>
    )
}

 const TestUI=()=>{
     const [getState,setState]=useState({
       "currentTab":"tab1",
       "isShowFooter":false,  
     });

     const setShowHideFooterFN=(status=true)=>{
        setState({
            ...getState,
            isShowFooter:status
        });
     }

     return (
         <CounterTemplate
            FooterComponent={Footer}
            isShowFooter={getState.isShowFooter}            
         >
            <TabsWrapper
                onChangeFn={(event)=>setState({
                    ...getState,
                    "currentTab":event.value
                })}
                active={getState.currentTab}
                variant={tabsWrapperVariants.scrollable}
                scrollButtons={tabsWrapperScrollButtons.on}
            >
                <SubTab tabid={"tab1"} header={"Normal License"}>
                    <NormalLicense setShowHideFooterFN={(status)=>setShowHideFooterFN(status)} />
                </SubTab>  
                <SubTab tabid={"tab2"} header={"Dealer License"}>
                    <DealerLicense/>
                </SubTab> 
                <SubTab tabid={"tab3"} header={"Quotation"}>
                    asdasd
                </SubTab> 
                <SubTab tabid={"tab4"} header={"Author Tab"}>
                    asdasd
                </SubTab> 
            </TabsWrapper>
         </CounterTemplate>
     )
 }

 export default TestUI;