/*
 * @Author: Sujith
 * @Date: 2020-12-22 10:11:46
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-03-08 14:42:10
 */
import React, { Fragment, useContext } from "react";

import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import {
  DataTable,
  actionBtnTypes,
} from "../../../ui-components/ui-elements/table/DataTable";
import { SubmitButton } from "../../../ui-components/ui-elements/form";
import { Form } from "./includes/DeliveryChargeForm";
import { View, setThousandSeparator } from "./includes/DeliveryChargeview";
import {
  deliveryChargeEditApi,
  deliveryChargeListApi,
} from "../../../../config/apiUrl.config";
import { getDataByFormObject } from "../../../../helpers/common-helpers/common.helpers";
import { apiResponseStoringType } from "../../../../config/apiResponseKey";
import { SearchElement } from "./includes/SearchElement";
import { AuthContext } from "../../core/context-providers/AuthContext.provider";
import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { RoleLevelCode } from "../../../../config/enum.config";
import { CheckPermission } from "../../../ui-components/ui-elements/common/BaseElements";
import { permissions } from "../../../../config/permission.config";
import { useEffect } from "react";

const CustomButton = (props) => {
  return (
    <Fragment>
      <CheckPermission
        permission={permissions.DELIVERY_CHARGE_EDIT.permissions}
      >
        <SubmitButton
          btnText="Update"
          startIcon={"far fa-edit"}
          elementStyle="btnWrapper"
          formGroupName={`${deliveryChargeListApi.key}_edit`}
          dataTableKey={deliveryChargeListApi.key}
          isValidate={true}
          mapValidationErrorObj={{
            charge: "Charge is required."
          }}
          validationObject={{
            fileds: {
              charge: "Charge",
              active: "Status",
            },
            rules: {
              charge: "requiredIf:active,true",
              active: "required",
            },
            message: {
              "charge.requiredIf": "Charge is required.",
            },
          }}
          flashMessages={{
            success: {
              status: true,
              message: "Delivery charge is successfully updated.",
              messageType: "success",
            },
          }}
          onGetAPIEndPointFn={(formState) => {
            return {
              url: `${deliveryChargeEditApi.url}/${formState.province}/delivery-charges/${formState.id}`,
              key: deliveryChargeEditApi.key,
            };
          }}
          onChangeRequestBodyFn={({ ...formObject }) => {
            const object = {
              active: formObject.active,
              charge: formObject.charge,
            };
            return getDataByFormObject({
              ...formObject,
              active: parseInt(formObject.active) === 1 ? true : false,
            });
          }}
          callApiObject={{
            isSetHeaders: true,
            method: "put",
          }}
          apiDataStoringObject={{
            setLoader: true,
            storingType: apiResponseStoringType.dateTableLinkedForm,
          }}
          onResponseCallBackFn={(response, error) => {
            if (
              response.length === undefined &&
              error === undefined &&
              response.data.errors === undefined
            ) {
              props.onClosePopupFn();
            }
          }}
        />
      </CheckPermission>

    </Fragment>
  );
};

const DeliveryCharge = () => {
  const formKey = "testInuts";
  const [authStatus, authAction] = useContext(AuthContext);
  const roleLevelCode = _get(authStatus, "authUser.role_level_code", null);
  const province_id = roleLevelCode == RoleLevelCode.ADN_NTL ? RoleLevelCode.ADN_NTL : _get(authStatus, "authUser.pdmt_id", "");

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  return (
    <DashboardTemplate
      pageTitle="Manage Delivery Charge – Web Revenue Licence"
      breadCrumbList={[
        { urlTo: "/", displayName: "Home" },
        { urlTo: "", displayName: "Web RL Deliver" },
        { urlTo: "", displayName: "Delivery Charge" },
      ]}
    >
      <DataTable
        tableName={"Delivery Charges"}
        apiUrl={`${deliveryChargeListApi.url}/${province_id}/delivery-charges?sort_by=name|asc`}
        dataTableKey={deliveryChargeListApi.key}
        isSetAction={true}
        defaultSearchFormObject={{
          delivery_method: "",
          active: null,
        }}
        isSetSearchFrom={true}
        searchFromComponent={
          <SearchElement dataTableKey={deliveryChargeListApi.key} />
        }
        actionTypeList={[
          {
            actionType: actionBtnTypes.view,
            actionBtn: {  tooltip: "View", "permissions": permissions.DELIVERY_CHARGE_VIEW.permissions },
            modelContentElement: View,
            dialogPopupProps: {
              isFullScreen: false,
              isSaveButton: false,
              dialogTitle: "View Delivery Charge",
            },
            parentToModelProps: {
              formKey: deliveryChargeEditApi.key + "_view",
              province_id: province_id
            },
          },
          {
            actionType: actionBtnTypes.edit,
            actionBtn: { tooltip: "Edit", "permissions": permissions.DELIVERY_CHARGE_EDIT.permissions },
            modelContentElement: Form,
            dialogPopupProps: {
              isFullScreen: false,
              isSaveButton: false,
              isCustomButton: true,
              customButtonComponent: CustomButton,
              dialogTitle: "Edit Delivery Charge",
              closeButton: {
                btnText: "Cancel",
                startIcon: "far fa-times-circle",
              },
              isConfirmationPopup: true,
            },
            parentToModelProps: {
              formKey: deliveryChargeEditApi.key + "_edit",
              dataTableKey: deliveryChargeEditApi.key,
              province_id: province_id
            },
            checkBeforeActionRenderFn: (rawData) => {
              return roleLevelCode == RoleLevelCode.ADN_NTL ? false : true;
            }
          }
        ]}
        tableHeaderList={[
          {
            displayName: "Delivery Method",
            key: "delivery_method.name",
            default: "-",
            isSort: false,
          },
          {
            displayName: "Charges (LKR) ",
            key: "charge",
            isSort: false,
            default: "-",
            onPrintFn: ({ charge }) => setThousandSeparator(charge, 2),
          },
          {
            displayName: "Status",
            key: "active",
            isSort: false,
            onPrintFn: ({ active }) => (active == 1 ? "Active" : "Inactive"),
          },
        ]}
      />
    </DashboardTemplate>
  );
};

export default DeliveryCharge;
