/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-07-14 10:18:15
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2021-07-22 09:36:53
 */

import React from "react";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import {
  DataTable,
  actionBtnTypes,
} from "../../../ui-components/ui-elements/table/DataTable";
import { DeliveryMethodForm } from "./includes/DeliveryMethodForm";
import { ViewDeliveryMethod } from "./includes/ViewDeliveryMethods";
import { SearchElement } from "./includes/SearchElement";
import { CustomButton } from "./includes/CustomButton";
import { deliveryMethodCreateApi, deliveryMethodEditApi, deliveryMethodListApi } from "../../../../config/apiUrl.config";
import { permissions } from "../../../../config/permission.config";
import { useEffect } from "react";

const ManageDeliveryMethods = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
    <DashboardTemplate
      pageTitle="Manage Delivery Method"
      breadCrumbList={[
        { urlTo: "/", displayName: "Home" },
        { urlTo: "", displayName: "Web RL Deliver" },
        { urlTo: "", displayName: "Manage Delivery Method" },
      ]}
    >
      <DataTable
        tableName="Delivery Methods"
        apiUrl={`${deliveryMethodListApi.url}?sort_by=created_at|desc`}
        dataTableKey={deliveryMethodListApi.key}
        isSetSearchFrom={true}
        defaultSearchFormObject={{
          "name": "",
          "active": null
        }}
        searchFromComponent={
          <SearchElement dataTableKey={deliveryMethodListApi.key} />
        }
        isSetAction={true}
        actionTypeList={[
          {
            actionType: actionBtnTypes.create,
            actionBtn: {
              "permissions": permissions.DELIVERY_METHOD_ADD.permissions
            },
            modelContentElement: DeliveryMethodForm,
            dialogPopupProps: {
              isFullScreen: false,
              isSaveButton: false,
              isCustomButton: true,
              customButtonComponent: CustomButton,
              customButtonComponentProps: { btnText: "Save", uiType: "add" },
              dialogTitle: "Add Delivery Method",
              closeButton: {
                btnText: "Cancel",
                startIcon: "far fa-times-circle",
              },
              isConfirmationPopup: true,
            },
            parentToModelProps: {
              formKey: deliveryMethodCreateApi.key + "_create",
              dataTableKey: deliveryMethodCreateApi.key,
            },
          },
          {
            actionType: actionBtnTypes.view,
            actionBtn: {  tooltip: "View", "permissions": permissions.DELIVERY_METHOD_VIEW.permissions },
            modelContentElement: ViewDeliveryMethod,
            dialogPopupProps: {
              isFullScreen: false,
              isSaveButton: false,
              dialogTitle: "View Delivery Method",
            },
          },
          {
            actionType: actionBtnTypes.edit,
            actionBtn: {
              "permissions": permissions.DELIVERY_METHOD_EDIT.permissions
            },
            modelContentElement: DeliveryMethodForm,
            dialogPopupProps: {
              isFullScreen: false,
              isCustomButton: true,
              customButtonComponent: CustomButton,
              customButtonComponentProps: { btnText: "Update", uiType: "Edit" },
              dialogTitle: "Edit Delivery Method",
              closeButton: {
                btnText: "Cancel",
                startIcon: "far fa-times-circle",
              },
              isConfirmationPopup: true,
            },
            parentToModelProps: {
              formKey: deliveryMethodEditApi.key + "_edit",
              dataTableKey: deliveryMethodEditApi.key,
            },
          }
        ]}
        tableHeaderList={[
          {
            displayName: "Delivery Method",
            key: "name",
            isSort: false,
          },
          {
            displayName: "Status",
            key: "active",
            isSort: false,
            onPrintFn: ({ active }) => active === 1 ? "Active" : "Inactive",
          },
        ]}
      />
    </DashboardTemplate>
  );
};

export default ManageDeliveryMethods;
