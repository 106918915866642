/*
 * @Author: Sujith
 * @Date: 2020-09-28 11:11:46 
 * @Last Modified by: Sujith
 * @Last Modified time: 2020-09-28 11:12:43
 */
import React, { Fragment } from 'react';

import { Navigate } from "../../ui-components/ui-elements/common/Navigate";
import { permissions } from "../../../config/permission.config";
import ManageFineConfigurations from "./pages/ManageFineConfigurations";
import authorized from '../../hoc-middlewares/Authorized';


const FineConfigurationsRoutes = () => {
    return (
        <Fragment>
            <Navigate exact={true} path="/fine-configurations" routeKey={"fine-configurations"} component={authorized(ManageFineConfigurations)} routePermissions={permissions.FINE_CONFIG_MANAGE.permissions} />
        </Fragment>
    )
};

export default FineConfigurationsRoutes;
