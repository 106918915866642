import React, { Fragment, useContext } from "react";

import { AuditDetail, UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { FormWrapper } from "../../../../ui-components/ui-elements/form";
import { DataTable } from "../../../../ui-components/ui-elements/table/DataTable";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { thousandSeparator, } from "../../../../../helpers/common-helpers/common.helpers";

const formKey = "generalConf";

const ConfigView = (props) => {

    switch (_get(props, "row.configuration_code", null)) {
        case "RNW_LITY":
            return (<RenePerForLicTyp props={props}
                name="View Renewable Period for Licence Types"
                fieldName="Days from the 1 st Registration Date"
                fineCalculationType="Exclude Fine calculation for"
                names={_get(props, `row.array`, '')}
            />);

        case "VET_FSTR":
            return (<PopupForm props={props}
                name="View Fine Exemption Days for Vehicle 1st Registration"
                fineCalculationType="Days from the 1st Registration Date"
                value_type={_get(props, `row.value_type`, '')}
                value={_get(props, `row.value`, '')}
            />);
        case "FIN_EXMPT":
            return (<PopupForm props={props}
                name="View Fine Exemption Days for Licence Renewal"
                fineCalculationType="Days from the Current Revenue Licence Expiry Date"
                value_type={_get(props, `row.value_type`, '')}
                value={_get(props, `row.value`, '')}
            />);
        case "LCN_NUSR":
            return (<PopupForm props={props}
                name="View Licence Type Change to Non-User"
                calculationPrefix="Issue Non-User only till"
                fineCalculationType="Days from the Normal/Temp Licence Expiry Date"
                value_type={_get(props, `row.value_type`, '')}
                value={_get(props, `row.value`, '')}
            />);
        case "VET_EXMPT":
            return (<PopupForm props={props}
                name="View VET Certificate Exemption – Manufactured Year"
                calculationPrefix="Exclude Validating VET certificate for Vehicles in which the Manufactured Year is On or Before"
                value_type={_get(props, `row.value_type`, '')}
                value={_get(props, `row.value`, '')}
            />);
        case "LCN_WARR":
            return (<PopupForm props={props}
                name="View Workflow Configuration – Licence with Arrears"
                calculationPrefix="Restrict issuing process for Normal /Non-User Licence with Arrears which have more than"
                fineCalculationType="Years of Arrears"
                value_type={_get(props, `row.value_type`, '')}
                value={_get(props, `row.value`, '')}
            />);
        case "WEB_MESG":
            return (<SimplePopupForm props={props}
                name="View Online Temporary Licence Receipt - Message Content"
                calculationPrefix="Message:"
                value_type={_get(props, `row.value_type`, '')}
                value={_get(props, `row.value`, '')}
            />);
        case "SER_CHRG":
            return (<SimplePopupForm props={props}
                name="View Service Charge Configuration "
                calculationPrefix="Service charge (LKR):"
                value_type={_get(props, `row.value_type`, '')} 
                value={thousandSeparator(_get(props, `row.value`, ''), 2)}
            />);
        case "TMP_RENW":
            return (<RenewablePeriodTemporaryLicence
                value={_get(props, "row.value", "")}
            />);

        default:
            return null;
    }
};
const RenePerForLicTyp = (props) => {
    const licenceType = props.names
    return (
        <Fragment>
                <FormWrapper
                    setGroupName={formKey}
                >
                    <DataTable
                        isSetSearchFrom={false}
                        isSetTableFooter={false}
                        // apiUrl={ generalConfigAPI.url}
                        dataTableKey={'generalConfigDataTable'}
                        tableHeaderList={[
                            { displayName: "Licence Type", key: "licence_type.name", isSort: false },
                            { displayName: "Calendar Months", key: "value", isSort: false },
                        ]}
                        tableBodyList={licenceType}
                        isSetAction={false}
                        isSetTableHeader={false}
                    />
                </FormWrapper>
            {/* <AuditDetail
                labelCol={6}
                createdById={_get(props, `names[0].created_by`, "")}
                createdLocationId={_get(props, `names[0].created_location`, "")}
                createdAt={dateObjectToString(_get(props, `names[0].created_at`, ""), "dd/mm/YY HH:MM")}
                createdRoleId={_get(props, `names[0].created_role_id`, '-')}
                updatedById={_get(props, `names[0].updated_by`, "")}
                updatedLocationId={_get(props, `names[0].created_location`, "")}
                updatedAt={dateObjectToString(_get(props, `names[0].updated_at`, ""), "dd/mm/YY HH:MM")}
                updatedRoleId={_get(props, `names[0].updated_role_id`, '-')}
            /> */}
        </Fragment>
    );
};
const PopupForm = ({
    props = "",
    name = "",
    fineCalculationType = "",
    value = "",
    value_type = "",
    calculationPrefix = "Exclude Fine calculation for",
}) => {

    const typeVal = (type) => {
        if (type === "CAL") {
            return "Calendar"
        }
        else if (type === "WRK") {
            return "Working"
        }
        else {
            return ""
        }
    }

    const [formState, formAction] = useContext(FormContext);
    return (
        < Fragment >
            <UICard>
                <div className="row">
                    <div className="col floatLeft defaultPaddingTop">
                        <p>{calculationPrefix}  <strong>{value} {value_type !== "NOY" ? typeVal(value_type) : null}</strong> {fineCalculationType}.</p>
                        {/* <p> {calculationPrefix} <strong>{_get(formState[formKey],"daysCount","")}  {_get(formState[formKey],"daysType","")}</strong> {fineCalculationType}.</p> */}
                    </div>
                </div>
                <AuditDetail
                    labelCol={6}
                    createdById={_get(props, `row.created_by`, "")}
                    createdLocationId={_get(props, `row.created_location`, "")}
                    createdAt={dateObjectToString(_get(props, `row.created_at`, ""), "dd/mm/YY HH:MM")}
                    createdRoleId={_get(props, `row.created_role_id`, '')}
                    updatedById={_get(props, `row.updated_by`, "")}
                    updatedLocationId={_get(props, `row.updated_location`, "")}
                    updatedAt={dateObjectToString(_get(props, `row.updated_at`, ""), "dd/mm/YY HH:MM")}
                    updatedRoleId={_get(props, `row.updated_role_id`, '')}
                />

            </UICard>
        </Fragment >
    );
};

const SimplePopupForm = ({
    name = "",
    value_type = "",
    calculationPrefix = "",
    value = "",
    props = "",
}) => {
    return (
        < Fragment >
            <UICard>
                <FormWrapper
                    setGroupName={formKey}
                >
                    <div className="row">
                        <div className="col floatLeft defaultPaddingLeft">
                            <p> {calculationPrefix} <strong>{value}</strong>  .</p>
                        </div>
                    </div>
                    <AuditDetail
                        labelCol={6}
                        createdById={_get(props, `row.created_by`, "")}
                        createdLocationId={_get(props, `row.created_location`, "")}
                        createdAt={dateObjectToString(_get(props, `row.created_at`, ""), "dd/mm/YY HH:MM")}
                        createdRoleId={_get(props, `row.created_role_id`, '-')}
                        updatedById={_get(props, `row.updated_by`, "")}
                        updatedLocationId={_get(props, `row.updated_location`, "")}
                        updatedAt={dateObjectToString(_get(props, `row.updated_at`, ""), "dd/mm/YY HH:MM")}
                        updatedRoleId={_get(props, `row.updated_role_id`, '-')}
                    />
                </FormWrapper>
            </UICard>
        </Fragment >
    );
};

const RenewablePeriodTemporaryLicence = (props) => {
    return (
        <Fragment>
            <UICard>
                <p>Renewable period for Temporary Licence is <strong>{props.value} month(s)</strong>.</p>
            </UICard>
        </Fragment>
    );
};

export { ConfigView };