import React from "react";

import { AddVFTCompany } from "./AddVFTCompany";
import { AuditDetail } from "../../../../ui-components/ui-elements/common/BaseElements";
import { Fragment } from "react";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { CustomButtonAdd } from "../VFTCompany";
import { vftListAPI } from "../../../../../config/apiUrl.config";

const CompanyInfoUI = (props) => {

  return (
    <div>
      <div className="row">
        <div className="col-md-12 ">
          <AddVFTCompany
            row={{ ...props.row }}
            fromParent={{ uiType: "edit" }}
          />
          {/* props={props} */}
        </div>
        <Fragment>
          <div className="col-12">
            <AuditDetail
              createdAt={dateObjectToString(_get(props, `row.service.created_at`, ""),"dd/mm/YY HH:MM")}
              createdById={_get(props, `row.service.created_by`, "")}
              createdLocationId={_get(props, `row.service.created_location`, "")}
              createdRoleId={_get(props, `row.service.created_role_id`, '')}
              updatedAt={dateObjectToString(_get(props, `row.service.updated_at`, ""),"dd/mm/YY HH:MM")}
              updatedById={_get(props, `row.service.updated_by`, "")}
              updatedRoleId={_get(props, `row.service.updated_role_id`, '')}
              updatedLocationId={_get(props, `row.service.updated_location`, "")}
              labelCol={4}
            />
          </div>

        </Fragment>
      </div>
      <div className="floatRight defaultPaddingTopBottom">
          <CustomButtonAdd
            btnText={"Update"}
            uiType={"EDIT"}
            dataTableKey={vftListAPI.key}
            formGroupName="vftListAPIKey_edit"
          />
      </div>
    </div>

  );
}

export { CompanyInfoUI }