import React, { Fragment } from 'react';

import { permissions } from '../../../config/permission.config';
import { Navigate } from '../../ui-components/ui-elements/common/Navigate';
import {VFTCompany} from './pages/VFTCompany';
import authorized from "../../hoc-middlewares/Authorized";

const VFTCompanyRoutes = () => {
    return (
        <Fragment>
            <Navigate exact={true} path="/vft-company" routeKey={"vft-company"} component={authorized(VFTCompany)} routePermissions={permissions.VFT_COMPANY_MANAGE.permissions} />
        </Fragment>
    )
};

export default VFTCompanyRoutes;