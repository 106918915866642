/*
 * @Author: Unknown
 * @Date: 2021-01-04 10:55:57
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-30 15:59:01
 */
import React, { useContext, useEffect } from "react";
import {
  FormWrapper,
  SelectBoxWithState,
  InputBoxWithState,
  CheckBoxWithState,
} from "../../../../ui-components/ui-elements/form";
import { templateTypes } from "../../../../../config/template.config";
import { _find, _findindex, _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { authoritiesListAPI } from "../../../../../config/apiUrl.config";
import { authorityChangeFn, operationTypeAllowedEditFn, operationTypeAllowedFn } from "../../../../../helpers/common-helpers/setAuthorityPrefix.helpers";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";

const Form = (props) => {
  const formKey = `${props.fromParent.formKey}`;
  const uiType = `${props.fromParent.uiType}`;
  const [formState, formAction] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);
  const pdmt_id = _get(authStatus, "authUser.pdmt_id", "");  

  useEffect(() => {
      if(uiType === 'edit'){ 
        operationTypeAllowedFn(
          {value: _get(props, "row.authority_id", null)},
          formAction,
          `${props.fromParent.formKey}`
        );
        operationTypeAllowedEditFn(
          {authority_id: _get(props, "row.authority_id", null), counter_id: _get(props, "row.id", "") },
          formAction,
          `${props.fromParent.formKey}`
        );
      }
  }, []);
  const allowedOperationTypes = _get(formState, `${formKey}.operation_type`, []);
  const allowedLicenceTypes = _get(formState, `${formKey}.licence_type`, []);
  
  return (
    <UICard>
      <FormWrapper
        setGroupName={`${props.fromParent.formKey}`}
        formGroupLinkWith={`${props.fromParent.formKey}`}
        setFormObject={{
          id: _get(props, "row.id", ""),
          authority_id: _get(props, "row.authority_id", ""),
          authority_type: _get(props, "row.authority_authority_type_code", ""),
          name: _get(props, "row.name", ""),
          code: _get(props, "row.code", ""),
          active: _get(props, "row.active", 1),
          prefix: _get(props, "row.authority_prefix", ""),
          transacted: _get(props, "row.counters_transacted", false),
        }}
        onDestroyUnsetFormObject={true}
      >
          <div>
            {uiType === "add" ? (
              <SelectBoxWithState
                uiType={templateTypes.bootstrap}
                formGroupName={`${props.fromParent.formKey}`}
                isRequired={true}
                apiUrl={`${authoritiesListAPI.url}?pdmt_id=${pdmt_id}`}
                apiStateKey={authoritiesListAPI.key}
                keyName={"id"}
                valueName={"service.name"}
                inputName={"authority_id"}
                labelText="Authority"
                onChangeFn={(event) => {
                  authorityChangeFn(
                    event,
                    formAction,
                    `${props.fromParent.formKey}`
                  );
                  operationTypeAllowedFn(
                    event,
                    formAction,
                    `${props.fromParent.formKey}`
                  );
                }}
                emptySelectOptionTxt={"Authority"}
              />
            ) : (
              <p>Authority: {_get(props, "row.authority_name", "")}</p>
            )}
          </div>

          <div className="row">
            <div className="col-md-4 col-sm-12 floatLeft">
              <InputBoxWithState
                uiType={templateTypes.bootstrap}
                formGroupName={formKey}
                inputName={"prefix"}
                labelText="Prefix"
                isDisabled={true}
              />
            </div>
            <div className="col-md-8 col-sm-12 floatLeft">
              <InputBoxWithState
                formGroupName={`${props.fromParent.formKey}`}
                inputName={"name"}
                labelText="Counter Name"
                isRequired={true}
                isDisabled={_get(formState, `${formKey}.transacted`, false) ? true : false }
                maxLength={10}
              />
            </div>
          </div>
          <div className="row">
          <div className="col-md-12 col-sm-12 floatLeft">
              <InputBoxWithState
                formGroupName={`${props.fromParent.formKey}`}
                inputName={"code"}
                labelText="Counter Code"
                isRequired={true}
                isDisabled={_get(formState, `${formKey}.transacted`, false) ? true : false }
                maxLength={4}
              />
            </div>
          </div>
          <SelectBoxWithState
            formGroupName={`${props.fromParent.formKey}`}
            dataList={[
              {
                id: "1",
                value: "Active",
              },
              {
                id: "0",
                value: "Inactive",
              },
            ]}
            inputName={"active"}
            labelText="Status"
          />
          <p>Operations Allowed:</p>
          <div className="row">
            <div className="col-md-6 col-sm-12 ">
              {allowedLicenceTypes.map((value, index) => {
                return (
                  <div className="col-md-12 " key={index}>
                    {
                      value.code === 'FE' || value.code === 'NU' ? 
                        null : 
                      <CheckBoxWithState
                        inputStatePath={`${formKey}.check_licence_type[${value.id}]`}
                        inputName={value.id}
                        formGroupName={formKey}
                        labelText={value.code === 'NO' ? 'Normal/ Free/ Non-User Licence' : value.name}
                      />
                    }
                  </div>
                );
              })}
            </div>
            <div className="col-md-6 col-sm-12 ">
              {allowedOperationTypes.map((value, index) => {
                return (
                  <div className="col-md-12 " key={index}>
                    <CheckBoxWithState
                      inputStatePath={`${formKey}.check_operation_type[${value.code}]`}
                      inputName={value.code}
                      formGroupName={formKey}
                      labelText={value.name}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </FormWrapper>
    </UICard>
  );
};

const CounterSessionEndForm = (props) => {
  const id = props.row.counter_session;
  return (
    <FormWrapper
      setGroupName={props.fromParent.formKey}
      formGroupLinkWith={props.fromParent.formKey}
      setFormObject={{
        id: id,
        counter_id: props.row.id,
        end_at: new Date(),
      }}
      onDestroyUnsetFormObject={true}
    >
      <div className="removeContent">
        <p>Are you sure you want to end the current active session?</p>
        <br />
        <br />
      </div>
    </FormWrapper>
  );
};

export { Form, CounterSessionEndForm };
