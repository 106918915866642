import React, { useContext }  from "react";
import { districtListAPI, pdmtListAPI, authorityTypeListAPI } from "../../../../../config/apiUrl.config";
import { templateTypes } from "../../../../../config/template.config";
import { FormWrapper, InputBoxWithState, SelectBoxWithState } from "../../../../ui-components/ui-elements/form";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";

const SearchElement = ({ dataTableKey = "" }) => {
    const formKey = `${dataTableKey}_search`;
    const [formState, formAction] = useContext(FormContext);
    const [authStatus, authAction] = useContext(AuthContext);
    const userRoleLevelCode = _get(authStatus, "authUser.role_level_code", "");
    const service_Id = _get(authStatus, "authUser.service_id", "");

    return (
        <FormWrapper 
            setGroupName={formKey}
        >
            <div className="row">
                <div className="col-md-4 col-sm-6">
                    <InputBoxWithState
                        labelText={"Authority Name"}
                        uiType={templateTypes.bootstrap}
                        formGroupName={formKey}
                        inputName={"name"}
                        maxLength={"50"}
                        inputPlaceholder={"Authority Name"}
                    />
                </div>
                <div className="col-md-4 col-sm-6">
                    <InputBoxWithState
                        labelText={"Authority Code"}
                        uiType={templateTypes.bootstrap}
                        formGroupName={formKey}
                        inputName={"code"}
                        maxLength={"12"}
                        inputPlaceholder={"Authority Code"}
                    />
                </div>
                <div className="col-md-4">
                    <SelectBoxWithState
                        inputName={"authority_type_code"}
                        uiType={templateTypes.bootstrap}
                        labelText={"Authority Type"}
                        emptySelectOptionTxt={"All"}
                        formGroupName={formKey}
                        apiUrl={authorityTypeListAPI.url}
                        apiStateKey={authorityTypeListAPI.key}
                        keyName="code"
                        valueName="name"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <SelectBoxWithState
                        formGroupName={formKey}
                        inputName={"province_id"}
                        apiUrl={(userRoleLevelCode === "ADN_NTL") ? pdmtListAPI.url + "/list?sort_by=name|asc" : pdmtListAPI.url + "/list?sort_by=name|asc&service_id=" + service_Id}
                        apiStateKey={pdmtListAPI.key}
                        keyName="id"
                        valueName="service.name"
                        // emptySelectOptionTxt={"All"}
                        labelText={"Province office"}
                        isEnableAllOption={(userRoleLevelCode === "ADN_NTL") ? true : false}
                    />
                </div>
                <div className="col-md-4">
                    
                    <SelectBoxWithState
                        formGroupName={formKey}
                        inputName={"district_code"}
                        apiUrl={_get(formState, `${formKey}.province_id`) === undefined ? null : (districtListAPI.url + '?province_id=' + _get(formState, `${formKey}.province_id`))}
                        apiStateKey={districtListAPI.key}
                        isDisabled={(_get(formState, `${formKey}.province_id`, undefined) === '' )||(_get(formState, `${formKey}.province_id`, undefined) === null )? true : _get(formState, `${formKey}.province_id`, undefined) === 'null' ? true : false}
                        emptySelectOptionTxt={"District"}
                        keyName="code"
                        valueName="name"
                        labelText={"District"}
                        isEnableAllOption={true}
                    />
                </div>
                <div className="col-md-4">
                    <SelectBoxWithState
                        inputName={"active"}
                        uiType={templateTypes.bootstrap}
                        labelText={"Status"}
                        formGroupName={formKey}
                        emptySelectOptionTxt={"All"}
                        dataList={[
                            {
                                id: "true",
                                value: "Active"
                            },
                            {
                                id: "false",
                                value: "Inactive"
                            }
                        ]}
                    />
                </div>
            </div>
        </FormWrapper>
    )
};

export {
    SearchElement
}