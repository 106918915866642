/*
 * @Author:gajani kangesan
 * @Date: 2022-12-19 13:22:32 
 * @Last Modified by: gajani kangesan
 * @Last Modified time: 2022-12-19 15:06:45
 */

import React, { useContext } from 'react';
import {
  FormWrapper,
  InputBoxWithState,
  CheckBoxWithState
} from "../../../../ui-components/ui-elements/form";
import { _get } from '../../../../../helpers/common-helpers/lodash.wrappers';
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { WorkFlowReason, OtherReason } from "../../../../../config/enum.config";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { AuditDetail, UICard } from '../../../../ui-components/ui-elements/common/BaseElements';

const RemoveFrom = (props) => {
  const formKey = `${props.fromParent.formKey}`;


  return (
    <FormWrapper
      setGroupName={formKey}
      setFormObject={{
        id: _get(props, "row.id", ""),
      }}
      onDestroyUnsetFormObject={true}
    >
      <div className="removeContent">
        <p>Are you sure you want to continue?</p>
        <br />
        <br />
      </div>
    </FormWrapper>
  );
};
const ReasonForm = (props) => {
  const [formState, formAction] = useContext(FormContext);
  const formKey = `${props.fromParent.formKey}`;
  const today = new Date();
  const ui = `${props.fromParent.uiType}`;
  return (

    <FormWrapper
      setGroupName={formKey}
      setFormObject={{
        id: _get(props, "row.id", ""),
        name: _get(props, "row.name", ""),
        add_dealer: _get(props, "row.add_dealer", ""),
        add_licence_receipt_transaction: _get(props, "row.add_licence_receipt_transaction", ""),
        add_licence_transaction: _get(props, "row.add_licence_transaction", ""),
        add_vehicle: _get(props, "row.add_vehicle", ""),
        change_serial_number: _get(props, "row.change_serial_number", ""),
        dealer_change_serial_number: _get(props, "row.dealer_change_serial_number", ""),
        dealers_cancel_licence_receipt_transaction: _get(props, "row.dealers_cancel_licence_receipt_transaction", ""),
        dealers_licence_reissuance: _get(props, "row.dealers_licence_reissuance", ""),
        dealers_licence_reprint: _get(props, "row.dealers_licence_reprint", ""),
        licence_receipt_reprint: _get(props, "row.licence_receipt_reprint", ""),
        licence_type_change: _get(props, "row.licence_type_change", ""),
        licence_waive_off: _get(props, "row.licence_waive_off", ""),
        other_additions_deductions: _get(props, "row.other_additions_deductions", ""),
        request_licence_invalid_documents: _get(props, "row.request_licence_invalid_documents", ""),
        request_licence_licence_arrears: _get(props, "row.request_licence_licence_arrears", ""),
        reverse_last_transaction: _get(props, "row.reverse_last_transaction", ""),
        set_effective_date: _get(props, "row.set_effective_date", ""),
        update_dealer: _get(props, "row.update_dealer", ""),
        update_vehicle: _get(props, "row.update_vehicle", ""),
        vehicle_black_list: _get(props, "row.vehicle_black_list", ""),
        vehicle_ineligible: _get(props, "row.vehicle_ineligible", ""),
        vehicle_transfer: _get(props, "row.vehicle_transfer", ""),
        vehicle_status_change: _get(props, "row.vehicle_status_change", ""),
        webpayment_reconciliation: _get(props, "row.webpayment_reconciliation", ""),
        created_roleId: _get(props, "row.created_role_id", "")

      }}
      onDestroyUnsetFormObject={true}
    >
      <div className='fullWidthDiv'>
        <div className="row">
          <div className="col-2 defaultMarginTop">
            <h6>Reason Name : <sup className="text-danger">*</sup></h6>
          </div>
          <div className="col-6">
            <InputBoxWithState
              formGroupName={formKey}
              inputName={"name"}
              isRequired={false}
              maxLength={100}
              isDisabled={ui === "view" ? true : false}
            />
          </div>

        </div>
        <UICard> <div className='row'>
          <div className={"col-2 d-flex "}><p>Reason for Workflow Request </p></div>
          <div className={"col-8"}>    <div className="row">

            {
              WorkFlowReason.map((item, index) => {
                return (
                  <div className={"col-md-6"}>
                    <CheckBoxWithState
                      labelText={item.name}
                      formGroupName={formKey}
                      inputName={item.id}
                      isDisabled={ui === "view" ? true : false}
                    />

                  </div>
                )
              })
            }
          </div></div>

        </div></UICard>
        <UICard>  <div className='row'>
          <div className={"col-2 d-flex "}><p>Reason for Other Functionality</p></div>
          <div className={"col-8"}>    <div className="row">

            {
              OtherReason.map((item, index) => {
                return (
                  <div className={"col-md-6"}>
                    <CheckBoxWithState
                      labelText={item.name}
                      formGroupName={formKey}
                      inputName={item.id}
                      isDisabled={ui === "view" ? true : false}
                    />

                  </div>
                )
              })
            }
          </div></div>

        </div></UICard>





        {ui == "edit" || ui === "view" ?
          <div className="fullWidthDiv defaultPaddingTop">

            <AuditDetail
              createdById={_get(props, "row.created_by", "")}
              createdLocationId={_get(props, `row.created_location`, "")}
              createdAt={dateObjectToString(_get(props, `row.created_at`, ""), "dd/mm/YY HH:MM")}
              createdRoleId={_get(props, "row.created_role_id", "")}
              updatedById={_get(props, "row.updated_by", "")}
              updatedLocationId={_get(props, `row.updated_location`, "")}
              updatedAt={dateObjectToString(_get(props, `row.updated_at`, ""), "dd/mm/YY HH:MM")}
              updatedRoleId={_get(props, "row.updated_role_id", "")}
              labelCol="4"
            />
          </div>
          : ""
        }

      </div>


    </FormWrapper>
  );
}

export {
  ReasonForm, RemoveFrom
}