import React from "react";
import { useState } from "react";
import {TabsWrapper,SubTab,tabsWrapperVariants,tabsWrapperScrollButtons} from "../../../../ui-components/ui-elements/tab/Tabs";
import { CertificateNumbersUI } from "./CertificateNumbersUI";
import { CertifyingOfficersUI } from "./CertifyingOfficersUI";
import { CompanyInfoUI } from "./CompanyInfoUI";
const ViewVFTCompany = (props) => {
    
    const [getState,setState]=useState({
        "currentTab":"tab1",
      });

    return ( 
        <TabsWrapper
                onChangeFn={(event)=>setState({
                    ...getState,
                    "currentTab":event.value
                })}
                elementWrapperStyle={""}
                active={getState.currentTab}
                variant={tabsWrapperVariants.scrollable}
                scrollButtons={tabsWrapperScrollButtons.on}
            >
                <SubTab tabid={"tab1"} header={"Company Info"}>
                    <CompanyInfoUI row={{ ...props.row }}  tableHeading={"Company Info"}/>
                </SubTab>  
                <SubTab tabid={"tab2"} header={"Certifying Officers"}>
                    <CertifyingOfficersUI row={{ ...props.row }}  tableHeading={"Certifying Officers"}/>
                </SubTab>
                <SubTab tabid={"tab3"} header={"VFT Certificate Numbers"}> 
                    <CertificateNumbersUI row={{ ...props.row }}  tableHeading={"Certificate Numbers"}/>
                </SubTab>                             
            </TabsWrapper>
    );
};

export { ViewVFTCompany };