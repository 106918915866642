/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-07-14 10:18:15
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-04-06 13:25:26
 * @Last Modified by: Senura Weerasinghe
 * @Last Modified time: 2021-05-04 13:39:46
 */

import React, { useEffect } from 'react';
import { DashboardTemplate } from '../../../ui-components/templates/DashboardTemplate';
import {
  DataTable,
  actionBtnTypes,
  apiResponseStoringType,
} from "../../../ui-components/ui-elements/table/DataTable";
import { ViewFuelType } from "./includes/View-fuel-type";
import { FuelTypeForm } from "./includes/Fuel-type-form";
import { SearchElement } from "./includes/Search-element";
import {
  fuelTypeListApi
} from "../../../../config/apiUrl.config";
import { SubmitButton } from "../../../ui-components/ui-elements/form";
import { getDataByFormObject } from "../../../../helpers/common-helpers/common.helpers";
import { CheckPermission } from '../../../ui-components/ui-elements/common/BaseElements';
import { permissions } from '../../../../config/permission.config';

const SubmitBtn = (props) => {
  return (
    <CheckPermission
      permission={permissions.FUEL_TYPE_ADD.permissions}
    >
      <SubmitButton
        btnText={props.uiType === "EDIT" ? "Update" : "Save"}
        formGroupName={props.formGroupName}
        tooltip={props.uiType === "EDIT" ? "Edit" : "Create New"}
        startIcon={props.uiType === "CREATE" ? 'fas fa-save' : 'far fa-edit'}
        dataTableKey={props.dataTableKey}
        isValidate={true}
        flashMessages={{
          "success": {
            status: true,
            message: `Fuel Type is successfully ${props.uiType === "EDIT" ? "updated" : "saved"}.`,
            messageType: "success"
          }
        }}
        mapValidationErrorObj={{
          name: "Fuel Type Name already exists",
          prefix: "Fuel Type Prefix already exists",
        }}
        validationObject={{
          fileds: {
            prefix: "Fuel Type Prefix",
            name: "Fuel Type Name",
            active: "Status",
            vet_applicability_code: "VET Validation",
            dmt_mapping_parameter: "DMT Mapping Parameter"
          },
          rules: {
            prefix: "required|max:2",
            name: "required|max:50",
            vet_applicability_code: "required",
            active: "required",
            dmt_mapping_parameter: "required|max:50",
          },
        }}
        onGetAPIEndPointFn={(formState) => {

          return {
            url: props.uiType === "EDIT" ? `${fuelTypeListApi.url}/${formState.id}` : fuelTypeListApi.url,
            key: fuelTypeListApi.key
          }
        }}
        onChangeRequestBodyFn={(fromObject) => {
          if(props.uiType === "EDIT") {
          return getDataByFormObject({
            ...fromObject,
            prefix: fromObject.prefix.trim(),
            name: fromObject.name.trim(),
            active: (parseInt(fromObject.active) === 1 ? (true) : (false)),
          }) 
        } else { 
          return getDataByFormObject({
            dmt_mapping_parameter: fromObject.dmt_mapping_parameter,
            vet_applicability_code:fromObject.vet_applicability_code,
            prefix: fromObject.prefix.trim(),
            name: fromObject.name.trim(),
            active: (parseInt(fromObject.active) === 1 ? (true) : (false)),
          })
        }
        }}
        callApiObject={{
          isSetHeaders: true,
          method: props.uiType === "EDIT" ? "put" : "post",
        }}
        apiDataStoringObject={{
          setLoader: true,
          storingType: apiResponseStoringType.dateTableLinkedForm
        }}
        onResponseCallBackFn={(response, error) => {
          if (
            response.length === undefined &&
            error === undefined &&
            response.data.errors === undefined
          ) {
            props.onClosePopupFn();
          }
        }}
      />
    </CheckPermission>

  )
}

const ManageFuelType = () => {
  const formKey = 'testInuts';
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
    <DashboardTemplate
      pageTitle="Manage Fuel Type"
      breadCrumbList={[
        { urlTo: '/', displayName: 'Home' },
        { urlTo: '', displayName: 'Configurations' },
        { urlTo: '', displayName: 'Manage Fuel Type' },
      ]}
    >
      <DataTable
        tableName="Fuel Types"
        apiUrl={`${fuelTypeListApi.url}?sort_by=name|asc`}
        dataTableKey={fuelTypeListApi.key}
        isSetAction={true}
        isSetSearchFrom={true}
        defaultSearchFormObject={{
          prefix: "",
          name: "",
          active: null
        }}
        searchFromComponent={
          <SearchElement dataTableKey={fuelTypeListApi.key} />
        }
        actionTypeList={[
          {
            actionType: actionBtnTypes.create,
            modelContentElement: FuelTypeForm,
            dialogPopupProps: {
              isFullScreen: false,
              isSaveButton: false,
              isCustomButton: true,
              customButtonComponent: SubmitBtn,
              closeButton: {
                btnText: "Cancel",
                startIcon: 'far fa-times-circle'
              },
              isConfirmationPopup: true,
              customButtonComponentProps: { btnText: "Save", uiType: "CREATE", dataTableKey: fuelTypeListApi.key },
              dialogTitle: "Add Fuel Type",
            },
            actionBtn: {
              "permissions": permissions.FUEL_TYPE_ADD.permissions
            },
            parentToModelProps: {
              uiType: "CREATE",
              formKey: fuelTypeListApi.key + "_create",


            },
          },
          {
            actionType: actionBtnTypes.view,
            actionBtn: { tooltip: "View", "permissions": permissions.FUEL_TYPE_VIEW.permissions },
            modelContentElement: ViewFuelType,
            dialogPopupProps: {
              isFullScreen: false,
              isSaveButton: false,
              dialogTitle: 'View Fuel Type',
            },
          },
          {
            actionType: actionBtnTypes.edit,
            modelContentElement: FuelTypeForm,
            dialogPopupProps: {
              isFullScreen: false,
              isSaveButton: false,
              isCustomButton: true,
              closeButton: {
                btnText: "Cancel",
                startIcon: 'far fa-times-circle'
              },
              isConfirmationPopup: true,
              customButtonComponent: SubmitBtn,
              customButtonComponentProps: { btnText: "Update", uiType: "EDIT", dataTableKey: fuelTypeListApi.key },
              dialogTitle: "Edit Fuel Type",
            },
            actionBtn: {
              "permissions": permissions.FUEL_TYPE_EDIT.permissions
            },
            parentToModelProps: {
              uiType: "EDIT",
              formKey: fuelTypeListApi.key + "_edit",
            },
          },
        ]}
        tableHeaderList={[
          { displayName: 'Fuel Type Prefix', key: 'prefix', isSort: false },
          { displayName: 'Fuel Type Name', key: 'name', isSort: false },

          {
            displayName: "Status",
            key: "active",
            isSort: false,
            onPrintFn: ({ active }) => active === 1 ? "Active" : "Inactive"
          },
        ]}
      />
    </DashboardTemplate>
  );
};

export default ManageFuelType;
