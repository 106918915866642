/*
 * @Author: Anjula Karunarathne
 * @Date: 2021-01-21 16:05:28
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-02-02 00:41:47
 */
import React, { Fragment } from "react";
import {
  additionDeductionCreateAPI,
  additionDeductionEditAPI,
  additionDeductionListAPI,
} from "../../../../../config/apiUrl.config";
import { getDataByFormObject } from "../../../../../helpers/common-helpers/common.helpers";
import { _findindex } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { SubmitButton } from "../../../../ui-components/ui-elements/form";
import { apiResponseStoringType } from "../../../core/context-providers/CoreContext.provider";

const CustomButton = (props) => {
  const btnText = props.btnText;
  const uiType = props.uiType;
  return (
    <Fragment>
      <SubmitButton
        btnText={btnText}
        startIcon={uiType === "add" ? "far fa-save" : "far fa-edit"}
        formGroupName={props.formGroupName}
        dataTableKey={additionDeductionListAPI.key}
        elementStyle="btnWrapper"
        isValidate={true}
        validationObject={{
          fileds: {
            name: "Name",
            applicability_type_code: "Applicability Type",
            charge_effect_code: "Charge Effect",
            charge_type_code: "Charge Type",
            charge_type_value: "Value",
            charge_component: "Charge Component",
            active: "Status",
            seat_base_applied: "Seat Basis applicable",
            seat_base_from: "From",
            seat_base_to: "To",
            date_base_applied: "Specific Date Base applicable",
            date_base_code: "Date Base",
            date_base: "Date",
            date_duration_mode:"Date Duration Mode",
            date_duration_code: "Date Duration",
            date_value: "Value",
            weight_base_applied: "Weight Basis applicable",
            weight_base_code: "Weight Base",
            weight_base_value_from: "From",
            weight_base_value_to: "To",
            engine_capacity_base_applied: "Engine Capacity Basis",
            engine_capacity_from: "From",
            engine_capacity_to: "To",
          },
          rules: {
            name: "required|max:50",
            applicability_type_code: "required",
            charge_effect_code: "required",
            charge_type_code: "requiredIf:applicability_type_code,GEN",
            charge_type_value: "requiredIf:applicability_type_code,GEN",
            charge_component: "requiredIf:applicability_type_code,GEN",
            active: "required",
            seat_base_from: "requiredIf:seat_base_applied,true",
            seat_base_to: "requiredIf:seat_base_applied,true",
            date_base_code: "requiredIf:date_base_applied,true",
            date_base: "requiredIf:date_base_code,CUD",
            date_duration_code: "requiredIf:date_base_applied,true",
            date_duration_mode: "requiredIf:date_base_applied,true",
            date_value: "requiredIf:date_base_applied,true",
            weight_base_code: "requiredIf:weight_base_applied,true",
            weight_base_value_from: "requiredIf:weight_base_applied,true",
            weight_base_value_to: "requiredIf:weight_base_applied,true",
            engine_capacity_from: "requiredIf:engine_capacity_base_applied,true",
            engine_capacity_to: "requiredIf:engine_capacity_base_applied,true",
          },
          message: {
            "charge_type_code.requiredIf": "The Charge Type is required",
            "charge_type_value.requiredIf": "The Value is required",
            "charge_component.requiredIf": "The Charge Component is required",
            "seat_base_from.requiredIf": "The From is required",
            "seat_base_to.requiredIf": "The To is required",
            "date_base_code.requiredIf": "The Date Base is required",
            "date_base.requiredIf": "The Date is required",
            "date_duration_code.requiredIf": "The Duration is required",
            "date_duration_mode.requiredIf": "The Duration Mode is required",
            "date_value.requiredIf": "The Value is required",
            "weight_base_code.requiredIf": "The Weight Base is required",
            "weight_base_value_from.requiredIf": "The From is required",
            "weight_base_value_to.requiredIf": "The To is required",
            "engine_capacity_from.requiredIf": "The From is required",
            "engine_capacity_to.requiredIf": "The To is required",
          }
        }}
        flashMessages={{
          success: {
            status: true,
            message:
              uiType === "add"
                ? "Addition / Deduction is successfully saved."
                : "Addition / Deduction is successfully updated.",
            messageType: "success",
          },
        }}
        onGetAPIEndPointFn={(formState) => {
          return {
            url:
              uiType === "add"
                ? additionDeductionCreateAPI.url
                : `${additionDeductionEditAPI.url}/${formState.id}`,
            key: additionDeductionListAPI.key,
          };
        }}
        onChangeRequestBodyFn={(fromObject) => {

          if (fromObject.applicability_type_code === "GEN") {
            fromObject.charge_type_code = fromObject.charge_type_code === "" ? null : fromObject.charge_type_code;
            fromObject.charge_type_value = fromObject.charge_type_value === "" ? null : fromObject.charge_type_value;
            fromObject.charge_component = fromObject.charge_component === "" ? null : fromObject.charge_component;
            fromObject.licence_type_id = fromObject.licence_type_id === "" ? null : fromObject.licence_type_id;
            fromObject.vehicle_class_id = fromObject.vehicle_class_id === "" ? null : fromObject.vehicle_class_id;
            fromObject.fuel_type_id = fromObject.fuel_type_id === "" ? null : fromObject.fuel_type_id;
            if (fromObject.weight_base_applied === true) {
              fromObject.weight_base_code = fromObject.weight_base_code === "" ? null : fromObject.weight_base_code;
              fromObject.weight_base_value_from = fromObject.weight_base_value_from === "" ? null : fromObject.weight_base_value_from;
              fromObject.weight_base_value_to = fromObject.weight_base_value_to === "" ? null : fromObject.weight_base_value_to;
            } else if (fromObject.seat_base_applied === true) {
              fromObject.seat_base_from = fromObject.seat_base_from === "" ? null : fromObject.seat_base_from;
              fromObject.seat_base_to = fromObject.seat_base_to === "" ? null : fromObject.seat_base_to;
            } else if (fromObject.date_base_applied === true) {
              if (fromObject.date_base_code === "VRD") {
                fromObject.date_duration_code = fromObject.date_duration_code === "" ? null : fromObject.date_duration_code;
                fromObject.date_duration_mode = fromObject.date_duration_mode === "" ? null : fromObject.date_duration_mode;
                fromObject.date_value = fromObject.date_value === "" ? null : fromObject.date_value;
              } else {
                fromObject.date_duration_code = fromObject.date_duration_code === "" ? null : fromObject.date_duration_code;
                fromObject.date_duration_mode = fromObject.date_duration_mode === "" ? null : fromObject.date_duration_mode;
                fromObject.date_value = fromObject.date_value === "" ? null : fromObject.date_value;
                fromObject.date_base = fromObject.date_base === "" ? null : fromObject.date_base;
              }

            } else if (fromObject.engine_capacity_base_applied === true) {
              fromObject.engine_capacity_from = fromObject.engine_capacity_from === "" ? null : fromObject.engine_capacity_from;
              fromObject.engine_capacity_to = fromObject.engine_capacity_to === "" ? null : fromObject.engine_capacity_to;
            } else {
              fromObject.weight_base_code = ""
              fromObject.weight_base_value_from = ""
              fromObject.weight_base_value_to = ""
              fromObject.seat_base_from = ""
              fromObject.seat_base_to = ""
              fromObject.date_duration_code = ""
              fromObject.date_duration_mode =""
              fromObject.date_value = ""
              fromObject.engine_capacity_from = ""
              fromObject.engine_capacity_to = ""
            }
          } else {
            fromObject.charge_type_code = null
            fromObject.charge_type_value = null
            fromObject.charge_component = null
            fromObject.licence_type_id = null
            fromObject.vehicle_class_id = null
            fromObject.fuel_type_id = null
            fromObject.weight_base_code = ""
            fromObject.weight_base_value_from = ""
            fromObject.weight_base_value_to = ""
            fromObject.seat_base_from = ""
            fromObject.seat_base_to = ""
            fromObject.date_duration_code = ""
            fromObject.date_duration_mode= ""
            fromObject.date_value = ""
            fromObject.engine_capacity_from = ""
            fromObject.engine_capacity_to = ""
          }

          return getDataByFormObject({
            ...fromObject,
            active: parseInt(fromObject.active) === 1,
          });
        }}
        callApiObject={{
          isSetHeaders: true,
          method: uiType === "add" ? "post" : "put",
        }}
        apiDataStoringObject={{
          setLoader: true,
          storingType: apiResponseStoringType.dateTableLinkedForm,
        }}
        onResponseCallBackFn={(response, error) => {
          if (
            response.length === undefined &&
            error === undefined &&
            response.data.errors === undefined
          ) {
            props.onClosePopupFn();
          }
        }}
      />
    </Fragment>
  );
};

export { CustomButton };
