import React, { Fragment, useContext, useEffect } from "react";
import {
    UICard,
    AuditDetail,
    ViewDetailBlock
} from "../../../../ui-components/ui-elements/common/BaseElements";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { getProvinceFn } from "../../../../../helpers/common-helpers/getProvinceById.helpers";
import { FormWrapper } from "../../../../ui-components/ui-elements/form";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";

export const setThousandSeparator = (value, decimal = 0) => {
    if(decimal != 0){
        value = (+value).toFixed(decimal);
        const result = value.split('.');
        return result[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +'.'+ result[1];
    }else{
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  
}
const View = (props) => {
    const numberOfLabelCol = 4;
    const formKey = `${props.fromParent.formKey}`;
    const [formState, formAction] = useContext(FormContext);
    const [authStatus, authAction] = useContext(AuthContext);
    const service_Id = _get(authStatus, "authUser.service_id", "");
    
    useEffect(() => {    
      getProvinceFn({value: service_Id}, formAction, formKey);
    }, []);
    return (
        <Fragment>
            <UICard >
                <FormWrapper
                    setGroupName={formKey}
                >

                    <ViewDetailBlock
                        label={"Province"}
                        value={_get(formState, `${formKey}.province_name`, "")}
                        labelCol={numberOfLabelCol}
                    />

                    <ViewDetailBlock
                        label={"Delivery Method"}
                        value={_get(props, `row.delivery_method.name`, '')}
                        labelCol={numberOfLabelCol}
                    />

                    <ViewDetailBlock
                        label={"Status"}
                        value={parseInt(_get(props, `row.active`, '')) === 1 ? ('Active') : ('Inactive')}
                        labelCol={numberOfLabelCol}
                    />

                    <ViewDetailBlock
                        label={"Charge (LKR)"}
                        value={setThousandSeparator(_get(props, `row.charge`, ''), 2)}
                        labelCol={numberOfLabelCol}
                    />
                </FormWrapper>               
            </UICard>
            <AuditDetail
                createdById={_get(props, `row.created_by`, '')}
                updatedById={_get(props, `row.updated_by`, '')}
                createdLocationId={_get(props, `row.created_location`, '')}
                updatedLocationId={_get(props, `row.updated_location`, '')}
                createdAt={dateObjectToString(_get(props, `row.created_at`, ''), "dd/mm/YY HH:MM")}
                updatedAt={dateObjectToString(_get(props, `row.updated_at`, ''), "dd/mm/YY HH:MM")}
                createdRoleId={_get(props, `row.created_role_id`, '')}
                updatedRoleId={_get(props, `row.updated_role_id`, '')}
                labelCol={numberOfLabelCol}
            />
        </Fragment>

    );
};

export { View };