/**
 * Created By Pradeepa Sandaruwan
 * At 2020/12/28
*/


import React from 'react';
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import {
  FormWrapper,
  InputBoxWithState,
  SelectBoxWithState
} from "../../../../ui-components/ui-elements/form";
import { templateTypes } from "../../../../../config/template.config";
import { _get } from '../../../../../helpers/common-helpers/lodash.wrappers';


const FuelTypeForm = (props) => {
  const formKey=props.fromParent.formKey;
  return (
    
    <FormWrapper
    setGroupName={`${formKey}`}
    formGroupLinkWith={formKey}    

      setFormObject={{
        id: _get(props, "row.id", ""),
        prefix: _get(props, "row.prefix", ""),
        name: _get(props, "row.name", ""),
        active: _get(props, "row.active", "1"),
        vet_applicability_code: _get(props, "row.vet_applicability_code", "MAN"),
        dmt_mapping_parameter: _get(props, "row.dmt_mapping_parameter", ""),
      }}
      onDestroyUnsetFormObject={true}
    >
      <InputBoxWithState
        uiType={templateTypes.bootstrap}
        formGroupName={formKey}
        inputName={"prefix"}
        labelText="Fuel Type Prefix"
        isRequired={true}
        maxLength="2"
      />
      <InputBoxWithState
        uiType={templateTypes.bootstrap}
        formGroupName={formKey}
        inputName={"name"}
        labelText="Fuel Type Name"
        isRequired={true}
        maxLength="50"
      />
      <SelectBoxWithState
        uiType={templateTypes.bootstrap}
        formGroupName={formKey}
        dataList={[
          {
            id: "1",
            value: "Active",
          },
          {
            id: "0",
            value: "Inactive",
          },
        ]}
        inputName={"active"}
        emptySelectOptionTxt=""
        labelText="Status"
      />
      <div className="defaultPaddingTop">
        <UICard cardHeading="Document Applicability">
          <SelectBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            emptySelectOptionTxt={""}
            dataList={[
              {
                id: "MAN",
                value: "Mandatory",
              },
              {
                id: "OPT",
                value: "Optional",
              },
              {
                id: "NOT",
                value: "Not Required",
              },
            ]}
            inputName={"vet_applicability_code"}
            labelText="VET"
          />
        </UICard>
      </div>
      <div className="defaultPaddingTop">
        <UICard cardHeading="DMT Mapping Parameters">
          <InputBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            inputName={"dmt_mapping_parameter"}
            labelText="DMT Mapping Parameter"
            isRequired={true}
            maxLength="50"
          />

        </UICard>
      </div>

    </FormWrapper>
  );
}

export {
  FuelTypeForm
}