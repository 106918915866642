/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-06-24 14:46:11 
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2021-05-06 12:27:07
 */

const pdmtTempLicenseValidityPeriod = [
    {
        id: 2,
        value: "2 Months"
    },
    {
        id: 3,
        value: "3 Months"
    },
    {
        id: 4,
        value: "4 Months"
    },
    {
        id: 6,
        value: "6 Months"
    },
    {
        id: 12,
        value: "12 Months"
    }
];

const pdmtChargingMethods = [
    {
        id: "MON",
        value: "Validity Period"
    },
    {
        id: "ANN",
        value: "Annually"
    }
];

const pdmtPaymentCategories = {
    CSH: "Cash",
    SMV: "Sampath Vishwa",
    QR: "QR",
    LGP: "LGPS",
    CRD: "Card",
    CHQ: "Cheque"
};

const ratesToValues = [
    {
        id: 2,
        value: "2"
    },
    {
        id: 3,
        value: "3"
    },
    {
        id: 4,
        value: "4"
    },
    {
        id: 5,
        value: "5"
    },
    {
        id: 6,
        value: "6"
    },
    {
        id: 7,
        value: "7"
    },
    {
        id: 8,
        value: "8"
    },
    {
        id: 9,
        value: "9"
    },
    {
        id: 10,
        value: "10"
    },
    {
        id: 11,
        value: "11"
    },
    {
        id: 12,
        value: "12"
    }
];

const fineConfigurationsChargeTypes = [
    {
        id: "PRC",
        value: "Percentage"
    },
    {
        id: "FLT",
        value: "Flat"
    }
];

export {
    pdmtTempLicenseValidityPeriod,
    pdmtChargingMethods,
    pdmtPaymentCategories,
    ratesToValues,
    fineConfigurationsChargeTypes
}
