/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-07-14 10:18:15
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2021-07-28 13:33:06
 */
import React, { Fragment, useState, useEffect, useRef, useContext } from "react";

import { UICard, CircleLoaderElement, CheckPermission } from "../../../ui-components/ui-elements/common/BaseElements";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { DialogPopup } from "../../../ui-components/ui-elements/modal/DialogPopup";
import { CheckBox, InputButton } from "../../../ui-components/ui-elements/form";
import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { callApi } from "../../../../helpers/common-helpers/callApi.helpers";
import { notificationsAPI } from "../../../../config/apiUrl.config";
import { dateObjectToString } from "../../../../helpers/common-helpers/dateTime.helpers";
import { permissions } from "../../../../config/permission.config";
import { AuthContext } from "../../core/context-providers/AuthContext.provider";
import { fromObjectToQueryString } from "../../../../helpers/common-helpers/common.helpers";
import { UIContext } from "../../../ui-components/context-providers/UIContext.provider";
import { NotificationTypesList } from "../../../../config/enum.config";

const Notification = () => {
  return (
    <DashboardTemplate
      pageTitle="Notifications"
      breadCrumbList={[
        { urlTo: "/", displayName: "Home" },
        { urlTo: "", displayName: "Notification" },
      ]}
    >
      <NotificationUI />
    </DashboardTemplate>
  );
};

const NotificationUI = () => {
  const [authState] = useContext(AuthContext);
  const [, uiAction] = useContext(UIContext);
  const [getState, setState] = useState({
    "unread": true,
    "read": true,
    initialRender: true,
    showInitialLoader: true,
    showLoader: true,
    showHideModel: false,
    modelData: {},
    dataList: [],
    meta: {}
  });

  const scrollDiv = useRef(null);
  const stateRef = useRef(getState);

  const trackScrolling = (event) => {
    if (
      (scrollDiv.current.getBoundingClientRect().bottom <= window.innerHeight) &&
      stateRef.current.showLoader === false &&
      stateRef.current.showInitialLoader === false &&
      Object.keys(stateRef.current.meta).length > 0
    ) {

      if (parseInt(stateRef.current.meta.current_page) < parseInt(stateRef.current.meta.total_pages)) {
        setState(prevState => {
          stateRef.current = {
            ...prevState,
            showLoader: true
          };

          return {
            ...prevState,
            showLoader: true
          }
        });

        setTimeout(() => getNotifications(parseInt(stateRef.current.meta.current_page) + 1), 1000);
      }
    }
  };

  const getNotifications = (page = 1, isAppend = true) => {
    // const queryStringObj = {
    //   read: (getState.unread && getState.read) ? undefined : getState.read ? true : false,
    //   user_id: _get(authState, "authUser.id", undefined),
    //   role_category_code: _get(authState, "authUser.role_level_code", undefined)
    // };
    // console.log('AUTH_ADMIN-->', authState);

    const queryStringObj = {
      read: page > 1 ? ((stateRef.current.unread && stateRef.current.read) ? undefined : stateRef.current.read ? true : false) : ((getState.unread && getState.read) ? undefined : getState.read ? true : false),
      pdmt_service_id:  _get(authState, "authUser.service_id", undefined),
      // authority_service_id
      user_id: _get(authState, "authUser.id", undefined),
      role_category_code: _get(authState, "authUser.role_level_code", undefined)  ===  "ADN_NTL" ? undefined : "SUP|PDM_ADN",
      current_role_category: "PDM_ADN",

    };
    // const queryString = (getState.unread && getState.read) ? "" : getState.read ? "&read=true" : "&read=false";
    callApi(`${notificationsAPI.url}?page=${page}&${fromObjectToQueryString(queryStringObj)}`)
      .method("get")
      .headers(true)
      .isMultipart(false)
      .send((error, result) => {
        if (!error) {
          setState(prevState => {
            stateRef.current = {
              ...prevState,
              "unread": prevState.unread,
                "read": prevState.read,
              dataList: isAppend ? [...prevState.dataList, ...result.data.data] : result.data.data,
              meta: result.data.meta,
              showInitialLoader: false,
              showLoader: false,
              initialRender: false
            };

            return {
              ...prevState,
              "unread": prevState.unread,
                "read": prevState.read,
              dataList: isAppend ? [...prevState.dataList, ...result.data.data] : result.data.data,
              meta: result.data.meta,
              showInitialLoader: false,
              showLoader: false,
              initialRender: false
            }
          });
        } else {
          setState(prevState => {
            stateRef.current = {
              ...prevState,
              showInitialLoader: false,
              showLoader: false,
              initialRender: false
            };

            return {
              ...prevState,
              showInitialLoader: false,
              showLoader: false,
              initialRender: false
            }
          });
        }
      });
  }

  useEffect(() => {
    document.addEventListener('scroll', trackScrolling);
    getNotifications();

    return () => {
      document.removeEventListener('scroll', trackScrolling);
    }
  }, []);

  useEffect(() => {
    if (!getState.initialRender) {
      if (!getState.read && !getState.unread) {
        setState(prevState => {
          stateRef.current = {
            ...prevState,
            dataList: [],
            meta: {},
            modelData: {},
            showInitialLoader: false
          };

          return {
            ...prevState,
            dataList: [],
            meta: {},
            modelData: {},
            showInitialLoader: false
          }
        });
      } else {
        getNotifications(1, false);
      }
    }

  }, [getState.read, getState.unread]);

  const marksAsRead = (index) => {
    // Update local state
    let dataList = [...getState.dataList];
    dataList[index]["read"] = 1;

    // Decrement notification count by one
    uiAction.decrementNotificationCountFn();

    // Call BE API
    callApi(`${notificationsAPI.url}/${dataList[index]["id"]}`)
      .method("put")
      .headers(true)
      .body({})
      .isMultipart(false)
      .send((error, result) => {
        if (error) {
          uiAction.incrementNotificationCountFn();
        }
      });
  };

  const getNotificationType = (type) => {
    switch (type) {
        case NotificationTypesList.Role_Change:
            return "Role Change";
        case NotificationTypesList.Serial_number_Change:
            return "Serial Number Change";
        case NotificationTypesList.Approval_Requests:
            return "Approval Requests";
        case NotificationTypesList.ReOrder_Level_Reached:
            return "Re-Order Level Reached";
        case NotificationTypesList.user_transfer:
              return "Transferred User Request";
        default:
            return "-";
    }
  };

  return (
    <div className="fullWidthDiv notificationMainWrapper" ref={scrollDiv}>
      <UICard elementStyle={"notificationFrom"}>
        <CheckBox
          labelText={"Unread"}
          inputValue={getState.unread}
          onChangeFn={(eventObj) => setState({
            ...getState,
            unread: eventObj.value,
            showInitialLoader: true
          })}
        />

        <CheckBox
          labelText={"Read"}
          inputValue={getState.read}
          onChangeFn={(eventObj) => setState({
            ...getState,
            read: eventObj.value,
            showInitialLoader: true
          })}
        />
      </UICard>

      {
        (getState.showInitialLoader) ? (
          <div className="fullWidthDiv">
            <center>
              <CircleLoaderElement />
            </center>
          </div>
        ) : (
          <Fragment>
            {
              (getState.dataList.length === 0) ? (
                <UICard>
                  <div className="fullWidthDiv defaultMarginTopBottom">
                    No notification(s) found.
                  </div>
                </UICard>
              ) : (
                <Fragment>
                  {
                    getState.dataList.map((data, index) => {
                      return (
                        <UICard
                          key={index}
                          elementStyle={`notificationWrapper ${data.read === 0 ? "new" : "seen"}`}
                          onClickFn={() => {
                            if (data.read === 0)
                              marksAsRead(index);

                            setState({
                              ...getState,
                              showHideModel: true,
                              modelData: data
                            })
                          }}
                        >
                          <div className="notificationContentWrapper">
                            <h1 className="defaultHeading">{data.heading}</h1>
                            <p>{data.subject}</p>
                          </div>

                          <div className="notificationTimeWrapper">
                            <i className="fas fa-circle newIcon"></i>
                            <p>{dateObjectToString(data.created_at, "dd/mm/YY HH:MM")}</p>
                          </div>
                        </UICard>
                      )
                    })
                  }

                  <div className="fullWidthDiv footerWrapper ">
                    <center>
                      {
                        (getState.showLoader) ? <CircleLoaderElement /> : null
                      }
                    </center>
                  </div>

                  <DialogPopup
                    isOpen={getState.showHideModel}
                    onClosePopupFn={() => setState({
                      ...getState,
                      showHideModel: false
                    })}
                    dialogTitle={"View Notification Information"}
                    isCustomButton={false}
                    customButtonComponent={ViewButton}
                  >
                    <div className="fullWidthDiv">
                      <dl className="row">
                        <dt className="col-sm-2">Type</dt>
                        <dd className="col-sm-10">{getNotificationType(_get(getState, "modelData.notification_type_code", ""))}</dd>

                        <dt className="col-sm-2">Description</dt>
                        <dd className="col-sm-10">{_get(getState, "modelData.content", "")}</dd>

                        <dt className="col-sm-2">Received at</dt>
                        <dd className="col-sm-10">{dateObjectToString(_get(getState, "modelData.created_at", ""), "dd/mm/YY HH:MM")}</dd>
                      </dl>
                    </div>
                  </DialogPopup>
                </Fragment>
              )
            }
          </Fragment>
        )
      }
    </div>
  )
};

const ViewButton = () => {
  return (
    <CheckPermission
      permission={permissions.NOTIFICATION_VIEW.permissions}
    >
      <InputButton btnText="View " btnName="View" />
    </CheckPermission>
  );
};

export {
  Notification,
  NotificationUI
};