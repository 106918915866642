import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 'bold',
        fontSize: 15
      }
    
  },
});


export default function DashboardDataGrid({rows, columns, isNational, loading}) {
  const classes = useStyles()
  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        className={classes.root}
        rows={rows}
        getRowId={(row) => !isNational ? row.authority_id : row.pdmt_id}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
        disableColumnMenu={true}
        showColumnRightBorder
        showCellRightBorder
        disableColumnSelector
        disableExtendRowFullWidth={true}
        disableColumnFilter
        autoHeight
        autoPageSize
        loading={loading}
      />
    </div>
  );
}