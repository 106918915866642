/*
 * @Author: Chanaka Wickramasinghe
 * @Description: Display Error Page
 * @Date: 2020-05-08 16:02:44
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2020-05-08 16:48:15
 */

import React, { useContext } from "react";

import { InputButton } from "../../../ui-components/ui-elements/form";
import { CoreContext } from "../../core/context-providers/CoreContext.provider";
import { AuthContext } from "../../core/context-providers/AuthContext.provider";
import { userLogoutAPI } from "../../../../config/apiUrl.config";
import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";

const DisplayError = () => {
    const [coreState, coreAction] = useContext(CoreContext);
    const [authStatus, authAction] = useContext(AuthContext)

    return (
        <div className="errorBackGround">
            <div className="itemWrapper">
                <h1>Somthing went wrong...!!</h1>
                <InputButton
                    elementWrapperStyle="buttonColor"
                    elementStyle="buttonColor"
                    btnName="submit"
                    btnText={"Try signing"}
                    onClickBtnFn={() => logoutApiCall(coreAction, authStatus, authAction)}
                />
            </div>
        </div>
    )
};

const logoutApiCall = (coreAction, authStatus, authAction) => {

    const requestBody = {
        //client_id:_get(authStatus, 'keycloak.clientId', null),
        refresh_token: _get(authStatus, 'keycloak.refreshToken', null)
    }
    coreAction.sendRequestFn(userLogoutAPI.url)
        .isMultipart(false)
        .method("post")
        .body(requestBody)
        .setLoader(true)
        .setInitStoring("none", null)
        .headers(true)
        .send((error, result) => {
            if (!error) {
                authAction.unauthorisedUserFn();
            }
        });
}

export default DisplayError;
