import React from "react";

import { DatePickerWithState, FormWrapper, SelectBoxWithState } from "../../../../ui-components/ui-elements/form";
import { templateTypes } from "../../../../../config/template.config";
import {
    licenceTypeListApi
} from "../../../../../config/apiUrl.config";

const SearchFineConfigurations = (props) => {
    const formKey = `${props.dataTableKey}_search`;

    return (
        <FormWrapper
            setGroupName={formKey}
        >
            <div className={"row"}>
                <div className={"col-md-4"}>
                    <SelectBoxWithState
                        uiType={templateTypes.bootstrap}
                        formGroupName={formKey}
                        inputName={"licence_type"}
                        labelText="Licence Type"
                        isEnableAllOption={true}
                        apiUrl={`${licenceTypeListApi.url}/list?codes=NO&codes=NU`}
                        apiStateKey={licenceTypeListApi.key}
                        valueName={"name"}
                    />
                </div>

                <div className={"col-md-4"}>
                    <DatePickerWithState
                        uiType={templateTypes.bootstrap}
                        formGroupName={formKey}
                        labelText="Effective From"
                        inputName={"effective_from"}
                    />
                </div>
            </div>
        </FormWrapper>
    );
};

export default SearchFineConfigurations;