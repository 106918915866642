/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-09-08 16:08:58 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-16 13:23:13
 */

import React,{useState,useContext} from 'react';

import {VariableToComponent} from "../ui-elements/common/BaseElements";
import {UIContext} from "../../ui-components/context-providers/UIContext.provider";



 const CounterTemplate=({
    children=null,
    FooterComponent=null,
    isShowFooter=true,
    propsToFooter={}
 })=>{
     const [getState,setState]=useState({
        "licenseSequenceVal":"231231231312434",
        "licenseSequenceStatus":true
     });
     const [uiState,uiDispach]=useContext(UIContext);
    return (
        <div className={"counterTemplateWrapper"}>
            <div className="counterTemplateHeaderWrapper">
                <div className="pageHeading">
                    <h1>Central Province</h1>
                    <h5>Kundasale</h5>
                </div>
                <div className="pageHeadingLinkWrapper">

                    <div className="licenseSequenceInputBox">
                        <input disabled={getState.licenseSequenceStatus} value={getState.licenseSequenceVal} onChange={(event)=>{
                            setState({
                                ...getState,
                                "licenseSequenceVal":event.target.value
                            })
                        }} />
                        <button onClick={()=>setState({
                            ...getState,
                            licenseSequenceStatus:!getState.licenseSequenceStatus
                            })}>
                        <i className="mdi mdi-lead-pencil"></i>
                        </button>
                    </div>
                    <button onClick={()=>uiDispach.toggleFullScreen()}>
                        <i className={`mdi ${(uiState.fullScreen===false)?"mdi-arrow-expand-all":"mdi-arrow-collapse-all"}`}></i>
                    </button>

                    <button>
                    <i className="mdi mdi-calculator"></i>
                    </button>
                    <button>
                    <i className="mdi mdi-bell"></i>
                    </button>
                    <button>
                    <i className="mdi mdi-logout"></i>
                    </button>
                    
                </div>
            </div>
            <div className="counterTemplateBodyWrapper">
                {children}
            </div>

            {
                (isShowFooter===true)?(
                    <div className="counterTemplateFooterWrapper">      
                        {
                            (FooterComponent!==null)?(
                                <VariableToComponent component={FooterComponent} props={propsToFooter}/>    
                            ):(null)
                        }          
                    </div>
                ):(null)
            }

            
        </div>
    )
 }

 export {
    CounterTemplate
 }