import React, { Fragment, useContext } from "react";

import { templateTypes } from "../../../../../config/template.config";
import {
    SelectBoxWithState,
    InputBoxWithState,
    RoundButton,
    NumberInputBoxWithState,
    InputButtonWithState,
    FormWrapper
} from "../../../../ui-components/ui-elements/form";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { ratesToValues } from "../../../../../config/DBstatus.config";
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";

const MonthRange = (props) => {
    const [formState, formAction] = useContext(FormContext);

    const getToValueList = (index) => {
        if (index === 0) {
            return ratesToValues;
        } else {
            let temp = [...ratesToValues];
            temp = temp.filter((value) => value.id > _get(formState, `${props.formKey}.monthRanges.${index - 1}.to_value`));
            return temp;
        }
    };

    const checkPlusBtnVisibility = (index) => {
        return (
            _get(formState, `${props.formKey}.monthRanges.${index}.to_value`, "") !== ""
            &&
            Object.keys(_get(formState, `${props.formKey}.monthRanges`, {})).length === index + 1
            &&
            _get(formState, `${props.formKey}.monthRanges.${index}.to_value`, "") < 12
        );
    };

    return (
        <Fragment>

            {
                Object.keys(_get(formState, `${props.formKey}.monthRanges`, {})).map((value, index) => {
                    return (
                        <div className={"row"} key={index}>
                            <div className="col-md-3 col-sm-6 floatLeft">
                                <InputBoxWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName={props.formKey}
                                    inputStatePath={`${props.formKey}.monthRanges.${index}.from_value`}
                                    labelText="From"
                                    isDisabled={true}
                                />
                            </div>

                            <div className="col-md-3 col-sm-6 floatLeft">
                                <SelectBoxWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName={props.formKey}
                                    inputStatePath={`${props.formKey}.monthRanges.${index}.to_value`}
                                    labelText="To"
                                    isRequired={true}
                                    dataList={getToValueList(index)}
                                    emptySelectOptionTxt={"Select a Value"}
                                />
                            </div>

                            <div className="col-md-3 col-sm-6 floatLeft">
                                <NumberInputBoxWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName={props.formKey}
                                    inputStatePath={`${props.formKey}.monthRanges.${index}.charge`}
                                    labelText="Charge (LKR)"
                                    isRequired={true}
                                    isAllowNegative={false}
                                    decimalScale={0}
                                    isAllowedFn={(value) => {
                                        const checkValue = value.value.toString();
                                        return checkValue.length <= 6;
                                    }}
                                />
                            </div>

                            <div className="col-md-3 col-sm-6 floatLeft roundBtnContainer">
                                <div className={"roundBtnVerticalCenter"}>
                                    {
                                        (checkPlusBtnVisibility(index)) ? (
                                            <RoundButton
                                                elementStyle={"mr-2"}
                                                iconClass={"fas fa-plus"}
                                                onClickBtnFn={(eventData) => {
                                                    formAction.changeInputFn(props.formKey, "", `${props.formKey}.monthRanges.${index + 1}.from_value`, 1);
                                                }}
                                            />
                                        ) : null
                                    }

                                    {
                                        (index > 0 && Object.keys(_get(formState, `${props.formKey}.monthRanges`, {})).length === index + 1) ? (
                                            <RoundButton
                                                iconClass={"fas fa-minus"}
                                                onClickBtnFn={(eventData) => {
                                                    let monthRangeObj = _get(formState, `${props.formKey}.monthRanges`, {});
                                                    delete monthRangeObj[index.toString()];
                                                    formAction.changeInputFn(props.formKey, "", `${props.formKey}.monthRanges`, monthRangeObj);
                                                }}
                                            />
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            <div className="col-md-9 col-sm-12 ">

                <InputButtonWithState
                    btnText="Clear All Ranges"
                    startIcon="fas fa-sync-alt"
                    elementWrapperStyle={"floatRight"}
                    formGroupName={props.formKey}
                    //  mergeToForm={defaultSearchFormObject}
                    onClickBtnFn={(event) => {
                        formAction.changeInputFn(props.formKey, "", `${props.formKey}.monthRanges.0.to_value`, "");
                        formAction.changeInputFn(props.formKey, "", `${props.formKey}.monthRanges.0.charge`, null);
                        Object.keys(_get(formState, `${props.formKey}.monthRanges`, {})).map((value, index) => {
                            // formAction.changeInputFn(props.formKey, "", `${props.formKey}.button.${index}`, false);
                            let monthRangeObj = _get(formState, `${props.formKey}.monthRanges`, {});
                            delete monthRangeObj[(index + 1).toString()];
                            formAction.changeInputFn(props.formKey, "", `${props.formKey}.monthRanges`, monthRangeObj);

                        })

                    }}
                />
            </div>
        </Fragment>
    );
};


const WeightRange = (props) => {
    const [formState, formAction] = useContext(FormContext);

    return (

        <Fragment>

            {
                Object.keys(_get(formState, `${props.formKey}.weightRanges`, {})).map((value, index) => {
                    return (

                        <div className={"row"} key={index}>
                            <div className="col-md-3 col-sm-6 floatLeft">
                                <NumberInputBoxWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName={props.formKey}
                                    inputStatePath={`${props.formKey}.weightRanges.${index}.from_value`}
                                    labelText="From (Kg)"
                                    isDisabled={true}
                                    isAllowNegative={false}
                                    decimalScale={0}
                                    isAllowedFn={(value) => {
                                        const checkValue = value.value.toString();
                                        return checkValue.length <= 6;
                                    }}
                                />
                            </div>

                            <div className="col-md-3 col-sm-6 floatLeft">
                                <NumberInputBoxWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName={props.formKey}
                                    inputStatePath={`${props.formKey}.weightRanges.${index}.to_value`}
                                    labelText="To (Kg)"
                                    isRequired={true}
                                    isAllowNegative={false}
                                    decimalScale={0}
                                    isAllowedFn={(value) => {
                                        const checkValue = value.value.toString();
                                        return checkValue.length <= 6;
                                    }}
                                    isDisabled={_get(formState, `${props.formKey}.button.${index}`, false) === true ? true : false}
                                />
                            </div>

                            <div className="col-md-3 col-sm-6 floatLeft">
                                <NumberInputBoxWithState
                                    uiType={templateTypes.bootstrap}
                                    formGroupName={props.formKey}
                                    inputStatePath={`${props.formKey}.weightRanges.${index}.charge`}
                                    labelText="Charge (LKR)"
                                    isRequired={true}
                                    isAllowNegative={false}
                                    decimalScale={0}
                                    isAllowedFn={(value) => {
                                        const checkValue = value.value.toString();
                                        return checkValue.length <= 6;
                                    }}
                                />
                            </div>

                            <div className="col-md-3 col-sm-6 floatLeft roundBtnContainer">
                                <div className={"roundBtnVerticalCenter"}>
                                    {
                                        (_get(formState, `${props.formKey}.weightRanges.${index}.to_value`, "") !== "" && Object.keys(_get(formState, `${props.formKey}.weightRanges`, {})).length === index + 1) ? (
                                            <RoundButton
                                                elementStyle={"mr-2"}
                                                iconClass={"fas fa-plus"}
                                                onClickBtnFn={(eventData) => {
                                                    const currentToValue = parseInt(_get(formState, `${props.formKey}.weightRanges.${index}.to_value`));
                                                    formAction.changeInputFn(props.formKey, "", `${props.formKey}.weightRanges.${index + 1}.from_value`, currentToValue + 1);
                                                    formAction.changeInputFn(props.formKey, "", `${props.formKey}.button.${index}`, true);

                                                }}
                                            />
                                        ) : null
                                    }

                                    {
                                        (index > 0 && Object.keys(_get(formState, `${props.formKey}.weightRanges`, {})).length === index + 1) ? (
                                            <RoundButton
                                                iconClass={"fas fa-minus"}
                                                onClickBtnFn={(eventData) => {
                                                    let weightRangeObj = _get(formState, `${props.formKey}.weightRanges`, {});
                                                    delete weightRangeObj[index.toString()];
                                                    formAction.changeInputFn(props.formKey, "", `${props.formKey}.weightRanges`, weightRangeObj);
                                                    formAction.changeInputFn(props.formKey, "", `${props.formKey}.button.${index - 1}`, false);
                                                }}
                                            />
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })

            }
            <div className="col-md-9 col-sm-12">

                <InputButtonWithState
                    btnText="Clear All Ranges"
                    startIcon="fas fa-sync-alt"
                    elementWrapperStyle={"floatRight"}
                    formGroupName={props.formKey}
                    //  mergeToForm={defaultSearchFormObject}
                    onClickBtnFn={(event) => {
                        formAction.changeInputFn(props.formKey, "", `${props.formKey}.weightRanges.0.to_value`, "");
                        formAction.changeInputFn(props.formKey, "", `${props.formKey}.weightRanges.0.charge`, null);
                        Object.keys(_get(formState, `${props.formKey}.weightRanges`, {})).map((value, index) => {
                            formAction.changeInputFn(props.formKey, "", `${props.formKey}.button.${index}`, false);
                            let weightRangeObj = _get(formState, `${props.formKey}.weightRanges`, {});
                            delete weightRangeObj[(index + 1).toString()];
                            formAction.changeInputFn(props.formKey, "", `${props.formKey}.weightRanges`, weightRangeObj);
                        })

                    }}
                />
            </div>
        </Fragment>

    );
};


export { MonthRange, WeightRange };