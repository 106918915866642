/*
 * @Author: Sujith
 * @Date: 2020-09-28 11:11:46 
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-30 11:31:08
 */
import React, { Fragment, useContext } from "react";

import { AuditDetail, UICard, ViewDetailBlock } from "../../../../ui-components/ui-elements/common/BaseElements";
import { DataTable } from "../../../../ui-components/ui-elements/table/DataTable";
import { FormWrapper } from "../../../../ui-components/ui-elements/form";
import { rateListAPI } from "../../../../../config/apiUrl.config";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { _get, _orderBy } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { isEmptyValue, thousandSeparator } from "../../../../../helpers/common-helpers/common.helpers";

const ViewRate = (props) => {
    const formKey = `${rateListAPI.key}_view`;
    const [formState] = useContext(FormContext);

    const getScenario = (licenceType, code) => {
        if (licenceType === "NO" && code === "ANN")
            return "1";
        else if (licenceType === "NO" && (code === "GRW" || code === "TRW"))
            return "2";
        else if (licenceType === "NO" && code === "PES")
            return "3";
        else if (licenceType === "NU" && code === "MTR")
            return "4";
        else if (licenceType === "DE" && code === "ANN")
            return "5";
        else if (licenceType === "CA" && code === "MTH")
            return "6";
    };

    const sortRanges = (ranges = [], licenceType, code) => {
        const scenario = getScenario(licenceType, code);

        if (scenario === "2") {
            ranges = _orderBy(ranges, ["from_value"], ["asc"]);
            ranges[ranges.length - 1] = {
                ...ranges[ranges.length - 1],
                to_value: "Above"
            };
        } else if (scenario === "4") {
            ranges = _orderBy(ranges, ["to_value"], ["asc"]);
        }

        return ranges;
    };

    return (
        <Fragment>
            <FormWrapper
                setGroupName={formKey}
                apiUrl={`${rateListAPI.url}/${props.row.id}`}
                onRebuildResponseFn={(response) => {
                    return {
                        description: response.description,
                        vehicle_class: response.vehicle_class_id ? response.vehicle_class_id.name : "-",
                        fuel_type: response.fuel_type_id ? response.fuel_type_id.name : "-",
                        effective_from: response.effective_from,
                        licence_type: response.licence_type_id.name,
                        lcn_rate_ranges: sortRanges(response.lcn_rate_ranges, response.licence_type_id.code, response.charging_category_code.code),
                        charging_category: response.charging_category_code.name,
                        scenario: getScenario(response.licence_type_id.code, response.charging_category_code.code),
                        created_at: response.created_at,
                        updated_at: response.updated_at,
                        created_by: response.created_by,
                        updated_by: response.updated_by,
                        created_location: response.created_location,
                        updated_location: response.updated_location,
                        created_role_id: response.created_role_id,
                        updated_role_id: response.updated_role_id,
                        "_onLoad": false
                    }
                }}
            >
                <UICard>
                    <ViewDetailBlock
                        label={"Rate Description"}
                        value={_get(formState, `${formKey}.description`, "")}
                        labelCol={6}
                    />

                    <ViewDetailBlock
                        label={"Charging Category"}
                        value={_get(formState, `${formKey}.charging_category`, "")}
                        labelCol={6}
                    />

                    <ViewDetailBlock
                        label={"Licence Type"}
                        value={_get(formState, `${formKey}.licence_type`, "")}
                        labelCol={6}
                    />

                    <ViewDetailBlock
                        label={"Fuel Type"}
                        value={_get(formState, `${formKey}.fuel_type`, "")}
                        labelCol={6}
                    />

                    <ViewDetailBlock
                        label={"Vehicle Class"}
                        value={_get(formState, `${formKey}.vehicle_class`, "")}
                        labelCol={6}
                    />

                    <ViewDetailBlock
                        label={"Effective From"}
                        value={dateObjectToString(_get(formState, `${formKey}.effective_from`, new Date()), "dd/mm/YY HH:MM")}
                        labelCol={6}
                    />

                    {
                        (["1", "3", "5", "6"].includes(_get(formState, `${formKey}.scenario`, ""))) ? (
                            <ViewDetailBlock
                                label={"Charge (LKR)"}
                                value={thousandSeparator(_get(formState, `${formKey}.lcn_rate_ranges[0].charge`, ""), true)}
                                labelCol={6}
                            />
                        ) : null
                    }
                </UICard>

                <AuditDetail
                    createdById={_get(formState, `${formKey}.created_by`, "")}
                    updatedById={_get(formState, `${formKey}.updated_by`, "")}
                    createdLocationId={_get(formState, `${formKey}.created_location`, "")}
                    updatedLocationId={_get(formState, `${formKey}.updated_location`, "")}
                    createdAt={dateObjectToString(_get(formState, `${formKey}.created_at`, ""), "dd/mm/YY HH:MM")}
                    updatedAt={dateObjectToString(_get(formState, `${formKey}.updated_at`, ""), "dd/mm/YY HH:MM")}
                    createdRoleId={_get(formState, `${formKey}.created_role_id`, '')}
                    updatedRoleId={_get(formState, `${formKey}.updated_role_id`, '')}
                    labelCol={6}
                />

                {
                    (["2", "4"].includes(_get(formState, `${formKey}.scenario`, ""))) ? (
                        <div className={"defaultMarginTop"}>
                            <DataTable
                                tableName={_get(formState, `${formKey}.scenario`, "2") === "2" ? "Charges for Weight Ranges" : "Charges for Month Ranges"}
                                dataTableKey={"chargeRange"}
                                isSetAction={false}
                                isSetTableFooter={false}
                                tableHeaderList={[
                                    { displayName: _get(formState, `${formKey}.scenario`, "2") === "2" ? "From (Kg)" : "From", key: "from_value", isSort: false },
                                    { displayName: _get(formState, `${formKey}.scenario`, "2") === "2" ? "To (Kg)" : "To", key: "to_value", isSort: false, onPrintFn: (row) => isEmptyValue(row.to_value) ? "-" : row.to_value },
                                    { displayName: "Charge (LKR)", key: "charge", isSort: false, onPrintFn: (row) => thousandSeparator(row.charge, true) },
                                ]}
                                tableBodyList={_get(formState, `${formKey}.lcn_rate_ranges`, [])}
                            />
                        </div>
                    ) : null
                }
            </FormWrapper>
        </Fragment>
    );
};

export default ViewRate;