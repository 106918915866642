/*
 * @Author: Farah Jabir
 * @Date: 2020-10-19 10:18:15
 * @Last Modified by: Senura Weerasinghe
 * @Last Modified time: 2021-06-22 15:42:08
 */

import React, { Fragment, useContext } from "react";
import { SubmitButton } from "../../../../../ui-components/ui-elements/form";
import { DataTable, actionBtnTypes, apiResponseStoringType } from "../../../../../ui-components/ui-elements/table/DataTable";
import { ViewIssuingOfficer } from "./issuing-officer-includes/ViewIssuingOfficer";
import { IssuingOfficerForm } from "./issuing-officer-includes/IssuingOfficerForm";
import { FormContext } from "../../../../core/context-providers/FormContext.provider";
import { _get } from "../../../../../../helpers/common-helpers/lodash.wrappers";
import { vrpCertificateOfficersListAPI, vrpCompanyListAPI, userPasswordResetAPI } from './../../../../../../config/apiUrl.config';
import { getDataByFormObject } from "../../../../../../helpers/common-helpers/common.helpers";
import { UIContext } from "../../../../../ui-components/context-providers/UIContext.provider";
import { AuthContext } from "../../../../core/context-providers/AuthContext.provider";

const DeleteFineConfigurations = () => {
    return (
        <div className="fullWidthDiv">
            <p>Are you sure you want to Reset the Password?</p>
        </div>
    )
}

const ResetPasswordBtn = (props) => {
    const [, uiAction] = useContext(UIContext);

    return (
        <SubmitButton
            btnText="Yes"
            elementStyle="btnWrapper"
            startIcon={"far fa-save"}
            dataTableKey={vrpCertificateOfficersListAPI.key}
            isValidate={false}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "put",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: "DATA_TABLE_LINKED_FORM",
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    "url": `${userPasswordResetAPI.url}/${_get(props, "row.user.id", null)}`,
                    "key": userPasswordResetAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {
                return getDataByFormObject(formObject);
            }}
            onResponseCallBackFn={(error) => {
                if (!error) {
                    uiAction.setFlashMessage({
                        status: true,
                        message: "Reset password success",
                        messageType: "success"
                    });

                    props.onClosePopupFn(false);
                }
            }}
        />
    )
}

const SaveBtn = (props) => {
    const uiType = props.uiType;
    const successMsg = props.uiType === "CREATE" ? "Issuing Officer is successfully saved." : "Issuing Officer is successfully updated.";
    const [authStatus] = useContext(AuthContext);
    const provinceCode = _get(authStatus, "authUser.pdmt_code", "");
    return (
        <Fragment>
            <SubmitButton
                btnText={uiType === "CREATE" ? "Save" : "Update"}
                startIcon={props.uiType === "CREATE" ? "far fa-save" : "far fa-edit"}
                elementStyle="btnWrapper"
                formGroupName={props.formGroupName}
                isValidate={true}
                flashMessages={{
                    "success": {
                        status: true,
                        message: successMsg,
                        messageType: "success"
                    }
                }}
                mapValidationErrorObj={{
                    user_name: "Username already exists.",
                    username: "Username is required.",
                    email: "Email already exists.",
                    display_name: "Display Name already exists.",

                }}
                validationObject={{
                    fileds: {
                        full_name: "Full name",
                        contact_number: "Contact number",
                        email: "E-mail",
                        status: "status",
                        user_name: "Username",
                        display_name: "Display name",
                        isSMS: "Notify via SMS"
                    },
                    rules: {
                        full_name: "required|max:150",
                        contact_number: "required|min:9|max:10",
                        email: "required|email",
                        status: "required",
                        display_name: "required|max:100",
                        user_name: "required",
                        isSMS: "required"

                    },
                    message: {},
                }}
                dataTableKey={props.dataTableKey}
                callApiObject={{
                    isSetHeaders: true,
                    multipart: false,
                    method:
                        uiType === "CREATE"
                            ? "post"
                            : "put",
                    onUpload: false,
                }}
                apiDataStoringObject={{
                    setLoader: true,
                    storingType: apiResponseStoringType.responseLinkToFrom
                }}
                onGetAPIEndPointFn={(formState) => {
                    return {
                        url:
                            uiType === "CREATE"
                                ? `${vrpCompanyListAPI.url}/${formState.reqId}/user`
                                : `${vrpCompanyListAPI.url}/${formState.reqId}/users/${formState.id}`,
                        key: vrpCertificateOfficersListAPI.key
                    }
                }}
                onChangeRequestBodyFn={(fromObject) => {
                    return getDataByFormObject({
                        ...fromObject,
                        full_name: fromObject.full_name,
                        contact_number: fromObject.contact_number,
                        email: fromObject.email,
                        active: (parseInt(fromObject.status)),
                        user_name: fromObject.user_name,
                        display_name: fromObject.display_name,
                        isSMS: (fromObject.isSMS === true || parseInt(fromObject.isSMS) === 1 ? 1 : 0),
                        isEmail: (fromObject.isEmail === true || parseInt(fromObject.isEmail) === 1 ? 1 : 0),
                        province_code: provinceCode
                    });
                }}
                onResponseCallBackFn={(response, error) => {
                    if (response.length === undefined && error === undefined && response.data.errors === undefined) {
                        props.onClosePopupFn();
                    }
                }}
            />
        </Fragment>
    );
}

const IssuingOfficers = () => {

    const [formState] = useContext(FormContext);
    const company_id = _get(formState[`${vrpCompanyListAPI.key}_edit`], "id", "");

    return (
        <div className="row">
            <div className="col-12 defaultMarginTopBottom">
                <DataTable
                    apiUrl={`${vrpCompanyListAPI.url}/${company_id}/users?sort_by=full_name|asc`}
                    dataTableKey={vrpCertificateOfficersListAPI.key}
                    isSetAction={true}
                    isSetTableFooter={true}
                    tableHeaderList={[
                        { displayName: "Full Name", key: "full_name", default: "-", isSort: false },
                        { displayName: "Email", key: "user.contact", isSort: false, onPrintFn: ({ user }) => user.contact.find((type) => type.contact_type === "EMA") !== undefined ? (user.contact.find((type) => type.contact_type === "EMA").contact) : ("-"), default: "-" },
                        { displayName: "Contact No.", key: "user.contact", isSort: false, onPrintFn: ({ user }) => user.contact.find((type) => type.contact_type === "MOB") !== undefined ? (user.contact.find((type) => type.contact_type === "MOB").contact) : ("-"), default: "-" },
                        { displayName: "Status", key: "user.active", isSort: false, onPrintFn: ({ user }) => user.active === 1 ? "Active" : "Inactive", default: "-", },
                    ]}
                    actionTypeList={[
                        {
                            actionType: actionBtnTypes.create,
                            modelContentElement: IssuingOfficerForm,
                            dialogPopupProps: {
                                isFullScreen: false,
                                isSaveButton: true,
                                isCloseButton: true,
                                dialogTitle: "Add Issuing Officer",
                                isCustomButton: true,
                                isConfirmationPopup: true,
                                customButtonComponent: SaveBtn,
                                customButtonComponentProps: { btnText: "Save", uiType: "CREATE", dataTableKey: vrpCertificateOfficersListAPI.key, formKey: vrpCertificateOfficersListAPI.key + "_create" },
                                closeButton: {
                                    btnText: "Cancel",
                                    startIcon: "far fa-times-circle",
                                },
                            },
                            parentToModelProps: {
                                requiredId: company_id,
                                formKey: vrpCertificateOfficersListAPI.key + "_create",
                                dataTableKey: vrpCertificateOfficersListAPI.key,
                                uiType: "CREATE"
                            },
                        },
                        {
                            actionType: actionBtnTypes.edit,
                            actionBtn: {
                                tooltip: 'Reset Password',
                                icon: "mdi mdi-account-key"
                            },
                            modelContentElement: DeleteFineConfigurations,
                            dialogPopupProps: {
                                isFullScreen: false,
                                dialogTitle: "Reset password",
                                closeButton: {
                                    btnText: "No",
                                    startIcon: "far fa-times-circle",
                                },
                                isCustomButton: true,
                                customButtonComponent: ResetPasswordBtn
                            }
                        },
                        {
                            actionType: actionBtnTypes.edit,
                            actionBtn: { tooltip: 'Edit' },
                            modelContentElement: IssuingOfficerForm,
                            dialogPopupProps: {
                                isFullScreen: false,
                                isSaveButton: true,
                                isConfirmationPopup: true,
                                dialogTitle: "Edit Issuing Officer",
                                closeButton: {
                                    btnText: "Close",
                                    startIcon: "far fa-times-circle",
                                },
                                isCustomButton: true,
                                customButtonComponent: SaveBtn,
                                customButtonComponentProps: { btnText: "Update", uiType: "EDIT", dataTableKey: vrpCertificateOfficersListAPI.key, formKey: vrpCertificateOfficersListAPI.key + "_edit" }
                            },
                            parentToModelProps: {
                                requiredId: company_id,
                                formKey: vrpCertificateOfficersListAPI.key + "_edit",
                                dataTableKey: vrpCertificateOfficersListAPI.key,
                                uiType: "EDIT"

                            },
                        },
                        {
                            actionType: actionBtnTypes.view,
                            actionBtn: {  tooltip: "View", },
                            modelContentElement: ViewIssuingOfficer,
                            dialogPopupProps: {
                                isFullScreen: false,
                                dialogTitle: "View Issuing Officer",
                                closeButton: {
                                    btnText: "Close",
                                    startIcon: "far fa-times-circle",
                                },
                            }

                        },
                    ]}>
                </DataTable>
            </div>
        </div>
    )
}

export { IssuingOfficers };
