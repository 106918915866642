/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-07-14 10:18:15
 * @Last Modified by: Migara Gunarathne
 * @Last Modified time: 2021-04-30 15.20
 */

import React, { Fragment, useContext } from "react";
import { UICard, AuditDetail, ViewDetailBlock } from "../../../../ui-components/ui-elements/common/BaseElements";
import { templateTypes } from "../../../../../config/template.config";
import {
  FormWrapper,
  InputBoxWithState,
  SelectBoxWithState,
  
} from "../../../../ui-components/ui-elements/form";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { dateObjectToString} from "../../../../../helpers/common-helpers/dateTime.helpers";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";


const SearchElement = ({ dataTableKey = "" }) => {
  const formKey = `${dataTableKey}_search`;
  const currentYear = new Date().getFullYear();
  const [authStatus, authAction] = useContext(AuthContext);
  const userRoleLevelCode = _get(authStatus, "authUser.role_level_code", "");
  return (
    <FormWrapper setGroupName={formKey}>
      <div className="row">
        <div className="col-md-3 col-sm-12 floatLeft">
          <SelectBoxWithState
            isEnableAllOption = {true}
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            dataList={[
              {
                id:  currentYear,
                value: currentYear,
              },
              {
                id: currentYear+1,
                value: currentYear+1,
              },
            ]}
            inputName={"year"}
            labelText="Year"
          />
        </div>
        <div className="col-md-3 col-sm-12 floatLeft">
          <SelectBoxWithState
            isEnableAllOption = {true}
            uiType={templateTypes.bootstrap}
            formGroupName={formKey}
            dataList={[
              {
                id: "01",
                value: "January",
              },
              {
                id: "02",
                value: "February",
              },
              {
                id: "03",
                value: "March",
              },
              {
                id: "04",
                value: "April",
              },
              {
                id: "05",
                value: "May",
              },
              {
                id: "06",
                value: "June",
              },
              {
                id: "07",
                value: "July",
              },
              {
                id: "08",
                value: "August",
              },
              {
                id: "09",
                value: "September",
              },
              {
                id: "10",
                value: "October",
              },
              {
                id: "11",
                value: "November",
              },
              {
                id: "12",
                value: "December",
              },
            ]}
            inputName={"month"}
            labelText="Month"
          />
        </div>
        <div className="col-md-3 col-sm-12 floatLeft">
          {userRoleLevelCode == 'ADN_NTL' ? 
            <InputBoxWithState
              formGroupName={formKey}
              labelText="Type"
              isDisabled={userRoleLevelCode == 'ADN_NTL' ? true : false}
              inputPlaceholder={"National"}
            />
          : 
            <SelectBoxWithState
              uiType={templateTypes.bootstrap}
              isEnableAllOption = {true}
              formGroupName={formKey}
              dataList={[
                // {
                //   id: "NAT",
                //   value: "National",
                // },
                {
                  id: "PRO",
                  value: "Province",
                },
                {
                  id: "AUT",
                  value: "Authority",
                }
              ]}
              inputName={"holiday_type_code"}
              labelText="Type"
            />
          }
        </div>
      </div>
    </FormWrapper>
  );
};

const ViewFineExcemption = (props) => {
  const numberOfLabelCol = 4;
  console.log("RRRRRRRRRRRRRRRRRRRRRRRRR", props);
  return (
    <Fragment>
      <UICard cardHeading="Holiday Information">
        <FormWrapper setGroupName={"test2"}>
          <ViewDetailBlock
            label={"Type"}
            value={(_get(props, `row.holiday_type_code.code`, '')) === 'NAT' ? ('National') : (_get(props, `row.holiday_type_code.code`, '')) === 'PRO' ? ('Province') : ('Authority')}
            labelCol={numberOfLabelCol}
          />
          <ViewDetailBlock
            label={"Name"}
            value={_get(props, `row.name`, '')}
            labelCol={numberOfLabelCol}
          />
          {_get(props, `row.pdmt_id`, '') !== null ? 
          <ViewDetailBlock
            label={"Province office"}
            value={_get(props, `row.pdmt_id.service.name`, '')}
            labelCol={numberOfLabelCol}
          /> : null }
           {_get(props, `row.authority_id`, '') !== null ? 
          <ViewDetailBlock
            label={"Authority"}
            value={_get(props, `row.authority_id.service.name`, '')}
            labelCol={numberOfLabelCol}
          /> : null }
          <ViewDetailBlock
            label={"Description"}
            value={_get(props, `row.description`, '')}
            labelCol={numberOfLabelCol}
          />
          <ViewDetailBlock
            label={"Status"}
            value={parseInt(_get(props, `row.active`, '')) === 1 ? ('Active') : ('Inactive')}
            labelCol={numberOfLabelCol}
          />
        </FormWrapper>
      </UICard>

      <UICard cardHeading="Holiday Range" elementStyle="defaultMarginTop">
        <FormWrapper setGroupName={"test2"}>
          <ViewDetailBlock
            label={"From"}
            value={dateObjectToString(_get(props, `row.from_date`, ''), "dd/mm/YY")}
            labelCol={numberOfLabelCol}
          />
          <ViewDetailBlock
            label={"To"}
            value={dateObjectToString(_get(props, `row.to_date`, ''), "dd/mm/YY")}
            labelCol={numberOfLabelCol}
          />
        </FormWrapper>
      </UICard>

      <AuditDetail
                
          createdById={_get(props, `row.created_by`, '')}
          updatedById={_get(props, `row.updated_by`, '')}
          createdLocationId={_get(props, `row.created_location`, '')}
          updatedLocationId={_get(props, `row.updated_location`, '')}
          createdAt={dateObjectToString(_get(props, `row.created_at`, ''), "dd/mm/YY HH:MM")}
          updatedAt={dateObjectToString(_get(props, `row.updated_at`, ''), "dd/mm/YY HH:MM")}
          createdRoleId={_get(props, `row.created_role_id`, '')}
          updatedRoleId={_get(props, `row.updated_role_id`, '')}
          labelCol={numberOfLabelCol}
      />
    </Fragment>
  );
};



export { ViewFineExcemption, SearchElement };
