import React, { Fragment } from 'react';
import { permissions } from '../../../config/permission.config';
import authorized from '../../hoc-middlewares/Authorized';
import { Navigate } from '../../ui-components/ui-elements/common/Navigate';
import VETCompany from './pages/VETCompany';

const VETCompanyRoutes = () => {
    return (
        <Fragment>
            <Navigate exact={true} path="/vetCompany" routeKey={"vetCompany"} component={authorized(VETCompany)} routePermissions={permissions.VET_COMPANY_MANAGE.permissions} />
        </Fragment>
    )
};

export default VETCompanyRoutes;