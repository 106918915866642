import React, { Fragment, useState, useEffect, useContext } from "react";
import { AuditDetail, UICard, ViewDetailBlock, CircleLoaderElement } from "../../../../ui-components/ui-elements/common/BaseElements";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { userRolesPermissionsListAPI } from "../../../../../config/apiUrl.config";
import { callApi } from "../../../../../helpers/common-helpers/callApi.helpers";
import { PermissionsPanel } from "../../../../ui-components/ui-elements/expansion-panel/PermissionPanel";
import { FormWrapper } from "../../../../ui-components/ui-elements/form";

const labelCol = 6;
const View = (props) => {

    const [permissions, setPermissions] = useState("loading");
    const [formState, formAction] = useContext(FormContext);
    const formKey = _get(props, `fromParent.formKey`, '');
    useEffect(() => {
        getInitialData();

    }, []);

    const getInitialData = () => {
        callApi(`${userRolesPermissionsListAPI.url}?sort_by=name|asc&status=true&application=${_get(props, `row.application`, null)}`)
            .headers(true)
            .method('get')
            .send((err, result) => {
                if (!err) {
                    setPermissions(result.data.data);
                }
            })
        if (_get(props, "fromParent.type", null) === "view") {

            const permitionSet = [];

            _get(props, "row.permissions", []).map((permition, index) => {
                permitionSet.push(permition.id);
            })
            formAction.changeInputFn(props.fromParent.formKey, 'permission_ids', "", Array.from(permitionSet));
        }
    }

    return (
        <Fragment>
            <FormWrapper
                setGroupName={formKey}
                formGroupLinkWith={formKey}
            >
                <UICard >
                    <div className="fullWidthDiv">
                        <ViewDetailBlock
                            labelCol={labelCol}
                            label="User Role Prefix"
                            value={_get(props, `row.prefix`, '-')}
                        />
                        <ViewDetailBlock
                            labelCol={labelCol}
                            label="User Role Name"
                            value={_get(props, `row.name`, '')}
                        />
                        <ViewDetailBlock
                            labelCol={labelCol}
                            label="Role Type"
                            value={_get(props, `row.role_level_code.role_type.name`, '')}
                        />
                        <ViewDetailBlock
                            labelCol={labelCol}
                            label="Role Level"
                            value={_get(props, `row.role_level_code.name`, '')}
                        />
                        <ViewDetailBlock
                            labelCol={labelCol}
                            label="Role Categoty"
                            value={_get(props, `row.role_category_code.name`, '')}
                        />
                        <ViewDetailBlock
                            labelCol={labelCol}
                            label="Status"
                            value={parseInt(_get(props, `row.active`, '')) === 1 ? ('Active') : ('Inactive')}
                        />
                    </div>
                </UICard>
                <div className="defaultPaddingTop">
                    <UICard cardHeading="Permissions" >
                        {
                            (permissions !== "loading") ? (
                                <div className="fullWidthDiv defaultPaddingTopBottom">
                                    <PermissionsPanel
                                        formGroupName={formKey}
                                        inputName="permission_ids"
                                        dataList={permissions}
                                        groupKey="id"
                                        groupDisplayNameKey="description"
                                        subPermissionKey="permissions"
                                        subPermissionUniqueKey="id"
                                        subPermissionDisplayNameKey="name"
                                        uiType={_get(props, "fromParent.type", null)}
                                    />
                                </div>
                            ) : (
                                <div className={"text-center"}>
                                    <CircleLoaderElement
                                        loaderSize={70}
                                        loaderStyle={3}
                                    />
                                </div>
                            )
                        }
                    </UICard>
                </div>

                <div className="fullWidthDiv">
                    <AuditDetail
                        labelCol="6"
                        createdAt={dateObjectToString(_get(props, `row.created_at`, ""), "dd/mm/YY HH:MM")}
                        updatedAt={dateObjectToString(_get(props, `row.updated_at`, ""), "dd/mm/YY HH:MM")}
                        createdById={_get(props, `row.created_by`, "")}
                        updatedById={_get(props, `row.updated_by`, "")}
                        createdLocationId={_get(props, `row.created_location`, "")}
                        updatedLocationId={_get(props, `row.updated_location`, "")}
                        createdRoleId={_get(props, `row.created_role_id`, '')}
                        updatedRoleId={_get(props, `row.updated_role_id`, '')}
                    />
                </div>
            </FormWrapper>
        </Fragment>
    );
};
export { View }