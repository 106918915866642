import React, { useEffect, useContext, useState } from 'react';
import { FormContext } from "../../core/context-providers/FormContext.provider";
import { UICard } from '../../../ui-components/ui-elements/common/BaseElements';
import { DashboardTemplate } from '../../../ui-components/templates/DashboardTemplate';
import Card from '../pages/includes/Card'
import Table from '../pages/includes/Table'
import Spinner from '../pages/includes/Spinner'
import { callApi } from "../../../../helpers/common-helpers/callApi.helpers";
import { DashboardDataApi } from "../../../../config/apiUrl.config";
import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import {
  setToLocalStorage,
  getFromLocalStorage
} from '../../../../helpers/common-helpers/manageStorage.helpers';
import { AuthContext } from "../../core/context-providers/AuthContext.provider";

const Dashboard = () => {
  const [authStatus, authAction] = useContext(AuthContext);

  const [loading, setLoading] = useState(true)
  const pdmtId = _get(authStatus, "authUser.pdmt_id", null);
  const roleLevelCode = _get(authStatus, "authUser.role_level_code", null);
 
  useEffect(() =>{
    if(roleLevelCode === "ADN_PRV") {
      setLoading(true)
      getDashboardPdmtData()
    } else if(roleLevelCode === "ADN_NTL") {
      setLoading(true)
      getDashboardNationalData()
    }
  }, [roleLevelCode,authStatus])

  const getDashboardPdmtData = async () => {
    // setLoading()
    await callApi(`${DashboardDataApi.url}?pdmt_id=${pdmtId}`)
    .method("get")
    .headers(true)
    .isMultipart(false)
    .send((error, response) => {
      if (!error) {
        setToLocalStorage('dashboard_data', response.data);
        setLoading(false)
      } else {
        setLoading(false)
      }
    });
  }

  const getDashboardNationalData = async () => {
    // setLoading(true)
    await callApi(`${DashboardDataApi.url}`)
    .method("get")
    .headers(true)
    .isMultipart(false)
    .send((error, response) => {
      if (!error) {
        setToLocalStorage('dashboard_data', response.data);
        setLoading(false)
      } else {
        setLoading(false)
      }
    });
  }
  const data = getFromLocalStorage('dashboard_data');
  const columns = (roleLevelCode === "ADN_PRV") ? [
    // { 
    //   field: 'id', 
    //   headerName: 'No.', 
    //   width: 100,
    //   valueGetter: (params) => (params.api.getRowIndex(params.row.id) + 1),
      
    // },
    {
      field: 'authority',
      headerName: 'Authority',
      flex: 0.1,
      sortable: false,
      minWidth: 420,
      valueGetter: (params) => {
       return `${params.getValue(params.row.authority_id, 'authority_name') || ''} (${
          params.getValue(params.row.authority_id, 'authority_code') || ''
        })`
      }
    },
    {
      field: 'active',
      headerName: 'Number of Active Counters',
      flex: 0.1,
      sortable: true,
      minWidth: 290,
      align: 'right',
      valueGetter: (params) => params.row.active_counters.length,
    },
    {
      field: 'inactive',
      headerName: 'Number of Inactive Counters',
      sortable: true,
      minWidth: 290,
      align: 'right',
      valueGetter: (params) => params.row.inactive_counters.length,
    }
  ] : (roleLevelCode === "ADN_NTL")?[
    {
      field: 'pdmt_name',
      headerName: 'PDMT',
      sortable: false,
      minWidth: 420,
    },
    {
      field: 'active',
      headerName: 'Number of Active Authorities',
      sortable: true,
      minWidth: 290,
      align: 'right',
      valueGetter: (params) => params.row.active_authorities.length,
    },
    {
      field: 'inactive',
      headerName: 'Number of Inactive Authorities',
      sortable: true,
      minWidth: 290,
      align: 'right',
      valueGetter: (params) => params.row.inactive_authorities.length,
    }
  ]:[];

  const getLoadedData = () => {
    if(roleLevelCode === "ADN_PRV") {
      return (
        <>
                <section style={{display:'flex', justifyContent:'space-around', flex:1, marginBottom: 50}}>
                    <Card 
                      title={'Total Authorities'} 
                      activeCount={data?.active_authorities_count} 
                      inactiveCount={data?.inactive_authorities_count}
                    />
                    <Card 
                      title={'Total Counters'} 
                      activeCount={data?.counter_list_by_authority?.reduce(
                        (acc, cur) => acc + cur.active_counters.length, 0
                      )}
                      inactiveCount={data?.counter_list_by_authority?.reduce(
                        (acc, cur) => acc + cur.inactive_counters.length, 0
                      )}
                    />
                    <Card 
                      title={'Total Users'} 
                      activeCount={data?.active_users_count_of_pdmt}
                      inactiveCount={data?.inactive_users_count_of_pdmt}
                    />
                
              </section>
              <section>
             
                <Table columns={(columns && columns?.length !== 0) ? columns : []} rows={data?.counter_list_by_authority ? data.counter_list_by_authority:[]} />
              </section>
              </>
      )
    } else if(roleLevelCode === "ADN_NTL") {
      return (
        <>
                <section style={{display:'flex', justifyContent:'space-around', flex:1, marginBottom: 50}}>
                    <Card 
                      title={'Total PDMTs'} 
                      activeCount={data?.active_pdmts_count} 
                      inactiveCount={data?.inactive_pdmts_count}
                    />
                    <Card 
                      title={'Total Authorities'} 
                      activeCount={data?.authority_list_by_pdmt?.reduce(
                        (acc, cur) => acc + cur.active_authorities.length, 0
                      )}
                      inactiveCount={data?.authority_list_by_pdmt?.reduce(
                        (acc, cur) => acc + cur.inactive_authorities.length, 0
                      )}
                    />
                    <Card 
                      title={'Total Users'} 
                      activeCount={data?.active_users_count_of_system}
                      inactiveCount={data?.inactive_users_count_of_system}
                    />
                
              </section>
              <section>            
                <Table columns={columns && columns?.length !== 0 ? columns : []} rows={data?.authority_list_by_pdmt ? data.authority_list_by_pdmt:[]} isNational />
              </section>
              </>
      )
    }
  }

  return (
    <DashboardTemplate
      pageTitle="Dashboard"
      breadCrumbList={[
        { urlTo: '/', displayName: 'Home' },
        { urlTo: '', displayName: 'Dashboard' },
      ]}
    >
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <UICard cardHeading="Admin Portal">
            {loading ?
            <section style={{display:'flex', justifyContent:'space-around', flex:1, margin: 50}}>
              <Spinner />
            </section>
            :
            getLoadedData()
            }
            
          </UICard>
        </div>
      </div>
    </DashboardTemplate>
  );
};

export default Dashboard;
