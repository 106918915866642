/*
 * @Author: Chanaka Wickramasinghe
 * @Date: 2020-06-25 21:37:44
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2021-06-17 09:37:56
 */

import React, { Fragment } from "react";

import { getRelevantImage } from "../../../../../helpers/upload-component-helpers/uploadComponent.helpers";
import { Icon } from "../../common/BaseElements";
import { uploadTypes } from "../UploadComponentBase64";


const emptyFun = (...para) => undefined;

const FileDisplay = ({
    elementStyle = "",
    file = "",
    apiUrl = "",
    isDownload = true,
    isDelete = true,
    isBase64Image = false,
    uploadType = uploadTypes.image,
    onDeleteFn = emptyFun,
    fileName=""
}) => {
    const getImageSrc = () => {
        if (isBase64Image) {
            return uploadType === uploadTypes.image && file.length !== 0 ? file : "images/file-common.png";
        }
        if (getRelevantImage(file) === null) {
            return `${apiUrl}${file}`;
        } else {
            return `images/${getRelevantImage(file)}`;
        }
    };

    return (
        <Fragment>
            {
                (file !== "") ? (
                    <div className={`display-image ${elementStyle}`}>
                        <img
                            src={getImageSrc()}
                            className={"img-thumbnail select-image"}
                        />
                        <p>{fileName}</p>
                        <div className={"image-hover"}>
                            {
                                (isDownload) ? (
                                    <Icon
                                        iconClass={"fas fa-file-download mr-4"}
                                        tooltip={"Download"}
                                        onClickFn={() => window.open(`${apiUrl}${file}`)}
                                    />
                                ) : null
                            }

                            {
                                (isDelete) ? (
                                    <Icon
                                        iconClass={"fas fa-trash-alt"}
                                        tooltip={"Delete"}
                                        onClickFn={() => onDeleteFn(file)}
                                    />
                                ) : null
                            }
                        </div>
                    </div>
                ) : null
            }
        </Fragment>
    )
};

export default FileDisplay;
