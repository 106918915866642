import React, { Fragment, useContext } from "react";

import { UICard, AuditDetail, ViewDetailBlock } from "../../../../ui-components/ui-elements/common/BaseElements";
import { CheckBox, FormWrapper } from "../../../../ui-components/ui-elements/form";
import { _findindex, _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { contactsArrayToObject } from "../../../../../helpers/common-helpers/contact.helpers";
import { ContactTypeCode } from "../../../../../config/enum.config";
import { paymentCategoriesListAPI, pdmtListAPI } from "../../../../../config/apiUrl.config";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { isEmpty } from "lodash";
const ViewProvinceOffice = ({ row }) => {

    const [formState] = useContext(FormContext);

    const getPaymentTypesAllowed = (categories = [], allowedTypes = []) => {
        let temp = {};

        categories.forEach((value) => {
            const index = _findindex(allowedTypes, (e) => value.code === e.payment_type_code);
            temp[value.code] = index !== -1;
        });

        return temp;
    }

    const getAddress = (type) => {
        const index = _findindex(_get(row, `service.addresses`, []), (e) => Number(e[type]));

        if (index !== -1) {
            if (type === "office_address") {
                return {
                    line1: _get(row, `service.addresses[${index}].line1`, ""),
                    line1_si: _get(row, `service.addresses[${index}].line1_si`, ""),
                    line1_ta: _get(row, `service.addresses[${index}].line1_ta`, ""),
                    line2: _get(row, `service.addresses[${index}].line2`, ""),
                    line2_si: _get(row, `service.addresses[${index}].line2_si`, ""),
                    line2_ta: _get(row, `service.addresses[${index}].line2_ta`, ""),
                    city: _get(row, `service.addresses[${index}].city`, ""),
                    city_si: _get(row, `service.addresses[${index}].city_si`, ""),
                    city_ta: _get(row, `service.addresses[${index}].city_ta`, ""),
                    postal_code: _get(row, `service.addresses[${index}].postal_code`, ""),
                    district: _get(row, `service.addresses[${index}].district.name`, ""),
                    province: _get(row, `service.addresses[${index}].province`, "")
                }
            } else {
                return {
                    line1: _get(row, `service.addresses[${index}].line1`, ""),
                    line2: _get(row, `service.addresses[${index}].line2`, ""),
                    city: _get(row, `service.addresses[${index}].city`, ""),
                }
            }
        } else {
            return {};
        }
    }
    const labelCol = 3;
    const cellCol = (12 - labelCol) / 3;
    return (
        <FormWrapper
            setGroupName={`${pdmtListAPI.key}_view`}
            apiUrl={`${paymentCategoriesListAPI.url}?status=true&internal_payment=true&sort_by=internal_order|ASC`}
            onRebuildResponseFn={(response) => {
                return {
                    address: getAddress("office_address"),
                    return_address: getAddress("postal_address"),
                    payment_categories: response,
                    ...getPaymentTypesAllowed(response, _get(row, `service.payment_types_allowed`, [])),
                    "_onLoad": false
                }
            }}
        >
            <Fragment>
                <UICard elementStyle="defaultPaddingLeft"  >
                    <ViewDetailBlock
                        labelCol={labelCol}
                        label={"Province Code"}
                        value={_get(row, `code`, "")}
                    />
                    <ViewDetailBlock
                        labelCol={labelCol}
                        label={"Province Prefix"}
                        value={_get(row, `prefix`, "")}
                    />
                    <ViewDetailBlock
                        labelCol={labelCol}
                        label={"Province"}
                        value={_get(row, `province.name`, "")}
                    />

                    <br />

                    <div className="row defaultPaddingTopBottom">
                        <div className={`col-${labelCol}`}>{/* Empty cell */}</div>
                        <div className={`col-${cellCol}`}>
                            <strong>English</strong>
                        </div>
                        <div className={`col-${cellCol}`}>
                            <strong>Sinhala</strong>
                        </div>
                        <div className={`col-${cellCol}`}>
                            <strong>Tamil</strong>
                        </div>
                        <div className="col-12">
                            <ViewDetailBlock
                                labelCol={labelCol}
                                label={"Province Office Name"}
                                value={
                                    <div className="row">
                                        <div className="col-4">{_get(row, `service.name`, "")}</div>
                                        <div className="col-4">{_get(row, `service.name_si`, "")}</div>
                                        <div className="col-4">{_get(row, `service.name_ta`, "")}</div>
                                    </div>
                                }
                            />
                            <ViewDetailBlock
                                labelCol={labelCol}
                                label={"Address Line 1"}
                                value={
                                    <div className="row">
                                        <div className="col-4">{_get(formState, `${pdmtListAPI.key}_view.address.line1`, "")}</div>
                                        <div className="col-4">{_get(formState, `${pdmtListAPI.key}_view.address.line1_si`, "")}</div>
                                        <div className="col-4">{_get(formState, `${pdmtListAPI.key}_view.address.line1_ta`, "")}</div>
                                    </div>
                                }
                            />
                            <ViewDetailBlock
                                labelCol={labelCol}
                                label={"Address Line 2"}
                                value={
                                    <div className="row">
                                        <div className="col-4">{_get(formState, `${pdmtListAPI.key}_view.address.line2`, "")}</div>
                                        <div className="col-4">{_get(formState, `${pdmtListAPI.key}_view.address.line2_si`, "")}</div>
                                        <div className="col-4">{_get(formState, `${pdmtListAPI.key}_view.address.line2_ta`, "")}</div>
                                    </div>
                                }
                            />
                            <ViewDetailBlock
                                labelCol={labelCol}
                                label={"City"}
                                value={
                                    <div className="row">
                                        <div className="col-4">{_get(formState, `${pdmtListAPI.key}_view.address.city`, "")}</div>
                                        <div className="col-4">{_get(formState, `${pdmtListAPI.key}_view.address.city_si`, "")}</div>
                                        <div className="col-4">{_get(formState, `${pdmtListAPI.key}_view.address.city_ta`, "")}</div>
                                    </div>
                                }
                            />
                            <ViewDetailBlock
                                labelCol={labelCol}
                                label={"Postal Code"}
                                value={
                                    <div className="row">
                                        <div className="col-4">{_get(formState, `${pdmtListAPI.key}_view.address.postal_code`, "")}</div>
                                        <div className="col-4"></div>
                                        <div className="col-4"></div>
                                    </div>
                                }
                            />
                            <ViewDetailBlock
                                labelCol={labelCol}
                                label={"District"}
                                value={
                                    <div className="row">
                                        <div className="col-4">{_get(formState, `${pdmtListAPI.key}_view.address.district`, "")}</div>
                                        <div className="col-4"></div>
                                        <div className="col-4"></div>
                                    </div>
                                }
                            />
                            <ViewDetailBlock
                                labelCol={labelCol}
                                label={"Contact Number 1"}
                                value={
                                    <div className="row">
                                        <div className="col-4">{contactsArrayToObject(_get(row, `service.contacts`, []), false, ContactTypeCode.MOBILE)}</div>
                                        <div className="col-4"></div>
                                        <div className="col-4"></div>
                                    </div>
                                }
                            />
                            <ViewDetailBlock
                                labelCol={labelCol}
                                label={"Contact Number 2"}
                                value={
                                    <div className="row">
                                        <div className="col-4">{contactsArrayToObject(_get(row, `service.contacts`, []), false, ContactTypeCode.PHONE)}</div>
                                        <div className="col-4"></div>
                                        <div className="col-4"></div>
                                    </div>
                                }
                            />
                            <ViewDetailBlock
                                labelCol={labelCol}
                                label={"E-mail"}
                                value={
                                    <div className="row">
                                        <div className="col-4">{contactsArrayToObject(_get(row, `service.contacts`, []), false, ContactTypeCode.EMAIL)}</div>
                                        <div className="col-4"></div>
                                        <div className="col-4"></div>
                                    </div>
                                }
                            />
                            <ViewDetailBlock
                                labelCol={labelCol}
                                label={"Fax"}
                                value={
                                    <div className="row">
                                        <div className="col-4">{contactsArrayToObject(_get(row, `service.contacts`, []), false, ContactTypeCode.FAX)}</div>
                                        <div className="col-4"></div>
                                        <div className="col-4"></div>
                                    </div>
                                }
                            />
                            <ViewDetailBlock
                                labelCol={labelCol}
                                label={"Province Website URL"}
                                value={
                                    <div className="row">
                                        <div className="col-4">{_get(row, `website_url`, "")}</div>
                                        <div className="col-4"></div>
                                        <div className="col-4"></div>
                                    </div>
                                }
                            />
                        </div>
                    </div>

                </UICard>
            </Fragment>


            <div className="col-12 defaultPaddingTop">
                <UICard cardHeading={"Payment Modes"}>
                    <div className="row">
                        {
                            _get(formState, `${pdmtListAPI.key}_view.payment_categories`, []).map((value, index) => {
                                return (
                                    <div className="col-sm-2" key={index}>
                                        <CheckBox
                                            labelText={value.name}
                                            isDisabled={true}
                                            inputValue={_get(formState, `${pdmtListAPI.key}_view[${value.code}]`, "")}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                </UICard>
            </div>

            <div className="col-12 defaultPaddingTop">
                <UICard cardHeading={"Temporary Licence"}>
                    <ViewDetailBlock
                        labelCol={labelCol}
                        label={"Validity Period"}
                        value={`${_get(row, `temp_licence_validity_period`, "")} months`}
                    />
                    <ViewDetailBlock
                        labelCol={labelCol}
                        label={"Charging Method"}
                        value={isEmpty(_get(row, `temp_licence_charging_method`, ""))?"":(_get(row, `temp_licence_charging_method`, "")==="ANN"?"Annually":"Validity Period")}
                    />
                </UICard>
            </div>

            <div className="col-12 defaultPaddingTop">
                <UICard cardHeading={"Document Validation"}>
                    <div className="row">
                        <div className="col-md-12">
                            VET valid up to {_get(row, `vet_validity_period`, "")} days from issuing date
                        </div>
                        <div className="col-md-12">
                            VFT valid up to {_get(row, `vft_validity_period`, "")} days from issuing date
                        </div>
                    </div>
                </UICard>
            </div>

            <div className="col-12 defaultPaddingTop">
                <UICard cardHeading={"VFT Pass Count Per Day"}>
                    <ViewDetailBlock
                        labelCol={labelCol}
                        label={"Pass Count"}
                        value={_get(row, `max_vft_certificates`, "")}
                    />
                </UICard>
            </div>

            <div className="col-md-12 defaultPaddingTop">
                <UICard cardHeading={"Web Revenue Licence - Returned Address"}>
                    <ViewDetailBlock
                        labelCol={labelCol}
                        label={"Address Line 1"}
                        value={_get(formState, `${pdmtListAPI.key}_view.return_address.line1`, "")}
                    />
                    <ViewDetailBlock
                        labelCol={labelCol}
                        label={"Address Line 2"}
                        value={_get(formState, `${pdmtListAPI.key}_view.return_address.line2`, "")}
                    />
                    <ViewDetailBlock
                        labelCol={labelCol}
                        label={"City"}
                        value={_get(formState, `${pdmtListAPI.key}_view.return_address.city`, "")}
                    />
                </UICard>
            </div>

            <div className="col-12 defaultPaddingTop">
                <UICard cardHeading={"Web Temporary Revenue Licence - Valid Period"}>
                    <div className="row">
                        <div className="col-md-12">
                            Valid up to {_get(row, `web_temp_valid_days`, "")} days
                        </div>
                    </div>
                </UICard>
            </div>

            <div className="col-md-12 defaultPaddingTop">
                <UICard cardHeading={"DMT Mapping Parameters"}>
                    <ViewDetailBlock
                        labelCol={labelCol}
                        label={"DMT Mapping Code"}
                        value={_get(row, `dmt_mapping`, "")}
                    />
                </UICard>
            </div>

            <div className="col-md-12 col-sm-12 defaultMarginBottom">
                <AuditDetail
                    labelCol="4"
                    createdAt={dateObjectToString(_get(row, `service.created_at`, ""), "dd/mm/YY HH:MM")}
                    updatedAt={dateObjectToString(_get(row, `service.updated_at`, ""), "dd/mm/YY HH:MM")}
                    createdById={_get(row, `service.created_by`, "")}
                    updatedById={_get(row, `service.updated_by`, "")}
                    createdLocationId={_get(row, `service.created_location`, "")}
                    updatedLocationId={_get(row, `service.updated_location`, "")}
                    createdRoleId={_get(row, `service.created_role_id`, '')}
                    updatedRoleId={_get(row, `service.updated_role_id`, '')}
                />
            </div>

        </FormWrapper >
    );
}

export default ViewProvinceOffice;
