import React from "react";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { SubmitButton } from "../../../ui-components/ui-elements/form";
import { actionBtnTypes, DataTable, apiResponseStoringType } from "../../../ui-components/ui-elements/table/DataTable";
import { VehicleConditionForm } from "./includes/VehicleConditionForm";
import { ViewVehicleCondition } from "./includes/ViewVehicleCondition";
import { SearchElement } from "./includes/VehicleConditionElements";
import {
    vehicleConditionListApi
} from "../../../../config/apiUrl.config";
import { getDataByFormObject } from "../../../../helpers/common-helpers/common.helpers";
import { permissions } from "../../../../config/permission.config";
import { useEffect } from "react";

const SubmitBtn = (props) => {


    return <SubmitButton
        btnText={props.uiType === "EDIT" ? "Update" : "Save"}
        formGroupName={props.formGroupName}
        tooltip={props.uiType === "EDIT" ? "Edit" : "Create"}
        startIcon= "far fa-save"
        dataTableKey={props.dataTableKey}
        isValidate={true}
        flashMessages={{
            "success": {
                status: true,
                message: `Vehicle Condition is successfully ${props.uiType === "EDIT" ? "updated" : "saved"}.`,
                messageType: "success"
            }
        }}
        mapValidationErrorObj={{
            prefix: "Vehicle Condition Prefix already exists",
            name: "Vehicle Condition Name already exists"
          }}
        validationObject={{
            fileds: {
                "prefix": "Vehicle Condition Prefix",
                "name": "Vehicle Condition Name",
                "active": "Status",
                "vet_exemption_duration_code": "VET Validation Exemption Duration",
                "vet_exemption_value": "VET Validation Exemption Value",
                "vft_exemption_duration_code": "VFT Validation Exemption Duration",
                "vft_exemption_value": "VFT Validation Exemption Value",
                "dmt_mapping_parameter": "DMT Mapping Parameter"
            },
            rules: {
                "prefix": "required|max:2",
                "name": "required|max:50",
                "active": "required",
                "vet_exemption_duration_code": "required",
                "vet_exemption_value": "required|max:3",
                "vft_exemption_duration_code": "required",
                "vft_exemption_value": "required|max:3",
                "dmt_mapping_parameter": "required|max:50",
            },
        }}
        onGetAPIEndPointFn={(formState) => {
            return {
                url: props.uiType === "EDIT" ? `${vehicleConditionListApi.url}/${formState.id}` : vehicleConditionListApi.url,
                key: vehicleConditionListApi.key
            }
        }}
        onChangeRequestBodyFn={(fromObject) => {
            fromObject.id = props.uiType === "EDIT" ? fromObject.id : undefined;
            return getDataByFormObject({
                ...fromObject,
                prefix: fromObject.prefix.trim(),
                name: fromObject.name.trim(),
                active: fromObject.active !== "null" ?
                    (parseInt(fromObject.active) === 1 ? (true) : (false))
                    : "null",
            })
        }}
        callApiObject={{
            isSetHeaders: true,
            method: props.uiType === "EDIT" ? "put" : "post",
        }}
        apiDataStoringObject={{
            setLoader: true,
            storingType: apiResponseStoringType.dateTableLinkedForm
        }}
        onResponseCallBackFn={(response, error) => {
            if (response.length === undefined && error === undefined && response.data.errors === undefined) {
                props.onClosePopupFn();
            }
        }}
    />
}



const ManageVehicleCondition = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <DashboardTemplate
            pageTitle="Manage Vehicle Condition"
            breadCrumbList={[
                { urlTo: "/", displayName: "Home" },
                { urlTo: "", displayName: "Configurations" },
                { urlTo: "", displayName: "Manage Vehicle Condition" },
            ]}
        >
            <DataTable
                tableName="Vehicle Conditions"
                apiUrl={`${vehicleConditionListApi.url}?sort_by=name|asc`}
                dataTableKey={vehicleConditionListApi.key}
                isSetSearchFrom={true}
                defaultSearchFormObject={{
                    prefix: "",
                    name: "",
                    active: "null"
                }}
                searchFromComponent={
                    <SearchElement dataTableKey={vehicleConditionListApi.key} />
                }
                tableHeaderList={[
                    { displayName: "Vehicle Condition Prefix", key: "prefix", isSort: false },
                    { displayName: "Vehicle Condition Name", key: "name", isSort: false },
                    { displayName: "Status", key: "active", isSort: false, onPrintFn: ({ active }) => active === 1 ? "Active" : "Inactive" },
                ]}
                actionTypeList={[
                    {
                        actionType: actionBtnTypes.create,
                        actionBtn: {
                            "permissions": permissions.VEHICLE_CONDITION_ADD.permissions
                        },
                        modelContentElement: VehicleConditionForm,
                        dialogPopupProps: {
                            isFullScreen: false,
                            dialogTitle: "Add Vehicle Condition",
                            closeButton: {
                                btnText: "Cancel",
                                startIcon: "far fa-times-circle",
                            },
                            isConfirmationPopup: true,
                            isCustomButton: true,
                            customButtonComponent: SubmitBtn,
                            customButtonComponentProps: { btnText: "Save", uiType: "CREATE", dataTableKey: vehicleConditionListApi.key },
                            dialogTitle: "Add Vehicle Condition",

                        },
                        parentToModelProps: {
                            uiType: "CREATE",
                            formKey: vehicleConditionListApi.key + "_create",
                        },
                    },
                    {
                        actionType: actionBtnTypes.view,
                        actionBtn: {  tooltip: "View", "permissions": permissions.VEHICLE_CONDITION_VIEW.permissions },
                        modelContentElement: ViewVehicleCondition,
                        dialogPopupProps: {
                            isFullScreen: false,
                            dialogTitle: "View Vehicle Condition",
                        }
                    },
                    {
                        actionType: actionBtnTypes.edit,
                        actionBtn: { tooltip: "Edit", "permissions": permissions.VEHICLE_CONDITION_EDIT.permissions },
                        modelContentElement: VehicleConditionForm,
                        dialogPopupProps: {
                            isFullScreen: false,
                            dialogTitle: "Edit Vehicle Condition",
                            closeButton: {
                                btnText: "Cancel",
                                startIcon: "far fa-times-circle",
                            },
                            isConfirmationPopup: true,
                            isCustomButton: true,
                            customButtonComponent: SubmitBtn,
                            customButtonComponentProps: { btnText: "Update", uiType: "EDIT", dataTableKey: vehicleConditionListApi.key },
                        },
                        parentToModelProps: {
                            uiType: "EDIT",
                            formKey: vehicleConditionListApi.key + "_edit",
                        },

                    }
                ]}
            />
        </DashboardTemplate>
    );
};

export default ManageVehicleCondition;