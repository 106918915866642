import React, { Fragment } from "react";
import { vftCertificateListAPI, vftListAPI } from "../../../../../config/apiUrl.config";
import { getDataByFormObject } from "../../../../../helpers/common-helpers/common.helpers";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { uiAction } from "../../../../ui-components/ui-components-helpers/uiContext.helpers";
import {
    SubmitButton,
} from "../../../../ui-components/ui-elements/form";
import { apiResponseStoringType } from "../../../core/context-providers/CoreContext.provider";

const emptyFn = (...para) => undefined;

const CustomButton = (props) => {
    const uiType = props.uiType;

    return (

        <Fragment>
            <SubmitButton
                btnText={uiType === "CREATE" ? "Save" : "Yes"}
                startIcon={uiType === "CREATE" ? "far fa-save" : "fas fa-check"}
                elementStyle="btnWrapper"
                // formGroupName={`${vftCertificateNoListAPI.key}_create`}
                formGroupName={props.formGroupName}
                isValidate={uiType === "CREATE" ? true : false}
                flashMessages={{
                    "success": {
                        status: true,
                        message: "VFT Certificate Numbers are successfully saved.",
                        messageType: "success"
                    }
                }}
                validationObject={{
                    fileds: {
                        certificate_number_from: "certificate number from",
                        certificate_number_to: "certificate number to",

                    },
                    rules: {
                        certificate_number_from: "required|numeric|max:12",
                        certificate_number_to: "required|numeric|max:12",

                    },
                    message: {},
                }}
                // dataTableKey={vftCertificateNoListAPI.key}
                dataTableKey={props.dataTableKey}
                callApiObject={{
                    isSetHeaders: true,
                    multipart: false,
                    method:
                        uiType === "CREATE"
                            ? "post"
                            : "delete",
                    //  ("post"),
                    onUpload: false,
                }}
                apiDataStoringObject={{
                    setLoader: true,
                    storingType: apiResponseStoringType.responseLinkToFrom
                }}
                onGetAPIEndPointFn={(formState) => {

                    return {
                        url:
                            uiType === "CREATE"
                                ? `${vftListAPI.url}/${formState.reqId}/certificate-numbers`
                                : `${vftListAPI.url}/${formState.reqId}/certificate-numbers/${formState.id}`,
                        key: vftCertificateListAPI.key
                    }
                }}
                onChangeRequestBodyFn={(fromObject) => {
                    return getDataByFormObject({

                        ...fromObject,
                    
                    });

                    

                }}
                onResponseCallBackFn={(response, error) => {
                    if (response.length === undefined && error === undefined && response.data.errors === undefined) {
                        props.onClosePopupFn();
                    }
                }}

            />
        </Fragment>
    );
}

export {
    CustomButton
}
