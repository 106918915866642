/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-07-14 10:18:15
 * @Last Modified by: Migara Gunarathne
 * @Last Modified time: 2021-06-07 15.18
 */

import React, { useContext, Fragment, useEffect, useState } from "react";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { SubmitButton, } from "../../../ui-components/ui-elements/form";
import { DataTable, actionBtnTypes } from "../../../ui-components/ui-elements/table/DataTable";
import { ViewFineExcemption, SearchElement } from "./includes/ViewExcemption";
import { holidayConfigurationListAPI } from "../../../../config/apiUrl.config";
import { dateObjectToString } from "../../../../helpers/common-helpers/dateTime.helpers";
import { getDataByFormObject, isEmptyValue } from "../../../../helpers/common-helpers/common.helpers";
import { apiResponseStoringType } from "../../../../config/apiResponseKey";
import { HolidayForm } from "./includes/ExemptionForm";
import { AuthContext } from "../../core/context-providers/AuthContext.provider";
import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { permissions } from "../../../../config/permission.config";
import { RoleLevelCode } from "../../../../config/enum.config";
import { callApi } from "../../../../helpers/common-helpers/callApi.helpers";
import { CoreContext } from "../../core/context-providers/CoreContext.provider";
import { FormContext } from "../../core/context-providers/FormContext.provider";
import { isEmpty } from "lodash";
import { useLocation } from "react-router-dom";
const emptyFn = (...para) => undefined;
const CustomButtonsSave = ({
  onClosePopupFn = emptyFn,
  formGroupName = ""
}) => {

  const close = () => {
    onClosePopupFn();
  }

  return (
    <Fragment>
      <SubmitButton
        btnText="Save"
        elementStyle="btnWrapper"
      />
    </Fragment>
  )
}
const SubmitBtn = (props) => {

  return <SubmitButton
    formGroupName={props.formGroupName}
    tooltip={props.uiType === "EDIT" ? "Edit" : "Create New"}
    startIcon={props.uiType === "EDIT" ? 'far fa-edit' : 'far fa-save'}
    dataTableKey={props.dataTableKey}
    btnText={props.uiType === "EDIT" ? "Update" : "Save"}
    isValidate={true}
    flashMessages={{
      "success": {
        status: true,
        message: `Holiday is successfully ${props.uiType === "EDIT" ? "updated" : "added"}.`,
        messageType: "success"
      },
    }}
    mapValidationErrorObj={{
      to_date: "Charge is required."
    }}
    validationObject={{
      fileds: {
        "name": "Name",
        "holiday_type_code": "Type",
        "description": "Description",
        "active": "Status",
        "from_date": "From Date",
        "to_date": "To Date",
        "pdmt_id": "Province",
        "authority_id": "Authority"
      },
      rules: {
        "name": "required|max:50",
        "holiday_type_code": "required",
        "description": "max:200",
        "pdmt_id": "requiredIf:holiday_type_code,PRO|requiredIf:holiday_type_code,AUT",
        "authority_id": "requiredIf:holiday_type_code,AUT",
        "from_date": "required",
        "to_date": "required",
        "active": "required"
      },
      message: {
        "pdmt_id.requiredIf": "The Province is required",
        "authority_id.requiredIf": "The Authority is required"
      }
    }}
    onGetAPIEndPointFn={(formState) => {
      return {
        url: props.uiType === "EDIT" ? `${holidayConfigurationListAPI.url}/${formState.id}` : holidayConfigurationListAPI.url,
        key: holidayConfigurationListAPI.key
      }
    }}
    onChangeRequestBodyFn={(fromObject) => {
      fromObject.id = props.uiType === "EDIT" ? fromObject.id : undefined;

      return getDataByFormObject({
        ...fromObject,
        pdmt_id: (fromObject.pdmt_id) === '' ? (null) : (fromObject.pdmt_id) === undefined ? null : fromObject.pdmt_id,
        authority_id: (fromObject.authority_id) === '' ? (null) : (fromObject.authority_id) === undefined ? null : fromObject.authority_id,
        from_date: dateObjectToString(fromObject.from_date, "YY-mm-dd"),
        to_date: dateObjectToString(fromObject.to_date, "YY-mm-dd"),
      })
    }}
    callApiObject={{
      isSetHeaders: true,
      method: props.uiType === "EDIT" ? "put" : "post",
    }}
    apiDataStoringObject={{
      setLoader: true,
      storingType: apiResponseStoringType.dateTableLinkedForm
    }}
    onResponseCallBackFn={(response, error) => {
      if (response.length === undefined && error === undefined) {
        props.onClosePopupFn();


      }
    }}
  />
}

const ManageFineExcemptionDays = () => {

  const [authStatus, authAction] = useContext(AuthContext);
  const userRoleLevelCode = _get(authStatus, "authUser.role_level_code", "");
  const pdmtId = _get(authStatus, "authUser.pdmt_id", "");
  const [formState, formAction] = useContext(FormContext)
  const [coreState, coreAction] = useContext(CoreContext);
  
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const mainUrl = isEmptyValue(pdmtId) ?
    (userRoleLevelCode === "ADN_NTL" ? `${holidayConfigurationListAPI.url}?user_role_level=${userRoleLevelCode}&sort_by=created_at|desc&holiday_type_code=NAT` : `${holidayConfigurationListAPI.url}?user_role_level=${userRoleLevelCode}&sort_by=created_at|desc`) :
    (userRoleLevelCode === "ADN_NTL" ? `${holidayConfigurationListAPI.url}?pdmt_id=${pdmtId}&user_role_level=${userRoleLevelCode}&sort_by=created_at|desc&holiday_type_code=NAT` : `${holidayConfigurationListAPI.url}?pdmt_id=${pdmtId}&user_role_level=${userRoleLevelCode}&sort_by=created_at|desc`);


  const getholidayFn = () => {
    coreAction
      .sendRequestFn(mainUrl)
      .method("get")
      // .setInitStoring(apiResponseStoringType.setResponse, {
      //   responseKey: `${holidayConfigurationListAPI.key}`,
      // })
      .headers(true)
      .send((error, result) => {
        if (!error) {
          if (userRoleLevelCode !== "ADN_NTL") {
            const getOtherList = result.data.data.filter((item) => item.holiday_type_code.code === "PRO" || item.holiday_type_code.code === "AUT")
            coreAction.setDataTable(`${holidayConfigurationListAPI.key}`, getOtherList, 'done', 1, null, null)


          }
        }
      });
  }


  useEffect(() => {
    getholidayFn()

  }, [userRoleLevelCode, `${holidayConfigurationListAPI.key}`, _get(formState, `${holidayConfigurationListAPI.key}_search.year`, "")]);



  return (
    <DashboardTemplate
      pageTitle="Manage Holiday Configuration"
      breadCrumbList={[
        { urlTo: "/", displayName: "Home" },
        { urlTo: "", displayName: "Other" },
        { urlTo: "", displayName: "Manage Holiday Configuration" },
      ]}
    >
      <DataTable
        tableName="Holiday Configurations"
        isSetSearchFrom={true}
        apiUrl={mainUrl}
        dataTableKey={`${holidayConfigurationListAPI.key}`}
        //  tableBodyList={getdatalist}
        isSetAction={true}
        defaultSearchFormObject={{
          "year": null,
          "month": null,
          "holiday_type_code": null,
        }}
        searchFromComponent={
          <SearchElement dataTableKey={`${holidayConfigurationListAPI.key}`} />
        }
        actionTypeList={[
          {
            actionType: actionBtnTypes.create,
            actionBtn: {
              "permissions": permissions.HOLIDAY_CONFIG_ADD.permissions
            },
            modelContentElement: HolidayForm,
            dialogPopupProps: {
              isFullScreen: false,
              isSaveButton: false,
              isCloseButton: true,
              closeButton: {
                btnText: "Cancel",
                startIcon: 'far fa-times-circle'
              },
              isCustomButton: true,
              isConfirmationPopup: true,
              customButtonComponent: SubmitBtn,
              customButtonComponentProps: { btnText: "Create", uiType: "CREATE", dataTableKey: holidayConfigurationListAPI.key },
              dialogTitle: "Add Holiday Configuration",
            },
            parentToModelProps: {
              uiType: "CREATE",
              formKey: holidayConfigurationListAPI.key + "_create",
            },
          },
          {

            actionType: actionBtnTypes.view,
            actionBtn: { tooltip: "View", "permissions": permissions.HOLIDAY_CONFIG_VIEW.permissions },
            modelContentElement: ViewFineExcemption,
            dialogPopupProps: {
              isFullScreen: false,
              isSaveButton: false,
              isCloseButton: true,
              dialogTitle: "View Holiday Configuration",
            },
            checkBeforeActionRenderFn: (rawData) => {
              let viewOption
              if (_get(rawData, "row.holiday_type_code.code", null) == 'NAT' && userRoleLevelCode === "ADN_NTL") {
                viewOption = true;
              }
              else if ((_get(rawData, "row.holiday_type_code.code", null) == 'AUT' || _get(rawData, "row.holiday_type_code.code", null) == 'PRO') && userRoleLevelCode === "ADN_PRV") {
                viewOption = true;
              }
              return (viewOption)
            }
          },
          {
            actionType: actionBtnTypes.edit,
            actionBtn: { icon: "far fa-edit", tooltip: "Edit", "permissions": permissions.HOLIDAY_CONFIG_EDIT.permissions },
            modelContentElement: HolidayForm,
            dialogPopupProps: {
              isFullScreen: false,
              isSaveButton: false,
              isCloseButton: true,
              closeButton: {
                btnText: "Cancel",
                startIcon: 'far fa-times-circle'
              },

              isConfirmationPopup: true,
              isCustomButton: true,
              customButtonComponent: SubmitBtn,
              customButtonComponentProps: { btnText: "Edit", uiType: "EDIT", dataTableKey: holidayConfigurationListAPI.key },
              dialogTitle: "Edit Holiday Configuration",
            },
            parentToModelProps: {
              uiType: "EDIT",
              formKey: holidayConfigurationListAPI.key + "_edit",

            },
            checkBeforeActionRenderFn: (rawData) => {
              const MyDate = new Date();
              var MyDateString;
              let validity
              if (_get(rawData, "row.holiday_type_code.code", null) == 'NAT' && userRoleLevelCode != RoleLevelCode.ADN_NTL) {
                validity = false;
              } else if (_get(rawData, "row.holiday_type_code.code", null) == 'NAT' && userRoleLevelCode === "ADN_NTL") {
                validity = true;
              }
              else if ((_get(rawData, "row.holiday_type_code.code", null) == 'AUT' || _get(rawData, "row.holiday_type_code.code", null) == 'PRO') && userRoleLevelCode === "ADN_PRV") {
                validity = true;
              }
              else {
                MyDateString = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2);
                const slicedToDate = rawData.row.to_date.slice(0, 10);
                validity = slicedToDate > MyDateString ? true : false;
              }
              return validity;
            }
          },
        ]}
        tableHeaderList={[
          { displayName: "From", key: "from", onPrintFn: (rawData) => dateObjectToString(rawData.from_date, "dd/mm/YY"), isSort: false },
          { displayName: "To", key: "to", onPrintFn: (rawData) => dateObjectToString(rawData.to_date, "dd/mm/YY"), isSort: false },
          {
            displayName: "Type",
            key: "holiday_type_code.code", onPrintFn: ({ holiday_type_code }) => holiday_type_code.code === "PRO" ? "Province" : holiday_type_code.code === "AUT" ? "Authority" : "National",
            isSort: false,
          },
          {
            displayName: "Status",
            key: "active", onPrintFn: ({ active }) => active === 1 ? "Active" : "Inactive",

            isSort: false,

          },
          {
            displayName: "Holiday name",
            key: "name",
            isSort: false,
          },
        ]}

      />
    </DashboardTemplate>
  );
};

export default ManageFineExcemptionDays;
