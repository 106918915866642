/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-07-14 10:18:15
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2022-12-31 22:18:26
 */

import React, { Fragment, useContext } from "react";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { SubmitButton } from "../../../ui-components/ui-elements/form";
import {
  DataTable,
  actionBtnTypes,
} from "../../../ui-components/ui-elements/table/DataTable";
import { ViewCounter, SearchElement } from "./includes/ViewCounter";
import { CounterSessionEndForm, Form } from "./includes/Form";
import { SetSerial } from "./includes/SetSerial";
import {
  countersListApi,
  counterCreateApi,
  counterUpdateApi,
  counterSerialNumbersCreateApi,
  counterSessionEditAPI,
} from "../../../../config/apiUrl.config";
import { getDataByFormObject, isEmptyValue } from "../../../../helpers/common-helpers/common.helpers";
import { apiResponseStoringType } from "../../../../config/apiResponseKey";
import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { AuthContext } from "../../core/context-providers/AuthContext.provider";
import { CheckPermission } from "../../../ui-components/ui-elements/common/BaseElements";
import { permissions } from "../../../../config/permission.config";
import { UIContext } from "../../../ui-components/context-providers/UIContext.provider";
import { isEmpty } from "lodash";
import { useEffect } from "react";

const CustomButton = (props) => {
  const uiType = `${props.uiType}`;
  const btnText = `${props.btnText}`;
  const [, uiAction] = useContext(UIContext);

  return (
    <Fragment>
      <CheckPermission
        permission={permissions.COUNTER_ADD.permissions}
      >
      <SubmitButton
        btnText={btnText}
        startIcon={
          uiType === "add"
            ? "far fa-save"
            : uiType === "end"
            ? "fas fa-check"
            : "far fa-edit"
        }
        formGroupName={
          uiType === "add"
            ? `${countersListApi.key}_create`
            : uiType === "end"
            ? `${counterSessionEditAPI.key}_end`
            : `${countersListApi.key}_edit`
        }
        dataTableKey={countersListApi.key}
        isValidate={uiType === "end" ? false : true}
        mapValidationErrorObj={{
          name: "Counter Name already exists",
          code: "Counter Code already exists",
        }}
        validationObject={{
          fileds: {
            authority_id: "Authority",
            name: "Counter Name",
            code: "Counter Code",
            active: "Status",
          },
          rules: {
            authority_id: "required",
            name: "required|max:10",
            code: "required|max:4",
            active: "required",
          },
        }}
        onGetAPIEndPointFn={(formState) => {
          return {
            url:
              uiType === "add"
                ? `${counterCreateApi.url}/${formState.authority_id}/counters`
                : uiType === "end"
                ? `${counterSessionEditAPI.url}/${formState.counter_id}/sessions/${formState.id}`
                : `${counterUpdateApi.url}/${formState.authority_id}/counters/${formState.id}`,
            key: counterCreateApi.key,
          };
        }}
        onChangeRequestBodyFn={(formObject) => {
          const ids=[];
          const codes=[];
          const active = isEmpty(formObject.active) || parseInt(formObject.active) === 1 ? true : false;
          for (var key in formObject.check_licence_type) {
            if(formObject.check_licence_type[key]){
              if(formObject.licence_type.map(object => object.id).indexOf(key) >= 0){
                ids.push(key);
              }
            }
          }
          for (var key in formObject.check_operation_type) {
            if(formObject.check_operation_type[key]){
              codes.push(key);
            }
          }
          /**
           * Normal/ Free/ Non-User Licence checking
           * if Normal/ Free/ Non-User Licence is checked, 
           * NO, NU, FE all 3 will added as allowed licence types
           */
          const index = formObject.licence_type.map(object => object.code).indexOf('NO');
          if(index >= 0){
            const normalLcnTypId = formObject.licence_type[index]['id'];
            const freeLcnTypIdx = formObject.licence_type.map(object => object.code).indexOf('FE');
            const nonUsrLcnTypIdx = formObject.licence_type.map(object => object.code).indexOf('NU');
            const freeLcnTypId = formObject.licence_type[freeLcnTypIdx]['id'];
            const nonUsrLcnTypId = formObject.licence_type[nonUsrLcnTypIdx]['id'];
            if(ids.includes(normalLcnTypId)){
              ids.push(freeLcnTypId, nonUsrLcnTypId);
            }else{
              if(ids.includes(freeLcnTypId) || ids.includes(nonUsrLcnTypId)){
                ids.splice(ids.indexOf(freeLcnTypId), 1);
                ids.splice(ids.indexOf(nonUsrLcnTypId), 1);
              }
            }
          }
          return getDataByFormObject({
            ...formObject,
            active: active,
            operation_type_codes: codes,
            licence_type_ids: ids
          });
        }}
        callApiObject={{
          isSetHeaders: true,
          method: uiType === "add" ? "post" : uiType === "end" ? "put" : "put",
        }}
        apiDataStoringObject={{
          setLoader: true,
          storingType: apiResponseStoringType.dateTableLinkedForm,
        }}
        onResponseCallBackFn={(response, error) => {
          if (
            response.length === undefined &&
            error === undefined &&
            response.data.errors === undefined          
          ) {
            uiAction.setFlashMessage({
              status: true,
              message:
              uiType === "add"
                ? "Counter is successfully saved."
                : uiType === "end"
                ? "Counter Session successfully ended."
                : "Counter is successfully updated.",
              messageType: "success",
            });
            props.onClosePopupFn();
          } else {
            uiAction.setFlashMessage({
              status:
                _get(response, "data.errors.details[0].message", null) == null
                  ? false
                  : true,
              message: 
              _get(response, "data.errors.details[0].property", null) == 'operation_type_codes'
                ? 'Select at least one operation' : 'Something went wrong',
              messageType: "error",
            });
          }
        }}
        />
      </CheckPermission>

    </Fragment>
  );
};

const ManageCounters = () => {

  const [authStatus, authAction] = useContext(AuthContext);
  const service_id = _get(authStatus, "authUser.service_id", "");
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  return (
    <DashboardTemplate
      pageTitle="Manage Counter"
      breadCrumbList={[
        { urlTo: "/", displayName: "Home" },
        { urlTo: "", displayName: "eRL Departments" },
        { urlTo: "", displayName: "Manage Counters" },
      ]}
    >
      <div className="row">
        <div className="col-md-12 floatLeft defaultMarginTopBottom">
          <DataTable
            tableName="Counters"
            apiUrl={`${countersListApi.url}/${service_id}/counters/latest-serial-range/get-all?sort_by=name|asc`}
            dataTableKey={countersListApi.key}
            isSetAction={true}
            isSetSearchFrom={true}
            defaultSearchFormObject={{
              authority: "",
              name: "",
              active: null,
            }}
            searchFromComponent={
              <SearchElement dataTableKey={countersListApi.key} />
            }
            actionTypeList={[
              {
                actionType: actionBtnTypes.create,
                actionBtn: { "permissions": permissions.COUNTER_ADD.permissions },
                modelContentElement: Form,
                dialogPopupProps: {
                  isFullScreen: false,
                  isSaveButton: false,
                  isCloseButton: true,
                  isCustomButton: true,
                  customButtonComponent: CustomButton,
                  customButtonComponentProps: {
                    btnText: "Save",
                    uiType: "add",
                  },
                  dialogTitle: "Add Counter",
                  closeButton: {
                    btnText: "Cancel",
                    startIcon: "far fa-times-circle",
                  },
                  isConfirmationPopup: true,
                },
                parentToModelProps: {
                  formKey: countersListApi.key + "_create",
                  dataTableKey: countersListApi.key,
                  uiType: "add",
                },
              },
              {
                actionType: actionBtnTypes.view,
                actionBtn: { 
                  
                  tooltip: "View", 
                  "permissions": permissions.COUNTER_VIEW.permissions 
                },
                modelContentElement: ViewCounter,
                dialogPopupProps: {
                  isFullScreen: false,
                  isSaveButton: false,
                  isCloseButton: true,
                  dialogTitle: "View Counter",
                },
              },
              {
                actionType: actionBtnTypes.edit,
                actionBtn: { 
                  icon: "far fa-edit", 
                  tooltip: "edit",
                  "permissions": permissions.COUNTER_EDIT.permissions
                },
                modelContentElement: Form,
                dialogPopupProps: {
                  isFullScreen: false,
                  isSaveButton: false,
                  isCloseButton: true,
                  isCustomButton: true,
                  customButtonComponent: CustomButton,
                  customButtonComponentProps: {
                    btnText: "Update",
                    uiType: "edit",
                  },
                  closeButton: {
                    btnText: "Cancel",
                    startIcon: "far fa-times-circle",
                  },
                  dialogTitle: "Edit Counter",
                  isConfirmationPopup: true,
                },
                parentToModelProps: {
                  formKey: countersListApi.key + "_edit",
                  dataTableKey: countersListApi.key,
                  uiType: "edit",
                },
              },
              {
                actionType: actionBtnTypes.view,
                actionBtn: {
                  icon: "fa fa-barcode",
                  tooltip: "Set Serial Numbers",
                  "permissions": permissions.COUNTER_SET_SERIAL.permissions
                },
                modelContentElement: SetSerial,
                dialogPopupProps: {
                  isFullScreen: true,
                  isSaveButton: false,
                  isCloseButton: true,
                  isCustomButton: false,
                  customButtonComponentProps: {
                    btnText: "Create",
                    uiType: "add",
                  },
                  dialogTitle: "Set Serial Numbers",
                },
                parentToModelProps: {
                  formKey: counterSerialNumbersCreateApi.key + "_create",
                  dataTableKey: counterSerialNumbersCreateApi.key,
                },
              },
              {
                actionType: actionBtnTypes.edit,
                actionBtn: {
                  icon: "fa fa-stop-circle",
                  tooltip: "End Current Session",
                  "permissions": permissions.COUNTER_SESSION_END.permissions
                },
                modelContentElement: CounterSessionEndForm,
                dialogPopupProps: {
                  isFullScreen: false,
                  dialogTitle: "Please Confirm",
                  isCustomButton: true,
                  closeButton: {
                    btnText: "No",
                    startIcon: "fa fa-times",
                  },
                  customButtonComponent: CustomButton,
                  customButtonComponentProps: {
                    dataTableKey: countersListApi.key,
                    uiType: "end",
                    btnText: "Yes"
                  },
                },
                parentToModelProps: {
                  formKey: counterSessionEditAPI.key + "_end",
                  dataTableKey: countersListApi.key,
                },
                checkBeforeActionRenderFn: (rawData) => {
                  const data = rawData.row.counter_session;
                  return data != null ? true : false;
                }
              },
            ]}
            tableHeaderList={[
              {
                displayName: "Authority",
                key: "authority_name",
                isSort: false,
              },
              { displayName: "Counter Name", key: "name", isSort: false },
              { displayName: "Counter Code", key: "code", isSort: false },
              {
                displayName: "Serial No. Range",
                key: "Serial_range",
                isSort: false,
                onPrintFn: (rowData) =>
                  isEmptyValue(rowData.serial_number_from) ? '-' : `${rowData.serial_number_from} - ${rowData.serial_number_to}`,
              },
              {
                displayName: "Current Serial No.",
                key: "next_serial_number",
                isSort: false,
                onPrintFn: ({ next_serial_number }) =>
                  isEmptyValue(next_serial_number) ? 'N/A' : next_serial_number
              },
              {
                displayName: "Serial No. Count ",
                key: "serial_count",
                isSort: false,
                onPrintFn: ({
                  serial_number_from,
                  serial_number_to,
                }) =>
                  isEmptyValue(serial_number_from) ? '-' :
                    parseInt(serial_number_to) -
                    parseInt(serial_number_from) +
                    +1,
              },
              {
                displayName: "Discarded Count",
                key: "discarded_count",
                isSort: false,
                onPrintFn: ({
                  discarded_count,
                  serial_number_from
                }) =>
                  isEmptyValue(serial_number_from) ? '-' : discarded_count,
              },
              {
                displayName: "Serial No. Balance",
                key: "serial_balance",
                isSort: false,
                onPrintFn: ({
                  serial_number_from,
                  serial_number_to,
                  discarded_count,
                  used_count
                }) =>
                  isEmptyValue(serial_number_from) ? '-' :
                    parseInt(serial_number_to) -
                    parseInt(serial_number_from) -
                    (parseInt(discarded_count) + parseInt(used_count)) +
                    +1,
              },
              {
                displayName: "Status",
                key: "active",
                isSort: false,
                onPrintFn: ({ active }) =>
                  active === 1 ? "Active" : "Inactive",
              },
            ]}
          />
        </div>
      </div>
    </DashboardTemplate>
  );
};

export default ManageCounters;
