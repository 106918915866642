/*
 * @Author: Sujith
 * @Date: 2020-10-19 11:11:46
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2021-07-16 17:30:44
 */
import React from "react";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import {
  DataTable,
  actionBtnTypes,
} from "../../../ui-components/ui-elements/table/DataTable";
import { Form } from "./includes/Form";
import { SearchElement } from "./includes/search-element";

import { View } from "./includes/view";
import { bankListApi } from "../../../../config/apiUrl.config";
import { CustomButton } from './includes/Custom-button';
import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { permissions } from "../../../../config/permission.config";
import { useEffect } from "react";




const Bank = () => {
  const formKey = "testInputs";
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
    <DashboardTemplate
      pageTitle="Manage Bank"
      breadCrumbList={[
        { urlTo: "/", displayName: "Home" },
        { urlTo: "/bank", displayName: "Configurations" },
        { urlTo: "/bank", displayName: "Bank" },
      ]}
    >
      <DataTable
        tableName={"Banks"}
        apiUrl={`${bankListApi.url}?sort_by=service.name|asc`}
        dataTableKey={bankListApi.key}
        isSetAction={true}
        defaultSearchFormObject={{
          "code": "",
          "name": "",
          "status": null,
        }}
        isSetSearchFrom={true}
        searchFromComponent={
          <SearchElement dataTableKey={bankListApi.key} />
        }

        actionTypeList={[
          {
            actionType: actionBtnTypes.create,
            actionBtn: {
              "permissions": permissions.BANK_ADD.permissions
            },
            modelContentElement: Form,
            dialogPopupProps: {
              isFullScreen: false,
              dialogTitle: "Add Bank",
              closeButton: {
                btnText: "Cancel",
                startIcon: "far fa-times-circle",
              },
              isConfirmationPopup: true,
              isCustomButton: true,
              customButtonComponent: CustomButton,
              customButtonComponentProps: {
                "uiType": "Add",
                dataTableKey: bankListApi.key,
              }
            },
            parentToModelProps: {
              "uiType": "CREATE",
              formKey: bankListApi.key + "_create",
            },
          },
          {
            actionType: actionBtnTypes.view,
            actionBtn: {  tooltip: "View", "permissions": permissions.BANK_VIEW.permissions },
            modelContentElement: View,
            dialogPopupProps: {
              isFullScreen: false,
              dialogTitle: "View Bank",
            },
          },
          {
            actionType: actionBtnTypes.edit,
            actionBtn: { tooltip: "Edit", "permissions": permissions.BANK_EDIT.permissions },
            modelContentElement: Form,
            dialogPopupProps: {
              isFullScreen: false,
              dialogTitle: "Edit Bank",
              closeButton: {
                btnText: "Cancel",
                startIcon: "far fa-times-circle",
              },
              isConfirmationPopup: true,
              isCustomButton: true,
              customButtonComponent: CustomButton,
              customButtonComponentProps: {
                "uiType": "Edit",
                dataTableKey: bankListApi.key,
              }
            },
            parentToModelProps: {
              uiType: "edit",
              formKey: bankListApi.key + "_edit",
              dataTableKey: bankListApi.key,
            },
          },
        ]}

        tableHeaderList={[
          {
            displayName: "Bank Code",
            key: "code",
            default: "-",
            isSort: false,
          },
          {
            displayName: "Bank Name",
            key: "service.name",
            isSort: false,
            default: "-",
          },
          {
            displayName: "Status",
            key: "service.active",
            isSort: false,
            default: "-",
            onPrintFn: (row) => _get(row, "service.active", false) ? "Active" : "Inactive"
          },
        ]}

      />
    </DashboardTemplate>
  );
};

export default Bank;
