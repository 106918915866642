/*
 * @Author: Chanaka Wickramasinghe
 * @Description: Material Lists And Menus
 * @Date: 2020-02-17 02:33:12
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-04-06 12:45:55
 */

import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import {Icon} from '../common/BaseElements';



const emptyFun = (...para) => undefined;

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Material List Wrapper
 * --------------------------------------------
 */

const ListWrapper = ({
    elementStyle = "",
    isDisablePadding = false,
    children = (null)
}) => {
    return (
        <List 
            className={`defaultListWrapper ${elementStyle}`}
            disablePadding={isDisablePadding}
        >
            {children}
        </List>
    );
};

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Material List Wrapper
 * --------------------------------------------
 */

ListWrapper.propTypes = {
    /** List element css class */
    elementStyle: PropTypes.string,
    /** Is padding disable or not */
    isDisablePadding: PropTypes.bool,
    /** Childrens */
    children: PropTypes.node
};

//----------------ListWrapper---------------------

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Material List Item Wrapper
 * --------------------------------------------
 */

const ListItemWrapper = ({
    elementStyle = "",
    keyValue = "",
    isButton = true,
    listItemText = "",
    leftIcon = null,
    rightIcon = null,
    onClickFn = emptyFun
}) => {
    return (
        <ListItem
            className={`defaultListItemWrapper ${elementStyle}`}
            key={keyValue}
            button={isButton}
            onClick={onClickFn}
        >
            {
                (leftIcon !== null) ? (
                    <ListItemIcon className={`defaultListItemIcon leftIcon`}>
                        <Icon iconClass={leftIcon} />
                    </ListItemIcon>
                ) : null
            }

            <ListItemText className={`defaultListItemText`} primary={listItemText} />

            {
                (rightIcon !== null) ? (
                    <ListItemIcon className={`defaultListItemIcon rightIcon`}>
                        <Icon iconClass={rightIcon} />
                    </ListItemIcon>
                ) : null
            }

        </ListItem>
    );
};

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: Material List Item Wrapper
 * --------------------------------------------
 */

ListItemWrapper.propTypes = {
    /** List item element css class */
    elementStyle: PropTypes.string,
    /** List item key */
    keyValue: PropTypes.string,
    /** Is button or not */
    isButton: PropTypes.bool,
    /** List item text */
    listItemText: PropTypes.string,
    /** Left icon */
    leftIcon: PropTypes.string,
    /** Right icon */
    rightIcon: PropTypes.string,
    /** Onclick element function */
    onClickFn: PropTypes.func
};
    
//----------------ListItemWrapper---------------------

/**
 * --------------------------------------------
 * @Author: Nisal Madusanka
 * @Description: Material Menu Wrapper
 * --------------------------------------------
 */



const MenuWrapper = ({
  id="",
  elementStyle="",
  anchorEl=null,
  keepMounted=true,
  open=true,
  onCloseFn=emptyFun,
  TransitionComponent=Fade,
  children=null
}) => {
    return (
        <Menu 
            id={id}
            anchorEl={anchorEl}
            keepMounted={keepMounted}
            open={open}
            onClose={onCloseFn}
            TransitionComponent={TransitionComponent}
            className={`defaultMenuWrapper ${elementStyle}`}
        >
            {children}
        </Menu>
    );
};

const MenuItemWrapper=({
    elementStyle="",
    menuKey="",
    children=null,
    onClickFn=emptyFun
})=>{
    return(
        <MenuItem className={`defaultMenuItem ${elementStyle}`} onClick={()=>onClickFn(menuKey)}>{children}</MenuItem>
    )
}



export {ListWrapper, ListItemWrapper,MenuWrapper,MenuItemWrapper};
