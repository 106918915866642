/*
 * @Author: Anjula Karunarathne
 * @Date: 2020-12-30 13:55:41
 * @Last Modified by: Anjula Karunarathne
 * @Last Modified time: 2020-12-30 15:34:01
 */

import React, { Fragment } from "react";
import { permissions } from "../../../config/permission.config";
import authorized from "../../hoc-middlewares/Authorized";
import { Navigate } from "../../ui-components/ui-elements/common/Navigate";
import ManageDealerFile from "./pages/ManageDealerFiles";
import UploadDealerFiles from "./pages/UploadDealerFiles";

const DealerFilesRoutes = () => {
    return (
        <Fragment>
            <Navigate exact={true} path="/dealerFiles" routeKey={"dealerFiles"} component={authorized(UploadDealerFiles)} routePermissions={permissions.DEALER_FILE_UPLOAD.permissions} />
        </Fragment>
    );
};

export default DealerFilesRoutes;
