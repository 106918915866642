import React from "react";
import {
  FormWrapper,
  InputBoxWithState,
  SelectBoxWithState,
} from "../../../../ui-components/ui-elements/form";
import { formCacheLevel } from "../../../../ui-components/ui-elements/form/index";

const SearchElement = ({ dataTableKey = "" }) => {
  return (
    <FormWrapper setGroupName={`${dataTableKey}_search`}>
      <div className="row">
        <div className={"col-md-2 col-sm-4 floatLeft"}>
          <InputBoxWithState
            labelText={"Prefix"}
            formGroupName={`${dataTableKey}_search`}
            inputName={"prefix"}
            inputPlaceholder={"Prefix"}
            cacheLevel={formCacheLevel.updateOnIndividual}
            maxLength={"2"}
          />
        </div>
        <div className={"col-md-2 col-sm-4 floatLeft"}>
          <InputBoxWithState
            labelText={"Name"}
            formGroupName={`${dataTableKey}_search`}
            inputName={"name"}
            inputPlaceholder={"Name"}
            cacheLevel={formCacheLevel.updateOnIndividual}
            maxLength={"50"}
          />
        </div>
        <div className={"col-md-2 col-sm-4 floatLeft"}>
          <SelectBoxWithState
            formGroupName={`${dataTableKey}_search`}
            labelText={"Status"}
            inputName={"active"}
            dataList={[
              { id: null, value: "All"},
              { id: true, value: "Active" },
              { id: false, value: "Inactive" },
            ]}
            emptySelectOptionTxt={"All"}
          />
        </div>
      </div>
    </FormWrapper>
  );
};

export { SearchElement };
