import React from "react";
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { FormWrapper, InputBoxWithState, SelectBoxWithState, NumberInputBoxWithState } from "../../../../ui-components/ui-elements/form";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";

const VETCompanyForm = (props) => {
    const formKey = `${props.fromParent.formKey}`;

    return (
        <UICard>
            <FormWrapper
                setGroupName={formKey}
                formGroupLinkWith={props.fromParent.formKey}
                onDestroyUnsetFormObject={true}
                setFormObject={{
                    id: _get(props, "row.id", ""),
                    code: _get(props, "row.code", ""),
                    br_number: _get(props, "row.br_number", ""),
                    name: _get(props, "row.service.name", ""),
                    name_si: _get(props, "row.service.name_si", ""),
                    name_ta: _get(props, "row.service.name_ta", ""),
                    contact_number: _get(props, "row.service.contacts[0].contact", ""),
                    active: parseInt(_get(props, "row.service.active", 1)),
                    api_mapping: _get(props, "row.code", ""),

                }}
            >
                {_get(props, "fromParent.uiType", "") === "Edit" ? (
                            <p className="defaultPaddingBottom"><strong>VET Company Code :</strong> {_get(props, "row.code", "")}</p>
                ) : (
                    <InputBoxWithState
                        labelText="Code"
                        isRequired={true}
                        formGroupName={formKey}
                        inputName={"code"}
                        maxLength={4}
                    />
                )}
                <InputBoxWithState
                    formGroupName={formKey}
                    labelText={"VET Company Name in English"}
                    isRequired={true}
                    inputName={"name"}
                    maxLength={50}
                    inputError={" Name already exist"}
                    //inputPlaceholder={"Enter VET Company Name in English"}
                />
                <InputBoxWithState
                    formGroupName={formKey}
                    labelText={"VET Company Name in Sinhala"}
                    isRequired={true}
                    inputName={"name_si"}
                    maxLength={50}
                    //inputPlaceholder={"Enter VET Company Name in Sinhala"}
                />
                <InputBoxWithState
                    formGroupName={formKey}
                    labelText={"VET Company Name in Tamil"}
                    isRequired={true}
                    inputName={"name_ta"}
                    maxLength={50}
                    //inputPlaceholder={"Enter VET Company Name in Tamil"}
                />
                <InputBoxWithState
                    formGroupName={formKey}
                    inputName={"br_number"}
                    labelText={"Business Registration Number"}
                    isRequired={true}
                    maxLength={30}
                />
                <NumberInputBoxWithState
                    formGroupName={formKey}
                    inputName={"contact_number"}
                    labelText="Contact Number"
                    isRequired={true}
                    isThousandSeparator={false}
                    isAllowNegative={false}
                    isAllowLeadingZeros={true}
                    decimalScale={0}
                    isAllowedFn={(value) => value.value.toString().length <= 10}
                    //inputPlaceholder={"Contact Number"}
                />
                <SelectBoxWithState
                    formGroupName={formKey}
                    labelText={"Status"}
                    inputName={"active"}
                    valueName={"value"}
                    isRequired={false}
                    keyName={"id"}
                    dataList={[{ "id": 1, "value": "Active" }, { "id": 0, "value": "Inactive" }]}
                />
                {/* <InputBoxWithState
                    formGroupName={formKey}
                    labelText={"API Mapping"}
                    isRequired={true}
                    inputName={"api_mapping"}
                    maxLength={50}
                /> */}
            </FormWrapper>
        </UICard>
    )
}

export { VETCompanyForm };