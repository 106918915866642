/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-03-22 12:44:12 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-03-01 16:49:59
 */

export const formErrorKey="Bad Request";


const apiResponseStoringType={
    dataTable:"DATA_TABLE",
    dateTableLinkedForm:"DATA_TABLE_LINKED_FORM",
    responseLinkToFrom:"API_RESPONSE_LINKED_FORM",
    setResponse:"SET_API_RESPONSE",
    apiResponseToFormState:"SET_FORM_STATE"
}

export {
    apiResponseStoringType
}