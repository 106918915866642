/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-03-30 15:26:15 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-11-09 17:56:57
 */

import React, { Fragment, useState } from 'react';

import { Icon, CollapseWrapper, CheckPermission } from "../../../ui-elements/common/BaseElements";
import { TooltipWrapper } from "../../common/BaseElements";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { getHeaderList } from "./TableHeader";
import { commonActionBtnPops } from "../../../../../config/dataTable.config";
import { screenSizeTypes } from "../../../../../config/template.config";
import Checkbox from "@material-ui/core/Checkbox";

const emptyFun = (...para) => undefined;

const ActionBtn = ({
    elementStyle = "",
    icon = "",
    tooltip = "",
    permissions = "NONE",
    isBtnDisabled = false,
    isSetModel = false,
    onClickBtnFn = { emptyFun },
    onActionClickFn = { emptyFun }
}) => {
    return (
        <CheckPermission permission={permissions}>
            <TooltipWrapper
                description={tooltip}
            >
                <button
                    disabled={isBtnDisabled}
                    className={`defaultActionBtn   ${elementStyle}`}
                    onClick={() => {
                        onClickBtnFn();
                        isSetModel ? onActionClickFn() : emptyFun()
                    }}>
                    <Icon iconClass={icon} />
                </button>
            </TooltipWrapper>
        </CheckPermission>
    )
}


const TableBody = ({
    tableHeaderList = [],
    dataTableKey = "",
    tableBody = [],
    isSetAction = true,
    actionTypeList = [],
    specificComponents = {},
    screenType = undefined,
    maxItemCount = 3,
    dataTableErros = [],
    pagingObject = {},
    isSelectRow = false,
    rowCheckObject = {},
    onCheckRowFn = emptyFun,
    onRowStyleFn = emptyFun,
    onChangeValueFn = emptyFun,
    onActionClickFn = emptyFun
}) => {

    const [bodyExpand, setBodyExpand] = useState({});

    return (
        <tbody>
            <Fragment>

                {
                    (tableBody).map((data, key) => {
                        const rowStyle = onRowStyleFn({
                            row: data,
                            rowIndex: key,
                        });

                        return (
                            <Fragment key={key}>
                                <tr key={`odd_${key}`} className={`odd_row ${rowStyle === undefined ? "" : rowStyle}`}>

                                    {
                                        isSelectRow === true ? (
                                            <td>
                                                <Checkbox
                                                    className={`dataTableCheckBox`}
                                                    name={"selectItem"}
                                                    checked={((rowCheckObject["selectAll"] === true) ? true : (_get(rowCheckObject, `row_${key}_key`, false)))}
                                                    onChange={(event, checked) => onCheckRowFn(`row_${key}_key`, checked)}
                                                />
                                            </td>
                                        ) : null
                                    }


                                    {
                                        (screenType === screenSizeTypes.largeDevice.key || screenType === screenSizeTypes.mediumDevice.key) ? (
                                            <Fragment>
                                                {
                                                    (getHeaderList(tableHeaderList, screenType, maxItemCount)).map((header, headerKey) => {
                                                        return (
                                                            <td key={headerKey}>
                                                                {

                                                                    (specificComponents.hasOwnProperty(header.key)) ?
                                                                        (specificComponents[header.key]({
                                                                            header: header,
                                                                            row: data,
                                                                            rowIndex: key,
                                                                            errors: dataTableErros,
                                                                            dataTableKey: dataTableKey,
                                                                            pagingObject: pagingObject,
                                                                            onActionClickFn: onActionClickFn,
                                                                            onChangeValueFn: (inputKey, value) => onChangeValueFn(dataTableKey, inputKey, value)
                                                                        })) :
                                                                        (
                                                                            <p className="maxWidth">
                                                                                {
                                                                                    (_get(header, "onPrintFn", null) === null) ?
                                                                                        _get(data, header.key, _get(header, "default", '-')) :
                                                                                        _get(header, "onPrintFn", null)(data)
                                                                                }
                                                                            </p>
                                                                        )
                                                                }
                                                            </td>
                                                        )
                                                    })
                                                }
                                            </Fragment>
                                        ) : (
                                            <td key={`odd_${key}`}>
                                                {
                                                    (getHeaderList(tableHeaderList, screenSizeTypes.mediumDevice.key, maxItemCount)).map((header, headerKey) => {
                                                        return (
                                                            <div key={headerKey} className={"dataViewCard"}>
                                                                {
                                                                    (specificComponents.hasOwnProperty(header.key)) ?
                                                                        (specificComponents[header.key]({
                                                                            header: header,
                                                                            row: data,
                                                                            rowIndex: key,
                                                                            dataTableKey: dataTableKey,
                                                                            onActionClickFn: onActionClickFn,
                                                                            onChangeValueFn: (inputKey, value) => onChangeValueFn(dataTableKey, inputKey, value)
                                                                        })) :
                                                                        (
                                                                            <Fragment>
                                                                                <strong>{header.displayName}: </strong>
                                                                                {
                                                                                    (_get(header, "onPrintFn", null) === null) ?
                                                                                        _get(data, header.key, _get(header, "default", '-')) :
                                                                                        _get(header, "onPrintFn", null)(data)
                                                                                }
                                                                            </Fragment>
                                                                        )
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </td>
                                        )
                                    }

                                    <Fragment>
                                        {
                                            (isSetAction === true || screenType !== screenSizeTypes.largeDevice.key) ? (
                                                <td className={`acctionBtnWrapper ${screenType !== screenSizeTypes.smallDevice.key  ? "acctionBtnWrapper-large":""}`}>

                                                    {
                                                        (actionTypeList || []).map((actionBtnData, actionKey) => {

                                                            actionBtnData = {
                                                                ...actionBtnData,
                                                                actionBtn: {
                                                                    ..._get(commonActionBtnPops, actionBtnData.actionType, {}),
                                                                    ...actionBtnData.actionBtn || {}
                                                                }
                                                            }

                                                            const checkBeforeActionRender = _get(actionBtnData, 'checkBeforeActionRenderFn', null) === null ? true : _get(actionBtnData, 'checkBeforeActionRenderFn', emptyFun)({
                                                                row: data,
                                                                rowIndex: key,
                                                                dataTableKey: dataTableKey
                                                            });
                                                            return (
                                                                <span key={actionKey}>
                                                                    {
                                                                        (actionBtnData.actionType !== "CREATE" && checkBeforeActionRender === true) ? (
                                                                            <ActionBtn
                                                                                elementStyle={_get(actionBtnData, 'actionBtn.elementStyle', '')}
                                                                                icon={_get(actionBtnData, 'actionBtn.icon', '')}
                                                                                permissions={_get(actionBtnData, 'actionBtn.permissions', 'NONE')}
                                                                                tooltip={_get(actionBtnData, 'actionBtn.tooltip', '')}
                                                                                isBtnDisabled={_get(actionBtnData, 'actionBtn.isBtnDisabled', false)}
                                                                                isSetModel={(actionBtnData.modelContentElement || null) === null ? false : true}
                                                                                onClickBtnFn={() => {
                                                                                    _get(actionBtnData, 'actionBtn.onClickBtnFn', emptyFun)({ row: data })
                                                                                }}
                                                                                onActionClickFn={() => {
                                                                                    onActionClickFn(
                                                                                        data,
                                                                                        actionKey,
                                                                                        key
                                                                                    );
                                                                                }
                                                                                }
                                                                            />
                                                                        ) : (null)
                                                                    }
                                                                </span>
                                                            )
                                                        })
                                                    }

                                                    {
                                                        ((screenType === screenSizeTypes.smallDevice.key || screenType === screenSizeTypes.mediumDevice.key) && maxItemCount !== 0) ? (
                                                            <span key={`even_btn_${key}`}>
                                                                <ActionBtn
                                                                    tooltip="View More"
                                                                    icon={bodyExpand[`even_td_${key}`] === true ? "fas fa-chevron-up" : "fas fa-chevron-down"}
                                                                    onClickBtnFn={(event) => setBodyExpand({
                                                                        ...bodyExpand,
                                                                        [`even_td_${key}`]: !bodyExpand[`even_td_${key}`]
                                                                    })}
                                                                    isSetModel={false}
                                                                />
                                                            </span>
                                                        ) : (null)
                                                    }

                                                </td>
                                            ) : (null)
                                        }
                                    </Fragment>

                                </tr>
                                <tr key={`even_${key}`} className={`even_row ${rowStyle === undefined ? "" : rowStyle}`}>
                                    <td key={`even_td_${key}`} colSpan={maxItemCount + 1}>
                                        <CollapseWrapper
                                            component={"div"}
                                            elementStyle={"tableBodyExpandWrapper"}
                                            isIn={bodyExpand[`even_td_${key}`] ? true : false}
                                            isUnMountOnExit={true}
                                        >
                                            {
                                                (screenType === screenSizeTypes.smallDevice.key || screenType === screenSizeTypes.mediumDevice.key) ?
                                                    (
                                                        <Fragment>
                                                            {
                                                                (getHeaderList(tableHeaderList, screenSizeTypes.mediumDevice.key, tableHeaderList.length, maxItemCount)).map((header, headerKey) => {
                                                                    return (
                                                                        <div key={headerKey} className="dataViewCard">
                                                                            {
                                                                                (specificComponents.hasOwnProperty(header.key)) ?
                                                                                    (specificComponents[header.key]({
                                                                                        header: header,
                                                                                        row: data,
                                                                                        rowIndex: key,
                                                                                        dataTableKey: dataTableKey,
                                                                                        onActionClickFn: onActionClickFn,
                                                                                        onChangeValueFn: (inputKey, value) => onChangeValueFn(dataTableKey, inputKey, value)
                                                                                    })) :
                                                                                    (
                                                                                        <Fragment>
                                                                                            <strong>{header.displayName}:</strong>
                                                                                            {
                                                                                                (_get(header, "onPrintFn", null) === null) ?
                                                                                                    _get(data, header.key, _get(header, "default", '-')) :
                                                                                                    _get(header, "onPrintFn", null)(data)
                                                                                            }
                                                                                        </Fragment>
                                                                                    )
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </Fragment>
                                                    ) : (null)
                                            }
                                        </CollapseWrapper>
                                    </td>
                                </tr>
                            </Fragment>
                        )
                    })
                }

            </Fragment>
        </tbody>
    )
}

export {
    TableBody
}