/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-09-15 10:59:39
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-15 12:23:22
 */

import React, { useState, Fragment } from "react";

import {
  FormWrapper,
  InputBoxWithState,
  InputButton,
  CheckBoxWithState,
  RoundButton,
  roundBtnSizes,
  SelectBoxWithState,
  checkBoxLabelPlacement,
} from "../../../../ui-components/ui-elements/form/index";
import { PaddingLessUICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { DataTable } from "../../../../ui-components/ui-elements/table/DataTable";
import { RoundButtonWithPopups } from "../../../../ui-components/ui-elements/form/Button";


const CommentsHistory=()=>{
    return (
      <div className="commentHistoryWrapper">
          <div className="commentElementWrapper">
              <h1 className="defaultHeading"><strong>Roshan Liyanage</strong> 2020-12-03:00:00:23</h1>
              <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec
              </p>
          </div>
          <div className="commentElementWrapper">
              <h1 className="defaultHeading"><strong>Roshan Liyanage</strong> 2020-12-03:00:00:23</h1>
              <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec
              </p>
          </div>
          <div className="commentElementWrapper">
              <h1 className="defaultHeading"><strong>Roshan Liyanage</strong> 2020-12-03:00:00:23</h1>
              <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec
              </p>
          </div>
      </div>
    )
  }

const LicenseArrearsIcons=({ uiType = null, formGroupName = null })=>{
    return(
        <Fragment>
            <RoundButtonWithPopups
            btnName="viewmore"
            iconClass={"mdi mdi-magnify-plus-outline"}
            btnSize={roundBtnSizes.small}
            dialogProps={{
            dialogTitle: `Comments `,
            }}
            modelComponent={CommentsHistory}
            propsToModel={{}}
        />
        </Fragment>
    )
}


const DealerLicense = () => {
  return (
    <div className="normalLicenseWrapper dealerLicenseWrapper">
      <div className="topWrapper">
        <div className="searchWrapper">
          <FormWrapper elementStyle={""} setGroupName={"searchFromKey"}>
            <div className="searchInputWrapper">
              <label className="control-labe">Dealer ID:</label>
              <input type="text" className="form-control" />
              <InputButton
                btnName="search"
                overrideTextToIcon={
                  <i className="mdi mdi-magnify-plus-outline" />
                }
              />
            </div>
          </FormWrapper>
        </div>
        <div className="statusWrapper">
          <ul className="statusUL">
            <li>
              <InputButton btnName="search" btnText={"Request for"} />
            </li>
          </ul>
        </div>
      </div>
      <FormWrapper
        setGroupName={"dealerLicense"}
        elementStyle={"normalLicenceWrapper"}
      >
        <div className="col-md-6 floatLeft">
          <PaddingLessUICard cardHeading="Dealer's Information">
            <div className="row">
              <div className="col-md-12 defaultHalfMarginBottom">
                <strong>Name of Dealer/ Manufacturer/ Repairer: </strong>Rohana Jayasundara                
              </div>
             
              <div className="col-md-12 defaultHalfMarginBottom">
                <strong> Dealer’s Office: </strong>
                <hr className="marginTopNone defaultHalfMarginBottom"/>
                <div className="row">
                    <div className="col-md-6">
                        <strong>Address 1: </strong>5 Sulaiman Terrace
                    </div>
                    <div className="col-md-6">
                        <strong>Address 2: </strong> Colombo 00500
                    </div>
                    <div className="col-md-6">
                        <strong>City: </strong> Colombo
                    </div>
                    <div className="col-md-6">
                        <strong>District: </strong> Colombo
                    </div>
                </div>
              </div>

              <div className="col-md-12 defaultHalfMarginBottom">
                <strong> Garage Address: </strong>
                <hr className="marginTopNone defaultHalfMarginBottom"/>
                <div className="row">
                    <div className="col-md-6">
                        <strong>Address 1: </strong>5 Sulaiman Terrace
                    </div>
                    <div className="col-md-6">
                        <strong>Address 2: </strong> Colombo 00500
                    </div>
                    <div className="col-md-6">
                        <strong>City: </strong> Colombo
                    </div>
                    <div className="col-md-6">
                        <strong>District: </strong> Colombo
                    </div>
                </div>
              </div>

              <div className="col-md-12 defaultHalfMarginBottom">
                <strong>Province: </strong>Colombo      
              </div>

            </div>
          </PaddingLessUICard>
        </div>
    
        <div className="col-md-6 floatRight ">
          <PaddingLessUICard
            cardHeading="Licence Amount"
          >
            <table style={{ width: "100%" }}>
              <tr>
                <th>Fee (LKR)</th>
                <td className="text-right">1000,000.00</td>
              </tr>              
              <tr>
                <th>Other Charges (LKR)</th>
                <td className="text-right">1000,000.00</td>
              </tr>
              <tr>
                <th>Total Amount (LKR)</th>
                <td className="text-right">
                  <b>1000,000.00</b>
                </td>
              </tr>
            </table>
          </PaddingLessUICard>
        </div>
                
        <div className="col-md-6 floatRight">
          <PaddingLessUICard
            cardHeading="Pending License"
            
          >
            <DataTable
              tableHeaderList={[
                { displayName: "Authorised Vehicle no", key: "vahicleNo", isSort: false },
                { displayName: "Validity Period (From -To)", key: "formTo", isSort: false },
                { displayName: "Insurance Company", key: "insComapny", isSort: false },
                {
                  displayName: "Insurance Expiry Date",
                  key: "insDate",
                  isSort: false,
                },
                { displayName: "Request ID", key: "reqID", isSort: false },
                { displayName: "Requested BY", key: "reqBY", isSort: false },
                { displayName: "Requested Location", key: "reqLoc", isSort: false },
              ]}
              tableBodyList={[
                {
                  vahicleNo: "KG-2789",
                  formTo: "2019-12-01 - 2020-12-01",
                  insComapny: "Ceylinco",
                  insDate: "2020-12-01",
                  reqID: "#00123",
                  reqBY: "-",
                  reqLoc: "Colombo"
                },
                {
                    vahicleNo: "KG-2789",
                    formTo: "2019-12-01 - 2020-12-01",
                    insComapny: "Ceylinco",
                    insDate: "2020-12-01",
                    reqID: "#00123",
                    reqBY: "-",
                    reqLoc: "Colombo"
                  },
                  {
                    vahicleNo: "KG-2789",
                    formTo: "2019-12-01 - 2020-12-01",
                    insComapny: "Ceylinco",
                    insDate: "2020-12-01",
                    reqID: "#00123",
                    reqBY: "-",
                    reqLoc: "Colombo"
                  },
              ]}
              isSetTableFooter={true}
              isSetTableHeader={false}
            />
          </PaddingLessUICard>
        </div>       

        <div className="col-md-6 floatLeft">
          <PaddingLessUICard
            cardHeading="Current Valid Licences Information"
            
          >
            <DataTable
              tableHeaderList={[
                { displayName: "Authorised Vehicle no", key: "vahicleNo", isSort: false },
                { displayName: "Lic No:", key: "licNo", isSort: false },
                { displayName: "Serial No", key: "serial", isSort: false },
                {
                  displayName: "Validity Period (From -To)",
                  key: "formTo",
                  isSort: false,
                },
                { displayName: "Insurance Company", key: "insComapny", isSort: false },
                { displayName: "Insurance Expiry Date", key: "insDate", isSort: false },
              ]}
              tableBodyList={[
                {
                  vahicleNo: "KG-2789",
                  licNo:"12312312312312asdasd3",
                  serial:"asdwqe23easdad23",
                  formTo: "2019-12-01 - 2020-12-01",
                  insComapny: "Ceylinco",
                  insDate: "2020-12-01",                 
                },
                {
                    vahicleNo: "KG-2789",
                    licNo:"12312312312312asdasd3",
                    serial:"asdwqe23easdad23",
                    formTo: "2019-12-01 - 2020-12-01",
                    insComapny: "Ceylinco",
                    insDate: "2020-12-01",                 
                  },
                  {
                    vahicleNo: "KG-2789",
                    licNo:"12312312312312asdasd3",
                    serial:"asdwqe23easdad23",
                    formTo: "2019-12-01 - 2020-12-01",
                    insComapny: "Ceylinco",
                    insDate: "2020-12-01",                 
                  }
              ]}
              isSetTableFooter={true}
              isSetTableHeader={false}
            />
          </PaddingLessUICard>
        </div>

        <div className="col-md-6 floatRight defaultHalfMarginTop">
          <PaddingLessUICard
            cardHeading="Payment History"
            
          >
            <DataTable
              tableHeaderList={[
                { displayName: "Tx. No", key: "txNo", isSort: false },
                { displayName: "Tx. Date & Time:", key: "txDate", isSort: false },
                { displayName: "Total Amount", key: "totalAmount", isSort: false },
                {
                  displayName: "Province",
                  key: "province",
                  isSort: false,
                },
                { displayName: "Authority", key: "authority", isSort: false },
              ]}
              tableBodyList={[
                {
                  txNo: "#000345",
                  txDate:"2020-12-01",
                  totalAmount:"2000/=",
                  province: "Western",
                  authority: "Western",              
                },
                {
                    txNo: "#000345",
                    txDate:"2020-12-01",
                    totalAmount:"2000/=",
                    province: "Western",
                    authority: "Western",              
                  },
                  {
                    txNo: "#000345",
                    txDate:"2020-12-01",
                    totalAmount:"2000/=",
                    province: "Western",
                    authority: "Western",              
                  }
              ]}
              isSetTableFooter={true}
              isSetTableHeader={false}
            />
          </PaddingLessUICard>
        </div>

        <div className="col-md-6 floatLeft defaultHalfMarginTop">
          <PaddingLessUICard
            cardHeading="Comments"
            passPropsToCardHeadingTail={{
              uiType: "comments",
              formGroupName: "normalLicence",
            }}
            cardHeadingTail={LicenseArrearsIcons}
          >
            <div className="row">
              <div className="col-md-12">
                <InputBoxWithState
                  isMultiline={true}
                  rows={1}
                  labelText={"Comments"}
                  formGroupName={"normalLicence"}
                  inputName={"comments"}
                />
              </div>
              <div className="col-md-12 text-right">
                <InputButton 
                  elementWrapperStyle={'defaultHalfMarginLeftRight'}
                  btnText="Save"
                />
                <InputButton 
                  elementWrapperStyle={'defaultHalfMarginLeftRight'}
                  btnText="Cancel"
                />
              </div>
              </div>

          </PaddingLessUICard>
        </div>

      </FormWrapper>
    </div>
  );
};

export { DealerLicense };
